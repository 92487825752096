import LabelingOverview from '../LabelingOverview.vue';
import LabelingBuild from '../LabelingBuild.vue';
import LabelingConfig from '../LabelingConfig.vue';
import RunOverview from '@/views/home/shared/RunOverview.vue';
import MonitorOverview from '@/views/home/shared/MonitorOverview.vue';

export default {
  path: '/labeling',
  name: 'Labeling',
  redirect: { name: 'LabelingConfig' },
  component: LabelingOverview,
  meta: { menuItemName: 'Labeling' },
  children: [
    {
      path: 'config',
      name: 'LabelingConfig',
      component: LabelingConfig
    },
    {
      path: 'build',
      name: 'LabelingBuild',
      component: LabelingBuild
    },
    {
      path: 'run',
      name: 'LabelingRun',
      component: RunOverview
    },
    {
      path: 'monitor',
      name: 'LabelingMonitor',
      component: MonitorOverview
    }
  ]
};
