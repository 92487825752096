<template>
  <el-row class="d-block">
    <h4 v-if="loadingPipelines">{{ $t('loading') }}</h4>
    <h4 v-else>{{ pipelines.length }} &#183; {{ $t('pipelines') }}</h4>
  </el-row>
  <base-table
    :show-loading-table="loadingPipelines"
    :show-no-content-table="!pipelines.length"
    :error-message="$t('noImport')"
  >
    <pipelines-table
      :pipelines="pipelines"
      :can-hide-pipelines="canHidePipelines"
      @start-pipeline-run="startPipelineRun"
      @update-pipeline="togglePipeline"
    />
  </base-table>
</template>

<script lang="ts">
import { ElRow } from 'element-plus';

import BaseTable from '@/components/tables/BaseTable.vue';
import PipelinesTable from '@/components/tables/PipelinesTable.vue';
import { PipelineDto } from '@etp/etp-pipelines-client/axios';

type PipelineTableEmitType = (...args: any[]) => any;

export default {
  name: 'BaseRunTab',
  components: { ElRow, BaseTable, PipelinesTable },
  props: {
    loadingPipelines: { type: Boolean, required: true },
    pipelines: { type: Array as () => Array<PipelineDto>, required: true },
    canHidePipelines: { type: Boolean, required: true },
    startPipelineRun: {
      type: Function as PipelineTableEmitType,
      required: true
    },
    togglePipeline: {
      type: Function as PipelineTableEmitType,
      required: true
    }
  }
};
</script>

<style lang="scss"></style>
