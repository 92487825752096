import { ActionContext, Module } from 'vuex';
import { createApiBffClient, createInfraApiClient } from '@/services/api';
import { createPipelinesApiClient } from '@/services/api';
import { TenantModel } from '@etp/etp-infra-client';
import { ResourceModel } from '@etp/etp-bff-client/axios';
import { CollaborationBranch } from '@etp/etp-pipelines-client/axios';

interface ConfigurationState {
  engagementResources: Array<ResourceModel>;
  tenant: TenantModel;
  collaborationBranches: Array<CollaborationBranch>;
}

class ConfigurationModule implements Module<ConfigurationState, any> {
  namespaced = true;
  state = {
    engagementResources: new Array<ResourceModel>(),
    tenant: {} as TenantModel,
    collaborationBranches: new Array<CollaborationBranch>()
  };

  getters = {
    getEngagementResources: (state: ConfigurationState) =>
      state.engagementResources,
    getTenant: (state: ConfigurationState) => state.tenant,
    getCollaborationBranches: (state: ConfigurationState) =>
      state.collaborationBranches
  };
  actions = {
    async fetchEngagementResources(
      context: ActionContext<ConfigurationState, any>
    ) {
      const engagementId = context.rootState.home.currentEngagement.id;
      if (!engagementId) return;

      let client = createApiBffClient();

      const { data, status } = await client.getEngagementResources(
        engagementId
      );

      if (status == 200) {
        context.commit('SET_ENGAGEMENT_RESOURCES', data);
      } else {
        context.commit('SET_ENGAGEMENT_RESOURCES', new Array<ResourceModel>());
      }
    },
    async fetchTenant(
      context: ActionContext<ConfigurationState, any>,
      payload: { tenantId: string }
    ) {
      let client = createInfraApiClient();
      const { data, status } = await client.getTenant(payload.tenantId);
      if (status == 200) {
        context.commit('SET_TENANT', data);
      } else {
        context.commit('SET_TENANT', {} as TenantModel);
      }
    },
    async fetchCollaborationBranches(
      context: ActionContext<ConfigurationState, any>
    ) {
      let client = createPipelinesApiClient();
      let engagementId = context.rootState.home.currentEngagement.id;
      const { data, status } = await client.getCollaborationBranches(
        engagementId
      );
      if (status == 200) {
        context.commit('SET_COLLABORATION_BRANCHES', data);
      } else {
        context.commit(
          'SET_COLLABORATION_BRANCHES',
          new Array<CollaborationBranch>()
        );
      }
    }
  };

  mutations = {
    SET_ENGAGEMENT_RESOURCES(
      state: ConfigurationState,
      payload: Array<ResourceModel>
    ) {
      state.engagementResources = payload;
    },
    SET_TENANT(state: ConfigurationState, payload: TenantModel) {
      state.tenant = payload;
    },
    SET_COLLABORATION_BRANCHES(
      state: ConfigurationState,
      payload: Array<CollaborationBranch>
    ) {
      state.collaborationBranches = payload;
    }
  };
}

export default new ConfigurationModule();
