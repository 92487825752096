import GainOverview from '../GainOverview.vue';
import GainBatches from '../GainBatches.vue';
import GainPromotions from '../GainPromotions.vue';
import GainRequirementsStatus from '../GainRequirementsStatus.vue';

export default {
  path: '/gain',
  name: 'GainOverview',
  redirect: { name: 'GainBatches' },
  component: GainOverview,
  meta: { menuItemName: 'Gain' },
  children: [
    {
      path: 'batches',
      name: 'GainBatches',
      component: GainBatches
    },
    {
      path: 'promotions',
      name: 'GainPromotions',
      component: GainPromotions
    },
    {
      path: 'requirements-status',
      name: 'GainRequirementsStatus',
      component: GainRequirementsStatus
    }
  ]
};
