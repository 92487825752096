<template>
  <el-row :gutter="spacingWidthHeaderComponents">
    <el-col>
      <el-row
        v-if="
          currentRequirementsStatus.requirementsStatus !=
          RequirementsStatus.SignedOff
        "
        class="d-flex"
      >
        <el-button
          type="primary"
          class="ml-auto"
          @click="showRequirementsStatusModal = true"
        >
          {{ $t('update') }}
        </el-button>
      </el-row>
      <el-row class="mt-2 d-block">
        <base-table
          :show-loading-table="loadingTable"
          :show-no-content-table="!requirementsStatusHistory.length"
          :error-message="$t('noHistoryToDisplay')"
        >
          <el-table
            v-if="requirementsStatusHistory.length"
            :data="requirementsStatusHistory"
          >
            <el-table-column :label="$t('timestamp')" width="200">
              <template #default="scope">
                <h4>{{ formatTimestamp(scope.row.updatedAt) }}</h4>
              </template>
            </el-table-column>
            <el-table-column :label="$t('requirementsStatus')" width="200">
              <template #default="scope">
                <h4>
                  {{
                    $t(
                      Object.keys(RequirementsStatus)[
                        scope.row.requirementsStatus
                      ]
                    )
                  }}
                </h4>
              </template>
            </el-table-column>
            <el-table-column :label="$t('updatedBy')" width="200">
              <template #default="scope">
                <h4>{{ scope.row.updatedBy }}</h4>
              </template>
            </el-table-column>
            <el-table-column :label="$t('message')">
              <template #default="scope">
                <h4>{{ scope.row.message }}</h4>
              </template>
            </el-table-column>
          </el-table>
        </base-table>
      </el-row>
    </el-col>
  </el-row>
  <update-requirements-status-modal
    v-model="showRequirementsStatusModal"
    :allowed-values="allowedRequirementsStatus"
    :feedback-text="$t('requirementsStatusSubmitted')"
    :current-status="currentRequirementsStatus"
    @confirm="updateRequirementsStatus"
  />
</template>
<script lang="ts">
import { ElButton, ElRow, ElCol, ElTable, ElTableColumn } from 'element-plus';
import { onMounted, computed, ComputedRef, ref } from 'vue';
import { useStore } from 'vuex';
import { spacingWidthHeaderComponents } from '@/utils/variables';
import BaseTable from '@/components/tables/BaseTable.vue';
import {
  EngagementRequirementsStatus,
  RequirementsStatus,
  UpdateRequirementsStatusRequest
} from '@etp/etp-gain-bff-client/axios';
import moment from 'moment';
import UpdateRequirementsStatusModal from './components/UpdateRequirementsStatusModal.vue';

export default {
  name: 'GainRequirementsStatus',
  components: {
    ElButton,
    ElCol,
    ElRow,
    ElTable,
    ElTableColumn,
    BaseTable,
    UpdateRequirementsStatusModal
  },
  setup() {
    const store = useStore();
    const showRequirementsStatusModal = ref(false);
    const loadingTable = ref(true);

    const currentRequirementsStatus: ComputedRef<EngagementRequirementsStatus> =
      computed(() => store.getters['gain/getCurrentRequirementsStatus']);
    const requirementsStatusHistory: ComputedRef<
      Array<EngagementRequirementsStatus>
    > = computed(() => store.getters['gain/getRequirementsStatusHistory']);
    const allowedRequirementsStatus = computed(
      () => store.getters['userPermissions/canSetGainRequirementsStatus']
    );

    const formatTimestamp = (input: Date) => {
      if (!input) return '';
      return moment(input).format('YYYY-MM-DD HH:mm:ss');
    };

    const updateRequirementsStatus = async (
      input: UpdateRequirementsStatusRequest
    ) => {
      await store.dispatch('gain/updateRequirementsStatus', input);
      await updateLocalVars();
      showRequirementsStatusModal.value = false;
    };

    const updateLocalVars = async () => {
      loadingTable.value = true;
      await store.dispatch('gain/fetchCurrentRequirementsStatus');
      await store.dispatch('gain/fetchRequirementsStatusHistory');
      await store.dispatch(
        'userPermissions/checkIfCanSetGainRequirementsStatus'
      );
      loadingTable.value = false;
    };

    onMounted(async () => await updateLocalVars());

    return {
      allowedRequirementsStatus,
      currentRequirementsStatus,
      formatTimestamp,
      loadingTable,
      RequirementsStatus,
      requirementsStatusHistory,
      showRequirementsStatusModal,
      spacingWidthHeaderComponents,
      updateRequirementsStatus
    };
  }
};
</script>
