import { StorageObject } from '@etp/etp-storage-client/axios';
import moment from 'moment';
import { decodeTime } from 'ulid';
import { Ulid } from 'id128';

const uuidRegex =
  /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/i;

export function formatFolders(input: StorageObject[]): StorageObject[] {
  input.forEach((folder: StorageObject) => {
    if (!folder.createdAt) {
      try {
        folder.createdAt = validateDate(
          moment(convertUuid(folder.actualName)).format()
        );
      } catch {
        folder.createdAt = '';
      }
    }
  });
  return input;
}

function convertUuid(id: string): Date | string {
  if (uuidRegex.test(id)) {
    return new Date(
      decodeTime(Ulid.fromRaw(id.split('-').slice(2).join('')).toCanonical())
    );
  }
  return '';
}

function validateDate(date: string): string {
  return moment(date).isValid() && moment(date).isBefore(moment()) ? date : '';
}
