<template>
  <el-form :model="form">
    <el-form-item prop="deployDataFactoryPrivateEndpoint" class="mb-0">
      <el-checkbox
        class="checkbox"
        :label="$t('deployDataFactoryPrivateEndpoint')"
        :model-value="form.deployDataFactoryPrivateEndpoint"
        @change="handleChangeDeployDataFactoryPrivateEndpoint"
      />
    </el-form-item>
    <el-form-item prop="addDataFactoryLinkedServices" class="mb-0">
      <el-checkbox
        class="checkbox"
        :label="$t('addDataFactoryLinkedServices')"
        :model-value="form.addDataFactoryLinkedServices"
        @change="handleChangeAddDataFactoryLinkedServices"
      />
    </el-form-item>
    <el-form-item :label="$t('managedIdentityName')" class="mb-0">
      <el-select
        v-model="form.managedIdentityName"
        size="small"
        :placeholder="$t('empty')"
        @change="handleChangeManagedIdentityName"
      >
        <el-option
          v-for="item in managedIdentityNames"
          :key="item"
          :label="item"
          :value="item"
        ></el-option>
      </el-select>
    </el-form-item>
    <el-form-item
      v-if="form.addDataFactoryLinkedServices"
      :label="$t('linkedServices')"
      class="mb-0"
    >
      <el-select
        v-model="form.linkedServiceResourceIds"
        multiple
        size="small"
        @change="handleChangeLinkedServices"
      >
        <el-option
          v-for="storage in storageAccounts"
          :key="storage.id!"
          :label="storage.name"
          :value="storage.id!"
        />
      </el-select>
    </el-form-item>
  </el-form>
</template>
<script lang="ts">
import {
  ElForm,
  ElFormItem,
  ElCheckbox,
  ElOption,
  ElSelect
} from 'element-plus';
import { DataFactoryConfiguration } from '@/views/admin/tenants/models/TenantAzureConfigurationObjects';
import { onMounted, onUpdated, ref } from 'vue';
import { ResourceModel } from '@etp/etp-infra-client';

export default {
  name: 'DataFactoryConfigurationForm',
  components: { ElForm, ElFormItem, ElCheckbox, ElOption, ElSelect },
  props: {
    configuration: { type: String, default: '' },
    managedIdentityNames: {
      type: Array as () => Array<string>,
      default: () => new Array<string>()
    },
    storageAccounts: {
      type: Array as () => Array<ResourceModel>,
      default: () => new Array<ResourceModel>()
    }
  },
  emits: ['update-configuration'],
  setup(props, { emit }) {
    let form = ref(new DataFactoryConfiguration());
    let localConfugrationObject = ref({});

    const emitUpdateEvent = () => {
      emit(
        'update-configuration',
        JSON.stringify({
          ...localConfugrationObject.value,
          ...form.value
        })
      );
    };

    const handleChangeDeployDataFactoryPrivateEndpoint = (
      newValue: boolean
    ) => {
      form.value.deployDataFactoryPrivateEndpoint = newValue;
      emitUpdateEvent();
    };

    const handleChangeAddDataFactoryLinkedServices = (newValue: boolean) => {
      form.value.addDataFactoryLinkedServices = newValue;
      emitUpdateEvent();
    };

    const handleChangeLinkedServices = (newValue: Array<string>) => {
      form.value.linkedServiceResourceIds = newValue;
      emitUpdateEvent();
    };

    const handleChangeManagedIdentityName = (newValue: string) => {
      form.value.managedIdentityName = newValue;
      emitUpdateEvent();
    };

    const updateLocalVars = () => {
      localConfugrationObject.value = JSON.parse(props.configuration || '{}');
      form.value.deployDataFactoryPrivateEndpoint =
        localConfugrationObject.value['deployDataFactoryPrivateEndpoint'] ??
        false;
      form.value.addDataFactoryLinkedServices =
        localConfugrationObject.value['addDataFactoryLinkedServices'] ?? false;
      form.value.managedIdentityName =
        localConfugrationObject.value['managedIdentityName'] ?? '';
      form.value.linkedServiceResourceIds =
        localConfugrationObject.value['linkedServiceResourceIds'] ?? [];
    };

    onMounted(() => {
      updateLocalVars();
      emitUpdateEvent();
    });
    onUpdated(() => updateLocalVars());

    return {
      form,
      handleChangeAddDataFactoryLinkedServices,
      handleChangeDeployDataFactoryPrivateEndpoint,
      handleChangeLinkedServices,
      handleChangeManagedIdentityName
    };
  }
};
</script>
<style scoped lang="scss"></style>
