<template>
  <el-tree
    ref="treeRef"
    :data="allNodes"
    show-checkbox
    node-key="id"
    :default-checked-keys="selectedNodes"
    :default-expanded-keys="selectedNodes"
    @check="handleChange"
  />
</template>
<script lang="ts">
import { onUpdated, ref } from 'vue';

import { ElTree } from 'element-plus';

import TreeNode from '@/components/types/treeNode';

export default {
  name: 'BaseTree',
  components: {
    ElTree
  },
  props: {
    allNodes: {
      type: Array as () => Array<TreeNode>,
      default: new Array<TreeNode>()
    },
    selectedNodes: {
      type: Array as () => Array<number>,
      default: new Array<number>()
    }
  },
  emits: ['change'],
  setup(props, { emit }) {
    const treeRef = ref<InstanceType<typeof ElTree>>();

    const handleChange = (data: TreeNode, treeState: any) => {
      const checkedValues: string[] = [];
      treeState.checkedNodes.forEach(node => {
        node.values.forEach(value => {
          if (!checkedValues.includes(value)) checkedValues.push(value);
        });
      });
      emit('change', checkedValues);
    };

    onUpdated(() => {
      treeRef.value?.setCheckedKeys(props.selectedNodes, false);
    });

    return {
      handleChange,
      treeRef
    };
  }
};
</script>

<style scoped lang="scss"></style>
