<template>
  <el-form :model="form">
    <el-form-item prop="createGatewayEntry" class="mb-0">
      <el-checkbox
        class="checkbox"
        :label="$t('createGatewayEntry')"
        :model-value="form.createGatewayEntry"
        @change="handleChangeCreateGatewayEntry"
      />
    </el-form-item>
    <el-form-item :label="$t('managedIdentityName')" class="mb-0">
      <el-select
        v-model="form.managedIdentityName"
        size="small"
        :placeholder="$t('empty')"
        @change="handleChangeManagedIdentityName"
      >
        <el-option
          v-for="item in managedIdentityNames"
          :key="item"
          :label="item"
          :value="item"
        ></el-option>
      </el-select>
    </el-form-item>
    <el-form-item :label="$t('storageAccountName')">
      <el-select
        v-model="form.storageAccountName"
        size="small"
        :placeholder="$t('empty')"
        @change="handleChangeStorageAccountName"
      >
        <el-option
          v-for="item in storageNames"
          :key="item"
          :label="item"
          :value="item"
        ></el-option>
      </el-select>
    </el-form-item>
  </el-form>
</template>
<script lang="ts">
import {
  ElForm,
  ElFormItem,
  ElCheckbox,
  ElOption,
  ElSelect
} from 'element-plus';
import { FunctionAppConfiguration } from '@/views/admin/tenants/models/TenantAzureConfigurationObjects';
import { onMounted, onUpdated, ref } from 'vue';

export default {
  name: 'FunctionAppConfigurationForm',
  components: { ElForm, ElFormItem, ElCheckbox, ElOption, ElSelect },
  props: {
    configuration: { type: String, default: '' },
    managedIdentityNames: {
      type: Array as () => Array<string>,
      default: () => new Array<string>()
    },
    storageNames: {
      type: Array as () => Array<string>,
      default: () => new Array<string>()
    }
  },
  emits: ['update-configuration'],
  setup(props, { emit }) {
    let form = ref(new FunctionAppConfiguration());
    let localConfugrationObject = ref({});

    const emitUpdateEvent = () => {
      emit(
        'update-configuration',
        JSON.stringify({
          ...localConfugrationObject.value,
          ...form.value
        })
      );
    };

    const handleChangeCreateGatewayEntry = (newValue: boolean) => {
      form.value.createGatewayEntry = newValue;
      emitUpdateEvent();
    };

    const handleChangeManagedIdentityName = (newValue: string) => {
      form.value.managedIdentityName = newValue;
      emitUpdateEvent();
    };

    const handleChangeStorageAccountName = (newValue: string) => {
      form.value.storageAccountName = newValue;
      emitUpdateEvent();
    };

    const updateLocalVars = () => {
      localConfugrationObject.value = JSON.parse(props.configuration || '{}');
      form.value.createGatewayEntry =
        localConfugrationObject.value['createGatewayEntry'] ?? false;
      form.value.managedIdentityName =
        localConfugrationObject.value['managedIdentityName'] ?? '';
      form.value.storageAccountName =
        localConfugrationObject.value['storageAccountName'] ?? '';
    };

    onMounted(() => {
      updateLocalVars();
      emitUpdateEvent();
    });
    onUpdated(() => updateLocalVars());

    return {
      form,
      handleChangeCreateGatewayEntry,
      handleChangeManagedIdentityName,
      handleChangeStorageAccountName
    };
  }
};
</script>
<style scoped lang="scss"></style>
