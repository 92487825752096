<template>
  <li class="list-item body-1">
    <div
      v-if="showImportStoragePages"
      class="body-1"
      @click="$router.push({ name: 'Import' })"
    >
      {{ $t('importFiles') }}
    </div>
    <div
      v-if="showPipelinePages"
      class="body-1"
      @click="$router.push({ name: 'ModelRun' })"
    >
      {{ $t('runDataModel') }}
    </div>
    <div
      v-if="showPipelinePages"
      class="body-1"
      @click="$router.push({ name: 'TestingRunOverview' })"
    >
      {{ $t('runTests') }}
    </div>
    <div
      v-if="showPipelinePages"
      class="body-1"
      @click="$router.push({ name: 'FilteringRun' })"
    >
      {{ $t('runPipeline') }}
    </div>
  </li>
</template>

<script lang="ts">
import { ComputedRef, computed } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'QuickActionPanel',
  setup() {
    const store = useStore();
    let showImportStoragePages: ComputedRef<boolean> = computed(
      () => store.getters['home/showImportStoragePages']
    );
    let showPipelinePages: ComputedRef<boolean> = computed(
      () => store.getters['home/showPipelinePages']
    );
    return { showImportStoragePages, showPipelinePages };
  }
};
</script>

<style lang="scss" scoped>
.list-item {
  list-style-type: none;
}
.body-1 {
  color: $etp-deloitte-blue-7;
  margin-top: 1.34em;
  cursor: pointer;
  &:hover {
    color: $etp-deloitte-blue-5;
    font-weight: 600;
    letter-spacing: 0.2px;
  }
}
</style>
