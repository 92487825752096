<template>
  <base-modal
    :modal-title="$t('addNewNode')"
    :confirm-text="$t('save')"
    :dialog-visible="showModal"
    width="400px"
    @confirm="confirm"
    @close="$emit('close')"
  >
    <template #content>
      <el-form ref="formRef" :model="form" :rules="formRules">
        <el-form-item prop="nodeName" :label="$t('name') + ':'">
          <el-input v-model="form.nodeName" size="small" />
        </el-form-item>
        <el-form-item
          v-if="!hideSchemaField"
          prop="dbSchema"
          :label="$t('dbSchema') + ':'"
        >
          <el-input v-model="form.dbSchema" size="small" />
        </el-form-item>
      </el-form>
    </template>
  </base-modal>
</template>
<script lang="ts">
import { ElForm, ElFormItem, ElInput, ElMessage } from 'element-plus';

import BaseModal from '@/components/modals/BaseModal.vue';
import i18n from '@/i18n';
import { onUpdated, reactive, ref } from 'vue';
import {
  mustBeAlphanumericDashUnderscore,
  mustNotBeEmpty
} from '@/utils/formValidators';
import { CreateNodeModel, NodeModel } from '@etp/etp-nodemodelgit-client/axios';
export default {
  name: 'NewNodeModal',
  components: {
    BaseModal,
    ElInput,
    ElForm,
    ElFormItem
  },
  props: {
    showModal: { type: Boolean, default: false },
    hideSchemaField: { type: Boolean, default: false },
    nodes: {
      type: Array as () => Array<NodeModel>,
      default: () => new Array<NodeModel>()
    }
  },
  emits: ['close', 'confirm'],
  setup(props, { emit }) {
    const mustNotContainDuplicateName = (rule, inputName: string, callback) => {
      const allNodes = props.nodes;
      allNodes.find(o => {
        if (o.name == inputName) {
          callback(new Error(i18n.global.t('noDuplicateNodeName')));
          return;
        }
      });
      callback();
    };

    let form = ref({
      nodeName: '',
      dbSchema: undefined
    });
    let formRules = reactive({
      nodeName: [
        {
          validator: mustNotBeEmpty,
          trigger: 'blur'
        },
        {
          validator: mustBeAlphanumericDashUnderscore,
          trigger: 'change'
        },
        {
          validator: mustNotContainDuplicateName,
          trigger: 'change'
        }
      ]
    });

    const formRef = ref<HTMLFormElement>();
    const confirm = () => {
      formRef.value?.validate((valid: boolean) => {
        if (!valid) return;
        emit('confirm', {
          name: form.value.nodeName,
          dbSchema: form.value.dbSchema
        } as CreateNodeModel);
        ElMessage({
          showClose: true,
          message: i18n.global.t('newNodeAdded')
        });
      });
    };

    onUpdated(() => {
      formRef.value?.resetFields();
    });

    return { confirm, form, formRef, formRules };
  }
};
</script>
