<template>
  <div class="contract-wrapper">
    <router-view :key="$route.fullPath" />
  </div>
</template>
<script lang="ts">
export default {
  name: 'ContractsOverview'
};
</script>
<style lang="scss" scoped>
.contract-wrapper {
  padding: 100px 28px 28px;
  width: 100%;
}
</style>
