<template>
  <base-modal
    width="400px"
    :modal-title="permission.name ? $t('editPermission') : $t('newPermission')"
    :dialog-visible="modelValue"
    :confirm-text="$t('save')"
    @confirm="confirm"
    @close="handleClose"
  >
    <template #content>
      <el-form
        ref="formRef"
        :model="form"
        :rules="formRules"
        label-position="top"
      >
        <el-form-item :label="$t('name')" prop="name">
          <el-input
            v-model="form.name"
            :disabled="permission.name"
            size="small"
          />
        </el-form-item>
        <el-form-item :label="$t('category')" prop="category">
          <el-input v-model="form.category" size="small" />
        </el-form-item>
        <el-form-item :label="$t('description')" prop="description">
          <el-input v-model="form.description" size="small" />
        </el-form-item>
      </el-form>
    </template>
  </base-modal>
</template>
<script lang="ts">
import BaseModal from '@/components/modals/BaseModal.vue';

import { ElForm, ElFormItem, ElInput } from 'element-plus';

import { onUpdated, reactive, ref } from 'vue';
import i18n from '@/i18n';
import { PermissionModel } from '@etp/etp-authorization-client/axios';
import { mustBeAlphanumericDot, mustNotBeEmpty } from '@/utils/formValidators';

export default {
  name: 'CreateOrUpdatePermissionModal',
  components: {
    BaseModal,
    ElInput,
    ElForm,
    ElFormItem
  },
  props: {
    modelValue: { type: Boolean, default: false },
    permission: {
      type: Object as () => PermissionModel,
      default: {} as PermissionModel
    }
  },
  emits: ['close', 'confirm'],
  setup(props, { emit }) {
    const form = reactive({
      name: '',
      description: '',
      category: ''
    });
    const formRef = ref<HTMLFormElement>();
    const formRules = reactive({
      name: [
        {
          required: true,
          message: i18n.global.t('pleaseProvideName'),
          trigger: ['change', 'blur']
        },
        {
          validator: mustBeAlphanumericDot,
          trigger: ['change', 'blur']
        }
      ],
      category: [
        { validator: mustNotBeEmpty, trigger: 'blur' },
        { validator: mustBeAlphanumericDot, trigger: ['change', 'blur'] }
      ]
    });

    let confirm = () => {
      formRef.value?.validate((valid: boolean) => {
        if (valid) {
          emit('confirm', {
            name: form.name,
            category: form.category,
            description: form.description
          } as PermissionModel);
        }
      });
    };

    onUpdated(() => {
      form.name = props.permission.name || '';
      form.category = props.permission.category || '';
      form.description = props.permission.description || '';
    });

    const handleClose = () => {
      emit('close');
    };

    return {
      confirm,
      form,
      formRef,
      formRules,
      handleClose
    };
  }
};
</script>
<style scoped lang="scss"></style>
