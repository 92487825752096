<template>
  <base-modal
    width="500px"
    :modal-title="$t('addNewFolder')"
    :confirm-text="$t('save')"
    :dialog-visible="dialogVisible"
    @confirm="confirm()"
    @close="handleClose"
  >
    <template #content>
      <el-form
        ref="formRef"
        :model="form"
        label-position="top"
        :rules="formRules"
        :inline="true"
      >
        <el-form-item :label="$t('name')" prop="name">
          <el-input v-model="form.name" size="small" />
        </el-form-item>
      </el-form>
    </template>
  </base-modal>
</template>

<script lang="ts">
import { ElForm, ElFormItem, ElInput, ElMessage } from 'element-plus';
import BaseModal from '@/components/modals/BaseModal.vue';
import { onUpdated, reactive, ref } from 'vue';
import i18n from '@/i18n';
import { CreateOrUpdateFolderDto } from '@etp/etp-storage-client/axios';
import { mustNotContainSpecialChars } from '@/utils/formValidators';

export default {
  name: 'CreateFolderModal',
  components: { BaseModal, ElForm, ElFormItem, ElInput },
  props: {
    dialogVisible: { type: Boolean }
  },
  emits: ['confirm', 'close'],
  setup(_, { emit }) {
    const form = ref({
      name: ''
    });

    const formRules = reactive({
      name: [
        {
          required: true,
          message: i18n.global.t('thisFieldIsRequired'),
          trigger: 'blur'
        },
        {
          validator: mustNotContainSpecialChars,
          trigger: 'blur'
        }
      ]
    });

    const formRef = ref<HTMLFormElement>();
    const confirm = () => {
      formRef.value?.validate((valid: boolean) => {
        if (valid) {
          emit('confirm', {
            displayName: form.value.name,
            metadata: {}
          } as CreateOrUpdateFolderDto);
          ElMessage({
            showClose: true,
            message: i18n.global.t('folderAdded')
          });
        }
      });
    };

    onUpdated(() => {
      formRef.value?.resetFields();
    });

    const handleClose = () => {
      emit('close');
    };

    return {
      confirm,
      form,
      formRef,
      formRules,
      handleClose
    };
  }
};
</script>
