<template>
  <el-dialog
    v-if="!eulaAccepted"
    :model-value="showEulaModal"
    :show-close="false"
    :title="$t('EULA')"
    :close-on-click-modal="false"
  >
    <div class="eula-body">
      <eula-text />
    </div>
    <template #footer>
      <div class="eula-footer">
        <el-checkbox
          v-model="eulaTermsAccepted"
          :label="$t('acceptTerms')"
        ></el-checkbox>
        <el-button
          type="primary"
          :disabled="!eulaTermsAccepted"
          @click="acceptEula"
        >
          {{ $t('accept') }}
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script lang="ts">
import { computed, onMounted, ComputedRef, ref } from 'vue';
import { useStore } from 'vuex';
import router from '@/router';

import { ElButton, ElDialog, ElCheckbox } from 'element-plus';

import EulaText from './partials/EulaText.vue';
import { UserViewModel } from '@etp/etp-profile-client/axios';

export default {
  name: 'EulaPage',
  components: {
    ElButton,
    ElDialog,
    ElCheckbox,
    EulaText
  },
  setup() {
    const store = useStore();

    const currentUser: ComputedRef<UserViewModel> = computed(
      () => store.getters['base/getCurrentUser']
    );
    let eulaAccepted: ComputedRef<boolean> = computed(() => {
      return !!currentUser.value.eulaAcceptedDate;
    });

    let showEulaModal = ref(!eulaAccepted.value);
    let eulaTermsAccepted = ref();
    let acceptEula = async () => {
      await store.dispatch('base/hasAcceptedEula');
      await store.dispatch('base/fetchCurrentUser');
      router.push({ name: 'Overview' });
    };

    const redirectToEulaWhenNotAccepted = () => {
      if (eulaAccepted.value) {
        router.push({ name: 'Overview' });
      }
    };

    onMounted(async () => {
      await store.dispatch('base/fetchCurrentUser');
      redirectToEulaWhenNotAccepted();
    });

    return {
      currentUser,
      showEulaModal,
      eulaAccepted,
      eulaTermsAccepted,
      acceptEula
    };
  }
};
</script>
<style scoped lang="scss">
.eula-body {
  max-height: 470px;
  overflow-y: scroll;
  max-width: 800px;
}
.eula-footer {
  display: flex;
  justify-content: space-between;
}
</style>
