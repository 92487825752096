<template>
  <el-switch
    :inactive-text="inactiveText"
    :active-text="activeText"
    :model-value="modelValue"
    :loading="loading"
    @change="handleSwitch"
  />
</template>

<script lang="ts">
import { ElSwitch, ElMessage } from 'element-plus';
import i18n from '@/i18n';

export default {
  name: 'BaseToggleButton',
  components: { ElSwitch },
  props: {
    modelValue: { type: Boolean, required: true },
    loading: { type: Boolean, default: false },
    inactiveText: { type: String, default: '' },
    activeText: { type: String, default: '' },
    indicatorText: { type: String, default: i18n.global.t('toggleClicked') }
  },
  emits: ['update:model-value'],
  setup(props, { emit }) {
    const handleSwitch = (val: boolean): void => {
      emit('update:model-value', val);
      ElMessage({
        showClose: true,
        message: props.indicatorText
      });
    };
    return { handleSwitch };
  }
};
</script>
<style scoped lang="scss"></style>
