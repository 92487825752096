<template>
  <table-placeholder-loading v-if="showLoadingTable" :columns="columns" />
  <table-placeholder
    v-else-if="showNoContentTable"
    :error-message="errorMessage"
  />
  <slot v-else></slot>
</template>

<script lang="ts">
import i18n from '@/i18n';

import TablePlaceholderLoading from './TablePlaceholderLoading.vue';
import TablePlaceholder from './TablePlaceholder.vue';
export default {
  name: 'BaseTable',
  components: { TablePlaceholder, TablePlaceholderLoading },
  props: {
    showLoadingTable: { type: Boolean, required: false, default: false },
    showNoContentTable: { type: Boolean, required: false, default: false },
    errorMessage: {
      type: String,
      required: false,
      default: () => i18n.global.t('noData')
    },
    columns: { type: Number, required: false, default: 2 }
  }
};
</script>

<style lang="scss"></style>
