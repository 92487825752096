import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_building_panel = _resolveComponent("base-building-panel")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_base_building_table = _resolveComponent("base-building-table")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createBlock(_component_el_row, { gutter: $setup.spacingWidthHeaderComponents }, {
    default: _withCtx(() => [
      _createVNode(_component_el_col, { span: 5 }, {
        default: _withCtx(() => [
          _createVNode(_component_base_building_panel, {
            selectedBranch: $setup.selectedBranch,
            "onUpdate:selectedBranch": _cache[0] || (_cache[0] = ($event: any) => (($setup.selectedBranch) = $event)),
            branches: $setup.branches,
            "loading-branches": $setup.loadingBranches
          }, null, 8, ["selectedBranch", "branches", "loading-branches"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_col, { span: 19 }, {
        default: _withCtx(() => [
          _createVNode(_component_base_building_table, {
            modelValue: $setup.nodes,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.nodes) = $event)),
            "show-build-button": $setup.userCanStartEngagementPipelines,
            "loading-nodes": $setup.loadingNodes,
            onStartPipelineRun: $setup.startPipelineRun
          }, null, 8, ["modelValue", "show-build-button", "loading-nodes", "onStartPipelineRun"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["gutter"]))
}