import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_permissions_tree = _resolveComponent("permissions-tree")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_base_modal = _resolveComponent("base-modal")!

  return (_openBlock(), _createBlock(_component_base_modal, {
    width: "400px",
    "modal-title": $props.role.name ? _ctx.$t('editRole') : _ctx.$t('newRole'),
    "dialog-visible": $props.modelValue,
    "confirm-text": _ctx.$t('save'),
    onConfirm: _cache[3] || (_cache[3] = ($event: any) => ($setup.confirm())),
    onClose: $setup.handleClose
  }, {
    content: _withCtx(() => [
      _createVNode(_component_el_form, {
        ref: "formRef",
        model: $setup.form,
        rules: $setup.formRules,
        "label-position": "top"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, {
            label: _ctx.$t('name'),
            prop: "name"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: $setup.form.name,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.form.name) = $event)),
                size: "small"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_form_item, {
            label: _ctx.$t('scopeMask'),
            prop: "scopeMask"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: $setup.form.scopeMask,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.form.scopeMask) = $event)),
                size: "small",
                placeholder: "/tenants/{tenantId}"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_form_item, {
            label: _ctx.$t('description'),
            prop: "description"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: $setup.form.description,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.form.description) = $event)),
                size: "small"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_form_item, {
            label: _ctx.$t('permissions'),
            prop: "permissions"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_permissions_tree, {
                "all-permissions": $setup.stringPermissions,
                "existing-permissions": $setup.form.permissions,
                delimiter: ".",
                onChange: $setup.handleSelectedPermissions
              }, null, 8, ["all-permissions", "existing-permissions", "onChange"])
            ]),
            _: 1
          }, 8, ["label"])
        ]),
        _: 1
      }, 8, ["model", "rules"])
    ]),
    _: 1
  }, 8, ["modal-title", "dialog-visible", "confirm-text", "onClose"]))
}