<template>
  <el-form :model="form">
    <el-form-item prop="deployKeyVaultPrivateEndpoint" class="mb-0">
      <el-checkbox
        class="checkbox"
        :label="$t('deployKeyVaultPrivateEndpoint')"
        :model-value="form.deployKeyVaultPrivateEndpoint"
        @change="handleChangeDeployKeyVaultPrivateEndpoint"
      />
    </el-form-item>
    <el-form-item :label="$t('managedIdentityNames')">
      <el-select
        v-model="form.managedIdentityNames"
        size="small"
        :placeholder="$t('empty')"
        multiple
        @change="handleChangeManagedIdentityNames"
      >
        <el-option
          v-for="item in props.managedIdentityNames"
          :key="item"
          :label="item"
          :value="item"
        />
      </el-select>
    </el-form-item>
  </el-form>
</template>
<script lang="ts">
import {
  ElCheckbox,
  ElForm,
  ElFormItem,
  ElOption,
  ElSelect
} from 'element-plus';
import { KeyVaultConfiguration } from '@/views/admin/tenants/models/TenantAzureConfigurationObjects';
import { onMounted, onUpdated, ref } from 'vue';

export default {
  name: 'KeyVaultConfigurationCard',
  components: { ElCheckbox, ElForm, ElFormItem, ElOption, ElSelect },
  props: {
    configuration: { type: String, default: '' },
    managedIdentityNames: {
      type: Array as () => Array<string>,
      default: () => new Array<string>()
    }
  },
  emits: ['update-configuration'],
  setup(props, { emit }) {
    let form = ref(new KeyVaultConfiguration());
    let localConfigurationObject = ref({});

    const emitUpdateEvent = () => {
      emit(
        'update-configuration',
        JSON.stringify({
          ...localConfigurationObject.value,
          ...form.value
        })
      );
    };

    const handleChangeDeployKeyVaultPrivateEndpoint = (newValue: boolean) => {
      form.value.deployKeyVaultPrivateEndpoint = newValue;
      emitUpdateEvent();
    };

    const handleChangeManagedIdentityNames = (newValue: Array<string>) => {
      form.value.managedIdentityNames = newValue;
      emitUpdateEvent();
    };

    const updateLocalVars = () => {
      localConfigurationObject.value = JSON.parse(props.configuration || '{}');
      form.value.deployKeyVaultPrivateEndpoint =
        localConfigurationObject.value['deployKeyVaultPrivateEndpoint'] ??
        false;
      form.value.managedIdentityNames =
        localConfigurationObject.value['managedIdentityNames'] ?? [];
    };

    onMounted(() => {
      updateLocalVars();
      emitUpdateEvent();
    });
    onUpdated(() => updateLocalVars());

    return {
      form,
      handleChangeDeployKeyVaultPrivateEndpoint,
      handleChangeManagedIdentityNames,
      props
    };
  }
};
</script>
