<template>
  <el-form
    ref="formRef"
    :model="form"
    :rules="formRules"
    label-position="top"
    :disabled="deploymentStatusIsInProgress"
  >
    <el-row>
      <el-col :span="12" class="d-flex pt-1">
        <el-button
          class="ml-auto"
          type="primary"
          :disabled="deploymentStatusIsInProgress"
          @click="showEditConfigurationModal = true"
        >
          <el-tooltip
            :content="$t('PageLockedBecauseDeploymentInProgress')"
            :disabled="!deploymentStatusIsInProgress"
          >
            <h4>{{ $t('configuration') }}</h4>
          </el-tooltip>
        </el-button>
        <el-button
          class="ml-2"
          type="primary"
          :disabled="deploymentStatusIsInProgress"
          @click="updateTenantGeneralInfo"
        >
          <el-tooltip
            :content="$t('PageLockedBecauseDeploymentInProgress')"
            :disabled="!deploymentStatusIsInProgress"
          >
            <h4>{{ $t('save') }}</h4>
          </el-tooltip>
        </el-button>
        <el-button
          v-if="tenant.isRemovable"
          class="ml-2"
          type="primary"
          :disabled="deploymentStatusIsInProgress"
          @click="openConfirmationModal"
        >
          <h4>{{ $t('delete') }}</h4>
        </el-button>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12" class="mt-2">
        <el-card class="cursor-default">
          <el-form-item :label="$t('name')" prop="name">
            <el-tooltip
              :content="$t('PageLockedBecauseDeploymentInProgress')"
              :disabled="!deploymentStatusIsInProgress"
            >
              <el-input
                v-model="form.name"
                :disabled="deploymentStatusIsInProgress"
                size="small"
              />
            </el-tooltip>
          </el-form-item>
          <el-form-item :label="$t('tenantId')" prop="id">
            <div class="body-1 form-item-readonly">
              {{ tenant.id }}
            </div>
          </el-form-item>
          <el-form-item :label="$t('cloudProvider')" prop="cloudProvider">
            <div class="body-1 form-item-readonly">
              {{ getCloudProvider(tenant.cloudProvider ?? 0) }}
            </div>
          </el-form-item>
          <el-form-item :label="$t('subscriptionId')" prop="subscriptionId">
            <el-tooltip
              :content="$t('PageLockedBecauseDeploymentInProgress')"
              :disabled="!deploymentStatusIsInProgress"
            >
              <el-input
                v-model="form.subscriptionId"
                :disabled="deploymentStatusIsInProgress"
                size="small"
              />
            </el-tooltip>
          </el-form-item>
          <el-form-item
            :label="$t('resourceGroupName')"
            prop="resourceGroupName"
          >
            <el-tooltip
              :content="$t('PageLockedBecauseDeploymentInProgress')"
              :disabled="!deploymentStatusIsInProgress"
            >
              <el-input
                v-model="form.resourceGroupName"
                :disabled="deploymentStatusIsInProgress"
                size="small"
              >
                <template #prepend>
                  {{ resourceGroupPrefix }}
                </template>
              </el-input>
            </el-tooltip>
          </el-form-item>
        </el-card>
      </el-col>
    </el-row>
  </el-form>
  <edit-configuration-modal
    v-model="showEditConfigurationModal"
    :configuration="tenant.cloudConfiguration ?? ''"
    @confirm="handleConfigurationUpdate"
    @close="showEditConfigurationModal = false"
  />
  <base-confirmation-modal
    v-model="showBaseConfirmationModal"
    :content="$t('confirmDeleteTenant')"
    :feedback-text="$t('tenantDeleted')"
    @confirm="deleteTenant"
    @close="showBaseConfirmationModal = false"
  />
</template>
<script lang="ts">
import {
  ElButton,
  ElCard,
  ElCol,
  ElForm,
  ElFormItem,
  ElMessage,
  ElInput,
  ElRow,
  ElTooltip
} from 'element-plus';
import { computed, ComputedRef, onMounted, ref, reactive } from 'vue';
import { useRouter } from 'vue-router';
import store from '@/store';
import { mustBeGuid } from '@/utils/formValidators';
import i18n from '@/i18n';
import formatResourceName from './helpers/formatResourceName';
import EditConfigurationModal from './components/EditConfigurationModal.vue';
import {
  CloudProvider,
  TenantModel,
  UpdateTenantModel
} from '@etp/etp-infra-client';
import BaseConfirmationModal from '@/components/modals/BaseConfirmationModal.vue';

export default {
  name: 'TenantGeneral',
  components: {
    EditConfigurationModal,
    ElRow,
    ElCol,
    ElButton,
    ElInput,
    ElForm,
    ElFormItem,
    ElCard,
    BaseConfirmationModal,
    ElTooltip
  },
  setup() {
    const router = useRouter();
    let tenantId: string;
    const showEditConfigurationModal = ref<boolean>(false);
    const showBaseConfirmationModal = ref<boolean>(false);

    const deploymentStatusIsInProgress: ComputedRef<boolean> = computed(
      () =>
        store.getters['tenants/getActiveTenant']?.deploymentStatus == 1 ?? false
    );

    let tenant: ComputedRef<TenantModel> = computed(
      () => store.getters['tenants/getActiveTenant'] as TenantModel
    );

    const resourceGroupPrefix = formatResourceName.getResourceGroupPrefix();

    const getCloudProvider = (num: number): string => {
      switch (num) {
        case CloudProvider.MicrosoftAzure:
          return i18n.global.t('microsoftAzure');
        case CloudProvider.AmazonAws:
          return i18n.global.t('amazonAws');
      }
      return '';
    };

    const form = ref({
      name: '',
      subscriptionId: '',
      resourceGroupName: ''
    });
    const formRef = ref<HTMLFormElement>();
    const formRules = reactive({
      name: [
        {
          required: true,
          message: i18n.global.t('thisFieldIsRequired'),
          trigger: 'blur'
        },
        {
          min: 3,
          message: i18n.global
            .t('pleaseEnterNumCharacters')
            .replace('[num]', '3'),
          trigger: 'blur'
        }
      ],
      subscriptionId: [
        {
          validator: mustBeGuid,
          trigger: 'blur'
        }
      ],
      resourceGroupName: [
        {
          min: 3,
          message: i18n.global.t('mustBeAtLeastThreeCharactersLong'),
          trigger: 'blur'
        },
        {
          max: 24,
          message: i18n.global.t('mustBeShorterThan24'),
          trigger: 'blur'
        }
      ]
    });

    const updateTenantGeneralInfo = () => {
      formRef.value?.validate(async (valid: boolean) => {
        if (valid) {
          await store.dispatch('tenants/updateTenant', {
            tenantId: tenantId,
            model: {
              name: form.value.name,
              cloudConfiguration: JSON.stringify({
                SubscriptionId: form.value.subscriptionId,
                ResourceGroupName: `${resourceGroupPrefix}${form.value.resourceGroupName}`
              })
            } as UpdateTenantModel
          });
          await updateLocalVars();
          ElMessage({
            showClose: true,
            message: i18n.global.t('tenantInfoSaved')
          });
        }
      });
    };

    const updateLocalVars = async () => {
      tenantId = router.currentRoute.value.params.tenantId as string;
      await store.dispatch('tenants/fetchTenant', { tenantId });
      form.value.name = tenant.value.name ?? '';
      let tenantConfiguration = JSON.parse(
        tenant.value.cloudConfiguration ?? '{}'
      );
      form.value.subscriptionId = tenantConfiguration['SubscriptionId'] ?? '';
      form.value.resourceGroupName =
        formatResourceName.removeResourceGroupPrefix(
          tenantConfiguration['ResourceGroupName'] ?? ''
        );
    };

    const handleConfigurationUpdate = (newConfiguration: string) => {
      let tenantConfiguration = JSON.parse(newConfiguration ?? '{}');
      form.value.subscriptionId = tenantConfiguration['SubscriptionId'] ?? '';
      form.value.resourceGroupName =
        formatResourceName.removeResourceGroupPrefix(
          tenantConfiguration['ResourceGroupName'] ?? ''
        );
      updateTenantGeneralInfo();
      showEditConfigurationModal.value = false;
    };

    const openConfirmationModal = () =>
      (showBaseConfirmationModal.value = true);

    const deleteTenant = async () => {
      await store.dispatch('tenants/deleteTenant', {
        tenantId: tenant.value.id
      });

      router.push({ name: 'TenantsOverview' });
    };

    onMounted(() => {
      updateLocalVars();
    });

    return {
      deploymentStatusIsInProgress,
      form,
      formRef,
      formRules,
      getCloudProvider,
      handleConfigurationUpdate,
      resourceGroupPrefix,
      showEditConfigurationModal,
      tenant,
      updateTenantGeneralInfo,
      deleteTenant,
      showBaseConfirmationModal,
      openConfirmationModal
    };
  }
};
</script>
<style lang="scss" scoped>
.form-item-readonly {
  padding: 9px 12px;
  width: 100%;
  color: $etp-shade-2;
}
.form-item-disabled {
  padding: 9px 12px;
  width: 100%;
  color: $etp-input-is-disabled;
  cursor: not-allowed;
}
</style>
