import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, { model: $setup.form }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form_item, {
        prop: "deployTopicPrivateEndpoint",
        class: "mb-0"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_checkbox, {
            class: "checkbox",
            label: _ctx.$t('deployTopicPrivateEndpoint'),
            "model-value": $setup.form.deployTopicPrivateEndpoint,
            onChange: $setup.handleChangeDeployTopicPrivateEndpoint
          }, null, 8, ["label", "model-value", "onChange"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model"]))
}