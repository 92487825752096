import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_table_placeholder_loading = _resolveComponent("table-placeholder-loading")!
  const _component_tenant_compose_card = _resolveComponent("tenant-compose-card")!
  const _component_new_resource_modal = _resolveComponent("new-resource-modal")!
  const _component_tenant_resource_template_modal = _resolveComponent("tenant-resource-template-modal")!
  const _component_base_confirmation_modal = _resolveComponent("base-confirmation-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_row, { class: "d-flex" }, {
      default: _withCtx(() => [
        _createElementVNode("h4", _hoisted_1, _toDisplayString($setup.resources.length) + " · " + _toDisplayString(_ctx.$t('azureComponents')), 1),
        _createVNode(_component_el_button, {
          type: "primary",
          class: "ml-auto",
          disabled: $setup.tenant.deploymentStatus === $setup.DeploymentStatus.InProgress,
          onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.showNewResourceModal = true))
        }, {
          default: _withCtx(() => [
            _createElementVNode("h4", null, _toDisplayString(_ctx.$t('new')), 1)
          ]),
          _: 1
        }, 8, ["disabled"]),
        _createVNode(_component_el_button, {
          type: "primary",
          disabled: $setup.tenant.deploymentStatus === $setup.DeploymentStatus.InProgress,
          onClick: $setup.saveResources
        }, {
          default: _withCtx(() => [
            _createElementVNode("h4", null, _toDisplayString(_ctx.$t('save')), 1)
          ]),
          _: 1
        }, 8, ["disabled", "onClick"]),
        _createVNode(_component_el_button, {
          type: "primary",
          disabled: $setup.tenant.deploymentStatus === $setup.DeploymentStatus.InProgress,
          onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.showStartDeploymentModal = true))
        }, {
          default: _withCtx(() => [
            _createElementVNode("h4", null, _toDisplayString(_ctx.$t('deploy')), 1)
          ]),
          _: 1
        }, 8, ["disabled"]),
        ($setup.resources.length <= 0)
          ? (_openBlock(), _createBlock(_component_el_button, {
              key: 0,
              type: "primary",
              disabled: $setup.tenant.deploymentStatus === $setup.DeploymentStatus.InProgress,
              onClick: _cache[2] || (_cache[2] = ($event: any) => ($setup.showTemplateModal = true))
            }, {
              default: _withCtx(() => [
                _createElementVNode("h4", null, _toDisplayString(_ctx.$t('importTemplate')), 1)
              ]),
              _: 1
            }, 8, ["disabled"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    ($setup.tenantResourcesLoading)
      ? (_openBlock(), _createBlock(_component_table_placeholder_loading, {
          key: 0,
          columns: 2
        }))
      : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList($setup.resources, (resource, index) => {
          return (_openBlock(), _createBlock(_component_el_row, {
            key: index,
            class: "mt-1"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_tenant_compose_card, {
                resources: $setup.resources,
                "deployment-in-progress": 
        $setup.tenant.deploymentStatus === $setup.DeploymentStatus.InProgress
      ,
                resource: resource,
                onUpdate: (configuration) => $setup.updateConfiguration(index, configuration),
                onDelete: ($event: any) => ($setup.deleteResource(index))
              }, null, 8, ["resources", "deployment-in-progress", "resource", "onUpdate", "onDelete"])
            ]),
            _: 2
          }, 1024))
        }), 128)),
    _createVNode(_component_new_resource_modal, {
      "dialog-visible": $setup.showNewResourceModal,
      onConfirm: $setup.addResource,
      onClose: _cache[3] || (_cache[3] = ($event: any) => ($setup.showNewResourceModal = false))
    }, null, 8, ["dialog-visible", "onConfirm"]),
    _createVNode(_component_tenant_resource_template_modal, {
      modelValue: $setup.showTemplateModal,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.showTemplateModal) = $event)),
      onClose: _cache[5] || (_cache[5] = ($event: any) => ($setup.showTemplateModal = false)),
      onConfirm: $setup.createTemplateResources
    }, null, 8, ["modelValue", "onConfirm"]),
    _createVNode(_component_base_confirmation_modal, {
      modelValue: $setup.showStartDeploymentModal,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($setup.showStartDeploymentModal) = $event)),
      content: _ctx.$t('confirmStartDeployment'),
      width: "600px",
      "feedback-text": _ctx.$t('deploymentInProgress'),
      onConfirm: $setup.startDeployment,
      onClose: _cache[7] || (_cache[7] = ($event: any) => ($setup.showStartDeploymentModal = false))
    }, null, 8, ["modelValue", "content", "feedback-text", "onConfirm"])
  ], 64))
}