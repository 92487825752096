import userService from '@/services/web-api/user';
import { ActionContext, Module } from 'vuex';
import i18n from '@/i18n';
import { createProfileApiClient } from '@/services/api';
import {
  CreateOrUpdateUserDto,
  UserViewModel
} from '@etp/etp-profile-client/axios';
import { PicureSizes } from '@/services/web-api/enums/pictureSizes';

interface BaseState {
  currentUser: UserViewModel;
  profilePicture?: string;
  fullScreen: boolean;
}

class BaseModule implements Module<BaseState, any> {
  namespaced = true;
  state = {
    currentUser: {
      displayName: '',
      email: '',
      eulaAcceptedDate: '',
      jobTitle: '',
      language: '',
      lastLoginDate: '',
      profileImage: '',
      templateName: localStorage.getItem('data-theme')
    } as UserViewModel,
    profilePicture: undefined,
    fullScreen: false
  };
  getters = {
    getCurrentUser: (state: BaseState): UserViewModel => state.currentUser,
    getProfilePicture: (state: BaseState): string | undefined =>
      state.profilePicture,
    getFullScreen: (state: BaseState): boolean => state.fullScreen
  };
  actions = {
    async fetchCurrentUser(context: ActionContext<BaseState, any>) {
      const client = createProfileApiClient();
      let { data, status } = await client.getMyUser();

      if (status >= 400) {
        await client.createOrUpdateMyUser({
          language: 'en',
          displayName: 'Guest',
          templateName: 'Deloitte'
        } as CreateOrUpdateUserDto);
        let response = await client.getMyUser();
        data = response.data;
      }
      if (data.language) {
        i18n.global.locale.value = data.language.split('-')[0].toLowerCase();
      }
      if (data.templateName) {
        localStorage.setItem('data-theme', data.templateName);
      }
      context.commit('SET_CURRENT_USER', data);
    },

    async fetchProfilePicture(
      context: ActionContext<BaseState, any>,
      dimentions = PicureSizes.small
    ) {
      const { data, status } = await userService.getProfilePicture(dimentions);
      if (status >= 300) {
        context.commit('SET_PROFILE_PICTURE', undefined);
        return;
      }

      const blob = new Blob([data], { type: 'image/jpg' });
      const blobURL = window.URL.createObjectURL(blob);

      context.commit('SET_PROFILE_PICTURE', blobURL);
    },
    async hasAcceptedEula() {
      let client = createProfileApiClient();
      await client.acceptEula();
    },
    async updateUserTemplate(
      context: ActionContext<BaseState, any>,
      payload: string
    ) {
      document.documentElement.setAttribute('data-theme', payload);
      localStorage.setItem('data-theme', payload);
      const client = createProfileApiClient();
      await client.createOrUpdateMyUser({
        templateName: payload,
        displayName: context.state.currentUser.displayName,
        language: context.state.currentUser.language
      } as CreateOrUpdateUserDto);
    },
    async updateUserLanguage(
      context: ActionContext<BaseState, any>,
      payload: string
    ) {
      const client = createProfileApiClient();
      await client.createOrUpdateMyUser({
        templateName: context.state.currentUser.templateName,
        displayName: context.state.currentUser.displayName,
        language: payload
      } as CreateOrUpdateUserDto);
    }
  };
  mutations = {
    SET_CURRENT_USER(state: BaseState, payload: UserViewModel) {
      state.currentUser = payload;
    },
    SET_PROFILE_PICTURE(state: BaseState, payload: string) {
      state.profilePicture = payload;
    },
    SET_FULL_SCREEN(state: BaseState, payload: boolean) {
      state.fullScreen = payload;
    }
  };
}

export default new BaseModule();
