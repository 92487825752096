<template>
  <el-row>
    <page-header :main-title="$t('applications')">
      <template #subheader>
        <h4 class="subtitle-2 mt-3">
          {{ $t('applicationsDesc') }}
        </h4>
      </template>
    </page-header>
  </el-row>
  <el-row>
    <el-button type="primary" class="ml-auto" @click="openApplicationsModal()">
      <h4>+ {{ $t('newApplication') }}</h4>
    </el-button>
  </el-row>
  <applications-table
    :applications-data="applications"
    :all-applications-count="allApplicationsCount"
    @delete-application="deleteApplication"
  />
  <pagination
    :count="allApplicationsCount"
    :page="pageNumber"
    :page-size="pageSize"
    @change-page="changePage"
  />
  <new-application-modal
    v-model="showApplicationsModal"
    :feedback-text="$t('applicationInfoSaved')"
    @confirm="createApplication"
    @close="showApplicationsModal = false"
  />
</template>

<script lang="ts">
import { ElRow, ElButton } from 'element-plus';
import PageHeader from '@/components/misc/PageHeader.vue';
import ApplicationsTable from './components/ApplicationsTable.vue';
import { computed, ComputedRef, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import {
  ApplicationModel,
  CreateOrUpdateApplicationModel
} from '@etp/etp-authorization-client/axios';
import NewApplicationModal from './components/NewApplicationModal.vue';
import Pagination from '@/components/misc/Pagination.vue';

export default {
  name: 'ApplicationsOverview',
  components: {
    NewApplicationModal,
    ElRow,
    PageHeader,
    ElButton,
    ApplicationsTable,
    Pagination
  },
  setup() {
    const store = useStore();
    const showApplicationsModal = ref<boolean>(false);
    const applicationModalData = ref<ApplicationModel>();

    const applications = computed<Array<ApplicationModel>>(
      () => store.getters['applications/getPageApplications']
    );

    let pageNumber: ComputedRef<number> = computed(
      () => store.getters['applications/getApplicationsPageNumber']
    );
    let pageSize: ComputedRef<number> = computed(
      () => store.getters['applications/getApplicationsPageSize']
    );
    let allApplicationsCount: ComputedRef<number> = computed(
      () => store.getters['applications/getApplicationsCount']
    );

    const openApplicationsModal = (application?: ApplicationModel): void => {
      if (application) {
        applicationModalData.value = application;
      }
      showApplicationsModal.value = true;
    };

    const fetchApplications = async () => {
      await store.dispatch('applications/fetchPageApplications');
    };

    const createApplication = async (application: ApplicationModel) => {
      showApplicationsModal.value = false;

      await store.dispatch('applications/createOrUpdateApplication', {
        objectId: application.objectId,
        model: {
          name: application.name
        } as CreateOrUpdateApplicationModel
      });
      await fetchApplications();
    };

    const deleteApplication = async (application: ApplicationModel) => {
      if (application.objectId)
        await store.dispatch('applications/deleteApplication', application);

      await fetchApplications();
    };

    const changePage = async (page: number) => {
      store.commit('applications/SET_APPLICATIONS_PAGE', page);
      await fetchApplications();
    };

    onMounted(async () => {
      await fetchApplications();
    });

    return {
      showApplicationsModal,
      openApplicationsModal,
      applicationModalData,
      createApplication,
      deleteApplication,
      applications,
      allApplicationsCount,
      pageNumber,
      pageSize,
      changePage
    };
  }
};
</script>
<style></style>
