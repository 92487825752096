import FilteringOverview from '../FilteringOverview.vue';
import FilteringBuild from '../FilteringBuild.vue';
import FilteringConfig from '../FilteringConfig.vue';
import RunOverview from '@/views/home/shared/RunOverview.vue';
import MonitorOverview from '@/views/home/shared/MonitorOverview.vue';

export default {
  path: '/filtering',
  name: 'Filtering',
  redirect: { name: 'FilteringConfig' },
  component: FilteringOverview,
  meta: { menuItemName: 'Filtering' },
  children: [
    {
      path: 'config',
      name: 'FilteringConfig',
      component: FilteringConfig
    },
    {
      path: 'build',
      name: 'FilteringBuild',
      component: FilteringBuild
    },
    {
      path: 'run',
      name: 'FilteringRun',
      component: RunOverview
    },
    {
      path: 'monitor',
      name: 'FilteringMonitor',
      component: MonitorOverview
    }
  ]
};
