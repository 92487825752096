<template>
  <el-row :gutter="24">
    <el-col :span="12">
      <el-row class="d-flex">
        <h4 class="mt-auto">
          {{ deployments.length }} &#183;
          {{ $t('deployments') }}
        </h4>
      </el-row>
      <el-table
        :data="deployments"
        row-class-name="table-row-clickable"
        @row-click="openDeployment"
      >
        <el-table-column prop="name" :label="$t('name')">
          <template #default="scope">
            <span class="body-1">
              {{ scope.row.name }}
            </span>
          </template>
        </el-table-column>
      </el-table>
    </el-col>
  </el-row>
</template>
<script lang="ts">
import { ElTable, ElTableColumn, ElRow, ElCol } from 'element-plus';
import { computed, ComputedRef, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { DeploymentViewModel } from '@etp/etp-infra-client';
export default {
  name: 'TenantDeployments',
  components: {
    ElTable,
    ElTableColumn,
    ElRow,
    ElCol
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    let tenantId: string;

    const deployments: ComputedRef<Array<DeploymentViewModel>> = computed(
      () => store.getters['tenants/getDeployments']
    );

    let openDeployment = (deployment: DeploymentViewModel) => {
      router.push({
        name: 'TenantDeploymentTasks',
        params: { tenantId: tenantId, deploymentId: deployment.id }
      });
    };

    onMounted(async () => {
      tenantId = router.currentRoute.value.params.tenantId as string;
      await store.dispatch('tenants/fetchDeployments', { tenantId });
    });

    return {
      deployments,
      openDeployment
    };
  }
};
</script>

<style lang="scss" scoped></style>
