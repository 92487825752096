<template>
  <el-row :gutter="spacingWidthHeaderComponents">
    <el-col :span="5">
      <base-card no-header>
        <template #body>
          <el-form>
            <el-form-item :label="$t('branch')" class="mb-0 form-item">
              <div class="d-flex align-items-center">
                <el-select
                  v-model="selectedBranch"
                  :disabled="loadingBranches"
                  value-key="name"
                  size="small"
                  :placeholder="loadingBranches ? $t('loading') : $t('empty')"
                >
                  <el-option
                    v-for="item in branches"
                    :key="item.name ?? ''"
                    :label="item.name ?? ''"
                    :value="item"
                  ></el-option>
                </el-select>
                <el-button
                  :disabled="
                    !userCanWriteEngagementNodeModelGit ||
                    isSignedOffGainEngagement
                  "
                  class="icon-button p-1 cursor-pointer"
                  @click="showBranchModal = true"
                >
                  <plus class="icon-size-8" />
                </el-button>
              </div>
            </el-form-item>
            <el-form-item :label="$t('node')" class="mb-0 form-item">
              <div class="d-flex align-items-center">
                <el-select
                  v-model="selectedNode"
                  :disabled="!selectedBranch.name || loadingNodes"
                  value-key="name"
                  size="small"
                  :placeholder="loadingNodes ? $t('loading') : $t('empty')"
                >
                  <el-option
                    v-for="item in nodes"
                    :key="item.name ?? ''"
                    :label="item.name ?? ''"
                    :value="item"
                  ></el-option>
                </el-select>
                <el-button
                  :disabled="
                    !selectedBranch.name ||
                    selectedBranch.readOnly ||
                    !userCanWriteEngagementNodeModelGit ||
                    isSignedOffGainEngagement
                  "
                  class="icon-button p-1 cursor-pointer"
                  @click="showNodeModal = true"
                >
                  <plus class="icon-size-8" />
                </el-button>
              </div>
            </el-form-item>
          </el-form>
        </template>
      </base-card>
      <base-card v-if="selectedNode.name" no-header class="mt-1">
        <template #body>
          <el-form class="model-form" label-position="top">
            <el-form-item :label="$t('fields')">
              <el-scrollbar max-height="200px">
                <model-list-item
                  v-for="field in fieldsObjects"
                  :key="field.name ?? ''"
                  :item="field"
                />
              </el-scrollbar>
              <div
                v-if="
                  !selectedBranch.readOnly &&
                  userCanWriteEngagementNodeModelGit &&
                  !isSignedOffGainEngagement
                "
                class="edit-fields-container"
                @click="showFieldsModal = true"
              >
                <div class="body-1 edit-fields-icon">
                  <img
                    src="@/assets/images/icons/new/edit.svg"
                    height="16"
                    width="16"
                    class="edit-field action-icon"
                  />
                  <h4>{{ $t('editFields') }}</h4>
                </div>
              </div>
            </el-form-item>
          </el-form>
        </template>
      </base-card>
    </el-col>
    <el-col :span="19">
      <base-card no-header>
        <template #body>
          <div class="d-flex pr-2">
            <el-form>
              <el-form-item :label="$t('source')">
                <div class="d-flex align-items-center">
                  <el-select
                    v-model="selectedSource"
                    :disabled="!selectedNode.name || loadingSources"
                    value-key="name"
                    size="small"
                    :placeholder="loadingSources ? $t('loading') : $t('empty')"
                  >
                    <el-option
                      v-for="item in sources"
                      :key="item.name ?? ''"
                      :label="item.name ?? ''"
                      :value="item"
                    ></el-option>
                  </el-select>
                  <el-button
                    :disabled="
                      !selectedNode.name ||
                      selectedBranch.readOnly ||
                      !userCanWriteEngagementNodeModelGit ||
                      isSignedOffGainEngagement
                    "
                    class="icon-button p-1 cursor-pointer"
                    @click="showSourceModal = true"
                  >
                    <plus class="icon-size-8" />
                  </el-button>
                </div>
              </el-form-item>
            </el-form>

            <div
              v-if="
                sourceDetail.query &&
                !selectedBranch.readOnly &&
                userCanWriteEngagementNodeModelGit &&
                !isSignedOffGainEngagement
              "
              class="ml-auto"
            >
              <el-button
                type="primary"
                class="mt-2"
                @click.stop="updateSourceDetail"
              >
                <h4>{{ $t('save') }}</h4>
              </el-button>
            </div>
          </div>
          <el-form>
            <el-form-item :label="$t('queryEditor')">
              <code-editor
                v-model="sourceDetail.query"
                :read_only="!selectedSource.name"
                font_size="13px"
                :languages="[['SQL', 'SQL']]"
                theme="light"
                min_height="300px"
                min_width="100%"
              ></code-editor>
            </el-form-item>
          </el-form>
        </template>
      </base-card>
    </el-col>
  </el-row>
  <fields-modal
    :dialog-visible="showFieldsModal"
    :fields="fields"
    :hide-data-types="isGainEngagement"
    :min-field-length="isGainEngagement ? 2 : 4"
    :all-fields-in-query="allFieldsInQuery"
    @confirm="updateFields"
    @close="showFieldsModal = false"
  />
  <new-branch-modal
    :show-modal="showBranchModal"
    :default-parent-branch="selectedBranch"
    :branches="branches"
    @confirm="addNewBranch"
    @close="showBranchModal = false"
  />
  <new-node-modal
    :show-modal="showNodeModal"
    :nodes="nodes"
    hide-schema-field
    @confirm="addNewNode"
    @close="showNodeModal = false"
  />
  <new-source-modal
    :show-modal="showSourceModal"
    @confirm="addNewSource"
    @close="showSourceModal = false"
  />
</template>

<script lang="ts">
import {
  ElButton,
  ElCol,
  ElForm,
  ElFormItem,
  ElOption,
  ElRow,
  ElSelect,
  ElScrollbar,
  ElMessage
} from 'element-plus';
import { Plus } from '@element-plus/icons-vue';

import BaseCard from '@/components/panels/BaseCard.vue';
import FieldsModal from '@/components/modals/FieldsModal.vue';
import NewBranchModal from '@/components/modals/NewBranchModal.vue';
import NewNodeModal from '@/components/modals/NewNodeModal.vue';
import NewSourceModal from '@/components/modals/NewSourceModal.vue';
import ModelListItem from '@/components/list/BaseFieldslListItem.vue';
import CodeEditor from 'simple-code-editor/CodeEditor.vue';
import { computed, ComputedRef, onMounted, ref } from 'vue';
import { spacingWidthHeaderComponents } from '@/utils/variables';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { computeGitFields, getFieldsFromQuery } from '@/utils/fieldsHelper';
import { appendUrlParam, processUrlParams } from '@/utils/urlParameter';
import i18n from '@/i18n';
import {
  BranchModel,
  CreateBranchModel,
  CreateFieldModel,
  CreateNodeModel,
  CreateSourceModel,
  FieldModel,
  NodeModel,
  SourceDetailModel,
  SourceModel
} from '@etp/etp-nodemodelgit-client/axios';
import { ConfigureField } from '@/services/web-api/dto/nodeModel';
import { RequirementsStatus } from '@etp/etp-gain-bff-client/axios';

export default {
  name: 'ModelConfig',
  components: {
    BaseCard,
    ElButton,
    ElCol,
    ElForm,
    ElFormItem,
    ElOption,
    ElRow,
    ElScrollbar,
    ElSelect,
    FieldsModal,
    NewBranchModal,
    NewNodeModal,
    NewSourceModal,
    CodeEditor,
    ModelListItem,
    Plus
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    let showBranchModal = ref(false);
    let showFieldsModal = ref(false);
    let showNodeModal = ref(false);
    let showSourceModal = ref(false);

    const loadingBranches: ComputedRef<boolean> = computed(
      () => store.getters['model/loadingBranches']
    );
    const loadingNodes: ComputedRef<boolean> = computed(
      () => store.getters['model/loadingNodes']
    );
    const loadingSources: ComputedRef<boolean> = computed(
      () => store.getters['model/loadingSources']
    );
    const isGainEngagement: ComputedRef<boolean> = computed(
      () => store.getters['home/isGainEngagement']
    );

    const fetchBranches = async () => {
      await store.dispatch('model/fetchBranches');
    };
    const fetchNodes = async () => {
      await store.dispatch('model/fetchConfigNodes');
    };
    const fetchFields = async () => {
      await store.dispatch('model/fetchConfigFields');
    };
    const fetchSources = async () => {
      await store.dispatch('model/fetchConfigSources');
    };
    const fetchSourceDetail = async () => {
      await store.dispatch('model/fetchConfigSourceDetail');
    };

    const addNewBranch = async (model: CreateBranchModel) => {
      await store.dispatch('model/addNewBranch', model);
      await fetchBranches();
      showBranchModal.value = false;
    };
    const addNewNode = async (model: CreateNodeModel) => {
      await store.dispatch('model/addNewNode', model);
      await fetchNodes();
      showNodeModal.value = false;
    };
    const addNewSource = async (model: CreateSourceModel) => {
      await store.dispatch('model/addNewSource', model);
      await fetchSources();
      showSourceModal.value = false;
    };
    const updateSourceDetail = async () => {
      ElMessage({
        showClose: true,
        message: i18n.global.t('savingChanges')
      });
      await store.dispatch('model/updateConfigSourceDetail');
      await fetchSourceDetail();
    };
    const updateFields = async (fields: Array<CreateFieldModel>) => {
      await store.dispatch('model/updateConfigFields', fields);
      await fetchFields();
      showFieldsModal.value = false;
    };

    const branches: ComputedRef<BranchModel[]> = computed(
      () => store.getters['model/branches']
    );
    const nodes: ComputedRef<NodeModel[]> = computed(
      () => store.getters['model/configNodes']
    );
    const fields: ComputedRef<FieldModel[]> = computed(
      () => store.getters['model/configFields']
    );
    const sources: ComputedRef<SourceModel[]> = computed(
      () => store.getters['model/configSources']
    );
    const sourceDetail: ComputedRef<SourceDetailModel> = computed(
      () => store.getters['model/configSourceDetail']
    );

    const selectedBranch = computed({
      get: () => store.getters['model/selectedConfigBranch'],
      set: async (branch: BranchModel) => {
        appendUrlParam('branch', branch.name ?? '');
        store.commit('model/SET_SELECTED_CONFIG_BRANCH', branch);
        await fetchNodes();
      }
    });
    const selectedNode = computed({
      get: () => store.getters['model/selectedConfigNode'],
      set: async (node: NodeModel) => {
        appendUrlParam('branch', selectedBranch.value.name ?? '');
        appendUrlParam('node', node.name ?? '');
        store.commit('model/SET_SELECTED_CONFIG_NODE', node);
        await fetchSources();
        await fetchFields();
      }
    });
    const selectedSource = computed({
      get: () => store.getters['model/selectedConfigSource'],
      set: async (source: SourceModel) => {
        appendUrlParam('branch', selectedBranch.value.name ?? '');
        appendUrlParam('node', selectedNode.value.name ?? '');
        appendUrlParam('source', source.name ?? '');
        store.commit('model/SET_SELECTED_CONFIG_SOURCE', source);
        await fetchSourceDetail();
      }
    });
    const fieldsObjects = computed<ConfigureField[]>(() =>
      computeGitFields(fields.value ?? [], sourceDetail.value.query ?? '')
    );
    const allFieldsInQuery = computed<string[]>(() =>
      getFieldsFromQuery(sourceDetail.value.query || '')
    );

    let showNodeModelGitPages: ComputedRef<boolean> = computed(
      () => store.getters['home/showNodeModelGitPages']
    );
    const isSignedOffGainEngagement: ComputedRef<boolean> = computed(
      () =>
        isGainEngagement.value &&
        store.getters['gain/getCurrentRequirementsStatus']
          ?.requirementsStatus === RequirementsStatus.SignedOff
    );
    const userCanWriteEngagementNodeModelGit: ComputedRef<boolean> = computed(
      () => store.getters['userPermissions/canWriteEngagementNodeModelGit']
    );

    onMounted(async () => {
      if (!showNodeModelGitPages.value) {
        return router.push({ name: 'Overview' });
      }
      await store.dispatch(
        'userPermissions/checkIfCanWriteEngagementNodeModelGit'
      );
      if (isGainEngagement.value) {
        await store.dispatch('gain/fetchCurrentRequirementsStatus');
      }
      fetchBranches().then(async () => {
        await processUrlParams([
          {
            name: 'branch',
            handler: async (branchName: string) => {
              let branch = branches.value.find(
                (el: BranchModel) => el.name == branchName
              );
              if (!branch) return;
              store.commit('model/SET_SELECTED_CONFIG_BRANCH', branch);
              await fetchNodes();
            }
          },
          {
            name: 'node',
            handler: async (nodeName: string) => {
              let node = nodes.value.find(
                (el: NodeModel) => el.name == nodeName
              );
              if (!node) return;
              store.commit('model/SET_SELECTED_CONFIG_NODE', node);
              await fetchFields();
              await fetchSources();
            }
          },
          {
            name: 'source',
            handler: async (sourceName: string) => {
              let source = sources.value.find(
                (el: SourceModel) => el.name == sourceName
              );
              if (!source) return;
              store.commit('model/SET_SELECTED_CONFIG_SOURCE', source);
              await fetchSourceDetail();
            }
          }
        ]);
      });
    });

    return {
      addNewBranch,
      addNewNode,
      addNewSource,
      branches,
      fields,
      nodes,
      selectedBranch,
      selectedNode,
      selectedSource,
      showBranchModal,
      showFieldsModal,
      showNodeModal,
      showSourceModal,
      loadingBranches,
      loadingNodes,
      loadingSources,
      sourceDetail,
      sources,
      spacingWidthHeaderComponents,
      updateFields,
      updateSourceDetail,
      allFieldsInQuery,
      fieldsObjects,
      isGainEngagement,
      isSignedOffGainEngagement,
      userCanWriteEngagementNodeModelGit
    };
  }
};
</script>
<style lang="scss" scoped>
.form-item {
  flex-grow: 1;
}
</style>
