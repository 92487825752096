import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, { model: $setup.form }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form_item, {
        prop: "deployMachineLearningPrivateEndpoint",
        class: "mb-0"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_checkbox, {
            class: "checkbox",
            label: _ctx.$t('deployMachineLearningPrivateEndpoint'),
            "model-value": $setup.form.deployMachineLearningPrivateEndpoint,
            onChange: $setup.handleChangeDeployMachineLearningPrivateEndpoint
          }, null, 8, ["label", "model-value", "onChange"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, {
        label: _ctx.$t('managedIdentityName'),
        class: "mb-0"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_select, {
            modelValue: $setup.form.managedIdentityName,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.form.managedIdentityName) = $event)),
            size: "small",
            placeholder: _ctx.$t('empty'),
            onChange: $setup.handleChangeManagedIdentityName
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.managedIdentityNames, (item) => {
                return (_openBlock(), _createBlock(_component_el_option, {
                  key: item,
                  label: item,
                  value: item
                }, null, 8, ["label", "value"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["modelValue", "placeholder", "onChange"])
        ]),
        _: 1
      }, 8, ["label"]),
      _createVNode(_component_el_form_item, {
        label: _ctx.$t('storageAccountName'),
        class: "mb-0"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_select, {
            modelValue: $setup.form.storageAccountName,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.form.storageAccountName) = $event)),
            size: "small",
            placeholder: _ctx.$t('empty'),
            onChange: $setup.handleChangeStorageAccountName
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.storageAccountNames, (item) => {
                return (_openBlock(), _createBlock(_component_el_option, {
                  key: item,
                  label: item,
                  value: item
                }, null, 8, ["label", "value"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["modelValue", "placeholder", "onChange"])
        ]),
        _: 1
      }, 8, ["label"]),
      _createVNode(_component_el_form_item, {
        label: _ctx.$t('keyVaultName'),
        class: "mb-0"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_select, {
            modelValue: $setup.form.keyVaultName,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.form.keyVaultName) = $event)),
            size: "small",
            placeholder: _ctx.$t('empty'),
            onChange: $setup.handleChangeKeyVaultName
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.keyVaultNames, (item) => {
                return (_openBlock(), _createBlock(_component_el_option, {
                  key: item,
                  label: item,
                  value: item
                }, null, 8, ["label", "value"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["modelValue", "placeholder", "onChange"])
        ]),
        _: 1
      }, 8, ["label"]),
      _createVNode(_component_el_form_item, {
        label: _ctx.$t('containerRegistryName')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_select, {
            modelValue: $setup.form.containerRegistryName,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.form.containerRegistryName) = $event)),
            size: "small",
            placeholder: _ctx.$t('empty'),
            onChange: $setup.handleChangeContainerRegistryName
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.containerRegistryNames, (item) => {
                return (_openBlock(), _createBlock(_component_el_option, {
                  key: item,
                  label: item,
                  value: item
                }, null, 8, ["label", "value"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["modelValue", "placeholder", "onChange"])
        ]),
        _: 1
      }, 8, ["label"])
    ]),
    _: 1
  }, 8, ["model"]))
}