<template>
  <div>
    <el-row>
      <page-header :main-title="$t('engagements')">
        <template #subheader>
          <h4 class="subtitle-2 mt-3">
            {{ $t('engagementsDesc') }}
          </h4>
        </template>
      </page-header>
    </el-row>
    <base-table-header
      :row-count="engagementsCount"
      :quantity-message="$t('engagements')"
      :input-placeholder="$t('searchEngagements')"
      debounce
      class="mt-6"
      @query-change="updateEngagementsFilter"
    >
      <el-button
        v-if="!isCloudProviderAws"
        class="ml-2"
        type="primary"
        @click="openNewEngagementModal()"
      >
        <h4>{{ '+ ' + $t('newEngagement') }}</h4>
      </el-button>
    </base-table-header>
    <el-row>
      <el-col>
        <div class="engagement-table">
          <el-table :data="engagements">
            <el-table-column prop="name" sortable="custom" :label="$t('name')">
              <template #default="scope">
                <div>
                  <h4>{{ scope.row.name }}</h4>
                  <span class="body-1 mt-1">
                    {{ scope.row.id }}
                  </span>
                </div>
              </template>
            </el-table-column>
            <el-table-column width="100">
              <template #default="scope">
                <div>
                  <img
                    :src="require(`@/assets/images/icons/new/settings.svg`)"
                    class="action-icon action-spin"
                    height="14"
                    width="14"
                    @click="openEngagementDetails(scope.row.id)"
                  />
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
    <pagination
      :count="engagementsCount"
      :page="engagementsPage"
      :page-size="engagementsPageSize"
      @change-page="changePage"
    />
    <new-engagement-modal
      v-model="showNewEngagementModal"
      :feedback-text="$t('newEngagementAdded')"
      :tenants="tenants"
      @confirm="createNewEngagement"
    />
  </div>
</template>
<script lang="ts">
import PageHeader from '@/components/misc/PageHeader.vue';
import BaseTableHeader from '@/components/tables/BaseTableHeader.vue';
import { ElTable, ElTableColumn, ElCol, ElRow, ElButton } from 'element-plus';
import { computed, onMounted, ref, ComputedRef } from 'vue';
import { useStore } from 'vuex';
import NewEngagementModal from './components/NewEngagementModal.vue';
import router from '@/router';
import Pagination from '@/components/misc/Pagination.vue';
import {
  CreateEngagementDto,
  EngagementModel
} from '@etp/etp-engagements-client/axios';
import { BaseTenantModel } from '@etp/etp-infra-client';
import config from '@/config/config';

export default {
  name: 'EngagementsOverview',
  components: {
    BaseTableHeader,
    ElTable,
    ElTableColumn,
    PageHeader,
    NewEngagementModal,
    ElRow,
    ElCol,
    ElButton,
    Pagination
  },

  setup() {
    const store = useStore();

    let tenants: ComputedRef<Array<BaseTenantModel>> = computed(
      () => store.getters['tenants/getTenants']
    );

    let engagements: ComputedRef<Array<EngagementModel>> = computed(
      () => store.getters['engagements/getPagedEngagements']
    );

    let engagementsCount: ComputedRef<number> = computed(
      () => store.getters['engagements/getEngagementsCount']
    );

    let engagementsPage: ComputedRef<number> = computed(
      () => store.getters['engagements/getEngagementsPage']
    );

    let engagementsPageSize: ComputedRef<number> = computed(
      () => store.getters['engagements/engagementsPageSize']
    );

    const fetchEngagements = async () => {
      await store.dispatch(
        'engagements/fetchPagedEngagements',
        engagementsFilter.value
      );
    };

    const isCloudProviderAws = config.cloudProvider.toLowerCase() === 'aws';

    const showNewEngagementModal = ref(false);

    const engagementsFilter = ref('');

    const openNewEngagementModal = () => {
      showNewEngagementModal.value = true;
    };

    const openEngagementDetails = (engagementId: string) => {
      router.push({
        name: 'EngagementDetails',
        params: { engagementId }
      });
    };

    const createNewEngagement = async (engagement: CreateEngagementDto) => {
      showNewEngagementModal.value = false;
      await store.dispatch('engagements/createEngagement', engagement);
      await fetchEngagements();
    };

    const updateEngagementsFilter = async (inputQuery: string) => {
      engagementsFilter.value = inputQuery.length >= 3 ? inputQuery : '';
      await fetchEngagements();
    };

    const changePage = async (page: number) => {
      store.commit('engagements/SET_ENGAGEMENTS_PAGE', page);
      await fetchEngagements();
    };

    onMounted(async () => {
      await store.dispatch('tenants/fetchAllTenants');
      await fetchEngagements();
    });

    return {
      changePage,
      updateEngagementsFilter,
      createNewEngagement,
      engagements,
      engagementsCount,
      engagementsPage,
      engagementsPageSize,
      isCloudProviderAws,
      openEngagementDetails,
      openNewEngagementModal,
      showNewEngagementModal,
      tenants
    };
  }
};
</script>
<style scoped lang="scss">
.engagements-table {
  &.action-spin:hover {
    transform: scale(1.15) rotate(90deg);
    opacity: 1;
  }
}

.align-right {
  float: right;
}
</style>
