import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pagination" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_pagination, {
      layout: "prev, pager, next",
      total: $props.count,
      "page-size": $props.pageSize,
      "hide-on-single-page": "",
      "current-page": $props.page,
      onCurrentChange: $setup.changePage
    }, null, 8, ["total", "page-size", "current-page", "onCurrentChange"])
  ]))
}