<template>
  <el-row>
    <el-col>
      <page-header :main-title="$t('permissions')">
        <template #subheader>
          <h4 class="subtitle-2 mt-3">
            {{ $t('permissionsDesc') }}
          </h4>
        </template>
      </page-header>
    </el-col>
  </el-row>
  <base-table-header
    :row-count="allPermissionsCount"
    :quantity-message="$t('permissions')"
    no-filter
    class="mt-3"
  >
    <el-button type="primary" @click="openCreatePermissionModal">
      <h4>+ {{ $t('newPermission') }}</h4>
    </el-button>
  </base-table-header>
  <el-row>
    <el-col>
      <base-table
        :show-loading-table="loadingPermissions"
        :show-no-content-table="!pagePermissions.length"
        :error-message="$t('noPermissions')"
      >
        <el-table :data="pagePermissions">
          <el-table-column :label="$t('name')" prop="name" />
          <el-table-column
            :label="$t('category')"
            prop="category"
            width="250"
          />
          <el-table-column
            :label="$t('description')"
            prop="description"
            width="350"
          />
          <el-table-column width="80">
            <template #default="props">
              <el-tooltip placement="top" :content="$t('editPermission')">
                <img
                  :src="require(`@/assets/images/icons/new/edit.svg`)"
                  height="14"
                  width="14"
                  class="action-icon"
                  @click="openUpdatePermissionModal(props.row)"
                />
              </el-tooltip>
              <el-tooltip
                placement="top"
                class="ml-1"
                :content="$t('deletePermission')"
              >
                <img
                  :src="require(`@/assets/images/icons/new/delete.svg`)"
                  height="14"
                  width="14"
                  class="action-icon"
                  @click="openRemovePermissionModal(props.row)"
                />
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </base-table>
    </el-col>
  </el-row>
  <pagination
    :count="allPermissionsCount"
    :page="pageNumber"
    :page-size="pageSize"
    @change-page="changePage"
  />
  <create-or-update-permission-modal
    v-model="showCreateOrUpdatePermissionModal"
    :permission="permissionToCreateOrUpdate"
    @confirm="createOrUpdatePermission"
    @close="showCreateOrUpdatePermissionModal = false"
  />
  <base-confirmation-modal
    v-model="showRemovePermissionConfirmationModal"
    :content="$t('confirmRemovePermission')"
    width="600px"
    :feedback-text="$t('permissionRemoved')"
    @confirm="removePermission"
    @close="showRemovePermissionConfirmationModal = false"
  />
</template>

<script lang="ts">
import {
  ElButton,
  ElCol,
  ElMessage,
  ElRow,
  ElTable,
  ElTableColumn,
  ElTooltip
} from 'element-plus';

import PageHeader from '@/components/misc/PageHeader.vue';
import BaseConfirmationModal from '@/components/modals/BaseConfirmationModal.vue';
import BaseTable from '@/components/tables/BaseTable.vue';
import BaseTableHeader from '@/components/tables/BaseTableHeader.vue';
import CreateOrUpdatePermissionModal from './components/CreateOrUpdatePermissionModal.vue';
import Pagination from '@/components/misc/Pagination.vue';

import { computed, ComputedRef, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { PermissionModel } from '@etp/etp-authorization-client/axios';
import i18n from '@/i18n';

export default {
  name: 'PermissionsOverview',
  components: {
    BaseConfirmationModal,
    BaseTable,
    BaseTableHeader,
    CreateOrUpdatePermissionModal,
    ElButton,
    ElCol,
    ElRow,
    ElTable,
    ElTableColumn,
    ElTooltip,
    PageHeader,
    Pagination
  },
  setup() {
    let store = useStore();

    const showCreateOrUpdatePermissionModal = ref(false);
    const showRemovePermissionConfirmationModal = ref(false);
    const permissionToCreateOrUpdate = ref({} as PermissionModel);
    const permissionToRemove = ref({} as PermissionModel);

    const pagePermissions: ComputedRef<Array<PermissionModel>> = computed(
      () => store.getters['permissions/getPagePermissions']
    );
    let pageNumber: ComputedRef<number> = computed(
      () => store.getters['permissions/getPermissionsPageNumber']
    );

    let pageSize: ComputedRef<number> = computed(
      () => store.getters['permissions/getPermissionsPageSize']
    );

    let allPermissionsCount: ComputedRef<number> = computed(
      () => store.getters['permissions/getPermissionsCount']
    );
    const loadingPermissions: ComputedRef<boolean> = computed(
      () => store.getters['permissions/getLoadingPermissions']
    );

    const fetchPermissions = async () => {
      await store.dispatch('permissions/fetchPagePermissions');
    };

    const openCreatePermissionModal = () => {
      permissionToCreateOrUpdate.value = {} as PermissionModel;
      showCreateOrUpdatePermissionModal.value = true;
    };
    const openUpdatePermissionModal = async (permission: PermissionModel) => {
      permissionToCreateOrUpdate.value = permission;
      showCreateOrUpdatePermissionModal.value = true;
    };
    let createOrUpdatePermission = async (permission: PermissionModel) => {
      if (permission.name)
        await store.dispatch('permissions/updatePermission', permission);
      else await store.dispatch('permissions/createPermission', permission);
      showCreateOrUpdatePermissionModal.value = false;
      await fetchPermissions();
      ElMessage({
        showClose: true,
        message: i18n.global.t('permissionInfoSaved')
      });
    };

    const openRemovePermissionModal = async (permission: PermissionModel) => {
      permissionToRemove.value = permission;
      showRemovePermissionConfirmationModal.value = true;
    };
    const removePermission = async () => {
      await store.dispatch('permissions/deletePermission', {
        permissionName: permissionToRemove.value.name
      });
      showRemovePermissionConfirmationModal.value = false;
      setTimeout(async () => await fetchPermissions(), 500);
    };

    const changePage = async (page: number) => {
      store.commit('permissions/SET_PERMISSIONS_PAGE', page);
      await fetchPermissions();
    };

    onMounted(async () => {
      await fetchPermissions();
    });

    return {
      allPermissionsCount,
      changePage,
      createOrUpdatePermission,
      loadingPermissions,
      openCreatePermissionModal,
      openRemovePermissionModal,
      openUpdatePermissionModal,
      pagePermissions,
      pageNumber,
      pageSize,
      permissionToCreateOrUpdate,
      removePermission,
      showCreateOrUpdatePermissionModal,
      showRemovePermissionConfirmationModal
    };
  }
};
</script>

<style scoped lang="scss"></style>
