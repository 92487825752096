<template>
  <base-timeline
    :pipeline-runs="pipelineRuns.slice(0, 8)"
    :show-layer-name="true"
  />
</template>
<script lang="ts">
import BaseTimeline from '@/components/timeline/BaseTimeline.vue';
import { PipelineRunDto } from '@etp/etp-pipelines-client/axios';
export default {
  name: 'LastPipelineRunsPanel',
  components: {
    BaseTimeline
  },
  props: {
    pipelineRuns: {
      type: Array as () => Array<PipelineRunDto>,
      required: true
    }
  }
};
</script>
<style lang="scss" scoped>
h4 {
  cursor: pointer;
  color: $etp-deloitte-blue-5;
}

.text {
  text-transform: lowercase;

  &:hover {
    text-shadow: 5px 5px 10px $etp-button-shadow;
  }
}
</style>
