<template>
  <div>
    <el-upload
      class="upload-demo"
      action=""
      :http-request="emitFiles"
      multiple
      :auto-upload="false"
      :show-file-list="false"
      :on-change="emitFiles"
      :accept="accept"
    >
      <el-button size="small" type="primary">
        <h4>{{ '+ ' + $t('newFile') }}</h4>
      </el-button>
    </el-upload>
  </div>
</template>
<script lang="ts">
import { ElUpload, ElButton } from 'element-plus';
import { ref } from 'vue';

export default {
  name: 'BaseUploadButton',
  components: { ElUpload, ElButton },
  props: {
    accept: { type: String, required: false, default: '' }
  },
  emits: ['upload'],
  setup(props, { emit }) {
    const queueLoading = ref(false);

    const emitFiles = async file => {
      emit('upload', file.raw);
    };
    return { emitFiles, queueLoading };
  }
};
</script>
<style lang="scss">
.upload-demo {
  float: right;
  line-height: 1;
}
</style>
