import EngagementsOverview from '@/views/admin/engagements/EngagementsOverview.vue';
import EngagementConfiguration from '@/views/admin/engagements/EngagementConfiguration.vue';
import EngagementConnectors from '@/views/admin/engagements/EngagementConnectors.vue';
import EngagementDetails from '@/views/admin/engagements/EngagementDetails.vue';
import EngagementGeneral from '@/views/admin/engagements/EngagementGeneral.vue';
import EngagementUsers from '@/views/admin/engagements/EngagementUsers.vue';
import EngagementApplications from '@/views/admin/engagements/EngagementApplications.vue';
import EngagementResources from '@/views/admin/engagements/EngagementResources.vue';
import EngagementStorage from '@/views/admin/engagements/EngagementStorage.vue';
import EngagementTasks from '@/views/admin/engagements/EngagementRbacTasks.vue';

export default [
  {
    path: 'engagements',
    name: 'EngagementsOverview',
    component: EngagementsOverview,
    meta: { menuItemName: 'EngagementsOverview' }
  },
  {
    path: 'engagements/:engagementId',
    name: 'EngagementDetails',
    redirect: { name: 'EngagementGeneral' },
    component: EngagementDetails,
    meta: { menuItemName: 'EngagementsOverview' },
    children: [
      {
        path: 'general',
        name: 'EngagementGeneral',
        component: EngagementGeneral
      },
      {
        path: 'resources',
        name: 'EngagementResources',
        component: EngagementResources
      },
      {
        path: 'users',
        name: 'EngagementUsers',
        component: EngagementUsers
      },
      {
        path: 'configuration',
        name: 'EngagementConfiguration',
        component: EngagementConfiguration
      },
      {
        path: 'applications',
        name: 'EngagementApplications',
        component: EngagementApplications
      },
      {
        path: 'connectors',
        name: 'EngagementConnectors',
        component: EngagementConnectors
      },
      {
        path: 'rbac-tasks',
        name: 'EngagementRbacTasks',
        component: EngagementTasks
      },
      {
        path: 'storage',
        name: 'EngagementStorage',
        component: EngagementStorage
      }
    ]
  }
];
