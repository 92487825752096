<template>
  <div id="loginpage" class="login">
    <login-circles />
    <el-row align="middle" justify="center" class="login-wrapper">
      <div class="login-tile-wrapper">
        <div class="login-tile">
          <div class="login-header">
            <h2>{{ $t('signIn') }}</h2>
          </div>
          <div class="login-content">{{ $t('loginMessage') }}</div>
          <div class="login-footer">
            <el-button class="login-button" type="primary" @click="login">
              {{ $t('login') }}
            </el-button>
          </div>
        </div>
      </div>
    </el-row>
  </div>
</template>

<script lang="ts">
import { ElButton, ElRow } from 'element-plus';

import msal from '@/services/msal/msal';
import LoginCircles from './components/LoginCircles.vue';

export default {
  name: 'LoginOverview',
  components: { ElButton, ElRow, LoginCircles },
  setup() {
    const login = async () => {
      await msal.signIn('loginRedirect');
    };
    return { login };
  }
};
</script>

<style scoped lang="scss">
.login {
  background: linear-gradient(-45deg, #fce9fe, #d4fffc, #c0eaff, #c4f4fe);
  background-size: 900% 900%;
  height: 100vh;
  animation: gradient 10s ease infinite;
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  .login-wrapper {
    height: 100%;
  }
  .login-tile-wrapper {
    border: 1em solid $etp-modal-border;
    border-radius: 2.5em;
    box-shadow: 0px 8px 24px -1px $etp-modal-shadow;
  }
  .login-tile {
    background: $etp-deloitte-white;
    width: 424px;
    padding: 2em;
    border-radius: 2em;
  }
  .login-header,
  .login-footer {
    display: flex;
    justify-content: center;
  }
  .login-content,
  .login-footer {
    margin-top: 2em;
  }
  .login-button {
    width: 120px;
  }
}
</style>
