<template>
  <div>
    <el-row>
      <el-col :span="24">
        <page-header
          :main-title="`${$t('engagementDetails')} - ${engagement.name}`"
        >
          <template #tabs>
            <div class="d-flex mt-2">
              <router-link
                v-slot="{ isExactActive }"
                :to="{ name: 'EngagementGeneral' }"
              >
                <base-tab :active="isExactActive">
                  <h4>{{ $t('general') }}</h4>
                </base-tab>
              </router-link>
              <router-link
                v-slot="{ isExactActive }"
                :to="{ name: 'EngagementResources' }"
              >
                <base-tab :active="isExactActive">
                  <h4>{{ $t('resources') }}</h4>
                </base-tab>
              </router-link>
              <router-link
                v-slot="{ isActive }"
                :to="{ name: 'EngagementUsers' }"
              >
                <base-tab :active="isActive">
                  <h4>{{ $t('users') }}</h4>
                </base-tab>
              </router-link>
              <router-link
                v-slot="{ isActive }"
                :to="{ name: 'EngagementApplications' }"
              >
                <base-tab :active="isActive">
                  <h4>{{ $t('application') }}</h4>
                </base-tab>
              </router-link>
              <router-link
                v-if="showRbacTasks"
                v-slot="{ isActive }"
                :to="{ name: 'EngagementRbacTasks' }"
              >
                <base-tab :active="isActive">
                  <h4>{{ $t('rbacTasks') }}</h4>
                </base-tab>
              </router-link>
              <router-link
                v-slot="{ isActive }"
                :to="{ name: 'EngagementConfiguration' }"
              >
                <base-tab :active="isActive">
                  <h4>{{ $t('configuration') }}</h4>
                </base-tab>
              </router-link>
              <router-link
                v-if="showConnectors"
                v-slot="{ isActive }"
                :to="{ name: 'EngagementConnectors' }"
              >
                <base-tab :active="isActive">
                  <h4>{{ $t('connectors') }}</h4>
                </base-tab>
              </router-link>
              <router-link
                v-if="showStorage"
                v-slot="{ isActive }"
                :to="{ name: 'EngagementStorage' }"
              >
                <base-tab :active="isActive">
                  <h4>{{ $t('storage') }}</h4>
                </base-tab>
              </router-link>
              <div class="horizontal-line mt-2">
                <hr />
              </div>
            </div>
          </template>
        </page-header>
      </el-col>
    </el-row>
    <div class="mt-6">
      <router-view />
    </div>
  </div>
</template>
<script lang="ts">
import { computed, ComputedRef, onMounted } from 'vue';
import { useStore } from 'vuex';
import PageHeader from '@/components/misc/PageHeader.vue';
import { useRouter } from 'vue-router';
import { ElRow, ElCol } from 'element-plus';
import BaseTab from '@/components/tabs/BaseTab.vue';
import { EngagementModel } from '@etp/etp-engagements-client/axios';
import { feature_pipelines, feature_storage } from '@/utils/variables';
import config from '@/config/config';

export default {
  name: 'EngagementDetails',
  components: {
    PageHeader,
    ElRow,
    ElCol,
    BaseTab
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    const engagement: ComputedRef<EngagementModel> = computed(
      () => store.getters['engagements/getActiveEngagement']
    );
    const activeEngagementFeaturesArray: ComputedRef<string[]> = computed(
      () => store.getters['engagements/getActiveEngagementFeaturesArray']
    );

    const fetchEngagement = async (engagementId: string) => {
      await store.dispatch('engagements/fetchEngagement', engagementId);
    };

    onMounted(async () => {
      let engagementId = router.currentRoute.value.params
        .engagementId as string;
      await fetchEngagement(engagementId);
    });

    const showConnectors: ComputedRef<boolean> = computed(
      () =>
        activeEngagementFeaturesArray.value.includes(feature_pipelines) &&
        config.cloudProvider.toLowerCase() !== 'aws'
    );

    const showRbacTasks: ComputedRef<boolean> = computed(
      () => config.cloudProvider.toLowerCase() !== 'aws'
    );

    const showStorage: ComputedRef<boolean> = computed(() =>
      activeEngagementFeaturesArray.value.includes(feature_storage)
    );

    return {
      activeEngagementFeaturesArray,
      engagement,
      feature_pipelines,
      showConnectors,
      showRbacTasks,
      showStorage
    };
  }
};
</script>
