<template>
  <el-form :model="form">
    <el-form-item prop="deploySqlServerPrivateEndpoint" class="mb-0">
      <el-checkbox
        class="checkbox"
        :label="$t('deploySqlServerPrivateEndpoint')"
        :model-value="form.deploySqlServerPrivateEndpoint"
        @change="handleChangeDeploySqlServerPrivateEndpoint"
      />
    </el-form-item>
    <el-form-item :label="$t('managedIdentityName')" class="mb-0">
      <el-select
        v-model="form.managedIdentityName"
        size="small"
        :placeholder="$t('empty')"
        @change="handleChangeManagedIdentityName"
      >
        <el-option
          v-for="item in managedIdentityNames"
          :key="item"
          :label="item"
          :value="item"
        ></el-option>
      </el-select>
    </el-form-item>
  </el-form>
</template>
<script lang="ts">
import {
  ElForm,
  ElFormItem,
  ElCheckbox,
  ElOption,
  ElSelect
} from 'element-plus';
import { SqlServerConfiguration } from '@/views/admin/tenants/models/TenantAzureConfigurationObjects';
import { onMounted, onUpdated, ref } from 'vue';

export default {
  name: 'SqlServerConfigurationForm',
  components: { ElForm, ElFormItem, ElCheckbox, ElOption, ElSelect },
  props: {
    configuration: { type: String, default: '' },
    managedIdentityNames: {
      type: Array as () => Array<string>,
      default: () => new Array<string>()
    }
  },
  emits: ['update-configuration'],
  setup(props, { emit }) {
    let form = ref(new SqlServerConfiguration());
    let localConfugrationObject = ref({});

    const emitUpdateEvent = () => {
      emit(
        'update-configuration',
        JSON.stringify({
          ...localConfugrationObject.value,
          ...form.value
        })
      );
    };

    const handleChangeDeploySqlServerPrivateEndpoint = (newValue: boolean) => {
      form.value.deploySqlServerPrivateEndpoint = newValue;
      emitUpdateEvent();
    };

    const handleChangeManagedIdentityName = (newValue: string) => {
      form.value.managedIdentityName = newValue;
      emitUpdateEvent();
    };

    const updateLocalVars = () => {
      localConfugrationObject.value = JSON.parse(props.configuration || '{}');
      form.value.deploySqlServerPrivateEndpoint =
        localConfugrationObject.value['deploySqlServerPrivateEndpoint'] ??
        false;
      form.value.managedIdentityName =
        localConfugrationObject.value['managedIdentityName'] ?? '';
    };

    onMounted(() => {
      updateLocalVars();
      emitUpdateEvent();
    });
    onUpdated(() => updateLocalVars());

    return {
      form,
      handleChangeDeploySqlServerPrivateEndpoint,
      handleChangeManagedIdentityName
    };
  }
};
</script>
<style scoped lang="scss"></style>
