<template>
  <base-card no-header>
    <template #body>
      <el-row>
        <el-col :span="6">
          <h3>{{ $t('dashboardPlatform') }}</h3>
        </el-col>
        <el-col :span="12">
          <el-form ref="formRef" :model="form" :rules="formRules">
            <el-form-item
              class="mb-0"
              :label="$t('dashboardType')"
              prop="dashboardType"
            >
              <el-select
                v-model="form.dashboardType"
                size="small"
                :placeholder="$t('empty')"
                class="mb-0"
                @change="handleDashboarTypeUpdate"
              >
                <el-option
                  v-for="(index, item) in DashboardToolType"
                  :key="index"
                  :label="item"
                  :value="index"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              :label="$t('subscriptionId')"
              prop="subscriptionId"
              class="mb-0"
            >
              <el-input v-model="form.subscriptionId" size="small" />
            </el-form-item>
            <el-form-item
              :label="$t('resourceGroupName')"
              prop="resourceGroupName"
              class="mb-0"
            >
              <el-input v-model="form.resourceGroupName" size="small" />
            </el-form-item>
            <el-form-item
              :label="$t('powerBiWorkspaceId')"
              prop="powerBiWorkspaceId"
              class="mb-0"
            >
              <el-input v-model="form.powerBiWorkspaceId" size="small" />
            </el-form-item>
            <el-form-item
              class="mb-0"
              :label="$t('PowerBIEmbedded')"
              prop="powerBiEmbeddedName"
            >
              <el-select
                v-model="form.powerBiEmbeddedName"
                size="small"
                :placeholder="$t('empty')"
              >
                <el-option
                  v-for="item in localPowerBiEmbeddeds"
                  :key="item.name"
                  :label="item.name"
                  :value="item.name"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              class="mb-0"
              :label="$t('analysisServices')"
              prop="analysisServicesName"
            >
              <el-select
                v-model="form.analysisServicesName"
                size="small"
                :placeholder="$t('empty')"
              >
                <el-option
                  v-for="item in localAnalysisServices"
                  :key="item.name"
                  :label="item.name"
                  :value="item.name"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              class="mb-0"
              :label="$t('storageType')"
              prop="storageType"
            >
              <el-select
                v-model="form.storageType"
                size="small"
                :placeholder="$t('empty')"
                class="mb-0"
                @change="handleStorageTypeUpdate"
              >
                <el-option
                  v-for="(index, item) in DashboardStorageType"
                  :key="index"
                  :label="item"
                  :value="index"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              class="mb-0"
              :label="$t('storageAccountName')"
              prop="storageAccountName"
            >
              <el-select
                v-model="form.storageAccountName"
                size="small"
                :placeholder="$t('empty')"
              >
                <el-option
                  v-for="item in localStorageResources"
                  :key="item.name"
                  :label="item.name"
                  :value="item.name"
                />
              </el-select>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="6" class="d-flex">
          <el-button
            class="ml-auto"
            type="primary"
            :disabled="disabled"
            @click="save"
          >
            <h4>{{ $t('save') }}</h4>
          </el-button>
        </el-col>
      </el-row>
    </template>
  </base-card>
</template>
<script lang="ts">
import BaseCard from '@/components/panels/BaseCard.vue';
import { mustBeGuid } from '@/utils/formValidators';
import { onUpdated, reactive, ref } from 'vue';
import {
  ElButton,
  ElCol,
  ElForm,
  ElFormItem,
  ElInput,
  ElOption,
  ElRow,
  ElSelect
} from 'element-plus';
import {
  DashboardToolType,
  DashboardStorageType,
  EngagementConfiguration,
  CreateOrUpdateEngagementConfigurationDto
} from '@etp/etp-dashboarding-client/axios';
import { ResourceType, ResourceModel } from '@etp/etp-infra-client';

export default {
  name: 'DashboardConfigurationCard',
  components: {
    BaseCard,
    ElButton,
    ElCol,
    ElForm,
    ElFormItem,
    ElInput,
    ElOption,
    ElRow,
    ElSelect
  },
  props: {
    configuration: {
      type: Object as () => EngagementConfiguration,
      required: true
    },
    disabled: { type: Boolean, default: false },
    resources: {
      type: Array as () => Array<ResourceModel>,
      default: () => new Array<ResourceModel>()
    }
  },
  emits: ['update'],
  setup(props, { emit }) {
    const form = reactive({
      dashboardType: -1,
      subscriptionId: '',
      resourceGroupName: '',
      powerBiWorkspaceId: '',
      powerBiEmbeddedName: '',
      analysisServicesName: '',
      storageType: -1,
      storageAccountName: ''
    });
    const formRef = ref<HTMLFormElement>();
    const formRules = reactive({
      subscriptionId: [
        {
          validator: mustBeGuid,
          trigger: 'blur'
        }
      ],
      powerBiWorkspaceId: [
        {
          validator: mustBeGuid,
          trigger: 'blur'
        }
      ]
    });

    const save = async () => {
      formRef.value?.validate((valid: boolean) => {
        if (valid) {
          emit('update', {
            dashboardToolType: form.dashboardType,
            dashboardToolConfiguration: createDashboardConfiguration(),
            dashboardStorageType: form.storageType,
            dashboardStorageConfiguration: createStorageConfiguration()
          } as CreateOrUpdateEngagementConfigurationDto);
        }
      });
    };

    const createDashboardConfiguration = () => {
      switch (form.dashboardType) {
        case DashboardToolType.PowerBi:
          return JSON.stringify({
            SubscriptionId: form.subscriptionId,
            ResourceGroupName: form.resourceGroupName,
            WorkspaceId: form.powerBiWorkspaceId,
            PowerBiEmbeddedResourceName: form.powerBiEmbeddedName,
            AnalysisServicesResourceName: form.analysisServicesName
          });
        default:
          return '';
      }
    };

    const createStorageConfiguration = () => {
      switch (form.storageType) {
        case DashboardStorageType.Azure_DataLakeStorage:
        case DashboardStorageType.Azure_StorageAccount:
          return JSON.stringify({
            StorageAccountName: form.storageAccountName
          });
        default:
          return '';
      }
    };

    const handleDashboarTypeUpdate = (type: number) => {
      form.dashboardType = type;
      switch (type) {
        case DashboardToolType.PowerBi:
          form.subscriptionId =
            localDashboardConfiguration.value['SubscriptionId'] ?? '';
          form.resourceGroupName =
            localDashboardConfiguration.value['ResourceGroupName'] ?? '';
          form.powerBiWorkspaceId =
            localDashboardConfiguration.value['WorkspaceId'] ?? '';
          form.powerBiEmbeddedName =
            localDashboardConfiguration.value['PowerBiEmbeddedResourceName'] ??
            '';
          form.analysisServicesName =
            localDashboardConfiguration.value['AnalysisServicesResourceName'] ??
            '';
          break;
        default:
          form.subscriptionId = '';
          form.resourceGroupName = '';
          form.powerBiWorkspaceId = '';
          form.powerBiEmbeddedName = '';
          form.analysisServicesName = '';
          break;
      }
    };

    const handleStorageTypeUpdate = (type: number) => {
      form.storageType = type;
      switch (type) {
        case DashboardStorageType.Azure_StorageAccount:
          localStorageResources.value = props.resources.filter(
            el => el.resourceType == ResourceType.StorageAccount
          );
          form.storageAccountName =
            localStorageConfiguration.value['StorageAccountName'] ?? '';
          break;
        case DashboardStorageType.Azure_DataLakeStorage:
          localStorageResources.value = props.resources.filter(
            el => el.resourceType == ResourceType.DataLakeStorage
          );
          form.storageAccountName =
            localStorageConfiguration.value['StorageAccountName'] ?? '';
          break;
        default:
          form.storageAccountName = '';
          break;
      }
    };

    let localDashboardConfiguration = ref({});
    let localStorageConfiguration = ref({});
    let localPowerBiEmbeddeds = ref(new Array<ResourceModel>());
    let localAnalysisServices = ref(new Array<ResourceModel>());
    let localStorageResources = ref(new Array<ResourceModel>());
    const updateLocalVars = async () => {
      localDashboardConfiguration.value = JSON.parse(
        props.configuration.dashboardToolConfiguration || '{}'
      );
      localStorageConfiguration.value = JSON.parse(
        props.configuration.dashboardStorageConfiguration || '{}'
      );
      localPowerBiEmbeddeds.value = props.resources.filter(
        el => el.resourceType == ResourceType.PowerBIEmbedded
      );
      localAnalysisServices.value = props.resources.filter(
        el => el.resourceType == ResourceType.AnalysisServices
      );
      handleDashboarTypeUpdate(props.configuration.dashboardToolType ?? 0);
      handleStorageTypeUpdate(props.configuration.dashboardStorageType ?? 0);
    };

    onUpdated(() => {
      updateLocalVars();
    });

    return {
      DashboardStorageType,
      DashboardToolType,
      form,
      formRef,
      formRules,
      handleDashboarTypeUpdate,
      handleStorageTypeUpdate,
      localAnalysisServices,
      localPowerBiEmbeddeds,
      localStorageResources,
      save
    };
  }
};
</script>
<style scoped lang="scss"></style>
