import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_base_card = _resolveComponent("base-card")!

  return (_openBlock(), _createBlock(_component_base_card, { "no-header": "" }, {
    body: _withCtx(() => [
      _createVNode(_component_el_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_col, { span: 6 }, {
            default: _withCtx(() => [
              _createElementVNode("h3", null, _toDisplayString(_ctx.$t('pipelinePlatform')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_col, { span: 12 }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form, {
                ref: "formRef",
                model: $setup.form,
                rules: $setup.formRules
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_form_item, {
                    class: "mb-0",
                    label: _ctx.$t('implementation'),
                    prop: "implementation"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_select, {
                        modelValue: $setup.form.implementation,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.form.implementation) = $event)),
                        size: "small",
                        placeholder: _ctx.$t('empty'),
                        class: "mb-0",
                        onChange: $setup.handleImplementationUpdate
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.PipelinePlatformType, (index, item) => {
                            return (_openBlock(), _createBlock(_component_el_option, {
                              key: index,
                              label: item,
                              value: index
                            }, null, 8, ["label", "value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["modelValue", "placeholder", "onChange"])
                    ]),
                    _: 1
                  }, 8, ["label"]),
                  (
                $setup.form.implementation != $setup.PipelinePlatformType.Aws_StepFunctions
              )
                    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                        _createVNode(_component_el_form_item, {
                          label: _ctx.$t('subscriptionId'),
                          prop: "subscriptionId",
                          class: "mb-0"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_input, {
                              modelValue: $setup.form.subscriptionId,
                              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.form.subscriptionId) = $event)),
                              size: "small"
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        }, 8, ["label"]),
                        _createVNode(_component_el_form_item, {
                          label: _ctx.$t('resourceGroupName'),
                          prop: "resourceGroupName",
                          class: "mb-0"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_input, {
                              modelValue: $setup.form.resourceGroupName,
                              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.form.resourceGroupName) = $event)),
                              size: "small"
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        }, 8, ["label"]),
                        _createVNode(_component_el_form_item, {
                          class: "mb-0",
                          label: _ctx.$t('resourceName'),
                          prop: "resourceName"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_select, {
                              modelValue: $setup.form.resourceName,
                              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.form.resourceName) = $event)),
                              size: "small",
                              placeholder: _ctx.$t('empty')
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.localResources, (item) => {
                                  return (_openBlock(), _createBlock(_component_el_option, {
                                    key: item.name ?? '',
                                    label: item.name ?? '',
                                    value: item.name ?? ''
                                  }, null, 8, ["label", "value"]))
                                }), 128))
                              ]),
                              _: 1
                            }, 8, ["modelValue", "placeholder"])
                          ]),
                          _: 1
                        }, 8, ["label"]),
                        _createVNode(_component_el_form_item, {
                          class: "mb-0",
                          label: _ctx.$t('gitImplementation'),
                          prop: "gitImplementation"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_select, {
                              modelValue: $setup.form.gitImplementation,
                              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.form.gitImplementation) = $event)),
                              size: "small",
                              placeholder: _ctx.$t('empty'),
                              class: "mb-0"
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.NodeModelPlatformType, (index, item) => {
                                  return (_openBlock(), _createBlock(_component_el_option, {
                                    key: index,
                                    label: item,
                                    value: index
                                  }, null, 8, ["label", "value"]))
                                }), 128))
                              ]),
                              _: 1
                            }, 8, ["modelValue", "placeholder"])
                          ]),
                          _: 1
                        }, 8, ["label"]),
                        _createVNode(_component_el_form_item, {
                          class: "mb-0",
                          label: _ctx.$t('GitRepository'),
                          prop: "gitRepositoryName"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_select, {
                              modelValue: $setup.form.gitRepositoryName,
                              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.form.gitRepositoryName) = $event)),
                              size: "small",
                              placeholder: _ctx.$t('empty')
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.localGitRepositories, (item) => {
                                  return (_openBlock(), _createBlock(_component_el_option, {
                                    key: item.name ?? '',
                                    label: item.name ?? '',
                                    value: item.name
                                  }, null, 8, ["label", "value"]))
                                }), 128))
                              ]),
                              _: 1
                            }, 8, ["modelValue", "placeholder"])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        _createVNode(_component_el_form_item, {
                          label: _ctx.$t('awsRegion'),
                          prop: "awsRegion",
                          class: "mb-0"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_input, {
                              modelValue: $setup.form.awsRegion,
                              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($setup.form.awsRegion) = $event)),
                              size: "small"
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        }, 8, ["label"]),
                        _createVNode(_component_el_form_item, {
                          label: _ctx.$t('awsAccountId'),
                          prop: "awsAccountId",
                          class: "mb-0"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_input, {
                              modelValue: $setup.form.awsAccountId,
                              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($setup.form.awsAccountId) = $event)),
                              size: "small"
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]))
                ]),
                _: 1
              }, 8, ["model", "rules"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_col, {
            span: 6,
            class: "d-flex"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_button, {
                class: "ml-auto",
                type: "primary",
                disabled: $props.disabled,
                onClick: $setup.save
              }, {
                default: _withCtx(() => [
                  _createElementVNode("h4", null, _toDisplayString(_ctx.$t('save')), 1)
                ]),
                _: 1
              }, 8, ["disabled", "onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}