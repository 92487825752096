<template>
  <base-modal
    width="500px"
    :modal-title="
      taskValue.id ? $t('updateOrchestration') : $t('addNewOrchestration')
    "
    :confirm-text="$t('save')"
    :dialog-visible="dialogVisible"
    @confirm="confirm()"
    @close="handleClose"
  >
    <template #content>
      <el-form ref="formRef" :model="form" :rules="formRules">
        <el-form-item prop="type" :label="$t('orchestrationType')">
          <el-input v-model="form.type" size="small" />
        </el-form-item>
        <el-form-item size="small" :label="$t('plannedExecutionDate') + ':'">
          <el-date-picker
            v-model="form.plannedExecutionDate"
            class="date-picker"
            type="datetime"
            :placeholder="$t('pickADate')"
          />
        </el-form-item>
        <el-form-item :label="$t('parameters') + ':'" prop="params">
          <code-editor
            v-model="form.params"
            font_size="13px"
            :languages="[['JSON', 'JSON']]"
            theme="light"
            min_height="300px"
            min_width="100%"
          ></code-editor>
        </el-form-item>
      </el-form>
    </template>
  </base-modal>
</template>

<script lang="ts">
import CodeEditor from 'simple-code-editor/CodeEditor.vue';
import {
  ElDatePicker,
  ElForm,
  ElFormItem,
  ElInput,
  ElMessage
} from 'element-plus';

import BaseModal from '@/components/modals/BaseModal.vue';
import { ref, reactive, onUpdated } from 'vue';
import { mustBeValidJson } from '@/utils/formValidators';
import i18n from '@/i18n';
import { ScheduledTaskDto } from '@etp/etp-taskscheduler-client/axios';

export default {
  name: 'ImportTaskCreate',
  components: {
    BaseModal,
    ElForm,
    ElFormItem,
    ElInput,
    ElDatePicker,
    CodeEditor
  },
  props: {
    dialogVisible: { type: Boolean },
    taskValue: {
      type: Object as () => ScheduledTaskDto,
      default: {} as ScheduledTaskDto
    }
  },
  emits: ['confirm', 'close'],
  setup(props, { emit }) {
    let form = ref({
      type: '',
      plannedExecutionDate: new Date(),
      params: ''
    });

    const formRules = reactive({
      params: [{ validator: mustBeValidJson, trigger: 'blur' }]
    });

    const formRef = ref<HTMLFormElement>();
    const confirm = () => {
      formRef.value?.validate((valid: boolean) => {
        if (valid) {
          emit('confirm', {
            ...props.taskValue,
            type: form.value.type,
            plannedExecutionDate: form.value.plannedExecutionDate.toISOString(),
            params: JSON.parse(form.value.params)
          } as ScheduledTaskDto);
          ElMessage({
            showClose: true,
            message: props.taskValue.id
              ? i18n.global.t('orchestrationUpdated')
              : i18n.global.t('orchestrationAdded')
          });
        }
      });
    };
    const handleClose = () => {
      emit('close');
    };

    onUpdated(() => {
      form.value.type = props.taskValue.type ?? '';
      if (props.taskValue.params) {
        form.value.params = JSON.stringify(props.taskValue.params);
      }
      if (props.taskValue.plannedExecutionDate) {
        form.value.plannedExecutionDate = new Date(
          props.taskValue.plannedExecutionDate
        );
      }
    });

    return { form, formRules, confirm, handleClose, formRef };
  }
};
</script>

<style scoped lang="scss">
.el-picker-panel.el-date-picker.has-time {
  .el-picker-panel__body-wrapper {
    .el-picker-panel__body {
      .el-picker-panel__content {
        .el-date-table {
          width: 100%;
        }
      }
    }
  }
}
</style>
