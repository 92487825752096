<template>
  <el-row :gutter="spacingWidthHeaderComponents">
    <el-col :span="5">
      <base-building-panel
        v-model:selectedBranch="selectedBranch"
        :branches="branches"
        :loading-branches="loadingBranches"
      />
    </el-col>
    <el-col :span="19">
      <base-building-table
        v-model="nodes"
        :show-build-button="userCanStartEngagementPipelines"
        :loading-nodes="loadingNodes"
        @start-pipeline-run="startPipelineRun"
      />
    </el-col>
  </el-row>
</template>

<script lang="ts">
import { ElCol, ElRow } from 'element-plus';
import BaseBuildingPanel from '@/components/panels/BaseBuildingPanel.vue';
import BaseBuildingTable from '@/components/tables/BaseBuildingTable.vue';
import { computed, ComputedRef, onMounted } from 'vue';
import { spacingWidthHeaderComponents } from '@/utils/variables';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { appendUrlParam, processUrlParams } from '@/utils/urlParameter';
import {
  LayerName,
  StartPipelineRunDto
} from '@etp/etp-pipelines-client/axios';
import config from '@/config/config';
import { BranchModel } from '@etp/etp-nodemodelgit-client/axios';

export default {
  name: 'LabelingBuild',
  components: {
    BaseBuildingPanel,
    BaseBuildingTable,
    ElCol,
    ElRow
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    let pipelineParameters = {
      EngagementId: store.getters['home/getCurrentEngagement'].id,
      TenantId: store.getters['home/getCurrentEngagement'].tenantId,
      Branch: store.getters['labeling/selectedBuildBranch'].name,
      Source: '',
      Category: '',
      ActiveNodes: store.getters['labeling/buildNodes']
        .filter(n => n.active)
        .map(n => n.name),
      RebuildNodes: store.getters['labeling/buildNodes']
        .filter(n => n.active && n.rebuild)
        .map(n => n.name)
    };

    const branches = computed(() => store.getters['labeling/branches']);
    const nodes = computed({
      get: () => store.getters['labeling/buildNodes'],
      set: items => {
        store.commit('labeling/SET_BUILD_NODES', items);
        pipelineParameters.ActiveNodes = items
          .filter(n => n.active)
          .map(n => n.name);
        pipelineParameters.RebuildNodes = items
          .filter(n => n.active && n.rebuild)
          .map(n => n.name);
      }
    });

    let loadingBranches: ComputedRef<boolean> = computed(
      () => store.getters['labeling/loadingBranches']
    );
    let loadingNodes: ComputedRef<boolean> = computed(
      () => store.getters['labeling/loadingNodes']
    );

    const fetchBranches = async () => {
      await store.dispatch('labeling/fetchBranches');
    };
    const fetchNodes = async () => {
      await store.dispatch('labeling/fetchBuildNodes');
    };
    const startPipelineRun = async () => {
      await store.dispatch('labeling/startPipelineRun', {
        currentLayer: LayerName.LabelingLayer,
        pipelineName: config.Pipeline.ClassificationPipelineName,
        parameters: pipelineParameters
      } as StartPipelineRunDto);
    };

    const selectedBranch = computed({
      get: () => store.getters['labeling/selectedBuildBranch'],
      set: async (branch: BranchModel) => {
        appendUrlParam('branch', branch.name ?? '');
        store.commit('labeling/SET_SELECTED_BUILD_BRANCH', branch);
        pipelineParameters.Branch = branch.name;
        await fetchNodes();
      }
    });

    const showNodeModelGitPages: ComputedRef<boolean> = computed(
      () => store.getters['home/showNodeModelGitPages']
    );
    const userCanStartEngagementPipelines: ComputedRef<boolean> = computed(
      () => store.getters['userPermissions/canStartEngagementPipelines']
    );
    onMounted(async () => {
      if (!showNodeModelGitPages.value) {
        return router.push({ name: 'LabelingOverview' });
      }
      fetchBranches().then(async () => {
        await processUrlParams([
          {
            name: 'branch',
            handler: async (branchName: string) => {
              let branch = branches.value.find(
                (el: BranchModel) => el.name == branchName
              );
              pipelineParameters.Branch = branchName;
              if (!branch) return;
              store.commit('labeling/SET_SELECTED_BUILD_BRANCH', branch);
              await fetchNodes();
            }
          }
        ]);
      });
      await store.dispatch(
        'userPermissions/checkIfCanStartEngagementPipelines'
      );
    });

    return {
      branches,
      startPipelineRun,
      loadingBranches,
      loadingNodes,
      nodes,
      selectedBranch,
      spacingWidthHeaderComponents,
      userCanStartEngagementPipelines
    };
  }
};
</script>
