<template>
  <base-modal
    :modal-title="$t('resource')"
    :dialog-visible="modelValue"
    :confirm-text="$t('save')"
    width="400px"
    @close="close()"
    @confirm="confirm()"
  >
    <template #content>
      <el-form
        ref="formRef"
        :model="form"
        :rules="formRules"
        label-position="top"
      >
        <el-form-item :label="$t('type')" prop="type">
          <el-select v-model="form.resourceType" filterable size="small">
            <el-option
              v-for="(index, item) in orderedResourceTypes"
              :key="index"
              :value="index"
              :label="$t(item)"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('name')" prop="name">
          <el-input v-model="form.name" size="small"></el-input>
        </el-form-item>
        <el-form-item :label="$t('tag')" prop="tag">
          <el-input v-model="form.tag" type="textarea"></el-input>
        </el-form-item>
      </el-form>
    </template>
  </base-modal>
</template>
<script lang="ts">
import BaseModal from '@/components/modals/BaseModal.vue';
import { ElForm, ElFormItem, ElInput, ElSelect, ElOption } from 'element-plus';
import { ref, reactive } from 'vue';
import { mustStartWith } from '@/utils/formValidators';
import { ResourceModel, ResourceType } from '@etp/etp-infra-client';
import i18n from '@/i18n';

export default {
  name: 'NewResourceModal',
  components: { BaseModal, ElInput, ElForm, ElFormItem, ElOption, ElSelect },
  props: {
    modelValue: { type: Boolean, default: false }
  },
  emits: ['close', 'confirm', 'update:model-value'],
  setup(props, { emit }) {
    const form = reactive({
      name: '',
      resourceType: 0,
      tag: ''
    });
    const formRef = ref<HTMLFormElement>();
    const formRules = reactive({
      resourceType: [
        {
          required: true,
          message: i18n.global.t('thisFieldIsRequired'),
          trigger: 'blur'
        }
      ],
      name: [
        {
          validator: mustStartWith(
            'nletp',
            i18n.global.t('resourceNameMustStartWithnletp')
          ),
          trigger: 'blur'
        }
      ],
      tag: [
        {
          required: true,
          message: i18n.global.t('thisFieldIsRequired'),
          trigger: 'blur'
        },
        {
          max: 100,
          message: i18n.global.t('tagMaxLengthExceeded'),
          trigger: 'blur'
        }
      ]
    });

    const resetValues = () => {
      form.name = '';
      form.resourceType = 0;
      form.tag = '';
    };

    const orderedResourceTypes = Object.keys(ResourceType)
      .sort()
      .reduce((obj, key) => {
        obj[key] = ResourceType[key];
        return obj;
      }, {});

    const confirm = () => {
      formRef.value?.validate((valid: boolean) => {
        if (valid) {
          emit('confirm', {
            name: form.name,
            resourceType: form.resourceType,
            tag: form.tag,
            configuration: ''
          } as ResourceModel);
          resetValues();
        }
      });
    };
    const close = () => {
      emit('close', 'update:model-value', false);
      resetValues();
    };
    return { form, formRules, formRef, orderedResourceTypes, confirm, close };
  }
};
</script>
<style scoped lang="scss"></style>
