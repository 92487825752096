import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_base_table = _resolveComponent("base-table")!
  const _component_el_col = _resolveComponent("el-col")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_row, { class: "d-flex" }, {
      default: _withCtx(() => [
        _createElementVNode("h4", _hoisted_1, _toDisplayString($setup.resources.length) + " · " + _toDisplayString(_ctx.$t('resources')), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, null, {
          default: _withCtx(() => [
            _createVNode(_component_base_table, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_table, {
                  ref: "multipleTableRef",
                  data: $setup.resources,
                  onSelectionChange: $setup.handleSelectionChange
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_table_column, { type: "selection" }),
                    _createVNode(_component_el_table_column, {
                      label: _ctx.$t('name'),
                      prop: "name"
                    }, null, 8, ["label"]),
                    _createVNode(_component_el_table_column, {
                      label: _ctx.$t('resourceType'),
                      prop: "resourceType"
                    }, {
                      default: _withCtx(({ row }) => [
                        _createTextVNode(_toDisplayString(_ctx.$t(Object.keys($setup.ResourceType)[row.resourceType])), 1)
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _: 1
                }, 8, ["data", "onSelectionChange"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}