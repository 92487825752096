<template>
  <div class="alignment-routelink">
    <p :class="[active ? 'active-tab' : 'no-active-tab']">
      <slot />
    </p>
    <hr :class="[active ? 'active-tab' : 'not-active-tab']" />
  </div>
</template>
<script lang="ts">
export default {
  name: 'BaseTab',
  props: { active: { type: Boolean } }
};
</script>
<style scoped lang="scss">
.alignment-routelink {
  transition: 0.5s;
  min-width: 7.5em;
  width: max-content;
  &:hover {
    p {
      color: $etp-deloitte-blue-7;
    }
    hr {
      border: 1px solid $etp-deloitte-blue-7;
    }
  }
  p {
    text-align: center;
    color: $etp-shade-2;
  }
  p.active-tab {
    color: $etp-deloitte-blue-7;
  }
  hr {
    min-width: 120px;
    border: 0.5px solid $etp-shade-2;
  }

  hr.active-tab {
    border: 1px solid $etp-deloitte-blue-7;
  }
}
</style>
