<template>
  <el-table
    :data="pipelineRuns"
    :empty-text="$t('noMonitor')"
    @sort-change="setSorting"
  >
    <el-table-column
      prop="status"
      :align="'center'"
      header-align="'left'"
      :width="65"
      sortable="custom"
    >
      <template #default="scope">
        <div
          class="pipeline-status-indicator"
          :class="getStatusColor(scope.row.status)"
        ></div>
      </template>
    </el-table-column>
    <el-table-column
      prop="pipelineName"
      :label="$t('name')"
      sortable="custom"
      show-overflow-tooltip
    >
      <template #default="scope">
        <h4>{{ scope.row.pipelineName }}</h4>
      </template>
    </el-table-column>
    <el-table-column
      prop="message"
      :label="$t('message')"
      show-overflow-tooltip
    >
      <template #default="scope">
        <h4>{{ scope.row.message }}</h4>
      </template>
    </el-table-column>
    <el-table-column
      width="200"
      prop="runStart"
      :label="$t('runStart')"
      sortable="custom"
    >
      <template #default="scope">
        <h4>{{ runDateFormat(scope.row.runStart) }}</h4>
      </template>
    </el-table-column>
    <el-table-column
      v-if="showAdfButton"
      width="150"
      class-name="overflow"
      prop="url"
      align="right"
    >
      <template #default="scope">
        <el-button type="primary" @click="goToPipelineRun(scope.row.url)">
          <h4>{{ $t('view') }}</h4>
        </el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script lang="ts">
import { ElButton, ElTable, ElTableColumn } from 'element-plus';

import moment from 'moment';
import { SortDirection, SortParams } from '@/services/web-api/dto/sortParams';
import { PipelineStatus } from '@/services/web-api/enums/pipelineStatus';
import { PipelineRunDto } from '@etp/etp-pipelines-client/axios';

export default {
  name: 'MonitorPipelinesTable',
  components: { ElTable, ElTableColumn, ElButton },
  props: {
    pipelineRuns: {
      type: Array as () => Array<PipelineRunDto>,
      required: true
    },
    sortParams: {
      type: SortParams,
      default: new SortParams('', SortDirection.DESC)
    },
    showAdfButton: {
      type: Boolean,
      default: false
    }
  },
  emits: ['updateSortParams'],
  setup(props, { emit }) {
    const getStatusColor = (pipelineStatus: PipelineStatus): string => {
      switch (pipelineStatus) {
        case PipelineStatus.Succeeded:
          return PipelineStatus.Succeeded;
        case PipelineStatus.Failed:
        case PipelineStatus.Cancelled:
        case PipelineStatus.Canceling:
          return PipelineStatus.Failed;
        case PipelineStatus.InProgress:
        case PipelineStatus.Queued:
          return PipelineStatus.InProgress;
        default:
          return 'unknown';
      }
    };
    const goToPipelineRun = (url: string) => {
      window.open(url, '_blank');
    };
    const runDateFormat = (runDate: string) => {
      return moment(runDate).format('DD-MM-YYYY, HH:mm:ss');
    };
    const setSorting = (data: any) => {
      let newSort = {
        ...props.sortParams,
        sortBy: data.prop
      } as SortParams;
      switch (data.order) {
        case 'ascending':
          newSort.sortDirection = SortDirection.ASC;
          break;
        case 'descending':
          newSort.sortDirection = SortDirection.DESC;
          break;
        default:
          newSort.sortBy = '';
          break;
      }
      emit('updateSortParams', newSort);
    };
    return {
      getStatusColor,
      goToPipelineRun,
      runDateFormat,
      setSorting
    };
  }
};
</script>

<style scoped lang="scss">
.body-1 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
