import { ConfigureField } from '@/services/web-api/dto/nodeModel';
import { FieldModel } from '@etp/etp-nodemodelgit-client/axios';

export const fieldActive = (payload: string, query: string): string => {
  const regex = new RegExp(`\\b${payload}\\b`, 'i');
  return query.search(regex) > -1 ? 'active' : 'inactive';
};

const dataType = (field: string): string => {
  let map = new Map([
      ['PERIOD', 'datetime'],
      ['YEAR', 'datetime'],
      ['DATE', 'datetime'],
      ['AMOUNT', 'decimal(38,5)'],
      ['RATE', 'decimal(38,5)']
    ]),
    result;
  for (let datatype of map.keys()) {
    if (field.includes(datatype)) {
      result = map.get(datatype);
      break;
    } else result = 'nvarchar(255)';
  }
  return result;
};

export const computeFields = (fieldArray: string[], query: string) => {
  return fieldArray.map(field => {
    return {
      status: fieldActive(field, query),
      name: field,
      dataType: dataType(field)
    };
  });
};

export const computeGitFields = (fieldArray: FieldModel[], query: string) => {
  return fieldArray.map(field => {
    return {
      status: fieldActive(field.name ?? '', query),
      name: field.name,
      dataType: dataType(field.dataType ?? '')
    } as ConfigureField;
  });
};

export const getFieldsFromQuery = (query: string) => {
  const fieldsFromQry: string[] = [];

  // this algorithm works as follows:
  // 1. remove possible characters trailing a line
  // 2. split by line
  // 3. trim spaces from the lines and split the lines in words
  // 4. go over every line and check for comments, if so exit the line
  // 5. go over every line and check if there is an 'as' and if some work comes after the 'as'
  // 6. check if the alias is in the right text format (only letters, numbers, -, _, [, ])
  // 7. if so, add the alias to the fields array and remove possible brackets.

  query
    .replace(/[,;]/g, '')
    .split(/[\n\r\n]+/)
    .map(x => x.trim().split(' '))
    .forEach(x => {
      for (let i = 0; i < x.length; i++) {
        if (x[i].includes('--')) break;
        if (x[i].toLowerCase() == 'as' && x[i + 1] !== undefined) {
          if (
            !x[i + 1].match('^[a-zA-Z0-9_-]+$') &&
            !x[i + 1].match(/[[\]']+/g)
          )
            break;
          fieldsFromQry.push(x[i + 1].replace(/[[\]']+/g, ''));
        }
      }
    });
  // We want to remove any duplicate fields from fieldsFromQry
  // For example, consider this:
  // SELECT foo AS bar
  // UNION
  // SELECT baz AS bar
  // --
  // without removing duplicates, the fieldsFromQry would incorrectly contain 2 bar fields.
  let uniqueFields = [...new Set(fieldsFromQry)];
  return uniqueFields;
};

export const getNewFieldsInQuery = (
  allFields: string[],
  existingFields: string[]
) => {
  return allFields.filter(function (obj) {
    return existingFields.indexOf(obj) == -1;
  });
};

export const getNewGitFieldsInQuery = (
  allFields: string[],
  existingFields: FieldModel[]
): FieldModel[] => {
  let newFieldsAsFieldShape = Array<FieldModel>();
  allFields
    .filter((el: string) => !existingFields.some(field => field.name === el))
    .forEach(field =>
      newFieldsAsFieldShape.push({
        name: field,
        dataType: 'unknown'
      } as FieldModel)
    );

  return newFieldsAsFieldShape;
};

export const discoverIndex = (
  targetArray: string[],
  originalArray: string[],
  fieldName: string
): number => {
  const originalIndex = originalArray.indexOf(fieldName);
  for (let i = targetArray.length - 1; i >= 0; i--) {
    if (originalArray.indexOf(targetArray[i]) < originalIndex) return i + 1;
  }
  return 0;
};

export const discoverGitIndex = (
  targetArray: FieldModel[],
  originalArray: string[],
  fieldName: string
): number => {
  const originalIndex = originalArray.indexOf(fieldName);
  for (let i = targetArray.length - 1; i >= 0; i--) {
    if (originalArray.indexOf(targetArray[i].name ?? '') < originalIndex)
      return i + 1;
  }
  return 0;
};
