import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_input_number = _resolveComponent("el-input-number")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_base_card = _resolveComponent("base-card")!
  const _component_gain_configuration_versions_modal = _resolveComponent("gain-configuration-versions-modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_base_card, { "no-header": "" }, {
      body: _withCtx(() => [
        _createVNode(_component_el_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, { span: 6 }, {
              default: _withCtx(() => [
                _createElementVNode("h3", null, _toDisplayString(_ctx.$t('gain')), 1),
                _createElementVNode("span", {
                  class: "body-1 cursor-pointer",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.openVersionModal && $setup.openVersionModal(...args)))
                }, _toDisplayString(_ctx.$t('currentVersion') + ': ' + $setup.props.latestConfiguration.version), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 12 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form, { model: $setup.form }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_divider, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('transformationEngineSettings')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('transformationEngineTargetBranchName'),
                      prop: "inputFlow_Transformations_MainBranchName",
                      class: "mb-0"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: $setup.form.inputFlow_Transformations_MainBranchName,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.form.inputFlow_Transformations_MainBranchName) = $event)),
                          size: "small"
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('transformationEngineBatchSize'),
                      prop: "inputFlow_TransformationEngine_BatchSize",
                      class: "mb-0"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input_number, {
                          modelValue: $setup.form.inputFlow_TransformationEngine_BatchSize,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.form.inputFlow_TransformationEngine_BatchSize) = $event)),
                          min: 0,
                          size: "small"
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_el_divider, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('sftpSettings')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('scanPath'),
                      prop: "sftpListener_ScanPath",
                      class: "mb-0"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: $setup.form.sftpListener_ScanPath,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.form.sftpListener_ScanPath) = $event)),
                          size: "small"
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('archivePath'),
                      prop: "sftpArchiver_ArchivePath",
                      class: "mb-0"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: $setup.form.sftpArchiver_ArchivePath,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.form.sftpArchiver_ArchivePath) = $event)),
                          size: "small"
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_el_divider, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('encryptionSettings')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('encryptionKey_FilePath'),
                      prop: "sftpEncryption_PgpFilePath",
                      class: "mb-0"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_tooltip, {
                          placement: "top",
                          content: _ctx.$t('sftpPgpFilePath_TooltipContent')
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_input, {
                              modelValue: $setup.form.sftpEncryption_PgpFilePath,
                              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.form.sftpEncryption_PgpFilePath) = $event)),
                              size: "small"
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        }, 8, ["content"])
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('encryptionKey_Passphrase'),
                      prop: "sftpEncryption_PgpPassphrase",
                      class: "mb-0"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: $setup.form.sftpEncryption_PgpPassphrase,
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($setup.form.sftpEncryption_PgpPassphrase) = $event)),
                          type: "password",
                          size: "small",
                          "show-password": ""
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _: 1
                }, 8, ["model"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, {
              span: 6,
              class: "d-flex"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_button, {
                  class: "ml-auto",
                  type: "primary",
                  onClick: $setup.saveConfiguration
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("h4", null, _toDisplayString(_ctx.$t('save')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_gain_configuration_versions_modal, {
      modelValue: $setup.showVersionModal,
      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($setup.showVersionModal) = $event)),
      "configuration-versions": $setup.props.configurationHistory,
      onRestore: $setup.handleRestore,
      onClose: _cache[8] || (_cache[8] = ($event: any) => ($setup.showVersionModal = false))
    }, null, 8, ["modelValue", "configuration-versions", "onRestore"])
  ]))
}