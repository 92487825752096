import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "body-1"
}
const _hoisted_2 = { class: "align-right" }
const _hoisted_3 = { class: "align-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_base_card = _resolveComponent("base-card")!

  return (_openBlock(), _createBlock(_component_base_card, {
    "no-header": "",
    class: "width-100"
  }, {
    body: _withCtx(() => [
      _createVNode(_component_el_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_col, { span: 10 }, {
            default: _withCtx(() => [
              _createElementVNode("h3", null, _toDisplayString($props.entry.batchId), 1),
              _createVNode(_component_el_tooltip, {
                placement: "top",
                content: $props.entry.statusMessage || ''
              }, {
                default: _withCtx(() => [
                  ($props.entry.status)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString($setup.formatBatchStatus($props.entry.status)), 1))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["content"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_col, { span: 12 }, {
            default: _withCtx(() => [
              _createVNode(_component_el_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_col, { span: 9 }, {
                    default: _withCtx(() => [
                      _createElementVNode("h4", null, _toDisplayString(_ctx.$t('created')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_col, { span: 9 }, {
                    default: _withCtx(() => [
                      _createElementVNode("h4", null, _toDisplayString($setup.formatDate($props.entry.createdAt)), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_el_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_col, { span: 9 }, {
                    default: _withCtx(() => [
                      _createElementVNode("h4", null, _toDisplayString(_ctx.$t('receivedFromClient')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_col, { span: 9 }, {
                    default: _withCtx(() => [
                      _createElementVNode("h4", null, _toDisplayString($setup.formatDate($props.entry.recordsReceivedFromClientAt)), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_col, { span: 6 }, {
                    default: _withCtx(() => [
                      _createElementVNode("h4", _hoisted_2, _toDisplayString($setup.formatRecords($props.entry.numRecordsReceivedFromClient)), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_el_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_col, { span: 9 }, {
                    default: _withCtx(() => [
                      _createElementVNode("h4", null, _toDisplayString(_ctx.$t('sentToGain')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_col, { span: 9 }, {
                    default: _withCtx(() => [
                      _createElementVNode("h4", null, _toDisplayString($setup.formatDate($props.entry.recordsSentToGainAt)), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_col, { span: 6 }, {
                    default: _withCtx(() => [
                      _createElementVNode("h4", _hoisted_3, _toDisplayString($setup.formatRecords($props.entry.numRecordsSentToGain)), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}