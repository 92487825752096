<template>
  <base-modal
    width="400px"
    :modal-title="$t('newApplication')"
    :dialog-visible="modelValue"
    :confirm-text="$t('save')"
    @close="$emit('close')"
    @confirm="confirm()"
  >
    <template #content>
      <el-form
        ref="formRef"
        label-position="top"
        :model="form"
        :rules="formRules"
      >
        <el-form-item :label="$t('name')" prop="name">
          <el-input v-model="form.name" size="small" :minlength="10" />
        </el-form-item>
        <el-form-item :label="$t('objectId')" prop="objectId">
          <el-input v-model="form.objectId" size="small" :minlength="30" />
        </el-form-item>
      </el-form>
    </template>
  </base-modal>
</template>

<script lang="ts">
import BaseModal from '@/components/modals/BaseModal.vue';

import { ElForm, ElFormItem, ElInput, ElMessage } from 'element-plus';

import { reactive, ref } from 'vue';
import i18n from '@/i18n';
import { mustBeGuid } from '@/utils/formValidators';
import { ApplicationModel } from '@etp/etp-authorization-client/axios';
export default {
  name: 'NewApplicationModal',
  components: {
    BaseModal,
    ElInput,
    ElForm,
    ElFormItem
  },
  props: {
    modelValue: { type: Boolean, default: false },
    feedbackText: { type: String, default: i18n.global.t('saved') }
  },
  emits: ['close', 'confirm'],
  setup(props, { emit }) {
    const form = ref({
      name: '',
      objectId: ''
    });
    const formRules = reactive({
      name: [
        {
          required: true,
          message: i18n.global.t('thisFieldIsRequired'),
          trigger: 'blur'
        },
        {
          min: 1,
          message: i18n.global
            .t('pleaseEnterNumCharacters')
            .replace('[num]', '1'),
          trigger: 'blur'
        }
      ],
      objectId: [
        {
          validator: mustBeGuid,
          trigger: 'blur'
        }
      ]
    });
    const formRef = ref<HTMLFormElement>();
    const confirm = () => {
      formRef.value?.validate((valid: boolean) => {
        if (valid) {
          emit('confirm', {
            name: form.value.name,
            objectId: form.value.objectId
          } as ApplicationModel);
          ElMessage({
            showClose: true,
            message: props.feedbackText
          });
        }
      });
    };

    return {
      confirm,
      form,
      formRules,
      formRef
    };
  }
};
</script>

<style scoped></style>
