import TestingBuild from '../TestingBuild.vue';
import TestingOverview from '../TestingOverview.vue';
import TestingConfig from '../TestingConfig.vue';
import RunOverview from '@/views/home/shared/RunOverview.vue';
import MonitorOverview from '@/views/home/shared/MonitorOverview.vue';

export default {
  path: '/testing',
  name: 'Testing',
  redirect: { name: 'TestingMonitorOverview' },
  component: TestingOverview,
  meta: { menuItemName: 'Testing' },
  children: [
    {
      path: 'config',
      name: 'TestingConfig',
      component: TestingConfig
    },
    {
      path: 'building',
      name: 'TestingBuild',
      component: TestingBuild
    },
    {
      path: 'run',
      name: 'TestingRunOverview',
      component: RunOverview
    },
    {
      path: 'monitor',
      name: 'TestingMonitorOverview',
      component: MonitorOverview
    }
  ]
};
