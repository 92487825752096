import { Buffer } from 'buffer';

const encodeStringToBase64 = inputString => {
  return Buffer.from(inputString).toString('base64');
};

const decodeBase64ToString = encodedString => {
  let decodedString = Buffer.from(encodedString, 'base64').toString('ascii');
  return decodedString;
};

const encodeStringFieldsToBase64 = obj => {
  for (const key of Object.keys(obj)) {
    if (typeof obj[key] === 'string') {
      obj[key] = encodeStringToBase64(obj[key]);
    }
  }
  return obj;
};

export {
  encodeStringToBase64,
  decodeBase64ToString,
  encodeStringFieldsToBase64
};
