import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, renderSlot as _renderSlot, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-812ef36e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "mt-auto"
}
const _hoisted_2 = {
  key: 1,
  class: "mt-auto"
}
const _hoisted_3 = { class: "options-header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createBlock(_component_el_row, {
    justify: "space-between",
    align: "middle"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_col, { span: 5 }, {
        default: _withCtx(() => [
          ($props.loadingState)
            ? (_openBlock(), _createElementBlock("h4", _hoisted_1, _toDisplayString(_ctx.$t('loading')), 1))
            : (_openBlock(), _createElementBlock("h4", _hoisted_2, _toDisplayString($props.rowCount) + " · " + _toDisplayString($props.quantityMessage), 1))
        ]),
        _: 1
      }),
      _createVNode(_component_el_col, { span: 17 }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            (!$props.noFilter)
              ? (_openBlock(), _createBlock(_component_el_input, {
                  key: 0,
                  modelValue: $setup.searchQuery,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.searchQuery) = $event)),
                  size: "small",
                  class: "ml-2",
                  placeholder: $props.inputPlaceholder,
                  onInput: $setup.handleInput
                }, null, 8, ["modelValue", "placeholder", "onInput"]))
              : _createCommentVNode("", true),
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ])
        ]),
        _: 3
      })
    ]),
    _: 3
  }))
}