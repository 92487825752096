import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, createSlots as _createSlots } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mt-auto"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_base_building_panel = _resolveComponent("base-building-panel")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_table_placeholder_loading = _resolveComponent("table-placeholder-loading")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_base_card = _resolveComponent("base-card")!

  return (_openBlock(), _createBlock(_component_el_row, { gutter: $setup.spacingWidthHeaderComponents }, {
    default: _withCtx(() => [
      _createVNode(_component_el_col, { span: 5 }, {
        default: _withCtx(() => [
          _createVNode(_component_base_building_panel, {
            selectedBranch: $setup.selectedBranch,
            "onUpdate:selectedBranch": _cache[1] || (_cache[1] = ($event: any) => (($setup.selectedBranch) = $event)),
            branches: $setup.branches,
            "loading-branches": $setup.loadingBranches
          }, {
            item: _withCtx(() => [
              _createVNode(_component_el_form_item, {
                label: _ctx.$t('node')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_select, {
                    modelValue: $setup.selectedNode,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.selectedNode) = $event)),
                    disabled: !$setup.selectedBranch.name || $setup.loadingNodes,
                    "value-key": "name",
                    size: "small",
                    placeholder: $setup.loadingNodes ? _ctx.$t('loading') : _ctx.$t('empty')
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.nodes, (item) => {
                        return (_openBlock(), _createBlock(_component_el_option, {
                          key: item.name ?? '',
                          label: item.name ?? '',
                          value: item
                        }, null, 8, ["label", "value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue", "disabled", "placeholder"])
                ]),
                _: 1
              }, 8, ["label"])
            ]),
            _: 1
          }, 8, ["selectedBranch", "branches", "loading-branches"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_col, { span: 19 }, {
        default: _withCtx(() => [
          _createVNode(_component_base_card, { "no-header": "" }, {
            body: _withCtx(() => [
              _createVNode(_component_el_row, { class: "d-flex pr-2" }, {
                default: _withCtx(() => [
                  ($setup.userCanStartEngagementPipelines && $setup.tests.length)
                    ? (_openBlock(), _createBlock(_component_el_button, {
                        key: 0,
                        disabled: !$setup.tests.some(el => el.active),
                        type: "primary",
                        class: "mt-2 ml-auto",
                        onClick: _withModifiers($setup.startPipelineRun, ["stop"])
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("h4", null, _toDisplayString(_ctx.$t('run')), 1)
                        ]),
                        _: 1
                      }, 8, ["disabled", "onClick"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_el_row, null, {
                default: _withCtx(() => [
                  ($setup.loadingTests)
                    ? (_openBlock(), _createElementBlock("h4", _hoisted_1, _toDisplayString(_ctx.$t('loading')), 1))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_el_row, null, {
                default: _withCtx(() => [
                  ($setup.loadingTests)
                    ? (_openBlock(), _createBlock(_component_table_placeholder_loading, {
                        key: 0,
                        columns: 1
                      }))
                    : (_openBlock(), _createBlock(_component_el_table, {
                        key: 1,
                        class: "build-table",
                        data: $setup.tests,
                        "empty-text": _ctx.$t('noTests')
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_table_column, {
                            prop: "active",
                            width: "120"
                          }, _createSlots({
                            default: _withCtx(({ row, $index }) => [
                              _createVNode(_component_el_checkbox, {
                                "model-value": row.active,
                                onChange: ($event: any) => (
                      $setup.updateRow({
                        index: $index,
                        row: { ...row, active: $event }
                      })
                    )
                              }, null, 8, ["model-value", "onChange"])
                            ]),
                            _: 2
                          }, [
                            ($setup.tests.length)
                              ? {
                                  name: "header",
                                  fn: _withCtx(() => [
                                    _createVNode(_component_el_checkbox, {
                                      class: "checkbox",
                                      label: _ctx.$t('active'),
                                      "model-value": $setup.allActive,
                                      onChange: $setup.updateAllActive
                                    }, null, 8, ["label", "model-value", "onChange"])
                                  ]),
                                  key: "0"
                                }
                              : undefined
                          ]), 1024),
                          _createVNode(_component_el_table_column, { prop: "name" }, _createSlots({
                            default: _withCtx((scope) => [
                              _createElementVNode("div", null, _toDisplayString(scope.row.name), 1)
                            ]),
                            _: 2
                          }, [
                            ($setup.tests.length)
                              ? {
                                  name: "header",
                                  fn: _withCtx(() => [
                                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('test')), 1)
                                  ]),
                                  key: "0"
                                }
                              : undefined
                          ]), 1024)
                        ]),
                        _: 1
                      }, 8, ["data", "empty-text"]))
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["gutter"]))
}