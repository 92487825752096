<template>
  <base-modal
    :modal-title="$t('fields')"
    :confirm-text="$t('save')"
    :dialog-visible="dialogVisible"
    width="640px"
    @confirm="save"
    @close="close"
  >
    <template #content>
      <el-row>
        <h4>{{ $t('addNewField') }}:</h4>
      </el-row>
      <el-form
        ref="newFieldFormRef"
        :model="newFieldForm"
        :rules="newFieldFormRules"
        inline
      >
        <el-row class="mb-4">
          <el-col :span="10" class="pr-2">
            <el-form-item prop="name" class="width-100">
              <el-input
                v-model="newFieldForm.name"
                size="small"
                :placeholder="$t('name')"
              />
            </el-form-item>
          </el-col>
          <el-col :span="10" class="d-flex pr-2">
            <el-form-item prop="dataType" class="width-100 mr-0">
              <el-select
                v-if="!props.hideDataTypes"
                v-model="newFieldForm.dataType"
                size="small"
                :placeholder="$t('dataType')"
              >
                <el-option
                  v-for="dataType in allowedDataTypes"
                  :key="dataType"
                  :label="dataType"
                  :value="dataType"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col
            :span="4"
            class="d-flex align-items-center justify-content-center"
          >
            <el-button
              class="icon-button p-0 cursor-pointer"
              @click="addField()"
            >
              <plus class="icon-size-8" />
            </el-button>
          </el-col>
        </el-row>
      </el-form>
      <el-row v-if="localNotAppliedFields.length" class="mb-1">
        <h4>{{ $t('fieldsFromQry') }}:</h4>
      </el-row>
      <el-row v-if="localNotAppliedFields.length" class="mb-4">
        <el-tag
          v-for="(field, index) in localNotAppliedFields"
          :key="index"
          size="small"
          class="cursor-pointer"
          @click="addField(field)"
        >
          {{ field.name }}
        </el-tag>
      </el-row>
      <el-row v-if="fieldsListForm.localFields.length" class="mb-1">
        <h4>{{ $t('fields') }}:</h4>
      </el-row>
      <el-scrollbar max-height="500px">
        <el-form ref="fieldsListFormRef" :model="fieldsListForm">
          <el-row
            v-for="(field, index) in fieldsListForm.localFields"
            :key="field.name ?? ''"
            class="hover-grey"
          >
            <el-form-item
              class="width-100"
              :prop="'localFields.' + index"
              :rules="{
                validator: localFieldValidator,
                trigger: 'change'
              }"
            >
              <el-col :span="10">
                <span class="body-1 pl-1">
                  {{ field.name }}
                </span>
              </el-col>
              <el-col :span="10" class="pr-2">
                <el-select
                  v-if="!props.hideDataTypes"
                  v-model="field.dataType"
                  size="small"
                >
                  <el-option
                    v-for="dataType in allowedDataTypes"
                    :key="dataType"
                    :label="dataType"
                    :value="dataType"
                  />
                </el-select>
              </el-col>
              <el-col :span="4" class="d-flex">
                <div class="clickable ml-auto" @click="promoteField(index)">
                  <img
                    :src="require(`@/assets/images/icons/listupicon.svg`)"
                    width="20"
                    height="20"
                  />
                </div>
                <div class="clickable" @click="demoteField(index)">
                  <img
                    :src="require(`@/assets/images/icons/listdownicon.svg`)"
                    width="20"
                    height="20"
                  />
                </div>
                <div class="clickable" @click="removeField(index)">
                  <img
                    :src="require(`@/assets/images/icons/new/delete.svg`)"
                    width="12"
                    height="12"
                  />
                </div>
              </el-col>
            </el-form-item>
          </el-row>
        </el-form>
      </el-scrollbar>
    </template>
  </base-modal>
</template>
<script lang="ts">
import { ref, onMounted, onUpdated, reactive } from 'vue';
import BaseModal from '@/components/modals/BaseModal.vue';

import {
  ElCol,
  ElForm,
  ElFormItem,
  ElTag,
  ElInput,
  ElOption,
  ElRow,
  ElSelect,
  ElScrollbar,
  ElMessage,
  ElButton
} from 'element-plus';
import { Plus } from '@element-plus/icons-vue';
import config from '@/config/config';
import { getNewGitFieldsInQuery, discoverGitIndex } from '@/utils/fieldsHelper';
import {
  mustNotBeEmpty,
  mustHaveValue,
  mustHaveMinCharacters,
  fieldValidator,
  mustBeAlphanumericUnderscoreSquareBrackets
} from '@/utils/formValidators';
import i18n from '@/i18n';
import arraysEqual from '@/utils/arrayUtils';
import {
  CreateFieldModel,
  FieldModel
} from '@etp/etp-nodemodelgit-client/axios';

export default {
  name: 'FieldsModal',
  components: {
    BaseModal,
    ElCol,
    ElForm,
    ElFormItem,
    ElInput,
    ElSelect,
    ElScrollbar,
    ElOption,
    ElRow,
    ElTag,
    Plus,
    ElButton
  },
  props: {
    dialogVisible: { type: Boolean, default: false, required: true },
    minFieldLength: { type: Number, default: 4 },
    hideDataTypes: { type: Boolean, default: false },
    fields: {
      type: Array as () => Array<FieldModel>,
      default: () => new Array<FieldModel>()
    },
    allFieldsInQuery: {
      type: Array as () => Array<string>,
      default: () => new Array<string>()
    }
  },
  emits: ['close', 'confirm'],
  setup(props, { emit }) {
    const localNotAppliedFields = ref(Array<FieldModel>());

    const defaultAllowedDataTypes = [
      'BIT',
      'DATE',
      'DATETIME',
      'DECIMAL(38,5)',
      'INT',
      'NVARCHAR(2)',
      'NVARCHAR(50)',
      'NVARCHAR(255)',
      'NVARCHAR(4000)',
      'NVARCHAR(MAX)',
      'VARCHAR(10)',
      'VARCHAR(50)',
      'VARCHAR(MAX)'
    ];

    const awsAllowedDataTypes = ['INTEGER', 'REAL', 'TEXT'];

    const isCloudProviderAws = config.cloudProvider.toLowerCase() === 'aws';

    const allowedDataTypes = ref(
      isCloudProviderAws ? awsAllowedDataTypes : defaultAllowedDataTypes
    );

    const defaultDataType = isCloudProviderAws ? 'TEXT' : 'NVARCHAR(255)';

    const newFieldFormRef = ref<HTMLFormElement>();

    const fieldsListForm = reactive<{
      localFields: Array<FieldModel>;
    }>({
      localFields: new Array<FieldModel>()
    });

    const fieldsListFormRef = ref<HTMLFormElement>();

    const localFieldValidator = fieldValidator(
      allowedDataTypes.value,
      props.minFieldLength
    );

    const localMustNotBeInArray = (rule: any, value: string, callback: any) => {
      if (
        fieldsListForm.localFields.some(
          el => el.name?.toLowerCase() == value.toLowerCase()
        )
      ) {
        callback(new Error(i18n.global.t('noDuplicateField')));
      } else callback();
    };

    let newFieldForm = ref({
      name: '',
      dataType: defaultDataType
    });
    let newFieldFormRules = reactive({
      name: [
        { validator: mustNotBeEmpty, trigger: 'blur' },
        {
          validator: mustHaveMinCharacters(props.minFieldLength),
          trigger: 'blur'
        },
        {
          validator: mustBeAlphanumericUnderscoreSquareBrackets,
          trigger: 'blur'
        },
        { validator: localMustNotBeInArray, trigger: 'blur' }
      ],
      dataType: [
        { validator: mustNotBeEmpty, trigger: 'blur' },
        { validator: mustHaveValue(allowedDataTypes.value), trigger: 'blur' }
      ]
    });

    const updateFields = () => {
      fieldsListForm.localFields = [...props.fields] as CreateFieldModel[];
      localNotAppliedFields.value = [
        ...getNewGitFieldsInQuery(props.allFieldsInQuery, props.fields)
      ];
      // remove from notApplied list if name already exists in fields list
      localNotAppliedFields.value = localNotAppliedFields.value.filter(
        queryField =>
          !fieldsListForm.localFields.some(
            localField =>
              localField.name?.toLowerCase() == queryField.name?.toLowerCase()
          )
      );
    };

    const save = () => {
      fieldsListFormRef.value?.validate((valid: boolean) => {
        if (!valid) return;
        emit('confirm', fieldsListForm.localFields);
        ElMessage({
          showClose: true,
          message: i18n.global.t('savingChanges')
        });
      });
    };

    const close = () => {
      if (
        arraysEqual<FieldModel>(props.fields, fieldsListForm.localFields) ||
        confirm(i18n.global.t('forgotToSave'))
      ) {
        emit('close');
      }
    };

    onMounted(() => updateFields());
    onUpdated(() => {
      updateFields();
      newFieldFormRef.value?.resetFields();
    });

    const addField = (field?: FieldModel) => {
      if (field?.name) {
        const indexOfField = localNotAppliedFields.value.findIndex(
          notAppliedField => notAppliedField.name === field.name
        );
        const targetIndex = discoverGitIndex(
          fieldsListForm.localFields,
          props.allFieldsInQuery,
          field.name
        );
        field.dataType = defaultDataType;
        fieldsListForm.localFields.splice(targetIndex, 0, field);

        localNotAppliedFields.value.splice(indexOfField, 1);
        return;
      }
      newFieldFormRef.value?.validate((valid: boolean) => {
        if (!valid) return;
        fieldsListForm.localFields.push({
          ...newFieldForm.value
        } as FieldModel);
        if (
          localNotAppliedFields.value.some(
            el =>
              el.name?.toLowerCase() == newFieldForm.value.name.toLowerCase()
          )
        ) {
          const indexOfNotApplied = localNotAppliedFields.value.findIndex(
            el =>
              el.name?.toLowerCase() == newFieldForm.value.name.toLowerCase()
          );
          localNotAppliedFields.value.splice(indexOfNotApplied, 1);
        }
        newFieldForm.value = { name: '', dataType: defaultDataType };
      });
    };
    const removeField = (index: number) => {
      var fieldName = fieldsListForm.localFields[index].name;
      if (!fieldName) return;
      const targetIndex = discoverGitIndex(
        localNotAppliedFields.value,
        props.allFieldsInQuery,
        fieldName
      );
      localNotAppliedFields.value.splice(
        targetIndex,
        0,
        fieldsListForm.localFields[index]
      );
      fieldsListForm.localFields.splice(index, 1);
    };
    const swapValuesInArray = (
      array: Array<FieldModel>,
      x,
      y
    ): Array<FieldModel> => {
      const b = array[x];
      array[x] = array[y];
      array[y] = b;
      return array;
    };
    const promoteField = (index: number) => {
      if (index != 0) {
        const newArray = swapValuesInArray(
          fieldsListForm.localFields,
          index,
          index - 1
        );
        // is necessary to get the vue reactivty system working properly
        fieldsListForm.localFields = [];
        fieldsListForm.localFields = newArray as CreateFieldModel[];
      }
    };
    const demoteField = (index: number) => {
      if (index != fieldsListForm.localFields.length - 1) {
        const newArray = swapValuesInArray(
          fieldsListForm.localFields,
          index,
          index + 1
        );
        // is necessary to get the vue reactivty system working properly
        fieldsListForm.localFields = [];
        fieldsListForm.localFields = newArray as CreateFieldModel[];
      }
    };

    return {
      allowedDataTypes,
      newFieldForm,
      newFieldFormRef,
      newFieldFormRules,
      localNotAppliedFields,
      addField,
      removeField,
      promoteField,
      demoteField,
      swapValuesInArray,
      save,
      close,
      fieldsListForm,
      fieldsListFormRef,
      localFieldValidator,
      props
    };
  }
};
</script>
<style lang="scss" scoped>
.clickable {
  margin: 0px 4px 0px 4px;
  cursor: pointer;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.7;
  filter: invert(12%) sepia(50%) saturate(1258%) hue-rotate(184deg)
    brightness(19%) contrast(101%);
  &:hover {
    opacity: 1;
  }
}
.hover-grey:hover {
  background-color: $etp-radio-grey;
}
</style>
