<template>
  <div>
    <base-card no-header>
      <template #body>
        <el-row>
          <el-col :span="6">
            <h3>{{ $t('gain') }}</h3>
            <span class="body-1 cursor-pointer" @click="openVersionModal">
              {{
                $t('currentVersion') + ': ' + props.latestConfiguration.version
              }}
            </span>
          </el-col>
          <el-col :span="12">
            <el-form :model="form">
              <el-divider>{{ $t('transformationEngineSettings') }}</el-divider>
              <el-form-item
                :label="$t('transformationEngineTargetBranchName')"
                prop="inputFlow_Transformations_MainBranchName"
                class="mb-0"
              >
                <el-input
                  v-model="form.inputFlow_Transformations_MainBranchName"
                  size="small"
                />
              </el-form-item>
              <el-form-item
                :label="$t('transformationEngineBatchSize')"
                prop="inputFlow_TransformationEngine_BatchSize"
                class="mb-0"
              >
                <el-input-number
                  v-model="form.inputFlow_TransformationEngine_BatchSize"
                  :min="0"
                  size="small"
                />
              </el-form-item>
              <el-divider>{{ $t('sftpSettings') }}</el-divider>
              <el-form-item
                :label="$t('scanPath')"
                prop="sftpListener_ScanPath"
                class="mb-0"
              >
                <el-input v-model="form.sftpListener_ScanPath" size="small" />
              </el-form-item>
              <el-form-item
                :label="$t('archivePath')"
                prop="sftpArchiver_ArchivePath"
                class="mb-0"
              >
                <el-input
                  v-model="form.sftpArchiver_ArchivePath"
                  size="small"
                />
              </el-form-item>
              <el-divider>{{ $t('encryptionSettings') }}</el-divider>
              <el-form-item
                :label="$t('encryptionKey_FilePath')"
                prop="sftpEncryption_PgpFilePath"
                class="mb-0"
              >
                <el-tooltip
                  placement="top"
                  :content="$t('sftpPgpFilePath_TooltipContent')"
                >
                  <el-input
                    v-model="form.sftpEncryption_PgpFilePath"
                    size="small"
                  />
                </el-tooltip>
              </el-form-item>
              <el-form-item
                :label="$t('encryptionKey_Passphrase')"
                prop="sftpEncryption_PgpPassphrase"
                class="mb-0"
              >
                <el-input
                  v-model="form.sftpEncryption_PgpPassphrase"
                  type="password"
                  size="small"
                  show-password
                />
              </el-form-item>
            </el-form>
          </el-col>
          <el-col :span="6" class="d-flex">
            <el-button
              class="ml-auto"
              type="primary"
              @click="saveConfiguration"
            >
              <h4>{{ $t('save') }}</h4>
            </el-button>
          </el-col>
        </el-row>
      </template>
    </base-card>
    <gain-configuration-versions-modal
      v-model="showVersionModal"
      :configuration-versions="props.configurationHistory"
      @restore="handleRestore"
      @close="showVersionModal = false"
    />
  </div>
</template>
<script lang="ts">
import BaseCard from '@/components/panels/BaseCard.vue';
import GainConfigurationVersionsModal from './GainConfigurationVersionsModal.vue';
import { onUpdated, ref } from 'vue';
import {
  ElButton,
  ElCol,
  ElDivider,
  ElForm,
  ElFormItem,
  ElInput,
  ElInputNumber,
  ElRow,
  ElTooltip
} from 'element-plus';
import { SystemConfiguration } from '@etp/etp-gain-bff-client/axios';

export default {
  name: 'GainConfigurationCard',
  components: {
    BaseCard,
    ElButton,
    ElCol,
    ElDivider,
    ElForm,
    ElFormItem,
    ElInput,
    ElInputNumber,
    ElRow,
    ElTooltip,
    GainConfigurationVersionsModal
  },
  props: {
    latestConfiguration: {
      type: Object as () => SystemConfiguration,
      required: true
    },
    configurationHistory: {
      type: Array as () => Array<SystemConfiguration>,
      default: () => new Array<SystemConfiguration>()
    }
  },
  emits: ['update', 'fetch-history'],
  setup(props, { emit }) {
    const showVersionModal = ref(false);

    const form = ref({
      inputFlow_TransformationEngine_BatchSize: 0,
      inputFlow_Transformations_MainBranchName: '',
      sftpListener_ScanPath: '',
      sftpArchiver_ArchivePath: '',
      sftpEncryption_PgpFilePath: '',
      sftpEncryption_PgpPassphrase: ''
    } as SystemConfiguration);

    const openVersionModal = () => {
      emit('fetch-history');
      showVersionModal.value = true;
    };

    const handleRestore = (input: SystemConfiguration) => {
      emit('update', input);
      showVersionModal.value = false;
    };

    const saveConfiguration = async () => {
      emit('update', {
        ...props.latestConfiguration,
        ...form.value
      } as SystemConfiguration);
    };

    onUpdated(() => {
      form.value = props.latestConfiguration;
    });

    return {
      form,
      handleRestore,
      openVersionModal,
      props,
      saveConfiguration,
      showVersionModal
    };
  }
};
</script>
<style scoped lang="scss"></style>
