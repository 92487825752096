import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_base_modal = _resolveComponent("base-modal")!

  return (_openBlock(), _createBlock(_component_base_modal, {
    "modal-title": _ctx.$t('revertPromotionModalTitle'),
    "dialog-visible": $props.modelValue,
    "confirm-text": _ctx.$t('revert'),
    width: "600px",
    onClose: $setup.close,
    onConfirm: $setup.confirm
  }, {
    content: _withCtx(() => [
      _createVNode(_component_el_row, null, {
        default: _withCtx(() => [
          _createElementVNode("h4", null, _toDisplayString(_ctx.$t('revertPromotionModalText')), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_row, { class: "mt-4" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form, {
            ref: "formRef",
            model: $setup.form,
            rules: $setup.formRules,
            "label-position": "top",
            class: "width-100"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, {
                label: _ctx.$t('versionToRevert'),
                prop: "versionToRevert"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: $setup.form.versionToRevert,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.form.versionToRevert) = $event)),
                    disabled: ""
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_el_form_item, {
                label: _ctx.$t('versionToRestore'),
                prop: "versionToRestore"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: $setup.form.versionToRestore,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.form.versionToRestore) = $event)),
                    disabled: ""
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_el_form_item, {
                label: _ctx.$t('comment'),
                prop: "comment"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: $setup.form.comment,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.form.comment) = $event)),
                    rows: 4,
                    type: "textarea"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }, 8, ["label"])
            ]),
            _: 1
          }, 8, ["model", "rules"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modal-title", "dialog-visible", "confirm-text", "onClose", "onConfirm"]))
}