import { RoleAssignmentModel } from '@etp/etp-authorization-client/axios';

export interface AggregatedRoleAssignment {
  aggregationType: RoleAssignmentAggregation;
  aggregationId: string;
  aggregationDisplayName: string;
  roleAssignments: Array<RoleAssignmentModel>;
}

export enum RoleAssignmentAggregation {
  Other = 0,
  Tenant = 1,
  Engagement = 2
}
