import i18n from '@/i18n';
import { FieldModel } from '@etp/etp-nodemodelgit-client/axios';

const mustBeAlphaNumericAndLowerCase = (rule, value: string, callback) => {
  if (!value.match(/^[a-z0-9]+$/)) {
    callback(new Error(i18n.global.t('onlyAlphanumericCharactersLowerCase')));
  } else {
    callback();
  }
};

const mustBeAlphaNumericDashAndLowerCase = (rule, value: string, callback) => {
  if (!value.match(/^[a-z0-9-]+$/)) {
    callback(
      new Error(i18n.global.t('onlyAlphaNumericCharactersLowerCaseAndDash'))
    );
  } else {
    callback();
  }
};

const mustStartWith = (startsWith: string, errorMessage: string) => {
  return (rule: any, value: any, callback: any) => {
    if (!value.startsWith(startsWith)) {
      callback(new Error(errorMessage));
    } else {
      callback();
    }
  };
};

const mustBeGuid = (rule, value, callback) => {
  if (
    !/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(
      value
    )
  ) {
    callback(new Error(i18n.global.t('pleaseProvideGUID')));
  } else {
    callback();
  }
};

const mustBeValidEmail = (rule, value, callback) => {
  if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
    callback();
  } else {
    callback(new Error(i18n.global.t('enterValidEmailAddress')));
  }
};

const mustNotContainSpecialChars = (rule, value: string, callback) => {
  if (!value.match(/^[A-Za-z0-9- ]+$/)) {
    callback(new Error(i18n.global.t('noSpecialCharsAllowed')));
  } else {
    callback();
  }
};

const mustBeAlphanumericDot = (rule, value: string, callback) => {
  if (!value.match(/^[A-Za-z0-9.]+$/)) {
    callback(new Error(i18n.global.t('onlyLettersNumbersDots')));
  } else {
    callback();
  }
};

const mustBeAlphanumericUnderscore = (rule, value: string, callback) => {
  if (!value.match(/^[A-Za-z0-9_]+$/)) {
    callback(new Error(i18n.global.t('onlyLettersNumbersUnderScore')));
  } else {
    callback();
  }
};

const mustBeAlphanumericUnderscoreSquareBrackets = (
  rule,
  value: string,
  callback
) => {
  if (!value.match(/^[A-Za-z0-9_[\]]+$/)) {
    callback(
      new Error(i18n.global.t('onlyLettersNumbersUnderScoreSquareBrackets'))
    );
  } else {
    callback();
  }
};

const mustBeAlphanumericDashUnderscore = (rule, value: string, callback) => {
  if (!value.match(/^[A-Za-z0-9-_]+$/)) {
    callback(new Error(i18n.global.t('onlyLettersNumbersDashUnderScore')));
  } else {
    callback();
  }
};

const mustHaveMinCharacters = (minCharacters: number) => {
  return (rule: any, value: string, callback: any) => {
    if (value.length < minCharacters) {
      callback(
        new Error(
          i18n.global
            .t('pleaseEnterNumCharacters')
            .replace('[num]', String(minCharacters))
        )
      );
    } else {
      callback();
    }
  };
};

const checkJson = (value: string): boolean => {
  if (!value) return true;
  try {
    JSON.parse(value);
    return true;
  } catch (e) {
    return false;
  }
};

const mustBeValidJson = (rule: any, value: any, callback: any) => {
  if (checkJson(value)) callback();
  else callback(new Error(i18n.global.t('notValidJson')));
};

const mustNotBeEmpty = (rule, value: string, callback) => {
  if (!value) {
    callback(new Error(i18n.global.t('noEmptyFieldAllowed')));
  } else {
    callback();
  }
};

const mustContainAtMostOneAsterisk = (
  rule: any,
  value: string,
  callback: any
) => {
  if (value.split('*').length <= 2) callback();
  else callback(new Error(i18n.global.t('mustContainAtMostOneAsterisk')));
};

const mustHaveValue = (allowedValues: string[]) => {
  return (rule: any, value: string, callback: any) => {
    if (
      !allowedValues
        .map(av => {
          return av.toUpperCase();
        })
        .includes(value.toUpperCase())
    ) {
      callback(new Error(i18n.global.t('invalidValue')));
    } else {
      callback();
    }
  };
};

const fieldValidator = (allowedDataTypes: string[], minFieldLength = 4) => {
  return (rule: any, value: FieldModel, callback: any) => {
    if (!value?.name?.match(/^[A-Za-z0-9_[\]]+$/)) {
      callback(
        new Error(i18n.global.t('onlyLettersNumbersUnderScoreSquareBrackets'))
      );
    } else if (value.name.length < minFieldLength) {
      callback(
        new Error(
          i18n.global
            .t('nameMustMinNumCharacters')
            .replace('[num]', `${minFieldLength}`)
        )
      );
    } else if (!allowedDataTypes.includes(value?.dataType ?? '')) {
      callback(new Error(i18n.global.t('invalidDataType')));
    } else {
      callback();
    }
  };
};

const mustNotBeInArray = (existingValues: string[], msg: string) => {
  return (rule: any, value: string, callback: any) => {
    if (existingValues.some(el => el.toLowerCase() == value.toLowerCase())) {
      callback(new Error(msg));
    } else callback();
  };
};

export {
  mustBeAlphaNumericAndLowerCase,
  mustBeAlphaNumericDashAndLowerCase,
  mustBeAlphanumericDot,
  mustBeAlphanumericUnderscore,
  mustBeAlphanumericUnderscoreSquareBrackets,
  mustBeAlphanumericDashUnderscore,
  mustBeValidEmail,
  mustContainAtMostOneAsterisk,
  mustStartWith,
  mustBeGuid,
  mustNotContainSpecialChars,
  mustHaveValue,
  mustBeValidJson,
  checkJson,
  mustNotBeEmpty,
  fieldValidator,
  mustHaveMinCharacters,
  mustNotBeInArray
};
