<template>
  <page-header :main-title="$t('dashboards')">
    <template #subheader>
      <h4 class="subtitle-2 mt-3">{{ $t('dashboardsDesc') }}</h4>
    </template>
  </page-header>
  <el-row :gutter="24" class="mt-6">
    <el-col :span="17">
      <el-row>
        <div class="d-flex mt-auto">
          <base-toggle-button
            v-model="powerBiIsActive"
            :inactive-text="$t('powerBi')"
            :loading="powerBiIsPausingResuming"
            :indicator-text="$t('togglingPowerBI')"
          />
          <base-toggle-button
            v-if="showAnalysisServicesPages"
            v-model="analysisServicesIsActive"
            class="ml-3"
            :inactive-text="$t('analysisServices')"
            :loading="analysisServicesIsPausingResuming"
            :indicator-text="$t('togglingAnalysisServices')"
          />
        </div>
        <base-upload-button
          v-if="userCanWritePowerBiDashboards"
          class="ml-auto"
          accept=".pbix"
          @upload="uploadDashboard"
        />
      </el-row>
      <el-row class="mt-4 mb-1">
        <h4 v-if="powerBiIsActive && loadingDashboards" class="mt-auto">
          {{ $t('loading') }}
        </h4>
        <h4 v-else>{{ dashboards.length }} &#183; {{ $t('dashboards') }}</h4>
      </el-row>
      <el-row class="mt-1 d-block">
        <div v-if="!powerBiIsActive" class="empty-table">
          <h4 class="body-1">
            {{ dashboards.length + ' ' + $t('dashboardsToDisplay') }}
            {{ dashboards.length ? $t('viewDashboards') : $t('noDashboards') }}
          </h4>
        </div>
        <base-table
          v-else
          :show-loading-table="powerBiIsActive && loadingDashboards"
          :show-no-content-table="powerBiIsActive && !dashboards.length"
          :error-message="$t('noDashboards')"
          :columns="3"
        >
          <dashboard-table
            :table-data="dashboards"
            @open-dashboard="openDashboard"
            @open-dashboard-newtab="openDashboardNewTab"
            @confirm="deleteDashboard"
          />
        </base-table>
      </el-row>
    </el-col>
  </el-row>
</template>

<script lang="ts">
import { onBeforeUnmount, onMounted, computed, ComputedRef } from 'vue';
import { useStore } from 'vuex';
import router from '@/router';
import { ElRow, ElCol, ElMessage } from 'element-plus';
import PageHeader from '@/components/misc/PageHeader.vue';
import BaseToggleButton from '@/components/buttons/BaseToggleButton.vue';
import DashboardTable from './components/DashboardTable.vue';
import BaseUploadButton from '@/components/buttons/BaseUploadButton.vue';
import BaseTable from '@/components/tables/BaseTable.vue';
import { setEtpInterval, destroyEtpInterval } from '@/utils/interval';
import {
  DashboardInfo,
  ProvisioningState
} from '@etp/etp-dashboarding-client/axios';
import { AutopauseExclusionInput } from '@etp/etp-costsaving-client/axios';

export default {
  name: 'DashboardOverview',
  components: {
    PageHeader,
    BaseToggleButton,
    DashboardTable,
    ElRow,
    ElCol,
    BaseUploadButton,
    BaseTable
  },
  setup() {
    const store = useStore();

    const refreshFetchPowerBiStatusKey = 'Dashboard-PowerBiStatus';
    const refreshFetchAnalStatusKey = 'Dashboard-AnalServices';
    const refreshIntervalMilliseconds = 5 * 1000;

    let showAnalysisServicesPages: ComputedRef<boolean> = computed(
      () => store.getters['home/showAnalysisServicesPages']
    );
    let showDashboardPages: ComputedRef<boolean> = computed(
      () => store.getters['home/showDashboardPages']
    );
    const userCanWritePowerBiDashboards: ComputedRef<boolean> = computed(
      () => store.getters['userPermissions/canWriteEngagementPowerBiDashboards']
    );

    const userCanReadPowerBiDashboards = computed(
      () => store.getters['userPermissions/canReadEngagementPowerBiDashboards']
    );

    const uploadDashboard = async (file: File) => {
      await store.dispatch('dashboard/uploadDashboard', {
        file
      });
      setTimeout(async () => {
        await store.dispatch('dashboard/fetchDashboards');
      }, 1337);
    };

    let loadingDashboards: ComputedRef<boolean> = computed(
      () => store.getters['dashboard/loadingDashboards']
    );

    let dashboards: ComputedRef<Array<DashboardInfo>> = computed(
      () => store.getters['dashboard/getDashboards']
    );

    let deleteDashboard = async (id: string): Promise<void> => {
      await store.dispatch('dashboard/deleteDashboard', id);
      await store.dispatch('dashboard/fetchDashboards');
    };

    const openDashboard = (dashboard: DashboardInfo): void => {
      router.push({
        name: 'DashboardDetails',
        params: {
          reportId: dashboard.id
        }
      });
    };

    const openDashboardNewTab = (dashboard: DashboardInfo): void => {
      let routeData = router.resolve({
        name: 'DashboardDetails',
        params: {
          reportId: dashboard.id
        }
      });

      window.open(routeData.href + '?full-screen', '_blank');
    };

    let analysisServicesStatus: ComputedRef<ProvisioningState> = computed(
      () => store.getters['dashboard/getAnalysisServicesStatus']
    );

    let analysisServicesIsPausingResuming = computed(() => {
      return Boolean(
        analysisServicesStatus.value == ProvisioningState.Pausing ||
          analysisServicesStatus.value == ProvisioningState.Resuming ||
          analysisServicesStatus.value == ProvisioningState.Suspending
      );
    });

    let pollAnalysisServices = async (): Promise<void> => {
      setEtpInterval(
        refreshFetchAnalStatusKey,
        async () => {
          await store.dispatch('dashboard/fetchAnalysisServicesStatus');
          ElMessage({
            message:
              'Analysis Services status: ' +
              Object.keys(ProvisioningState)[
                Object.values(ProvisioningState).findIndex(
                  el => el == analysisServicesStatus.value
                )
              ]
          });
          if (!analysisServicesIsPausingResuming.value) {
            destroyEtpInterval(refreshFetchAnalStatusKey);
          }
        },
        refreshIntervalMilliseconds
      );
    };

    let analysisServicesIsActive = computed({
      get: () =>
        analysisServicesStatus.value == ProvisioningState.Succeeded ||
        analysisServicesStatus.value == ProvisioningState.Online,
      set: async () => {
        if (
          analysisServicesStatus.value == ProvisioningState.Succeeded ||
          analysisServicesStatus.value == ProvisioningState.Online
        ) {
          store.commit(
            'dashboard/SET_ANALYSISSERVICES_STATUS',
            ProvisioningState.Pausing
          );
          await store.dispatch('dashboard/suspendAnalysisServices');
        } else {
          store.commit(
            'dashboard/SET_ANALYSISSERVICES_STATUS',
            ProvisioningState.Resuming
          );
          await store.dispatch('costs/stopCostSavingForEngagement', {
            hours: 1
          } as AutopauseExclusionInput);
          await store.dispatch('dashboard/resumeAnalysisServices');
        }
        await pollAnalysisServices();
      }
    });

    let powerBiStatus: ComputedRef<ProvisioningState> = computed(
      () => store.getters['dashboard/getPowerBiStatus']
    );

    let powerBiIsPausingResuming = computed(() => {
      return Boolean(
        powerBiStatus.value == ProvisioningState.Pausing ||
          powerBiStatus.value == ProvisioningState.Resuming ||
          powerBiStatus.value == ProvisioningState.Suspending
      );
    });

    let pollPowerBiStatus = async (): Promise<void> => {
      setEtpInterval(
        refreshFetchPowerBiStatusKey,
        async () => {
          await store.dispatch('dashboard/fetchPowerBiStatus');
          ElMessage({
            message:
              'Power BI status: ' +
              Object.keys(ProvisioningState)[
                Object.values(ProvisioningState).findIndex(
                  el => el == powerBiStatus.value
                )
              ]
          });
          if (!powerBiIsPausingResuming.value) {
            destroyEtpInterval(refreshFetchPowerBiStatusKey);
          }
        },
        refreshIntervalMilliseconds
      );
    };

    let powerBiIsActive = computed({
      get: () =>
        powerBiStatus.value == ProvisioningState.Succeeded ||
        powerBiStatus.value == ProvisioningState.Online,
      set: async () => {
        if (
          powerBiStatus.value == ProvisioningState.Succeeded ||
          powerBiStatus.value == ProvisioningState.Online
        ) {
          store.commit(
            'dashboard/SET_POWERBI_STATUS',
            ProvisioningState.Pausing
          );
          await store.dispatch('dashboard/suspendPowerBi');
        } else {
          store.commit(
            'dashboard/SET_POWERBI_STATUS',
            ProvisioningState.Resuming
          );
          await store.dispatch('costs/stopCostSavingForEngagement', {
            hours: 1
          } as AutopauseExclusionInput);
          await store.dispatch('dashboard/resumePowerBi');
        }
        await pollPowerBiStatus();
      }
    });

    onMounted(async () => {
      if (!showDashboardPages.value) {
        return router.push({ name: 'Overview' });
      }
      await store.dispatch(
        'userPermissions/checkIfCanReadEngagementPowerBiDashboards'
      );
      if (!userCanReadPowerBiDashboards.value) {
        return router.push({ name: 'Overview' });
      }
      await store.dispatch('dashboard/fetchDashboards');
      await store.dispatch(
        'userPermissions/checkIfCanWriteEngagementPowerBiDashboards'
      );
      await store.dispatch('dashboard/fetchPowerBiStatus');
      if (powerBiIsPausingResuming.value) {
        await pollPowerBiStatus();
      }
      if (!showAnalysisServicesPages.value) return;
      await store.dispatch('dashboard/fetchAnalysisServicesStatus');
      if (analysisServicesIsPausingResuming.value) {
        await pollAnalysisServices();
      }
    });

    onBeforeUnmount(async () => {
      destroyEtpInterval(refreshFetchPowerBiStatusKey);
    });

    return {
      dashboards,
      deleteDashboard,
      openDashboard,
      openDashboardNewTab,
      analysisServicesIsActive,
      analysisServicesIsPausingResuming,
      powerBiIsActive,
      powerBiIsPausingResuming,
      uploadDashboard,
      showAnalysisServicesPages,
      userCanWritePowerBiDashboards,
      loadingDashboards
    };
  }
};
</script>

<style scoped lang="scss"></style>
