import ApplicationsOverview from '@/views/admin/applications/ApplicationsOverview.vue';
import ApplicationDetails from '@/views/admin/applications/ApplicationDetails.vue';
import ApplicationProfile from '@/views/admin/applications/ApplicationProfile.vue';
import ApplicationRoles from '@/views/admin/applications/ApplicationRoles.vue';
export default [
  {
    path: 'applications',
    name: 'ApplicationsOverview',
    component: ApplicationsOverview,
    meta: { menuItemName: 'ApplicationsOverview' }
  },
  {
    path: 'applications/:objectId',
    name: 'ApplicationDetails',
    redirect: { name: 'ApplicationProfile' },
    component: ApplicationDetails,
    meta: { menuItemName: 'ApplicationsOverview' },
    children: [
      {
        path: 'profile',
        name: 'ApplicationProfile',
        component: ApplicationProfile
      },
      {
        path: 'roles',
        name: 'ApplicationRoles',
        component: ApplicationRoles
      }
    ]
  }
];
