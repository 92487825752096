import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-auto" }
const _hoisted_2 = { class: "enagagements-table" }
const _hoisted_3 = { class: "body-1 mt-1" }
const _hoisted_4 = ["src", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, null, {
          default: _withCtx(() => [
            _createElementVNode("h4", _hoisted_1, _toDisplayString($setup.engagements.length) + " · " + _toDisplayString(_ctx.$t('engagements')), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, null, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_el_table, { data: $setup.engagements }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_table_column, {
                    prop: "name",
                    sortable: "custom",
                    label: _ctx.$t('name')
                  }, {
                    default: _withCtx((scope) => [
                      _createElementVNode("div", null, [
                        _createElementVNode("h4", null, _toDisplayString(scope.row.name), 1),
                        _createElementVNode("span", _hoisted_3, _toDisplayString(scope.row.id), 1)
                      ])
                    ]),
                    _: 1
                  }, 8, ["label"]),
                  _createVNode(_component_el_table_column, { width: "100" }, {
                    default: _withCtx((scope) => [
                      _createElementVNode("div", null, [
                        _createElementVNode("img", {
                          src: require(`@/assets/images/icons/new/settings.svg`),
                          class: "action-icon action-spin",
                          height: "14",
                          width: "14",
                          onClick: ($event: any) => ($setup.openEngagementsDetails(scope.row.id))
                        }, null, 8, _hoisted_4)
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["data"])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}