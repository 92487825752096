import TreeNode from '@/components/types/treeNode';

// convert from Array<string> to TreeNode[] using delimeter
export const populateTree = (
  allValues: Array<string>,
  delimiter: string
): Array<TreeNode> => {
  const tree: Array<TreeNode> = [];
  let id = 0;
  allValues.forEach(value => {
    let currentNode;
    const levels = value.split(delimiter);
    levels.forEach((levelName, levelIndex) => {
      if (levelIndex == 0) {
        if (!tree.some(x => x.label == levelName)) {
          tree.push({
            id: ++id,
            label: levelName,
            children: [],
            values: []
          });
        }
        currentNode = tree.find(x => x.label == levelName);
      } else {
        if (!currentNode.children.some(x => x.label == levelName)) {
          currentNode.children.push({
            id: ++id,
            label: levelName,
            children: [],
            values: []
          });
        }
        currentNode = currentNode.children.find(x => x.label == levelName);
      }
      currentNode.values.push(value);
    });
  });
  return tree;
};
