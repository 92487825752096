import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-db8c4da4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "eula-body" }
const _hoisted_2 = { class: "eula-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_eula_text = _resolveComponent("eula-text")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (!$setup.eulaAccepted)
    ? (_openBlock(), _createBlock(_component_el_dialog, {
        key: 0,
        "model-value": $setup.showEulaModal,
        "show-close": false,
        title: _ctx.$t('EULA'),
        "close-on-click-modal": false
      }, {
        footer: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_el_checkbox, {
              modelValue: $setup.eulaTermsAccepted,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.eulaTermsAccepted) = $event)),
              label: _ctx.$t('acceptTerms')
            }, null, 8, ["modelValue", "label"]),
            _createVNode(_component_el_button, {
              type: "primary",
              disabled: !$setup.eulaTermsAccepted,
              onClick: $setup.acceptEula
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('accept')), 1)
              ]),
              _: 1
            }, 8, ["disabled", "onClick"])
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_eula_text)
          ])
        ]),
        _: 1
      }, 8, ["model-value", "title"]))
    : _createCommentVNode("", true)
}