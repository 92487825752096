<template>
  <base-card no-header class="width-100">
    <template #body>
      <el-row>
        <el-col :span="7">
          <h3>{{ connectorConfiguration.connector }}</h3>
          <span class="body-1">
            {{ connectorConfiguration.environment }}
          </span>
        </el-col>
        <el-col :span="14">
          <el-form ref="formRef" :model="form" :rules="formRules">
            <el-form-item :label="$t('configuration')" prop="configuration">
              <el-input
                v-model="form.configuration"
                :rows="6"
                type="textarea"
                @change="handleConfigurationChange"
              />
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="3" class="d-flex">
          <img
            :src="require(`@/assets/images/icons/new/delete.svg`)"
            height="12"
            width="12"
            class="action-icon ml-auto mt-1 mr-2"
            @click="deleteConfiguration"
          />
        </el-col>
      </el-row>
    </template>
  </base-card>
</template>
<script lang="ts">
import BaseCard from '@/components/panels/BaseCard.vue';
import { mustBeValidJson } from '@/utils/formValidators';
import { onMounted, onUpdated, reactive, ref } from 'vue';
import { ElCol, ElForm, ElFormItem, ElInput, ElRow } from 'element-plus';
import { ConnectorConfigurationDto } from '@etp/etp-connectors-client';

export default {
  name: 'ConnectorConfigurationCard',
  components: {
    BaseCard,
    ElCol,
    ElForm,
    ElFormItem,
    ElInput,
    ElRow
  },
  props: {
    connectorConfiguration: {
      type: Object as () => ConnectorConfigurationDto,
      default: {} as ConnectorConfigurationDto,
      required: true
    }
  },
  emits: ['delete', 'update'],
  setup(props, { emit }) {
    const form = reactive({
      configuration: ''
    });
    const formRef = ref<HTMLFormElement>();
    const formRules = reactive({
      configuration: [{ validator: mustBeValidJson, trigger: 'blur' }]
    });

    const deleteConfiguration = () => {
      emit('delete');
    };
    const handleConfigurationChange = (newValue: string) => {
      form.configuration = newValue;
      emit('update', form.configuration);
    };

    onMounted(() => {
      form.configuration = props.connectorConfiguration.configuration ?? '';
    });

    onUpdated(() => {
      form.configuration = props.connectorConfiguration.configuration ?? '';
    });

    return {
      deleteConfiguration,
      form,
      formRef,
      formRules,
      handleConfigurationChange
    };
  }
};
</script>
<style scoped lang="scss"></style>
