<template>
  <el-timeline>
    <el-timeline-item
      v-for="(item, index) in pipelineRuns"
      :key="index"
      class="timeline-item"
      :type="
        item.status === 'Succeeded'
          ? 'primary'
          : item.status === 'Failed'
          ? 'danger'
          : 'warning'
      "
    >
      <span class="pipeline-name">
        {{ item.pipelineName }}
      </span>
      <div class="content-meta d-flex">
        <div class="d-flex body-2">
          <span class="content-meta-item mr-1">
            {{ getLayerName(item.layerName) }}
          </span>
          <span>|</span>
          <span class="content-meta-item d-flex body-2 ml-1">
            {{ localDateTime(item.runStart) }}
          </span>
        </div>
      </div>
    </el-timeline-item>
  </el-timeline>
</template>

<script lang="ts">
import { ElTimeline, ElTimelineItem } from 'element-plus';

import moment from 'moment';
import i18n from '@/i18n';
import { LayerName, PipelineRunDto } from '@etp/etp-pipelines-client/axios';

export default {
  name: 'BaseTimeline',
  components: { ElTimeline, ElTimelineItem },
  props: {
    pipelineRuns: {
      type: Array as () => Array<PipelineRunDto>,
      default: () => {
        return [];
      }
    },
    showLayerName: {
      type: Boolean,
      default: () => {
        return false;
      }
    }
  },
  setup(props) {
    let getLayerName = (value: LayerName | undefined): string => {
      if (!props.showLayerName || value === undefined) {
        return i18n.global.t('na');
      }
      switch (value) {
        case LayerName.ModelLayer:
          return i18n.global.t('modeling');
        case LayerName.AnalyticalLayer:
          return i18n.global.t('analytical');
        case LayerName.TestLayer:
          return i18n.global.t('testing');
        case LayerName.ImportLayer:
          return i18n.global.t('import');
        case LayerName.FilteringLayer:
          return i18n.global.t('filtering');
        case LayerName.LabelingLayer:
          return i18n.global.t('labeling');
        default:
          return i18n.global.t('na');
      }
    };

    let localDateTime = date => {
      let dateTime = moment(date).format('DD.MM.YYYY, HH:mm');
      return dateTime;
    };

    return { localDateTime, getLayerName };
  }
};
</script>

<style scoped lang="scss">
.content-meta {
  flex-direction: column;
}

.meta-divider {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  font-size: 1.8rem;
  line-height: 0.5;
  color: $etp-dark-grey;
}

.body-2 {
  color: $etp-shade-2;
}

.content-meta-item {
  margin-right: 0.8em;
}
</style>
