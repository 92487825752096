import config from '@/config/config';

function getResourceGroupPrefix(): string {
  return 'NL-ETP-' + config.environment[0] + '-';
}

function removeResourceGroupPrefix(resourceGroupName: string): string {
  let prefix = getResourceGroupPrefix();
  return resourceGroupName && resourceGroupName.startsWith(prefix)
    ? resourceGroupName.substring(prefix.length)
    : resourceGroupName;
}
export default { getResourceGroupPrefix, removeResourceGroupPrefix };
