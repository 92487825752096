import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "body-1" }
const _hoisted_2 = ["src", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_base_modal = _resolveComponent("base-modal")!

  return (_openBlock(), _createBlock(_component_base_modal, {
    "modal-title": _ctx.$t('tenant'),
    "dialog-visible": $props.modelValue,
    "confirm-text": _ctx.$t('save'),
    width: "400px",
    onClose: _cache[2] || (_cache[2] = ($event: any) => ($setup.close())),
    onConfirm: _cache[3] || (_cache[3] = ($event: any) => ($setup.confirm()))
  }, {
    content: _withCtx(() => [
      _createVNode(_component_el_form, {
        ref: "formRef",
        model: $setup.form,
        rules: $setup.formRules,
        "label-position": "top"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, {
            label: _ctx.$t('etpTemplateType'),
            prop: "etpTemplateType"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_select, {
                modelValue: $setup.form.templateType,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.form.templateType) = $event)),
                size: "small",
                placeholder: _ctx.$t('empty'),
                onChange: $setup.handleTemplateTypeChange
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.templateTypes, (template) => {
                    return (_openBlock(), _createBlock(_component_el_option, {
                      key: template,
                      label: template,
                      value: template
                    }, null, 8, ["label", "value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modelValue", "placeholder", "onChange"])
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_form_item, {
            label: _ctx.$t('prefix'),
            prop: "resourceGroupName"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: $setup.form.resourceGroupName,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.form.resourceGroupName) = $event)),
                size: "small",
                onInput: $setup.handleResourceGroupNameChange
              }, {
                prepend: _withCtx(() => [
                  _createTextVNode(_toDisplayString($setup.resourceGroupPrefix), 1)
                ]),
                _: 1
              }, 8, ["modelValue", "onInput"])
            ]),
            _: 1
          }, 8, ["label"]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.proposedResources, (resource, index) => {
            return (_openBlock(), _createBlock(_component_el_row, { key: index }, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, { span: 23 }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_1, _toDisplayString($setup.formatResourceType(resource.resourceType)) + " - " + _toDisplayString(resource.name), 1)
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_el_col, { span: 1 }, {
                  default: _withCtx(() => [
                    _createElementVNode("img", {
                      src: require(`@/assets/images/icons/new/delete.svg`),
                      height: "12",
                      width: "12",
                      class: "action-icon",
                      onClick: ($event: any) => ($setup.removeListElement(index))
                    }, null, 8, _hoisted_2)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }, 8, ["model", "rules"])
    ]),
    _: 1
  }, 8, ["modal-title", "dialog-visible", "confirm-text"]))
}