<template>
  <div class="menu-layout bs-1 glass-card">
    <the-menu>
      <template #menu-contents>
        <el-menu-item
          v-if="userCanReadGlobalUsers"
          index="UsersOverview"
          :route="{ name: 'UsersOverview' }"
          class="main-menu-item"
        >
          <img
            class="menu-icon"
            src="@/assets/images/icons/new/users.svg"
            height="15"
            width="15"
          />
          {{ $t('users') }}
        </el-menu-item>
        <el-menu-item
          v-if="userCanReadGlobalUsers"
          index="ApplicationsOverview"
          :route="{ name: 'ApplicationsOverview' }"
          class="main-menu-item"
        >
          <img
            class="menu-icon"
            src="@/assets/images/icons/new/healthcheck.svg"
            height="15"
            width="15"
          />
          {{ $t('applications') }}
        </el-menu-item>
        <el-menu-item
          v-if="userCanReadGlobalTenants"
          index="TenantsOverview"
          :route="{ name: 'TenantsOverview' }"
          class="main-menu-item"
        >
          <img
            class="menu-icon"
            src="@/assets/images/icons/new/tenants.svg"
            height="15"
            width="15"
          />
          {{ $t('tenants') }}
        </el-menu-item>
        <el-menu-item
          v-if="userCanReadGlobalEngagements"
          index="EngagementsOverview"
          :route="{ name: 'EngagementsOverview' }"
          class="main-menu-item"
        >
          <img
            class="menu-icon"
            src="@/assets/images/icons/office.svg"
            height="15"
            width="15"
          />
          {{ $t('engagements') }}
        </el-menu-item>
        <el-menu-item
          v-if="userCanReadGlobalAuthorization"
          index="RolesOverview"
          :route="{ name: 'RolesOverview' }"
          class="main-menu-item"
        >
          <img
            class="menu-icon"
            src="@/assets/images/icons/userroles.svg"
            height="15"
            width="15"
          />
          {{ $t('roles') }}
        </el-menu-item>
        <el-menu-item
          v-if="userCanReadGlobalAuthorization"
          index="PermissionsOverview"
          :route="{ name: 'PermissionsOverview' }"
          class="main-menu-item"
        >
          <img
            class="menu-icon"
            src="@/assets/images/icons/check-list.svg"
            height="15"
            width="15"
          />
          {{ $t('permissions') }}
        </el-menu-item>
      </template>
    </the-menu>
  </div>
  <div class="content-wrapper">
    <router-view :key="$route.fullPath" />
  </div>
</template>
<script lang="ts">
import { ElMenuItem } from 'element-plus';
import TheMenu from '@/components/layout/TheMenu.vue';
import { useStore } from 'vuex';
import { computed, ComputedRef, onMounted } from 'vue';
export default {
  name: 'AdminOverview',
  components: { TheMenu, ElMenuItem },
  setup() {
    const store = useStore();

    const userCanReadGlobalAuthorization: ComputedRef<boolean> = computed(
      () => store.getters['userPermissions/canReadGlobalAuthorization']
    );
    const userCanReadGlobalEngagements: ComputedRef<boolean> = computed(
      () => store.getters['userPermissions/canReadGlobalEngagements']
    );
    const userCanReadGlobalTenants: ComputedRef<boolean> = computed(
      () => store.getters['userPermissions/canReadGlobalTenants']
    );
    const userCanReadGlobalUsers: ComputedRef<boolean> = computed(
      () => store.getters['userPermissions/canReadGlobalUsers']
    );

    onMounted(async () => {
      await store.dispatch('userPermissions/checkIfCanReadGlobalAuthorization');
      await store.dispatch('userPermissions/checkIfCanReadGlobalEngagements');
      await store.dispatch('userPermissions/checkIfCanReadGlobalTenants');
      await store.dispatch('userPermissions/checkIfCanReadGlobalUsers');
    });

    return {
      userCanReadGlobalAuthorization,
      userCanReadGlobalEngagements,
      userCanReadGlobalTenants,
      userCanReadGlobalUsers
    };
  }
};
</script>
<style scoped lang="scss">
.menu-layout {
  padding-top: 7.5em;
  border: 1px $etp-plain-white;
  border-radius: 0px 25px 25px 0px;
}
.content-wrapper {
  width: 100%;
  padding: 7.5em 6em 3em 5em;
  min-height: 100vh;
  box-sizing: border-box;
}

.menu-icon {
  filter: invert(62%) sepia(4%) saturate(1076%) hue-rotate(179deg)
    brightness(90%) contrast(96%);
}
</style>
