<template>
  <base-modal
    :modal-title="$t('engagement')"
    :dialog-visible="modelValue"
    :confirm-text="$t('save')"
    width="400px"
    @close="close()"
    @confirm="confirm()"
  >
    <template #content>
      <el-form
        ref="formRef"
        :model="form"
        :rules="formRules"
        label-position="top"
      >
        <el-form-item :label="$t('name')" prop="name">
          <el-input v-model="form.name" size="small" />
        </el-form-item>
        <el-form-item :label="$t('tenant')" prop="tenantId">
          <el-select v-model="form.tenantId" size="small" value-key="id">
            <el-option
              v-for="tenant in tenants"
              :key="tenant.id ?? ''"
              :label="tenant.name ?? ''"
              :value="tenant.id ?? ''"
            />
          </el-select>
        </el-form-item>
      </el-form>
    </template>
  </base-modal>
</template>
<script lang="ts">
import BaseModal from '@/components/modals/BaseModal.vue';
import {
  ElForm,
  ElFormItem,
  ElInput,
  ElMessage,
  ElSelect,
  ElOption
} from 'element-plus';
import { reactive, ref } from 'vue';
import { mustNotContainSpecialChars } from '@/utils/formValidators';
import i18n from '@/i18n';
import { BaseTenantModel } from '@etp/etp-infra-client';
import { CreateEngagementDto } from '@etp/etp-engagements-client/axios';

export default {
  name: 'NewEngagementModal',
  components: {
    BaseModal,
    ElInput,
    ElForm,
    ElFormItem,
    ElSelect,
    ElOption
  },
  props: {
    modelValue: { type: Boolean, default: false },
    feedbackText: { type: String, default: i18n.global.t('saved') },
    tenants: {
      type: Array as () => Array<BaseTenantModel>,
      default: new Array<BaseTenantModel>()
    }
  },
  emits: ['close', 'confirm', 'update:model-value'],
  setup(props, { emit }) {
    const form = reactive({
      name: '',
      tenantId: ''
    });
    const formRules = reactive({
      name: [
        {
          required: true,
          message: i18n.global.t('thisFieldIsRequired'),
          trigger: 'blur'
        },
        {
          min: 3,
          message: i18n.global
            .t('pleaseEnterNumCharacters')
            .replace('[num]', '3'),
          trigger: 'blur'
        },
        {
          validator: mustNotContainSpecialChars,
          trigger: 'blur'
        }
      ],
      tenantId: {
        required: true,
        message: i18n.global.t('thisFieldIsRequired'),
        trigger: 'blur'
      }
    });
    const formRef = ref<HTMLFormElement>();
    const confirm = () => {
      formRef.value?.validate((valid: boolean) => {
        if (valid) {
          emit('confirm', {
            name: form.name,
            tenantId: form.tenantId
          } as CreateEngagementDto);
          ElMessage({
            showClose: true,
            message: props.feedbackText
          });
        }
      });
    };
    const close = () => {
      form.name = '';
      form.tenantId = '';
      emit('update:model-value', false);
    };

    return {
      form,
      formRules,
      formRef,
      confirm,
      close
    };
  }
};
</script>
<style scoped lang="scss"></style>
