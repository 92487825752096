import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input_number = _resolveComponent("el-input-number")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_base_modal = _resolveComponent("base-modal")!

  return (_openBlock(), _createBlock(_component_base_modal, {
    "modal-title": _ctx.$t('stopCostSavingDesc'),
    "dialog-visible": $props.modelValue,
    "confirm-text": _ctx.$t('save'),
    width: "400px",
    onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update:model-value', false))),
    onConfirm: _cache[3] || (_cache[3] = ($event: any) => ($setup.confirm()))
  }, {
    content: _withCtx(() => [
      _createVNode(_component_el_form, { "label-position": "top" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, { span: 12 }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_form_item, {
                    label: _ctx.$t('hours')
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input_number, {
                        modelValue: $setup.localHours,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.localHours) = $event)),
                        min: 0,
                        max: 10,
                        size: "small"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }, 8, ["label"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_col, { span: 12 }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_form_item, {
                    label: _ctx.$t('minutes')
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input_number, {
                        modelValue: $setup.localMinutes,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.localMinutes) = $event)),
                        min: 0,
                        max: 60,
                        size: "small"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }, 8, ["label"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_el_row, null, {
        default: _withCtx(() => [
          _createElementVNode("h4", _hoisted_1, _toDisplayString(_ctx.$t('estimatedPeriodEnd') +
            ': ' +
            $setup.calculatePeriodEnd($setup.localHours, $setup.localMinutes)), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modal-title", "dialog-visible", "confirm-text"]))
}