<template>
  <el-row :gutter="spacingWidthHeaderComponents">
    <el-col :span="5">
      <base-card no-header>
        <template #body>
          <el-form>
            <el-form-item :label="$t('branch')" class="mb-0 form-item">
              <div class="d-flex align-items-center">
                <el-select
                  v-model="selectedBranch"
                  :disabled="loadingBranches"
                  value-key="name"
                  size="small"
                  :placeholder="loadingBranches ? $t('loading') : $t('empty')"
                >
                  <el-option
                    v-for="item in branches"
                    :key="item.name ?? ''"
                    :label="item.name ?? ''"
                    :value="item"
                  ></el-option>
                </el-select>
                <el-button
                  class="icon-button p-1 cursor-pointer"
                  @click="showBranchModal = true"
                >
                  <plus class="icon-size-8" />
                </el-button>
              </div>
            </el-form-item>
            <el-form-item :label="$t('node')" class="mb-0 form-item">
              <div class="d-flex align-items-center">
                <el-select
                  v-model="selectedNode"
                  :disabled="!selectedBranch.name || loadingNodes"
                  value-key="name"
                  size="small"
                  :placeholder="loadingNodes ? $t('loading') : $t('empty')"
                >
                  <el-option
                    v-for="item in nodes"
                    :key="item.name ?? ''"
                    :label="item.name ?? ''"
                    :value="item"
                  ></el-option>
                </el-select>
                <el-button
                  :disabled="!selectedBranch.name || selectedBranch.readOnly"
                  class="icon-button p-1 cursor-pointer"
                  @click="showNodeModal = true"
                >
                  <plus class="icon-size-8" />
                </el-button>
              </div>
            </el-form-item>
          </el-form>
        </template>
      </base-card>
      <base-card v-if="selectedNode.name" no-header class="mt-1">
        <template #body>
          <el-form class="model-form" label-position="top">
            <el-form-item :label="$t('fields')">
              <el-scrollbar max-height="200px">
                <model-list-item
                  v-for="field in fieldsObjects"
                  :key="field.name ?? ''"
                  :item="field"
                />
              </el-scrollbar>
              <div
                v-if="!selectedBranch.readOnly"
                class="edit-fields-container"
                @click="showFieldsModal = true"
              >
                <div class="body-1 edit-fields-icon">
                  <img
                    src="@/assets/images/icons/new/edit.svg"
                    height="16"
                    width="16"
                    class="edit-field action-icon"
                  />
                  <h4>{{ $t('editFields') }}</h4>
                </div>
              </div>
            </el-form-item>
          </el-form>
        </template>
      </base-card>
      <base-card v-if="selectedNode.name" no-header class="mt-1">
        <template #body>
          <el-form class="model-form" label-position="top">
            <el-form-item :label="$t('properties')">
              <el-scrollbar max-height="200px">
                <div
                  v-for="property in properties"
                  :key="property.name ?? ''"
                  class="model-list-item mb-1"
                >
                  <span class="d-flex body-1 align-items-center ml-2">
                    {{ property.name }}
                  </span>
                </div>
              </el-scrollbar>
              <div
                v-if="!selectedBranch.readOnly"
                class="edit-properties-container"
                @click="showPropertiesModal = true"
              >
                <div class="body-1 edit-properties-icon">
                  <img
                    src="@/assets/images/icons/new/edit.svg"
                    height="16"
                    width="16"
                    class="edit-property action-icon"
                  />
                  <h4>{{ $t('editProperties') }}</h4>
                </div>
              </div>
            </el-form-item>
          </el-form>
        </template>
      </base-card>
    </el-col>
    <el-col :span="19">
      <base-card no-header>
        <template #body>
          <div class="d-flex pr-2">
            <div
              v-if="!selectedBranch.readOnly && selectedNode.name"
              class="ml-auto"
            >
              <el-button type="primary" @click.stop="updateNodeDetail">
                <h4>{{ $t('save') }}</h4>
              </el-button>
            </div>
          </div>
          <el-form>
            <el-form-item :label="$t('queryEditor')">
              <code-editor
                v-model="nodeDetail.defaultQuery"
                :read_only="!selectedNode.name"
                font_size="13px"
                :languages="[['SQL', 'SQL']]"
                theme="light"
                min_height="300px"
                min_width="100%"
              ></code-editor>
            </el-form-item>
          </el-form>
        </template>
      </base-card>
    </el-col>
  </el-row>
  <fields-modal
    :dialog-visible="showFieldsModal"
    :fields="fields"
    :all-fields-in-query="allFieldsInQuery"
    @confirm="updateFields"
    @close="showFieldsModal = false"
  />
  <properties-modal
    :dialog-visible="showPropertiesModal"
    :properties="properties"
    @confirm="updateProperties"
    @close="showPropertiesModal = false"
  />
  <new-branch-modal
    :show-modal="showBranchModal"
    :branches="branches"
    @confirm="addNewBranch"
    @close="showBranchModal = false"
  />
  <new-node-modal
    :show-modal="showNodeModal"
    :nodes="nodes"
    hide-schema-field
    @confirm="addNewNode"
    @close="showNodeModal = false"
  />
</template>

<script lang="ts">
import {
  ElButton,
  ElCol,
  ElForm,
  ElFormItem,
  ElOption,
  ElRow,
  ElSelect,
  ElMessage,
  ElScrollbar
} from 'element-plus';

import BaseCard from '@/components/panels/BaseCard.vue';
import FieldsModal from '@/components/modals/FieldsModal.vue';
import PropertiesModal from '@/components/modals/PropertiesModal.vue';
import ModelListItem from '@/components/list/BaseFieldslListItem.vue';
import NewBranchModal from '@/components/modals/NewBranchModal.vue';
import NewNodeModal from '@/components/modals/NewNodeModal.vue';
import CodeEditor from 'simple-code-editor/CodeEditor.vue';
import { computed, ComputedRef, onMounted, ref } from 'vue';
import { spacingWidthHeaderComponents } from '@/utils/variables';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { appendUrlParam, processUrlParams } from '@/utils/urlParameter';
import { computeGitFields, getFieldsFromQuery } from '@/utils/fieldsHelper';
import i18n from '@/i18n';
import { Plus } from '@element-plus/icons-vue';
import {
  BranchModel,
  CreateBranchModel,
  CreateFieldModel,
  CreateNodeModel,
  CreatePropertyModel,
  FieldModel,
  NodeDetailModel,
  NodeModel,
  PropertyModel
} from '@etp/etp-nodemodelgit-client/axios';
import { ConfigureField } from '@/services/web-api/dto/nodeModel';
import { GitBranch } from '@etp/etp-pipelines-client/axios';

export default {
  name: 'LabelingConfig',
  components: {
    BaseCard,
    ElButton,
    ElCol,
    ElForm,
    ElFormItem,
    ElOption,
    ElRow,
    ElScrollbar,
    ElSelect,
    FieldsModal,
    PropertiesModal,
    ModelListItem,
    NewBranchModal,
    NewNodeModal,
    CodeEditor,
    Plus
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    let showBranchModal = ref(false);
    let showFieldsModal = ref(false);
    let showPropertiesModal = ref(false);
    let showNodeModal = ref(false);

    let loadingBranches: ComputedRef<boolean> = computed(
      () => store.getters['labeling/loadingBranches']
    );

    let loadingNodes: ComputedRef<boolean> = computed(
      () => store.getters['labeling/loadingNodes']
    );

    const fetchBranches = async () => {
      await store.dispatch('labeling/fetchBranches');
    };
    const fetchNodes = async () => {
      await store.dispatch('labeling/fetchConfigNodes');
    };
    const fetchFields = async () => {
      await store.dispatch('labeling/fetchConfigFields');
    };
    const fetchNodeDetail = async () => {
      await store.dispatch('labeling/fetchConfigNodeDetail');
    };
    const fetchProperties = async () => {
      await store.dispatch('labeling/fetchConfigProperties');
    };

    const addNewBranch = async (model: CreateBranchModel) => {
      await store.dispatch('labeling/addNewBranch', model);
      await fetchBranches();
      showBranchModal.value = false;
    };
    const addNewNode = async (model: CreateNodeModel) => {
      await store.dispatch('labeling/addNewNode', model);
      await fetchNodes();
      showNodeModal.value = false;
    };
    const updateNodeDetail = async () => {
      ElMessage({
        showClose: true,
        message: i18n.global.t('savingChanges')
      });
      await store.dispatch('labeling/updateConfigNodeDetail');
      await fetchNodeDetail();
    };
    const updateFields = async (fields: Array<CreateFieldModel>) => {
      await store.dispatch('labeling/updateConfigFields', fields);
      await fetchFields();
      showFieldsModal.value = false;
    };
    const updateProperties = async (properties: Array<CreatePropertyModel>) => {
      await store.dispatch('labeling/updateConfigProperties', properties);
      await fetchProperties();
      showPropertiesModal.value = false;
    };

    const branches = computed<Array<GitBranch>>(
      () => store.getters['labeling/branches']
    );
    const nodes = computed<Array<NodeModel>>(
      () => store.getters['labeling/configNodes']
    );
    const fields = computed<Array<FieldModel>>(
      () => store.getters['labeling/configFields']
    );
    const nodeDetail = computed<NodeDetailModel>(
      () => store.getters['labeling/configNodeDetail']
    );
    const properties = computed<Array<PropertyModel>>(
      () => store.getters['labeling/configProperties']
    );

    const selectedBranch = computed({
      get: () => store.getters['labeling/selectedConfigBranch'],
      set: async (branch: BranchModel) => {
        appendUrlParam('branch', branch.name ?? '');
        store.commit('labeling/SET_SELECTED_CONFIG_BRANCH', branch);
        await fetchNodes();
      }
    });
    const selectedNode = computed({
      get: () => store.getters['labeling/selectedConfigNode'],
      set: async (node: NodeModel) => {
        appendUrlParam('branch', selectedBranch.value.name ?? '');
        appendUrlParam('node', node.name ?? '');
        store.commit('labeling/SET_SELECTED_CONFIG_NODE', node);
        await fetchFields();
        await fetchProperties();
        await fetchNodeDetail();
      }
    });
    const fieldsObjects = computed<ConfigureField[]>(() =>
      computeGitFields(fields.value ?? [], nodeDetail.value.defaultQuery ?? '')
    );
    const allFieldsInQuery = computed<string[]>(() =>
      getFieldsFromQuery(nodeDetail.value.defaultQuery || '')
    );

    const showNodeModelGitPages: ComputedRef<boolean> = computed(
      () => store.getters['home/showNodeModelGitPages']
    );
    onMounted(async () => {
      if (!showNodeModelGitPages.value) {
        return router.push({ name: 'LabelingOverview' });
      }
      fetchBranches().then(async () => {
        await processUrlParams([
          {
            name: 'branch',
            handler: async (branchName: string) => {
              let branch = branches.value.find(
                (el: BranchModel) => el.name == branchName
              );
              if (!branch) return;
              store.commit('labeling/SET_SELECTED_CONFIG_BRANCH', branch);
              await fetchNodes();
            }
          },
          {
            name: 'node',
            handler: async (nodeName: string) => {
              let node = nodes.value.find(
                (el: NodeModel) => el.name == nodeName
              );
              if (!node) return;
              store.commit('labeling/SET_SELECTED_CONFIG_NODE', node);
              await fetchFields();
              await fetchProperties();
              await fetchNodeDetail();
            }
          }
        ]);
      });
    });

    return {
      addNewBranch,
      addNewNode,
      allFieldsInQuery,
      branches,
      fields,
      properties,
      fieldsObjects,
      nodes,
      nodeDetail,
      selectedBranch,
      selectedNode,
      loadingBranches,
      loadingNodes,
      showBranchModal,
      showFieldsModal,
      showPropertiesModal,
      showNodeModal,
      spacingWidthHeaderComponents,
      updateFields,
      updateProperties,
      updateNodeDetail
    };
  }
};
</script>
<style lang="scss" scoped>
.form-item {
  flex-grow: 1;
}

.model-list-item {
  background: $etp-deloitte-white;
  height: 24px;
  border-radius: 8px;
  display: flex;
}

.model-list-item-content {
  display: inline-flex;
  align-items: center;
  .model-list-item-status {
    width: 8px;
    height: 8px;
    background: $etp-deloitte-green-1;
    border-radius: 100%;
    margin: 0px 16px 0px 12px;
  }
}
</style>
