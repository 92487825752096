import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createBlock as _createBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = ["src", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_base_modal = _resolveComponent("base-modal")!

  return (_openBlock(), _createBlock(_component_base_modal, {
    width: "500px",
    "modal-title": _ctx.$t('metadata'),
    "confirm-text": _ctx.$t('save'),
    "dialog-visible": $props.dialogVisible,
    onConfirm: $setup.confirm,
    onClose: $setup.handleClose
  }, {
    content: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.forms, (form, index) => {
        return (_openBlock(), _createBlock(_component_el_form, {
          key: index,
          ref_for: true,
          ref: "formRef",
          model: form.value,
          rules: $setup.formRules
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_row, { class: "mt-1 mb-2 is-justify-space-between" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, { span: 10 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, { prop: "key" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: form.value.key,
                          "onUpdate:modelValue": ($event: any) => ((form.value.key) = $event),
                          placeholder: "key",
                          size: "small"
                        }, null, 8, ["modelValue", "onUpdate:modelValue"])
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_el_col, { span: 10 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, { prop: "value" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: form.value.value,
                          "onUpdate:modelValue": ($event: any) => ((form.value.value) = $event),
                          placeholder: "value",
                          size: "small"
                        }, null, 8, ["modelValue", "onUpdate:modelValue"])
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_el_col, {
                  span: 1,
                  class: "d-flex justify-content-center align-items-center"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_tooltip, {
                      placement: "top",
                      content: _ctx.$t('deleteEntry')
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("img", {
                          src: require(`@/assets/images/icons/new/delete.svg`),
                          height: "14",
                          width: "14",
                          class: "action-icon ml-auto",
                          onClick: ($event: any) => ($setup.deleteMetadataEntry(index))
                        }, null, 8, _hoisted_1)
                      ]),
                      _: 2
                    }, 1032, ["content"])
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1032, ["model", "rules"]))
      }), 128)),
      _createVNode(_component_el_row, { class: "mt-3" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_col, { span: 12 }, {
            default: _withCtx(() => [
              _createVNode(_component_el_button, {
                type: "info",
                size: "small",
                onClick: $setup.addMetadataEntry
              }, {
                default: _withCtx(() => [
                  _createElementVNode("h4", null, _toDisplayString(_ctx.$t('addEntry')), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modal-title", "confirm-text", "dialog-visible", "onConfirm", "onClose"]))
}