<template>
  <div class="mt-2 empty-table">
    <h6 class="body-1">{{ errorMessage }}</h6>
  </div>
</template>

<script lang="ts">
import i18n from '@/i18n';
export default {
  name: 'TablePlaceholder',
  props: {
    errorMessage: {
      type: String,
      default: () => i18n.global.t('noData')
    }
  }
};
</script>
