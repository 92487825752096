<template>
  <base-card no-header>
    <template #body>
      <el-row>
        <el-col :span="6">
          <h3>{{ $t('pipelinePlatform') }}</h3>
        </el-col>
        <el-col :span="12">
          <el-form ref="formRef" :model="form" :rules="formRules">
            <el-form-item
              class="mb-0"
              :label="$t('implementation')"
              prop="implementation"
            >
              <el-select
                v-model="form.implementation"
                size="small"
                :placeholder="$t('empty')"
                class="mb-0"
                @change="handleImplementationUpdate"
              >
                <el-option
                  v-for="(index, item) in PipelinePlatformType"
                  :key="index"
                  :label="item"
                  :value="index"
                />
              </el-select>
            </el-form-item>
            <div
              v-if="
                form.implementation != PipelinePlatformType.Aws_StepFunctions
              "
            >
              <el-form-item
                :label="$t('subscriptionId')"
                prop="subscriptionId"
                class="mb-0"
              >
                <el-input v-model="form.subscriptionId" size="small" />
              </el-form-item>
              <el-form-item
                :label="$t('resourceGroupName')"
                prop="resourceGroupName"
                class="mb-0"
              >
                <el-input v-model="form.resourceGroupName" size="small" />
              </el-form-item>
              <el-form-item
                class="mb-0"
                :label="$t('resourceName')"
                prop="resourceName"
              >
                <el-select
                  v-model="form.resourceName"
                  size="small"
                  :placeholder="$t('empty')"
                >
                  <el-option
                    v-for="item in localResources"
                    :key="item.name ?? ''"
                    :label="item.name ?? ''"
                    :value="item.name ?? ''"
                  />
                </el-select>
              </el-form-item>
              <el-form-item
                class="mb-0"
                :label="$t('gitImplementation')"
                prop="gitImplementation"
              >
                <el-select
                  v-model="form.gitImplementation"
                  size="small"
                  :placeholder="$t('empty')"
                  class="mb-0"
                >
                  <el-option
                    v-for="(index, item) in NodeModelPlatformType"
                    :key="index"
                    :label="item"
                    :value="index"
                  />
                </el-select>
              </el-form-item>
              <el-form-item
                class="mb-0"
                :label="$t('GitRepository')"
                prop="gitRepositoryName"
              >
                <el-select
                  v-model="form.gitRepositoryName"
                  size="small"
                  :placeholder="$t('empty')"
                >
                  <el-option
                    v-for="item in localGitRepositories"
                    :key="item.name ?? ''"
                    :label="item.name ?? ''"
                    :value="item.name"
                  />
                </el-select>
              </el-form-item>
            </div>
            <div v-else>
              <el-form-item
                :label="$t('awsRegion')"
                prop="awsRegion"
                class="mb-0"
              >
                <el-input v-model="form.awsRegion" size="small" />
              </el-form-item>
              <el-form-item
                :label="$t('awsAccountId')"
                prop="awsAccountId"
                class="mb-0"
              >
                <el-input v-model="form.awsAccountId" size="small" />
              </el-form-item>
            </div>
          </el-form>
        </el-col>
        <el-col :span="6" class="d-flex">
          <el-button
            class="ml-auto"
            type="primary"
            :disabled="disabled"
            @click="save"
          >
            <h4>{{ $t('save') }}</h4>
          </el-button>
        </el-col>
      </el-row>
    </template>
  </base-card>
</template>
<script lang="ts">
import BaseCard from '@/components/panels/BaseCard.vue';
import {
  mustBeAlphaNumericAndLowerCase,
  mustBeAlphanumericDashUnderscore,
  mustBeGuid,
  mustNotBeEmpty
} from '@/utils/formValidators';
import {
  CreateOrUpdateEngagementPipelineResourceDto,
  PipelinePlatformType,
  EngagementConfiguration
} from '@etp/etp-pipelines-client/axios';
import { NodeModelPlatformType } from '@etp/etp-nodemodelgit-client/axios';
import { onUpdated, reactive, ref } from 'vue';
import {
  ElButton,
  ElCol,
  ElForm,
  ElFormItem,
  ElInput,
  ElOption,
  ElRow,
  ElSelect
} from 'element-plus';
import { ResourceType, ResourceModel } from '@etp/etp-infra-client';

export default {
  name: 'PipelineConfigurationCard',
  components: {
    BaseCard,
    ElButton,
    ElCol,
    ElForm,
    ElFormItem,
    ElInput,
    ElOption,
    ElRow,
    ElSelect
  },
  props: {
    configuration: {
      type: Object as () => EngagementConfiguration,
      required: true
    },
    disabled: { type: Boolean, default: false },
    resources: {
      type: Array as () => Array<ResourceModel>,
      default: () => new Array<ResourceModel>()
    }
  },
  emits: ['update'],
  setup(props, { emit }) {
    const form = reactive({
      implementation: -1,
      subscriptionId: '',
      resourceGroupName: '',
      resourceName: '',
      gitImplementation: 0,
      gitRepositoryName: '',
      awsRegion: '',
      awsAccountId: ''
    });
    const formRef = ref<HTMLFormElement>();
    const formRules = ref({});

    const save = async () => {
      formRef.value?.validate((valid: boolean) => {
        if (valid) {
          emit('update', {
            pipelinePlatformType: form.implementation,
            pipelinePlatformConfiguration: createPlatformConfiguration()
          } as CreateOrUpdateEngagementPipelineResourceDto);
        }
      });
    };

    const createPlatformConfiguration = () => {
      switch (form.implementation) {
        case PipelinePlatformType.Azure_DataFactory:
          return JSON.stringify({
            SubscriptionId: form.subscriptionId,
            ResourceGroupName: form.resourceGroupName,
            DataFactoryName: form.resourceName,
            GitPlatformType: form.gitImplementation,
            GitPlatformConfiguration: createGitPlatformConfiguration()
          });
        case PipelinePlatformType.Azure_SynapseAnalytics:
          return JSON.stringify({
            SubscriptionId: form.subscriptionId,
            ResourceGroupName: form.resourceGroupName,
            SynapseWorkspaceName: form.resourceName,
            GitPlatformType: form.gitImplementation,
            GitPlatformConfiguration: createGitPlatformConfiguration()
          });
        case PipelinePlatformType.Aws_StepFunctions:
          return JSON.stringify({
            AwsRegion: form.awsRegion,
            AwsAccountId: form.awsAccountId
          });
        default:
          return '';
      }
    };

    const createGitPlatformConfiguration = () => {
      switch (form.gitImplementation) {
        case 0:
          return {
            GitRepositoryId: getRepositoryIdByName(form.gitRepositoryName),
            GitRepositoryName: form.gitRepositoryName
          };
        default:
          return '';
      }
    };

    const getRepositoryIdByName = (repositoryName: string) => {
      let repositoryObject = localGitRepositories.value.find(
        el => el.name == repositoryName
      );
      if (!repositoryObject) return '';
      return (
        JSON.parse(repositoryObject.configuration ?? '{}')['repositoryId'] ?? ''
      );
    };

    const getRepositoryNameById = (repositoryId: string) => {
      let repositoryObject = localGitRepositories.value.find(
        el =>
          JSON.parse(el.configuration ?? '{}')['repositoryId'] == repositoryId
      );
      return repositoryObject?.name ?? '';
    };

    let localResources = ref(new Array<ResourceModel>());
    const handleImplementationUpdate = (implementation: number) => {
      form.implementation = implementation;
      form.resourceName = '';
      switch (implementation) {
        case PipelinePlatformType.Azure_DataFactory:
          localResources.value = props.resources.filter(
            el => el.resourceType == ResourceType.DataFactory
          );
          form.resourceName = localConfiguration.value['DataFactoryName'] ?? '';
          formRules.value = {
            subscriptionId: [
              {
                validator: mustBeGuid,
                trigger: 'blur'
              }
            ],
            resourceGroupName: [
              {
                validator: mustNotBeEmpty,
                trigger: 'blur'
              },
              {
                validator: mustBeAlphanumericDashUnderscore,
                trigger: 'blur'
              }
            ],
            GitImplementation: [
              {
                validator: mustNotBeEmpty,
                trigger: 'blur'
              }
            ]
          };
          break;
        case PipelinePlatformType.Azure_SynapseAnalytics:
          localResources.value = props.resources.filter(
            el => el.resourceType == ResourceType.SynapseAnalytics
          );
          form.resourceName =
            localConfiguration.value['SynapseWorkspaceName'] ?? '';
          formRules.value = {
            subscriptionId: [
              {
                validator: mustBeGuid,
                trigger: 'blur'
              }
            ],
            resourceGroupName: [
              {
                validator: mustNotBeEmpty,
                trigger: 'blur'
              },
              {
                validator: mustBeAlphanumericDashUnderscore,
                trigger: 'blur'
              }
            ],
            GitImplementation: [
              {
                validator: mustNotBeEmpty,
                trigger: 'blur'
              }
            ]
          };
          break;
        case PipelinePlatformType.Aws_StepFunctions:
          localResources.value = new Array<ResourceModel>();
          formRules.value = {
            awsRegion: [
              {
                validator: mustBeAlphanumericDashUnderscore,
                trigger: 'blur'
              }
            ],
            awsAccountId: [
              {
                validator: mustBeAlphaNumericAndLowerCase,
                trigger: 'blur'
              }
            ]
          };
          break;
        default:
          localResources.value = new Array<ResourceModel>();
          formRules.value = {};
      }
    };

    let localConfiguration = ref({});

    const updateLocalVars = async () => {
      localConfiguration.value = JSON.parse(
        props.configuration.pipelinePlatformConfiguration || '{}'
      );
      form.gitImplementation = localConfiguration.value['GitPlatformType'] ?? 0;
      let gitConfig =
        localConfiguration.value['GitPlatformConfiguration'] || '{}';

      form.gitRepositoryName = getRepositoryNameById(gitConfig.GitRepositoryId);
      form.subscriptionId = localConfiguration.value['SubscriptionId'] ?? '';
      form.resourceGroupName =
        localConfiguration.value['ResourceGroupName'] ?? '';
      form.awsRegion = localConfiguration.value['AwsRegion'] ?? '';
      form.awsAccountId = localConfiguration.value['AwsAccountId'] ?? '';
      handleImplementationUpdate(props.configuration.pipelinePlatformType ?? 0);
    };

    let localGitRepositories = ref(new Array<ResourceModel>());

    onUpdated(() => {
      localGitRepositories.value = props.resources.filter(
        el => el.resourceType == ResourceType.GitRepository
      );
      updateLocalVars();
    });

    return {
      form,
      formRef,
      formRules,
      localGitRepositories,
      handleImplementationUpdate,
      localResources,
      PipelinePlatformType,
      NodeModelPlatformType,
      save
    };
  }
};
</script>
<style scoped lang="scss"></style>
