import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_base_modal = _resolveComponent("base-modal")!

  return (_openBlock(), _createBlock(_component_base_modal, {
    "modal-title": _ctx.$t('addNewNode'),
    "confirm-text": _ctx.$t('save'),
    "dialog-visible": $props.showModal,
    width: "400px",
    onConfirm: $setup.confirm,
    onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close')))
  }, {
    content: _withCtx(() => [
      _createVNode(_component_el_form, {
        ref: "formRef",
        model: $setup.form,
        rules: $setup.formRules
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, {
            prop: "nodeName",
            label: _ctx.$t('name') + ':'
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: $setup.form.nodeName,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.form.nodeName) = $event)),
                size: "small"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["label"]),
          (!$props.hideSchemaField)
            ? (_openBlock(), _createBlock(_component_el_form_item, {
                key: 0,
                prop: "dbSchema",
                label: _ctx.$t('dbSchema') + ':'
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: $setup.form.dbSchema,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.form.dbSchema) = $event)),
                    size: "small"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }, 8, ["label"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["model", "rules"])
    ]),
    _: 1
  }, 8, ["modal-title", "confirm-text", "dialog-visible", "onConfirm"]))
}