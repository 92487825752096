import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dashboard_configuration_card = _resolveComponent("dashboard-configuration-card")!
  const _component_e_invoicing_configuration_card = _resolveComponent("e-invoicing-configuration-card")!
  const _component_gain_configuration_card = _resolveComponent("gain-configuration-card")!
  const _component_node_model_configuration_card = _resolveComponent("node-model-configuration-card")!
  const _component_reporting_configuration_card = _resolveComponent("reporting-configuration-card")!
  const _component_task_scheduler_configuration_card = _resolveComponent("task-scheduler-configuration-card")!
  const _component_pipeline_configuration_card = _resolveComponent("pipeline-configuration-card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($setup.activeEngagementFeaturesArray.includes($setup.feature_dashboards_powerbi))
      ? (_openBlock(), _createBlock(_component_dashboard_configuration_card, {
          key: 0,
          configuration: $setup.dashboardConfiguration,
          disabled: false,
          resources: $setup.tenantResources,
          class: "mt-1",
          onUpdate: $setup.handleDashboardConfigurationUpdate
        }, null, 8, ["configuration", "resources", "onUpdate"]))
      : _createCommentVNode("", true),
    ($setup.activeEngagementFeaturesArray.includes($setup.feature_eInvoicing))
      ? (_openBlock(), _createBlock(_component_e_invoicing_configuration_card, {
          key: 1,
          configuration: $setup.eInvoicingConfiguration.configuration,
          resources: $setup.tenantResources,
          class: "mt-1",
          onUpdate: $setup.handleEInvoicingConfigurationUpdate
        }, null, 8, ["configuration", "resources", "onUpdate"]))
      : _createCommentVNode("", true),
    ($setup.activeEngagementFeaturesArray.includes($setup.feature_gain))
      ? (_openBlock(), _createBlock(_component_gain_configuration_card, {
          key: 2,
          "latest-configuration": $setup.gainConfiguration,
          "configuration-history": $setup.gainConfigurationHistory,
          class: "mt-1",
          onFetchHistory: $setup.fetchGainConfigurationHistory,
          onUpdate: $setup.handleGainConfigurationUpdate
        }, null, 8, ["latest-configuration", "configuration-history", "onFetchHistory", "onUpdate"]))
      : _createCommentVNode("", true),
    ($setup.activeEngagementFeaturesArray.includes($setup.feature_nodeModelGit))
      ? (_openBlock(), _createBlock(_component_node_model_configuration_card, {
          key: 3,
          configuration: $setup.nodeModelConfiguration,
          disabled: false,
          "hide-sql-fields": $setup.activeEngagementFeaturesArray.includes($setup.feature_gain),
          resources: $setup.tenantResources,
          class: "mt-1",
          onUpdate: $setup.handleNodeModelConfigurationUpdate
        }, null, 8, ["configuration", "hide-sql-fields", "resources", "onUpdate"]))
      : _createCommentVNode("", true),
    ($setup.activeEngagementFeaturesArray.includes($setup.feature_reporting))
      ? (_openBlock(), _createBlock(_component_reporting_configuration_card, {
          key: 4,
          configuration: $setup.reportingConfiguration,
          resources: $setup.tenantResources,
          class: "mt-1",
          onUpdate: $setup.handleReportingConfigurationUpdate
        }, null, 8, ["configuration", "resources", "onUpdate"]))
      : _createCommentVNode("", true),
    ($setup.activeEngagementFeaturesArray.includes($setup.feature_taskScheduler))
      ? (_openBlock(), _createBlock(_component_task_scheduler_configuration_card, {
          key: 5,
          configuration: $setup.taskSchedulerConfiguration,
          disabled: false,
          resources: $setup.tenantResources,
          class: "mt-1",
          onUpdate: $setup.handleTaskSchedulerConfigurationUpdate
        }, null, 8, ["configuration", "resources", "onUpdate"]))
      : _createCommentVNode("", true),
    ($setup.activeEngagementFeaturesArray.includes($setup.feature_pipelines))
      ? (_openBlock(), _createBlock(_component_pipeline_configuration_card, {
          key: 6,
          configuration: $setup.pipelineConfiguration,
          disabled: false,
          resources: $setup.tenantResources,
          class: "mt-1",
          onUpdate: $setup.handlePipelineConfigurationUpdate
        }, null, 8, ["configuration", "resources", "onUpdate"]))
      : _createCommentVNode("", true)
  ], 64))
}