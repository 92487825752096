<template>
  <div class="eula-wrapper">
    <h3 class="title">General</h3>
    <p class="content">
      The Tax Data Platform by Deloitte Netherlands can only be used for
      engagements or in preparation for
      <br />
      engagements. For use of other purposes please contact Platform Support via
      <a href="mailto:nltaxtdp@deloitte.nl">nltaxtdp@deloitte.nl</a>
    </p>
    <h3 class="title">Compliance with laws and your Member Firm's policies</h3>
    <p class="content">
      When using the Tax Data Platform by Deloitte Netherlands, you must also
      comply with the following
      <br />
      (as applicable):
    </p>

    <ul class="content">
      <li>
        Your Member Firms' rules and policies including any rules or policies
        that your Member Firm has adopted with respect to the Tax Data Platform
        by Deloitte Netherlands;
      </li>
      <li>
        All laws, regulations and rules (including any professional secrecy laws
        or data privacy laws) applicable to your Member Firm’s use of the Tax
        Data Platform by Deloitte Netherlands;
      </li>
      <li>
        Your Member Firm rules and policies, including all rules and policies
        that govern the treatment of confidential, proprietary, or personal
        information;
      </li>
      <li>
        The terms of any applicable client engagement or service agreement or
        any other applicable third party agreements;
      </li>
      <li>
        Any additional license terms or IPCO Agreements Use Restrictions
        applicable to such material.
      </li>
    </ul>

    <p class="content">
      In addition, You Member firm may limit your access to certain sections of
      the Tax Data Platform by
      <br />
      Deloitte Netherlands. If so, you may access and use only those sections,
      and you shall not access any
      <br />
      other section or content.
    </p>
    <h3 class="title">IPCO</h3>
    <p class="content">
      Unless otherwise contractually stipulated, every Member Firm involved in
      the Tax Data Platform agrees that IPCO Category D is applicable:
    </p>
    <p>&nbsp;</p>
    <p class="content">
      <i>Any party to the Shared IP Agreement and its affiliates may:</i>
    </p>

    <ul class="content">
      <li>
        <i>
          create enhancements of the Shared IP (owned by such party or its
          affiliate);
        </i>
      </li>
      <li><i>use the Shared IP and enhancements for any purpose, and;</i></li>
      <li>
        <i>
          sublicense the Shared IP and enhancements to any third party to use
          for any purpose.
        </i>
      </li>
    </ul>
    <p class="content">
      For more information:

      <a
        href="https://global.deloitteresources.com/resource/KM/Documents/Shared%20ip%20agreement_final_928277.pdf"
      >
        Deloitte Resources
      </a>
    </p>
    <p>&nbsp;</p>
    <p class="content">
      If desired, other agreements are negotiable between Member Firms. Please
      contact Platform Support
      <br />
      via
      <a href="mailto:nltaxtdp@deloitte.nl">nltaxtdp@deloitte.nl</a>
    </p>
  </div>
</template>

<script lang="ts">
export default {
  name: 'EulaText'
};
</script>

<style lang="scss" scoped>
.title {
  color: $etp-deloitte-blue-7;
  margin: 20px 0px 10px 0;
}
.content {
  color: $etp-deloitte-black;
}
.eula-wrapper {
  padding: 10px;
}
</style>
