<template>
  <page-header :main-title="$t('engagementAccessOverview')">
    <template #tabs>
      <div class="d-flex mt-5">
        <router-link
          v-if="userCanReadEngagementAuthorization"
          v-slot="{ isActive }"
          :to="{ name: 'EngagementAccessUsers' }"
        >
          <base-tab :active="isActive">
            <h4>{{ $t('users') }}</h4>
          </base-tab>
        </router-link>
        <router-link
          v-if="userCanReadEngagementAuthorization"
          v-slot="{ isActive }"
          :to="{ name: 'EngagementAccessApplications' }"
        >
          <base-tab :active="isActive">
            <h4>{{ $t('applications') }}</h4>
          </base-tab>
        </router-link>
        <router-link
          v-if="userCanReadEngagementRbacTasks"
          v-slot="{ isActive }"
          :to="{ name: 'EngagementAccessTasks' }"
        >
          <base-tab :active="isActive">
            <h4>{{ $t('tasks') }}</h4>
          </base-tab>
        </router-link>
        <div class="horizontal-line mt-2">
          <hr />
        </div>
      </div>
    </template>
  </page-header>
  <div class="mt-6">
    <router-view />
  </div>
</template>
<script lang="ts">
import PageHeader from '@/components/misc/PageHeader.vue';
import BaseTab from '@/components/tabs/BaseTab.vue';
import { ComputedRef, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import store from '@/store';
export default {
  name: 'EngagementAccessOverview',
  components: { PageHeader, BaseTab },
  setup() {
    const router = useRouter();

    const userCanReadEngagementAuthorization: ComputedRef<boolean> = computed(
      () => store.getters['userPermissions/canReadEngagementAuthorization']
    );
    const userCanReadEngagementRbacTasks: ComputedRef<boolean> = computed(
      () => store.getters['userPermissions/canReadEngagementRbacTasks']
    );

    onMounted(async () => {
      await store.dispatch(
        'userPermissions/checkIfCanReadEngagementAuthorization'
      );
      await store.dispatch('userPermissions/checkIfCanReadEngagementRbacTasks');

      if (
        !userCanReadEngagementAuthorization.value &&
        !userCanReadEngagementRbacTasks.value
      ) {
        return router.push({ name: 'Overview' });
      }

      const engagementId = router.currentRoute.value.params
        .engagementId as string;
      await store.dispatch('engagements/fetchEngagement', engagementId);
    });

    return {
      userCanReadEngagementAuthorization,
      userCanReadEngagementRbacTasks
    };
  }
};
</script>
<style scoped lang="scss"></style>
