import StorageOverview from '@/views/home/storage/StorageOverview.vue';
import StorageManager from '@/views/home/storage/components/StorageManager.vue';

export default [
  {
    path: '/storage-manager',
    name: 'StorageOverview',
    component: StorageOverview,
    redirect: {
      name: 'StorageManager',
      params: { storagePurpose: 'main', path: [] }
    },
    meta: { menuItemName: 'StorageOverview' },
    children: [
      {
        path: ':storagePurpose/:path(.*)*',
        name: 'StorageManager',
        component: StorageManager
      }
    ]
  }
];
