<template>
  <el-card class="box-card">
    <template v-if="!noHeader" #header>
      <div class="card-header">
        <slot name="header"></slot>
      </div>
    </template>
    <slot name="body"></slot>
  </el-card>
</template>
<script lang="ts">
import { ElCard } from 'element-plus';
export default {
  name: 'BaseCard',
  components: { ElCard },
  props: { noHeader: { type: Boolean, default: false } }
};
</script>
