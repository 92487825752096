<template>
  <el-row>
    <el-button class="ml-auto" type="primary" @click="showAddRoleModal = true">
      <h4>{{ $t('addRole') }}</h4>
    </el-button>
  </el-row>
  <table-placeholder-loading v-if="loading" :columns="4" />
  <el-table v-else :data="aggregatedRoleAssignments">
    <el-table-column width="200">
      <template #default="scope">
        <el-tooltip>
          <template #default>
            <div>
              <h4>{{ scope.row.aggregationDisplayName ?? $t('na') }}</h4>
              <span class="body-1">
                {{
                  Object.keys(RoleAssignmentAggregation)[
                    Object.values(RoleAssignmentAggregation).findIndex(
                      el => el == scope.row.aggregationType
                    )
                  ]
                }}
              </span>
            </div>
          </template>
          <template #content>
            <el-button
              type="primary"
              @click="removeAllRoleAssignments(scope.row)"
            >
              {{ $t('remove') }}
            </el-button>
          </template>
        </el-tooltip>
      </template>
    </el-table-column>
    <el-table-column>
      <template #header>
        <el-row>
          <el-col :span="6">
            {{ $t('role') }}
          </el-col>
          <el-col :span="17">
            {{ $t('scope') }}
          </el-col>
        </el-row>
      </template>
      <template #default="scope">
        <el-row
          v-for="roleAssignment in scope.row.roleAssignments.sort(compareRoleAssignments) as Array<RoleAssignmentModel>"
          :key="roleAssignment.roleName"
        >
          <el-col :span="6">
            <span class="body-1">
              {{ roleAssignment.roleName }}
            </span>
          </el-col>
          <el-col :span="17">
            <span class="body-1">
              {{ roleAssignment.scope }}
            </span>
          </el-col>
          <el-col :span="1">
            <img
              :src="require(`@/assets/images/icons/new/delete.svg`)"
              height="12"
              width="12"
              class="action-icon"
              @click="removeSingleRoleAssignment(roleAssignment)"
            />
          </el-col>
        </el-row>
      </template>
    </el-table-column>
  </el-table>
  <add-role-modal
    v-model="showAddRoleModal"
    :engagements="engagements"
    :roles="roles"
    :tenants="tenants"
    @confirm="addRoleAssignment"
  />
</template>
<script lang="ts">
import {
  ElButton,
  ElCol,
  ElRow,
  ElTable,
  ElTableColumn,
  ElTooltip
} from 'element-plus';
import { computed, ComputedRef, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import AddRoleModal from '@/components/modals/AddRoleModal.vue';
import {
  RoleAssignmentModel,
  RoleModel,
  ApplicationRoleAssignmentModel
} from '@etp/etp-authorization-client/axios';
import TablePlaceholderLoading from '@/components/tables/TablePlaceholderLoading.vue';
import config from '@/config/config';
import {
  AggregatedRoleAssignment,
  RoleAssignmentAggregation
} from '@/models/aggregatedRoleAssignment';
import {
  compareRoleAssignments,
  createAggregatedRoleAssignments
} from '@/utils/roleAssignmentFunctions';
import { EngagementModel } from '@etp/etp-engagements-client/axios';
import { BaseTenantModel } from '@etp/etp-infra-client';
import {
  ExtractEngagementFromScope,
  IsEngagementScope
} from '@/utils/scopeHelper';

export default {
  name: 'ApplicationRoles',
  components: {
    AddRoleModal,
    ElButton,
    ElCol,
    ElRow,
    ElTable,
    ElTableColumn,
    ElTooltip,
    TablePlaceholderLoading
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    const objectId = router.currentRoute.value.params.objectId as string;
    const showAddRoleModal = ref(false);
    const loading = ref(false);
    const aggregatedRoleAssignments = ref(
      new Array<AggregatedRoleAssignment>()
    );

    const roleAssignments: ComputedRef<Array<RoleAssignmentModel>> = computed(
      () => store.getters['applications/getApplicationRoleAssignments']
    );
    const engagements: ComputedRef<Array<EngagementModel>> = computed(
      () => store.getters['engagements/getAllEngagements']
    );
    const roles: ComputedRef<Array<RoleModel>> = computed(
      () => store.getters['roles/getRoles']
    );
    const tenants: ComputedRef<Array<BaseTenantModel>> = computed(
      () => store.getters['tenants/getTenants']
    );

    const addRoleAssignment = async (
      roleAssignments: Array<ApplicationRoleAssignmentModel>
    ) => {
      loading.value = true;
      showAddRoleModal.value = false;

      for (let roleAssignment of roleAssignments) {
        roleAssignment.objectId = objectId;
        await store.dispatch(
          'applications/addApplicationRoleAssignment',
          roleAssignment
        );
      }

      let roleAssignmentScope = roleAssignments[0].scope;
      if (
        IsEngagementScope(roleAssignmentScope) &&
        config.cloudProvider.toLowerCase() !== 'aws'
      ) {
        const engagementId = ExtractEngagementFromScope(roleAssignmentScope);
        await store.dispatch('engagements/fetchEngagement', engagementId);
        await store.dispatch('engagements/applyApplicationRbac', {
          objectId
        });
      }

      await updateLocalVars();
      loading.value = false;
    };

    const removeAllRoleAssignments = async (
      aggregatedRoleAssignment: AggregatedRoleAssignment
    ) => {
      loading.value = true;
      for (let roleAssignment of aggregatedRoleAssignment.roleAssignments) {
        await store.dispatch('applications/removeApplicationRoleAssignment', {
          objectId: objectId,
          roleName: roleAssignment.roleName,
          scope: roleAssignment.scope
        } as ApplicationRoleAssignmentModel);
      }
      if (
        aggregatedRoleAssignment.aggregationType ==
          RoleAssignmentAggregation.Engagement &&
        engagements.value.find(
          e => e.id == aggregatedRoleAssignment.aggregationId
        ) &&
        config.cloudProvider.toLowerCase() !== 'aws'
      ) {
        await store.dispatch(
          'engagements/fetchEngagement',
          aggregatedRoleAssignment.aggregationId
        );
        await store.dispatch('engagements/applyApplicationRbac', {
          objectId
        });
      }
      await updateLocalVars();
      loading.value = false;
    };

    const removeSingleRoleAssignment = async (
      roleAssignment: RoleAssignmentModel
    ) => {
      loading.value = true;
      await store.dispatch('applications/removeApplicationRoleAssignment', {
        objectId: objectId,
        roleName: roleAssignment.roleName,
        scope: roleAssignment.scope
      } as ApplicationRoleAssignmentModel);
      if (
        IsEngagementScope(roleAssignment.scope) &&
        roleAssignment.scope.split('/').length === 3 &&
        engagements.value.find(
          e => e.id == ExtractEngagementFromScope(roleAssignment.scope)
        ) &&
        config.cloudProvider.toLowerCase() !== 'aws'
      ) {
        const engagementId = ExtractEngagementFromScope(roleAssignment.scope);
        await store.dispatch('engagements/fetchEngagement', engagementId);
        await store.dispatch('engagements/applyApplicationRbac', {
          objectId
        });
      }
      await updateLocalVars();
      loading.value = false;
    };

    const updateLocalVars = async () => {
      await store.dispatch('applications/fetchApplicationDetails', objectId);
      await store.dispatch('engagements/fetchAllEngagements');
      await store.dispatch('roles/fetchAllRoles');
      await store.dispatch('tenants/fetchAllTenants');
      aggregatedRoleAssignments.value = createAggregatedRoleAssignments(
        roleAssignments.value,
        engagements.value,
        tenants.value
      );
    };

    onMounted(async () => {
      loading.value = true;
      await updateLocalVars();
      loading.value = false;
    });

    return {
      addRoleAssignment,
      aggregatedRoleAssignments,
      compareRoleAssignments,
      engagements,
      loading,
      removeAllRoleAssignments,
      removeSingleRoleAssignment,
      roles,
      RoleAssignmentAggregation,
      showAddRoleModal,
      tenants
    };
  }
};
</script>
<style lang="scss" scoped></style>
