import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "pr-2 ml-2" }
const _hoisted_2 = { class: "pl-1 ml-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_base_table_header = _resolveComponent("base-table-header")!
  const _component_import_tasks_table = _resolveComponent("import-tasks-table")!
  const _component_base_table = _resolveComponent("base-table")!
  const _component_pagination = _resolveComponent("pagination")!
  const _component_import_task_create = _resolveComponent("import-task-create")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_col = _resolveComponent("el-col")!

  return (_openBlock(), _createBlock(_component_el_row, { gutter: 24 }, {
    default: _withCtx(() => [
      _createVNode(_component_el_col, { span: 17 }, {
        default: _withCtx(() => [
          _createVNode(_component_base_table_header, {
            "row-count": $setup.tasksCount,
            "quantity-message": _ctx.$t('orchestrations'),
            "input-placeholder": _ctx.$t('searchOrchestrations'),
            onQueryChange: $setup.changeFilter
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_el_select, {
                  modelValue: $setup.statusFilter,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.statusFilter) = $event)),
                  size: "small",
                  clearable: "",
                  placeholder: $setup.loadingTasks ? _ctx.$t('loading') : _ctx.$t('filterStatus')
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.TaskState, (item, key) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: key,
                        label: _ctx.$t(key),
                        value: item
                      }, null, 8, ["label", "value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue", "placeholder"])
              ]),
              _createElementVNode("div", _hoisted_2, [
                ($setup.userCanWriteEngagementTaskSchedulerTasks)
                  ? (_openBlock(), _createBlock(_component_el_button, {
                      key: 0,
                      type: "primary",
                      onClick: $setup.openNewTasksModal
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("h4", null, _toDisplayString('+ ' + _ctx.$t('newOrchestration')), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }, 8, ["row-count", "quantity-message", "input-placeholder", "onQueryChange"]),
          _createVNode(_component_el_row, { class: "d-block" }, {
            default: _withCtx(() => [
              _createVNode(_component_base_table, {
                "show-loading-table": $setup.loadingTasks,
                "show-no-content-table": !$setup.tasks.length
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_import_tasks_table, {
                    "table-data": $setup.tasks,
                    onApproveTask: $setup.approveTask,
                    onDisapproveTask: $setup.disapproveTask,
                    onEditTask: $setup.openNewTasksModal
                  }, null, 8, ["table-data", "onApproveTask", "onDisapproveTask", "onEditTask"])
                ]),
                _: 1
              }, 8, ["show-loading-table", "show-no-content-table"]),
              _createVNode(_component_pagination, {
                count: $setup.tasksCount,
                page: $setup.tasksPaginationParams.page,
                "page-size": $setup.tasksPaginationParams.pageSize,
                onChangePage: $setup.changePage
              }, null, 8, ["count", "page", "page-size", "onChangePage"]),
              _createVNode(_component_import_task_create, {
                "dialog-visible": $setup.showNewTaskModal,
                "task-value": $setup.taskModalData,
                onConfirm: $setup.createOrUpdateTask,
                onClose: _cache[1] || (_cache[1] = ($event: any) => ($setup.showNewTaskModal = false))
              }, null, 8, ["dialog-visible", "task-value", "onConfirm"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}