<template>
  <div v-for="row in 4" :key="row" class="mt-2 d-grid skeleton-table-row">
    <div class="loading-table-checkbox" />
    <div class="data d-grid content">
      <div v-for="column in columns" :key="column" class="loading-table-data" />
    </div>
    <div class="actions d-flex">
      <div
        v-for="action in actions"
        :key="action"
        class="loading-table-action"
      />
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: 'TablePlaceholderLoading',
  props: {
    columns: { type: Number, default: 3 },
    actions: { type: Number, default: 3 }
  }
};
</script>
<style scoped lang="scss">
.skeleton-table-row {
  grid-template-columns: 5% 80% 10%;
  column-gap: 1em;
  padding: 0 1em 0;
  align-items: center;
  height: 3em;
  background: $etp-table-row;
  border-radius: 1.25em;
  backdrop-filter: blur(20px);
  box-shadow: 0px 2px 10px $etp-nodata-shadow;
  &:nth-child(2),
  &:nth-child(4) {
    .loading-table-data {
      &:first-child {
        width: 50%;
      }
    }
  }
  &:nth-child(2),
  &:nth-child(4) {
    .loading-table-data {
      &:nth-child(2) {
        width: 70%;
      }
    }
  }
  &:nth-child(1),
  &:nth-child(3) {
    .loading-table-data {
      &:last-child {
        width: 30%;
      }
    }
  }
  animation: topDown 6s ease-in;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  opacity: 0;
  &:nth-child(2) {
    animation-delay: 0.5s;
  }
  &:nth-child(3) {
    animation-delay: 0.75s;
  }
  &:nth-child(4) {
    animation-delay: 1s;
  }
  @keyframes topDown {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}
.loading-table-checkbox,
.loading-table-data,
.loading-table-action {
  height: 0.75em;
  margin: 0 0.25em 0;
  background-image: linear-gradient(90deg, $etp-deloitte-blue-1, $etp-shade-1);
  border-radius: 1.25em;
}
.loading-table-checkbox {
  width: 1em;
}
.loading-table-action {
  width: 2em;
}
.content {
  grid-template-columns: repeat(auto-fit, minmax(2em, 1fr));
  column-gap: 5em;
}
</style>
