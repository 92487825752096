<template>
  <div>
    <el-row>
      <el-col :span="24">
        <page-header :main-title="`${$t('tenantDetails')} - ${tenant.name}`">
          <template #tabs>
            <h4 class="subtitle-2 mt-2">
              {{
                $t('deploymentStatus') +
                ' : ' +
                Object.keys(DeploymentStatus)[tenant.deploymentStatus]
              }}
            </h4>
            <div class="d-flex mt-2">
              <router-link
                v-slot="{ isExactActive }"
                :to="{ name: 'TenantGeneral' }"
              >
                <base-tab :active="isExactActive">
                  <h4>{{ $t('general') }}</h4>
                </base-tab>
              </router-link>
              <router-link
                v-slot="{ isActive }"
                :to="{ name: 'TenantCompose' }"
              >
                <base-tab :active="isActive">
                  <h4>{{ $t('compose') }}</h4>
                </base-tab>
              </router-link>
              <router-link
                v-slot="{ isActive }"
                :to="{ name: 'TenantDeployments' }"
              >
                <base-tab :active="isActive">
                  <h4>{{ $t('deployments') }}</h4>
                </base-tab>
              </router-link>
              <router-link
                v-slot="{ isActive }"
                :to="{ name: 'TenantEngagements' }"
              >
                <base-tab :active="isActive">
                  <h4>{{ $t('engagements') }}</h4>
                </base-tab>
              </router-link>
              <router-link v-slot="{ isActive }" :to="{ name: 'TenantUsers' }">
                <base-tab :active="isActive">
                  <h4>{{ $t('users') }}</h4>
                </base-tab>
              </router-link>
              <router-link
                v-slot="{ isActive }"
                :to="{ name: 'TenantApplications' }"
              >
                <base-tab :active="isActive">
                  <h4>{{ $t('applications') }}</h4>
                </base-tab>
              </router-link>
              <div class="horizontal-line mt-2">
                <hr />
              </div>
            </div>
          </template>
        </page-header>
      </el-col>
    </el-row>
    <div class="mt-6">
      <router-view />
    </div>
  </div>
</template>
<script lang="ts">
import { computed, ComputedRef, onMounted } from 'vue';
import { useStore } from 'vuex';
import PageHeader from '@/components/misc/PageHeader.vue';
import { useRouter } from 'vue-router';
import { ElRow, ElCol } from 'element-plus';
import BaseTab from '@/components/tabs/BaseTab.vue';
import { TenantModel, DeploymentStatus } from '@etp/etp-infra-client';

export default {
  name: 'TenantDetails',
  components: {
    PageHeader,
    ElRow,
    ElCol,
    BaseTab
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    let tenantId: string;

    let tenant: ComputedRef<TenantModel> = computed(
      () => store.getters['tenants/getActiveTenant']
    );

    // eslint-disable-next-line
    const fetchTenant = async (tenantId: string) => {
      await store.dispatch('tenants/fetchTenant', { tenantId });
    };

    onMounted(async () => {
      tenantId = router.currentRoute.value.params.tenantId as string;
      await fetchTenant(tenantId);
    });

    return {
      DeploymentStatus,
      tenant
    };
  }
};
</script>
<style lang="scss" scoped>
.router {
  display: flex;
}
</style>
