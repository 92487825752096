<template>
  <base-modal
    :modal-title="$t('addNewBranch')"
    :confirm-text="$t('save')"
    :dialog-visible="showModal"
    width="400px"
    @confirm="confirm"
    @close="$emit('close')"
  >
    <template #content>
      <el-form ref="formRef" :model="form" :rules="formRules">
        <el-form-item prop="parentBranch" :label="$t('parentBranch')">
          <el-select
            v-model="form.parentBranch"
            value-key="name"
            size="small"
            :placeholder="$t('empty')"
          >
            <el-option
              v-for="item in branches"
              :key="item.name ?? ''"
              :label="item.name ?? ''"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="branchName" :label="$t('branchName') + ':'">
          <el-input v-model="form.branchName" size="small" />
        </el-form-item>
      </el-form>
    </template>
  </base-modal>
</template>
<script lang="ts">
import {
  ElForm,
  ElFormItem,
  ElOption,
  ElSelect,
  ElInput,
  ElMessage
} from 'element-plus';

import BaseModal from '@/components/modals/BaseModal.vue';
import { onUpdated, reactive, ref } from 'vue';
import { mustBeAlphanumericDashUnderscore } from '@/utils/formValidators';
import i18n from '@/i18n';
import {
  BranchModel,
  CreateBranchModel
} from '@etp/etp-nodemodelgit-client/axios';
export default {
  name: 'NewBranchModal',
  components: {
    BaseModal,
    ElInput,
    ElForm,
    ElFormItem,
    ElOption,
    ElSelect
  },
  props: {
    showModal: { type: Boolean, default: false },
    defaultParentBranch: { type: Object, default: {} as BranchModel },
    branches: {
      type: Array as () => Array<BranchModel>,
      default: () => new Array<BranchModel>()
    }
  },
  emits: ['close', 'confirm'],
  setup(props, { emit }) {
    const mustNotContainDuplicateName = (rule, inputName: string, callback) => {
      const allBranches = props.branches;
      allBranches.find(o => {
        if (o.name == inputName) {
          callback(new Error(i18n.global.t('noDuplicateBranchName')));
          return;
        }
      });
      callback();
    };

    let form = ref({
      branchName: '',
      parentBranch: {} as BranchModel
    });
    let formRules = reactive({
      branchName: [
        { validator: mustBeAlphanumericDashUnderscore, trigger: 'change' },
        { validator: mustNotContainDuplicateName, trigger: 'change' }
      ]
    });

    const formRef = ref<HTMLFormElement>();
    const confirm = () => {
      formRef.value?.validate((valid: boolean) => {
        if (!valid) return;
        emit('confirm', {
          name: form.value.branchName,
          parentBranch: form.value.parentBranch.name
        } as CreateBranchModel);
        ElMessage({
          showClose: true,
          message: i18n.global.t('newBranchAdded')
        });
      });
    };
    onUpdated(() => {
      form.value.parentBranch = props.defaultParentBranch;
      formRef.value?.resetFields();
    });

    return { confirm, form, formRef, formRules, mustNotContainDuplicateName };
  }
};
</script>
