import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-38e122b0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-inline-block subtitle-2 mt-3" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "mt-2" }
const _hoisted_5 = { class: "user-info-container mt-1" }
const _hoisted_6 = { class: "d-inline-block" }
const _hoisted_7 = { class: "d-inline-block" }
const _hoisted_8 = { class: "d-inline-block" }
const _hoisted_9 = { class: "d-inline-block" }
const _hoisted_10 = { class: "mb-1" }
const _hoisted_11 = { class: "space-around" }
const _hoisted_12 = { class: "space-around" }
const _hoisted_13 = { class: "d-inline-block" }
const _hoisted_14 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_header = _resolveComponent("page-header")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_base_card = _resolveComponent("base-card")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createBlock(_component_el_row, { gutter: $setup.spacingWidthHeaderComponents }, {
    default: _withCtx(() => [
      _createVNode(_component_el_col, null, {
        default: _withCtx(() => [
          _createVNode(_component_page_header, {
            "main-title": _ctx.$t('profile')
          }, {
            subheader: _withCtx(() => [
              _createElementVNode("h4", _hoisted_1, _toDisplayString(_ctx.$t('profileDesc')), 1)
            ]),
            _: 1
          }, 8, ["main-title"]),
          _createVNode(_component_el_row, {
            gutter: 50,
            class: "mt-6"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, { span: 4.5 }, {
                default: _withCtx(() => [
                  _createVNode(_component_base_card, { "no-header": "" }, {
                    body: _withCtx(() => [
                      ($setup.profilePicture)
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 0,
                            src: $setup.profilePicture,
                            class: "profile-picture"
                          }, null, 8, _hoisted_2))
                        : (_openBlock(), _createElementBlock("img", {
                            key: 1,
                            src: $setup.defaultProfilePicture,
                            class: "profile-picture"
                          }, null, 8, _hoisted_3)),
                      _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.$t('myInfo')), 1),
                      _createElementVNode("div", _hoisted_5, [
                        _createVNode(_component_el_row, { class: "user-info-row" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_col, { span: 6 }, {
                              default: _withCtx(() => [
                                _createElementVNode("h4", _hoisted_6, _toDisplayString(_ctx.$t('name')) + ":", 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_el_col, { span: 18 }, {
                              default: _withCtx(() => [
                                _createElementVNode("h4", _hoisted_7, _toDisplayString($setup.currentUser.displayName), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_el_row, { class: "user-info-row" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_col, { span: 6 }, {
                              default: _withCtx(() => [
                                _createElementVNode("h4", _hoisted_8, _toDisplayString(_ctx.$t('email')) + ":", 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_el_col, { span: 18 }, {
                              default: _withCtx(() => [
                                _createElementVNode("h4", _hoisted_9, _toDisplayString($setup.currentUser.email), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_base_card, {
                    "no-header": "",
                    class: "mt-2"
                  }, {
                    body: _withCtx(() => [
                      _createElementVNode("h3", _hoisted_10, _toDisplayString(_ctx.$t('profileSettings')), 1),
                      _createVNode(_component_el_form, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_form_item, {
                            label: _ctx.$t('language'),
                            class: "mb-0"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_select, {
                                modelValue: _ctx.$i18n.locale,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$i18n.locale) = $event)),
                                size: "small",
                                onChange: $setup.updateLanguage
                              }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$i18n.availableLocales, (language, i) => {
                                    return (_openBlock(), _createBlock(_component_el_option, {
                                      key: `Lang${i}`,
                                      value: language,
                                      class: "d-flex align-items-center"
                                    }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("h3", _hoisted_11, _toDisplayString(language), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["value"]))
                                  }), 128))
                                ]),
                                _: 1
                              }, 8, ["modelValue", "onChange"])
                            ]),
                            _: 1
                          }, 8, ["label"]),
                          _createVNode(_component_el_form_item, {
                            label: _ctx.$t('template'),
                            class: "mb-0"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_select, {
                                modelValue: $setup.selectedTemplate,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.selectedTemplate) = $event)),
                                size: "small"
                              }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.templates, (template, i) => {
                                    return (_openBlock(), _createBlock(_component_el_option, {
                                      key: i,
                                      value: template,
                                      class: "d-flex align-items-center"
                                    }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("h3", _hoisted_12, _toDisplayString(template), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["value"]))
                                  }), 128))
                                ]),
                                _: 1
                              }, 8, ["modelValue"])
                            ]),
                            _: 1
                          }, 8, ["label"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_el_col, { span: 17 }, {
                default: _withCtx(() => [
                  _createVNode(_component_base_card, null, {
                    header: _withCtx(() => [
                      _createElementVNode("h2", null, _toDisplayString(_ctx.$t('yourEngagements')), 1),
                      _createElementVNode("h4", _hoisted_13, _toDisplayString(_ctx.$t('yourEngagementsDesc')), 1)
                    ]),
                    body: _withCtx(() => [
                      _createVNode(_component_el_table, {
                        data: $setup.engagements,
                        "empty-text": _ctx.$t('noEngagement')
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_table_column, {
                            label: _ctx.$t('name')
                          }, {
                            default: _withCtx((scope) => [
                              _createElementVNode("h4", {
                                class: "your-group-table",
                                onClick: ($event: any) => ($setup.changeEngagement(scope.row))
                              }, _toDisplayString(scope.row.name), 9, _hoisted_14)
                            ]),
                            _: 1
                          }, 8, ["label"])
                        ]),
                        _: 1
                      }, 8, ["data", "empty-text"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["gutter"]))
}