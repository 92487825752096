import { ActionContext, Module } from 'vuex';
import { SortDirection, SortParams } from '@/services/web-api/dto/sortParams';
import { createPipelinesApiClient } from '@/services/api';
import { PipelineStatus } from '@/services/web-api/enums/pipelineStatus';
import {
  LayerName,
  PipelineDto,
  PipelineRunDto,
  StartPipelineRunDto,
  UpdatePipelineDto
} from '@etp/etp-pipelines-client/axios';

export interface PipelinesStoreState {
  pipelineLayerName: LayerName;
  pipelines: Array<PipelineDto>;
  pipelineRuns: Array<PipelineRunDto>;
  pipelineRunsDaysToLookBack: number;
  pipelineRunsSortParams: SortParams;
  pipelineStatusFilter: Array<PipelineStatus>;
  loadingListPipelineRuns: boolean;
  loadingListPipelines: boolean;
}
export class PipelinesStoreModule implements Module<PipelinesStoreState, any> {
  namespaced = true;
  constructor(pipelineLayerName: LayerName) {
    this.state.pipelineLayerName =
      LayerName[
        Object.keys(LayerName)[
          Object.values(LayerName).findIndex(el => el == pipelineLayerName)
        ]
      ];
  }
  state = {
    pipelineLayerName: LayerName.ImportLayer,
    pipelines: [],
    pipelineRuns: [],
    pipelineRunsDaysToLookBack: 30,
    pipelineRunsSortParams: new SortParams('runStart', SortDirection.DESC),
    pipelineStatusFilter: new Array<PipelineStatus>(),
    loadingListPipelineRuns: false,
    loadingListPipelines: false
  };
  getters = {
    pipelines: (state: PipelinesStoreState): any => state.pipelines,
    pipelineRuns: (state: PipelinesStoreState): any => state.pipelineRuns,
    pipelineRunsSortParams: (state: PipelinesStoreState): any =>
      state.pipelineRunsSortParams,
    loadingListPipelineRuns: (state: PipelinesStoreState): boolean =>
      state.loadingListPipelineRuns,
    loadingListPipelines: (state: PipelinesStoreState): boolean =>
      state.loadingListPipelines
  };
  actions = {
    async listPipelines(context: ActionContext<PipelinesStoreState, any>) {
      context.state.loadingListPipelines = true;
      let client = createPipelinesApiClient();
      const { data, status } = await client.listPipelines(
        context.rootState.home.currentEngagement.id,
        context.state.pipelineLayerName
      );

      if (status == 200) {
        context.commit('SET_PIPELINES', data);
      } else {
        context.commit('SET_PIPELINES', new Array<PipelineDto>());
      }
      context.state.loadingListPipelines = false;
    },
    async listPipelineRuns(context: ActionContext<PipelinesStoreState, any>) {
      context.state.loadingListPipelineRuns = true;
      let client = createPipelinesApiClient();
      const { data, status } = await client.listPipelineRuns(
        context.rootState.home.currentEngagement.id,
        context.state.pipelineLayerName,
        context.state.pipelineRunsSortParams.sortBy,
        context.state.pipelineRunsSortParams.sortDirection,
        context.state.pipelineRunsDaysToLookBack,
        context.state.pipelineStatusFilter
      );

      context.state.loadingListPipelineRuns = false;
      if (status == 200) {
        context.commit('SET_PIPELINE_RUNS', data);
      } else {
        context.commit('SET_PIPELINE_RUNS', new Array<PipelineRunDto>());
      }
      context.state.loadingListPipelineRuns = false;
    },
    async startPipelineRun(
      context: ActionContext<PipelinesStoreState, any>,
      payload: StartPipelineRunDto
    ) {
      let client = createPipelinesApiClient();
      await client.startPipelineRun(
        context.rootState.home.currentEngagement.id,
        {
          ...payload,
          currentLayer: context.state.pipelineLayerName
        }
      );
    },
    async updatePipeline(
      context: ActionContext<PipelinesStoreState, any>,
      payload: UpdatePipelineDto
    ) {
      let client = createPipelinesApiClient();
      await client.updatePipeline(context.rootState.home.currentEngagement.id, {
        ...payload,
        currentLayer: context.state.pipelineLayerName
      });
    },
    resetState({ commit }) {
      commit('RESET_STATE');
    }
  };
  mutations = {
    SET_PIPELINES(state: PipelinesStoreState, payload: Array<PipelineDto>) {
      state.pipelines = payload;
    },
    SET_PIPELINE_RUNS(
      state: PipelinesStoreState,
      payload: Array<PipelineRunDto>
    ) {
      state.pipelineRuns = payload;
    },
    SET_PIPELINE_RUNS_SORT_PARAMS(
      state: PipelinesStoreState,
      payload: SortParams
    ) {
      state.pipelineRunsSortParams = payload;
    },
    SET_PIPELINE_STATUS_FILTER(
      state: PipelinesStoreState,
      payload: Array<PipelineStatus>
    ) {
      state.pipelineStatusFilter = payload;
    }
  };
}
