import { createEngagementsApiClient } from '@/services/api';
import {
  feature_dashboards_analysisServices,
  feature_dashboards_powerbi,
  feature_gain,
  feature_nodeModelGit,
  feature_pipelines,
  feature_storage,
  feature_taskScheduler
} from '@/utils/variables';
import { EngagementModel } from '@etp/etp-engagements-client/axios';
import { ActionContext, Module } from 'vuex';

interface HomeState {
  loading: boolean;
  pageHeader: string;
  currentEngagement: EngagementModel;
  currentEngagementFeatures: Array<string>;
  availableEngagements: Array<EngagementModel>;
}

class HomeModule implements Module<HomeState, any> {
  namespaced = true;
  state = {
    loading: false,
    pageHeader: '',
    currentEngagement: {} as EngagementModel,
    currentEngagementFeatures: [],
    availableEngagements: new Array<EngagementModel>()
  };
  getters = {
    getCurrentEngagement: (state: HomeState): EngagementModel => {
      return state.currentEngagement;
    },
    getAvailableEngagements: (state: HomeState): Array<EngagementModel> => {
      return state.availableEngagements;
    },
    getLoading: (state: HomeState): boolean => {
      return state.loading;
    },
    getPageHeader: (state: HomeState): string => state.pageHeader,
    isGainEngagement: (state: HomeState): boolean => {
      return state.currentEngagementFeatures.includes(feature_gain);
    },
    showAnalysisServicesPages: (state: HomeState): boolean => {
      return state.currentEngagementFeatures.includes(
        feature_dashboards_analysisServices
      );
    },
    showDashboardPages: (state: HomeState): boolean => {
      return state.currentEngagementFeatures.includes(
        feature_dashboards_powerbi
      );
    },
    showDocumentationPages: (state: HomeState): boolean => {
      return state.currentEngagementFeatures.includes(feature_storage);
    },
    showNodeModelGitPages: (state: HomeState): boolean => {
      return state.currentEngagementFeatures.includes(feature_nodeModelGit);
    },
    showPipelinePages: (state: HomeState): boolean => {
      return state.currentEngagementFeatures.includes(feature_pipelines);
    },
    showStoragePages: (state: HomeState): boolean => {
      return state.currentEngagementFeatures.includes(feature_storage);
    },
    showTaskSchedulerPages: (state: HomeState): boolean => {
      return state.currentEngagementFeatures.includes(feature_taskScheduler);
    }
  };
  actions = {
    async fetchAvailableEngagements(context: ActionContext<HomeState, any>) {
      let client = createEngagementsApiClient();
      const { data, status } = await client.listMyEngagements();
      if (status >= 200 && status <= 299) {
        context.commit('SET_AVAILABLE_ENGAGEMENTS', data.results);
      } else {
        context.commit(
          'SET_AVAILABLE_ENGAGEMENTS',
          new Array<EngagementModel>()
        );
      }
    },
    async fetchCurrentEngagement(
      context: ActionContext<HomeState, any>,
      engagementId: string
    ) {
      if (!engagementId) {
        context.commit('SET_CURRENT_ENGAGEMENT', {} as EngagementModel);
        context.commit('SET_CURRENT_ENGAGEMENT_FEATURES', []);
        return;
      }
      let engagementClient = createEngagementsApiClient();
      const { data, status } = await engagementClient.getEngagement(
        engagementId
      );
      if (status >= 200 && status <= 299) {
        context.commit('SET_CURRENT_ENGAGEMENT', data);
        context.commit(
          'SET_CURRENT_ENGAGEMENT_FEATURES',
          JSON.parse(data.features ?? '[]')
        );
      } else {
        context.commit('SET_CURRENT_ENGAGEMENT', {} as EngagementModel);
        context.commit('SET_CURRENT_ENGAGEMENT_FEATURES', []);
      }
    },
    async fetchLastCurrentEngagement(context: ActionContext<HomeState, any>) {
      let lastCurrentEngagementId = localStorage.getItem(
        'lastCurrentEngagement'
      );
      if (!lastCurrentEngagementId) return;
      if (
        context.state.availableEngagements.some(
          e => e.id == lastCurrentEngagementId
        )
      ) {
        await context.dispatch(
          'fetchCurrentEngagement',
          lastCurrentEngagementId
        );
      } else {
        context.commit('SET_CURRENT_ENGAGEMENT', {} as EngagementModel);
        context.commit('SET_CURRENT_ENGAGEMENT_FEATURES', []);
      }
    }
  };
  mutations = {
    SET_AVAILABLE_ENGAGEMENTS(
      state: HomeState,
      engagements: Array<EngagementModel>
    ) {
      state.availableEngagements = engagements;
    },
    SET_CURRENT_ENGAGEMENT(state: HomeState, engagement: EngagementModel) {
      state.currentEngagement = engagement;
      if (engagement.id) {
        localStorage.setItem('lastCurrentEngagement', engagement.id);
      } else {
        localStorage.removeItem('lastCurrentEngagement');
      }
    },
    SET_CURRENT_ENGAGEMENT_FEATURES(state: HomeState, features: Array<string>) {
      state.currentEngagementFeatures = features;
    },
    SET_LOADING(state: HomeState, payload: boolean) {
      state.loading = payload;
    },
    SET_PAGE_HEADER(state: HomeState, payload: string) {
      state.pageHeader = payload;
    }
  };
}

export default new HomeModule();
