<template>
  <base-modal
    width="690px"
    :modal-title="props.modalTitle"
    :confirm-text="$t('confirm')"
    :dialog-visible="dialogVisible"
    :has-confirmation-button="!!fileNamesToProcess.length"
    @confirm="confirm"
    @close="close"
  >
    <template #content>
      <base-table>
        <el-table
          ref="multipleTableRef"
          :data="props.files"
          @selection-change="handleProcessSelectionChange"
        >
          <el-table-column type="selection" />
          <el-table-column :label="$t('name')" prop="actualName" />
          <el-table-column
            v-if="showArchive"
            width="100"
            :label="$t('archive')"
          >
            <template #default="scope">
              <el-checkbox
                @change="x => handleArchiveSelectionChange(x, scope.row)"
              />
            </template>
          </el-table-column>
        </el-table>
      </base-table>
    </template>
  </base-modal>
</template>

<script lang="ts">
import {
  CheckboxValueType,
  ElCheckbox,
  ElMessage,
  ElTable,
  ElTableColumn
} from 'element-plus';
import i18n from '@/i18n';
import BaseModal from '@/components/modals/BaseModal.vue';
import BaseTable from '@/components/tables/BaseTable.vue';
import { ref } from 'vue';
import { StorageObject } from '@etp/etp-storage-client/axios';
import { ImportFileSelection } from '../models/ImportFileSelection';

export default {
  name: 'ImportFileSelectionModal',
  components: {
    BaseModal,
    BaseTable,
    ElCheckbox,
    ElTable,
    ElTableColumn
  },
  props: {
    dialogVisible: { type: Boolean },
    feedbackText: { type: String, default: i18n.global.t('saved') },
    files: {
      type: Array as () => Array<StorageObject>,
      default: new Array<StorageObject>()
    },
    modalTitle: { type: String, default: i18n.global.t('selectFiles') },
    showArchive: { type: Boolean, default: false }
  },
  emits: ['confirm', 'close'],
  setup(props, { emit }) {
    let fileNamesToArchive = ref(new Array<string>());
    let fileNamesToProcess = ref(new Array<string>());

    const confirm = () => {
      emit('confirm', {
        fileNamesToArchive: fileNamesToArchive.value,
        fileNamesToProcess: fileNamesToProcess.value
      } as ImportFileSelection);
      ElMessage({
        showClose: true,
        message: props.feedbackText
      });
    };
    const close = () => emit('close');

    const handleArchiveSelectionChange = (
      isSelected: CheckboxValueType,
      file: StorageObject
    ) => {
      const index = fileNamesToArchive.value.indexOf(file.actualName);
      if (isSelected && index == -1) {
        fileNamesToArchive.value.push(file.actualName);
      }
      if (!isSelected && index != -1) {
        fileNamesToArchive.value.splice(index, 1);
      }
    };
    const handleProcessSelectionChange = (
      selectedFiles: Array<StorageObject>
    ) => {
      fileNamesToProcess.value = selectedFiles.map(
        (file: StorageObject) => file.actualName
      );
    };

    return {
      close,
      confirm,
      fileNamesToProcess,
      handleArchiveSelectionChange,
      handleProcessSelectionChange,
      props
    };
  }
};
</script>
