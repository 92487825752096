<template>
  <div class="d-block">
    <div class="d-flex">
      <h2 class="main-title">{{ mainTitle }}</h2>
      <div v-if="underConstruction" class="ml-auto">
        <under-construction-tag />
      </div>
    </div>
    <slot name="tabs" />
    <slot name="subheader" />
  </div>
</template>
<script lang="ts">
import UnderConstructionTag from '@/components/misc/UnderConstructionTag.vue';

export default {
  name: 'PageHeader',
  components: {
    UnderConstructionTag
  },
  props: {
    mainTitle: { type: String, default: '', required: true },
    underConstruction: { type: Boolean, default: false }
  }
};
</script>
<style lang="scss" scoped></style>
