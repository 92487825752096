<template>
  <el-row class="d-flex">
    <el-button
      class="ml-auto"
      type="primary"
      @click="openNewConfigurationModal"
    >
      <h4>{{ $t('new') }}</h4>
    </el-button>
    <el-button class="ml-2" type="primary" @click="saveConfiguration">
      <h4>{{ $t('save') }}</h4>
    </el-button>
  </el-row>
  <el-row
    v-for="(item, index) in connectorsConfiguration"
    :key="index"
    class="mt-1"
  >
    <connector-configuration-card
      :connector-configuration="item"
      @update="(configuration: string) => updateEntry(index, configuration)"
      @delete="deleteEntry(index)"
    />
  </el-row>
  <new-connector-configuration-modal
    v-model="showNewConfigurationModal"
    :available-connectors="availableConnectors"
    @confirm="addEntry"
    @close="showNewConfigurationModal = false"
  />
</template>
<script lang="ts">
import { ElButton, ElRow } from 'element-plus';
import { computed, ComputedRef, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import ConnectorConfigurationCard from './components/ConnectorConfigurationCard.vue';
import NewConnectorConfigurationModal from './components/NewConnectorConfigurationModal.vue';
import {
  ConnectorConfigurationDto,
  IConnector
} from '@etp/etp-connectors-client';
import { useStore } from 'vuex';

export default {
  name: 'EngagementConnectors',
  components: {
    ElButton,
    ElRow,
    ConnectorConfigurationCard,
    NewConnectorConfigurationModal
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    const showNewConfigurationModal = ref(false);
    const availableConnectors: ComputedRef<Array<IConnector>> = computed(
      () => store.getters['engagements/getAvailableConnectors']
    );
    const connectorsConfiguration = ref(new Array<ConnectorConfigurationDto>());

    const addEntry = async (entry: ConnectorConfigurationDto) => {
      connectorsConfiguration.value.push(entry);
      showNewConfigurationModal.value = false;
    };

    const deleteEntry = async (index: number) => {
      connectorsConfiguration.value.splice(index, 1);
    };

    const updateEntry = async (index: number, configuration: string) => {
      connectorsConfiguration.value[index].configuration = configuration;
    };

    const openNewConfigurationModal = async () => {
      let engagementId = router.currentRoute.value.params
        .engagementId as string;
      await store.dispatch(
        'engagements/fetchAvailableConnectors',
        engagementId
      );
      showNewConfigurationModal.value = true;
    };

    const saveConfiguration = async () => {
      let engagementId = router.currentRoute.value.params
        .engagementId as string;
      await store.dispatch(
        'engagements/createOrUpdateConnectorsConfiguration',
        {
          engagementId: engagementId,
          configuration: connectorsConfiguration.value
        }
      );
      await updateLocalVars();
    };

    const updateLocalVars = async () => {
      let engagementId = router.currentRoute.value.params
        .engagementId as string;
      await store.dispatch(
        'engagements/fetchConnectorsConfiguration',
        engagementId
      );
      connectorsConfiguration.value = [
        ...store.getters['engagements/getConnectorsConfiguration']
      ];
    };

    onMounted(async () => {
      await updateLocalVars();
    });

    return {
      addEntry,
      availableConnectors,
      connectorsConfiguration,
      deleteEntry,
      saveConfiguration,
      openNewConfigurationModal,
      showNewConfigurationModal,
      updateEntry
    };
  }
};
</script>
<style lang="scss" scoped></style>
