import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "subtitle-2 mt-3" }
const _hoisted_2 = { class: "d-flex mt-auto" }
const _hoisted_3 = {
  key: 0,
  class: "mt-auto"
}
const _hoisted_4 = { key: 1 }
const _hoisted_5 = {
  key: 0,
  class: "empty-table"
}
const _hoisted_6 = { class: "body-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_header = _resolveComponent("page-header")!
  const _component_base_toggle_button = _resolveComponent("base-toggle-button")!
  const _component_base_upload_button = _resolveComponent("base-upload-button")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_dashboard_table = _resolveComponent("dashboard-table")!
  const _component_base_table = _resolveComponent("base-table")!
  const _component_el_col = _resolveComponent("el-col")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_page_header, {
      "main-title": _ctx.$t('dashboards')
    }, {
      subheader: _withCtx(() => [
        _createElementVNode("h4", _hoisted_1, _toDisplayString(_ctx.$t('dashboardsDesc')), 1)
      ]),
      _: 1
    }, 8, ["main-title"]),
    _createVNode(_component_el_row, {
      gutter: 24,
      class: "mt-6"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, { span: 17 }, {
          default: _withCtx(() => [
            _createVNode(_component_el_row, null, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_base_toggle_button, {
                    modelValue: $setup.powerBiIsActive,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.powerBiIsActive) = $event)),
                    "inactive-text": _ctx.$t('powerBi'),
                    loading: $setup.powerBiIsPausingResuming,
                    "indicator-text": _ctx.$t('togglingPowerBI')
                  }, null, 8, ["modelValue", "inactive-text", "loading", "indicator-text"]),
                  ($setup.showAnalysisServicesPages)
                    ? (_openBlock(), _createBlock(_component_base_toggle_button, {
                        key: 0,
                        modelValue: $setup.analysisServicesIsActive,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.analysisServicesIsActive) = $event)),
                        class: "ml-3",
                        "inactive-text": _ctx.$t('analysisServices'),
                        loading: $setup.analysisServicesIsPausingResuming,
                        "indicator-text": _ctx.$t('togglingAnalysisServices')
                      }, null, 8, ["modelValue", "inactive-text", "loading", "indicator-text"]))
                    : _createCommentVNode("", true)
                ]),
                ($setup.userCanWritePowerBiDashboards)
                  ? (_openBlock(), _createBlock(_component_base_upload_button, {
                      key: 0,
                      class: "ml-auto",
                      accept: ".pbix",
                      onUpload: $setup.uploadDashboard
                    }, null, 8, ["onUpload"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            _createVNode(_component_el_row, { class: "mt-4 mb-1" }, {
              default: _withCtx(() => [
                ($setup.powerBiIsActive && $setup.loadingDashboards)
                  ? (_openBlock(), _createElementBlock("h4", _hoisted_3, _toDisplayString(_ctx.$t('loading')), 1))
                  : (_openBlock(), _createElementBlock("h4", _hoisted_4, _toDisplayString($setup.dashboards.length) + " · " + _toDisplayString(_ctx.$t('dashboards')), 1))
              ]),
              _: 1
            }),
            _createVNode(_component_el_row, { class: "mt-1 d-block" }, {
              default: _withCtx(() => [
                (!$setup.powerBiIsActive)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createElementVNode("h4", _hoisted_6, _toDisplayString($setup.dashboards.length + ' ' + _ctx.$t('dashboardsToDisplay')) + " " + _toDisplayString($setup.dashboards.length ? _ctx.$t('viewDashboards') : _ctx.$t('noDashboards')), 1)
                    ]))
                  : (_openBlock(), _createBlock(_component_base_table, {
                      key: 1,
                      "show-loading-table": $setup.powerBiIsActive && $setup.loadingDashboards,
                      "show-no-content-table": $setup.powerBiIsActive && !$setup.dashboards.length,
                      "error-message": _ctx.$t('noDashboards'),
                      columns: 3
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_dashboard_table, {
                          "table-data": $setup.dashboards,
                          onOpenDashboard: $setup.openDashboard,
                          onOpenDashboardNewtab: $setup.openDashboardNewTab,
                          onConfirm: $setup.deleteDashboard
                        }, null, 8, ["table-data", "onOpenDashboard", "onOpenDashboardNewtab", "onConfirm"])
                      ]),
                      _: 1
                    }, 8, ["show-loading-table", "show-no-content-table", "error-message"]))
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}