import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_base_modal = _resolveComponent("base-modal")!

  return (_openBlock(), _createBlock(_component_base_modal, {
    "modal-title": _ctx.$t('addNewBranch'),
    "confirm-text": _ctx.$t('save'),
    "dialog-visible": $props.showModal,
    width: "400px",
    onConfirm: $setup.confirm,
    onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close')))
  }, {
    content: _withCtx(() => [
      _createVNode(_component_el_form, {
        ref: "formRef",
        model: $setup.form,
        rules: $setup.formRules
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, {
            prop: "parentBranch",
            label: _ctx.$t('parentBranch')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_select, {
                modelValue: $setup.form.parentBranch,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.form.parentBranch) = $event)),
                "value-key": "name",
                size: "small",
                placeholder: _ctx.$t('empty')
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.branches, (item) => {
                    return (_openBlock(), _createBlock(_component_el_option, {
                      key: item.name ?? '',
                      label: item.name ?? '',
                      value: item
                    }, null, 8, ["label", "value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modelValue", "placeholder"])
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_form_item, {
            prop: "branchName",
            label: _ctx.$t('branchName') + ':'
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: $setup.form.branchName,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.form.branchName) = $event)),
                size: "small"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["label"])
        ]),
        _: 1
      }, 8, ["model", "rules"])
    ]),
    _: 1
  }, 8, ["modal-title", "confirm-text", "dialog-visible", "onConfirm"]))
}