<template>
  <page-header :main-title="$t('gainOverview')">
    <template #tabs>
      <div class="d-flex mt-5">
        <router-link v-slot="{ isActive }" :to="{ name: 'GainBatches' }">
          <base-tab :active="isActive">
            <h4>{{ $t('batches') }}</h4>
          </base-tab>
        </router-link>
        <router-link
          v-slot="{ isActive }"
          :to="{ name: 'GainRequirementsStatus' }"
        >
          <base-tab :active="isActive">
            <h4>{{ $t('requirementsStatus') }}</h4>
          </base-tab>
        </router-link>
        <router-link v-slot="{ isActive }" :to="{ name: 'GainPromotions' }">
          <base-tab :active="isActive">
            <h4>{{ $t('promotions') }}</h4>
          </base-tab>
        </router-link>
        <div class="horizontal-line mt-2">
          <hr />
        </div>
      </div>
    </template>
  </page-header>
  <div class="mt-6">
    <router-view />
  </div>
</template>
<script lang="ts">
import PageHeader from '@/components/misc/PageHeader.vue';
import BaseTab from '@/components/tabs/BaseTab.vue';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
export default {
  name: 'GainOverview',
  components: { PageHeader, BaseTab },
  setup() {
    const router = useRouter();
    let routerName = ref(router.currentRoute.value.name);
    return { routerName };
  }
};
</script>
<style scoped lang="scss"></style>
