<template>
  <el-row>
    <el-col :span="17">
      <page-header :main-title="$t('overview')">
        <template #subheader>
          <div class="subtitle-2 mt-3">
            {{
              $t('welcomeBack') +
              ' ' +
              currentUser.displayName +
              '!' +
              ' ' +
              $t('yourOverview')
            }}
          </div>
        </template>
      </page-header>
    </el-col>
  </el-row>
  <el-row :gutter="24" class="mt-6">
    <el-col :span="5" class="mr-7">
      <el-row :gutter="4">
        <el-col
          :span="24"
          class="bs-1 glass-card overview-card-whereyouleftoff"
        >
          <panel-header :main-title="$t('whereYouLeftOff')">
            <template #avatar>
              <img
                src="@/assets/images/icons/new/where_you_left_off.svg"
                height="16"
                width="16"
              />
            </template>
          </panel-header>
          <div v-if="whereYouLeftOff">
            <h4>{{ whereYouLeftOff.displayPath }}</h4>
            <a :href="whereYouLeftOff.fullPath">
              <el-button type="primary" class="mt-3">
                <h4>{{ $t('continue') }}</h4>
              </el-button>
            </a>
          </div>
          <div v-else>
            <h4>{{ $t('thereIsNoActivityYet') }}</h4>
          </div>
        </el-col>
        <el-col
          v-if="
            currentEngagement &&
            showImportStoragePages &&
            showPipelinePages &&
            !isGainEngagement
          "
          :span="24"
          class="bs-1 mt-4 glass-card overview-card-quickactions"
        >
          <panel-header :main-title="$t('quickActions')">
            <template #avatar>
              <img
                src="@/assets/images/icons/new/quick_action.svg"
                height="16"
                width="16"
              />
            </template>
          </panel-header>
          <div>
            <quick-action-panel />
          </div>
        </el-col>
      </el-row>
    </el-col>
    <el-col
      v-if="currentEngagement && showPipelinePages"
      :span="5"
      class="mr-7"
    >
      <div class="bs-1 glass-card overview-card-pipelineruns">
        <panel-header :main-title="$t('pipelineRuns')" />
        <last-pipeline-runs-panel :pipeline-runs="lastPipelineRunsOverview" />
      </div>
    </el-col>
    <el-col v-if="currentEngagement" :span="5">
      <div
        v-if="showDashboardPages"
        class="bs-1 mb-4 glass-card overview-card-dashboards"
      >
        <panel-header :main-title="$t('dashboards')" />
        <dashboards-panel />
      </div>
    </el-col>
  </el-row>
</template>
<script lang="ts">
import { computed, ComputedRef, onMounted, ref } from 'vue';
import { useStore } from 'vuex';

import { ElRow, ElCol, ElButton } from 'element-plus';

import PageHeader from '@/components/misc/PageHeader.vue';
import PanelHeader from '@/views/home/overview/components/PanelHeader.vue';
import QuickActionPanel from '@/views/home/overview/components/QuickActionPanel.vue';
import LastPipelineRunsPanel from '@/views/home/overview/components/LastPipelineRunsPanel.vue';
import DashboardsPanel from '@/views/home/overview/components/DashboardsPanel.vue';
import { EngagementModel } from '@etp/etp-engagements-client/axios';
import { PipelineRunDto } from '@etp/etp-pipelines-client/axios';

export default {
  name: 'OverviewPage',
  components: {
    PageHeader,
    PanelHeader,
    ElRow,
    ElCol,
    ElButton,
    QuickActionPanel,
    LastPipelineRunsPanel,
    DashboardsPanel
  },
  setup() {
    const store = useStore();
    const currentUser = computed(() => store.getters['base/getCurrentUser']);

    const whereYouLeftOff = computed((): any => {
      let sanitizeUrl = require('@braintree/sanitize-url').sanitizeUrl;
      const fullPath = sanitizeUrl(localStorage.getItem('whereYouLeftOff'));
      if (!fullPath) return null;
      const url = new URL(fullPath);

      return {
        fullPath: fullPath,
        displayPath: url.pathname
      };
    });

    let currentEngagement: ComputedRef<EngagementModel> = computed(
      () => store.getters['home/getCurrentEngagement']
    );

    let lastPipelineRunsOverview: ComputedRef<Array<PipelineRunDto>> = computed(
      () => store.getters['overview/getLastPipelineRunsOverview']
    );

    let isGainEngagement: ComputedRef<boolean> = computed(
      () => store.getters['home/isGainEngagement']
    );
    let showDashboardPages: ComputedRef<boolean> = computed(
      () => store.getters['home/showDashboardPages']
    );
    let showImportStoragePages: ComputedRef<boolean> = computed(
      () => store.getters['home/showImportStoragePages']
    );
    let showNodeModelApiPages: ComputedRef<boolean> = computed(
      () => store.getters['home/showNodeModelApiPages']
    );
    let showPipelinePages: ComputedRef<boolean> = computed(
      () => store.getters['home/showPipelinePages']
    );
    let showTaskSchedulerPages: ComputedRef<boolean> = computed(
      () => store.getters['home/showTaskSchedulerPages']
    );
    let userCanReadEngagementPipelines: ComputedRef<boolean> = computed(
      () => store.getters['userPermissions/canReadEngagementPipelines']
    );

    let showFeedbackModal = ref(false);

    onMounted(async () => {
      if (showPipelinePages.value && userCanReadEngagementPipelines.value) {
        await store.dispatch('overview/fetchLastPipelineRunsOverview');
      }
    });

    return {
      currentUser,
      currentEngagement,
      isGainEngagement,
      lastPipelineRunsOverview,
      whereYouLeftOff,
      showFeedbackModal,
      showDashboardPages,
      showImportStoragePages,
      showNodeModelApiPages,
      showPipelinePages,
      showTaskSchedulerPages
    };
  }
};
</script>
<style lang="scss" scoped>
.sub-header {
  color: $etp-deloitte-blue-7;
}

.glass-card {
  border-radius: 1.5em;
  padding: 2em !important;
}
</style>
