<template>
  <base-modal
    :modal-title="$t('addRole')"
    :dialog-visible="modelValue"
    :confirm-text="$t('save')"
    width="600px"
    @close="$emit('update:model-value', false)"
    @confirm="confirm()"
  >
    <template #content>
      <el-form
        ref="formRef"
        :model="form"
        :rules="formRules"
        label-position="top"
      >
        <el-form-item :label="$t('role')" prop="roleNames">
          <el-select
            v-model="form.roleNames"
            multiple
            size="small"
            @change="handleRolesChange"
          >
            <el-option
              v-for="role in roles"
              :key="role.name"
              :label="role.name"
              :value="role.name"
              :disabled="
                !!selectedRolesScopeMask &&
                role.scopeMask != selectedRolesScopeMask
              "
            />
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="showEngagementSelector"
          :label="$t('engagement')"
          prop="engagementId"
        >
          <el-select
            v-model="form.engagementId"
            size="small"
            @change="handleEngagementChange"
          >
            <el-option
              v-for="engagement in engagements"
              :key="engagement.id"
              :label="engagement.name"
              :value="engagement.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="showTenantSelector"
          :label="$t('tenant')"
          prop="tenantId"
        >
          <el-select
            v-model="form.tenantId"
            size="small"
            @change="handleTenantChange"
          >
            <el-option
              v-for="tenant in tenants"
              :key="tenant.id"
              :label="tenant.name"
              :value="tenant.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('scope')" prop="scope">
          <el-input
            v-model="form.scope"
            size="small"
            :disabled="disableScopeField"
          />
        </el-form-item>
      </el-form>
    </template>
  </base-modal>
</template>
<script lang="ts">
import BaseModal from '@/components/modals/BaseModal.vue';
import {
  ElForm,
  ElFormItem,
  ElInput,
  ElMessage,
  ElOption,
  ElSelect
} from 'element-plus';

import { reactive, ref } from 'vue';
import i18n from '@/i18n';
import {
  RoleAssignmentModel,
  RoleModel
} from '@etp/etp-authorization-client/axios';
import { EngagementModel } from '@etp/etp-engagements-client/axios';
import { TenantModel } from '@etp/etp-infra-client';
import {
  GetScopeForEngagement,
  GetScopeForTenant,
  IsEngagementScope,
  IsTenantScope
} from '@/utils/scopeHelper';

export default {
  name: 'AddRoleModal',
  components: {
    BaseModal,
    ElForm,
    ElFormItem,
    ElInput,
    ElSelect,
    ElOption
  },
  props: {
    modelValue: { type: Boolean, default: false },
    engagements: {
      type: Array as () => Array<EngagementModel>,
      default: () => new Array<EngagementModel>()
    },
    roles: {
      type: Array as () => Array<RoleModel>,
      default: () => new Array<RoleModel>()
    },
    tenants: {
      type: Array as () => Array<TenantModel>,
      default: () => new Array<TenantModel>()
    },
    feedbackText: { type: String, default: i18n.global.t('saved') }
  },
  emits: ['close', 'confirm', 'update:model-value'],
  setup(props, { emit }) {
    const showEngagementSelector = ref(false);
    const showTenantSelector = ref(false);
    const disableScopeField = ref(false);
    const selectedRolesScopeMask = ref('');

    const form = reactive({
      roleNames: new Array<string>(),
      engagementId: '',
      tenantId: '',
      scope: ''
    });

    const formRules = reactive({
      roleNames: {
        required: true,
        message: i18n.global.t('thisFieldIsRequired'),
        trigger: ['change', 'blur']
      },
      scope: {
        required: true,
        message: i18n.global.t('thisFieldIsRequired'),
        trigger: ['change', 'blur']
      }
    });
    const formRef = ref<HTMLFormElement>();

    const handleRolesChange = (selectedRoleNames: Array<string>) => {
      selectedRolesScopeMask.value =
        props.roles.find(role => role.name === selectedRoleNames[0])
          ?.scopeMask ?? '';
      form.engagementId = '';
      form.tenantId = '';
      form.scope = '';
      if (
        IsEngagementScope(selectedRolesScopeMask.value) &&
        selectedRolesScopeMask.value.split('/').length === 3
      ) {
        showEngagementSelector.value = true;
        showTenantSelector.value = false;
        disableScopeField.value = true;
      } else if (
        IsTenantScope(selectedRolesScopeMask.value) &&
        selectedRolesScopeMask.value.split('/').length === 3
      ) {
        showEngagementSelector.value = false;
        showTenantSelector.value = true;
        disableScopeField.value = true;
      } else {
        showEngagementSelector.value = false;
        showTenantSelector.value = false;
        disableScopeField.value = false;
        form.scope = selectedRolesScopeMask.value;
      }
    };

    const handleEngagementChange = (newEngagementId: string) => {
      form.scope = GetScopeForEngagement(newEngagementId);
    };

    const handleTenantChange = (newTenantId: string) => {
      form.scope = GetScopeForTenant(newTenantId);
    };

    const confirm = () => {
      formRef.value?.validate((valid: boolean) => {
        if (valid) {
          emit(
            'confirm',
            form.roleNames.map(roleName => {
              return {
                roleName,
                scope: form.scope
              } as RoleAssignmentModel;
            })
          );
          ElMessage({
            showClose: true,
            message: props.feedbackText
          });
        }
      });
    };
    return {
      confirm,
      disableScopeField,
      form,
      formRef,
      formRules,
      handleEngagementChange,
      handleRolesChange,
      handleTenantChange,
      selectedRolesScopeMask,
      showEngagementSelector,
      showTenantSelector
    };
  }
};
</script>
<style scoped lang="scss"></style>
