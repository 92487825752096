<template>
  <base-modal
    :modal-title="$t('addConfiguration')"
    :dialog-visible="modelValue"
    :confirm-text="$t('save')"
    width="400px"
    @close="close"
    @confirm="confirm"
  >
    <template #content>
      <el-form
        ref="formRef"
        :model="form"
        :rules="formRules"
        label-position="top"
      >
        <el-form-item
          :label="$t('newStoragePurposeDescription')"
          prop="storagePurpose"
        >
          <el-input v-model="form.storagePurpose" size="small" />
        </el-form-item>
      </el-form>
    </template>
  </base-modal>
</template>
<script lang="ts">
import BaseModal from '@/components/modals/BaseModal.vue';
import { ElForm, ElFormItem, ElInput } from 'element-plus';
import { reactive, ref } from 'vue';
import i18n from '@/i18n';
import { mustBeAlphaNumericAndLowerCase } from '@/utils/formValidators';

export default {
  name: 'NewStorageConfigurationModal',
  components: {
    BaseModal,
    ElInput,
    ElForm,
    ElFormItem
  },
  props: {
    modelValue: { type: Boolean, default: false },
    existingPurposes: {
      type: Array as () => Array<string>,
      default: () => new Array<string>()
    }
  },
  emits: ['close', 'confirm'],
  setup(props, { emit }) {
    const localMustNotBeInArray = (rule: any, value: string, callback: any) => {
      if (
        props.existingPurposes.some(
          el => el.toLocaleLowerCase() == value.toLocaleLowerCase()
        )
      ) {
        callback(new Error(i18n.global.t('thisPurposeAlreadyExists')));
      } else callback();
    };

    const form = reactive({
      storagePurpose: ''
    });
    const formRef = ref<HTMLFormElement>();
    const formRules = reactive({
      storagePurpose: [
        {
          required: true,
          message: i18n.global.t('thisFieldIsRequired'),
          trigger: 'blur'
        },
        {
          min: 3,
          message: i18n.global
            .t('pleaseEnterNumCharacters')
            .replace('[num]', '3'),
          trigger: 'blur'
        },
        {
          validator: mustBeAlphaNumericAndLowerCase,
          triegger: 'blur'
        },
        {
          validator: localMustNotBeInArray,
          triegger: 'blur'
        }
      ]
    });

    const confirm = () => {
      formRef.value?.validate((valid: boolean) => {
        if (valid) {
          emit('confirm', form.storagePurpose);
        }
      });
    };
    const close = () => {
      emit('close');
    };

    return {
      form,
      formRules,
      formRef,
      confirm,
      close
    };
  }
};
</script>
