import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_code_editor = _resolveComponent("code-editor")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_base_modal = _resolveComponent("base-modal")!

  return (_openBlock(), _createBlock(_component_base_modal, {
    "modal-title": _ctx.$t('configuration'),
    "dialog-visible": $props.modelValue,
    "confirm-text": _ctx.$t('save'),
    width: "400px",
    onClose: $setup.close,
    onConfirm: $setup.confirm
  }, {
    content: _withCtx(() => [
      _createVNode(_component_el_form, {
        ref: "formRef",
        model: $setup.form,
        rules: $setup.formRules,
        "label-position": "top"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, { prop: "configuration" }, {
            default: _withCtx(() => [
              _createVNode(_component_code_editor, {
                modelValue: $setup.form.configuration,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.form.configuration) = $event)),
                font_size: "13px",
                languages: [['JSON', 'JSON']],
                theme: "light"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model", "rules"])
    ]),
    _: 1
  }, 8, ["modal-title", "dialog-visible", "confirm-text", "onClose", "onConfirm"]))
}