<template>
  <base-monitor-tab
    :pipeline-runs="pipelineRuns"
    :sort-params="sortParams"
    :show-adf-button="userCanWriteEngagementPipelines && !isGainEngagement"
    :loading-pipeline-runs="loadingPipelineRuns"
    @update-sort-params="updateSortParams"
    @update-status-filter="updateStatusFilter"
  />
</template>

<script lang="ts">
import { useStore } from 'vuex';
import { computed, ComputedRef, onBeforeUnmount, onMounted } from 'vue';
import BaseMonitorTab from '@/components/tabs/BaseMonitorTab.vue';
import { useRouter } from 'vue-router';
import { spacingWidthHeaderComponents } from '@/utils/variables';
import { SortParams } from '@/services/web-api/dto/sortParams';
import { PipelineStatus } from '@/services/web-api/enums/pipelineStatus';
import { setEtpInterval, destroyEtpInterval } from '@/utils/interval';
import { detectStoreToUse } from '@/utils/storeHelper';

export default {
  name: 'MonitorOverview',
  components: { BaseMonitorTab },
  setup() {
    const store = useStore();
    const router = useRouter();
    const storeName = detectStoreToUse();
    const refreshListPipelineRunKey = `${storeName}-Monitor`;
    const refreshListPipelineRunsInMilliseconds = 5 * 1000;

    const loadingPipelineRuns: ComputedRef<boolean> = computed<boolean>(
      () => store.getters[`${storeName}/loadingListPipelineRuns`]
    );
    const pipelineRuns = computed(
      () => store.getters[`${storeName}/pipelineRuns`]
    );
    const sortParams: ComputedRef<SortParams> = computed(
      () => store.getters[`${storeName}/pipelineRunsSortParams`]
    );

    const listPipelineRuns = async () => {
      await store.dispatch(`${storeName}/listPipelineRuns`);
    };

    const updateSortParams = async (value: SortParams) => {
      store.commit(`${storeName}/SET_PIPELINE_RUNS_SORT_PARAMS`, value);
      await listPipelineRuns();
    };

    const updateStatusFilter = async (value: Array<PipelineStatus>) => {
      store.commit(`${storeName}/SET_PIPELINE_STATUS_FILTER`, value);
      await listPipelineRuns();
    };

    const isGainEngagement: ComputedRef<boolean> = computed(
      () => store.getters['home/isGainEngagement']
    );
    const showPipelinePages: ComputedRef<boolean> = computed(
      () => store.getters['home/showPipelinePages']
    );
    const userCanWriteEngagementPipelines: ComputedRef<boolean> = computed(
      () => store.getters['userPermissions/canWriteEngagementPipelines']
    );

    onMounted(async () => {
      if (!showPipelinePages.value) return router.push({ name: 'Overview' });
      await listPipelineRuns();
      await store.dispatch(
        'userPermissions/checkIfCanWriteEngagementPipelines'
      );
      setEtpInterval(
        refreshListPipelineRunKey,
        listPipelineRuns,
        refreshListPipelineRunsInMilliseconds
      );
    });

    onBeforeUnmount(async () => {
      destroyEtpInterval(refreshListPipelineRunKey);
    });

    return {
      isGainEngagement,
      pipelineRuns,
      spacingWidthHeaderComponents,
      sortParams,
      updateSortParams,
      userCanWriteEngagementPipelines,
      loadingPipelineRuns,
      updateStatusFilter
    };
  }
};
</script>

<style scoped lang="scss"></style>
