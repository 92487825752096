<template>
  <el-row>
    <el-col>
      <page-header :main-title="$t('roles')">
        <template #subheader>
          <h4 class="subtitle-2 mt-3">
            {{ $t('rolesDesc') }}
          </h4>
        </template>
      </page-header>
    </el-col>
  </el-row>
  <base-table-header
    :row-count="allRolesCount"
    :quantity-message="$t('roles')"
    no-filter
    class="mt-3"
  >
    <el-button type="primary" @click="openCreateRoleModal">
      <h4>+ {{ $t('newRole') }}</h4>
    </el-button>
  </base-table-header>
  <el-row>
    <el-col>
      <base-table
        :show-loading-table="loadingRoles"
        :show-no-content-table="!roles.length"
        :error-message="$t('noRoles')"
      >
        <el-table :data="roles">
          <el-table-column :label="$t('name')" prop="name" />
          <el-table-column :label="$t('scopeMask')" prop="scopeMask" />
          <el-table-column :label="$t('description')" prop="description" />
          <el-table-column width="80">
            <template #default="props">
              <el-tooltip placement="top" :content="$t('editRole')">
                <img
                  :src="require(`@/assets/images/icons/new/edit.svg`)"
                  height="14"
                  width="14"
                  class="action-icon"
                  @click="openUpdateRoleModal(props.row)"
                />
              </el-tooltip>
              <el-tooltip
                placement="top"
                class="ml-1"
                :content="$t('deleteRole')"
              >
                <img
                  :src="require(`@/assets/images/icons/new/delete.svg`)"
                  height="14"
                  width="14"
                  class="action-icon"
                  @click="openRemoveRoleModal(props.row)"
                />
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </base-table>
    </el-col>
  </el-row>
  <pagination
    :count="allRolesCount"
    :page="pageNumber"
    :page-size="pageSize"
    @change-page="changePage"
  />
  <create-or-update-role-modal
    v-model="showCreateOrUpdateRoleModal"
    :role="activeRole"
    :permissions="permissions"
    @confirm="createOrUpdateRole"
    @close="showCreateOrUpdateRoleModal = false"
  />
  <base-confirmation-modal
    v-model="showRemoveRoleConfirmationModal"
    :content="$t('confirmRemoveRole')"
    width="600px"
    :feedback-text="$t('roleRemoved')"
    @confirm="removeRole"
    @close="showRemoveRoleConfirmationModal = false"
  />
</template>

<script lang="ts">
import {
  ElButton,
  ElCol,
  ElMessage,
  ElRow,
  ElTable,
  ElTableColumn,
  ElTooltip
} from 'element-plus';

import BaseConfirmationModal from '@/components/modals/BaseConfirmationModal.vue';
import PageHeader from '@/components/misc/PageHeader.vue';
import BaseTable from '@/components/tables/BaseTable.vue';
import BaseTableHeader from '@/components/tables/BaseTableHeader.vue';
import CreateOrUpdateRoleModal from './components/CreateOrUpdateRoleModal.vue';

import { computed, onMounted, ref, ComputedRef } from 'vue';
import { useStore } from 'vuex';
import {
  PermissionModel,
  RoleDetailsModel,
  RoleModel
} from '@etp/etp-authorization-client/axios';
import i18n from '@/i18n';
import Pagination from '@/components/misc/Pagination.vue';

export default {
  name: 'RolesOverview',
  components: {
    BaseConfirmationModal,
    BaseTable,
    BaseTableHeader,
    CreateOrUpdateRoleModal,
    ElButton,
    ElCol,
    ElRow,
    ElTable,
    ElTableColumn,
    ElTooltip,
    PageHeader,
    Pagination
  },
  setup() {
    let store = useStore();
    const showCreateOrUpdateRoleModal = ref(false);
    const showRemoveRoleConfirmationModal = ref(false);
    const roleToRemove = ref({} as RoleModel);

    let roles: ComputedRef<Array<RoleModel>> = computed(
      () => store.getters['roles/getPageRoles']
    );
    let pageNumber: ComputedRef<number> = computed(
      () => store.getters['roles/getRolesPageNumber']
    );
    let pageSize: ComputedRef<number> = computed(
      () => store.getters['roles/getRolesPageSize']
    );
    let allRolesCount: ComputedRef<number> = computed(
      () => store.getters['roles/getRolesCount']
    );
    let loadingRoles: ComputedRef<boolean> = computed(
      () => store.getters['roles/getLoadingRoles']
    );
    let activeRole: ComputedRef<RoleDetailsModel> = computed(
      () => store.getters['roles/getActiveRole']
    );
    let permissions: ComputedRef<Array<PermissionModel>> = computed(
      () => store.getters['permissions/getPermissions']
    );

    let fetchRoles = async () => await store.dispatch('roles/fetchPageRoles');
    let fetchPermissions = async () =>
      await store.dispatch('permissions/fetchAllPermissions');

    const openCreateRoleModal = () => {
      store.commit('roles/SET_ACTIVE_ROLE', {} as RoleDetailsModel);
      showCreateOrUpdateRoleModal.value = true;
    };
    const openUpdateRoleModal = async (role: RoleModel) => {
      await store.dispatch('roles/fetchActiveRole', role.name);
      showCreateOrUpdateRoleModal.value = true;
    };
    const createOrUpdateRole = async (role: RoleDetailsModel) => {
      let roleModel = { ...role } as RoleModel;
      if (activeRole.value.name)
        await store.dispatch('roles/updateRole', roleModel);
      else await store.dispatch('roles/createRole', roleModel);

      let currentPermissions = activeRole.value.permissions ?? [];
      let updatedPermissions = role.permissions ?? [];

      for (let permissionName of updatedPermissions.filter(
        (el: string) => !currentPermissions.includes(el)
      )) {
        await store.dispatch('roles/addPermissionToRole', {
          roleName: activeRole.value.name,
          permissionName: permissionName
        });
      }

      for (let permissionName of currentPermissions.filter(
        (el: string) => !updatedPermissions.includes(el)
      )) {
        await store.dispatch('roles/removePermissionFromRole', {
          roleName: activeRole.value.name,
          permissionName: permissionName
        });
      }

      ElMessage({
        showClose: true,
        message: i18n.global.t('roleInfoSaved')
      });
      showCreateOrUpdateRoleModal.value = false;
      await fetchRoles();
    };

    const openRemoveRoleModal = async (role: RoleModel) => {
      if (!role.name) return;
      roleToRemove.value = role;
      showRemoveRoleConfirmationModal.value = true;
    };
    const removeRole = async () => {
      await store.dispatch('roles/deleteRole', {
        roleName: roleToRemove.value.name
      });
      showRemoveRoleConfirmationModal.value = false;
      await fetchRoles();
    };

    const changePage = async (page: number) => {
      store.commit('roles/SET_ROLES_PAGE', page);
      await fetchRoles();
    };

    onMounted(async () => {
      await fetchRoles();
      await fetchPermissions();
    });

    return {
      activeRole,
      allRolesCount,
      changePage,
      createOrUpdateRole,
      loadingRoles,
      openCreateRoleModal,
      openRemoveRoleModal,
      openUpdateRoleModal,
      pageNumber,
      pageSize,
      permissions,
      removeRole,
      roles,
      showCreateOrUpdateRoleModal,
      showRemoveRoleConfirmationModal
    };
  }
};
</script>

<style scoped lang="scss"></style>
