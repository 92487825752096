import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_base_building_panel = _resolveComponent("base-building-panel")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_base_building_table = _resolveComponent("base-building-table")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createBlock(_component_el_row, { gutter: $setup.spacingWidthHeaderComponents }, {
    default: _withCtx(() => [
      _createVNode(_component_el_col, { span: 5 }, {
        default: _withCtx(() => [
          _createVNode(_component_base_building_panel, {
            selectedBranch: $setup.selectedBranch,
            "onUpdate:selectedBranch": _cache[1] || (_cache[1] = ($event: any) => (($setup.selectedBranch) = $event)),
            branches: $setup.branches,
            "loading-branches": $setup.loadingBranches
          }, {
            item: _withCtx(() => [
              _createVNode(_component_el_form_item, {
                label: _ctx.$t('category')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_select, {
                    modelValue: $setup.selectedCategory,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.selectedCategory) = $event)),
                    disabled: !$setup.selectedBranch.name || $setup.loadingCategories,
                    "value-key": "name",
                    size: "small",
                    placeholder: $setup.loadingCategories ? _ctx.$t('loading') : _ctx.$t('empty')
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.categories, (item) => {
                        return (_openBlock(), _createBlock(_component_el_option, {
                          key: item.name ?? '',
                          label: item.name ?? '',
                          value: item
                        }, null, 8, ["label", "value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue", "disabled", "placeholder"])
                ]),
                _: 1
              }, 8, ["label"])
            ]),
            _: 1
          }, 8, ["selectedBranch", "branches", "loading-branches"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_col, { span: 19 }, {
        default: _withCtx(() => [
          _createVNode(_component_base_building_table, {
            modelValue: $setup.nodes,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.nodes) = $event)),
            "show-build-button": $setup.userCanStartEngagementPipelines,
            "loading-nodes": $setup.loadingNodes,
            onStartPipelineRun: $setup.startPipelineRun
          }, null, 8, ["modelValue", "show-build-button", "loading-nodes", "onStartPipelineRun"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["gutter"]))
}