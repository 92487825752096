import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0f368a30"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "base-container" }
const _hoisted_2 = {
  key: 0,
  class: "navbar-container"
}
const _hoisted_3 = { class: "homeoverview-container" }
const _hoisted_4 = {
  key: 1,
  class: "platform-footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_circles = _resolveComponent("base-circles")!
  const _component_the_user_menu = _resolveComponent("the-user-menu")!
  const _component_the_navbar = _resolveComponent("the-navbar")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_base_circles),
    (!$setup.fullScreen)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_the_navbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_the_user_menu, {
                "current-user": $setup.currentUser,
                "profile-picture": $setup.profilePicture,
                onSignout: $setup.signOut
              }, null, 8, ["current-user", "profile-picture", "onSignout"])
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_router_view)
    ]),
    (!$setup.fullScreen)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("span", {
            class: "footer-content body-2",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({ name: 'Privacy' })))
          }, _toDisplayString(_ctx.$t('privacy')), 1),
          _createElementVNode("span", {
            class: "footer-content body-2",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push({ name: 'TermsOfUse' })))
          }, _toDisplayString(_ctx.$t('termsOfUse')), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}