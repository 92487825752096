import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_table_placeholder_loading = _resolveComponent("table-placeholder-loading")!
  const _component_table_placeholder = _resolveComponent("table-placeholder")!

  return ($props.showLoadingTable)
    ? (_openBlock(), _createBlock(_component_table_placeholder_loading, {
        key: 0,
        columns: $props.columns
      }, null, 8, ["columns"]))
    : ($props.showNoContentTable)
      ? (_openBlock(), _createBlock(_component_table_placeholder, {
          key: 1,
          "error-message": $props.errorMessage
        }, null, 8, ["error-message"]))
      : _renderSlot(_ctx.$slots, "default", { key: 2 })
}