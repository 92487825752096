<template>
  <el-table
    :data="tableData"
    :empty-text="$t('noTasks')"
    row-key="id"
    :expand-row-keys="expandedRowKeys"
    @expand-change="expandChange"
  >
    <el-table-column type="expand">
      <template #default="scope">
        <div class="el-form-item__label align-left">
          <div class="el-form-item__label align-left">
            {{ $t('parameters') }}
          </div>
          <code-editor
            :value="JSON.stringify(scope.row.params)"
            font_size="13px"
            :read_only="true"
            :languages="[['JSON', 'JSON']]"
            theme="light"
            min_height="300px"
            min_width="100%"
          ></code-editor>
        </div>
      </template>
    </el-table-column>
    <el-table-column :label="$t('orchestrationType')">
      <template #default="scope">
        <h4>{{ scope.row.type }}</h4>
      </template>
    </el-table-column>
    <el-table-column :label="$t('plannedExecutionDate')">
      <template #default="scope">
        <h4>{{ formatDate(scope.row.plannedExecutionDate) }}</h4>
      </template>
    </el-table-column>
    <el-table-column :label="$t('orchestrationStatus')">
      <template #default="scope">
        <div
          class="pipeline-status-indicator"
          :class="getStatusColor(scope.row.state)"
        ></div>
        <h4 class="d-inline-block ml-1">
          {{ scope.row.state }}
        </h4>
      </template>
    </el-table-column>
    <el-table-column :label="$t('actions')" width="100">
      <template #default="scope">
        <div class="actions">
          <el-tooltip
            v-if="checkAction('approve', scope.row.state)"
            :content="$t('approve')"
          >
            <img
              src="@/assets/images/icons/new/approve.svg"
              class="action-icon mr-1 icon-size-7"
              @click="openApproveTaskModal(scope.row.id)"
            />
          </el-tooltip>
          <el-tooltip
            v-if="checkAction('disapprove', scope.row.state)"
            :content="$t('reject')"
          >
            <img
              src="@/assets/images/icons/new/delete.svg"
              class="action-icon mr-1 icon-size-7"
              @click="openRejectTaskModal(scope.row.id)"
            />
          </el-tooltip>
          <el-tooltip
            v-if="checkAction('edit', scope.row.state)"
            :content="$t('edit')"
          >
            <img
              src="@/assets/images/icons/new/edit.svg"
              class="action-icon mr-1 icon-size-7"
              @click.stop="$emit('edit-task', scope.row)"
            />
          </el-tooltip>
        </div>
      </template>
    </el-table-column>
  </el-table>
  <base-confirmation-modal
    v-model="showRejectTaskModal"
    :content="$t('confirmDisapproveTask')"
    @confirm="
      $emit('disapprove-task', taskToApproveOrReject);
      showRejectTaskModal = false;
    "
    @close="showRejectTaskModal = false"
  />
  <base-confirmation-modal
    v-model="showApproveTaskModal"
    :content="$t('confirmApproveTask')"
    @confirm="
      $emit('approve-task', taskToApproveOrReject);
      showApproveTaskModal = false;
    "
    @close="showApproveTaskModal = false"
  />
</template>

<script lang="ts">
import { TaskState } from '@/views/home/data-journey/import/store/entities/task';
import CodeEditor from 'simple-code-editor/CodeEditor.vue';
import { ElTable, ElTableColumn, ElTooltip } from 'element-plus';
import moment from 'moment';
import { ref } from 'vue';
import BaseConfirmationModal from '@/components/modals/BaseConfirmationModal.vue';
import { ScheduledTaskDto } from '@etp/etp-taskscheduler-client/axios';

export default {
  name: 'ImportTasksTable',
  components: {
    ElTable,
    ElTableColumn,
    ElTooltip,
    BaseConfirmationModal,
    CodeEditor
  },
  props: {
    tableData: {
      type: Array as () => Array<ScheduledTaskDto>,
      required: true
    }
  },
  emits: ['approve-task', 'disapprove-task', 'edit-task'],
  setup() {
    const expandedRowKeys = ref(new Array<number>());
    const taskToApproveOrReject = ref();

    const showApproveTaskModal = ref(false);
    const showRejectTaskModal = ref(false);

    const openRejectTaskModal = (taskId: number) => {
      taskToApproveOrReject.value = taskId;
      showRejectTaskModal.value = true;
    };

    const openApproveTaskModal = (taskId: number) => {
      taskToApproveOrReject.value = taskId;
      showApproveTaskModal.value = true;
    };

    const getStatusColor = (status: TaskState): string => {
      switch (status) {
        case TaskState.Successful:
        case TaskState.Approved:
        case TaskState.Created:
          return TaskState.Successful;
        case TaskState.Pending:
        case TaskState.Running:
          return TaskState.Running;
        case TaskState.Failed:
        case TaskState.TimedOut:
          return TaskState.Failed;
        default:
          return 'unknown';
      }
    };

    const expandChange = (row, expandedRows) => {
      if (expandedRows.length == 0) expandedRowKeys.value = [];
      else expandedRowKeys.value = [row.id];
    };

    const checkAction = (action: string, taskState: TaskState) => {
      switch (action) {
        case 'approve':
          return taskState === TaskState.Created;
        case 'disapprove':
          return (
            taskState === TaskState.Approved || taskState === TaskState.Pending
          );
        case 'edit':
          return (
            taskState === TaskState.Created ||
            taskState === TaskState.Approved ||
            taskState === TaskState.Pending
          );
        default:
          return false;
      }
    };

    const formatDate = (date: Date) => {
      return moment(date).format('DD-MM-YYYY, HH:mm:ss');
    };

    return {
      getStatusColor,
      formatDate,
      checkAction,
      expandChange,
      expandedRowKeys,
      taskToApproveOrReject,
      showApproveTaskModal,
      showRejectTaskModal,
      openRejectTaskModal,
      openApproveTaskModal
    };
  }
};
</script>

<style scoped lang="scss">
.actions {
  display: flex;
}

.align-left {
  text-align: left;
}
.error {
  color: $etp-warning-red;
  font-size: 12px;
  line-height: 1;
  padding-top: 4px;
}
</style>
