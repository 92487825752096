import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "subtitle-2 mt-3" }
const _hoisted_2 = { class: "ml-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_header = _resolveComponent("page-header")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_configuration_card = _resolveComponent("configuration-card")!
  const _component_base_table = _resolveComponent("base-table")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_publish_pipelines_modal = _resolveComponent("publish-pipelines-modal")!
  const _component_stop_cost_saving_modal = _resolveComponent("stop-cost-saving-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_page_header, {
          "main-title": _ctx.$t('engagementConfiguration')
        }, {
          subheader: _withCtx(() => [
            _createElementVNode("h4", _hoisted_1, _toDisplayString(_ctx.$t('engagementConfigurationDesc') + ' ' + $setup.currentEngagement.id), 1)
          ]),
          _: 1
        }, 8, ["main-title"])
      ]),
      _: 1
    }),
    (!$setup.isGainEngagement)
      ? (_openBlock(), _createBlock(_component_el_row, {
          key: 0,
          class: "d-flex align-items-center"
        }, {
          default: _withCtx(() => [
            _createElementVNode("h4", _hoisted_2, _toDisplayString(_ctx.$t('engagementActions') + ' :'), 1),
            _createVNode(_component_el_button, {
              type: "primary",
              class: "ml-2",
              onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.showStopCostSavingModal = true))
            }, {
              default: _withCtx(() => [
                _createElementVNode("h4", null, _toDisplayString(_ctx.$t('stopCostSaving')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_button, {
              type: "primary",
              class: "ml-2",
              onClick: $setup.openPublishPipelinesModal
            }, {
              default: _withCtx(() => [
                _createElementVNode("h4", null, _toDisplayString(_ctx.$t('publishPipelines')), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_el_row, {
      gutter: $setup.spacingWidthHeaderComponents,
      class: "mt-6"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, { span: 17 }, {
          default: _withCtx(() => [
            _createVNode(_component_el_row, { class: "mt-2 mb-1" }, {
              default: _withCtx(() => [
                _createElementVNode("h4", null, _toDisplayString($setup.engagementResources.length) + " · " + _toDisplayString(_ctx.$t('results')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_row, { class: "d-block" }, {
              default: _withCtx(() => [
                _createVNode(_component_base_table, {
                  "show-no-content-table": !$setup.engagementResources.length,
                  "error-message": _ctx.$t('noImport')
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_row, { class: "mt-1" }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.engagementResources, (resource, index) => {
                          return (_openBlock(), _createBlock(_component_configuration_card, {
                            key: index,
                            resource: resource,
                            tenant: $setup.tenant,
                            class: "mb-1"
                          }, null, 8, ["resource", "tenant"]))
                        }), 128))
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["show-no-content-table", "error-message"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["gutter"]),
    _createVNode(_component_publish_pipelines_modal, {
      "show-modal": $setup.showPublishPipelineModal,
      "collaboration-branches": $setup.collaborationBranches,
      onClose: _cache[1] || (_cache[1] = ($event: any) => ($setup.showPublishPipelineModal = false))
    }, null, 8, ["show-modal", "collaboration-branches"]),
    _createVNode(_component_stop_cost_saving_modal, {
      modelValue: $setup.showStopCostSavingModal,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.showStopCostSavingModal) = $event)),
      onConfirm: $setup.stopCostSaving
    }, null, 8, ["modelValue", "onConfirm"])
  ], 64))
}