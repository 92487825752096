<template>
  <el-row class="d-flex">
    <h4 class="mt-auto">
      {{ tenantUsers.length }} &#183;
      {{ $t('users') }}
    </h4>
    <el-button
      class="ml-auto"
      type="primary"
      @click="showAddRoleAssignmentModal = true"
    >
      <h4>{{ $t('addUser') }}</h4>
    </el-button>
  </el-row>
  <el-table :data="tenantUsers">
    <el-table-column prop="name" :label="$t('name')" width="200" />
    <el-table-column>
      <template #header>
        <el-row>
          <el-col :span="6">
            {{ $t('role') }}
          </el-col>
          <el-col :span="17">
            {{ $t('scope') }}
          </el-col>
        </el-row>
      </template>
      <template #default="scope">
        <el-row
          v-for="roleAssignment in scope.row.roleAssignments.sort(compareRoleAssignments) as Array<RoleAssignmentModel>"
          :key="roleAssignment.roleName"
        >
          <el-col :span="6">
            <span class="body-1">
              {{ roleAssignment.roleName }}
            </span>
          </el-col>
          <el-col :span="17">
            <span class="body-1">
              {{ roleAssignment.scope }}
            </span>
          </el-col>
          <el-col :span="1">
            <img
              :src="require(`@/assets/images/icons/new/delete.svg`)"
              height="12"
              width="12"
              class="action-icon"
              @click="openRemoveRoleAssignmentModal(roleAssignment)"
            />
          </el-col>
        </el-row>
      </template>
    </el-table-column>
  </el-table>
  <add-user-role-assignment-modal
    :modal-visible="showAddRoleAssignmentModal"
    :users="allUsers"
    :roles="allRoles"
    :scope="GetScopeForTenant(tenantId)"
    @add-roles="addRoleAssignments"
    @close="showAddRoleAssignmentModal = false"
    @create-user="createUser"
  />
  <base-confirmation-modal
    v-model="showRemoveRoleAssignmentModal"
    :content="$t('confirmRemoveUserFromTenant')"
    :feedback-text="$t('tenantUserDeleted')"
    @confirm="removeRoleAssignment"
    @close="showRemoveRoleAssignmentModal = false"
  />
</template>
<script lang="ts">
import {
  ElRow,
  ElCol,
  ElTable,
  ElTableColumn,
  ElButton,
  ElMessage
} from 'element-plus';

import { computed, ComputedRef, onMounted, ref } from 'vue';
import AddUserRoleAssignmentModal from '@/components/modals/AddUserRoleAssignmentModal.vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { GetScopeForTenant, GetScopeMaskForTenant } from '@/utils/scopeHelper';
import BaseConfirmationModal from '@/components/modals/BaseConfirmationModal.vue';
import {
  CreateOrUpdateUserModel,
  RoleAssignmentModel,
  RoleModel,
  UserDetailsModel,
  UserModel,
  UserRoleAssignmentModel
} from '@etp/etp-authorization-client/axios';
import i18n from '@/i18n';
import { compareRoleAssignments } from '@/utils/roleAssignmentFunctions';

export default {
  name: 'TenantUsers',
  components: {
    BaseConfirmationModal,
    AddUserRoleAssignmentModal,
    ElButton,
    ElRow,
    ElCol,
    ElTable,
    ElTableColumn
  },
  setup() {
    let tenantId = ref('');

    const router = useRouter();
    const store = useStore();

    const tenantUsers: ComputedRef<Array<UserDetailsModel>> = computed(
      () => store.getters['tenants/getUsers']
    );
    const allUsers: ComputedRef<Array<UserModel>> = computed(
      () => store.getters['users/getUsers']
    );
    const allRoles: ComputedRef<Array<RoleModel>> = computed(
      () => store.getters['roles/getRoles']
    );

    const fetchTenantUsers = async () =>
      await store.dispatch('tenants/fetchUsers', tenantId.value);

    const updateLocalVars = async () => {
      tenantId.value = router.currentRoute.value.params.tenantId as string;
      await fetchTenantUsers();
      await store.dispatch('users/fetchAllUsers');
      await store.dispatch('roles/fetchAllRoles', GetScopeMaskForTenant());
    };

    const roleAssignmentToRemove = ref({} as RoleAssignmentModel);
    const showRemoveRoleAssignmentModal = ref(false);
    const openRemoveRoleAssignmentModal = (
      roleAssignment: RoleAssignmentModel
    ) => {
      roleAssignmentToRemove.value = roleAssignment;
      showRemoveRoleAssignmentModal.value = true;
    };
    const removeRoleAssignment = async () => {
      await store.dispatch('roles/removeUserRoleAssignment', {
        email: roleAssignmentToRemove.value.principalId,
        scope: roleAssignmentToRemove.value.scope,
        roleName: roleAssignmentToRemove.value.roleName
      } as UserRoleAssignmentModel);
      showRemoveRoleAssignmentModal.value = false;
      await fetchTenantUsers();
    };

    const showAddRoleAssignmentModal = ref(false);
    const addRoleAssignments = async (
      userRoleAssignments: Array<UserRoleAssignmentModel>
    ) => {
      for (let userRoleAssignment of userRoleAssignments) {
        await store.dispatch('roles/addUserRoleAssignment', userRoleAssignment);
      }
      ElMessage({
        showClose: true,
        message: i18n.global.t('rolesAddedSuccessfully')
      });
      showAddRoleAssignmentModal.value = false;
      await fetchTenantUsers();
    };

    const createUser = async (user: UserModel) => {
      await store.dispatch('users/createOrUpdateUser', {
        email: user.email,
        authorizationModel: {
          name: user.name,
          disabled: user.disabled
        } as CreateOrUpdateUserModel
      });
    };

    onMounted(() => updateLocalVars());

    return {
      addRoleAssignments,
      allUsers,
      allRoles,
      compareRoleAssignments,
      createUser,
      GetScopeForTenant,
      openRemoveRoleAssignmentModal,
      removeRoleAssignment,
      showAddRoleAssignmentModal,
      showRemoveRoleAssignmentModal,
      tenantId,
      tenantUsers
    };
  }
};
</script>
<style lang="scss" scoped></style>
