<template>
  <base-card no-header class="width-100">
    <template #body>
      <el-row class="d-flex">
        <el-select
          v-model="localFileLogicType"
          size="small"
          class="ml-auto width-20"
          @change="handleLogicTypeChange"
        >
          <el-option
            v-for="(index, item) in FileLogicType"
            :key="index"
            :label="item"
            :value="index"
          />
        </el-select>
        <el-button
          class="ml-2"
          type="primary"
          :disabled="props.disabled"
          @click="save"
        >
          <h4>{{ $t('save') }}</h4>
        </el-button>
      </el-row>
      <el-row class="mt-3">
        <el-col :span="10">
          <el-tooltip placement="top" :content="$t('fileNameTooltipContent')">
            <h4>{{ $t('fileName') }}</h4>
          </el-tooltip>
        </el-col>
        <el-col :span="3" class="d-flex justify-content-center">
          <el-tooltip placement="top" :content="$t('requiredTooltipContent')">
            <h4>{{ $t('required') }}</h4>
          </el-tooltip>
        </el-col>
        <el-col :span="3" class="d-flex justify-content-center">
          <el-tooltip placement="top" :content="$t('triggerTooltipContent')">
            <h4>{{ $t('trigger') }}</h4>
          </el-tooltip>
        </el-col>
        <el-col :span="3" class="d-flex justify-content-center">
          <el-tooltip placement="top" :content="$t('versionedTooltipContent')">
            <h4>{{ $t('versioned') }}</h4>
          </el-tooltip>
        </el-col>
        <el-col :span="3" class="d-flex justify-content-center">
          <el-tooltip placement="top" :content="$t('archivedTooltipContent')">
            <h4>{{ $t('archived') }}</h4>
          </el-tooltip>
        </el-col>
      </el-row>
      <el-form
        v-for="(form, index) in forms"
        :key="index"
        ref="formRef"
        :model="form.value"
        :rules="formRules"
      >
        <el-row class="mt-1 mb-2">
          <el-col :span="10">
            <el-form-item prop="namePattern">
              <el-input
                v-model="form.value.namePattern"
                :disabled="props.disabled"
                size="small"
              />
            </el-form-item>
          </el-col>
          <el-col
            :span="3"
            class="d-flex justify-content-center align-items-center"
          >
            <el-checkbox
              v-model="form.value.isRequired"
              :disabled="props.disabled"
            />
          </el-col>
          <el-col
            :span="3"
            class="d-flex justify-content-center align-items-center"
          >
            <el-checkbox
              v-model="form.value.isTrigger"
              :disabled="props.disabled"
            />
          </el-col>
          <el-col
            :span="3"
            class="d-flex justify-content-center align-items-center"
          >
            <el-checkbox
              v-model="form.value.isVersioned"
              :disabled="props.disabled"
            />
          </el-col>
          <el-col
            :span="3"
            class="d-flex justify-content-center align-items-center"
          >
            <el-checkbox
              v-model="form.value.isArchived"
              :disabled="props.disabled"
            />
          </el-col>
          <el-col
            :span="2"
            class="d-flex justify-content-center align-items-center"
          >
            <el-tooltip
              v-if="!props.disabled"
              placement="top"
              :content="$t('deleteInputFile')"
            >
              <img
                :src="require(`@/assets/images/icons/new/delete.svg`)"
                height="14"
                width="14"
                class="action-icon"
                @click="deleteFile(index)"
              />
            </el-tooltip>
          </el-col>
        </el-row>
      </el-form>
      <el-row v-if="!props.disabled" class="mt-3">
        <el-col :span="12">
          <el-button type="info" size="small" @click="addFile">
            <h4>{{ $t('addInputFile') }}</h4>
          </el-button>
        </el-col>
      </el-row>
    </template>
  </base-card>
</template>
<script lang="ts">
import BaseCard from '@/components/panels/BaseCard.vue';
import {
  ElButton,
  ElCheckbox,
  ElCol,
  ElForm,
  ElFormItem,
  ElInput,
  ElOption,
  ElRow,
  ElSelect,
  ElTooltip
} from 'element-plus';
import {
  FileLogicType,
  InputFileConfiguration
} from '@etp/etp-gain-bff-client/axios';
import { onMounted, onUpdated, reactive, Ref, ref } from 'vue';
import i18n from '@/i18n';
import { mustContainAtMostOneAsterisk } from '@/utils/formValidators';

export default {
  name: 'GainImportFilesListCard',
  components: {
    BaseCard,
    ElButton,
    ElCheckbox,
    ElCol,
    ElForm,
    ElFormItem,
    ElInput,
    ElOption,
    ElRow,
    ElSelect,
    ElTooltip
  },
  props: {
    fileConfgurations: {
      type: Array as () => Array<InputFileConfiguration>,
      default: () => new Array<InputFileConfiguration>()
    },
    disabled: { type: Boolean, default: false }
  },
  emits: ['change-logic-type', 'save'],
  setup(props, { emit }) {
    const localFileLogicType = ref(FileLogicType.BooleanLogic);

    const forms = ref(new Array<Ref<InputFileConfiguration>>());
    const formRef = ref<HTMLFormElement>();
    const formRules = reactive({
      namePattern: [
        {
          required: true,
          message: i18n.global.t('thisFieldIsRequired'),
          trigger: ['change', 'blur']
        },
        {
          validator: mustContainAtMostOneAsterisk,
          trigger: ['change', 'blur']
        }
      ]
    });

    const addFile = () => {
      var newEntry = ref({
        namePattern: '',
        isArchived: false,
        isRequired: false,
        isTrigger: false,
        isVersioned: false
      } as InputFileConfiguration);
      if (!forms.value?.length) {
        forms.value = [newEntry];
      } else {
        forms.value.push(newEntry);
      }
    };

    const deleteFile = (index: number) => {
      if (!forms.value?.length) return;
      forms.value.splice(index, 1);
    };

    const handleLogicTypeChange = (newValue: FileLogicType) => {
      emit('change-logic-type', newValue);
    };

    const save = () => {
      let allFormsValid = true;
      let numFormsChecked = 0;
      formRef.value?.forEach((el: HTMLFormElement) =>
        el.validate((formValid: boolean) => {
          if (!formValid) allFormsValid = false;
          numFormsChecked++;
          if (numFormsChecked == forms.value.length && allFormsValid) {
            emit(
              'save',
              forms.value.map(form => form.value)
            );
          }
        })
      );
    };

    onMounted(() => {
      forms.value = props.fileConfgurations.map(el => ref(el));
    });
    onUpdated(() => {
      forms.value = props.fileConfgurations.map(el => ref(el));
    });

    return {
      addFile,
      close,
      deleteFile,
      FileLogicType,
      forms,
      formRef,
      formRules,
      handleLogicTypeChange,
      localFileLogicType,
      props,
      save
    };
  }
};
</script>
<style></style>
