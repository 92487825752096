<template>
  <el-table :data="pipelines" :empty-text="$t('noRuns')">
    <el-table-column :label="$t('name')">
      <template #default="scope">
        <h4>{{ scope.row.shortName }}</h4>
      </template>
    </el-table-column>
    <el-table-column
      v-if="canHidePipelines"
      :label="$t('hidePipeline')"
      :width="120"
    >
      <template #default="scope">
        <div class="toggle">
          <base-toggle-button
            :model-value="scope.row.isHidden ?? false"
            :indicator-text="$t('togglingPipeline')"
            @update:model-value="updatePipeline(scope.row)"
          />
        </div>
      </template>
    </el-table-column>
    <el-table-column :width="150" class-name="overflow" align="right">
      <template #default="scope">
        <el-button type="primary" @click="startPipelineRun(scope.row)">
          <h4>{{ $t('run') }}</h4>
        </el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script lang="ts">
import { ElTable, ElTableColumn, ElButton, ElNotification } from 'element-plus';
import BaseToggleButton from '@/components/buttons/BaseToggleButton.vue';
import i18n from '@/i18n';
import { PipelineDto } from '@etp/etp-pipelines-client/axios';

export default {
  name: 'PipelinesTable',
  components: { ElTable, ElTableColumn, ElButton, BaseToggleButton },
  props: {
    pipelines: { type: Array as () => Array<PipelineDto>, required: true },
    canHidePipelines: { type: Boolean, required: true }
  },
  emits: ['startPipelineRun', 'updatePipeline'],
  setup(props, { emit }) {
    const startPipelineRun = (pipeline: PipelineDto): void => {
      emit('startPipelineRun', pipeline);
      ElNotification({
        title: 'Running',
        dangerouslyUseHTMLString: true,
        message: i18n.global
          .t('pipelineRunning')
          .replace('[pipeline]', pipeline.shortName || ''),
        type: 'info'
      });
    };
    const updatePipeline = (pipeline: PipelineDto): void => {
      emit('updatePipeline', pipeline);
    };
    return {
      startPipelineRun,
      updatePipeline
    };
  }
};
</script>

<style lang="scss" scoped>
.toggle {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
