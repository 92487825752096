<template>
  <el-row justify="space-between" align="middle">
    <el-col :span="5">
      <h4 v-if="loadingState" class="mt-auto">{{ $t('loading') }}</h4>
      <h4 v-else class="mt-auto">
        {{ rowCount }} &#183; {{ quantityMessage }}
      </h4>
    </el-col>
    <el-col :span="17">
      <div class="options-header">
        <el-input
          v-if="!noFilter"
          v-model="searchQuery"
          size="small"
          class="ml-2"
          :placeholder="inputPlaceholder"
          @input="handleInput"
        />
        <slot></slot>
      </div>
    </el-col>
  </el-row>
</template>

<script lang="ts">
import { ElRow, ElCol, ElInput } from 'element-plus';

import { ref } from 'vue';
import { useDebounceFn } from '@vueuse/core';

export default {
  name: 'BaseTableHeader',
  components: { ElRow, ElCol, ElInput },
  props: {
    rowCount: { type: Number, required: true },
    quantityMessage: {
      type: String,
      required: true
    },
    inputPlaceholder: {
      type: String,
      default: ''
    },
    loadingState: { type: Boolean, required: false, default: false },
    noFilter: { type: Boolean, default: false },
    debounce: { type: Boolean, default: false }
  },
  emits: ['queryChange'],
  setup(props, { emit }) {
    const searchQuery = ref('');

    const handleInput = useDebounceFn(
      () => {
        emit('queryChange', searchQuery.value);
      },
      props.debounce ? 500 : 0
    );

    return {
      searchQuery,
      handleInput
    };
  }
};
</script>

<style lang="scss" scoped>
.el-input {
  width: 200px;
}
.options-header {
  display: flex;
  justify-content: flex-end;
}
</style>
