<template>
  <el-row>
    <el-col>
      <h4>{{ allApplicationsCount }} &#183; {{ $t('applications') }}</h4>
    </el-col>
  </el-row>
  <el-row>
    <el-col>
      <el-table :data="applicationsData" :empty-text="$t('noApplications')">
        <el-table-column :label="$t('name')" show-overflow-tooltip prop="name">
          <template #default="props">
            <div>
              {{ props.row.name }}
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="$t('objectId')" prop="objectId" />
        <el-table-column width="100">
          <template #default="props">
            <el-tooltip :content="$t('edit')" placement="top">
              <img
                src="@/assets/images/icons/new/edit.svg"
                height="12"
                width="12"
                class="action-icon mr-1"
                @click="openApplicationDetails(props.row.objectId)"
              />
            </el-tooltip>
            <el-tooltip :content="$t('delete')" placement="top">
              <img
                src="@/assets/images/icons/new/delete.svg"
                height="12"
                width="12"
                class="action-icon"
                @click="openDeleteApplicationModal(props.row)"
              />
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </el-col>
  </el-row>
  <base-confirmation-modal
    v-model="showDeleteApplicationModal"
    :content="$t('confirmDeleteApplication')"
    :feedback-text="$t('applicationDeleted')"
    @confirm="deleteApplication"
    @close="showDeleteApplicationModal = false"
  />
</template>

<script lang="ts">
import { ElRow, ElCol, ElTable, ElTableColumn, ElTooltip } from 'element-plus';
import BaseConfirmationModal from '@/components/modals/BaseConfirmationModal.vue';
import { ref } from 'vue';
import router from '@/router';
import { ApplicationModel } from '@etp/etp-authorization-client/axios';

export default {
  name: 'ApplicationsTable',
  components: {
    ElRow,
    ElCol,
    ElTableColumn,
    ElTable,
    ElTooltip,
    BaseConfirmationModal
  },
  props: {
    applicationsData: {
      type: Array as () => ApplicationModel[],
      default: () => [],
      required: true
    },
    allApplicationsCount: { type: Number, required: true }
  },
  emits: ['deleteApplication'],
  setup(props, { emit }) {
    const showDeleteApplicationModal = ref(false);
    const applicationToDelete = ref<ApplicationModel>();

    const openDeleteApplicationModal = (application: ApplicationModel) => {
      applicationToDelete.value = application;
      showDeleteApplicationModal.value = true;
    };

    const deleteApplication = (): void => {
      showDeleteApplicationModal.value = false;
      emit('deleteApplication', applicationToDelete.value);
    };

    const openApplicationDetails = (objectId: string) => {
      router.push({
        name: 'ApplicationDetails',
        params: { objectId }
      });
    };

    return {
      deleteApplication,
      openDeleteApplicationModal,
      openApplicationDetails,
      showDeleteApplicationModal
    };
  }
};
</script>

<style scoped></style>
