<template>
  <base-modal
    :modal-title="$t('revertPromotionModalTitle')"
    :dialog-visible="modelValue"
    :confirm-text="$t('revert')"
    width="600px"
    @close="close"
    @confirm="confirm"
  >
    <template #content>
      <el-row>
        <h4>{{ $t('revertPromotionModalText') }}</h4>
      </el-row>
      <el-row class="mt-4">
        <el-form
          ref="formRef"
          :model="form"
          :rules="formRules"
          label-position="top"
          class="width-100"
        >
          <el-form-item :label="$t('versionToRevert')" prop="versionToRevert">
            <el-input v-model="form.versionToRevert" disabled />
          </el-form-item>
          <el-form-item :label="$t('versionToRestore')" prop="versionToRestore">
            <el-input v-model="form.versionToRestore" disabled />
          </el-form-item>
          <el-form-item :label="$t('comment')" prop="comment">
            <el-input v-model="form.comment" :rows="4" type="textarea" />
          </el-form-item>
        </el-form>
      </el-row>
    </template>
  </base-modal>
</template>
<script lang="ts">
import BaseModal from '@/components/modals/BaseModal.vue';
import { ElForm, ElFormItem, ElInput, ElMessage, ElRow } from 'element-plus';
import { onMounted, onUpdated, reactive, ref } from 'vue';
import i18n from '@/i18n';
import { RevertPromotionRequest } from '@etp/etp-gain-bff-client/axios';

export default {
  name: 'NewEngagementModal',
  components: {
    BaseModal,
    ElInput,
    ElForm,
    ElFormItem,
    ElRow
  },
  props: {
    modelValue: { type: Boolean, default: false },
    feedbackText: { type: String, default: i18n.global.t('saved') },
    versionToRevert: { type: Number, required: true },
    versionToRestore: { type: Number, required: true }
  },
  emits: ['close', 'confirm', 'update:model-value'],
  setup(props, { emit }) {
    const form = reactive({
      versionToRevert: 0,
      versionToRestore: 0,
      comment: ''
    });
    const formRules = reactive({
      comment: {
        required: true,
        message: i18n.global.t('thisFieldIsRequired'),
        trigger: 'blur'
      }
    });
    const formRef = ref<HTMLFormElement>();
    const confirm = () => {
      formRef.value?.validate((valid: boolean) => {
        if (valid) {
          emit('confirm', {
            comment: form.comment
          } as RevertPromotionRequest);
          ElMessage({
            showClose: true,
            message: props.feedbackText
          });
        }
      });
    };

    const resetLocalVars = () => {
      form.versionToRevert = props.versionToRevert;
      form.versionToRestore = props.versionToRestore;
      form.comment = '';
    };

    onMounted(() => resetLocalVars());
    onUpdated(() => resetLocalVars());

    const close = () => {
      resetLocalVars();
      emit('update:model-value', false);
    };

    return {
      form,
      formRules,
      formRef,
      confirm,
      close,
      props
    };
  }
};
</script>
<style scoped lang="scss"></style>
