<template>
  <el-row>
    <span class="body-1 cursor-pointer" @click="openVersionModal">
      {{ $t('currentVersion') + ': ' + gainImportConfiguration.version }}
    </span>
  </el-row>
  <el-row class="mt-2 mb-2">
    <gain-import-files-list-card
      v-if="localFileLogicType == FileLogicType.BooleanLogic"
      :disabled="!userCanWriteEngagementGainImportConfiguration || isSignedOff"
      :file-confgurations="localFileConfigurations"
      @change-logic-type="handleChangeLogicType"
      @save="saveFileConfigurations"
    />
    <gain-import-files-sql-card
      v-if="localFileLogicType == FileLogicType.SqlLogic"
      :batch-files="gainBatchFiles"
      :disabled="!userCanWriteEngagementGainImportConfiguration || isSignedOff"
      :sql-confguration="localSqlConfiguration"
      :staging-files="gainStagingFiles"
      @change-logic-type="handleChangeLogicType"
      @save="saveSqlConfiguration"
      @test="testSqlConfiguration"
    />
  </el-row>
  <import-configuration-versions-modal
    v-model="showVersionModal"
    :configuration-versions="gainImportConfigurationHistory"
    :disable-restore="
      !userCanWriteEngagementGainImportConfiguration || isSignedOff
    "
    @restore="handleRestore"
    @close="showVersionModal = false"
  />
</template>

<script lang="ts">
import { ElMessage, ElRow } from 'element-plus';
import { useStore } from 'vuex';
import { computed, ComputedRef, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import {
  BatchFileSqlModel,
  ExecuteFileSqlRequest,
  FileLogicType,
  GainImportConfiguration,
  InputFileConfiguration,
  InputSqlConfiguration,
  ModelFile,
  RequirementsStatus
} from '@etp/etp-gain-bff-client/axios';
import ImportConfigurationVersionsModal from './components/ImportConfigurationVersionsModal.vue';
import GainImportFilesListCard from './components/GainImportFilesListCard.vue';
import GainImportFilesSqlCard from './components/GainImportFilesSqlCard.vue';

export default {
  name: 'GainImportFiles',
  components: {
    ElRow,
    GainImportFilesListCard,
    GainImportFilesSqlCard,
    ImportConfigurationVersionsModal
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const showVersionModal = ref(false);
    const localFileLogicType = ref(0);
    const localFileConfigurations = ref(new Array<InputFileConfiguration>());
    const localSqlConfiguration = ref({} as InputSqlConfiguration);

    const gainBatchFiles: ComputedRef<Array<BatchFileSqlModel>> = computed(
      () => store.getters['gain/getBatchFiles']
    );
    const gainImportConfiguration: ComputedRef<GainImportConfiguration> =
      computed(() => store.getters['import/getGainImportConfiguration']);
    const gainImportConfigurationHistory: ComputedRef<
      Array<GainImportConfiguration>
    > = computed(
      () => store.getters['import/getGainImportConfigurationHistory']
    );
    const gainStagingFiles: ComputedRef<Array<ModelFile>> = computed(
      () => store.getters['gain/getStagingFiles']
    );

    const openVersionModal = async () => {
      await store.dispatch('import/fetchGainImportConfigurationHistory');
      showVersionModal.value = true;
    };

    const handleChangeLogicType = (newValue: FileLogicType) => {
      localFileLogicType.value = newValue;
    };

    const handleRestore = async (configuration: GainImportConfiguration) => {
      await store.dispatch(
        'import/updateGainImportConfiguration',
        configuration
      );
      showVersionModal.value = false;
      await updateLocalVars();
    };

    const saveFileConfigurations = async (
      configurations: Array<InputFileConfiguration>
    ) => {
      await store.dispatch('import/updateGainImportConfiguration', {
        ...gainImportConfiguration.value,
        fileConfigurations: configurations,
        fileLogicType: localFileLogicType.value
      } as GainImportConfiguration);
      await updateLocalVars();
    };

    const saveSqlConfiguration = async (
      configuration: InputSqlConfiguration
    ) => {
      await store.dispatch('import/updateGainImportConfiguration', {
        ...gainImportConfiguration.value,
        inputSqlConfiguration: configuration,
        fileLogicType: localFileLogicType.value
      } as GainImportConfiguration);
      await updateLocalVars();
    };

    const testSqlConfiguration = async (request: ExecuteFileSqlRequest) => {
      let sqliteError = await store.dispatch(
        'gain/testImportConfiguration',
        request
      );
      if (!sqliteError) return;
      ElMessage({
        showClose: true,
        message: sqliteError
      });
    };

    const updateLocalVars = async () => {
      await store.dispatch('import/fetchGainImportConfiguration');
      localFileLogicType.value =
        gainImportConfiguration.value.fileLogicType ??
        FileLogicType.BooleanLogic;
      localFileConfigurations.value = [
        ...(gainImportConfiguration.value.fileConfigurations ??
          new Array<InputFileConfiguration>())
      ];
      localSqlConfiguration.value = {
        ...(gainImportConfiguration.value.inputSqlConfiguration ??
          ({} as InputSqlConfiguration))
      };
    };

    const isGainEngagement: ComputedRef<boolean> = computed(
      () => store.getters['home/isGainEngagement']
    );
    const isSignedOff: ComputedRef<boolean> = computed(
      () =>
        store.getters['gain/getCurrentRequirementsStatus']
          ?.requirementsStatus === RequirementsStatus.SignedOff
    );
    const userCanWriteEngagementGainImportConfiguration: ComputedRef<boolean> =
      computed(
        () =>
          store.getters[
            'userPermissions/canWriteEngagementGainImportConfiguration'
          ]
      );

    onMounted(async () => {
      if (!isGainEngagement.value) router.push({ name: 'ImportMonitor' });
      await store.dispatch('gain/fetchCurrentRequirementsStatus');
      await store.dispatch('gain/fetchStagingFiles');
      await store.dispatch(
        'userPermissions/checkIfCanWriteEngagementGainImportConfiguration'
      );
      await updateLocalVars();
    });

    return {
      FileLogicType,
      gainBatchFiles,
      gainImportConfiguration,
      gainImportConfigurationHistory,
      gainStagingFiles,
      handleChangeLogicType,
      handleRestore,
      isSignedOff,
      localFileConfigurations,
      localFileLogicType,
      localSqlConfiguration,
      openVersionModal,
      saveFileConfigurations,
      saveSqlConfiguration,
      showVersionModal,
      testSqlConfiguration,
      userCanWriteEngagementGainImportConfiguration
    };
  }
};
</script>

<style scoped lang="scss"></style>
