import ModelOverview from '@/views/home/data-journey/model/ModelOverview.vue';
import ModelConfig from '../ModelConfig.vue';
import ModelBuilding from '../ModelBuilding.vue';
import RunOverview from '@/views/home/shared/RunOverview.vue';
import MonitorOverview from '@/views/home/shared/MonitorOverview.vue';

export default {
  path: '/modeling',
  name: 'Model',
  redirect: { name: 'ModelMonitor' },
  component: ModelOverview,
  meta: { menuItemName: 'Model' },
  children: [
    {
      path: 'config',
      name: 'ModelConfig',
      component: ModelConfig
    },
    {
      path: 'building',
      name: 'ModelBuilding',
      component: ModelBuilding
    },
    {
      path: 'run',
      name: 'ModelRun',
      component: RunOverview
    },
    {
      path: 'monitor',
      name: 'ModelMonitor',
      component: MonitorOverview
    }
  ]
};
