import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "subtitle-2 mt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_header = _resolveComponent("page-header")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_applications_table = _resolveComponent("applications-table")!
  const _component_pagination = _resolveComponent("pagination")!
  const _component_new_application_modal = _resolveComponent("new-application-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_page_header, {
          "main-title": _ctx.$t('applications')
        }, {
          subheader: _withCtx(() => [
            _createElementVNode("h4", _hoisted_1, _toDisplayString(_ctx.$t('applicationsDesc')), 1)
          ]),
          _: 1
        }, 8, ["main-title"])
      ]),
      _: 1
    }),
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_button, {
          type: "primary",
          class: "ml-auto",
          onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.openApplicationsModal()))
        }, {
          default: _withCtx(() => [
            _createElementVNode("h4", null, "+ " + _toDisplayString(_ctx.$t('newApplication')), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_applications_table, {
      "applications-data": $setup.applications,
      "all-applications-count": $setup.allApplicationsCount,
      onDeleteApplication: $setup.deleteApplication
    }, null, 8, ["applications-data", "all-applications-count", "onDeleteApplication"]),
    _createVNode(_component_pagination, {
      count: $setup.allApplicationsCount,
      page: $setup.pageNumber,
      "page-size": $setup.pageSize,
      onChangePage: $setup.changePage
    }, null, 8, ["count", "page", "page-size", "onChangePage"]),
    _createVNode(_component_new_application_modal, {
      modelValue: $setup.showApplicationsModal,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.showApplicationsModal) = $event)),
      "feedback-text": _ctx.$t('applicationInfoSaved'),
      onConfirm: $setup.createApplication,
      onClose: _cache[2] || (_cache[2] = ($event: any) => ($setup.showApplicationsModal = false))
    }, null, 8, ["modelValue", "feedback-text", "onConfirm"])
  ], 64))
}