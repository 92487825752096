<template>
  <base-modal
    :modal-title="$t('configurationHistory')"
    :dialog-visible="modelValue"
    :has-confirmation-button="false"
    width="100%"
    @close="close"
  >
    <template #content>
      <el-table :data="props.configurationVersions">
        <el-table-column :label="$t('version')" width="150">
          <template #default="scope">
            <h4>{{ scope.row.version }}</h4>
            <h4>{{ formatDate(scope.row.timestamp) }}</h4>
            <h4>{{ formatTime(scope.row.timestamp) }}</h4>
          </template>
        </el-table-column>
        <el-table-column :label="$t('configuration')">
          <template #default="{ row, $index }">
            <el-row v-for="(value, key) in row" :key="key">
              <el-col :span="6">
                <h4>{{ key }}</h4>
              </el-col>
              <el-col :span="18">
                <h4
                  :class="
                    isDifferentToPreviousVersion($index, key) ? 'color-red' : ''
                  "
                >
                  {{ value }}
                </h4>
              </el-col>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column width="150">
          <template #default="{ $index }">
            <el-button
              v-if="$index !== 0"
              type="primary"
              @click="restoreConfiguration($index)"
            >
              {{ $t('restore') }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </template>
  </base-modal>
</template>
<script lang="ts">
import BaseModal from '@/components/modals/BaseModal.vue';
import { ElButton, ElCol, ElRow, ElTable, ElTableColumn } from 'element-plus';
import { SystemConfiguration } from '@etp/etp-gain-bff-client/axios';
import moment from 'moment';

export default {
  name: 'GainConfigurationVersionsModal',
  components: {
    BaseModal,
    ElButton,
    ElCol,
    ElRow,
    ElTable,
    ElTableColumn
  },
  props: {
    modelValue: { type: Boolean, default: false },
    configurationVersions: {
      type: Array as () => Array<SystemConfiguration>,
      default: () => new Array<SystemConfiguration>()
    }
  },
  emits: ['close', 'update:model-value', 'restore'],
  setup(props, { emit }) {
    const close = () => {
      emit('update:model-value', false);
    };

    const formatDate = (input: Date) => {
      if (!input) return '';
      return moment(input).format('YYYY-MM-DD');
    };
    const formatTime = (input: Date) => {
      if (!input) return '';
      return moment(input).format('HH:mm:ss');
    };

    const isDifferentToPreviousVersion = (index: number, key: any): boolean => {
      if (key === 'version') return false;
      if (index === props.configurationVersions.length - 1) return false;
      if (
        JSON.stringify(props.configurationVersions[index][key]) ===
        JSON.stringify(props.configurationVersions[index + 1][key])
      )
        return false;
      return true;
    };

    const restoreConfiguration = (index: number) => {
      emit('restore', props.configurationVersions[index]);
    };

    return {
      confirm,
      close,
      formatDate,
      formatTime,
      isDifferentToPreviousVersion,
      props,
      restoreConfiguration
    };
  }
};
</script>
<style scoped lang="scss">
.color-red {
  color: $etp-darker-red;
}
</style>
