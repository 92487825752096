<template>
  <base-modal
    width="400px"
    :modal-title="role.name ? $t('editRole') : $t('newRole')"
    :dialog-visible="modelValue"
    :confirm-text="$t('save')"
    @confirm="confirm()"
    @close="handleClose"
  >
    <template #content>
      <el-form
        ref="formRef"
        :model="form"
        :rules="formRules"
        label-position="top"
      >
        <el-form-item :label="$t('name')" prop="name">
          <el-input v-model="form.name" size="small" />
        </el-form-item>
        <el-form-item :label="$t('scopeMask')" prop="scopeMask">
          <el-input
            v-model="form.scopeMask"
            size="small"
            placeholder="/tenants/{tenantId}"
          />
        </el-form-item>
        <el-form-item :label="$t('description')" prop="description">
          <el-input v-model="form.description" size="small" />
        </el-form-item>
        <el-form-item :label="$t('permissions')" prop="permissions">
          <permissions-tree
            :all-permissions="stringPermissions"
            :existing-permissions="form.permissions"
            delimiter="."
            @change="handleSelectedPermissions"
          />
        </el-form-item>
      </el-form>
    </template>
  </base-modal>
</template>
<script lang="ts">
import BaseModal from '@/components/modals/BaseModal.vue';
import PermissionsTree from '@/components/trees/PermissionsTree.vue';

import { ElForm, ElFormItem, ElInput } from 'element-plus';

import { computed, onUpdated, reactive, Ref, ref } from 'vue';
import i18n from '@/i18n';
import {
  PermissionModel,
  RoleDetailsModel
} from '@etp/etp-authorization-client/axios';
import { mustBeAlphanumericDot } from '@/utils/formValidators';

export default {
  name: 'CreateOrUpdateRoleModal',
  components: {
    BaseModal,
    PermissionsTree,
    ElInput,
    ElForm,
    ElFormItem
  },
  props: {
    modelValue: { type: Boolean, default: false },
    permissions: {
      type: Object as () => Array<PermissionModel>,
      default: new Array<PermissionModel>()
    },
    role: {
      type: Object as () => RoleDetailsModel,
      default: {} as RoleDetailsModel
    }
  },
  emits: ['close', 'confirm'],
  setup(props, { emit }) {
    const form = reactive({
      name: '',
      scopeMask: '',
      description: '',
      permissions: new Array<string>()
    });
    const formRef = ref<HTMLFormElement>();
    const formRules = reactive({
      name: [
        {
          required: true,
          message: i18n.global.t('pleaseProvideName'),
          trigger: ['change', 'blur']
        },
        {
          validator: mustBeAlphanumericDot,
          trigger: ['change', 'blur']
        }
      ],
      scopeMask: {
        required: true,
        message: i18n.global.t('thisFieldIsRequired'),
        trigger: ['change', 'blur']
      }
    });

    let stringPermissions: Ref<Array<string>> = computed(() =>
      props.permissions.map(x => x.name || '')
    );

    const handleSelectedPermissions = (selectedPermissions: Array<string>) => {
      form.permissions = selectedPermissions;
    };

    let confirm = () => {
      formRef.value?.validate((valid: boolean) => {
        if (valid) {
          emit('confirm', {
            ...props.role,
            name: form.name,
            scopeMask: form.scopeMask,
            description: form.description,
            permissions: form.permissions
          } as RoleDetailsModel);
        }
      });
    };

    onUpdated(() => {
      form.name = props.role.name || '';
      form.scopeMask = props.role.scopeMask || '';
      form.description = props.role.description || '';
      form.permissions = props.role.permissions || [];
    });

    const handleClose = () => {
      emit('close');
    };

    return {
      stringPermissions,
      confirm,
      form,
      formRef,
      formRules,
      handleClose,
      handleSelectedPermissions
    };
  }
};
</script>
<style scoped lang="scss"></style>
