<template>
  <div>
    <el-dialog
      :model-value="modelValue"
      :before-close="handleClose"
      :show-close="showClose"
      :width="width"
      :center="center"
    >
      <template #header>
        <h2 class="modal-title">
          {{ modalTitle }}
        </h2>
      </template>
      <div class="modal-body">
        <slot name="content">
          <h3 class="subtitle-2 modal-content">
            {{ content }}
          </h3>
        </slot>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <slot name="footer">
            <div class="footer-cancel">
              <el-button
                class="modal-cancel"
                type="default"
                @click="handleClose"
              >
                <h4>{{ cancelText }}</h4>
              </el-button>
            </div>
            <div class="footer-confirm">
              <el-button
                class="modal-confirm"
                type="primary"
                @click="handleConfirm"
              >
                <h4>{{ confirmText }}</h4>
              </el-button>
            </div>
          </slot>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script lang="ts">
import { ElDialog, ElButton, ElMessage } from 'element-plus';

import i18n from '@/i18n';

export default {
  name: 'ConfirmationModal',
  components: { ElButton, ElDialog },
  props: {
    modelValue: { type: Boolean, default: false },
    width: { type: String, default: 'fit-content !important' },
    showClose: { type: Boolean, default: false },
    center: { type: Boolean, default: true },
    modalTitle: {
      type: String,
      default: i18n.global.t('confirmationRequired')
    },
    content: { type: String, default: i18n.global.t('areYouSure') },
    confirmText: { type: String, default: i18n.global.t('confirm') },
    cancelText: { type: String, default: i18n.global.t('cancel') },
    feedbackText: { type: String, default: i18n.global.t('saved') }
  },
  emits: ['confirm', 'update:model-value'],
  setup(props, { emit }) {
    const handleClose = () => {
      emit('update:model-value', false);
    };

    const handleConfirm = () => {
      emit('confirm');
      ElMessage({
        showClose: true,
        message: props.feedbackText
      });
    };

    return {
      handleClose,
      handleConfirm
    };
  }
};
</script>
<style lang="scss" scoped>
.modal-body {
  text-align: center;
}
.dialog-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.modal-content {
  text-align: center;
}
.modal-confirm {
  margin-left: 10px;
}
.footer-cancel {
  flex-grow: 1;
}
.footer-confirm {
  flex-grow: 1;
}
</style>
