import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_base_card = _resolveComponent("base-card")!

  return (_openBlock(), _createBlock(_component_base_card, { "no-header": "" }, {
    body: _withCtx(() => [
      _createVNode(_component_el_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_col, { span: 6 }, {
            default: _withCtx(() => [
              _createElementVNode("h3", null, _toDisplayString(_ctx.$t('nodeModel')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_col, { span: 12 }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form, {
                ref: "formRef",
                model: $setup.form,
                rules: $setup.formRules
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_form_item, {
                    class: "mb-0",
                    label: _ctx.$t('implementation'),
                    prop: "implementation"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_select, {
                        modelValue: $setup.form.nodeModelPlatformType,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.form.nodeModelPlatformType) = $event)),
                        size: "small",
                        placeholder: _ctx.$t('empty'),
                        class: "mb-0"
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.NodeModelPlatformType, (index, item) => {
                            return (_openBlock(), _createBlock(_component_el_option, {
                              key: index,
                              label: item,
                              value: index
                            }, null, 8, ["label", "value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["modelValue", "placeholder"])
                    ]),
                    _: 1
                  }, 8, ["label"]),
                  _createVNode(_component_el_form_item, {
                    class: "mb-0",
                    label: _ctx.$t('repository'),
                    prop: "nodeModelRepositoryName"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_select, {
                        modelValue: $setup.form.nodeModelRepositoryName,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.form.nodeModelRepositoryName) = $event)),
                        size: "small",
                        placeholder: _ctx.$t('empty')
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.localGitRepositories, (item) => {
                            return (_openBlock(), _createBlock(_component_el_option, {
                              key: item.name ?? '',
                              label: item.name ?? '',
                              value: item.name
                            }, null, 8, ["label", "value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["modelValue", "placeholder"])
                    ]),
                    _: 1
                  }, 8, ["label"]),
                  (!$setup.props.hideSqlFields)
                    ? (_openBlock(), _createBlock(_component_el_form_item, {
                        key: 0,
                        class: "mb-0",
                        label: _ctx.$t('sqlServerName'),
                        prop: "nodeModelSqlServerName"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_select, {
                            modelValue: $setup.form.nodeModelSqlServerName,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.form.nodeModelSqlServerName) = $event)),
                            clearable: "",
                            size: "small",
                            placeholder: _ctx.$t('empty')
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.localSqlServers, (item) => {
                                return (_openBlock(), _createBlock(_component_el_option, {
                                  key: item.name ?? '',
                                  label: item.name ?? '',
                                  value: item.name
                                }, null, 8, ["label", "value"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["modelValue", "placeholder"])
                        ]),
                        _: 1
                      }, 8, ["label"]))
                    : _createCommentVNode("", true),
                  (!$setup.props.hideSqlFields)
                    ? (_openBlock(), _createBlock(_component_el_form_item, {
                        key: 1,
                        class: "mb-0",
                        label: _ctx.$t('sqlDatabase'),
                        prop: "nodeModelDatabaseName"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_select, {
                            modelValue: $setup.form.nodeModelDatabaseName,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.form.nodeModelDatabaseName) = $event)),
                            clearable: "",
                            size: "small",
                            placeholder: _ctx.$t('empty')
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.localDatabases, (item) => {
                                return (_openBlock(), _createBlock(_component_el_option, {
                                  key: item.name ?? '',
                                  label: item.name ?? '',
                                  value: item.name
                                }, null, 8, ["label", "value"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["modelValue", "placeholder"])
                        ]),
                        _: 1
                      }, 8, ["label"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["model", "rules"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_col, {
            span: 6,
            class: "d-flex"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_button, {
                class: "ml-auto",
                type: "primary",
                disabled: $props.disabled,
                onClick: $setup.save
              }, {
                default: _withCtx(() => [
                  _createElementVNode("h4", null, _toDisplayString(_ctx.$t('save')), 1)
                ]),
                _: 1
              }, 8, ["disabled", "onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}