<template>
  <el-dropdown
    class="menu-dropdown"
    trigger="click"
    placement="bottom"
    max-height="400px"
    @command="handleClick"
  >
    <div class="project-selection-wrapper width-100">
      <div class="project-meta-content">
        <div class="projectstatus-icon-wrapper">
          <img
            v-if="
              !currentEngagement ||
              !currentEngagement.id ||
              !currentEngagement.name
            "
            src="@/assets/images/icons/git-commit.svg"
            class="engagement-icon"
          />
          <img v-else :src="getImage()" class="engagement-icon" />
        </div>
        <div>
          <h4
            v-if="
              !currentEngagement ||
              !currentEngagement.id ||
              !currentEngagement.name
            "
            class="subtitle-1"
          >
            {{ $t('selectEngagement') }}
          </h4>
          <span v-else class="subtitle-1" :title="currentEngagement.name">
            {{ currentEngagement.name }}
          </span>
        </div>
      </div>
      <img
        src="@/assets/images/icons/new/arrow.svg"
        width="10"
        height="10"
        class="action-icon"
      />
    </div>
    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item
          v-for="engagement in engagements"
          :key="engagement.id"
          :title="engagement.name"
          :command="engagement"
        >
          <h3>{{ engagement.name }}</h3>
        </el-dropdown-item>
        <el-dropdown-item v-if="engagements.length === 0">
          <h3>{{ $t('noEngagement') }}</h3>
        </el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>
<script lang="ts">
import { ElDropdown, ElDropdownMenu, ElDropdownItem } from 'element-plus';
import { EngagementModel } from '@etp/etp-engagements-client/axios';

export default {
  name: 'TheEngagementSelector',
  components: { ElDropdown, ElDropdownMenu, ElDropdownItem },
  props: {
    engagements: {
      type: Array as () => Array<EngagementModel>,
      required: true
    },
    currentEngagement: {
      type: Object as () => EngagementModel,
      default: null
    }
  },
  emits: ['click'],
  setup(props, { emit }) {
    const handleClick = tenant => {
      emit('click', tenant);
    };
    let getImage = () => {
      try {
        return require(`@/assets/images/icons/${props.currentEngagement.name}.svg`);
      } catch (_) {
        return require(`@/assets/images/icons/git-commit.svg`);
      }
    };
    return { handleClick, getImage };
  }
};
</script>
<style lang="scss" scoped>
.project-selection-wrapper {
  padding: 20px 30px 0px;
  margin: 0px 20px;
  border-bottom-style: length($list: 2px);
  background: transparentize($color: $etp-green-white, $amount: 1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.subtitle-1 {
  margin-bottom: 2px;
  color: $etp-deloitte-blue-7;
  display: block;
  max-width: 120px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.project-meta-content {
  display: flex;
  align-items: center;
  > div:nth-of-type(2) {
    text-align: initial;
  }
}
.projectstatus-icon-wrapper {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 13px;
}
.engagement-icon {
  height: 24px;
  width: 24px;
  border-radius: 100%;
}
.el-icon-arrow-down {
  color: $etp-deloitte-blue-7;
}
h3 {
  padding: 15px 10px;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
