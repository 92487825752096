import { ActionContext, Module } from 'vuex';
import { createNodeModelClient } from '@/services/api';
import { encodeStringToBase64 } from '@/utils/Base64Conversion';
import {
  BranchModel,
  CategoryDetailModel,
  CategoryModel,
  CreateBranchModel,
  CreateCategoryModel,
  CreateFieldModel,
  CreateNodeModel,
  CreatePropertyModel,
  CreateSourceModel,
  CreateTestModel,
  FieldModel,
  NodeDetailModel,
  NodeModel,
  PropertyModel,
  SourceDetailModel,
  SourceModel,
  TestDetailModel,
  TestModel,
  UpdateCategoryDetailsModel,
  UpdateNodeModel,
  UpdateSourceDetailsModel,
  UpdateTestModel
} from '@etp/etp-nodemodelgit-client/axios';
import {
  BuildNodeModel,
  BuildTestModel
} from '@/services/web-api/dto/nodeModel';

export interface NodeModelStoreState {
  nodeModelLayerName: string;
  loadingBranches: boolean;
  loadingNodes: boolean;
  loadingSources: boolean;
  loadingCategories: boolean;
  loadingTests: boolean;
  branches: Array<BranchModel>;
  buildNodes: Array<BuildNodeModel>;
  buildSources: Array<SourceModel>;
  buildTests: Array<BuildTestModel>;
  buildCategories: Array<CategoryModel>;
  configCategories: Array<CategoryModel>;
  configFields: Array<FieldModel>;
  configProperties: Array<PropertyModel>;
  configNodes: Array<NodeModel>;
  configSources: Array<SourceModel>;
  configTests: Array<TestModel>;
  configCategoryDetail: CategoryDetailModel;
  configNodeDetail: NodeDetailModel;
  configSourceDetail: SourceDetailModel;
  configTestDetail: TestDetailModel;
  selectedBuildBranch: BranchModel;
  selectedBuildCategory: CategoryModel;
  selectedBuildSource: SourceModel;
  selectedBuildNode: NodeModel;
  selectedBuildTest: TestModel;
  selectedConfigBranch: BranchModel;
  selectedConfigCategory: CategoryModel;
  selectedConfigNode: NodeModel;
  selectedConfigSource: SourceModel;
  selectedConfigTest: TestModel;
}
export class NodeModelStoreModule implements Module<NodeModelStoreState, any> {
  namespaced = true;
  constructor(nodeModelLayerName: string) {
    this.state.nodeModelLayerName = nodeModelLayerName;
  }
  state = {
    nodeModelLayerName: '',
    loadingBranches: false,
    loadingNodes: false,
    loadingSources: false,
    loadingCategories: false,
    loadingTests: false,
    branches: [],
    buildCategories: [],
    buildNodes: [],
    buildSources: [],
    buildTests: [],
    configCategories: [],
    configFields: [],
    configProperties: [],
    configNodes: [],
    configSources: [],
    configTests: [],
    configCategoryDetail: {
      query: ''
    } as CategoryDetailModel,
    configNodeDetail: {
      defaultQuery: ''
    } as NodeDetailModel,
    configSourceDetail: {
      query: ''
    } as SourceDetailModel,
    configTestDetail: {
      categoryNames: [],
      query: ''
    } as TestDetailModel,
    selectedBuildBranch: {} as BranchModel,
    selectedBuildCategory: {} as CategoryModel,
    selectedBuildSource: {} as SourceModel,
    selectedBuildNode: {} as NodeModel,
    selectedBuildTest: {} as TestModel,
    selectedConfigBranch: {} as BranchModel,
    selectedConfigCategory: {} as CategoryModel,
    selectedConfigNode: {} as NodeModel,
    selectedConfigSource: {} as SourceModel,
    selectedConfigTest: {} as TestModel
  };
  getters = {
    loadingBranches: (state: NodeModelStoreState): boolean =>
      state.loadingBranches,
    loadingNodes: (state: NodeModelStoreState): boolean => state.loadingNodes,
    loadingSources: (state: NodeModelStoreState): boolean =>
      state.loadingSources,
    loadingCategories: (state: NodeModelStoreState): boolean =>
      state.loadingCategories,
    loadingTests: (state: NodeModelStoreState): boolean => state.loadingTests,
    branches: (state: NodeModelStoreState): any => state.branches,
    buildNodes: (state: NodeModelStoreState): any => state.buildNodes,
    buildSources: (state: NodeModelStoreState): any => state.buildSources,
    buildTests: (state: NodeModelStoreState): any => state.buildTests,
    buildCategories: (state: NodeModelStoreState): any => state.buildCategories,
    configCategories: (state: NodeModelStoreState): any =>
      state.configCategories,
    configFields: (state: NodeModelStoreState): any => state.configFields,
    configProperties: (state: NodeModelStoreState): any =>
      state.configProperties,
    configNodes: (state: NodeModelStoreState): any => state.configNodes,
    configSources: (state: NodeModelStoreState): any => state.configSources,
    configTests: (state: NodeModelStoreState): any => state.configTests,
    configCategoryDetail: (state: NodeModelStoreState): any =>
      state.configCategoryDetail,
    configNodeDetail: (state: NodeModelStoreState): any =>
      state.configNodeDetail,
    configSourceDetail: (state: NodeModelStoreState): any =>
      state.configSourceDetail,
    configTestDetail: (state: NodeModelStoreState): any =>
      state.configTestDetail,
    selectedBuildBranch: (state: NodeModelStoreState): any =>
      state.selectedBuildBranch,
    selectedBuildCategory: (state: NodeModelStoreState): any =>
      state.selectedBuildCategory,
    selectedBuildSource: (state: NodeModelStoreState): any =>
      state.selectedBuildSource,
    selectedBuildNode: (state: NodeModelStoreState): any =>
      state.selectedBuildNode,
    selectedBuildTest: (state: NodeModelStoreState): any =>
      state.selectedBuildTest,
    selectedConfigBranch: (state: NodeModelStoreState): any =>
      state.selectedConfigBranch,
    selectedConfigCategory: (state: NodeModelStoreState): any =>
      state.selectedConfigCategory,
    selectedConfigNode: (state: NodeModelStoreState): any =>
      state.selectedConfigNode,
    selectedConfigSource: (state: NodeModelStoreState): any =>
      state.selectedConfigSource,
    selectedConfigTest: (state: NodeModelStoreState): any =>
      state.selectedConfigTest
  };
  actions = {
    async fetchBranches(context: ActionContext<NodeModelStoreState, any>) {
      context.state.loadingBranches = true;
      let client = createNodeModelClient();
      let { data, status } = await client.listBranches(
        context.rootState.home.currentEngagement.id
      );
      if (status == 200 && data.length) {
        context.commit('SET_BRANCHES', data);
      } else {
        context.commit('SET_BRANCHES', new Array<BranchModel>());
      }
      context.state.loadingBranches = false;
    },
    async fetchBuildCategories(
      context: ActionContext<NodeModelStoreState, any>
    ) {
      context.state.loadingCategories = true;
      let client = createNodeModelClient();
      let { data, status } = await client.listAllCategories(
        context.rootState.home.currentEngagement.id,
        context.state.selectedBuildBranch?.name ?? ''
      );
      if (status == 200) {
        context.commit('SET_BUILD_CATEGORIES', data);
      } else {
        context.commit('SET_BUILD_CATEGORIES', new Array<CategoryModel>());
      }
      context.state.loadingCategories = false;
    },
    async fetchBuildNodes(context: ActionContext<NodeModelStoreState, any>) {
      context.state.loadingNodes = true;
      let client = createNodeModelClient();
      let { data, status } = await client.listNodes(
        context.rootState.home.currentEngagement.id,
        context.state.selectedBuildBranch?.name ?? '',
        context.state.nodeModelLayerName,
        context.state.selectedBuildSource?.name ?? '',
        context.state.selectedBuildCategory?.name ?? ''
      );
      if (status == 200 && data.length) {
        context.commit(
          'SET_BUILD_NODES',
          data.map(d => ({ ...d, active: false, rebuild: false }))
        );
      } else {
        context.commit('SET_BUILD_NODES', new Array<BuildNodeModel>());
      }
      context.state.loadingNodes = false;
    },
    async fetchBuildSources(context: ActionContext<NodeModelStoreState, any>) {
      context.state.loadingSources = true;
      let client = createNodeModelClient();
      let { data, status } = await client.listAllSources(
        context.rootState.home.currentEngagement.id,
        context.state.selectedBuildBranch?.name ?? '',
        context.state.nodeModelLayerName
      );
      if (status == 200) {
        context.commit('SET_BUILD_SOURCES', data);
      } else {
        context.commit('SET_BUILD_SOURCES', new Array<SourceModel>());
      }
      context.state.loadingSources = false;
    },
    async fetchBuildTests(context: ActionContext<NodeModelStoreState, any>) {
      context.state.loadingTests = true;
      let client = createNodeModelClient();
      let { data, status } = await client.listTests(
        context.rootState.home.currentEngagement.id,
        context.state.selectedBuildBranch?.name ?? '',
        context.state.nodeModelLayerName,
        context.state.selectedBuildNode?.name ?? ''
      );
      if (status == 200) {
        context.commit('SET_BUILD_TESTS', data);
      } else {
        context.commit('SET_BUILD_TESTS', new Array<TestModel>());
      }
      context.state.loadingTests = false;
    },
    async fetchConfigNodes(context: ActionContext<NodeModelStoreState, any>) {
      context.state.loadingNodes = true;
      let client = createNodeModelClient();
      let { data, status } = await client.listNodes(
        context.rootState.home.currentEngagement.id,
        context.state.selectedConfigBranch?.name ?? '',
        context.state.nodeModelLayerName
      );
      if (status == 200) {
        context.commit('SET_CONFIG_NODES', data);
      } else {
        context.commit('SET_CONFIG_NODES', new Array<NodeModel>());
      }
      context.state.loadingNodes = false;
    },
    async fetchConfigCategories(
      context: ActionContext<NodeModelStoreState, any>
    ) {
      context.state.loadingCategories = true;
      let client = createNodeModelClient();
      let { data, status } = await client.listCategories(
        context.rootState.home.currentEngagement.id,
        context.state.selectedConfigBranch?.name ?? '',
        context.state.nodeModelLayerName,
        context.state.selectedConfigNode?.name ?? ''
      );
      if (status == 200) {
        context.commit('SET_CONFIG_CATEGORIES', data);
      } else {
        context.commit('SET_CONFIG_CATEGORIES', new Array<CategoryModel>());
      }
      context.state.loadingCategories = false;
    },
    async fetchConfigFields(context: ActionContext<NodeModelStoreState, any>) {
      let client = createNodeModelClient();
      let { data, status } = await client.listFields(
        context.rootState.home.currentEngagement.id,
        context.state.selectedConfigBranch?.name ?? '',
        context.state.nodeModelLayerName,
        context.state.selectedConfigNode?.name ?? ''
      );
      if (status == 200) {
        context.commit('SET_CONFIG_FIELDS', data);
      } else {
        context.commit('SET_CONFIG_FIELDS', new Array<FieldModel>());
      }
    },
    async fetchConfigProperties(
      context: ActionContext<NodeModelStoreState, any>
    ) {
      let client = createNodeModelClient();
      let { data, status } = await client.listProperties(
        context.rootState.home.currentEngagement.id,
        context.state.selectedConfigBranch?.name ?? '',
        context.state.nodeModelLayerName,
        context.state.selectedConfigNode?.name ?? ''
      );
      if (status == 200) {
        context.commit('SET_CONFIG_PROPERTIES', data);
      } else {
        context.commit('SET_CONFIG_PROPERTIES', new Array<PropertyModel>());
      }
    },
    async fetchConfigSources(context: ActionContext<NodeModelStoreState, any>) {
      context.state.loadingSources = true;
      let client = createNodeModelClient();
      let { data, status } = await client.listSources(
        context.rootState.home.currentEngagement.id,
        context.state.selectedConfigBranch?.name ?? '',
        context.state.nodeModelLayerName,
        context.state.selectedConfigNode?.name ?? ''
      );
      if (status == 200) {
        context.commit('SET_CONFIG_SOURCES', data);
      } else {
        context.commit('SET_CONFIG_SOURCES', new Array<SourceModel>());
      }
      context.state.loadingSources = false;
    },
    async fetchConfigTests(context: ActionContext<NodeModelStoreState, any>) {
      context.state.loadingTests = true;
      let client = createNodeModelClient();
      let { data, status } = await client.listTests(
        context.rootState.home.currentEngagement.id,
        context.state.selectedConfigBranch?.name ?? '',
        context.state.nodeModelLayerName,
        context.state.selectedConfigNode?.name ?? ''
      );
      if (status == 200) {
        context.commit('SET_CONFIG_TESTS', data);
      } else {
        context.commit('SET_CONFIG_TESTS', new Array<TestModel>());
      }
      context.state.loadingTests = false;
    },
    async fetchConfigCategoryDetail(
      context: ActionContext<NodeModelStoreState, any>
    ) {
      let client = createNodeModelClient();
      let { data, status } = await client.getCategoryDetail(
        context.rootState.home.currentEngagement.id,
        context.state.selectedConfigBranch?.name ?? '',
        context.state.nodeModelLayerName,
        context.state.selectedConfigNode?.name ?? '',
        context.state.selectedConfigCategory?.name ?? ''
      );
      if (status == 200) {
        context.commit('SET_CONFIG_CATEGORY_DETAIL', data);
      } else {
        context.commit('SET_CONFIG_CATEGORY_DETAIL', {} as CategoryDetailModel);
      }
    },
    async fetchConfigNodeDetail(
      context: ActionContext<NodeModelStoreState, any>
    ) {
      let client = createNodeModelClient();
      let { data, status } = await client.getNodeDetail(
        context.rootState.home.currentEngagement.id,
        context.state.selectedConfigBranch?.name ?? '',
        context.state.nodeModelLayerName,
        context.state.selectedConfigNode?.name ?? ''
      );
      if (status == 200) {
        context.commit('SET_CONFIG_NODE_DETAIL', data);
      } else {
        context.commit('SET_CONFIG_NODE_DETAIL', {} as NodeDetailModel);
      }
    },
    async fetchConfigSourceDetail(
      context: ActionContext<NodeModelStoreState, any>
    ) {
      let client = createNodeModelClient();
      let { data, status } = await client.getSourceDetail(
        context.rootState.home.currentEngagement.id,
        context.state.selectedConfigBranch?.name ?? '',
        context.state.nodeModelLayerName,
        context.state.selectedConfigNode?.name ?? '',
        context.state.selectedConfigSource?.name ?? ''
      );
      if (status == 200) {
        context.commit('SET_CONFIG_SOURCE_DETAIL', data);
      } else {
        context.commit('SET_CONFIG_SOURCE_DETAIL', {} as SourceDetailModel);
      }
    },
    async fetchConfigTestDetail(
      context: ActionContext<NodeModelStoreState, any>
    ) {
      let client = createNodeModelClient();
      let { data, status } = await client.getTestDetail(
        context.rootState.home.currentEngagement.id,
        context.state.selectedConfigBranch?.name ?? '',
        context.state.nodeModelLayerName,
        context.state.selectedConfigNode?.name ?? '',
        context.state.selectedConfigTest?.name ?? ''
      );
      if (status == 200) {
        context.commit('SET_CONFIG_TEST_DETAIL', data);
      } else {
        context.commit('SET_CONFIG_TEST_DETAIL', {} as TestDetailModel);
      }
    },
    async addNewBranch(
      context: ActionContext<NodeModelStoreState, any>,
      payload: CreateBranchModel
    ) {
      let client = createNodeModelClient();
      await client.createBranch(
        context.rootState.home.currentEngagement.id,
        payload
      );
    },
    async addNewCategory(
      context: ActionContext<NodeModelStoreState, any>,
      payload: CreateCategoryModel
    ) {
      let client = createNodeModelClient();
      await client.createCategory(
        context.rootState.home.currentEngagement.id,
        context.state.selectedConfigBranch?.name ?? '',
        context.state.nodeModelLayerName,
        context.state.selectedConfigNode?.name ?? '',
        payload
      );
    },
    async addNewNode(
      context: ActionContext<NodeModelStoreState, any>,
      payload: CreateNodeModel
    ) {
      let client = createNodeModelClient();
      await client.createNode(
        context.rootState.home.currentEngagement.id,
        context.state.selectedConfigBranch?.name ?? '',
        context.state.nodeModelLayerName,
        payload
      );
    },
    async addNewSource(
      context: ActionContext<NodeModelStoreState, any>,
      payload: CreateSourceModel
    ) {
      let client = createNodeModelClient();
      await client.createSource(
        context.rootState.home.currentEngagement.id,
        context.state.selectedConfigBranch?.name ?? '',
        context.state.nodeModelLayerName,
        context.state.selectedConfigNode?.name ?? '',
        payload
      );
    },
    async addNewTest(
      context: ActionContext<NodeModelStoreState, any>,
      payload: CreateTestModel
    ) {
      let client = createNodeModelClient();
      await client.createTest(
        context.rootState.home.currentEngagement.id,
        context.state.selectedConfigBranch?.name ?? '',
        context.state.nodeModelLayerName,
        context.state.selectedConfigNode?.name ?? '',
        payload
      );
    },
    async updateConfigCategoryDetail(
      context: ActionContext<NodeModelStoreState, any>
    ) {
      let client = createNodeModelClient();
      // We base64 encode the query because else it is sometimes incorrectly seen as a SQL injection / attack.
      let encodedQuery = encodeStringToBase64(
        context.state.configCategoryDetail.query
      );
      await client.updateCategoryDetails(
        context.rootState.home.currentEngagement.id,
        context.state.selectedConfigBranch?.name ?? '',
        context.state.nodeModelLayerName,
        context.state.selectedConfigNode?.name ?? '',
        context.state.selectedConfigCategory?.name ?? '',
        {
          query: encodedQuery
        } as UpdateCategoryDetailsModel
      );
    },
    async updateConfigNodeDetail(
      context: ActionContext<NodeModelStoreState, any>
    ) {
      let client = createNodeModelClient();
      // We base64 encode the query because else it is sometimes incorrectly seen as a SQL injection / attack.
      let encodedDefaultQuery = encodeStringToBase64(
        context.state.configNodeDetail.defaultQuery
      );
      await client.updateNode(
        context.rootState.home.currentEngagement.id,
        context.state.selectedConfigBranch?.name ?? '',
        context.state.nodeModelLayerName,
        context.state.selectedConfigNode?.name ?? '',
        {
          defaultQuery: encodedDefaultQuery
        } as UpdateNodeModel
      );
    },
    async updateConfigSourceDetail(
      context: ActionContext<NodeModelStoreState, any>
    ) {
      let client = createNodeModelClient();
      // We base64 encode the query because else it is sometimes incorrectly seen as a SQL injection / attack.
      let encodedQuery = encodeStringToBase64(
        context.state.configSourceDetail.query
      );
      await client.updateSourceDetails(
        context.rootState.home.currentEngagement.id,
        context.state.selectedConfigBranch?.name ?? '',
        context.state.nodeModelLayerName,
        context.state.selectedConfigNode?.name ?? '',
        context.state.configSourceDetail?.name ?? '',
        {
          query: encodedQuery
        } as UpdateSourceDetailsModel
      );
    },
    async updateConfigTestDetail(
      context: ActionContext<NodeModelStoreState, any>
    ) {
      let client = createNodeModelClient();
      // We base64 encode the query because else it is sometimes incorrectly seen as a SQL injection / attack.
      let encodedQuery = encodeStringToBase64(
        context.state.configTestDetail.query
      );
      await client.updateTest(
        context.rootState.home.currentEngagement.id,
        context.state.selectedConfigBranch?.name ?? '',
        context.state.nodeModelLayerName,
        context.state.selectedConfigNode?.name ?? '',
        context.state.selectedConfigTest?.name ?? '',
        {
          query: encodedQuery
        } as UpdateTestModel
      );
    },
    async updateConfigFields(
      context: ActionContext<NodeModelStoreState, any>,
      payload: Array<CreateFieldModel>
    ) {
      let client = createNodeModelClient();
      await client.setFields(
        context.rootState.home.currentEngagement.id,
        context.state.selectedConfigBranch?.name ?? '',
        context.state.nodeModelLayerName,
        context.state.selectedConfigNode?.name ?? '',
        payload
      );
    },
    async updateConfigProperties(
      context: ActionContext<NodeModelStoreState, any>,
      payload: Array<CreatePropertyModel>
    ) {
      let client = createNodeModelClient();
      await client.setProperties(
        context.rootState.home.currentEngagement.id,
        context.state.selectedConfigBranch?.name ?? '',
        context.state.nodeModelLayerName,
        context.state.selectedConfigNode?.name ?? '',
        payload
      );
    },
    async updateConfigCategories(
      context: ActionContext<NodeModelStoreState, any>,
      payload: Array<string>
    ) {
      let client = createNodeModelClient();
      await client.updateTest(
        context.rootState.home.currentEngagement.id,
        context.state.selectedConfigBranch?.name ?? '',
        context.state.nodeModelLayerName,
        context.state.selectedConfigNode?.name ?? '',
        context.state.selectedConfigTest?.name ?? '',
        {
          categoryNames: payload
        } as UpdateTestModel
      );
    },
    resetState({ commit }) {
      commit('RESET_STATE');
    }
  };
  mutations = {
    SET_BRANCHES(state: NodeModelStoreState, payload: Array<BranchModel>) {
      state.branches = payload;
    },
    SET_BUILD_CATEGORIES(
      state: NodeModelStoreState,
      payload: Array<CategoryModel>
    ) {
      state.buildCategories = payload;
    },
    SET_BUILD_NODES(
      state: NodeModelStoreState,
      payload: Array<BuildNodeModel>
    ) {
      state.buildNodes = payload;
    },
    SET_BUILD_SOURCES(state: NodeModelStoreState, payload: Array<SourceModel>) {
      state.buildSources = payload;
    },
    SET_BUILD_TESTS(state: NodeModelStoreState, payload: Array<TestModel>) {
      state.buildTests = payload.map((el: TestModel) => {
        return { ...el, active: false } as BuildTestModel;
      });
    },
    SET_CONFIG_CATEGORIES(
      state: NodeModelStoreState,
      payload: Array<CategoryModel>
    ) {
      state.configCategories = payload;
    },
    SET_CONFIG_NODES(state: NodeModelStoreState, payload: Array<NodeModel>) {
      state.configNodes = payload;
    },
    SET_CONFIG_FIELDS(state: NodeModelStoreState, payload: Array<FieldModel>) {
      state.configFields = payload;
    },
    SET_CONFIG_PROPERTIES(
      state: NodeModelStoreState,
      payload: Array<PropertyModel>
    ) {
      state.configProperties = payload;
    },
    SET_CONFIG_SOURCES(
      state: NodeModelStoreState,
      payload: Array<SourceModel>
    ) {
      state.configSources = payload;
    },
    SET_CONFIG_TESTS(state: NodeModelStoreState, payload: Array<TestModel>) {
      state.configTests = payload;
    },
    SET_CONFIG_CATEGORY_DETAIL(
      state: NodeModelStoreState,
      payload: CategoryDetailModel
    ) {
      state.configCategoryDetail = payload;
    },
    SET_CONFIG_NODE_DETAIL(
      state: NodeModelStoreState,
      payload: NodeDetailModel
    ) {
      state.configNodeDetail = payload;
    },
    SET_CONFIG_SOURCE_DETAIL(
      state: NodeModelStoreState,
      payload: SourceDetailModel
    ) {
      state.configSourceDetail = payload;
    },
    SET_CONFIG_TEST_DETAIL(
      state: NodeModelStoreState,
      payload: TestDetailModel
    ) {
      state.configTestDetail = payload;
    },
    SET_SELECTED_BUILD_BRANCH(
      state: NodeModelStoreState,
      payload: BranchModel
    ) {
      state.selectedBuildBranch = payload;
      state.selectedBuildCategory = {} as CategoryModel;
      state.selectedBuildSource = {} as SourceModel;
      state.selectedBuildNode = {} as NodeModel;
      state.selectedBuildTest = {} as TestModel;
      state.buildNodes = [];
      state.buildSources = [];
      state.buildTests = [];
    },
    SET_SELECTED_BUILD_CATEGORY(
      state: NodeModelStoreState,
      payload: CategoryModel
    ) {
      state.selectedBuildCategory = payload;
      state.buildNodes = [];
    },
    SET_SELECTED_BUILD_SOURCE(
      state: NodeModelStoreState,
      payload: SourceModel
    ) {
      state.selectedBuildSource = payload;
    },
    SET_SELECTED_BUILD_NODE(state: NodeModelStoreState, payload: NodeModel) {
      state.selectedBuildNode = payload;
      state.selectedBuildTest = {} as TestModel;
      state.buildTests = [];
    },
    SET_SELECTED_CONFIG_BRANCH(
      state: NodeModelStoreState,
      payload: BranchModel
    ) {
      state.selectedConfigBranch = payload;
      state.selectedConfigNode = {} as NodeModel;
      state.selectedConfigCategory = {} as CategoryModel;
      state.selectedConfigSource = {} as SourceModel;
      state.selectedConfigTest = {} as TestModel;
      state.configCategories = [];
      state.configFields = [];
      state.configProperties = [];
      state.configNodes = [];
      state.configSources = [];
      state.configTests = [];
      state.configNodeDetail = {
        defaultQuery: ''
      } as NodeDetailModel;
      state.configCategoryDetail = {
        query: ''
      } as CategoryDetailModel;
      state.configSourceDetail = {
        query: ''
      } as SourceDetailModel;
      state.configTestDetail = {
        categoryNames: [],
        query: ''
      } as TestDetailModel;
    },
    SET_SELECTED_CONFIG_NODE(state: NodeModelStoreState, payload: NodeModel) {
      state.selectedConfigNode = payload;
      state.selectedConfigSource = {} as SourceModel;
      state.selectedConfigCategory = {} as CategoryModel;
      state.selectedConfigTest = {} as TestModel;
      state.configSources = [];
      state.configCategories = [];
      state.configTests = [];
      state.configFields = [];
      state.configProperties = [];
      state.configNodeDetail = {
        defaultQuery: ''
      } as NodeDetailModel;
      state.configSourceDetail = {
        query: ''
      } as SourceDetailModel;
      state.configCategoryDetail = {
        query: ''
      } as CategoryDetailModel;
      state.configTestDetail = {
        categoryNames: [],
        query: ''
      } as TestDetailModel;
    },
    SET_SELECTED_CONFIG_CATEGORY(
      state: NodeModelStoreState,
      payload: CategoryModel
    ) {
      state.selectedConfigCategory = payload;
      state.configCategoryDetail = {
        query: ''
      } as CategoryDetailModel;
    },
    SET_SELECTED_CONFIG_SOURCE(
      state: NodeModelStoreState,
      payload: SourceModel
    ) {
      state.selectedConfigSource = payload;
      state.configSourceDetail = {
        query: ''
      } as SourceDetailModel;
    },
    SET_SELECTED_CONFIG_TEST(state: NodeModelStoreState, payload: TestModel) {
      state.selectedConfigTest = payload;
      state.configTestDetail = {
        categoryNames: [],
        query: ''
      } as TestDetailModel;
    }
  };
}
