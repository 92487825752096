import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "subtitle-2 mt-3" }
const _hoisted_2 = { class: "d-flex align-items-center" }
const _hoisted_3 = { class: "ml-auto mt--1 mb--1" }
const _hoisted_4 = { class: "ml-2 mr-2 d-flex align-items-center" }
const _hoisted_5 = ["src", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_header = _resolveComponent("page-header")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_base_table_header = _resolveComponent("base-table-header")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_base_toggle_button = _resolveComponent("base-toggle-button")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_pagination = _resolveComponent("pagination")!
  const _component_new_user_modal = _resolveComponent("new-user-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_page_header, {
          "main-title": _ctx.$t('users')
        }, {
          subheader: _withCtx(() => [
            _createElementVNode("h4", _hoisted_1, _toDisplayString(_ctx.$t('userDesc')), 1)
          ]),
          _: 1
        }, 8, ["main-title"])
      ]),
      _: 1
    }),
    _createVNode(_component_base_table_header, {
      "row-count": $setup.allUsersCount,
      "quantity-message": _ctx.$t('users'),
      "input-placeholder": _ctx.$t('searchUser'),
      class: "mt-6",
      onQueryChange: $setup.changeFilter
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_button, {
          type: "primary",
          class: "ml-2",
          onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.openNewUserModal()))
        }, {
          default: _withCtx(() => [
            _createElementVNode("h4", null, _toDisplayString('+ ' + _ctx.$t('newUser')), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["row-count", "quantity-message", "input-placeholder", "onQueryChange"]),
    _createVNode(_component_el_table, { data: $setup.filteredUsers }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          label: _ctx.$t('name'),
          "show-overflow-tooltip": "",
          prop: "name",
          sortable: "",
          width: "360"
        }, null, 8, ["label"]),
        _createVNode(_component_el_table_column, {
          label: _ctx.$t('email'),
          "show-overflow-tooltip": "",
          prop: "email"
        }, null, 8, ["label"]),
        _createVNode(_component_el_table_column, { width: "100" }, {
          default: _withCtx((props) => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_el_tooltip, {
                  placement: "top",
                  content: 
                props.row.disabled ? _ctx.$t('activateUser') : _ctx.$t('deactivateUser')
              
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_base_toggle_button, {
                      "model-value": !props.row.disabled,
                      "indicator-text": 
                  props.row.disabled
                    ? _ctx.$t('userActivated')
                    : _ctx.$t('userDeactivated')
                ,
                      "onUpdate:modelValue": ($event: any) => ($setup.toggleUser(props.row))
                    }, null, 8, ["model-value", "indicator-text", "onUpdate:modelValue"])
                  ]),
                  _: 2
                }, 1032, ["content"])
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_el_tooltip, {
                  placement: "top",
                  content: _ctx.$t('userDetails')
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("img", {
                      src: require(`@/assets/images/icons/new/edit.svg`),
                      height: "14",
                      width: "14",
                      class: "action-icon",
                      onClick: ($event: any) => ($setup.openUserDetails(props.row.email))
                    }, null, 8, _hoisted_5)
                  ]),
                  _: 2
                }, 1032, ["content"])
              ])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["data"]),
    _createVNode(_component_pagination, {
      count: $setup.allUsersCount,
      page: $setup.pageNumber,
      "page-size": $setup.pageSize,
      onChangePage: $setup.changePage
    }, null, 8, ["count", "page", "page-size", "onChangePage"]),
    _createVNode(_component_new_user_modal, {
      modelValue: $setup.showNewUserModal,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.showNewUserModal) = $event)),
      "existing-emails": $setup.allUsersEmails,
      "feedback-text": _ctx.$t('userInfoSaved'),
      onConfirm: $setup.createNewUser
    }, null, 8, ["modelValue", "existing-emails", "feedback-text", "onConfirm"])
  ], 64))
}