const appendUrlParam = (key: string, value: string) => {
  let url = new URL(window.location.href);
  if (url.searchParams.has(key)) {
    url.search = url.search.slice(0, url.search.indexOf(key) - 1);
  }
  url.searchParams.append(key, encodeURIComponent(value));
  localStorage.setItem('whereYouLeftOff', url.toString());
  history.pushState({}, '', url.toString());
};

const processUrlParams = async (
  // eslint-disable-next-line
  urlParams: Array<{ name: string; handler: Function }>
) => {
  const url = new URL(window.location.href);
  for (let urlParam of urlParams) {
    if (!url.searchParams.has(urlParam.name)) break;
    await urlParam.handler(
      decodeURIComponent(url.searchParams.get(urlParam.name) ?? '')
    );
  }
};

const objectToUrlParams = (inputObject: any): string => {
  Object.keys(inputObject).forEach(
    k => inputObject[k] !== 0 && !inputObject[k] && delete inputObject[k]
  );
  const serialised = Object.keys(inputObject)
    .map(
      key =>
        encodeURIComponent(key) + '=' + encodeURIComponent(inputObject[key])
    )
    .join('&');
  return serialised ? '?' + serialised : '';
};

export { appendUrlParam, processUrlParams, objectToUrlParams };
