import { ActionContext, Module } from 'vuex';
import {
  createAuthorisationClient,
  createStorageConfigurationApiClient,
  createStorageFileUploadApiClient,
  createStorageObjectsApiClient
} from '@/services/api';
import {
  CreateOrUpdateFolderDto,
  EngagementStorageConfiguration,
  StorageObject,
  UpdateStorageObjectDto
} from '@etp/etp-storage-client/axios';
import {
  ApplicationRoleAssignmentModel,
  UserRoleAssignmentModel
} from '@etp/etp-authorization-client/axios';
import { GetScopeForStoragePath } from '@/utils/scopeHelper';
import fileDownload from 'js-file-download';
import { IsBlankPath } from '@/utils/pathHelper';
import { UploadFileResult } from '@/models/uploadFileResult';
import { HttpStatusCode, RawAxiosRequestConfig } from 'axios';
import { roles_engagement_folderContributor } from '@/utils/variables';
import { formatFolders } from '@/utils/formatFolders';

export interface StorageState {
  configurations: Array<EngagementStorageConfiguration>;
  currentConfiguration: EngagementStorageConfiguration;
  currentFolder: StorageObject;
  currentFolderObjects: Array<StorageObject>;
  userHasFolderAccess: boolean;
}
export class StorageModule implements Module<StorageState, any> {
  namespaced = true;
  state = {
    configurations: new Array<EngagementStorageConfiguration>(),
    currentConfiguration: {} as EngagementStorageConfiguration,
    currentFolder: {} as StorageObject,
    currentFolderObjects: new Array<StorageObject>(),
    userHasFolderAccess: true
  };
  getters = {
    classificationStorageExists: (state: StorageState): boolean =>
      state.configurations.some(
        el => el.storagePurpose.toLocaleLowerCase() == 'classification'
      ),
    configurationStorageExists: (state: StorageState): boolean =>
      state.configurations.some(
        el => el.storagePurpose.toLocaleLowerCase() == 'configuration'
      ),
    getConfigurations: (
      state: StorageState
    ): Array<EngagementStorageConfiguration> => state.configurations,
    getCurrentConfiguration: (
      state: StorageState
    ): EngagementStorageConfiguration => state.currentConfiguration,
    getCurrentFolder: (state: StorageState): StorageObject =>
      state.currentFolder,
    getCurrentFolderObjects: (state: StorageState): Array<StorageObject> =>
      state.currentFolderObjects,
    getUserHasFolderAccess: (state: StorageState): boolean =>
      state.userHasFolderAccess,
    importStorageExists: (state: StorageState): boolean =>
      state.configurations.some(
        el => el.storagePurpose.toLocaleLowerCase() == 'import'
      )
  };
  actions = {
    async fetchConfigurations(context: ActionContext<StorageState, any>) {
      let client = createStorageConfigurationApiClient();
      try {
        const { data } = await client.listConfigurations(
          context.rootState.home.currentEngagement.id
        );
        context.commit('SET_CONFIGURATIONS', data);
      } catch (exception: any) {
        context.commit(
          'SET_CONFIGURATIONS',
          new Array<EngagementStorageConfiguration>()
        );
      }
    },
    async fetchCurrentConfiguration(
      context: ActionContext<StorageState, any>,
      storagePurpose: string
    ) {
      const configuration = context.state.configurations.find(
        c =>
          c.storagePurpose.toLocaleLowerCase() ==
          storagePurpose.toLocaleLowerCase()
      );
      if (configuration !== undefined) {
        context.commit('SET_CURRENT_CONFIGURATION', configuration);
        return;
      }

      let client = createStorageConfigurationApiClient();
      try {
        const { data } = await client.getConfiguration(
          context.rootState.home.currentEngagement.id,
          storagePurpose
        );
        context.commit('SET_CURRENT_CONFIGURATION', data);
      } catch (exception: any) {
        context.commit(
          'SET_CURRENT_CONFIGURATION',
          {} as EngagementStorageConfiguration
        );
      }
    },
    async fetchCurrentFolder(
      context: ActionContext<StorageState, any>,
      payload: {
        path: Array<string>;
      }
    ) {
      context.commit('SET_CURRENT_FOLDER', {} as StorageObject);
      if (IsBlankPath(payload.path)) {
        context.commit('SET_USER_HAS_FOLDER_ACCESS', true);
        return;
      }
      let client = createStorageObjectsApiClient();
      try {
        const { data } = await client.getFolder(
          context.rootState.home.currentEngagement.id,
          context.state.currentConfiguration.storagePurpose,
          payload.path.join('/')
        );
        context.commit('SET_CURRENT_FOLDER', data);
        context.commit('SET_USER_HAS_FOLDER_ACCESS', true);
      } catch (exception: any) {
        if (exception?.response?.status === 403) {
          context.commit('SET_USER_HAS_FOLDER_ACCESS', false);
        }
      }
    },
    async fetchCurrentFolderObjects(
      context: ActionContext<StorageState, any>,
      payload: {
        path: Array<string>;
      }
    ) {
      context.commit('SET_CURRENT_FOLDER_OBJECTS', [] as Array<StorageObject>);

      let client = createStorageObjectsApiClient();
      let path = payload.path.length
        ? payload.path.join('/')
        : process.env.NODE_ENV == 'development'
        ? ' ' // Ugly fix for development env since autogenerated client can't handle empty path
        : '';
      try {
        const { data } = await client.listObjects(
          context.rootState.home.currentEngagement.id,
          context.state.currentConfiguration.storagePurpose,
          path
        );
        context.commit('SET_CURRENT_FOLDER_OBJECTS', formatFolders(data));
        context.commit('SET_USER_HAS_FOLDER_ACCESS', true);
      } catch (exception: any) {
        if (exception?.response?.status === 403) {
          context.commit('SET_USER_HAS_FOLDER_ACCESS', false);
        }
      }
    },
    async createOrUpdateFolder(
      context: ActionContext<StorageState, any>,
      payload: {
        path: Array<string>;
        model: CreateOrUpdateFolderDto;
      }
    ) {
      let client = createStorageObjectsApiClient();
      await client.createOrUpdateFolder(
        context.rootState.home.currentEngagement.id,
        context.state.currentConfiguration.storagePurpose,
        payload.path.join('/'),
        payload.model
      );
    },
    async updateObject(
      context: ActionContext<StorageState, any>,
      payload: { path: Array<string>; model: UpdateStorageObjectDto }
    ) {
      let client = createStorageObjectsApiClient();
      await client.updateObject(
        context.rootState.home.currentEngagement.id,
        context.state.currentConfiguration.storagePurpose,
        payload.path.join('/'),
        payload.model
      );
    },
    async downloadFile(
      context: ActionContext<StorageState, any>,
      payload: { path: Array<string> }
    ) {
      let client = createStorageObjectsApiClient();
      const { data, status } = await client.downloadFile(
        context.rootState.home.currentEngagement.id,
        context.state.currentConfiguration.storagePurpose,
        payload.path.join('/'),
        '',
        {
          responseType: 'blob'
        } as RawAxiosRequestConfig
      );
      if (status == 200) {
        const fileName = payload.path[payload.path.length - 1];
        fileDownload(data, fileName);
      }
    },
    async uploadFile(
      context: ActionContext<StorageState, any>,
      payload: { path: Array<string>; file: globalThis.File }
    ): Promise<UploadFileResult> {
      try {
        let client = createStorageFileUploadApiClient();
        let uploadPath = [...payload.path];
        uploadPath.push(payload.file.name);
        const { data, status } = await client.uploadFile(
          context.rootState.home.currentEngagement.id,
          context.state.currentConfiguration.storagePurpose,
          uploadPath.join('/'),
          '',
          payload.file
        );
        return {
          httpStatusCode: status,
          resultingFileNames: [data.actualName]
        } as UploadFileResult;
      } catch (exception: any) {
        return {
          httpStatusCode:
            exception?.response?.status ?? HttpStatusCode.InternalServerError
        } as UploadFileResult;
      }
    },
    async addApplicationToStoragePath(
      context: ActionContext<StorageState, any>,
      payload: {
        path: Array<string>;
        objectId: string;
      }
    ) {
      const scope = GetScopeForStoragePath(
        context.rootState.home.currentEngagement.id,
        context.state.currentConfiguration.storagePurpose,
        payload.path
      );
      const client = createAuthorisationClient();
      await client.addApplicationRoleAssignment({
        objectId: payload.objectId,
        roleName: roles_engagement_folderContributor,
        scope: scope
      } as ApplicationRoleAssignmentModel);
    },
    async removeApplicationFromStoragePath(
      context: ActionContext<StorageState, any>,
      payload: {
        path: Array<string>;
        objectId: string;
      }
    ) {
      const scope = GetScopeForStoragePath(
        context.rootState.home.currentEngagement.id,
        context.state.currentConfiguration.storagePurpose,
        payload.path
      );
      const client = createAuthorisationClient();
      await client.removeApplicationRoleAssignment({
        objectId: payload.objectId,
        roleName: roles_engagement_folderContributor,
        scope: scope
      } as ApplicationRoleAssignmentModel);
    },
    async addUserToStoragePath(
      context: ActionContext<StorageState, any>,
      payload: {
        path: Array<string>;
        email: string;
      }
    ) {
      const scope = GetScopeForStoragePath(
        context.rootState.home.currentEngagement.id,
        context.state.currentConfiguration.storagePurpose,
        payload.path
      );
      const client = createAuthorisationClient();
      await client.addUserRoleAssignment({
        email: payload.email,
        roleName: roles_engagement_folderContributor,
        scope: scope
      } as UserRoleAssignmentModel);
    },
    async removeUserFromStoragePath(
      context: ActionContext<StorageState, any>,
      payload: {
        path: Array<string>;
        email: string;
      }
    ) {
      const scope = GetScopeForStoragePath(
        context.rootState.home.currentEngagement.id,
        context.state.currentConfiguration.storagePurpose,
        payload.path
      );
      const client = createAuthorisationClient();
      await client.removeUserRoleAssignment({
        email: payload.email,
        roleName: roles_engagement_folderContributor,
        scope: scope
      } as UserRoleAssignmentModel);
    },
    async deleteStorageObject(
      context: ActionContext<StorageState, any>,
      payload: { path: Array<string> }
    ) {
      let client = createStorageObjectsApiClient();
      await client.deleteObject(
        context.rootState.home.currentEngagement.id,
        context.state.currentConfiguration.storagePurpose,
        payload.path.join('/')
      );
    },
    async resetState(context: ActionContext<StorageState, any>) {
      context.commit('RESET_STATE');
    }
  };
  mutations = {
    SET_CONFIGURATIONS(
      state: StorageState,
      payload: Array<EngagementStorageConfiguration>
    ) {
      state.configurations = payload;
    },
    SET_CURRENT_CONFIGURATION(
      state: StorageState,
      payload: EngagementStorageConfiguration
    ) {
      state.currentConfiguration = payload;
    },
    SET_CURRENT_FOLDER(state: StorageState, payload: StorageObject) {
      state.currentFolder = payload;
    },
    SET_CURRENT_FOLDER_OBJECTS(
      state: StorageState,
      payload: Array<StorageObject>
    ) {
      state.currentFolderObjects = payload;
    },
    SET_USER_HAS_FOLDER_ACCESS(state: StorageState, payload: boolean) {
      state.userHasFolderAccess = payload;
    },
    RESET_STATE(state: StorageState) {
      const defaultState = new StorageModule().state;
      Object.assign(state, defaultState);
    }
  };
}

export default new StorageModule();
