{
  "yourOverview": "Here is your overview:",
  "filtering": "Flagged Records",
  "labeling": "New Joiner Report",
  "overview": "Home",
  "source": "Report",
  "sources": "Reports",
  "import": "Import Data",
  "modeling": "Data Integrity",
  "pipeline": "Activity",
  "pipelines": "Activities",
  "dashboardsDesc": "Here you can see dashboard reports for your data.",
  "dataJourney": "Data Processing",
  "analytics": "Payroll Instructions",
  "analyticalModel": "Create Payroll Instruction",
  "testing": "Configuration",
  "noDashboards": "Upload dashboard(s) and switch the Power BI Service on to view the dashboard(s).",
  "export": "Reports",
  "newFile": "new file",
  "runTabDesc": "Here you can choose which activities to run",
  "analyticalModelOverviewDesc": "Once data has been imported and flagged records have been reviewed, payroll instructions can be created here.",
  "analyticalModelRunOverviewDesc": "Once data has been imported and flagged records have been reviewed, payroll instructions can be created here.",
  "testingConfigDesc": "This is where configuration can be made to set up the payroll instructions.",
  "filteringStorageDesc": "Flagged records are generated for review. You can download the Excel file, provide feedback, and upload the Excel file back into the platform.",
  "labelingOverviewDesc": "This is where new joiner reports can be generated. These can then be sent to your payroll team to aid with the employee setup.",
  "folders": "Reports",
  "filters": "Files",
  "pipelineRuns": "Recent Activity",
  "importFiles": "Import Data",
  "runDataModel": "Run Data Integrity Checks",
  "runTests": "Run Payroll Instructions",
  "runPipeline": "Review Flagged Records",
  "manual": "User Guide",
  "feedback": "Raise Query",
  "403Desc": "Oh oh, it looks like you do not have access to this source. Please try again in 5 minutes. Then, in case you still cannot access this source, please reach out to your engagement team",
  "pipelineStatus": "Status",
  "nodesBuilding": "Selected nodes are being built"
}
