import {
  createAuthorisationClient,
  createConnectorsClient,
  createConnectorsConfigurationClient,
  createCostManagementClient,
  createDashboardingApiClient,
  createEInvoicingApiClient,
  createEngagementsApiClient,
  createGainBffClient,
  createNodeModelClient,
  createPipelinesApiClient,
  createRbacApiClient,
  createReportingConfigurationApiClient,
  createStorageConfigurationApiClient,
  createTaskSchedulerApiClient
} from '@/services/api';
import { GetScopeForEngagement } from '@/utils/scopeHelper';
import {
  ApplicationDetailsModel,
  UserDetailsModel
} from '@etp/etp-authorization-client/axios';
import {
  ConnectorConfigurationDto,
  IConnector
} from '@etp/etp-connectors-client';
import { AutopauseExclusionInput } from '@etp/etp-costsaving-client/axios';
import {
  CreateOrUpdateEngagementConfigurationDto as CreateOrUpdateDashboardConfigurationDto,
  EngagementConfiguration as EngagementDashboardConfiguration
} from '@etp/etp-dashboarding-client/axios';
import {
  EInvoicingConfiguration,
  EInvoicingConfigurationDetails,
  CreateOrUpdateEInvoicingProviderConfigurationDto
} from '@etp/etp-einvoicing-client/axios';
import {
  CreateEngagementDto,
  EngagementModel,
  EngagementModelPage,
  UpdateEngagementDto
} from '@etp/etp-engagements-client/axios';
import { ResourceType, ResourceModel } from '@etp/etp-infra-client';
import {
  CreateOrUpdateEngagementNodeModelResourceDto,
  EngagementConfiguration as EngagementNodeModelConfiguration
} from '@etp/etp-nodemodelgit-client/axios';
import {
  CreateOrUpdateEngagementPipelineResourceDto,
  EngagementConfiguration as EngagementPipelineConfiguration
} from '@etp/etp-pipelines-client/axios';
import {
  EngagementStorageConfiguration,
  UpdateConfigurationDto,
  UpdateConfigurationsDto
} from '@etp/etp-storage-client/axios';
import {
  CreateOrUpdateConfigurationDto as CreateOrUpdateTaskSchedulerConfigurationDto,
  EngagementConfiguration as EngagementTaskConfigurationModel
} from '@etp/etp-taskscheduler-client/axios';
import { ReportConfigurationDto } from '@etp/etp-reporting-client/axios';
import { SystemConfiguration as EngagementGainConfiguration } from '@etp/etp-gain-bff-client/axios';
import { ActionContext, Module } from 'vuex';
import { RBACAssignmentTask, SyncRBACModel } from '@etp/etp-rbac-client';
import { HttpStatusCode } from 'axios';

interface EngagementsState {
  loading: boolean;
  engagementApplications: Array<ApplicationDetailsModel>;
  engagementUsers: Array<UserDetailsModel>;
  activeEngagement: EngagementModel;
  allEngagements: Array<EngagementModel>;
  pagedEngagements: Array<EngagementModel>;
  engagementsCount: number;
  engagementsPage: number;
  engagementsPageSize: number;
  engagementTasks: Array<RBACAssignmentTask>;
  tenantEngagements: Array<EngagementModel>;
  connectorsConfiguration: Array<ConnectorConfigurationDto>;
  dashboardConfiguration: EngagementDashboardConfiguration;
  eInvoicingConfiguration: EInvoicingConfiguration;
  gainConfiguration: EngagementGainConfiguration;
  gainConfigurationHistory: Array<EngagementGainConfiguration>;
  nodeModelConfiguration: EngagementNodeModelConfiguration;
  pipelineConfiguration: EngagementPipelineConfiguration;
  storageConfigurations: Array<EngagementStorageConfiguration>;
  taskSchedulerConfiguration: EngagementTaskConfigurationModel;
  availableConnectors: Array<IConnector>;
  reportingConfiguration: ReportConfigurationDto;
}

class EngagementsModule implements Module<EngagementsState, any> {
  namespaced = true;
  state = {
    loading: false,
    engagementApplications: new Array<ApplicationDetailsModel>(),
    engagementUsers: new Array<UserDetailsModel>(),
    activeEngagement: {} as EngagementModel,
    allEngagements: [],
    pagedEngagements: [],
    engagementsCount: 0,
    engagementsPage: 1,
    engagementsPageSize: 10,
    engagementTasks: [],
    tenantEngagements: [],
    connectorsConfiguration: new Array<ConnectorConfigurationDto>(),
    dashboardConfiguration: {} as EngagementDashboardConfiguration,
    eInvoicingConfiguration: {} as EInvoicingConfiguration,
    gainConfiguration: {} as EngagementGainConfiguration,
    gainConfigurationHistory: new Array<EngagementGainConfiguration>(),
    nodeModelConfiguration: {} as EngagementNodeModelConfiguration,
    pipelineConfiguration: {} as EngagementPipelineConfiguration,
    storageConfigurations: new Array<EngagementStorageConfiguration>(),
    taskSchedulerConfiguration: {} as EngagementTaskConfigurationModel,
    reportingConfiguration: {} as ReportConfigurationDto,
    availableConnectors: new Array<IConnector>()
  };
  getters = {
    getAllEngagements: (state: EngagementsState): Array<EngagementModel> => {
      return state.allEngagements;
    },
    getPagedEngagements: (state: EngagementsState): Array<EngagementModel> => {
      return state.pagedEngagements;
    },
    getTenantEngagements: (state: EngagementsState): Array<EngagementModel> => {
      return state.tenantEngagements;
    },
    getActiveEngagement: (state: EngagementsState): EngagementModel => {
      return state.activeEngagement;
    },
    getActiveEngagementFeaturesArray: (state: EngagementsState): string[] => {
      try {
        return JSON.parse(state.activeEngagement.features);
      } catch (error) {
        return [];
      }
    },
    getEngagementsCount: (state: EngagementsState): number => {
      return state.engagementsCount;
    },
    getEngagementsPage: (state: EngagementsState): number => {
      return state.engagementsPage;
    },
    getEngagementsPageSize: (state: EngagementsState): number => {
      return state.engagementsPageSize;
    },
    getEngagementApplications: (
      state: EngagementsState
    ): Array<ApplicationDetailsModel> => {
      return state.engagementApplications;
    },
    getEngagementUsers: (state: EngagementsState): Array<UserDetailsModel> => {
      return state.engagementUsers;
    },
    getEngagementTasks: (
      state: EngagementsState
    ): Array<RBACAssignmentTask> => {
      return state.engagementTasks;
    },
    getAvailableConnectors: (state: EngagementsState): Array<IConnector> => {
      return state.availableConnectors;
    },
    getConnectorsConfiguration: (
      state: EngagementsState
    ): Array<ConnectorConfigurationDto> => {
      return state.connectorsConfiguration;
    },
    getDashboardConfiguration: (
      state: EngagementsState
    ): EngagementDashboardConfiguration => {
      return state.dashboardConfiguration;
    },
    getEInvoicingConfiguration: (
      state: EngagementsState
    ): EInvoicingConfiguration => {
      return state.eInvoicingConfiguration;
    },
    getGainConfiguration: (
      state: EngagementsState
    ): EngagementGainConfiguration => {
      return state.gainConfiguration;
    },
    getGainConfigurationHistory: (
      state: EngagementsState
    ): Array<EngagementGainConfiguration> => {
      return state.gainConfigurationHistory;
    },
    getNodeModelConfiguration: (
      state: EngagementsState
    ): EngagementNodeModelConfiguration => {
      return state.nodeModelConfiguration;
    },
    getPipelineConfiguration: (
      state: EngagementsState
    ): EngagementPipelineConfiguration => {
      return state.pipelineConfiguration;
    },
    getStorageConfigurations: (
      state: EngagementsState
    ): Array<EngagementStorageConfiguration> => {
      return state.storageConfigurations;
    },
    getTaskSchedulerConfiguration: (state: EngagementsState) => {
      return state.taskSchedulerConfiguration;
    },
    getReportingConfiguration: (state: EngagementsState) => {
      return state.reportingConfiguration;
    }
  };
  actions = {
    async fetchAllEngagements(context: ActionContext<EngagementsState, any>) {
      context.state.loading = true;
      let client = createEngagementsApiClient();
      const { data, status } = await client.listEngagements();
      if (status == 200) {
        context.commit('SET_ALL_ENGAGEMENTS', data);
      } else {
        context.commit('SET_ALL_ENGAGEMENTS', {} as EngagementModelPage);
      }
      context.state.loading = false;
    },
    async fetchPagedEngagements(
      context: ActionContext<EngagementsState, any>,
      filter: string
    ) {
      context.state.loading = true;
      let client = createEngagementsApiClient();
      const { data, status } = await client.listEngagements(
        undefined,
        context.state.engagementsPage,
        context.state.engagementsPageSize,
        filter
      );
      if (status == 200) {
        context.commit('SET_PAGED_ENGAGEMENTS', data);
      } else {
        context.commit('SET_PAGED_ENGAGEMENTS', {} as EngagementModelPage);
      }
      context.state.loading = false;
    },
    async fetchTenantEngagements(
      context: ActionContext<EngagementsState, any>,
      tenantId: string
    ) {
      context.state.loading = true;
      let client = createEngagementsApiClient();

      const { data, status } = await client.listEngagements(tenantId);

      if (status == 200) {
        context.commit('SET_TENANT_ENGAGEMENTS', data);
      } else {
        context.commit('SET_TENANT_ENGAGEMENTS', {} as EngagementModelPage);
      }
      context.state.loading = false;
    },
    async fetchEngagement(
      context: ActionContext<EngagementsState, any>,
      engagementId: string
    ) {
      context.state.loading = true;
      let client = createEngagementsApiClient();
      const { data, status } = await client.getEngagement(engagementId);
      if (status == 200) {
        context.commit('SET_ACTIVE_ENGAGEMENT', data);
      } else {
        context.commit('SET_ACTIVE_ENGAGEMENT', {} as EngagementModel);
      }
      context.state.loading = false;
    },
    async fetchEngagementApplications(
      context: ActionContext<EngagementsState, any>,
      engagementId: string
    ) {
      let client = createAuthorisationClient();
      let scope = GetScopeForEngagement(engagementId);
      const { data, status } = await client.getApplicationsByScope(scope, true);
      if (status == 200) {
        context.commit('SET_ENGAGEMENT_APPLICATIONS', data);
      } else {
        context.commit(
          'SET_ENGAGEMENT_APPLICATIONS',
          new Array<ApplicationDetailsModel>()
        );
      }
    },
    async fetchEngagementUsers(
      context: ActionContext<EngagementsState, any>,
      engagementId: string
    ) {
      let client = createAuthorisationClient();
      let scope = GetScopeForEngagement(engagementId);

      const { data, status } = await client.getUsersByScope(scope, true);

      if (status == 200) {
        context.commit('SET_ENGAGEMENT_USERS', data);
      } else {
        context.commit('SET_ENGAGEMENT_USERS', new Array<UserDetailsModel>());
      }
    },
    async fetchEngagementTasks(
      context: ActionContext<EngagementsState, any>,
      engagementId: string
    ) {
      let client = createRbacApiClient();

      const { data, status } = await client.listTasks(engagementId);

      if (status == 200) {
        context.commit('SET_ENGAGEMENT_TASKS', data);
      } else {
        context.commit('SET_ENGAGEMENT_TASKS', new Array<RBACAssignmentTask>());
      }
    },
    async createEngagement(
      context: ActionContext<EngagementsState, any>,
      payload: CreateEngagementDto
    ) {
      let client = createEngagementsApiClient();
      await client.createEngagement(payload);
    },
    async updateEngagement(
      context: ActionContext<EngagementsState, any>,
      payload: { engagementId: string; model: UpdateEngagementDto }
    ) {
      let client = createEngagementsApiClient();
      await client.updateEngagement(payload.engagementId, payload.model);
    },
    async fetchAvailableConnectors(
      context: ActionContext<EngagementsState, any>,
      engagementId: string
    ) {
      let client = createConnectorsClient();
      const { data, status } = await client.listConnectors(engagementId);
      if (status == 200) {
        context.commit('SET_AVAILABLE_CONNECTORS', data);
      } else {
        context.commit('SET_AVAILABLE_CONNECTORS', []);
      }
    },
    async fetchConnectorsConfiguration(
      context: ActionContext<EngagementsState, any>,
      engagementId: string
    ) {
      let client = createConnectorsConfigurationClient();
      const { data, status } = await client.listConfigurations(engagementId);
      if (status == 200) {
        context.commit('SET_CONNECTORS_CONFIGURATION', data);
      } else {
        context.commit('SET_CONNECTORS_CONFIGURATION', []);
      }
    },
    async fetchDashboardConfiguration(
      context: ActionContext<EngagementsState, any>,
      engagementId: string
    ) {
      let client = createDashboardingApiClient();
      const { data, status } = await client.getEngagementConfiguration(
        engagementId
      );
      if (status == 200) {
        context.commit('SET_DASHBOARD_CONFIGURATION', data);
      } else {
        context.commit(
          'SET_DASHBOARD_CONFIGURATION',
          {} as EngagementDashboardConfiguration
        );
      }
    },
    async fetchEInvoicingConfiguration(
      context: ActionContext<EngagementsState, any>,
      engagementId: string
    ) {
      let client = createEInvoicingApiClient();
      const { data, status } = await client.getEInvoicingConfiguration(
        engagementId
      );
      if (status == 200) {
        context.commit('SET_EINVOICING_CONFIGURATION', data);
      } else {
        context.commit(
          'SET_EINVOICING_CONFIGURATION',
          {} as EInvoicingConfiguration
        );
      }
    },
    async fetchGainConfiguration(
      context: ActionContext<EngagementsState, any>,
      engagementId: string
    ) {
      let client = createGainBffClient();
      const { data, status } = await client.getLatestSystemConfiguration(
        engagementId
      );
      if (status == 200) {
        context.commit('SET_GAIN_CONFIGURATION', data);
      } else {
        context.commit(
          'SET_GAIN_CONFIGURATION',
          {} as EngagementGainConfiguration
        );
      }
    },
    async fetchGainConfigurationHistory(
      context: ActionContext<EngagementsState, any>,
      engagementId: string
    ) {
      let client = createGainBffClient();
      const { data, status } = await client.listSystemConfigurations(
        engagementId
      );
      if (status == 200) {
        context.commit('SET_GAIN_CONFIGURATION_HISTORY', data);
      } else {
        context.commit(
          'SET_GAIN_CONFIGURATION_HISTORY',
          new Array<EngagementGainConfiguration>()
        );
      }
    },
    async fetchNodeModelConfiguration(
      context: ActionContext<EngagementsState, any>,
      engagementId: string
    ) {
      let client = createNodeModelClient();
      const { data, status } = await client.getEngagementConfiguration(
        engagementId
      );
      if (status == 200) {
        context.commit('SET_NODEMODEL_CONFIGURATION', data);
      } else {
        context.commit(
          'SET_NODEMODEL_CONFIGURATION',
          {} as EngagementNodeModelConfiguration
        );
      }
    },
    async fetchPipelineConfiguration(
      context: ActionContext<EngagementsState, any>,
      engagementId: string
    ) {
      let client = createPipelinesApiClient();
      const { data, status } = await client.getEngagementConfiguration(
        engagementId
      );
      if (status == 200) {
        context.commit('SET_PIPELINE_CONFIGURATION', data);
      } else {
        context.commit(
          'SET_PIPELINE_CONFIGURATION',
          {} as EngagementPipelineConfiguration
        );
      }
    },
    async fetchStorageConfigurations(
      context: ActionContext<EngagementsState, any>,
      engagementId: string
    ) {
      let client = createStorageConfigurationApiClient();
      const { data, status } = await client.listConfigurations(engagementId);
      if (status == 200) {
        context.commit('SET_STORAGE_CONFIGURATIONS', data);
      } else {
        context.commit('SET_STORAGE_CONFIGURATIONS', []);
      }
    },
    async fetchTaskSchedulerConfiguration(
      context: ActionContext<EngagementsState, any>,
      engagementId: string
    ) {
      let client = createTaskSchedulerApiClient();
      const { data, status } = await client.getConfiguration(engagementId);
      if (status == 200) {
        context.commit('SET_TASKSCHEDULER_CONFIGURATION', data);
      } else {
        context.commit(
          'SET_TASKSCHEDULER_CONFIGURATION',
          {} as EngagementTaskConfigurationModel
        );
      }
    },
    async fetchReportingConfiguration(
      context: ActionContext<EngagementsState, any>,
      engagementId: string
    ) {
      let client = createReportingConfigurationApiClient();
      const { data, status } = await client.getReportConfiguration(
        engagementId,
        engagementId
      );
      if (status == 200) {
        context.commit('SET_REPORTING_CONFIGURATION', data);
      } else {
        context.commit(
          'SET_REPORTING_CONFIGURATION',
          {} as ReportConfigurationDto
        );
      }
    },
    async createOrUpdateConnectorsConfiguration(
      context: ActionContext<EngagementsState, any>,
      payload: {
        engagementId: string;
        configuration: Array<ConnectorConfigurationDto>;
      }
    ) {
      let client = createConnectorsConfigurationClient();
      await client.updateConfigurations(
        payload.engagementId,
        payload.configuration
      );
    },
    async createOrUpdateDashboardConfiguration(
      context: ActionContext<EngagementsState, any>,
      payload: {
        engagementId: string;
        configuration: CreateOrUpdateDashboardConfigurationDto;
      }
    ) {
      let client = createDashboardingApiClient();
      await client.createOrUpdateEngagementConfiguration(
        payload.engagementId,
        payload.configuration
      );
    },
    async createOrUpdateEInvoicingConfiguration(
      context: ActionContext<EngagementsState, any>,
      payload: {
        engagementId: string;
        configuration: EInvoicingConfigurationDetails;
      }
    ) {
      let client = createEInvoicingApiClient();
      await client.createOrUpdateProviderConfiguration(payload.engagementId, {
        configuration: payload.configuration
      } as CreateOrUpdateEInvoicingProviderConfigurationDto);
    },
    async createOrUpdateGainConfiguration(
      context: ActionContext<EngagementsState, any>,
      payload: {
        engagementId: string;
        configuration: EngagementGainConfiguration;
      }
    ) {
      let client = createGainBffClient();
      await client.setSystemConfiguration(
        payload.engagementId,
        payload.configuration
      );
    },
    async createOrUpdateNodeModelConfiguration(
      context: ActionContext<EngagementsState, any>,
      payload: {
        engagementId: string;
        configuration: CreateOrUpdateEngagementNodeModelResourceDto;
      }
    ) {
      let client = createNodeModelClient();
      await client.createOrUpdateEngagementConfiguration(
        payload.engagementId,
        payload.configuration
      );
    },
    async createOrUpdatePipelineConfiguration(
      context: ActionContext<EngagementsState, any>,
      payload: {
        engagementId: string;
        model: CreateOrUpdateEngagementPipelineResourceDto;
      }
    ) {
      let client = createPipelinesApiClient();
      await client.createOrUpdateEngagementConfiguration(
        payload.engagementId,
        payload.model
      );
    },
    async updateStorageConfigurations(
      context: ActionContext<EngagementsState, any>,
      payload: {
        engagementId: string;
        configurations: Array<UpdateConfigurationDto>;
      }
    ): Promise<HttpStatusCode> {
      try {
        let client = createStorageConfigurationApiClient();
        const { status } = await client.setConfigurations(
          payload.engagementId,
          {
            configurations: payload.configurations
          } as UpdateConfigurationsDto
        );
        return status;
      } catch (exception: any) {
        return (
          exception?.response?.status ?? HttpStatusCode.InternalServerError
        );
      }
    },
    async createOrUpdateTaskSchedulerConfiguration(
      context: ActionContext<EngagementsState, any>,
      payload: {
        engagementId: string;
        configuration: CreateOrUpdateTaskSchedulerConfigurationDto;
      }
    ) {
      let client = createTaskSchedulerApiClient();
      await client.createOrUpdateConfiguration(
        payload.engagementId,
        payload.configuration
      );
    },
    async createOrUpdateReportingConfiguration(
      context: ActionContext<EngagementsState, any>,
      payload: {
        engagementId: string;
        configuration: ReportConfigurationDto;
      }
    ) {
      let client = createReportingConfigurationApiClient();
      if (!payload.configuration.configurationName) {
        payload.configuration.configurationName = payload.engagementId;
        await client.createReportConfiguration(
          payload.engagementId,
          payload.configuration
        );
      } else {
        await client.updateReportConfiguration(
          payload.engagementId,
          payload.configuration
        );
      }
    },
    async applyUserRbac(
      context: ActionContext<EngagementsState, any>,
      payload: { email: string }
    ) {
      let engagementId = context.state.activeEngagement.id;
      let tenantId = context.state.activeEngagement.tenantId;
      await context.dispatch(
        'tenants/fetchResources',
        { tenantId },
        { root: true }
      );
      if (
        context.rootState.tenants.resources.some(
          (r: ResourceModel) =>
            r.resourceType == ResourceType.SynapseDataWarehouse
        )
      ) {
        let costManagementClient = createCostManagementClient();
        await costManagementClient.setEngagementAutoPauseExclusion(
          engagementId,
          {
            hours: 1
          } as AutopauseExclusionInput
        );
      }
      let rbacClient = createRbacApiClient();
      let syncRBACModel: SyncRBACModel = {
        scopedEngagementIds: [engagementId]
      };
      await rbacClient.syncUserRBAC(payload.email, syncRBACModel);
    },
    async applyApplicationRbac(
      context: ActionContext<EngagementsState, any>,
      payload: { objectId: string }
    ) {
      let engagementId = context.state.activeEngagement.id;
      let tenantId = context.state.activeEngagement.tenantId;
      await context.dispatch(
        'tenants/fetchResources',
        { tenantId },
        { root: true }
      );
      if (
        context.rootState.tenants.resources.some(
          (r: ResourceModel) =>
            r.resourceType == ResourceType.SynapseDataWarehouse
        )
      ) {
        let costManagementClient = createCostManagementClient();
        await costManagementClient.setEngagementAutoPauseExclusion(
          engagementId,
          {
            hours: 1
          } as AutopauseExclusionInput
        );
      }
      let rbacClient = createRbacApiClient();
      let syncRBACModel: SyncRBACModel = {
        scopedEngagementIds: [engagementId]
      };
      await rbacClient.syncApplicationRBAC(payload.objectId, syncRBACModel);
    }
  };
  mutations = {
    SET_ALL_ENGAGEMENTS(state: EngagementsState, payload: EngagementModelPage) {
      state.allEngagements = payload.results ?? new Array<EngagementModel>();
    },
    SET_PAGED_ENGAGEMENTS(
      state: EngagementsState,
      payload: EngagementModelPage
    ) {
      state.engagementsCount = payload.meta?.totalItems ?? 0;
      state.pagedEngagements = payload.results ?? new Array<EngagementModel>();
    },
    SET_TENANT_ENGAGEMENTS(
      state: EngagementsState,
      payload: EngagementModelPage
    ) {
      state.tenantEngagements = payload.results ?? new Array<EngagementModel>();
    },
    SET_ACTIVE_ENGAGEMENT(state: EngagementsState, payload: EngagementModel) {
      state.activeEngagement = payload;
    },
    SET_ENGAGEMENTS_PAGE(state: EngagementsState, payload: number) {
      state.engagementsPage = payload;
    },
    SET_ENGAGEMENT_APPLICATIONS(
      state: EngagementsState,
      payload: Array<ApplicationDetailsModel>
    ) {
      state.engagementApplications = payload;
    },
    SET_ENGAGEMENT_USERS(
      state: EngagementsState,
      payload: Array<UserDetailsModel>
    ) {
      state.engagementUsers = payload;
    },
    SET_ENGAGEMENT_TASKS(
      state: EngagementsState,
      payload: Array<RBACAssignmentTask>
    ) {
      state.engagementTasks = payload;
    },
    SET_AVAILABLE_CONNECTORS(
      state: EngagementsState,
      payload: Array<IConnector>
    ) {
      state.availableConnectors = payload;
    },
    SET_CONNECTORS_CONFIGURATION(
      state: EngagementsState,
      payload: Array<ConnectorConfigurationDto>
    ) {
      state.connectorsConfiguration = payload;
    },
    SET_DASHBOARD_CONFIGURATION(
      state: EngagementsState,
      payload: EngagementDashboardConfiguration
    ) {
      state.dashboardConfiguration = payload;
    },
    SET_EINVOICING_CONFIGURATION(
      state: EngagementsState,
      payload: EInvoicingConfiguration
    ) {
      state.eInvoicingConfiguration = payload;
    },
    SET_GAIN_CONFIGURATION(
      state: EngagementsState,
      payload: EngagementGainConfiguration
    ) {
      state.gainConfiguration = payload;
    },
    SET_GAIN_CONFIGURATION_HISTORY(
      state: EngagementsState,
      payload: Array<EngagementGainConfiguration>
    ) {
      state.gainConfigurationHistory = payload;
    },
    SET_NODEMODEL_CONFIGURATION(
      state: EngagementsState,
      payload: EngagementNodeModelConfiguration
    ) {
      state.nodeModelConfiguration = payload;
    },
    SET_PIPELINE_CONFIGURATION(
      state: EngagementsState,
      payload: EngagementPipelineConfiguration
    ) {
      state.pipelineConfiguration = payload;
    },
    SET_STORAGE_CONFIGURATIONS(
      state: EngagementsState,
      payload: Array<EngagementStorageConfiguration>
    ) {
      state.storageConfigurations = payload;
    },
    SET_TASKSCHEDULER_CONFIGURATION(
      state: EngagementsState,
      payload: EngagementTaskConfigurationModel
    ) {
      state.taskSchedulerConfiguration = payload;
    },
    SET_REPORTING_CONFIGURATION(
      state: EngagementsState,
      payload: ReportConfigurationDto
    ) {
      state.reportingConfiguration = payload;
    }
  };
}

export default new EngagementsModule();
