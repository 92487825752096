<template>
  <el-row :gutter="spacingWidthHeaderComponents">
    <el-col>
      <page-header :main-title="$t('profile')">
        <template #subheader>
          <h4 class="d-inline-block subtitle-2 mt-3">
            {{ $t('profileDesc') }}
          </h4>
        </template>
      </page-header>
      <el-row :gutter="50" class="mt-6">
        <el-col :span="4.5">
          <base-card no-header>
            <template #body>
              <img
                v-if="profilePicture"
                :src="profilePicture"
                class="profile-picture"
              />
              <img
                v-else
                :src="defaultProfilePicture"
                class="profile-picture"
              />
              <h3 class="mt-2">{{ $t('myInfo') }}</h3>
              <div class="user-info-container mt-1">
                <el-row class="user-info-row">
                  <el-col :span="6">
                    <h4 class="d-inline-block">{{ $t('name') }}:</h4>
                  </el-col>
                  <el-col :span="18">
                    <h4 class="d-inline-block">
                      {{ currentUser.displayName }}
                    </h4>
                  </el-col>
                </el-row>
                <el-row class="user-info-row">
                  <el-col :span="6">
                    <h4 class="d-inline-block">{{ $t('email') }}:</h4>
                  </el-col>
                  <el-col :span="18">
                    <h4 class="d-inline-block">{{ currentUser.email }}</h4>
                  </el-col>
                </el-row>
              </div>
            </template>
          </base-card>
          <base-card no-header class="mt-2">
            <template #body>
              <h3 class="mb-1">{{ $t('profileSettings') }}</h3>
              <el-form>
                <el-form-item :label="$t('language')" class="mb-0">
                  <el-select
                    v-model="$i18n.locale"
                    size="small"
                    @change="updateLanguage"
                  >
                    <el-option
                      v-for="(language, i) in $i18n.availableLocales"
                      :key="`Lang${i}`"
                      :value="language"
                      class="d-flex align-items-center"
                    >
                      <h3 class="space-around">
                        {{ language }}
                      </h3>
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item :label="$t('template')" class="mb-0">
                  <el-select v-model="selectedTemplate" size="small">
                    <el-option
                      v-for="(template, i) in templates"
                      :key="i"
                      :value="template"
                      class="d-flex align-items-center"
                    >
                      <h3 class="space-around">
                        {{ template }}
                      </h3>
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-form>
            </template>
          </base-card>
        </el-col>
        <el-col :span="17">
          <base-card>
            <template #header>
              <h2>{{ $t('yourEngagements') }}</h2>
              <h4 class="d-inline-block">
                {{ $t('yourEngagementsDesc') }}
              </h4>
            </template>
            <template #body>
              <el-table :data="engagements" :empty-text="$t('noEngagement')">
                <el-table-column :label="$t('name')">
                  <template #default="scope">
                    <h4
                      class="your-group-table"
                      @click="changeEngagement(scope.row)"
                    >
                      {{ scope.row.name }}
                    </h4>
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </base-card>
        </el-col>
      </el-row>
    </el-col>
  </el-row>
</template>

<script lang="ts">
import {
  ElRow,
  ElCol,
  ElForm,
  ElFormItem,
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption
} from 'element-plus';

import BaseCard from '@/components/panels/BaseCard.vue';
import PageHeader from '@/components/misc/PageHeader.vue';

import { computed, onMounted, ComputedRef } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import { PicureSizes } from '@/services/web-api/enums/pictureSizes';
import { spacingWidthHeaderComponents } from '@/utils/variables';
import { EngagementModel } from '@etp/etp-engagements-client/axios';
import { UserViewModel } from '@etp/etp-profile-client/axios';

export default {
  name: 'ProfilePage',
  components: {
    ElRow,
    ElCol,
    ElForm,
    ElFormItem,
    BaseCard,
    ElTable,
    ElTableColumn,
    ElSelect,
    ElOption,
    PageHeader
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const defaultProfilePicture = '@/assets/images/icons/user-placeholder.png';

    const currentUser: ComputedRef<UserViewModel> = computed(
      () => store.getters['base/getCurrentUser']
    );
    const profilePicture: ComputedRef<string> = computed(
      () => store.getters['base/getProfilePicture'] as string
    );
    let engagements: ComputedRef<Array<EngagementModel>> = computed(
      () => store.getters['home/getAvailableEngagements']
    );

    const updateLanguage = async (language: string) => {
      await store.dispatch('base/updateUserLanguage', language);
      await store.dispatch('base/fetchCurrentUser');
    };

    const selectedTemplate = computed({
      get: () => store.getters['base/getCurrentUser'].templateName,
      set: async newTemplate => {
        await store.dispatch('base/updateUserTemplate', newTemplate);
        await store.dispatch('base/fetchCurrentUser');
      }
    });

    const templates: string[] = ['Default', 'Deloitte'];

    const resetStores = () => {
      store.dispatch('analytical/resetState');
      store.dispatch('filtering/resetState');
      store.dispatch('labeling/resetState');
      store.dispatch('model/resetState');
      store.dispatch('testing/resetState');
      store.dispatch('storage/resetState');
      store.dispatch('import/resetState');
      store.dispatch('userPermissions/resetEngagementsTenantsState');
    };

    const changeEngagement = async (engagement: EngagementModel) => {
      await store.dispatch('home/fetchCurrentEngagement', engagement.id);
      resetStores();
      router.push({ name: 'Overview' });
    };

    onMounted(async () => {
      store.dispatch('base/fetchProfilePicture', PicureSizes.large);
      await store.dispatch('home/fetchAvailableEngagements');
    });

    return {
      currentUser,
      profilePicture,
      defaultProfilePicture,
      spacingWidthHeaderComponents,
      engagements,
      changeEngagement,
      updateLanguage,
      selectedTemplate,
      templates
    };
  }
};
</script>

<style scoped lang="scss">
.user-info-row {
  border-bottom: 0.5px solid rgb(226, 226, 226);
}
.user-info-roles {
  display: inline-flex;
  flex-direction: column;
}
.user-info-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.your-group-table {
  cursor: pointer;
}
</style>
