import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "d-block" }
const _hoisted_2 = { class: "d-flex" }
const _hoisted_3 = { class: "main-title" }
const _hoisted_4 = {
  key: 0,
  class: "ml-auto"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_under_construction_tag = _resolveComponent("under-construction-tag")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, _toDisplayString($props.mainTitle), 1),
      ($props.underConstruction)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_under_construction_tag)
          ]))
        : _createCommentVNode("", true)
    ]),
    _renderSlot(_ctx.$slots, "tabs"),
    _renderSlot(_ctx.$slots, "subheader")
  ]))
}