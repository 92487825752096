import config from '@/config/config';
import { StorageObjectType } from '@etp/etp-storage-client/axios';

const CreateSystemName = (displayName: string): string => {
  return displayName
    .replace(/[^0-9a-z ]/gi, '')
    .trim()
    .replace('  ', ' ')
    .replace(' ', '-')
    .toLowerCase();
};

const EditMetadataSupported = (
  currentPath: Array<string>,
  targetObjectType: StorageObjectType
): boolean => {
  const cloudProvider = config.cloudProvider.toLowerCase();
  if (cloudProvider == 'aws') return true;
  if (!currentPath.length && targetObjectType === StorageObjectType.Folder)
    return true;
  if (currentPath.length && targetObjectType === StorageObjectType.File)
    return true;
  return false;
};

const FileUploadSupported = (path: Array<string>): boolean => {
  const cloudProvider = config.cloudProvider.toLowerCase();
  if (cloudProvider == 'azure' && path.length === 0) return false;
  return true;
};

const FolderCreationVirtual = (currentPath: Array<string>): boolean => {
  const cloudProvider = config.cloudProvider.toLowerCase();
  return cloudProvider === 'azure' && !!currentPath.length;
};

const IsBlankPath = (path: Array<string>): boolean => {
  return !path || !path.length || !path[0].trim();
};

export {
  CreateSystemName,
  EditMetadataSupported,
  FileUploadSupported,
  FolderCreationVirtual,
  IsBlankPath
};
