<template>
  <div>
    <el-row>
      <el-col :span="24">
        <page-header :main-title="`${$t('userDetails')} - ${user.name}`">
          <template #tabs>
            <div class="d-flex mt-2">
              <router-link
                v-slot="{ isExactActive }"
                :to="{ name: 'UserProfile' }"
              >
                <base-tab :active="isExactActive">
                  <h4>{{ $t('profile') }}</h4>
                </base-tab>
              </router-link>
              <router-link
                v-slot="{ isExactActive }"
                :to="{ name: 'UserRoles' }"
              >
                <base-tab :active="isExactActive">
                  <h4>{{ $t('roles') }}</h4>
                </base-tab>
              </router-link>
              <div class="horizontal-line mt-2">
                <hr />
              </div>
            </div>
          </template>
        </page-header>
      </el-col>
    </el-row>
    <div class="mt-6">
      <router-view />
    </div>
  </div>
</template>
<script lang="ts">
import { computed, ComputedRef, onMounted } from 'vue';
import { useStore } from 'vuex';
import PageHeader from '@/components/misc/PageHeader.vue';
import { useRouter } from 'vue-router';
import { ElRow, ElCol } from 'element-plus';
import BaseTab from '@/components/tabs/BaseTab.vue';
import { UserModel } from '@etp/etp-authorization-client/axios';

export default {
  name: 'UserDetails',
  components: {
    PageHeader,
    ElRow,
    ElCol,
    BaseTab
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    const user: ComputedRef<UserModel> = computed(
      () => store.getters['users/getAuthorizationUser']
    );

    onMounted(async () => {
      const email = router.currentRoute.value.params.email as string;
      await store.dispatch('users/fetchAuthorizationUser', email);
    });

    return {
      user
    };
  }
};
</script>
<style lang="scss" scoped>
.router {
  display: flex;
}
</style>
