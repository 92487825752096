<template>
  <base-modal
    :modal-title="$t('tenant')"
    :dialog-visible="modelValue"
    :confirm-text="$t('save')"
    width="400px"
    @close="close()"
    @confirm="confirm()"
  >
    <template #content>
      <el-form
        ref="formRef"
        :model="form"
        :rules="formRules"
        label-position="top"
      >
        <el-form-item :label="$t('name')" prop="name">
          <el-input v-model="form.name" size="small" />
        </el-form-item>
        <el-form-item :label="$t('removable')" prop="removable">
          <el-radio-group v-model="form.removable">
            <el-radio-button :label="true">
              {{ $t('yes') }}
            </el-radio-button>
            <el-radio-button :label="false">
              {{ $t('no') }}
            </el-radio-button>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </template>
  </base-modal>
</template>
<script lang="ts">
import BaseModal from '@/components/modals/BaseModal.vue';
import {
  ElForm,
  ElFormItem,
  ElInput,
  ElMessage,
  ElRadioGroup,
  ElRadioButton
} from 'element-plus';
import { reactive, ref } from 'vue';
import { mustNotContainSpecialChars } from '@/utils/formValidators';
import i18n from '@/i18n';
import { CloudProvider, CreateTenantModel } from '@etp/etp-infra-client';

export default {
  name: 'NewTenantModal',
  components: {
    BaseModal,
    ElInput,
    ElForm,
    ElFormItem,
    ElRadioButton,
    ElRadioGroup
  },
  props: {
    modelValue: { type: Boolean, default: false },
    feedbackText: { type: String, default: i18n.global.t('saved') }
  },
  emits: ['close', 'confirm', 'update:model-value'],
  setup(props, { emit }) {
    const form = reactive({
      name: '',
      cloudConfiguration: '',
      cloudProvider: CloudProvider.MicrosoftAzure,
      removable: false
    });
    const formRules = reactive({
      name: [
        {
          required: true,
          message: i18n.global.t('thisFieldIsRequired'),
          trigger: 'blur'
        },
        {
          min: 3,
          message: i18n.global
            .t('pleaseEnterNumCharacters')
            .replace('[num]', '3'),
          trigger: 'blur'
        },
        {
          validator: mustNotContainSpecialChars,
          trigger: 'blur'
        }
      ]
    });
    const formRef = ref<HTMLFormElement>();
    const confirm = () => {
      formRef.value?.validate((valid: boolean) => {
        if (valid) {
          emit('confirm', {
            name: form.name,
            cloudConfiguration: form.cloudConfiguration,
            cloudProvider: form.cloudProvider,
            isRemovable: form.removable
          } as CreateTenantModel);
          ElMessage({
            showClose: true,
            message: props.feedbackText
          });
        }
      });
    };
    const close = () => {
      form.name = '';
      form.cloudConfiguration = '';
      form.cloudProvider = CloudProvider.MicrosoftAzure;
      form.removable = false;
      emit('update:model-value', false);
    };

    return {
      form,
      formRules,
      formRef,
      confirm,
      close
    };
  }
};
</script>
<style scoped lang="scss"></style>
