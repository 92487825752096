<template>
  <div
    id="dashboarding-report"
    :class="
      'dashboarding-details-container ' + [fullScreen ? 'full-screen' : '']
    "
  ></div>
</template>

<script lang="ts">
import { computed, ComputedRef, onMounted, onBeforeUnmount } from 'vue';
import { useStore } from 'vuex';
import router from '@/router';
import * as pbi from 'powerbi-client';
import { EmbeddedDashboard } from '@etp/etp-dashboarding-client/axios';
import { IEmbedConfigurationBase } from 'embed';

export default {
  name: 'DashboardDetails',
  setup() {
    const store = useStore();
    let reportId: string;
    const fullScreen = computed(() => store.getters['base/getFullScreen']);

    const fetchEmbeddingInfo = async () => {
      await store.dispatch('dashboard/fetchEmbeddedDashboard', reportId);
    };

    let embedInfo: ComputedRef<EmbeddedDashboard> = computed(
      () => store.getters['dashboard/getEmbeddedDashboard']
    );

    let getConfig = (embedInfo: EmbeddedDashboard) => {
      return {
        type: 'report',
        tokenType: pbi.models.TokenType.Embed,
        viewMode: pbi.models.ViewMode.View,
        permissions: pbi.models.Permissions.Read,
        pageView: 'fitToWidth',
        accessToken: embedInfo.accessToken,
        embedUrl: embedInfo.embedUrl,
        id: reportId,
        settings: {}
      } as IEmbedConfigurationBase;
    };

    let showReport = async () => {
      let embedContainer = document.getElementById(
        'dashboarding-report'
      ) as HTMLElement;

      let powerBi = new pbi.service.Service(
        pbi.factories.hpmFactory,
        pbi.factories.wpmpFactory,
        pbi.factories.routerFactory
      );

      let config = getConfig(embedInfo.value);

      powerBi.embed(embedContainer, config);
    };

    onMounted(async () => {
      reportId = router.currentRoute.value.params.reportId as string;
      let searchParams = new URLSearchParams(window.location.search);
      store.commit('base/SET_FULL_SCREEN', searchParams.has('full-screen'));
      await fetchEmbeddingInfo();
      await showReport();
    });

    onBeforeUnmount(() => {
      store.commit('base/SET_FULL_SCREEN', false);
    });

    return { fullScreen };
  }
};
</script>

<style scoped lang="scss">
.dashboarding-details-container {
  position: absolute;
  height: 85%;
  width: 80%;
  &.full-screen {
    position: relative;
    width: 100%;
    min-height: inherit;
  }
}
</style>
