<template>
  <base-modal
    :modal-title="$t('addConfiguration')"
    :dialog-visible="modelValue"
    :confirm-text="$t('save')"
    width="400px"
    @close="close"
    @confirm="confirm"
  >
    <template #content>
      <el-form
        ref="formRef"
        :model="form"
        :rules="formRules"
        label-position="top"
      >
        <el-form-item :label="$t('connector')" prop="connector">
          <el-select v-model="form.connector" filterable allow-create>
            <el-option
              v-for="item in availableConnectors"
              :key="item.key"
              :label="item.label"
              :value="item.key"
            />
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('environment')" prop="environment">
          <el-input v-model="form.environment" size="small" />
        </el-form-item>
      </el-form>
    </template>
  </base-modal>
</template>
<script lang="ts">
import BaseModal from '@/components/modals/BaseModal.vue';
import { ElForm, ElFormItem, ElInput, ElOption, ElSelect } from 'element-plus';
import { reactive, ref } from 'vue';
import i18n from '@/i18n';
import {
  ConnectorConfigurationDto,
  IConnector
} from '@etp/etp-connectors-client';

export default {
  name: 'NewConnectorConfigurationModal',
  components: {
    BaseModal,
    ElInput,
    ElForm,
    ElFormItem,
    ElOption,
    ElSelect
  },
  props: {
    modelValue: { type: Boolean, default: false },
    availableConnectors: {
      type: Array as () => Array<IConnector>,
      default: () => new Array<IConnector>()
    }
  },
  emits: ['close', 'confirm', 'update:model-value'],
  setup(props, { emit }) {
    const form = reactive({
      connector: '',
      environment: ''
    });
    const formRef = ref<HTMLFormElement>();
    const formRules = reactive({
      service: [
        {
          required: true,
          message: i18n.global.t('thisFieldIsRequired'),
          trigger: 'blur'
        },
        {
          min: 3,
          message: i18n.global
            .t('pleaseEnterNumCharacters')
            .replace('[num]', '3'),
          trigger: 'blur'
        }
      ],
      environment: {
        required: true,
        message: i18n.global.t('thisFieldIsRequired'),
        trigger: 'blur'
      }
    });

    const confirm = () => {
      formRef.value?.validate((valid: boolean) => {
        if (valid) {
          emit('confirm', {
            connector: form.connector,
            environment: form.environment,
            configuration: ''
          } as ConnectorConfigurationDto);
        }
      });
    };
    const close = () => {
      form.connector = '';
      form.environment = '';
      emit('update:model-value', false);
    };

    return {
      form,
      formRules,
      formRef,
      confirm,
      close
    };
  }
};
</script>
<style scoped lang="scss"></style>
