<template>
  <base-card no-header class="width-100">
    <template #body>
      <el-row>
        <el-col :span="9">
          <h3>{{ resource.name }}</h3>
          <span class="body-1">
            {{ $t(Object.keys(ResourceType)[resource.resourceType]) }}
          </span>
          <div class="body-1">
            {{ resource.tag }}
          </div>
        </el-col>
        <el-col :span="9">
          <ai-services-configuration-form
            v-if="
              includesResourceType(
                [ResourceType.AiServices],
                resource.resourceType
              )
            "
            :configuration="resource.configuration"
            :managed-identity-names="
              resources
                .filter(el => el.resourceType == ResourceType.ManagedIdentity)
                .map(el => el.name)
            "
            @update-configuration="handleUpdate"
          />
          <container-registry-configuration-form
            v-if="
              includesResourceType(
                [ResourceType.ContainerRegistry],
                resource.resourceType
              )
            "
            :configuration="resource.configuration"
            :managed-identity-names="
              resources
                .filter(el => el.resourceType == ResourceType.ManagedIdentity)
                .map(el => el.name)
            "
            @update-configuration="handleUpdate"
          />
          <databricks-workspace-configuration-form
            v-if="
              includesResourceType(
                [ResourceType.DatabricksWorkspace],
                resource.resourceType
              )
            "
            :configuration="resource.configuration"
            @update-configuration="handleUpdate"
          />
          <data-factory-configuration-form
            v-if="
              includesResourceType(
                [ResourceType.DataFactory],
                resource.resourceType
              )
            "
            :configuration="resource.configuration"
            :managed-identity-names="
              resources
                .filter(el => el.resourceType == ResourceType.ManagedIdentity)
                .map(el => el.name)
            "
            :storage-accounts="
              resources.filter(
                el =>
                  el.resourceType === ResourceType.StorageAccount ||
                  el.resourceType === ResourceType.DataLakeStorage
              )
            "
            @update-configuration="handleUpdate"
          />
          <event-grid-topic-configuration-form
            v-else-if="
              includesResourceType(
                [ResourceType.EventGridTopic],
                resource.resourceType
              )
            "
            :configuration="resource.configuration"
            @update-configuration="handleUpdate"
          />
          <function-app-configuration-form
            v-else-if="
              includesResourceType(
                [
                  ResourceType.FunctionAppDotnet,
                  ResourceType.FunctionAppPython
                ],
                resource.resourceType
              )
            "
            :configuration="resource.configuration"
            :managed-identity-names="
              resources
                .filter(el => el.resourceType == ResourceType.ManagedIdentity)
                .map(el => el.name)
            "
            :storage-names="
              resources
                .filter(
                  el =>
                    el.resourceType === ResourceType.StorageAccount ||
                    el.resourceType === ResourceType.DataLakeStorage
                )
                .map(el => el.name)
            "
            @update-configuration="handleUpdate"
          />
          <key-vault-configuration-form
            v-else-if="
              includesResourceType(
                [ResourceType.KeyVault],
                resource.resourceType
              )
            "
            :configuration="resource.configuration"
            :managed-identity-names="
              resources
                .filter(el => el.resourceType == ResourceType.ManagedIdentity)
                .map(el => el.name)
            "
            @update-configuration="handleUpdate"
          />
          <machine-learning-workspace-configuration-form
            v-else-if="
              includesResourceType(
                [ResourceType.MachineLearningWorkspace],
                resource.resourceType
              )
            "
            :configuration="resource.configuration"
            :managed-identity-names="
              resources
                .filter(el => el.resourceType == ResourceType.ManagedIdentity)
                .map(el => el.name)
            "
            :storage-account-names="
              resources
                .filter(el => el.resourceType == ResourceType.StorageAccount)
                .map(el => el.name)
            "
            :key-vault-names="
              resources
                .filter(el => el.resourceType == ResourceType.KeyVault)
                .map(el => el.name)
            "
            :container-registry-names="
              resources
                .filter(el => el.resourceType == ResourceType.ContainerRegistry)
                .map(el => el.name)
            "
            @update-configuration="handleUpdate"
          />
          <power-bi-workspace-configuration-form
            v-else-if="
              includesResourceType(
                [ResourceType.PowerBIWorkspace],
                resource.resourceType
              )
            "
            :configuration="resource.configuration"
            :power-bi-capacity-names="
              resources
                .filter(el => el.resourceType == ResourceType.PowerBIEmbedded)
                .map(el => el.name)
            "
            @update-configuration="handleUpdate"
          />
          <sql-database-configuration-form
            v-else-if="
              includesResourceType(
                [ResourceType.SqlDatabase, ResourceType.SynapseDataWarehouse],
                resource.resourceType
              )
            "
            :configuration="resource.configuration"
            :sql-server-names="
              resources
                .filter(el => el.resourceType == ResourceType.SqlServer)
                .map(el => el.name)
            "
            @update-configuration="handleUpdate"
          />
          <sql-server-configuration-form
            v-else-if="
              includesResourceType(
                [ResourceType.SqlServer],
                resource.resourceType
              )
            "
            :configuration="resource.configuration"
            :managed-identity-names="
              resources
                .filter(el => el.resourceType == ResourceType.ManagedIdentity)
                .map(el => el.name)
            "
            @update-configuration="handleUpdate"
          />
          <storage-account-configuration-form
            v-else-if="
              includesResourceType(
                [ResourceType.DataLakeStorage, ResourceType.StorageAccount],
                resource.resourceType
              )
            "
            :configuration="resource.configuration"
            @update-configuration="handleUpdate"
          />
          <synapse-analytics-configuration-form
            v-else-if="
              includesResourceType(
                [ResourceType.SynapseAnalytics],
                resource.resourceType
              )
            "
            :configuration="resource.configuration"
            :data-lake-storage-names="
              resources
                .filter(el => el.resourceType == ResourceType.DataLakeStorage)
                .map(el => el.name)
            "
            :managed-identity-names="
              resources
                .filter(el => el.resourceType == ResourceType.ManagedIdentity)
                .map(el => el.name)
            "
            @update-configuration="handleUpdate"
          />
        </el-col>
        <el-col :span="6" class="d-flex">
          <el-tooltip placement="top" :content="resource.configuration">
            <img
              :src="require(`@/assets/images/icons/new/settings.svg`)"
              height="12"
              width="12"
              class="ml-auto mt-1 action-icon"
              @click="showEditConfigurationModal = true"
            />
          </el-tooltip>
          <img
            v-if="!deploymentInProgress"
            :src="require(`@/assets/images/icons/new/delete.svg`)"
            height="12"
            width="12"
            class="action-icon ml-1 mt-1 mr-2"
            @click="showDeleteResourceModal = true"
          />
        </el-col>
      </el-row>
    </template>
  </base-card>
  <delete-resource-confirmation-modal
    v-model="showDeleteResourceModal"
    :type-text="Object.keys(ResourceType)[resource.resourceType].label"
    :name-text="resource.name"
    @confirm="handleDelete"
  />
  <edit-configuration-modal
    v-model="showEditConfigurationModal"
    :configuration="resource.configuration"
    @confirm="handleUpdate"
    @close="showEditConfigurationModal = false"
  />
</template>
<script lang="ts">
import { ElCol, ElRow, ElTooltip } from 'element-plus';
import BaseCard from '@/components/panels/BaseCard.vue';
import AiServicesConfigurationForm from './AiServicesConfigurationForm.vue';
import ContainerRegistryConfigurationForm from './ContainerRegistryConfigurationForm.vue';
import DeleteResourceConfirmationModal from './DeleteResourceConfirmationModal.vue';
import DatabricksWorkspaceConfigurationForm from './DatabricksWorkspaceConfigurationForm.vue';
import DataFactoryConfigurationForm from './DataFactoryConfigurationForm.vue';
import EditConfigurationModal from './EditConfigurationModal.vue';
import EventGridTopicConfigurationForm from './EventGridTopicConfigurationForm.vue';
import FunctionAppConfigurationForm from './FunctionAppConfigurationForm.vue';
import KeyVaultConfigurationForm from './KeyVaultConfigurationForm.vue';
import MachineLearningWorkspaceConfigurationForm from './MachineLearningWorkspaceConfigurationForm.vue';
import PowerBiWorkspaceConfigurationForm from './PowerBiWorkspaceConfigurationForm.vue';
import SqlDatabaseConfigurationForm from './SqlDatabaseConfigurationForm.vue';
import SqlServerConfigurationForm from './SqlServerConfigurationForm.vue';
import StorageAccountConfigurationForm from './StorageAccountConfigurationForm.vue';
import SynapseAnalyticsConfigurationForm from './SynapseAnalyticsConfigurationForm.vue';
import { ResourceType, ResourceModel } from '@etp/etp-infra-client';
import { ref } from 'vue';

export default {
  name: 'TenantComposeCard',
  components: {
    BaseCard,
    ElRow,
    ElCol,
    ElTooltip,
    AiServicesConfigurationForm,
    ContainerRegistryConfigurationForm,
    DeleteResourceConfirmationModal,
    DatabricksWorkspaceConfigurationForm,
    DataFactoryConfigurationForm,
    EditConfigurationModal,
    EventGridTopicConfigurationForm,
    FunctionAppConfigurationForm,
    KeyVaultConfigurationForm,
    MachineLearningWorkspaceConfigurationForm,
    PowerBiWorkspaceConfigurationForm,
    SqlDatabaseConfigurationForm,
    SqlServerConfigurationForm,
    StorageAccountConfigurationForm,
    SynapseAnalyticsConfigurationForm
  },
  props: {
    resources: {
      type: Array as () => ResourceModel[],
      default: () => new Array<ResourceModel>()
    },
    deploymentInProgress: { type: Boolean, default: false },
    resource: { type: Object as () => ResourceModel, required: true }
  },
  emits: ['delete', 'update'],
  setup(props, { emit }) {
    const showDeleteResourceModal = ref(false);
    const showEditConfigurationModal = ref(false);

    const handleDelete = () => {
      emit('delete');
      showDeleteResourceModal.value = false;
    };

    const handleUpdate = (newConfiguration: string) => {
      emit('update', newConfiguration);
      showEditConfigurationModal.value = false;
    };

    const includesResourceType = (
      resourceTypes: ResourceType[],
      resourceTypeToCheck: ResourceType
    ) => {
      return resourceTypes.includes(resourceTypeToCheck);
    };

    return {
      handleDelete,
      handleUpdate,
      includesResourceType,
      ResourceType,
      showDeleteResourceModal,
      showEditConfigurationModal
    };
  }
};
</script>
<style scoped lang="scss"></style>
