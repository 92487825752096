<template>
  <base-modal
    :modal-title="$t('updateRequirementsStatus')"
    :dialog-visible="modelValue"
    :confirm-text="$t('save')"
    width="400px"
    @close="close"
    @confirm="confirm"
  >
    <template #content>
      <el-form
        ref="formRef"
        :model="form"
        :rules="formRules"
        label-position="top"
      >
        <el-form-item
          :label="$t('requirementsStatus')"
          prop="requirementsStatus"
        >
          <el-select v-model="form.requirementsStatus" size="small">
            <el-option
              v-for="(index, item) in RequirementsStatus"
              :key="index"
              :disabled="!props.allowedValues[item]"
              :label="$t(item)"
              :value="index"
            />
          </el-select>
          <el-form-item
            :label="$t('message')"
            prop="message"
            class="mt-2 width-100"
          >
            <el-input v-model="form.message" :rows="4" type="textarea" />
          </el-form-item>
        </el-form-item>
      </el-form>
    </template>
  </base-modal>
</template>
<script lang="ts">
import BaseModal from '@/components/modals/BaseModal.vue';
import {
  ElForm,
  ElFormItem,
  ElInput,
  ElMessage,
  ElSelect,
  ElOption
} from 'element-plus';
import { onMounted, onUpdated, reactive, ref } from 'vue';
import i18n from '@/i18n';
import {
  EngagementRequirementsStatus,
  RequirementsStatus,
  UpdateRequirementsStatusRequest
} from '@etp/etp-gain-bff-client/axios';

export default {
  name: 'NewEngagementModal',
  components: {
    BaseModal,
    ElInput,
    ElForm,
    ElFormItem,
    ElSelect,
    ElOption
  },
  props: {
    modelValue: { type: Boolean, default: false },
    allowedValues: { type: Object, default: {} as object },
    feedbackText: { type: String, default: i18n.global.t('saved') },
    currentStatus: {
      type: Object as () => EngagementRequirementsStatus,
      required: true
    }
  },
  emits: ['close', 'confirm', 'update:model-value'],
  setup(props, { emit }) {
    const form = reactive({
      requirementsStatus: -1,
      message: ''
    });
    const formRules = reactive({
      message: {
        required: true,
        message: i18n.global.t('thisFieldIsRequired'),
        trigger: 'blur'
      }
    });
    const formRef = ref<HTMLFormElement>();
    const confirm = () => {
      formRef.value?.validate((valid: boolean) => {
        if (valid) {
          emit('confirm', {
            requirementsStatus: form.requirementsStatus,
            message: form.message
          } as UpdateRequirementsStatusRequest);
          ElMessage({
            showClose: true,
            message: props.feedbackText
          });
        }
      });
    };

    const resetLocalVars = () => {
      form.requirementsStatus = props.currentStatus.requirementsStatus ?? 0;
      form.message = '';
    };

    onMounted(() => resetLocalVars());
    onUpdated(() => resetLocalVars());

    const close = () => {
      resetLocalVars();
      emit('update:model-value', false);
    };

    return {
      form,
      formRules,
      formRef,
      confirm,
      close,
      props,
      RequirementsStatus
    };
  }
};
</script>
<style scoped lang="scss"></style>
