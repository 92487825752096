import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_base_modal = _resolveComponent("base-modal")!

  return (_openBlock(), _createBlock(_component_base_modal, {
    "modal-title": _ctx.$t('addUser'),
    "confirm-text": _ctx.$t('add'),
    "dialog-visible": $props.modalVisible,
    width: "400px",
    onConfirm: $setup.confirm,
    onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('close')))
  }, {
    content: _withCtx(() => [
      _createVNode(_component_el_form, {
        ref: "formRef",
        model: $setup.form,
        rules: $setup.formRules,
        "label-position": "top"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, {
            label: _ctx.$t('scope'),
            prop: "scope"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: $setup.form.scope,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.form.scope) = $event)),
                disabled: ""
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_form_item, {
            label: _ctx.$t('user'),
            prop: "email"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_select, {
                modelValue: $setup.form.email,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.form.email) = $event)),
                filterable: "",
                "allow-create": $setup.createNewUser,
                size: "small",
                "value-key": "email",
                "filter-method": $setup.filterUsers
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.sortUsers($setup.filteredUsers), (user) => {
                    return (_openBlock(), _createBlock(_component_el_option, {
                      key: user.email,
                      label: user.name,
                      value: user.email,
                      disabled: user.disabled
                    }, null, 8, ["label", "value", "disabled"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modelValue", "allow-create", "filter-method"])
            ]),
            _: 1
          }, 8, ["label"]),
          ($setup.createNewUser)
            ? (_openBlock(), _createBlock(_component_el_form_item, {
                key: 0,
                label: _ctx.$t('name'),
                prop: "name"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: $setup.form.name,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.form.name) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }, 8, ["label"]))
            : _createCommentVNode("", true),
          _createVNode(_component_el_form_item, {
            label: _ctx.$t('roles'),
            prop: "selectedRoles"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_select, {
                modelValue: $setup.form.selectedRoles,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.form.selectedRoles) = $event)),
                multiple: "",
                size: "small",
                "value-key": "email"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.roles, (role) => {
                    return (_openBlock(), _createBlock(_component_el_option, {
                      key: role.name,
                      label: role.name,
                      value: role.name
                    }, null, 8, ["label", "value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["label"])
        ]),
        _: 1
      }, 8, ["model", "rules"])
    ]),
    _: 1
  }, 8, ["modal-title", "confirm-text", "dialog-visible", "onConfirm"]))
}