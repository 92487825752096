<template>
  <el-row class="d-flex">
    <h4 class="mt-auto">
      {{ resources.length }} &#183;
      {{ $t('resources') }}
    </h4>
  </el-row>
  <el-row>
    <el-col>
      <base-table>
        <el-table
          ref="multipleTableRef"
          :data="resources"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" />
          <el-table-column :label="$t('name')" prop="name" />
          <el-table-column :label="$t('resourceType')" prop="resourceType">
            <template #default="{ row }">
              {{ $t(Object.keys(ResourceType)[row.resourceType]) }}
            </template>
          </el-table-column>
        </el-table>
      </base-table>
    </el-col>
  </el-row>
</template>
<script lang="ts">
import BaseTable from '@/components/tables/BaseTable.vue';
import { ElCol, ElTable, ElTableColumn, ElRow } from 'element-plus';
import { computed, ComputedRef, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { ResourceType, ResourceModel } from '@etp/etp-infra-client';
import {
  EngagementModel,
  UpdateEngagementDto
} from '@etp/etp-engagements-client/axios';

export default {
  name: 'EngagementResources',
  components: {
    BaseTable,
    ElCol,
    ElTable,
    ElTableColumn,
    ElRow
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    let engagementId = '';
    let toggleSelectionCheck = 1;

    const multipleTableRef = ref<InstanceType<typeof ElTable>>();

    const resources: ComputedRef<Array<ResourceModel>> = computed(
      () => store.getters['tenants/getResources']
    );

    const engagement: ComputedRef<EngagementModel> = computed(
      () => store.getters['engagements/getActiveEngagement']
    );

    const handleSelectionChange = async (
      selectedResources: Array<ResourceModel>
    ) => {
      if (toggleSelectionCheck === 0) {
        const resourceIds = new Set([
          ...selectedResources.map(sr => sr.id || '')
        ]);

        await store.dispatch('engagements/updateEngagement', {
          engagementId: engagementId,
          model: {
            name: engagement.value.name,
            features: engagement.value.features,
            resourceIds: [...resourceIds]
          } as UpdateEngagementDto
        });
      }
    };

    const toggleSelection = (rs: ResourceModel[]) => {
      if (rs) {
        rs.forEach(resource => {
          const checkIfEngagementIdExists = (
            engagement.value?.resourceIds || []
          ).includes(resource.id || '');

          if (multipleTableRef.value) {
            multipleTableRef.value.toggleRowSelection(
              resource,
              checkIfEngagementIdExists
            );
          }
        });
      }
      toggleSelectionCheck--;
    };

    const updateLocalVars = async () => {
      engagementId = router.currentRoute.value.params.engagementId as string;
      await store.dispatch('engagements/fetchEngagement', engagementId);
      let tenantId = store.getters['engagements/getActiveEngagement'].tenantId;
      await store.dispatch('tenants/fetchResources', { tenantId });
      toggleSelection(resources.value);
    };

    onMounted(async () => {
      await updateLocalVars();
    });

    return {
      handleSelectionChange,
      multipleTableRef,
      resources,
      ResourceType,
      toggleSelection
    };
  }
};
</script>
<style lang="scss" scoped></style>
