import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_storage_configuration_card = _resolveComponent("storage-configuration-card")!
  const _component_new_storage_configuration_modal = _resolveComponent("new-storage-configuration-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_row, { class: "d-flex" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_button, {
          class: "ml-auto",
          type: "primary",
          onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.showNewConfigurationModal = true))
        }, {
          default: _withCtx(() => [
            _createElementVNode("h4", null, _toDisplayString(_ctx.$t('new')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_button, {
          class: "ml-2",
          type: "primary",
          onClick: $setup.saveConfigurations
        }, {
          default: _withCtx(() => [
            _createElementVNode("h4", null, _toDisplayString(_ctx.$t('save')), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _: 1
    }),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.localConfigurations, (item, index) => {
      return (_openBlock(), _createBlock(_component_el_row, {
        key: index,
        class: "d-block mt-1"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_storage_configuration_card, {
            configuration: item,
            resources: $setup.tenantResources,
            onUpdate: (payload) => $setup.handleConfigurationUpdate(index, payload),
            onDelete: ($event: any) => ($setup.handleConfigurationDelete(index))
          }, null, 8, ["configuration", "resources", "onUpdate", "onDelete"])
        ]),
        _: 2
      }, 1024))
    }), 128)),
    _createVNode(_component_new_storage_configuration_modal, {
      "model-value": $setup.showNewConfigurationModal,
      "existing-purposes": $setup.localConfigurations.map(el => el.storagePurpose),
      onClose: _cache[1] || (_cache[1] = ($event: any) => ($setup.showNewConfigurationModal = false)),
      onConfirm: $setup.handleConfigurationCreate
    }, null, 8, ["model-value", "existing-purposes", "onConfirm"])
  ], 64))
}