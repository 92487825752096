<template>
  <base-tree
    :all-nodes="tree"
    :selected-nodes="getSelectedNodes(existingPermissions)"
    @change="handleChange"
  />
</template>
<script lang="ts">
import { ref } from 'vue';
import BaseTree from '@/components/trees/BaseTree.vue';
import TreeNode from '@/components/types/treeNode';
import { populateTree } from '@/utils/treeHelper';

export default {
  name: 'PermissionsTree',
  components: {
    BaseTree
  },
  props: {
    allPermissions: {
      type: Array as () => Array<string>,
      default: new Array<string>()
    },
    existingPermissions: {
      type: Array as () => Array<string>,
      default: new Array<string>()
    },
    delimiter: {
      type: String,
      default: '.'
    }
  },
  emits: ['change'],
  setup(props, { emit }) {
    const tree = ref<TreeNode[]>(
      populateTree(props.allPermissions, props.delimiter)
    );

    const getSelectedNodes = (permissions: Array<string>): Array<number> => {
      const selectedNodes = new Array<number>();
      permissions.forEach(permission => {
        let currentNode;
        const levels = permission.split(props.delimiter);
        for (let i = 0; i < levels.length; i++) {
          if (i == 0) {
            currentNode = tree.value.find(x => x.label == levels[0]);
          } else {
            currentNode = currentNode.children.find(x => x.label == levels[i]);
          }
          if (!currentNode) return;
          if (i == levels.length - 1) {
            selectedNodes.push(currentNode.id);
            return;
          }
        }
      });
      return selectedNodes;
    };

    const handleChange = (permissions: Array<string>) => {
      emit('change', permissions);
    };

    return {
      getSelectedNodes,
      handleChange,
      tree
    };
  }
};
</script>

<style scoped lang="scss"></style>
