import { checkAnyPermission } from '@/utils/permissionChecker';
import {
  GetGlobalScope,
  GetScopeForEngagement,
  GetScopeForTenant
} from '@/utils/scopeHelper';
import {
  permissions_authorization_reader,
  permissions_authorization_writer,
  permissions_connectors_configurationWriter,
  permissions_dashboarding_powerBiReader,
  permissions_dashboarding_powerBiWriter,
  permissions_engagements_reader,
  permissions_gainBff_configurationImportReader,
  permissions_gainBff_configurationImportWriter,
  permissions_gainBff_requirementsStatusWriter,
  permissions_infra_costsReader,
  permissions_infra_tenantsReader,
  permissions_nodeModel_gitRead,
  permissions_nodeModel_gitWrite,
  permissions_pipelines_pipelinesRead,
  permissions_pipelines_pipelinesStart,
  permissions_pipelines_pipelinesWrite,
  permissions_rbac_apply,
  permissions_rbac_tasks_read,
  permissions_storage_foldersReader,
  permissions_storage_foldersWriter,
  permissions_taskScheduler_taskCreator,
  permissions_taskScheduler_taskReader
} from '@/utils/variables';
import { PermissionRequirementModel } from '@etp/etp-authorization-client/axios';
import { RequirementsStatus } from '@etp/etp-gain-bff-client/axios';
import { ActionContext, Module } from 'vuex';

export interface UserPermissionsState {
  canReadEngagementAuthorization?: boolean;
  canReadEngagementFolders?: boolean;
  canReadEngagementGainImportConfiguration?: boolean;
  canReadEngagementNodeModelGit?: boolean;
  canReadEngagementPipelines?: boolean;
  canReadEngagementPowerBiDashboards?: boolean;
  canReadEngagementRbacTasks?: boolean;
  canReadEngagementTaskSchedulerTasks?: boolean;
  canReadGlobalAuthorization?: boolean;
  canReadGlobalEngagements?: boolean;
  canReadGlobalTenants?: boolean;
  canReadGlobalUsers?: boolean;
  canReadTenantCosts?: boolean;
  canSetGainRequirementsStatus: object;
  canStartEngagementPipelines?: boolean;
  canWriteEngagementAuthorization?: boolean;
  canWriteEngagementConnectorsConfig?: boolean;
  canWriteEngagementGainImportConfiguration?: boolean;
  canWriteEngagementNodeModelGit?: boolean;
  canWriteEngagementPipelines?: boolean;
  canWriteEngagementPowerBiDashboards?: boolean;
  canWriteEngagementRbac?: boolean;
  canWriteEngagementStorageFolders?: boolean;
  canWriteEngagementTaskSchedulerTasks?: boolean;
}
export class UserPermissionsModule
  implements Module<UserPermissionsState, any>
{
  namespaced = true;
  state = {
    canSetGainRequirementsStatus: {}
  };
  getters = {
    canReadEngagementAuthorization: (state: UserPermissionsState): boolean =>
      !!state.canReadEngagementAuthorization,
    canReadEngagementFolders: (state: UserPermissionsState): boolean =>
      !!state.canReadEngagementFolders,
    canReadEngagementGainImportConfiguration: (
      state: UserPermissionsState
    ): boolean => !!state.canReadEngagementGainImportConfiguration,
    canReadEngagementNodeModelGit: (state: UserPermissionsState): boolean =>
      !!state.canReadEngagementNodeModelGit,
    canReadEngagementPipelines: (state: UserPermissionsState): boolean =>
      !!state.canReadEngagementPipelines,
    canReadEngagementPowerBiDashboards: (
      state: UserPermissionsState
    ): boolean => !!state.canReadEngagementPowerBiDashboards,
    canReadEngagementRbacTasks: (state: UserPermissionsState): boolean =>
      !!state.canReadEngagementRbacTasks,
    canReadEngagementTaskSchedulerTasks: (
      state: UserPermissionsState
    ): boolean => !!state.canReadEngagementTaskSchedulerTasks,
    canReadGlobalAuthorization: (state: UserPermissionsState): boolean =>
      !!state.canReadGlobalAuthorization,
    canReadGlobalEngagements: (state: UserPermissionsState): boolean =>
      !!state.canReadGlobalEngagements,
    canReadGlobalTenants: (state: UserPermissionsState): boolean =>
      !!state.canReadGlobalTenants,
    canReadGlobalUsers: (state: UserPermissionsState): boolean =>
      !!state.canReadGlobalUsers,
    canReadTenantCosts: (state: UserPermissionsState): boolean =>
      !!state.canReadTenantCosts,
    canSetGainRequirementsStatus: (state: UserPermissionsState): object =>
      state.canSetGainRequirementsStatus,
    canStartEngagementPipelines: (state: UserPermissionsState): boolean =>
      !!state.canStartEngagementPipelines,
    canWriteEngagementAuthorization: (state: UserPermissionsState): boolean =>
      !!state.canWriteEngagementAuthorization,
    canWriteEngagementConnectorsConfig: (
      state: UserPermissionsState
    ): boolean => !!state.canWriteEngagementConnectorsConfig,
    canWriteEngagementGainImportConfiguration: (
      state: UserPermissionsState
    ): boolean => !!state.canWriteEngagementGainImportConfiguration,
    canWriteEngagementNodeModelGit: (state: UserPermissionsState): boolean =>
      !!state.canWriteEngagementNodeModelGit,
    canWriteEngagementPipelines: (state: UserPermissionsState): boolean =>
      !!state.canWriteEngagementPipelines,
    canWriteEngagementPowerBiDashboards: (
      state: UserPermissionsState
    ): boolean => !!state.canWriteEngagementPowerBiDashboards,
    canWriteEngagementRbac: (state: UserPermissionsState): boolean =>
      !!state.canWriteEngagementRbac,
    canWriteEngagementStorageFolders: (state: UserPermissionsState): boolean =>
      !!state.canWriteEngagementStorageFolders,
    canWriteEngagementTaskSchedulerTasks: (
      state: UserPermissionsState
    ): boolean => !!state.canWriteEngagementTaskSchedulerTasks
  };
  actions = {
    async checkIfCanReadEngagementAuthorization(
      context: ActionContext<UserPermissionsState, any>
    ) {
      if (context.state.canReadEngagementAuthorization !== undefined) return;
      let engagementId = context.rootState.home.currentEngagement.id;
      let result = await checkAnyPermission([
        {
          permission: permissions_authorization_reader,
          scope: GetScopeForEngagement(engagementId)
        } as PermissionRequirementModel,
        {
          permission: permissions_authorization_reader,
          scope: GetGlobalScope()
        } as PermissionRequirementModel
      ]);
      context.commit('SET_CAN_READ_ENGAGEMENT_AUTHORIZATION', result);
    },
    async checkIfCanReadEngagementFolders(
      context: ActionContext<UserPermissionsState, any>
    ) {
      if (context.state.canReadEngagementFolders !== undefined) return;
      let engagementId = context.rootState.home.currentEngagement.id;
      let result = await checkAnyPermission([
        {
          permission: permissions_storage_foldersReader,
          scope: GetScopeForEngagement(engagementId)
        } as PermissionRequirementModel
      ]);
      context.commit('SET_CAN_READ_ENGAGEMENT_FOLDERS', result);
    },
    async checkIfCanReadGainImportConfiguration(
      context: ActionContext<UserPermissionsState, any>
    ) {
      if (context.state.canReadEngagementGainImportConfiguration !== undefined)
        return;
      let engagementId = context.rootState.home.currentEngagement.id;
      let result = await checkAnyPermission([
        {
          permission: permissions_gainBff_configurationImportReader,
          scope: GetScopeForEngagement(engagementId)
        } as PermissionRequirementModel
      ]);
      context.commit(
        'SET_CAN_READ_ENGAGEMENT_GAIN_IMPORT_CONFIGURATION',
        result
      );
    },
    async checkIfCanReadEngagementNodeModelGit(
      context: ActionContext<UserPermissionsState, any>
    ) {
      if (context.state.canReadEngagementNodeModelGit !== undefined) return;
      let engagementId = context.rootState.home.currentEngagement.id;
      let result = await checkAnyPermission([
        {
          permission: permissions_nodeModel_gitRead,
          scope: GetScopeForEngagement(engagementId)
        } as PermissionRequirementModel
      ]);
      context.commit('SET_CAN_READ_ENGAGEMENT_NODEMODEL_GIT', result);
    },
    async checkIfCanReadEngagementPipelines(
      context: ActionContext<UserPermissionsState, any>
    ) {
      if (context.state.canReadEngagementPipelines !== undefined) return;
      let engagementId = context.rootState.home.currentEngagement.id;
      let result = await checkAnyPermission([
        {
          permission: permissions_pipelines_pipelinesRead,
          scope: GetScopeForEngagement(engagementId)
        } as PermissionRequirementModel
      ]);
      context.commit('SET_CAN_READ_ENGAGEMENT_PIPELINES', result);
    },
    async checkIfCanReadEngagementPowerBiDashboards(
      context: ActionContext<UserPermissionsState, any>
    ) {
      if (context.state.canReadEngagementPowerBiDashboards !== undefined)
        return;
      let engagementId = context.rootState.home.currentEngagement.id;
      let result = await checkAnyPermission([
        {
          permission: permissions_dashboarding_powerBiReader,
          scope: GetScopeForEngagement(engagementId)
        } as PermissionRequirementModel
      ]);
      context.commit('SET_CAN_READ_ENGAGEMENT_POWERBI_DASHBOARDS', result);
    },
    async checkIfCanReadEngagementRbacTasks(
      context: ActionContext<UserPermissionsState, any>
    ) {
      if (context.state.canReadEngagementRbacTasks !== undefined) return;
      let engagementId = context.rootState.home.currentEngagement.id;
      let result = await checkAnyPermission([
        {
          permission: permissions_rbac_tasks_read,
          scope: GetScopeForEngagement(engagementId)
        } as PermissionRequirementModel,
        {
          permission: permissions_rbac_tasks_read,
          scope: GetGlobalScope()
        } as PermissionRequirementModel
      ]);
      context.commit('SET_CAN_READ_ENGAGEMENT_RBAC_TASKS', result);
    },
    async checkIfCanReadEngagementTaskSchedulerTasks(
      context: ActionContext<UserPermissionsState, any>
    ) {
      if (context.state.canReadEngagementTaskSchedulerTasks !== undefined)
        return;
      let engagementId = context.rootState.home.currentEngagement.id;
      let result = await checkAnyPermission([
        {
          permission: permissions_taskScheduler_taskReader,
          scope: GetScopeForEngagement(engagementId)
        } as PermissionRequirementModel
      ]);
      context.commit('SET_CAN_READ_ENGAGEMENT_TASKSCHEDULER_TASKS', result);
    },
    async checkIfCanReadGlobalAuthorization(
      context: ActionContext<UserPermissionsState, any>
    ) {
      if (context.state.canReadGlobalAuthorization !== undefined) return;
      let result = await checkAnyPermission([
        {
          permission: permissions_authorization_reader,
          scope: GetGlobalScope()
        } as PermissionRequirementModel
      ]);
      context.commit('SET_CAN_READ_GLOBAL_AUTHORIZATION', result);
    },
    async checkIfCanReadGlobalEngagements(
      context: ActionContext<UserPermissionsState, any>
    ) {
      if (context.state.canReadGlobalEngagements !== undefined) return;
      let result = await checkAnyPermission([
        {
          permission: permissions_engagements_reader,
          scope: GetGlobalScope()
        } as PermissionRequirementModel
      ]);
      context.commit('SET_CAN_READ_GLOBAL_ENGAGEMENTS', result);
    },
    async checkIfCanReadGlobalTenants(
      context: ActionContext<UserPermissionsState, any>
    ) {
      if (context.state.canReadGlobalTenants !== undefined) return;
      let result = await checkAnyPermission([
        {
          permission: permissions_infra_tenantsReader,
          scope: GetGlobalScope()
        } as PermissionRequirementModel
      ]);
      context.commit('SET_CAN_READ_GLOBAL_TENANTS', result);
    },
    async checkIfCanReadGlobalUsers(
      context: ActionContext<UserPermissionsState, any>
    ) {
      if (context.state.canReadGlobalUsers !== undefined) return;
      let result = await checkAnyPermission([
        {
          permission: permissions_authorization_reader,
          scope: GetGlobalScope()
        } as PermissionRequirementModel
      ]);
      context.commit('SET_CAN_READ_GLOBAL_USERS', result);
    },
    async checkIfCanReadTenantCosts(
      context: ActionContext<UserPermissionsState, any>
    ) {
      if (context.state.canReadTenantCosts !== undefined) return;
      let tenantId = context.rootState.home.currentEngagement.tenantId;
      let result = await checkAnyPermission([
        {
          permission: permissions_infra_costsReader,
          scope: GetScopeForTenant(tenantId)
        } as PermissionRequirementModel
      ]);
      context.commit('SET_CAN_READ_TENANT_COSTS', result);
    },
    async checkIfCanSetGainRequirementsStatus(
      context: ActionContext<UserPermissionsState, any>
    ) {
      let engagementId = context.rootState.home.currentEngagement.id;
      for (let key of Object.keys(RequirementsStatus)) {
        let permissionToCheck =
          permissions_gainBff_requirementsStatusWriter + '.' + key;
        let result = await checkAnyPermission([
          {
            permission: permissionToCheck,
            scope: GetScopeForEngagement(engagementId)
          } as PermissionRequirementModel,
          {
            permission: permissionToCheck,
            scope: GetGlobalScope()
          } as PermissionRequirementModel
        ]);
        context.commit('SET_CAN_SET_GAIN_REQUIREMENTS_STATUS', {
          key,
          value: result
        });
      }
    },
    async checkIfCanStartEngagementPipelines(
      context: ActionContext<UserPermissionsState, any>
    ) {
      if (context.state.canStartEngagementPipelines !== undefined) return;
      let engagementId = context.rootState.home.currentEngagement.id;
      let result = await checkAnyPermission([
        {
          permission: permissions_pipelines_pipelinesStart,
          scope: GetScopeForEngagement(engagementId)
        } as PermissionRequirementModel
      ]);
      context.commit('SET_CAN_START_ENGAGEMENT_PIPELINES', result);
    },
    async checkIfCanWriteEngagementAuthorization(
      context: ActionContext<UserPermissionsState, any>
    ) {
      if (context.state.canWriteEngagementAuthorization !== undefined) return;
      let engagementId = context.rootState.home.currentEngagement.id;
      let result = await checkAnyPermission([
        {
          permission: permissions_authorization_writer,
          scope: GetScopeForEngagement(engagementId)
        } as PermissionRequirementModel,
        {
          permission: permissions_authorization_writer,
          scope: GetGlobalScope()
        } as PermissionRequirementModel
      ]);
      context.commit('SET_CAN_WRITE_ENGAGEMENT_AUTHORIZATION', result);
    },
    async checkIfCanWriteEngagementConnectorsConfig(
      context: ActionContext<UserPermissionsState, any>
    ) {
      if (context.state.canWriteEngagementConnectorsConfig !== undefined)
        return;
      let engagementId = context.rootState.home.currentEngagement.id;
      let result = await checkAnyPermission([
        {
          permission: permissions_connectors_configurationWriter,
          scope: GetScopeForEngagement(engagementId)
        } as PermissionRequirementModel
      ]);
      context.commit('SET_CAN_WRITE_ENGAGEMENT_CONNECTORS_CONFIG', result);
    },
    async checkIfCanWriteEngagementGainImportConfiguration(
      context: ActionContext<UserPermissionsState, any>
    ) {
      if (context.state.canWriteEngagementGainImportConfiguration !== undefined)
        return;
      const engagementId = context.rootState.home.currentEngagement.id;
      const result = await checkAnyPermission([
        {
          permission: permissions_gainBff_configurationImportWriter,
          scope: GetScopeForEngagement(engagementId)
        } as PermissionRequirementModel
      ]);
      context.commit(
        'SET_CAN_WRITE_ENGAGEMENT_GAIN_IMPORT_CONFIGURATION',
        result
      );
    },
    async checkIfCanWriteEngagementNodeModelGit(
      context: ActionContext<UserPermissionsState, any>
    ) {
      if (context.state.canWriteEngagementNodeModelGit !== undefined) return;
      const engagementId = context.rootState.home.currentEngagement.id;
      const result = await checkAnyPermission([
        {
          permission: permissions_nodeModel_gitWrite,
          scope: GetScopeForEngagement(engagementId)
        } as PermissionRequirementModel
      ]);
      context.commit('SET_CAN_WRITE_ENGAGEMENT_NODEMODEL_GIT', result);
    },
    async checkIfCanWriteEngagementPipelines(
      context: ActionContext<UserPermissionsState, any>
    ) {
      if (context.state.canWriteEngagementPipelines !== undefined) return;
      let engagementId = context.rootState.home.currentEngagement.id;
      let result = await checkAnyPermission([
        {
          permission: permissions_pipelines_pipelinesWrite,
          scope: GetScopeForEngagement(engagementId)
        } as PermissionRequirementModel
      ]);
      context.commit('SET_CAN_WRITE_ENGAGEMENT_PIPELINES', result);
    },
    async checkIfCanWriteEngagementPowerBiDashboards(
      context: ActionContext<UserPermissionsState, any>
    ) {
      if (context.state.canWriteEngagementPowerBiDashboards !== undefined)
        return;
      let engagementId = context.rootState.home.currentEngagement.id;
      let result = await checkAnyPermission([
        {
          permission: permissions_dashboarding_powerBiWriter,
          scope: GetScopeForEngagement(engagementId)
        } as PermissionRequirementModel
      ]);
      context.commit('SET_CAN_WRITE_ENGAGEMENT_POWERBI_DASHBOARDS', result);
    },
    async checkIfCanWriteEngagementRbac(
      context: ActionContext<UserPermissionsState, any>
    ) {
      if (context.state.canWriteEngagementRbac !== undefined) return;
      let engagementId = context.rootState.home.currentEngagement.id;
      let result = await checkAnyPermission([
        {
          permission: permissions_rbac_apply,
          scope: GetScopeForEngagement(engagementId)
        } as PermissionRequirementModel,
        {
          permission: permissions_rbac_apply,
          scope: GetGlobalScope()
        } as PermissionRequirementModel
      ]);
      context.commit('SET_CAN_WRITE_ENGAGEMENT_RBAC', result);
    },
    async checkIfCanWriteEngagementStorageFolders(
      context: ActionContext<UserPermissionsState, any>
    ) {
      if (context.state.canWriteEngagementStorageFolders !== undefined) return;
      let engagementId = context.rootState.home.currentEngagement.id;
      let result = await checkAnyPermission([
        {
          permission: permissions_storage_foldersWriter,
          scope: GetScopeForEngagement(engagementId)
        } as PermissionRequirementModel
      ]);
      context.commit('SET_CAN_WRITE_ENGAGEMENT_STORAGE_FOLDERS', result);
    },
    async checkIfCanWriteEngagementTaskSchedulerTasks(
      context: ActionContext<UserPermissionsState, any>
    ) {
      if (context.state.canWriteEngagementTaskSchedulerTasks !== undefined)
        return;
      let engagementId = context.rootState.home.currentEngagement.id;
      let result = await checkAnyPermission([
        {
          permission: permissions_taskScheduler_taskCreator,
          scope: GetScopeForEngagement(engagementId)
        } as PermissionRequirementModel
      ]);
      context.commit('SET_CAN_WRITE_ENGAGEMENT_TASKSCHEDULER_TASKS', result);
    },
    async resetEngagementsTenantsState(
      context: ActionContext<UserPermissionsState, any>
    ) {
      context.commit('RESET_ENGAGEMENTS_TENANTS_STATE');
    }
  };
  mutations = {
    SET_CAN_READ_ENGAGEMENT_AUTHORIZATION(
      state: UserPermissionsState,
      payload: boolean
    ) {
      state.canReadEngagementAuthorization = payload;
    },
    SET_CAN_READ_ENGAGEMENT_FOLDERS(
      state: UserPermissionsState,
      payload: boolean
    ) {
      state.canReadEngagementFolders = payload;
    },
    SET_CAN_READ_ENGAGEMENT_NODEMODEL_GIT(
      state: UserPermissionsState,
      payload: boolean
    ) {
      state.canReadEngagementNodeModelGit = payload;
    },
    SET_CAN_READ_ENGAGEMENT_GAIN_IMPORT_CONFIGURATION(
      state: UserPermissionsState,
      payload: boolean
    ) {
      state.canReadEngagementGainImportConfiguration = payload;
    },
    SET_CAN_READ_ENGAGEMENT_PIPELINES(
      state: UserPermissionsState,
      payload: boolean
    ) {
      state.canReadEngagementPipelines = payload;
    },
    SET_CAN_READ_ENGAGEMENT_POWERBI_DASHBOARDS(
      state: UserPermissionsState,
      payload: boolean
    ) {
      state.canReadEngagementPowerBiDashboards = payload;
    },
    SET_CAN_READ_ENGAGEMENT_RBAC_TASKS(
      state: UserPermissionsState,
      payload: boolean
    ) {
      state.canReadEngagementRbacTasks = payload;
    },
    SET_CAN_READ_ENGAGEMENT_TASKSCHEDULER_TASKS(
      state: UserPermissionsState,
      payload: boolean
    ) {
      state.canReadEngagementTaskSchedulerTasks = payload;
    },
    SET_CAN_READ_GLOBAL_AUTHORIZATION(
      state: UserPermissionsState,
      payload: boolean
    ) {
      state.canReadGlobalAuthorization = payload;
    },
    SET_CAN_READ_GLOBAL_ENGAGEMENTS(
      state: UserPermissionsState,
      payload: boolean
    ) {
      state.canReadGlobalEngagements = payload;
    },
    SET_CAN_READ_GLOBAL_TENANTS(state: UserPermissionsState, payload: boolean) {
      state.canReadGlobalTenants = payload;
    },
    SET_CAN_READ_GLOBAL_USERS(state: UserPermissionsState, payload: boolean) {
      state.canReadGlobalUsers = payload;
    },
    SET_CAN_READ_TENANT_COSTS(state: UserPermissionsState, payload: boolean) {
      state.canReadTenantCosts = payload;
    },
    SET_CAN_SET_GAIN_REQUIREMENTS_STATUS(
      state: UserPermissionsState,
      payload: {
        key: string;
        value: boolean;
      }
    ) {
      state.canSetGainRequirementsStatus[payload.key] = payload.value;
    },
    SET_CAN_WRITE_ENGAGEMENT_AUTHORIZATION(
      state: UserPermissionsState,
      payload: boolean
    ) {
      state.canWriteEngagementAuthorization = payload;
    },
    SET_CAN_WRITE_ENGAGEMENT_CONNECTORS_CONFIG(
      state: UserPermissionsState,
      payload: boolean
    ) {
      state.canWriteEngagementConnectorsConfig = payload;
    },
    SET_CAN_WRITE_ENGAGEMENT_GAIN_IMPORT_CONFIGURATION(
      state: UserPermissionsState,
      payload: boolean
    ) {
      state.canWriteEngagementGainImportConfiguration = payload;
    },
    SET_CAN_WRITE_ENGAGEMENT_NODEMODEL_GIT(
      state: UserPermissionsState,
      payload: boolean
    ) {
      state.canWriteEngagementNodeModelGit = payload;
    },
    SET_CAN_START_ENGAGEMENT_PIPELINES(
      state: UserPermissionsState,
      payload: boolean
    ) {
      state.canStartEngagementPipelines = payload;
    },
    SET_CAN_WRITE_ENGAGEMENT_PIPELINES(
      state: UserPermissionsState,
      payload: boolean
    ) {
      state.canWriteEngagementPipelines = payload;
    },
    SET_CAN_WRITE_ENGAGEMENT_POWERBI_DASHBOARDS(
      state: UserPermissionsState,
      payload: boolean
    ) {
      state.canWriteEngagementPowerBiDashboards = payload;
    },
    SET_CAN_WRITE_ENGAGEMENT_RBAC(
      state: UserPermissionsState,
      payload: boolean
    ) {
      state.canWriteEngagementRbac = payload;
    },
    SET_CAN_WRITE_ENGAGEMENT_STORAGE_FOLDERS(
      state: UserPermissionsState,
      payload: boolean
    ) {
      state.canWriteEngagementStorageFolders = payload;
    },
    SET_CAN_WRITE_ENGAGEMENT_TASKSCHEDULER_TASKS(
      state: UserPermissionsState,
      payload: boolean
    ) {
      state.canWriteEngagementTaskSchedulerTasks = payload;
    },
    RESET_ENGAGEMENTS_TENANTS_STATE(state: UserPermissionsState) {
      state.canReadEngagementAuthorization = undefined;
      state.canReadEngagementFolders = undefined;
      state.canReadEngagementGainImportConfiguration = undefined;
      state.canReadEngagementNodeModelGit = undefined;
      state.canReadEngagementPipelines = undefined;
      state.canReadEngagementPowerBiDashboards = undefined;
      state.canReadEngagementRbacTasks = undefined;
      state.canReadEngagementTaskSchedulerTasks = undefined;
      state.canReadTenantCosts = undefined;
      state.canSetGainRequirementsStatus = {};
      state.canStartEngagementPipelines = undefined;
      state.canWriteEngagementAuthorization = undefined;
      state.canWriteEngagementConnectorsConfig = undefined;
      state.canWriteEngagementGainImportConfiguration = undefined;
      state.canWriteEngagementNodeModelGit = undefined;
      state.canWriteEngagementPipelines = undefined;
      state.canWriteEngagementPowerBiDashboards = undefined;
      state.canWriteEngagementRbac = undefined;
      state.canWriteEngagementStorageFolders = undefined;
      state.canWriteEngagementTaskSchedulerTasks = undefined;
    }
  };
}

export default new UserPermissionsModule();
