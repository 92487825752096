import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, createSlots as _createSlots } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mt-auto"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_table_placeholder_loading = _resolveComponent("table-placeholder-loading")!
  const _component_table_placeholder = _resolveComponent("table-placeholder")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_base_card = _resolveComponent("base-card")!

  return (_openBlock(), _createBlock(_component_base_card, { "no-header": "" }, {
    body: _withCtx(() => [
      _createVNode(_component_el_row, { class: "d-flex pr-2" }, {
        default: _withCtx(() => [
          ($props.showBuildButton && $setup.localNodes.length)
            ? (_openBlock(), _createBlock(_component_el_button, {
                key: 0,
                disabled: !$setup.localNodes.some(el => el.active || el.rebuild),
                type: "primary",
                class: "mt-2 ml-auto",
                onClick: _withModifiers($setup.startPipelineRun, ["stop"])
              }, {
                default: _withCtx(() => [
                  _createElementVNode("h4", null, _toDisplayString(_ctx.$t('build')), 1)
                ]),
                _: 1
              }, 8, ["disabled", "onClick"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_el_row, null, {
        default: _withCtx(() => [
          ($props.loadingNodes)
            ? (_openBlock(), _createElementBlock("h4", _hoisted_1, _toDisplayString(_ctx.$t('loading')), 1))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_el_row, null, {
        default: _withCtx(() => [
          ($props.loadingNodes)
            ? (_openBlock(), _createBlock(_component_table_placeholder_loading, {
                key: 0,
                columns: 1
              }))
            : (!$setup.localNodes.length)
              ? (_openBlock(), _createBlock(_component_table_placeholder, { key: 1 }))
              : (_openBlock(), _createBlock(_component_el_table, {
                  key: 2,
                  class: "build-table",
                  data: $setup.localNodes,
                  "empty-text": _ctx.$t('noBuilds')
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_table_column, {
                      prop: "active",
                      width: "120"
                    }, _createSlots({
                      default: _withCtx(({ row, $index }) => [
                        _createVNode(_component_el_checkbox, {
                          "model-value": row.active,
                          "onUpdate:modelValue": ($event: any) => (
                  $setup.updateRow({
                    index: $index,
                    row: { ...row, active: $event }
                  })
                )
                        }, null, 8, ["model-value", "onUpdate:modelValue"])
                      ]),
                      _: 2
                    }, [
                      ($setup.localNodes.length)
                        ? {
                            name: "header",
                            fn: _withCtx(() => [
                              _createVNode(_component_el_checkbox, {
                                class: "checkbox",
                                label: _ctx.$t('active'),
                                "model-value": $setup.allActive,
                                "onUpdate:modelValue": $setup.updateAllActive
                              }, null, 8, ["label", "model-value", "onUpdate:modelValue"])
                            ]),
                            key: "0"
                          }
                        : undefined
                    ]), 1024),
                    _createVNode(_component_el_table_column, {
                      prop: "rebuild",
                      width: "150"
                    }, _createSlots({
                      default: _withCtx(({ row, $index }) => [
                        _createVNode(_component_el_checkbox, {
                          "model-value": row.rebuild,
                          "onUpdate:modelValue": ($event: any) => (
                  $setup.updateRow({
                    index: $index,
                    row: { ...row, rebuild: $event, active: $event }
                  })
                )
                        }, null, 8, ["model-value", "onUpdate:modelValue"])
                      ]),
                      _: 2
                    }, [
                      ($setup.localNodes.length)
                        ? {
                            name: "header",
                            fn: _withCtx(() => [
                              _createVNode(_component_el_checkbox, {
                                class: "checkbox",
                                label: _ctx.$t('rebuild'),
                                "model-value": $setup.allRebuild,
                                "onUpdate:modelValue": $setup.updateAllRebuild
                              }, null, 8, ["label", "model-value", "onUpdate:modelValue"])
                            ]),
                            key: "0"
                          }
                        : undefined
                    ]), 1024),
                    _createVNode(_component_el_table_column, { prop: "name" }, _createSlots({
                      default: _withCtx((scope) => [
                        _createElementVNode("span", null, _toDisplayString(scope.row.name), 1)
                      ]),
                      _: 2
                    }, [
                      ($setup.localNodes.length)
                        ? {
                            name: "header",
                            fn: _withCtx(() => [
                              _createElementVNode("span", null, _toDisplayString(_ctx.$t('node')), 1)
                            ]),
                            key: "0"
                          }
                        : undefined
                    ]), 1024)
                  ]),
                  _: 1
                }, 8, ["data", "empty-text"]))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}