<template>
  <base-modal
    :modal-title="$t('configuration')"
    :dialog-visible="modelValue"
    :confirm-text="$t('save')"
    width="400px"
    @close="close"
    @confirm="confirm"
  >
    <template #content>
      <el-form
        ref="formRef"
        :model="form"
        :rules="formRules"
        label-position="top"
      >
        <el-form-item prop="configuration">
          <code-editor
            v-model="form.configuration"
            font_size="13px"
            :languages="[['JSON', 'JSON']]"
            theme="light"
          />
        </el-form-item>
      </el-form>
    </template>
  </base-modal>
</template>
<script lang="ts">
import BaseModal from '@/components/modals/BaseModal.vue';
import CodeEditor from 'simple-code-editor/CodeEditor.vue';
import { ElForm, ElFormItem } from 'element-plus';
import { ref, reactive, onMounted, onUpdated } from 'vue';
import { mustBeValidJson } from '@/utils/formValidators';

export default {
  name: 'EditConfigurationModal',
  components: { BaseModal, CodeEditor, ElForm, ElFormItem },
  props: {
    modelValue: { type: Boolean, default: false },
    configuration: { type: String, default: '' }
  },
  emits: ['close', 'confirm'],
  setup(props, { emit }) {
    const form = ref({
      configuration: ''
    });
    const formRef = ref<HTMLFormElement>();
    const formRules = reactive({
      configuration: [{ validator: mustBeValidJson, trigger: 'blur' }]
    });

    const confirm = () => {
      formRef.value?.validate((valid: boolean) => {
        if (valid) {
          emit('confirm', form.value.configuration);
        }
      });
    };
    const close = () => {
      emit('close');
    };

    onMounted(() => {
      form.value.configuration = props.configuration;
    });

    onUpdated(() => {
      form.value.configuration = props.configuration;
    });

    return { form, formRules, formRef, confirm, close };
  }
};
</script>
<style scoped lang="scss"></style>
