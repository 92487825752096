<template>
  <page-header :main-title="$t('costs')">
    <template #subheader>
      <h4 class="subtitle-2 mt-3">
        {{ $t('costsOverview') }}
      </h4>
    </template>
  </page-header>
  <el-row class="d-flex align-items-center">
    <h4 class="ml-auto">
      {{ $t('tenantActions') + ' :' }}
    </h4>
    <el-button
      type="primary"
      class="ml-2"
      @click="showStopCostSavingModal = true"
    >
      <h4>{{ $t('stopCostSaving') }}</h4>
    </el-button>
  </el-row>
  <el-row :gutter="24" class="mt-6">
    <el-col>
      <el-row>
        <h4 v-if="loadingCosts">{{ $t('loading') }}</h4>
        <h4 v-else class="mt-auto">
          {{ usageCosts.length }} &#183; {{ $t('cost') }}
        </h4>
      </el-row>
    </el-col>
  </el-row>
  <el-row>
    <el-col>
      <base-table
        :show-loading-table="loadingCosts"
        :show-no-content-table="!usageCosts.length"
        :error-message="$t('noCosts')"
      >
        <div class="costs-table">
          <el-table
            :data="usageCosts"
            :summary-method="totalCostsRow"
            show-summary
          >
            <el-table-column type="expand">
              <template #default="scope">
                <el-table :data="scope.row.resourceCloudCostsGroupDetails">
                  <el-table-column prop="ResourceName" :label="$t('name')">
                    <template #default="inner">
                      <div>
                        <h4>{{ inner.row.resourceName }}</h4>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('costLastMonth')" width="200">
                    <template #default="inner">
                      <h4>
                        {{
                          formatCloudCosts(
                            inner.row.lastMonth?.currency,
                            inner.row.lastMonth?.amount
                          )
                        }}
                      </h4>
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('costMtd')" width="200">
                    <template #default="inner">
                      <h4>
                        {{
                          formatCloudCosts(
                            inner.row.monthToDate?.currency,
                            inner.row.monthToDate?.amount
                          )
                        }}
                      </h4>
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </el-table-column>
            <el-table-column prop="ResourcePath" :label="$t('path')">
              <template #default="scope">
                <h4>{{ scope.row.resourcePath }}</h4>
              </template>
            </el-table-column>
            <el-table-column prop="ResourceName" :label="$t('name')">
              <template #default="scope">
                <h4>{{ scope.row.resourceName }}</h4>
              </template>
            </el-table-column>
            <el-table-column :label="$t('costLastMonth')" width="200">
              <template #default="scope">
                <h4>
                  {{
                    formatCloudCosts(
                      scope.row.lastMonth?.currency,
                      scope.row.lastMonth?.amount
                    )
                  }}
                </h4>
              </template>
            </el-table-column>
            <el-table-column :label="$t('costMtd')" width="200">
              <template #default="scope">
                <h4>
                  {{
                    formatCloudCosts(
                      scope.row.monthToDate?.currency,
                      scope.row.monthToDate?.amount
                    )
                  }}
                </h4>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </base-table>
    </el-col>
  </el-row>
  <stop-cost-saving-modal
    v-model="showStopCostSavingModal"
    @confirm="stopCostSaving"
  />
</template>
<script lang="ts">
import {
  ElTable,
  ElTableColumn,
  ElRow,
  ElCol,
  ElButton,
  ElMessage
} from 'element-plus';

import BaseTable from '@/components/tables/BaseTable.vue';

import PageHeader from '@/components/misc/PageHeader.vue';
import i18n from '@/i18n';
import StopCostSavingModal from '@/components/modals/StopCostSavingModal.vue';

import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { AutopauseExclusionInput } from '@etp/etp-costsaving-client/axios';
export default {
  name: 'CostsOverview',
  components: {
    ElTable,
    ElTableColumn,
    PageHeader,
    ElRow,
    ElCol,
    ElButton,
    BaseTable,
    StopCostSavingModal
  },
  setup() {
    const store = useStore();
    const loadingCosts = computed(() => store.getters['costs/getLoadingCosts']);

    const usageCosts = computed(
      () => store.getters['costs/getCostsPerResource']
    );

    const totalCosts = computed(() => store.getters['costs/getTotalCosts']);

    const formatCloudCosts = (currency: string, amount: string) => {
      if (currency == 'N/A') {
        return i18n.global.t('na');
      } else {
        return currency + ' ' + amount;
      }
    };

    const totalCostsRow = () => {
      const sums: string[] = [];
      sums[0] = '';
      sums[1] = '';
      sums[2] = i18n.global.t('total') + ':';
      sums[3] = formatCloudCosts(
        totalCosts.value.lastMonth?.currency,
        totalCosts.value.lastMonth?.amount
      );
      sums[4] = formatCloudCosts(
        totalCosts.value.monthToDate?.currency,
        totalCosts.value.monthToDate?.amount
      );
      return sums;
    };

    let showStopCostSavingModal = ref(false);
    let stopCostSaving = async (payload: AutopauseExclusionInput) => {
      showStopCostSavingModal.value = false;
      const httpStatusCode = await store.dispatch(
        'costs/stopCostSavingForTenant',
        payload
      );
      if (httpStatusCode < 200 || httpStatusCode >= 300) {
        ElMessage({
          showClose: true,
          type: 'error',
          message: i18n.global.t('stopCostSavingFailed')
        });
      }
    };

    onMounted(async () => {
      await store.dispatch('costs/fetchCostsPerResource');
      await store.dispatch('costs/fetchTotalCosts');
    });

    return {
      totalCosts,
      totalCostsRow,
      usageCosts,
      loadingCosts,
      formatCloudCosts,
      showStopCostSavingModal,
      stopCostSaving
    };
  }
};
</script>
<style scoped lang="scss"></style>
