<template>
  <el-form :model="form">
    <el-form-item prop="deployTopicPrivateEndpoint" class="mb-0">
      <el-checkbox
        class="checkbox"
        :label="$t('deployTopicPrivateEndpoint')"
        :model-value="form.deployTopicPrivateEndpoint"
        @change="handleChangeDeployTopicPrivateEndpoint"
      />
    </el-form-item>
  </el-form>
</template>
<script lang="ts">
import { ElForm, ElFormItem, ElCheckbox } from 'element-plus';
import { EventGridTopicConfiguration } from '@/views/admin/tenants/models/TenantAzureConfigurationObjects';
import { onMounted, onUpdated, ref } from 'vue';

export default {
  name: 'EventGridConfigurationForm',
  components: { ElForm, ElFormItem, ElCheckbox },
  props: {
    configuration: { type: String, default: '' }
  },
  emits: ['update-configuration'],
  setup(props, { emit }) {
    let form = ref(new EventGridTopicConfiguration());
    let localConfugrationObject = ref({});

    const emitUpdateEvent = () => {
      emit(
        'update-configuration',
        JSON.stringify({
          ...localConfugrationObject.value,
          ...form.value
        })
      );
    };

    const handleChangeDeployTopicPrivateEndpoint = (newValue: boolean) => {
      form.value.deployTopicPrivateEndpoint = newValue;
      emitUpdateEvent();
    };

    const updateLocalVars = () => {
      localConfugrationObject.value = JSON.parse(props.configuration || '{}');
      form.value.deployTopicPrivateEndpoint =
        localConfugrationObject.value['deployTopicPrivateEndpoint'] ?? false;
    };

    onMounted(() => {
      updateLocalVars();
      emitUpdateEvent();
    });
    onUpdated(() => updateLocalVars());

    return {
      form,
      handleChangeDeployTopicPrivateEndpoint
    };
  }
};
</script>
<style scoped lang="scss"></style>
