import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4737ce26"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "body-1 ml-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.dashboards.slice(0, 5), (item) => {
      return (_openBlock(), _createElementBlock("div", {
        key: item.id,
        class: "dashboards-panel-item",
        onClick: ($event: any) => (
      _ctx.$router.push({
        name: 'DashboardDetails',
        params: { reportId: item.id }
      })
    )
      }, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(item.name), 1)
      ], 8, _hoisted_1))
    }), 128)),
    _createElementVNode("h4", {
      class: "text mt-3 ml-1",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({ name: 'DashboardOverview' })))
    }, _toDisplayString(_ctx.$t('seeAllDashboards')), 1)
  ], 64))
}