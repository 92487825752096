<template>
  <div class="privacy-wrapper">
    <el-row :gutter="5" justify="center">
      <el-col :span="14">
        <page-header class="page-header" :main-title="$t('privacyStatement')" />
        <p>
          Deloitte provides global, regional, national and/or practice-specific
          services; each service is administered by Deloitte Touche Tohmatsu
          Limited
          <b>(‘DTTL’)</b>
          or by one of the affiliated member firms or entities related thereto
          (jointly referred to as the
          <b>‘Deloitte Network’</b>
          ).
        </p>
        <p>
          Deloitte Belastingadviseurs B.V. (hereinafter also referred to as
          <b>‘we’, ‘us’, or ‘our’</b>
          ) is a part of Deloitte NSE, the member firm affiliated to DTTL. We
          are a global organization that handles privacy consciously. We are the
          entity within the Deloitte Network responsible for Enterprise Tax
          Platform - Core (hereinafter also referred as ETP Core) and act as
          ‘controller’ in the sense of the General Data Protection Regulation
          <b>(‘GDPR’)</b>
          . In this Privacy Statement we explain how we use and protect the data
          of the individuals that are directly or indirectly involved with ETP
          Core (hereinafter referred to as
          <b>‘the individuals’</b>
          ). Please contact your Deloitte engagement partner for more
          information on the nature of cooperation between you, the client
          (hereinafter referred to as ‘the client’), and Deloitte
          Belastingadviseurs B.V.
        </p>
        <p>
          We point out that this Privacy Statement does not apply to the other
          services or products provided by other entities within the Deloitte
          Network.
        </p>
        <h3>Data collection</h3>
        <p>
          The personal data that falls within the scope of this Privacy
          Statement is originally collected by the client.
        </p>
        <p>
          Whilst providing ETP Core, it is necessary to process the personal
          data of the individuals for the purposes of the legitimate interest
          pursued by us or by the client. The legitimate interests are specified
          in the underlying engagement letter granting access to ETP Core and
          may include compliance with a legal obligation to which the client is
          subject to.
        </p>
        <p>
          The purpose of processing the personal data is specified in the
          underlying engagement letter. The underlying engagement letter is
          always available from your Deloitte engagement partner upon request.
        </p>
        <p>
          We process the following categories of personal data: contact details.
          Additional categories of personal data may be processed and are
          specified in the engagement letter. Throughout this Privacy Statement
          these categories of personal data are jointly referred to as
          <b>‘the personal data’</b>
          .
        </p>
        <p>
          We do not intentionally collect special categories of personal data,
          such as data relating to race or ethnic origin, religious conviction,
          criminal record, physical and mental health status or sexual
          orientation. If unforeseen, special categories of personal data are
          being processed, for example when a medical data of individuals are
          processed, we will ensure that the appropriate measures are in place
          for compliant processing.
        </p>
        <h3>Use of data</h3>
        <p>
          The beforementioned types of personal data will only be used to
          provide the services rendered as mentioned in the engagement letter
          through ETP Core. We will not use the data for any other type of
          activity. We will not disclose the personal data to any third party,
          unless we are obliged to do so by law. There is no automated
          decision-making, including profiling, involved in the processing of
          the personal data. The personal data may also be used to protect our
          rights or properties and, if necessary, to comply with legal
          proceedings. In any case, the personal data is not processed outside
          the European Union/European Economic Area (EU/EAA).
        </p>
        <h3>Access to data</h3>
        <p>
          Individuals wishing to exercise their rights under the GDPR, such as
          accessing, rectifying or erasing personal data, can contact our
          Privacy Office through this
          <a
            href="https://www2.deloitte.com/nl/nl/footerlinks/contact.html"
            target="_blank"
          >
            contact form.
          </a>
        </p>
        <h3>Retention</h3>
        <p>
          We will process the personal data for the duration of the services
          mentioned in the engagement letters. The personal data can be retained
          for a longer period, if this is required either, directly or
          indirectly by law, or by another type of obligation where Deloitte
          Belastingadviseurs B.V. is subject to, such as insurance agreements or
          other contractual obligations. We will make our best effort to ensure
          optimal data protection measures are in place to make sure that the
          personal data is securely processed throughout its entire lifecycle.
        </p>
        <h3>Complaints</h3>
        <p>
          We make every effort to process personal data in a lawful manner. If
          individuals are of the opinion that we violate or harm any of their
          privacy rights, they have the right to lodge a complaint with the
          Dutch Data Protection Authority (‘Autoriteit Persoonsgegevens’).
          Before turning to the Data Protection Authority, we kindly request
          individuals to first inform our Privacy Office through this
          <a
            href="https://www2.deloitte.com/nl/nl/footerlinks/contact.html"
            target="_blank"
          >
            contact form.
          </a>
        </p>
        <h3>Contact</h3>
        <p>
          If there are any questions or concerns about the processing of
          personal data in respect of ETP Core, please contact our Privacy
          Office through this
          <a
            href="https://www2.deloitte.com/nl/nl/footerlinks/contact.html"
            target="_blank"
          >
            contact form.
          </a>
        </p>
        <hr />
      </el-col>
    </el-row>
  </div>
</template>

<script lang="ts">
import PageHeader from '@/components/misc/PageHeader.vue';
import { ElRow, ElCol } from 'element-plus';

export default {
  name: 'PrivacyPage',
  components: { PageHeader, ElRow, ElCol }
};
</script>

<style lang="scss" scoped>
.page-header {
  margin-bottom: 0px;
}
.privacy-wrapper {
  max-height: 88vh;
  position: absolute;
  overflow-y: scroll;
  overflow-x: hidden;
  h3 {
    margin: 20px 0px 10px 0px;
  }
  p {
    text-align: justify;
    color: $etp-darker-grey;
  }
  hr {
    margin-top: 20px;
  }
}
</style>
