import { ActionContext, Module } from 'vuex';
import { createDashboardingApiClient } from '@/services/api';
import {
  DashboardInfo,
  EmbeddedDashboard,
  ProvisioningState
} from '@etp/etp-dashboarding-client/axios';

interface DashboardState {
  loadingDashboards: boolean;
  dashboards: Array<DashboardInfo>;
  embeddedDashboard: EmbeddedDashboard;
  analysisServicesStatus: ProvisioningState;
  powerBiStatus: ProvisioningState;
}

class DashboardModule implements Module<DashboardState, any> {
  namespaced = true;
  state = {
    loadingDashboards: false,
    dashboards: Array<DashboardInfo>(),
    embeddedDashboard: {} as EmbeddedDashboard,
    analysisServicesStatus: ProvisioningState.Suspended,
    powerBiStatus: ProvisioningState.Suspended
  };
  getters = {
    getDashboards: (state: DashboardState): Array<DashboardInfo> =>
      state.dashboards,
    getAnalysisServicesStatus: (state: DashboardState) =>
      state.analysisServicesStatus,
    getPowerBiStatus: (state: DashboardState) => state.powerBiStatus,
    getEmbeddedDashboard: (state: DashboardState) => state.embeddedDashboard,
    loadingDashboards: (state: DashboardState) => state.loadingDashboards
  };
  actions = {
    async uploadDashboard(
      context: ActionContext<DashboardState, any>,
      payload: {
        file: globalThis.File;
      }
    ) {
      let engagementId = context.rootState.home.currentEngagement.id;
      let client = createDashboardingApiClient();
      return await client.uploadDashboard(engagementId, payload.file);
    },
    async fetchDashboards(context: ActionContext<DashboardState, any>) {
      let client = createDashboardingApiClient();
      context.commit('SET_LOADING_DASHBOARDS', true);
      let engagementId = context.rootState.home.currentEngagement.id;
      const response = await client.getDashboards(engagementId);
      if (response.status === 200) {
        context.commit('SET_DASHBOARDS', response.data);
      } else {
        context.commit('SET_DASHBOARDS', []);
      }
      context.commit('SET_LOADING_DASHBOARDS', false);
    },
    async deleteDashboard(
      context: ActionContext<DashboardState, any>,
      reportId: string
    ) {
      let client = createDashboardingApiClient();
      let engagementId = context.rootState.home.currentEngagement.id;
      await client.deleteDashboard(engagementId, reportId);
    },
    async fetchAnalysisServicesStatus(
      context: ActionContext<DashboardState, any>
    ) {
      let client = createDashboardingApiClient();
      let engagementId = context.rootState.home.currentEngagement.id;
      const { data, status } = await client.getAnalysisServicesStatus(
        engagementId
      );
      if (status == 200) {
        context.commit('SET_ANALYSISSERVICES_STATUS', data);
      } else {
        context.commit('SET_ANALYSISSERVICES_STATUS', ProvisioningState.Failed);
      }
    },
    async resumeAnalysisServices(context: ActionContext<DashboardState, any>) {
      let client = createDashboardingApiClient();
      let engagementId = context.rootState.home.currentEngagement.id;
      await client.resumeAnalysisServices(engagementId);
    },
    async suspendAnalysisServices(context: ActionContext<DashboardState, any>) {
      let client = createDashboardingApiClient();
      let engagementId = context.rootState.home.currentEngagement.id;
      await client.suspendAnalysisServices(engagementId);
    },
    async fetchPowerBiStatus(context: ActionContext<DashboardState, any>) {
      let client = createDashboardingApiClient();
      let engagementId = context.rootState.home.currentEngagement.id;
      const { data, status } = await client.getPowerBiStatus(engagementId);
      if (status == 200) {
        context.commit('SET_POWERBI_STATUS', data);
      } else {
        context.commit('SET_POWERBI_STATUS', ProvisioningState.Failed);
      }
    },
    async resumePowerBi(context: ActionContext<DashboardState, any>) {
      let client = createDashboardingApiClient();
      let engagementId = context.rootState.home.currentEngagement.id;
      await client.resumePowerBi(engagementId);
    },
    async suspendPowerBi(context: ActionContext<DashboardState, any>) {
      let client = createDashboardingApiClient();
      let engagementId = context.rootState.home.currentEngagement.id;
      await client.suspendPowerBi(engagementId);
    },
    async fetchEmbeddedDashboard(
      context: ActionContext<DashboardState, any>,
      dashboardId: string
    ) {
      let client = createDashboardingApiClient();
      let engagementId = context.rootState.home.currentEngagement.id;
      const { data, status } = await client.embedDashboard(
        engagementId,
        dashboardId
      );
      if (status === 200) {
        context.commit('SET_EMBEDDED_DASHBOARD', data);
      } else {
        context.commit('SET_EMBEDDED_DASHBOARD', null);
      }
    }
  };
  mutations = {
    SET_DASHBOARDS(state: DashboardState, payload: Array<DashboardInfo>) {
      state.dashboards = payload;
    },
    SET_ANALYSISSERVICES_STATUS(
      state: DashboardState,
      payload: ProvisioningState
    ) {
      state.analysisServicesStatus = payload;
    },
    SET_POWERBI_STATUS(state: DashboardState, payload: ProvisioningState) {
      state.powerBiStatus = payload;
    },
    SET_EMBEDDED_DASHBOARD(state: DashboardState, payload: EmbeddedDashboard) {
      state.embeddedDashboard = payload;
    },
    SET_LOADING_DASHBOARDS(state: DashboardState, payload: boolean) {
      state.loadingDashboards = payload;
    }
  };
}

export default new DashboardModule();
