export default function arraysEqual<Type>(
  a: Array<Type>,
  b: Array<Type>
): boolean {
  if (a.length != b.length) {
    return false;
  }
  for (let i = 0; i < a.length; i++) {
    for (let key in a[i]) {
      if (a[i][key] != b[i][key]) {
        return false;
      }
    }
  }
  return true;
}
