<template>
  <div>
    <el-row>
      <el-col>
        <h4 class="mt-auto">
          {{ engagements.length }} &#183; {{ $t('engagements') }}
        </h4>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <div class="enagagements-table">
          <el-table :data="engagements">
            <el-table-column prop="name" sortable="custom" :label="$t('name')">
              <template #default="scope">
                <div>
                  <h4>{{ scope.row.name }}</h4>
                  <span class="body-1 mt-1">{{ scope.row.id }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column width="100">
              <template #default="scope">
                <div>
                  <img
                    :src="require(`@/assets/images/icons/new/settings.svg`)"
                    class="action-icon action-spin"
                    height="14"
                    width="14"
                    @click="openEngagementsDetails(scope.row.id)"
                  />
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script lang="ts">
import { useRouter } from 'vue-router';
import { ElTable, ElTableColumn, ElCol, ElRow } from 'element-plus';
import { computed, onMounted, ComputedRef } from 'vue';
import { useStore } from 'vuex';
import { EngagementModel } from '@etp/etp-engagements-client/axios';

export default {
  name: 'TenantEngagements',
  components: {
    ElTable,
    ElTableColumn,
    ElRow,
    ElCol
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    let tenantId: string;

    let engagements: ComputedRef<Array<EngagementModel>> = computed(
      () => store.getters['engagements/getTenantEngagements']
    );

    const fetchEngagements = async (tenantId: string) => {
      await store.dispatch('engagements/fetchTenantEngagements', tenantId);
    };

    const openEngagementsDetails = (engagementId: string) => {
      router.push({
        name: 'EngagementDetails',
        params: { engagementId }
      });
    };

    onMounted(async () => {
      tenantId = router.currentRoute.value.params.tenantId as string;
      await fetchEngagements(tenantId);
    });

    return {
      engagements,
      fetchEngagements,
      openEngagementsDetails
    };
  }
};
</script>
<style></style>
