<template>
  <el-row :gutter="spacingWidthHeaderComponents">
    <base-table
      :show-loading-table="showLoadingTable"
      :show-no-content-table="!promotions.length"
      :error-message="$t('noHistoryToDisplay')"
    >
      <el-table v-if="promotions.length" :data="promotions">
        <el-table-column :label="$t('version')" width="100">
          <template #default="scope">
            <h4>{{ scope.row.sourceVersion }}</h4>
          </template>
        </el-table-column>
        <el-table-column :label="$t('status')" width="120">
          <template #default="scope">
            <h4>
              {{ $t(Object.keys(PromotionStatus)[scope.row.status]) }}
            </h4>
          </template>
        </el-table-column>
        <el-table-column :label="$t('time')" width="120">
          <template #default="scope">
            <h4>{{ formatTimestamp(scope.row.promotedAt) }}</h4>
          </template>
        </el-table-column>
        <el-table-column :label="$t('updatedBy')" width="200">
          <template #default="scope">
            <h4>{{ scope.row.createdBy }}</h4>
          </template>
        </el-table-column>
        <el-table-column :label="$t('comment')">
          <template #default="scope">
            <h4>{{ scope.row.comment }}</h4>
          </template>
        </el-table-column>
        <el-table-column
          v-if="
            !promotionInProgress &&
            versionToRevert > -Infinity &&
            versionToRestore > -Infinity
          "
          width="120"
        >
          <template #default="scope">
            <div class="d-flex justify-content-center">
              <el-button
                v-if="scope.row.sourceVersion == versionToRevert"
                type="primary"
                @click="openRevertModal(scope.row)"
              >
                {{ $t('revert') }}
              </el-button>
              <h4 v-else-if="scope.row.sourceVersion == versionToRestore">
                {{ $t('target') }}
              </h4>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </base-table>
  </el-row>
  <revert-promotion-modal
    v-model="showRevertModal"
    :feedback-text="$t('revertingPromotion')"
    :version-to-revert="versionToRevert"
    :version-to-restore="versionToRestore"
    @confirm="revertPromotion"
  />
</template>
<script lang="ts">
import { ElButton, ElRow, ElTable, ElTableColumn } from 'element-plus';
import { onMounted, computed, ComputedRef, ref } from 'vue';
import { useStore } from 'vuex';
import { spacingWidthHeaderComponents } from '@/utils/variables';
import BaseTable from '@/components/tables/BaseTable.vue';
import RevertPromotionModal from './components/RevertPromotionModal.vue';
import {
  Promotion,
  PromotionStatus,
  RevertPromotionRequest
} from '@etp/etp-gain-bff-client/axios';
import moment from 'moment';

export default {
  name: 'GainRequirementsStatus',
  components: {
    BaseTable,
    ElButton,
    ElRow,
    ElTable,
    ElTableColumn,
    RevertPromotionModal
  },
  setup() {
    const store = useStore();
    const showRevertModal = ref(false);
    const showLoadingTable = ref(true);
    const promotionToRevert = ref({} as Promotion);

    const promotions: ComputedRef<Array<Promotion>> = computed(
      () => store.getters['gain/getPromotions']
    );

    const promotionInProgress: ComputedRef<boolean> = computed(() => {
      var maxVersion = -1;
      var inProgress = false;
      for (var promotion of promotions.value) {
        var promotionVersion = promotion.sourceVersion ?? 0;
        if (promotionVersion > maxVersion) {
          maxVersion = promotionVersion;
          inProgress = promotion.status == PromotionStatus.InProgress;
        }
      }
      return inProgress;
    });

    const versionToRevert: ComputedRef<number> = computed(() => {
      let candididates = promotions.value.filter(
        el =>
          el.status == PromotionStatus.Success ||
          el.status == PromotionStatus.Failed
      );
      return Math.max(...candididates.map(el => el.sourceVersion ?? -Infinity));
    });

    const versionToRestore: ComputedRef<number> = computed(() => {
      let candidates = promotions.value.filter(
        el =>
          el.status == PromotionStatus.Success &&
          (el.sourceVersion ?? -Infinity) < versionToRevert.value
      );
      return Math.max(...candidates.map(el => el.sourceVersion ?? -Infinity));
    });

    const openRevertModal = (row: Promotion) => {
      promotionToRevert.value = row;
      showRevertModal.value = true;
    };

    const revertPromotion = async (payload: RevertPromotionRequest) => {
      payload.promotionId = promotionToRevert.value.promotionId;
      showRevertModal.value = false;
      showLoadingTable.value = true;
      await store.dispatch('gain/revertPromotion', payload);
      await updateLocalVars();
      showLoadingTable.value = false;
    };

    const updateLocalVars = async () => {
      await store.dispatch('gain/fetchPromotions');
    };

    const formatTimestamp = (input: Date) => {
      if (!input) return '';
      return moment(input).format('YYYY-MM-DD');
    };

    onMounted(async () => {
      showLoadingTable.value = true;
      await updateLocalVars();
      showLoadingTable.value = false;
    });

    return {
      formatTimestamp,
      openRevertModal,
      promotionInProgress,
      promotions,
      PromotionStatus,
      revertPromotion,
      showLoadingTable,
      showRevertModal,
      spacingWidthHeaderComponents,
      versionToRestore,
      versionToRevert
    };
  }
};
</script>
