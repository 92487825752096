<template>
  <el-form :model="form">
    <el-form-item :label="$t('azureDatabricksTier')" class="mb-0">
      <el-select
        v-model="form.pricingTier"
        size="small"
        :placeholder="$t('empty')"
        @change="handleChangePricingTier"
      >
        <el-option
          v-for="item in pricingTier"
          :key="item"
          :label="item"
          :value="item"
        ></el-option>
      </el-select>
    </el-form-item>
  </el-form>
</template>
<script lang="ts">
import { ElForm, ElFormItem, ElSelect, ElOption } from 'element-plus';
import { DatabricksConfiguration } from '@/views/admin/tenants/models/TenantAzureConfigurationObjects';
import { onMounted, onUpdated, ref } from 'vue';

export default {
  name: 'DatabricksWorkspaceConfigurationCard',
  components: { ElForm, ElFormItem, ElSelect, ElOption },
  props: {
    configuration: { type: String, default: '' }
  },
  emits: ['update-configuration'],
  setup(props, { emit }) {
    let form = ref(new DatabricksConfiguration());
    let localConfigurationObject = ref({});
    const pricingTier = ['Standard', 'Premium'];

    const emitUpdateEvent = () => {
      emit(
        'update-configuration',
        JSON.stringify({
          ...localConfigurationObject.value,
          ...form.value
        })
      );
    };

    const handleChangePricingTier = (newValue: string) => {
      form.value.pricingTier = newValue;
      emitUpdateEvent();
    };

    const updateLocalVars = () => {
      localConfigurationObject.value = JSON.parse(props.configuration || '{}');
    };

    onMounted(() => {
      updateLocalVars();
      emitUpdateEvent();
    });
    onUpdated(() => updateLocalVars());

    return {
      form,
      handleChangePricingTier,
      pricingTier
    };
  }
};
</script>
<style scoped lang="scss"></style>
