<template>
  <page-header :main-title="$t('modeling')">
    <template #tabs>
      <div class="d-flex mt-5">
        <router-link
          v-if="userCanReadEngagementNodeModelGit && showNodeModelGitPages"
          v-slot="{ isActive }"
          :to="{ name: 'ModelConfig' }"
        >
          <base-tab :active="isActive">
            <h4>{{ $t('configure') }}</h4>
          </base-tab>
        </router-link>
        <router-link
          v-if="
            userCanReadEngagementNodeModelGit &&
            showNodeModelGitPages &&
            !isGainEngagement
          "
          v-slot="{ isActive }"
          :to="{ name: 'ModelBuilding' }"
        >
          <base-tab :active="isActive">
            <h4>{{ $t('build') }}</h4>
          </base-tab>
        </router-link>
        <router-link
          v-if="showPipelinePages && !isGainEngagement"
          v-slot="{ isActive }"
          :to="{ name: 'ModelRun' }"
        >
          <base-tab :active="isActive">
            <h4>{{ $t('run') }}</h4>
          </base-tab>
        </router-link>
        <router-link
          v-if="showPipelinePages"
          v-slot="{ isActive }"
          :to="{ name: 'ModelMonitor' }"
        >
          <base-tab :active="isActive">
            <h4>{{ $t('monitor') }}</h4>
          </base-tab>
        </router-link>
        <div class="horizontal-line mt-2">
          <hr />
        </div>
      </div>
    </template>
    <template #subheader>
      <div class="mt-3">
        <h4 v-if="routerName == 'ModelConfig'" class="subtitle-2">
          {{ $t('modelingConfigDesc') }}
        </h4>
        <h4 v-if="routerName == 'ModelBuilding'" class="subtitle-2">
          {{ $t('modelingBuildingDesc') }}
        </h4>
        <h4 v-if="routerName == 'ModelRun'" class="subtitle-2">
          {{ $t('runTabDesc') }}
        </h4>
        <h4 v-if="routerName == 'ModelMonitor'" class="subtitle-2">
          {{ $t('monitorTabDesc') }}
        </h4>
      </div>
    </template>
  </page-header>
  <div class="mt-6">
    <router-view />
  </div>
</template>
<script lang="ts">
import PageHeader from '@/components/misc/PageHeader.vue';
import BaseTab from '@/components/tabs/BaseTab.vue';

import { ref, ComputedRef, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default {
  name: 'ModelOverview',
  components: { PageHeader, BaseTab },
  setup() {
    const router = useRouter();
    const store = useStore();

    let routerName = ref(router.currentRoute.value.name);

    const isGainEngagement: ComputedRef<boolean> = computed(
      () => store.getters['home/isGainEngagement']
    );
    const showNodeModelApiPages: ComputedRef<boolean> = computed(
      () => store.getters['home/showNodeModelApiPages']
    );
    const showNodeModelGitPages: ComputedRef<boolean> = computed(
      () => store.getters['home/showNodeModelGitPages']
    );
    const showPipelinePages: ComputedRef<boolean> = computed(
      () => store.getters['home/showPipelinePages']
    );
    const userCanReadEngagementNodeModelGit: ComputedRef<boolean> = computed(
      () => store.getters['userPermissions/canReadEngagementNodeModelGit']
    );

    onMounted(async () => {
      await store.dispatch(
        'userPermissions/checkIfCanReadEngagementNodeModelGit'
      );
    });

    return {
      isGainEngagement,
      routerName,
      showNodeModelApiPages,
      showNodeModelGitPages,
      showPipelinePages,
      userCanReadEngagementNodeModelGit
    };
  }
};
</script>
<style scoped lang="scss"></style>
