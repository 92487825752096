import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_popover = _resolveComponent("el-popover")!

  return (_openBlock(), _createBlock(_component_el_popover, {
    placement: "left-start",
    width: 525,
    trigger: "hover"
  }, {
    reference: _withCtx(() => [
      _createVNode(_component_el_tag, {
        size: "default",
        type: "warning"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('underConstruction')), 1)
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('underConstructionDesc')) + " ", 1)
    ]),
    _: 1
  }))
}