import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_base_card = _resolveComponent("base-card")!

  return (_openBlock(), _createBlock(_component_base_card, { "no-header": "" }, {
    body: _withCtx(() => [
      _createVNode(_component_el_form, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, {
            label: _ctx.$t('branch'),
            class: "mb-0"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_select, {
                modelValue: $setup.localSelectedBranch,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.localSelectedBranch) = $event)),
                disabled: $props.loadingBranches,
                "value-key": "name",
                size: "small",
                placeholder: $props.loadingBranches ? _ctx.$t('loading') : _ctx.$t('empty'),
                onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:selectedBranch', $setup.localSelectedBranch)))
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.branches, (item) => {
                    return (_openBlock(), _createBlock(_component_el_option, {
                      key: item.name,
                      label: item.name,
                      value: item
                    }, null, 8, ["label", "value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modelValue", "disabled", "placeholder"])
            ]),
            _: 1
          }, 8, ["label"]),
          _renderSlot(_ctx.$slots, "item")
        ]),
        _: 3
      })
    ]),
    _: 3
  }))
}