import DashboardOverview from '@/views/home/reporting/dashboard/DashboardOverview.vue';
import DashboardDetails from '@/views/home/reporting/dashboard/DashboardDetails.vue';

export default [
  {
    path: '/dashboards',
    name: 'DashboardOverview',
    component: DashboardOverview,
    meta: { menuItemName: 'DashboardOverview' }
  },
  {
    path: '/dashboards/:reportId',
    name: 'DashboardDetails',
    component: DashboardDetails,
    meta: { menuItemName: 'DashboardOverview' }
  }
];
