<template>
  <el-row :gutter="spacingWidthHeaderComponents">
    <el-col :span="5">
      <base-card no-header>
        <template #body>
          <el-form>
            <el-form-item class="mb-0" :label="$t('branch')">
              <div class="d-flex align-items-center">
                <el-select
                  v-model="selectedBranch"
                  :disabled="loadingBranches"
                  value-key="name"
                  size="small"
                  :placeholder="loadingBranches ? $t('loading') : $t('empty')"
                >
                  <el-option
                    v-for="item in branches"
                    :key="item.name ?? ''"
                    :label="item.name ?? ''"
                    :value="item"
                  ></el-option>
                </el-select>
                <el-button
                  class="icon-button p-1 cursor-pointer"
                  @click="showBranchModal = true"
                >
                  <plus class="icon-size-8" />
                </el-button>
              </div>
            </el-form-item>
            <el-form-item :label="$t('node')" class="mb-0">
              <div class="d-flex align-items-center">
                <el-select
                  v-model="selectedNode"
                  :disabled="!selectedBranch.name || loadingNodes"
                  value-key="name"
                  size="small"
                  :placeholder="loadingNodes ? $t('loading') : $t('empty')"
                >
                  <el-option
                    v-for="item in nodes"
                    :key="item.name ?? ''"
                    :label="item.name ?? ''"
                    :value="item"
                  ></el-option>
                </el-select>
                <el-button
                  :disabled="!selectedBranch.name || selectedBranch.readOnly"
                  class="icon-button p-1 cursor-pointer"
                  @click="showNodeModal = true"
                >
                  <plus class="icon-size-8" />
                </el-button>
              </div>
            </el-form-item>
          </el-form>
        </template>
      </base-card>
      <base-card v-if="selectedNode.name" no-header class="mt-1">
        <template #body>
          <el-form class="model-form" label-position="top">
            <el-form-item :label="$t('fields')">
              <el-scrollbar max-height="200px">
                <model-list-item
                  v-for="field in fieldsObjects"
                  :key="field.name ?? ''"
                  :item="field"
                />
              </el-scrollbar>
              <div
                v-if="!selectedBranch.readOnly"
                class="edit-fields-container"
                @click="showFieldsModal = true"
              >
                <div class="body-1 edit-fields-icon">
                  <img
                    src="@/assets/images/icons/new/edit.svg"
                    class="edit-field action-icon icon-size-8"
                  />
                  <h4>{{ $t('editFields') }}</h4>
                </div>
              </div>
            </el-form-item>
          </el-form>
        </template>
      </base-card>
    </el-col>
    <el-col :span="19">
      <base-card no-header>
        <template #body>
          <div class="d-flex pr-2">
            <el-form>
              <el-form-item :label="$t('category')" class="mb-0">
                <div class="d-flex align-items-center">
                  <el-select
                    v-model="selectedCategory"
                    :disabled="!selectedNode.name || loadingCategories"
                    value-key="name"
                    size="small"
                    :placeholder="
                      loadingCategories ? $t('loading') : $t('empty')
                    "
                  >
                    <el-option
                      v-for="item in categories"
                      :key="item.name ?? ''"
                      :label="item.name ?? ''"
                      :value="item"
                    ></el-option>
                  </el-select>
                  <el-button
                    class="icon-button p-1 cursor-pointer"
                    :disabled="!selectedBranch.name || !selectedNode.name"
                    @click="showCategoryModal = true"
                  >
                    <plus class="icon-size-8" />
                  </el-button>
                </div>
              </el-form-item>
            </el-form>
            <div
              v-if="categoryDetail.query && !selectedBranch.readOnly"
              class="ml-auto"
            >
              <el-button
                type="primary"
                class="mt-2"
                @click.stop="updateCategoryDetail"
              >
                <h4>{{ $t('save') }}</h4>
              </el-button>
            </div>
          </div>
          <el-form>
            <el-form-item :label="$t('queryEditor')">
              <code-editor
                v-model="categoryDetail.query"
                :read_only="!selectedCategory.name"
                font_size="13px"
                :languages="[['SQL', 'SQL']]"
                theme="light"
                min_height="300px"
                min_width="100%"
              ></code-editor>
            </el-form-item>
          </el-form>
        </template>
      </base-card>
    </el-col>
  </el-row>
  <fields-modal
    :dialog-visible="showFieldsModal"
    :fields="fields"
    :all-fields-in-query="allFieldsInQuery"
    @confirm="updateFields"
    @close="showFieldsModal = false"
  />
  <new-branch-modal
    :show-modal="showBranchModal"
    :branches="branches"
    @confirm="addNewBranch"
    @close="showBranchModal = false"
  />
  <new-node-modal
    :show-modal="showNodeModal"
    :nodes="nodes"
    @confirm="addNewNode"
    @close="showNodeModal = false"
  />
  <new-category-modal
    :show-modal="showCategoryModal"
    @confirm="addNewCategory"
    @close="showCategoryModal = false"
  />
</template>

<script lang="ts">
import {
  ElButton,
  ElCol,
  ElForm,
  ElFormItem,
  ElOption,
  ElRow,
  ElSelect,
  ElScrollbar,
  ElMessage
} from 'element-plus';

import BaseCard from '@/components/panels/BaseCard.vue';
import FieldsModal from '@/components/modals/FieldsModal.vue';
import ModelListItem from '@/components/list/BaseFieldslListItem.vue';
import NewBranchModal from '@/components/modals/NewBranchModal.vue';
import NewCategoryModal from '@/components/modals/NewCategoryModal.vue';
import NewNodeModal from '@/components/modals/NewNodeModal.vue';
import CodeEditor from 'simple-code-editor/CodeEditor.vue';
import { computed, ComputedRef, onMounted, ref } from 'vue';
import { spacingWidthHeaderComponents } from '@/utils/variables';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { appendUrlParam, processUrlParams } from '@/utils/urlParameter';
import { computeGitFields, getFieldsFromQuery } from '@/utils/fieldsHelper';
import i18n from '@/i18n';
import { Plus } from '@element-plus/icons-vue';
import {
  BranchModel,
  CategoryDetailModel,
  CategoryModel,
  CreateBranchModel,
  CreateCategoryModel,
  CreateFieldModel,
  CreateNodeModel,
  FieldModel,
  NodeModel
} from '@etp/etp-nodemodelgit-client/axios';
import { ConfigureField } from '@/services/web-api/dto/nodeModel';

export default {
  name: 'AnalyticalModelConfig',
  components: {
    BaseCard,
    ElButton,
    ElCol,
    ElForm,
    ElFormItem,
    ElOption,
    ElRow,
    ElScrollbar,
    ElSelect,
    FieldsModal,
    ModelListItem,
    NewBranchModal,
    NewCategoryModal,
    NewNodeModal,
    CodeEditor,
    Plus
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    let showBranchModal = ref(false);
    let showNodeModal = ref(false);
    let showCategoryModal = ref(false);
    let showFieldsModal = ref(false);

    let loadingBranches: ComputedRef<boolean> = computed(
      () => store.getters['analytical/loadingBranches']
    );

    let loadingNodes: ComputedRef<boolean> = computed(
      () => store.getters['analytical/loadingNodes']
    );

    let loadingCategories: ComputedRef<boolean> = computed(
      () => store.getters['analytical/loadingCategories']
    );

    const branches: ComputedRef<BranchModel[]> = computed(
      () => store.getters['analytical/branches']
    );
    const nodes: ComputedRef<NodeModel[]> = computed(
      () => store.getters['analytical/configNodes']
    );
    const categories: ComputedRef<CategoryModel[]> = computed(
      () => store.getters['analytical/configCategories']
    );
    const fields: ComputedRef<FieldModel[]> = computed(
      () => store.getters['analytical/configFields']
    );
    const categoryDetail: ComputedRef<CategoryDetailModel> = computed(
      () => store.getters['analytical/configCategoryDetail']
    );

    const fetchBranches = async () => {
      await store.dispatch('analytical/fetchBranches');
    };
    const fetchNodes = async () => {
      await store.dispatch('analytical/fetchConfigNodes');
    };
    const fetchCategories = async () => {
      await store.dispatch('analytical/fetchConfigCategories');
    };
    const fetchFields = async () => {
      await store.dispatch('analytical/fetchConfigFields');
    };
    const fetchCategoryDetail = async () => {
      await store.dispatch('analytical/fetchConfigCategoryDetail');
    };

    const selectedBranch = computed({
      get: () => store.getters['analytical/selectedConfigBranch'],
      set: async (branch: BranchModel) => {
        appendUrlParam('branch', branch.name ?? '');
        store.commit('analytical/SET_SELECTED_CONFIG_BRANCH', branch);
        await fetchNodes();
      }
    });
    const selectedNode = computed({
      get: () => store.getters['analytical/selectedConfigNode'],
      set: async (node: NodeModel) => {
        appendUrlParam('branch', selectedBranch.value.name ?? '');
        appendUrlParam('node', node.name ?? '');
        store.commit('analytical/SET_SELECTED_CONFIG_NODE', node);
        await fetchFields();
        await fetchCategories();
      }
    });
    const selectedCategory = computed({
      get: () => store.getters['analytical/selectedConfigCategory'],
      set: async (category: CategoryModel) => {
        appendUrlParam('branch', selectedBranch.value.name ?? '');
        appendUrlParam('node', selectedNode.value.name ?? '');
        appendUrlParam('category', category.name ?? '');
        store.commit('analytical/SET_SELECTED_CONFIG_CATEGORY', category);
        await fetchCategoryDetail();
      }
    });
    const fieldsObjects = computed<ConfigureField[]>(() =>
      computeGitFields(fields.value ?? [], categoryDetail.value.query ?? '')
    );
    const allFieldsInQuery = computed<string[]>(() =>
      getFieldsFromQuery(categoryDetail.value.query || '')
    );

    const addNewBranch = async (model: CreateBranchModel) => {
      await store.dispatch('analytical/addNewBranch', model);
      await fetchBranches();
      showBranchModal.value = false;
    };
    const addNewCategory = async (model: CreateCategoryModel) => {
      await store.dispatch('analytical/addNewCategory', model);
      await fetchCategories();
      showCategoryModal.value = false;
    };
    const addNewNode = async (model: CreateNodeModel) => {
      await store.dispatch('analytical/addNewNode', model);
      await fetchNodes();
      showNodeModal.value = false;
    };
    const updateCategoryDetail = async () => {
      ElMessage({
        showClose: true,
        message: i18n.global.t('savingChanges')
      });
      await store.dispatch('analytical/updateConfigCategoryDetail');
      await fetchCategoryDetail();
    };
    const updateFields = async (fields: Array<CreateFieldModel>) => {
      await store.dispatch('analytical/updateConfigFields', fields);
      await fetchFields();
      showFieldsModal.value = false;
    };

    let showNodeModelGitPages: ComputedRef<boolean> = computed(
      () => store.getters['home/showNodeModelGitPages']
    );
    onMounted(async () => {
      if (!showNodeModelGitPages.value) {
        return router.push({ name: 'Overview' });
      }
      fetchBranches().then(async () => {
        await processUrlParams([
          {
            name: 'branch',
            handler: async (branchName: string) => {
              let branch = branches.value.find(
                (el: BranchModel) => el.name == branchName
              );
              if (!branch) return;
              store.commit('analytical/SET_SELECTED_CONFIG_BRANCH', branch);
              await fetchNodes();
            }
          },
          {
            name: 'node',
            handler: async (nodeName: string) => {
              let node = nodes.value.find(
                (el: NodeModel) => el.name == nodeName
              );
              if (!node) return;
              store.commit('analytical/SET_SELECTED_CONFIG_NODE', node);
              await fetchFields();
              await fetchCategories();
            }
          },
          {
            name: 'category',
            handler: async (categoryName: string) => {
              let category = categories.value.find(
                (el: CategoryModel) => el.name == categoryName
              );
              if (!category) return;
              store.commit('analytical/SET_SELECTED_CONFIG_CATEGORY', category);
              await fetchCategoryDetail();
            }
          }
        ]);
      });
    });

    return {
      addNewBranch,
      addNewCategory,
      addNewNode,
      allFieldsInQuery,
      branches,
      categories,
      categoryDetail,
      fields,
      fieldsObjects,
      nodes,
      selectedBranch,
      selectedCategory,
      selectedNode,
      loadingBranches,
      loadingNodes,
      loadingCategories,
      showBranchModal,
      showCategoryModal,
      showFieldsModal,
      showNodeModal,
      spacingWidthHeaderComponents,
      updateCategoryDetail,
      updateFields
    };
  }
};
</script>
<style lang="scss" scoped>
.form-item {
  flex-grow: 1;
}
</style>
