<template>
  <base-card no-header>
    <template #body>
      <el-row>
        <el-col :span="6">
          <h3>{{ $t('nodeModel') }}</h3>
        </el-col>
        <el-col :span="12">
          <el-form ref="formRef" :model="form" :rules="formRules">
            <el-form-item
              class="mb-0"
              :label="$t('implementation')"
              prop="implementation"
            >
              <el-select
                v-model="form.nodeModelPlatformType"
                size="small"
                :placeholder="$t('empty')"
                class="mb-0"
              >
                <el-option
                  v-for="(index, item) in NodeModelPlatformType"
                  :key="index"
                  :label="item"
                  :value="index"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              class="mb-0"
              :label="$t('repository')"
              prop="nodeModelRepositoryName"
            >
              <el-select
                v-model="form.nodeModelRepositoryName"
                size="small"
                :placeholder="$t('empty')"
              >
                <el-option
                  v-for="item in localGitRepositories"
                  :key="item.name ?? ''"
                  :label="item.name ?? ''"
                  :value="item.name"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              v-if="!props.hideSqlFields"
              class="mb-0"
              :label="$t('sqlServerName')"
              prop="nodeModelSqlServerName"
            >
              <el-select
                v-model="form.nodeModelSqlServerName"
                clearable
                size="small"
                :placeholder="$t('empty')"
              >
                <el-option
                  v-for="item in localSqlServers"
                  :key="item.name ?? ''"
                  :label="item.name ?? ''"
                  :value="item.name"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              v-if="!props.hideSqlFields"
              class="mb-0"
              :label="$t('sqlDatabase')"
              prop="nodeModelDatabaseName"
            >
              <el-select
                v-model="form.nodeModelDatabaseName"
                clearable
                size="small"
                :placeholder="$t('empty')"
              >
                <el-option
                  v-for="item in localDatabases"
                  :key="item.name ?? ''"
                  :label="item.name ?? ''"
                  :value="item.name"
                />
              </el-select>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="6" class="d-flex">
          <el-button
            class="ml-auto"
            type="primary"
            :disabled="disabled"
            @click="save"
          >
            <h4>{{ $t('save') }}</h4>
          </el-button>
        </el-col>
      </el-row>
    </template>
  </base-card>
</template>
<script lang="ts">
import BaseCard from '@/components/panels/BaseCard.vue';
import { mustNotBeEmpty } from '@/utils/formValidators';
import { onUpdated, reactive, ref } from 'vue';
import {
  ElButton,
  ElCol,
  ElForm,
  ElFormItem,
  ElOption,
  ElRow,
  ElSelect
} from 'element-plus';
import {
  CreateOrUpdateEngagementNodeModelResourceDto,
  EngagementConfiguration,
  NodeModelPlatformType
} from '@etp/etp-nodemodelgit-client/axios';
import { ResourceType, ResourceModel } from '@etp/etp-infra-client';

export default {
  name: 'NodeModelConfigurationCard',
  components: {
    BaseCard,
    ElButton,
    ElCol,
    ElForm,
    ElFormItem,
    ElOption,
    ElRow,
    ElSelect
  },
  props: {
    configuration: {
      type: Object as () => EngagementConfiguration,
      default: {} as EngagementConfiguration,
      required: true
    },
    disabled: { type: Boolean, default: false },
    hideSqlFields: { type: Boolean, default: false },
    resources: {
      type: Array as () => Array<ResourceModel>,
      default: () => new Array<ResourceModel>()
    }
  },
  emits: ['update'],
  setup(props, { emit }) {
    const form = reactive({
      nodeModelPlatformType: -1,
      nodeModelRepositoryName: '',
      nodeModelSqlServerName: '',
      nodeModelDatabaseName: ''
    });
    const formRef = ref<HTMLFormElement>();
    const formRules = reactive({
      nodeModelPlatformType: [
        {
          validator: mustNotBeEmpty,
          trigger: 'blur'
        }
      ],
      nodeModelRepositoryName: [
        {
          validator: mustNotBeEmpty,
          trigger: 'blur'
        }
      ]
    });

    const save = async () => {
      formRef.value?.validate((valid: boolean) => {
        if (valid) {
          emit('update', {
            nodeModelPlatformType: form.nodeModelPlatformType,
            nodeModelPlatformConfiguration: createEngagementConfiguration()
          } as CreateOrUpdateEngagementNodeModelResourceDto);
        }
      });
    };

    const createEngagementConfiguration = () => {
      switch (form.nodeModelPlatformType) {
        case 0:
          return JSON.stringify({
            NodeModelRepositoryId: getRepositoryIdByName(
              form.nodeModelRepositoryName
            ),
            NodeModelSqlServerName: form.nodeModelSqlServerName,
            NodeModelDatabaseName: form.nodeModelDatabaseName
          });
        default:
          return '';
      }
    };

    const getRepositoryIdByName = (repositoryName: string) => {
      let repositoryObject = localGitRepositories.value.find(
        el => el.name == repositoryName
      );
      if (!repositoryObject) return '';
      return (
        JSON.parse(repositoryObject.configuration ?? '{}')['repositoryId'] ?? ''
      );
    };

    const getRepositoryNameById = (repositoryId: string) => {
      let repositoryObject = localGitRepositories.value.find(
        el =>
          JSON.parse(el.configuration ?? '{}')['repositoryId'] == repositoryId
      );
      return repositoryObject?.name ?? '';
    };

    let localGitRepositories = ref(new Array<ResourceModel>());
    let localSqlServers = ref(new Array<ResourceModel>());
    let localDatabases = ref(new Array<ResourceModel>());

    onUpdated(() => {
      localGitRepositories.value = props.resources.filter(
        el => el.resourceType == ResourceType.GitRepository
      );
      localSqlServers.value = props.resources.filter(
        el => el.resourceType == ResourceType.SqlServer
      );
      localDatabases.value = props.resources.filter(
        el => el.resourceType == ResourceType.SqlDatabase
      );
      let nodeModelConfig = JSON.parse(
        props.configuration.nodeModelPlatformConfiguration || '{}'
      );
      form.nodeModelPlatformType =
        props.configuration.nodeModelPlatformType ?? -1;
      form.nodeModelRepositoryName = getRepositoryNameById(
        nodeModelConfig['NodeModelRepositoryId']
      );
      form.nodeModelSqlServerName = nodeModelConfig['NodeModelSqlServerName'];
      form.nodeModelDatabaseName = nodeModelConfig['NodeModelDatabaseName'];
    });

    return {
      form,
      formRef,
      formRules,
      localGitRepositories,
      localSqlServers,
      localDatabases,
      NodeModelPlatformType,
      props,
      save
    };
  }
};
</script>
<style scoped lang="scss"></style>
