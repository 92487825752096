import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_row = _resolveComponent("el-row")!
  const _component_pipelines_table = _resolveComponent("pipelines-table")!
  const _component_base_table = _resolveComponent("base-table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_row, { class: "d-block" }, {
      default: _withCtx(() => [
        ($props.loadingPipelines)
          ? (_openBlock(), _createElementBlock("h4", _hoisted_1, _toDisplayString(_ctx.$t('loading')), 1))
          : (_openBlock(), _createElementBlock("h4", _hoisted_2, _toDisplayString($props.pipelines.length) + " · " + _toDisplayString(_ctx.$t('pipelines')), 1))
      ]),
      _: 1
    }),
    _createVNode(_component_base_table, {
      "show-loading-table": $props.loadingPipelines,
      "show-no-content-table": !$props.pipelines.length,
      "error-message": _ctx.$t('noImport')
    }, {
      default: _withCtx(() => [
        _createVNode(_component_pipelines_table, {
          pipelines: $props.pipelines,
          "can-hide-pipelines": $props.canHidePipelines,
          onStartPipelineRun: $props.startPipelineRun,
          onUpdatePipeline: $props.togglePipeline
        }, null, 8, ["pipelines", "can-hide-pipelines", "onStartPipelineRun", "onUpdatePipeline"])
      ]),
      _: 1
    }, 8, ["show-loading-table", "show-no-content-table", "error-message"])
  ], 64))
}