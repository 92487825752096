<template>
  <div
    v-for="item in dashboards.slice(0, 5)"
    :key="item.id"
    class="dashboards-panel-item"
    @click="
      $router.push({
        name: 'DashboardDetails',
        params: { reportId: item.id }
      })
    "
  >
    <span class="body-1 ml-1">{{ item.name }}</span>
  </div>
  <h4
    class="text mt-3 ml-1"
    @click="$router.push({ name: 'DashboardOverview' })"
  >
    {{ $t('seeAllDashboards') }}
  </h4>
</template>
<script lang="ts">
import { computed, onMounted, ComputedRef } from 'vue';
import { useStore } from 'vuex';
import { DashboardInfo } from '@etp/etp-dashboarding-client/axios';
export default {
  name: 'DashboardsPanel',
  setup() {
    const store = useStore();
    let dashboards: ComputedRef<Array<DashboardInfo>> = computed(
      () => store.getters['dashboard/getDashboards']
    );
    onMounted(async () => {
      await store.dispatch('dashboard/fetchDashboards');
    });
    return {
      dashboards
    };
  }
};
</script>
<style lang="scss" scoped>
.dashboards-panel-item {
  background: linear-gradient(
    152.2deg,
    $etp-card-effect-1 0%,
    $etp-card-effect-2 100%
  );
  background-blend-mode: overlay, normal;
  box-shadow: 0px 2px 10px $etp-modal-shadow;
  backdrop-filter: blur(20px);
  border-radius: 16px;
  padding: 0.3em;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 10px 10px $etp-modal-shadow;
  }
}
.dashboards-panel-item:not(:first-child) {
  margin-top: 0.5em;
}
.body-1 {
  line-height: 16px !important;
  font-size: 12px;
  color: $etp-deloitte-blue-7;
}
.text {
  cursor: pointer;
  color: $etp-deloitte-blue-5;
  padding-left: 0.3em;
  &:hover {
    text-shadow: 5px 5px 10px $etp-button-shadow;
  }
}
</style>
