<template>
  <base-card no-header class="width-100">
    <template #body>
      <el-row>
        <el-col :span="5">
          <h3>{{ form.targetTableName }}</h3>
        </el-col>
        <el-col :span="17">
          <el-form ref="formRef" :model="form" :rules="formRules">
            <el-form-item
              :label="$t('targetTableName')"
              prop="targetTableName"
              class="mb-0"
            >
              <el-input
                v-model="form.targetTableName"
                size="small"
                @change="emitUpdate"
              />
            </el-form-item>
            <el-form-item
              :label="$t('sourceFileName')"
              prop="sourceFileName"
              class="mb-0"
            >
              <el-select
                v-model="form.sourceFileName"
                size="small"
                filterable
                allow-create
                @change="emitUpdate"
              >
                <el-option
                  v-for="(item, index) in props.fileConfigurations"
                  :key="index"
                  :value="item.namePattern ?? ''"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              v-if="form.sourceFileName?.toLocaleLowerCase().endsWith('xlsx')"
              :label="$t('sourceFileWorksheetName')"
              prop="sourceFileWorksheetName"
              class="mb-0"
            >
              <el-input
                v-model="form.sourceFileWorksheetName"
                size="small"
                @change="emitUpdate"
              />
            </el-form-item>
            <el-row>
              <el-col :span="8">
                <el-form-item
                  :label="$t('sourceFileRowsToSkip')"
                  prop="sourceFileRowsToSkip"
                  class="mb-0"
                >
                  <el-input-number
                    v-model="form.sourceFileRowsToSkip"
                    :min="0"
                    @change="emitUpdate"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  :label="$t('sourceFileHasHeaderRow')"
                  prop="sourceFileHasHeaderRow"
                  class="mb-0"
                >
                  <el-checkbox
                    v-model="form.sourceFileHasHeaderRow"
                    @change="emitUpdate"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  :label="$t('rowExistenceCriterion')"
                  prop="rowExistenceCriterion"
                  class="mb-0"
                >
                  <el-tooltip
                    placement="top"
                    :content="$t('rowExistenceCriterionTooltip')"
                  >
                    <el-select
                      v-model="form.rowExistenceCriterion"
                      size="small"
                      @change="emitUpdate"
                    >
                      <el-option
                        v-for="(index1, item1) in RowExistenceCriterion"
                        :key="index1"
                        :label="item1"
                        :value="index1"
                      />
                    </el-select>
                  </el-tooltip>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row
              v-if="form.sourceFileName?.toLocaleLowerCase().endsWith('xlsx')"
            >
              <el-col :span="8">
                <el-form-item
                  :label="$t('sourceFileColsToSkip')"
                  prop="sourceFileColsToSkip"
                  class="mb-0"
                >
                  <el-input-number
                    v-model="form.sourceFileColsToSkip"
                    :min="0"
                    @change="emitUpdate"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  :label="$t('takeAllColumns')"
                  prop="takeAllColumns"
                  class="mb-0"
                >
                  <el-checkbox
                    v-model="form.takeAllColumns"
                    @change="emitUpdate"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  v-if="!form.takeAllColumns"
                  :label="$t('sourceFileColsToTake')"
                  prop="sourceFileColsToTake"
                  class="mb-0"
                >
                  <el-input-number
                    v-model="form.sourceFileColsToTake"
                    :min="1"
                    :disabled="form.takeAllColumns"
                    @change="emitUpdate"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-collapse class="width-100">
              <el-collapse-item name="1">
                <template #title>
                  <h4>
                    {{
                      $t('columnValidationRules') +
                      ' (' +
                      (form.columnConfigurations?.length ?? 0) +
                      ')'
                    }}
                  </h4>
                </template>
                <el-row v-if="form.columnConfigurations?.length">
                  <el-col :span="8">
                    <el-tooltip
                      placement="top"
                      :content="$t('importConfigColumnNameTooltipContent')"
                    >
                      <h4>
                        {{
                          form.sourceFileHasHeaderRow
                            ? $t('columnName')
                            : $t('columnIndex')
                        }}
                      </h4>
                    </el-tooltip>
                  </el-col>
                  <el-col :span="4" class="d-flex justify-content-center">
                    <h4>{{ $t('mustHaveValue') }}</h4>
                  </el-col>
                  <el-col :span="8">
                    <h4>{{ $t('dataType') }}</h4>
                  </el-col>
                  <el-col :span="3">
                    <h4>{{ $t('onFail') }}</h4>
                  </el-col>
                </el-row>
                <el-row
                  v-for="(item, index) in form.columnConfigurations"
                  :key="index"
                >
                  <el-form-item class="width-100">
                    <el-col :span="8">
                      <el-input
                        v-if="form.sourceFileHasHeaderRow"
                        v-model="item.columnName"
                        size="small"
                        @change="emitUpdate"
                      />
                      <el-input-number
                        v-else
                        v-model="item.columnIndex"
                        :min="0"
                        size="small"
                        @change="emitUpdate"
                      />
                    </el-col>
                    <el-col :span="4" class="d-flex justify-content-center">
                      <el-checkbox
                        v-model="item.mustHaveValue"
                        @change="emitUpdate"
                      />
                    </el-col>
                    <el-col :span="4">
                      <el-select
                        v-model="item.dataType"
                        size="small"
                        @change="emitUpdate"
                      >
                        <el-option
                          v-for="(index1, item1) in DataType"
                          :key="index1"
                          :disabled="
                            index1 == DataType.DateTime_Excel &&
                            !form.sourceFileName
                              ?.toLocaleLowerCase()
                              .endsWith('xlsx')
                          "
                          :label="$t(item1)"
                          :value="index1"
                        />
                      </el-select>
                    </el-col>
                    <el-col :span="4">
                      <el-tooltip
                        v-if="item.dataType == DataType.DateTime_String"
                        :trigger-keys="[]"
                      >
                        <template #content>
                          <span
                            class="hyperlink"
                            @click="openDateTimeDocumentation"
                          >
                            {{ $t('dateFormatTooltipContent') }}
                            <img
                              class="ml-1 icon-size-5"
                              src="@/assets/images/icons/share-26.svg"
                            />
                          </span>
                        </template>
                        <el-input
                          v-model="item.dataTypeSpecs"
                          placeholder="dd-MMM-yyyy HH:mm"
                          size="small"
                          @change="emitUpdate"
                        />
                      </el-tooltip>
                    </el-col>
                    <el-col :span="3">
                      <el-select
                        v-model="item.failureBehaviour"
                        size="small"
                        @change="emitUpdate"
                      >
                        <el-option
                          v-for="(index1, item1) in ValidationFailureBehaviour"
                          :key="index1"
                          :label="item1"
                          :value="index1"
                        />
                      </el-select>
                    </el-col>
                    <el-col :span="1" class="d-flex">
                      <el-tooltip
                        placement="top"
                        :content="$t('deleteValidationRule')"
                        class="ml-auto"
                      >
                        <img
                          :src="require(`@/assets/images/icons/new/delete.svg`)"
                          height="14"
                          width="14"
                          class="action-icon ml-auto"
                          @click="deleteColumnConfiguration(index)"
                        />
                      </el-tooltip>
                    </el-col>
                  </el-form-item>
                </el-row>
                <el-row class="mt-1">
                  <el-col :span="9">
                    <el-button
                      type="info"
                      size="small"
                      @click="addColumnConfiguration"
                    >
                      <h4>{{ $t('newRule') }}</h4>
                    </el-button>
                  </el-col>
                </el-row>
              </el-collapse-item>
            </el-collapse>
          </el-form>
        </el-col>
        <el-col :span="2" class="d-flex">
          <img
            v-if="!disableDelete"
            :src="require(`@/assets/images/icons/new/delete.svg`)"
            height="12"
            width="12"
            class="action-icon ml-auto mt-1 mr-2"
            @click="deleteTableConfiguration"
          />
        </el-col>
      </el-row>
    </template>
  </base-card>
</template>
<script lang="ts">
import BaseCard from '@/components/panels/BaseCard.vue';
import { mustNotBeEmpty } from '@/utils/formValidators';
import { onMounted, onUpdated, reactive, ref } from 'vue';
import {
  ElButton,
  ElCheckbox,
  ElCol,
  ElCollapse,
  ElCollapseItem,
  ElForm,
  ElFormItem,
  ElInput,
  ElInputNumber,
  ElOption,
  ElRow,
  ElSelect,
  ElTooltip
} from 'element-plus';
import {
  ColumnConfiguration,
  DataType,
  InputFileConfiguration,
  RowExistenceCriterion,
  TableConfiguration,
  ValidationFailureBehaviour
} from '@etp/etp-gain-bff-client/axios';
import i18n from '@/i18n';
import { sqlReservedWords } from '@/utils/variables';

export default {
  name: 'ConnectorConfigurationCard',
  components: {
    BaseCard,
    ElButton,
    ElCheckbox,
    ElCol,
    ElCollapse,
    ElCollapseItem,
    ElForm,
    ElFormItem,
    ElInput,
    ElInputNumber,
    ElOption,
    ElRow,
    ElSelect,
    ElTooltip
  },
  props: {
    configuration: {
      type: Object as () => TableConfiguration,
      default: {} as TableConfiguration,
      required: true
    },
    disableDelete: { type: Boolean, default: false },
    fileConfigurations: {
      type: Array as () => Array<InputFileConfiguration>,
      default: () => new Array<InputFileConfiguration>()
    }
  },
  emits: ['delete', 'update'],
  setup(props, { emit }) {
    const localMustNotBeInArray = (rule: any, value: string, callback: any) => {
      if (
        sqlReservedWords.some(el => el.toLowerCase() == value.toLowerCase())
      ) {
        callback(new Error(i18n.global.t('sqlReservedWord')));
      } else callback();
    };

    const form = ref({
      targetTableName: '',
      sourceFileName: '',
      sourceFileWorksheetName: '',
      sourceFileHasHeaderRow: true,
      rowExistenceCriterion: 0,
      sourceFileRowsToSkip: 0,
      sourceFileColsToSkip: 0,
      sourceFileColsToTake: 1,
      takeAllColumns: true,
      columnConfigurations: new Array<ColumnConfiguration>()
    });
    const formRef = ref<HTMLFormElement>();
    const formRules = reactive({
      targetTableName: [
        { validator: mustNotBeEmpty, trigger: 'blur' },
        { validator: localMustNotBeInArray, trigger: 'blur' }
      ],
      sourceFileName: [{ validator: mustNotBeEmpty, trigger: 'blur' }]
    });

    const addColumnConfiguration = () => {
      var newEntry = {
        columnName: '',
        columnIndex: 0,
        mustHaveValue: false,
        dataType: DataType.Any,
        dataTypeSpecs: '',
        failureBehaviour: ValidationFailureBehaviour.Break
      } as ColumnConfiguration;
      if (!form.value.columnConfigurations?.length) {
        form.value.columnConfigurations = [newEntry];
      } else {
        form.value.columnConfigurations.push(newEntry);
      }
    };

    const deleteColumnConfiguration = (index: number) => {
      if (!form.value.columnConfigurations?.length) return;
      form.value.columnConfigurations.splice(index, 1);
    };

    const deleteTableConfiguration = () => {
      emit('delete');
    };

    const openDateTimeDocumentation = () => {
      window.open(
        `https://learn.microsoft.com/en-us/dotnet/standard/base-types/custom-date-and-time-format-strings`,
        '_blank'
      );
    };

    const emitUpdate = () => {
      emit('update', {
        targetTableName: form.value.targetTableName,
        sourceFileName: form.value.sourceFileName,
        sourceFileWorksheetName: form.value.sourceFileWorksheetName,
        sourceFileHasHeaderRow: form.value.sourceFileHasHeaderRow,
        rowExistenceCriterion: form.value.rowExistenceCriterion,
        sourceFileRowsToSkip: form.value.sourceFileRowsToSkip,
        sourceFileColsToSkip: form.value.sourceFileColsToSkip,
        sourceFileColsToTake: form.value.takeAllColumns
          ? null
          : form.value.sourceFileColsToTake,
        columnConfigurations: form.value.columnConfigurations.length
          ? form.value.columnConfigurations
          : new Array<ColumnConfiguration>()
      } as TableConfiguration);
    };

    const setFormValue = () => {
      form.value = {
        targetTableName: props.configuration.targetTableName ?? '',
        sourceFileName: props.configuration.sourceFileName ?? '',
        sourceFileWorksheetName:
          props.configuration.sourceFileWorksheetName ?? '',
        sourceFileHasHeaderRow:
          props.configuration.sourceFileHasHeaderRow ?? true,
        rowExistenceCriterion:
          props.configuration.rowExistenceCriterion ??
          RowExistenceCriterion.None,
        sourceFileRowsToSkip: props.configuration.sourceFileRowsToSkip ?? 0,
        sourceFileColsToSkip: props.configuration.sourceFileColsToSkip ?? 0,
        sourceFileColsToTake: props.configuration.sourceFileColsToTake ?? 1,
        takeAllColumns: props.configuration.sourceFileColsToTake === null,
        columnConfigurations:
          props.configuration.columnConfigurations ??
          new Array<ColumnConfiguration>()
      };
    };

    onMounted(() => setFormValue());
    onUpdated(() => setFormValue());

    return {
      addColumnConfiguration,
      DataType,
      deleteColumnConfiguration,
      deleteTableConfiguration,
      emitUpdate,
      form,
      formRef,
      formRules,
      openDateTimeDocumentation,
      props,
      RowExistenceCriterion,
      ValidationFailureBehaviour
    };
  }
};
</script>
