export const AiServicesKind = {
  Unknown: 0,
  AnomalyDetector: 12,
  CognitiveServices: 1,
  ComputerVision: 2,
  ContentModerator: 13,
  CustomVisionPrediction: 3,
  CustomVisionTraining: 4,
  Face: 5,
  FormRecognizer: 6,
  Luis: 8,
  OpenAi: 15,
  QnaMaker: 9,
  Personalizer: 14,
  SpeechServices: 7,
  TextAnalytics: 10,
  TextTranslation: 11
};
