<template>
  <base-card no-header>
    <template #body>
      <el-form>
        <el-form-item :label="$t('branch')" class="mb-0">
          <el-select
            v-model="localSelectedBranch"
            :disabled="loadingBranches"
            value-key="name"
            size="small"
            :placeholder="loadingBranches ? $t('loading') : $t('empty')"
            @change="$emit('update:selectedBranch', localSelectedBranch)"
          >
            <el-option
              v-for="item in branches"
              :key="item.name"
              :label="item.name"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <slot name="item"></slot>
      </el-form>
    </template>
  </base-card>
</template>
<script lang="ts">
import { ElForm, ElFormItem, ElSelect, ElOption } from 'element-plus';

import BaseCard from '@/components/panels/BaseCard.vue';
import { ref, watch } from 'vue';
import { BranchModel } from '@etp/etp-nodemodelgit-client/axios';

export default {
  name: 'BaseBuildingPanel',
  components: {
    BaseCard,
    ElForm,
    ElFormItem,
    ElOption,
    ElSelect
  },
  props: {
    selectedBranch: { type: Object, default: {} as BranchModel },
    branches: { type: Array as () => Array<BranchModel>, required: true },
    loadingBranches: { type: Boolean, default: false }
  },
  emits: ['update:selectedBranch'],
  setup(props) {
    const localSelectedBranch = ref();

    watch(
      () => props.selectedBranch,
      newValue => {
        localSelectedBranch.value = newValue;
      },
      { immediate: true }
    );

    return { localSelectedBranch };
  }
};
</script>
