<template>
  <el-row :gutter="spacingWidthHeaderComponents">
    <el-col :span="5">
      <base-building-panel
        v-model:selectedBranch="selectedBranch"
        :branches="branches"
        :loading-branches="loadingBranches"
      >
        <template #item>
          <el-form-item :label="$t('source')">
            <el-select
              v-model="selectedSource"
              :disabled="!selectedBranch.name || loadingSources"
              value-key="name"
              size="small"
              :placeholder="loadingSources ? $t('loading') : $t('empty')"
            >
              <el-option
                v-for="item in sources"
                :key="item.name ?? ''"
                :label="item.name ?? ''"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
        </template>
      </base-building-panel>
    </el-col>
    <el-col :span="19">
      <base-building-table
        v-model="nodes"
        :show-build-button="userCanStartEngagementPipelines"
        :loading-nodes="loadingNodes"
        @start-pipeline-run="startPipelineRun"
      />
    </el-col>
  </el-row>
</template>

<script lang="ts">
import { ElCol, ElRow, ElFormItem, ElSelect, ElOption } from 'element-plus';

import BaseBuildingPanel from '@/components/panels/BaseBuildingPanel.vue';
import BaseBuildingTable from '@/components/tables/BaseBuildingTable.vue';
import { computed, ComputedRef, onMounted } from 'vue';
import { spacingWidthHeaderComponents } from '@/utils/variables';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { appendUrlParam, processUrlParams } from '@/utils/urlParameter';
import {
  LayerName,
  StartPipelineRunDto
} from '@etp/etp-pipelines-client/axios';
import config from '@/config/config';
import { BranchModel, SourceModel } from '@etp/etp-nodemodelgit-client/axios';

export default {
  name: 'ModelBuilding',
  components: {
    BaseBuildingPanel,
    BaseBuildingTable,
    ElCol,
    ElRow,
    ElFormItem,
    ElOption,
    ElSelect
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    let selectedSourceName = {} as SourceModel;
    let pipelineParameters = {
      EngagementId: store.getters['home/getCurrentEngagement'].id,
      TenantId: store.getters['home/getCurrentEngagement'].tenantId,
      Branch: store.getters['model/selectedBuildBranch'].name,
      Source: store.getters['model/selectedBuildSource'].name,
      Category: '',
      ActiveNodes: store.getters['model/buildNodes']
        .filter(n => n.active)
        .map(n => n.name),
      RebuildNodes: store.getters['model/buildNodes']
        .filter(n => n.active && n.rebuild)
        .map(n => n.name)
    };

    const branches = computed(() => store.getters['model/branches']);
    const sources = computed<Array<SourceModel>>(
      () => store.getters['model/buildSources']
    );
    const nodes = computed({
      get: () => store.getters['model/buildNodes'],
      set: items => {
        store.commit('model/SET_BUILD_NODES', items);
        pipelineParameters.ActiveNodes = items
          .filter(n => n.active)
          .map(n => n.name);
        pipelineParameters.RebuildNodes = items
          .filter(n => n.active && n.rebuild)
          .map(n => n.name);
      }
    });

    let loadingBranches: ComputedRef<boolean> = computed(
      () => store.getters['model/loadingBranches']
    );
    let loadingSources: ComputedRef<boolean> = computed(
      () => store.getters['model/loadingSources']
    );
    let loadingNodes: ComputedRef<boolean> = computed(
      () => store.getters['model/loadingNodes']
    );

    const fetchBranches = async () => {
      await store.dispatch('model/fetchBranches');
    };
    const fetchSources = async () => {
      await store.dispatch('model/fetchBuildSources');
    };
    const fetchNodes = async () => {
      await store.dispatch('model/fetchBuildNodes');
    };
    const startPipelineRun = async () => {
      await store.dispatch('model/startPipelineRun', {
        currentLayer: LayerName.ModelLayer,
        pipelineName: config.Pipeline.ModelLayerPipelineName,
        parameters: pipelineParameters
      } as StartPipelineRunDto);
    };

    const selectedBranch = computed({
      get: () => store.getters['model/selectedBuildBranch'],
      set: async (branch: BranchModel) => {
        appendUrlParam('branch', branch.name ?? '');
        store.commit('model/SET_SELECTED_BUILD_BRANCH', branch);
        pipelineParameters.Branch = branch.name;
        await fetchSources();
      }
    });
    const selectedSource = computed({
      get: () => store.getters['model/selectedBuildSource'],
      set: async (source: SourceModel) => {
        appendUrlParam('branch', selectedBranch.value.name ?? '');
        appendUrlParam('source', source.name ?? '');
        store.commit('model/SET_SELECTED_BUILD_SOURCE', source);
        selectedSourceName = source;
        pipelineParameters.Source = selectedSourceName.name;
        await fetchNodes();
      }
    });

    let showNodeModelGitPages: ComputedRef<boolean> = computed(
      () => store.getters['home/showNodeModelGitPages']
    );
    const userCanStartEngagementPipelines: ComputedRef<boolean> = computed(
      () => store.getters['userPermissions/canStartEngagementPipelines']
    );
    onMounted(async () => {
      if (!showNodeModelGitPages.value) {
        return router.push({ name: 'Overview' });
      }
      fetchBranches().then(async () => {
        await processUrlParams([
          {
            name: 'branch',
            handler: async (branchName: string) => {
              let branch = branches.value.find(
                (el: BranchModel) => el.name == branchName
              );
              pipelineParameters.Branch = branchName;
              if (!branch) return;
              store.commit('model/SET_SELECTED_BUILD_BRANCH', branch);
              await fetchSources();
            }
          },
          {
            name: 'source',
            handler: async (sourceName: string) => {
              let source = sources.value.find(
                (el: SourceModel) => el.name == sourceName
              );
              pipelineParameters.Source = sourceName;
              if (!source) return;
              store.commit('model/SET_SELECTED_BUILD_SOURCE', source);
              await fetchNodes();
            }
          }
        ]);
      });
      await store.dispatch(
        'userPermissions/checkIfCanStartEngagementPipelines'
      );
    });

    return {
      branches,
      startPipelineRun,
      loadingBranches,
      loadingSources,
      loadingNodes,
      nodes,
      selectedBranch,
      selectedSource,
      sources,
      spacingWidthHeaderComponents,
      userCanStartEngagementPipelines
    };
  }
};
</script>
