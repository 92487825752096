<template>
  <dashboard-configuration-card
    v-if="activeEngagementFeaturesArray.includes(feature_dashboards_powerbi)"
    :configuration="dashboardConfiguration"
    :disabled="false"
    :resources="tenantResources"
    class="mt-1"
    @update="handleDashboardConfigurationUpdate"
  />
  <e-invoicing-configuration-card
    v-if="activeEngagementFeaturesArray.includes(feature_eInvoicing)"
    :configuration="eInvoicingConfiguration.configuration"
    :resources="tenantResources"
    class="mt-1"
    @update="handleEInvoicingConfigurationUpdate"
  />
  <gain-configuration-card
    v-if="activeEngagementFeaturesArray.includes(feature_gain)"
    :latest-configuration="gainConfiguration"
    :configuration-history="gainConfigurationHistory"
    class="mt-1"
    @fetch-history="fetchGainConfigurationHistory"
    @update="handleGainConfigurationUpdate"
  />
  <node-model-configuration-card
    v-if="activeEngagementFeaturesArray.includes(feature_nodeModelGit)"
    :configuration="nodeModelConfiguration"
    :disabled="false"
    :hide-sql-fields="activeEngagementFeaturesArray.includes(feature_gain)"
    :resources="tenantResources"
    class="mt-1"
    @update="handleNodeModelConfigurationUpdate"
  />
  <reporting-configuration-card
    v-if="activeEngagementFeaturesArray.includes(feature_reporting)"
    :configuration="reportingConfiguration"
    :resources="tenantResources"
    class="mt-1"
    @update="handleReportingConfigurationUpdate"
  />
  <task-scheduler-configuration-card
    v-if="activeEngagementFeaturesArray.includes(feature_taskScheduler)"
    :configuration="taskSchedulerConfiguration"
    :disabled="false"
    :resources="tenantResources"
    class="mt-1"
    @update="handleTaskSchedulerConfigurationUpdate"
  />
  <pipeline-configuration-card
    v-if="activeEngagementFeaturesArray.includes(feature_pipelines)"
    :configuration="pipelineConfiguration"
    :disabled="false"
    :resources="tenantResources"
    class="mt-1"
    @update="handlePipelineConfigurationUpdate"
  />
</template>
<script lang="ts">
import DashboardConfigurationCard from '@/views/admin/engagements/components/DashboardConfigurationCard.vue';
import EInvoicingConfigurationCard from '@/views/admin/engagements/components/EInvoicingConfigurationCard.vue';
import GainConfigurationCard from '@/views/admin/engagements/components/GainConfigurationCard.vue';
import NodeModelConfigurationCard from '@/views/admin/engagements/components/NodeModelConfigurationCard.vue';
import TaskSchedulerConfigurationCard from '@/views/admin/engagements/components/TaskSchedulerConfigurationCard.vue';
import PipelineConfigurationCard from '@/views/admin/engagements/components/PipelineConfigurationCard.vue';
import ReportingConfigurationCard from '@/views/admin/engagements/components/ReportingConfigurationCard.vue';
import {
  CreateOrUpdateEngagementPipelineResourceDto,
  EngagementConfiguration as EngagementPipelineConfiguration
} from '@etp/etp-pipelines-client/axios';
import { computed, ComputedRef, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import {
  CreateOrUpdateEngagementConfigurationDto,
  EngagementConfiguration as EngagementDashboardConfiguration
} from '@etp/etp-dashboarding-client/axios';
import {
  feature_dashboards_powerbi,
  feature_eInvoicing,
  feature_gain,
  feature_nodeModelGit,
  feature_pipelines,
  feature_taskScheduler,
  feature_reporting
} from '@/utils/variables';
import { ResourceModel } from '@etp/etp-infra-client';
import {
  CreateOrUpdateEngagementNodeModelResourceDto,
  EngagementConfiguration as EngagementNodeModelConfiguration
} from '@etp/etp-nodemodelgit-client/axios';
import {
  EngagementConfiguration as EngagementTaskConfigurationModel,
  CreateOrUpdateConfigurationDto as CreateOrUpdateTaskSchedulerConfigurationDto
} from '@etp/etp-taskscheduler-client/axios';
import { ReportConfigurationDto } from '@etp/etp-reporting-client/axios';
import { SystemConfiguration as EngagementGainConfiguration } from '@etp/etp-gain-bff-client/axios';
import {
  EInvoicingConfiguration,
  EInvoicingConfigurationDetails
} from '@etp/etp-einvoicing-client/axios';

export default {
  name: 'EngagementConfiguration',
  components: {
    DashboardConfigurationCard,
    EInvoicingConfigurationCard,
    GainConfigurationCard,
    NodeModelConfigurationCard,
    PipelineConfigurationCard,
    TaskSchedulerConfigurationCard,
    ReportingConfigurationCard
  },
  setup() {
    let engagementId = '';
    const store = useStore();
    const router = useRouter();

    const activeEngagementFeaturesArray: ComputedRef<string[]> = computed(
      () => store.getters['engagements/getActiveEngagementFeaturesArray']
    );

    const tenantResources: ComputedRef<Array<ResourceModel>> = computed(
      () => store.getters['tenants/getResources']
    );

    const dashboardConfiguration: ComputedRef<EngagementDashboardConfiguration> =
      computed(() => store.getters['engagements/getDashboardConfiguration']);

    const eInvoicingConfiguration: ComputedRef<EInvoicingConfiguration> =
      computed(() => store.getters['engagements/getEInvoicingConfiguration']);

    const gainConfiguration: ComputedRef<EngagementGainConfiguration> =
      computed(() => store.getters['engagements/getGainConfiguration']);

    const gainConfigurationHistory: ComputedRef<
      Array<EngagementGainConfiguration>
    > = computed(
      () => store.getters['engagements/getGainConfigurationHistory']
    );

    const nodeModelConfiguration: ComputedRef<EngagementNodeModelConfiguration> =
      computed(() => store.getters['engagements/getNodeModelConfiguration']);

    const pipelineConfiguration: ComputedRef<EngagementPipelineConfiguration> =
      computed(() => store.getters['engagements/getPipelineConfiguration']);

    const taskSchedulerConfiguration: ComputedRef<EngagementTaskConfigurationModel> =
      computed(
        () => store.getters['engagements/getTaskSchedulerConfiguration']
      );

    const reportingConfiguration: ComputedRef<ReportConfigurationDto> =
      computed(() => store.getters['engagements/getReportingConfiguration']);

    const handleDashboardConfigurationUpdate = async (
      payload: CreateOrUpdateEngagementConfigurationDto
    ) => {
      await store.dispatch('engagements/createOrUpdateDashboardConfiguration', {
        engagementId: engagementId,
        configuration: payload
      });
      await fetchDashboardConfiguration();
    };

    const handleEInvoicingConfigurationUpdate = async (
      payload: EInvoicingConfigurationDetails
    ) => {
      await store.dispatch(
        'engagements/createOrUpdateEInvoicingConfiguration',
        {
          engagementId: engagementId,
          configuration: payload
        }
      );
      await fetchEInvoicingConfiguration();
    };

    const handleGainConfigurationUpdate = async (
      payload: EngagementGainConfiguration
    ) => {
      await store.dispatch('engagements/createOrUpdateGainConfiguration', {
        engagementId: engagementId,
        configuration: payload
      });
      await fetchGainConfiguration();
    };

    const handleNodeModelConfigurationUpdate = async (
      payload: CreateOrUpdateEngagementNodeModelResourceDto
    ) => {
      await store.dispatch('engagements/createOrUpdateNodeModelConfiguration', {
        engagementId: engagementId,
        configuration: payload
      });
      await fetchNodeModelConfiguration();
    };

    const handleTaskSchedulerConfigurationUpdate = async (
      payload: CreateOrUpdateTaskSchedulerConfigurationDto
    ) => {
      await store.dispatch(
        'engagements/createOrUpdateTaskSchedulerConfiguration',
        {
          engagementId: engagementId,
          configuration: payload
        }
      );
      await fetchTaskSchedulerConfiguration();
    };

    const handleReportingConfigurationUpdate = async (
      payload: ReportConfigurationDto
    ) => {
      await store.dispatch('engagements/createOrUpdateReportingConfiguration', {
        engagementId: engagementId,
        configuration: payload
      });
      await fetchReportingConfiguration();
    };

    const handlePipelineConfigurationUpdate = async (
      payload: CreateOrUpdateEngagementPipelineResourceDto
    ) => {
      await store.dispatch('engagements/createOrUpdatePipelineConfiguration', {
        engagementId: engagementId,
        model: payload
      });
      await fetchPipelineConfiguration();
    };

    const fetchDashboardConfiguration = async () => {
      if (
        !activeEngagementFeaturesArray.value.includes(
          feature_dashboards_powerbi
        )
      )
        return;
      await store.dispatch(
        'engagements/fetchDashboardConfiguration',
        engagementId
      );
    };

    const fetchEInvoicingConfiguration = async () => {
      if (!activeEngagementFeaturesArray.value.includes(feature_eInvoicing))
        return;
      await store.dispatch(
        'engagements/fetchEInvoicingConfiguration',
        engagementId
      );
    };

    const fetchGainConfiguration = async () => {
      if (!activeEngagementFeaturesArray.value.includes(feature_gain)) return;
      await store.dispatch('engagements/fetchGainConfiguration', engagementId);
    };

    const fetchGainConfigurationHistory = async () => {
      await store.dispatch(
        'engagements/fetchGainConfigurationHistory',
        engagementId
      );
    };

    const fetchNodeModelConfiguration = async () => {
      if (!activeEngagementFeaturesArray.value.includes(feature_nodeModelGit))
        return;
      await store.dispatch(
        'engagements/fetchNodeModelConfiguration',
        engagementId
      );
    };

    const fetchPipelineConfiguration = async () => {
      if (!activeEngagementFeaturesArray.value.includes(feature_pipelines))
        return;
      await store.dispatch(
        'engagements/fetchPipelineConfiguration',
        engagementId
      );
    };

    const fetchTaskSchedulerConfiguration = async () => {
      if (!activeEngagementFeaturesArray.value.includes(feature_taskScheduler))
        return;
      await store.dispatch(
        'engagements/fetchTaskSchedulerConfiguration',
        engagementId
      );
    };

    const fetchReportingConfiguration = async () => {
      if (!activeEngagementFeaturesArray.value.includes(feature_reporting))
        return;
      await store.dispatch(
        'engagements/fetchReportingConfiguration',
        engagementId
      );
    };

    const updateLocalVars = async () => {
      engagementId = router.currentRoute.value.params.engagementId as string;
      await store.dispatch('engagements/fetchEngagement', engagementId);
      let tenantId = store.getters['engagements/getActiveEngagement'].tenantId;
      await store.dispatch('tenants/fetchResources', { tenantId });
      await fetchDashboardConfiguration();
      await fetchEInvoicingConfiguration();
      await fetchGainConfiguration();
      await fetchNodeModelConfiguration();
      await fetchPipelineConfiguration();
      await fetchTaskSchedulerConfiguration();
      await fetchReportingConfiguration();
    };

    onMounted(() => {
      updateLocalVars();
    });

    return {
      dashboardConfiguration,
      eInvoicingConfiguration,
      fetchGainConfigurationHistory,
      gainConfiguration,
      gainConfigurationHistory,
      handleDashboardConfigurationUpdate,
      handleEInvoicingConfigurationUpdate,
      handleGainConfigurationUpdate,
      handleNodeModelConfigurationUpdate,
      handlePipelineConfigurationUpdate,
      handleTaskSchedulerConfigurationUpdate,
      handleReportingConfigurationUpdate,
      nodeModelConfiguration,
      pipelineConfiguration,
      taskSchedulerConfiguration,
      reportingConfiguration,
      tenantResources,
      activeEngagementFeaturesArray,
      feature_dashboards_powerbi,
      feature_eInvoicing,
      feature_gain,
      feature_nodeModelGit,
      feature_taskScheduler,
      feature_reporting,
      feature_pipelines
    };
  }
};
</script>
