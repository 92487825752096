export const detectStoreToUse = () => {
  const url = window.location.href;

  if (url.indexOf('modeling') > -1) {
    return 'model';
  } else if (url.indexOf('labeling') > -1) {
    return 'labeling';
  } else if (url.indexOf('filtering') > -1) {
    return 'filtering';
  } else if (url.indexOf('analytical') > -1) {
    return 'analytical';
  } else if (url.indexOf('testing') > -1) {
    return 'testing';
  } else if (url.indexOf('import') > -1) {
    return 'import';
  }
  return '';
};
