<template>
  <page-header :main-title="pageHeader">
    <template #tabs>
      <div class="d-flex mt-5">
        <router-link
          v-if="isGainEngagement"
          v-slot="{ isActive }"
          :to="{ name: 'GainImportFiles' }"
        >
          <base-tab :active="isActive">
            <h4>{{ $t('filesGain') }}</h4>
          </base-tab>
        </router-link>
        <router-link
          v-if="isGainEngagement"
          v-slot="{ isActive }"
          :to="{ name: 'GainImportTables' }"
        >
          <base-tab :active="isActive">
            <h4>{{ $t('tablesGain') }}</h4>
          </base-tab>
        </router-link>
        <router-link
          v-if="importStorageExists"
          v-slot="{ isActive }"
          :to="{
            name: 'ImportFolders',
            params: { storagePurpose: 'import', path: [] }
          }"
        >
          <base-tab :active="isActive">
            <h4>{{ $t('folders') }}</h4>
          </base-tab>
        </router-link>
        <router-link
          v-if="
            showTaskSchedulerPages && userCanReadEngagementTaskSchedulerTasks
          "
          v-slot="{ isActive }"
          :to="{ name: 'ImportScheduler' }"
        >
          <base-tab :active="isActive">
            <h4>{{ $t('tasks') }}</h4>
          </base-tab>
        </router-link>
        <router-link
          v-if="showPipelinePages"
          v-slot="{ isActive }"
          :to="{ name: 'ImportMonitor' }"
        >
          <base-tab :active="isActive">
            <h4>{{ $t('monitor') }}</h4>
          </base-tab>
        </router-link>
        <div class="horizontal-line mt-2">
          <hr />
        </div>
      </div>
    </template>
  </page-header>
  <div class="mt-2">
    <router-view />
  </div>
</template>
<script lang="ts">
import PageHeader from '@/components/misc/PageHeader.vue';
import BaseTab from '@/components/tabs/BaseTab.vue';
import i18n from '@/i18n';
import { computed, ComputedRef, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default {
  name: 'ImportOverview',
  components: {
    PageHeader,
    BaseTab
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    let routerName = ref(router.currentRoute.value.name);

    const pageHeader: ComputedRef<string> = computed(
      () => store.getters['home/getPageHeader']
    );

    let isGainEngagement: ComputedRef<boolean> = computed(
      () => store.getters['home/isGainEngagement']
    );
    let showStoragePages: ComputedRef<boolean> = computed(
      () => store.getters['home/showStoragePages']
    );
    let importStorageExists: ComputedRef<boolean> = computed(
      () => store.getters['storage/importStorageExists']
    );
    let showPipelinePages: ComputedRef<boolean> = computed(
      () => store.getters['home/showPipelinePages']
    );
    let showTaskSchedulerPages: ComputedRef<boolean> = computed(
      () => store.getters['home/showTaskSchedulerPages']
    );
    const userCanReadEngagementTaskSchedulerTasks: ComputedRef<boolean> =
      computed(
        () =>
          store.getters['userPermissions/canReadEngagementTaskSchedulerTasks']
      );

    onMounted(async () => {
      if (!showStoragePages.value) return router.push({ name: 'Overview' });
      if (routerName.value == 'Import') {
        if (importStorageExists.value) {
          return router.push({
            name: 'ImportFolders',
            params: { storagePurpose: 'import', path: [] }
          });
        } else {
          return router.push({ name: 'ImportMonitor' });
        }
      }
      if (routerName.value !== 'ImportFolders') {
        store.commit('home/SET_PAGE_HEADER', i18n.global.t('import'));
      }
      await store.dispatch(
        'userPermissions/checkIfCanReadEngagementTaskSchedulerTasks'
      );
    });

    return {
      importStorageExists,
      isGainEngagement,
      pageHeader,
      routerName,
      showPipelinePages,
      showTaskSchedulerPages,
      userCanReadEngagementTaskSchedulerTasks
    };
  }
};
</script>
<style lang="scss" scoped></style>
