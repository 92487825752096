import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["src", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_base_card = _resolveComponent("base-card")!

  return (_openBlock(), _createBlock(_component_base_card, {
    "no-header": "",
    class: "width-100"
  }, {
    body: _withCtx(() => [
      _createVNode(_component_el_row, { class: "d-flex" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_select, {
            modelValue: $setup.localFileLogicType,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.localFileLogicType) = $event)),
            size: "small",
            class: "ml-auto width-20",
            onChange: $setup.handleLogicTypeChange
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.FileLogicType, (index, item) => {
                return (_openBlock(), _createBlock(_component_el_option, {
                  key: index,
                  label: item,
                  value: index
                }, null, 8, ["label", "value"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["modelValue", "onChange"]),
          _createVNode(_component_el_button, {
            class: "ml-2",
            type: "primary",
            disabled: $setup.props.disabled,
            onClick: $setup.save
          }, {
            default: _withCtx(() => [
              _createElementVNode("h4", null, _toDisplayString(_ctx.$t('save')), 1)
            ]),
            _: 1
          }, 8, ["disabled", "onClick"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_row, { class: "mt-3" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_col, { span: 10 }, {
            default: _withCtx(() => [
              _createVNode(_component_el_tooltip, {
                placement: "top",
                content: _ctx.$t('fileNameTooltipContent')
              }, {
                default: _withCtx(() => [
                  _createElementVNode("h4", null, _toDisplayString(_ctx.$t('fileName')), 1)
                ]),
                _: 1
              }, 8, ["content"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_col, {
            span: 3,
            class: "d-flex justify-content-center"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_tooltip, {
                placement: "top",
                content: _ctx.$t('requiredTooltipContent')
              }, {
                default: _withCtx(() => [
                  _createElementVNode("h4", null, _toDisplayString(_ctx.$t('required')), 1)
                ]),
                _: 1
              }, 8, ["content"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_col, {
            span: 3,
            class: "d-flex justify-content-center"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_tooltip, {
                placement: "top",
                content: _ctx.$t('triggerTooltipContent')
              }, {
                default: _withCtx(() => [
                  _createElementVNode("h4", null, _toDisplayString(_ctx.$t('trigger')), 1)
                ]),
                _: 1
              }, 8, ["content"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_col, {
            span: 3,
            class: "d-flex justify-content-center"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_tooltip, {
                placement: "top",
                content: _ctx.$t('versionedTooltipContent')
              }, {
                default: _withCtx(() => [
                  _createElementVNode("h4", null, _toDisplayString(_ctx.$t('versioned')), 1)
                ]),
                _: 1
              }, 8, ["content"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_col, {
            span: 3,
            class: "d-flex justify-content-center"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_tooltip, {
                placement: "top",
                content: _ctx.$t('archivedTooltipContent')
              }, {
                default: _withCtx(() => [
                  _createElementVNode("h4", null, _toDisplayString(_ctx.$t('archived')), 1)
                ]),
                _: 1
              }, 8, ["content"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.forms, (form, index) => {
        return (_openBlock(), _createBlock(_component_el_form, {
          key: index,
          ref_for: true,
          ref: "formRef",
          model: form.value,
          rules: $setup.formRules
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_row, { class: "mt-1 mb-2" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, { span: 10 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, { prop: "namePattern" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: form.value.namePattern,
                          "onUpdate:modelValue": ($event: any) => ((form.value.namePattern) = $event),
                          disabled: $setup.props.disabled,
                          size: "small"
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"])
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_el_col, {
                  span: 3,
                  class: "d-flex justify-content-center align-items-center"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_checkbox, {
                      modelValue: form.value.isRequired,
                      "onUpdate:modelValue": ($event: any) => ((form.value.isRequired) = $event),
                      disabled: $setup.props.disabled
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"])
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_el_col, {
                  span: 3,
                  class: "d-flex justify-content-center align-items-center"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_checkbox, {
                      modelValue: form.value.isTrigger,
                      "onUpdate:modelValue": ($event: any) => ((form.value.isTrigger) = $event),
                      disabled: $setup.props.disabled
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"])
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_el_col, {
                  span: 3,
                  class: "d-flex justify-content-center align-items-center"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_checkbox, {
                      modelValue: form.value.isVersioned,
                      "onUpdate:modelValue": ($event: any) => ((form.value.isVersioned) = $event),
                      disabled: $setup.props.disabled
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"])
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_el_col, {
                  span: 3,
                  class: "d-flex justify-content-center align-items-center"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_checkbox, {
                      modelValue: form.value.isArchived,
                      "onUpdate:modelValue": ($event: any) => ((form.value.isArchived) = $event),
                      disabled: $setup.props.disabled
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"])
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_el_col, {
                  span: 2,
                  class: "d-flex justify-content-center align-items-center"
                }, {
                  default: _withCtx(() => [
                    (!$setup.props.disabled)
                      ? (_openBlock(), _createBlock(_component_el_tooltip, {
                          key: 0,
                          placement: "top",
                          content: _ctx.$t('deleteInputFile')
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("img", {
                              src: require(`@/assets/images/icons/new/delete.svg`),
                              height: "14",
                              width: "14",
                              class: "action-icon",
                              onClick: ($event: any) => ($setup.deleteFile(index))
                            }, null, 8, _hoisted_1)
                          ]),
                          _: 2
                        }, 1032, ["content"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1032, ["model", "rules"]))
      }), 128)),
      (!$setup.props.disabled)
        ? (_openBlock(), _createBlock(_component_el_row, {
            key: 0,
            class: "mt-3"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, { span: 12 }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_button, {
                    type: "info",
                    size: "small",
                    onClick: $setup.addFile
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("h4", null, _toDisplayString(_ctx.$t('addInputFile')), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}