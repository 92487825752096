import AnalyticalModelOverview from '../AnalyticalModelOverview.vue';
import AnalyticalModelConfig from '../AnalyticalModelConfig.vue';
import AnalyticalModelBuild from '../AnalyticalModelBuild.vue';
import RunOverview from '@/views/home/shared/RunOverview.vue';
import MonitorOverview from '@/views/home/shared/MonitorOverview.vue';

export default {
  path: '/analytical',
  name: 'Analytical',
  redirect: { name: 'AnalyticalModelRunOverview' },
  component: AnalyticalModelOverview,
  meta: { menuItemName: 'Analytical' },
  children: [
    {
      path: 'config',
      name: 'AnalyticalModelConfig',
      component: AnalyticalModelConfig
    },
    {
      path: 'build',
      name: 'AnalyticalModelBuild',
      component: AnalyticalModelBuild
    },
    {
      path: 'run',
      name: 'AnalyticalModelRunOverview',
      component: RunOverview
    },
    {
      path: 'monitor',
      name: 'AnalyticalModelMonitorOverview',
      component: MonitorOverview
    }
  ]
};
