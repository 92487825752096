import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "subtitle-2 mt-3" }
const _hoisted_2 = ["src", "onClick"]
const _hoisted_3 = ["src", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_header = _resolveComponent("page-header")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_base_table_header = _resolveComponent("base-table-header")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_base_table = _resolveComponent("base-table")!
  const _component_pagination = _resolveComponent("pagination")!
  const _component_create_or_update_role_modal = _resolveComponent("create-or-update-role-modal")!
  const _component_base_confirmation_modal = _resolveComponent("base-confirmation-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, null, {
          default: _withCtx(() => [
            _createVNode(_component_page_header, {
              "main-title": _ctx.$t('roles')
            }, {
              subheader: _withCtx(() => [
                _createElementVNode("h4", _hoisted_1, _toDisplayString(_ctx.$t('rolesDesc')), 1)
              ]),
              _: 1
            }, 8, ["main-title"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_base_table_header, {
      "row-count": $setup.allRolesCount,
      "quantity-message": _ctx.$t('roles'),
      "no-filter": "",
      class: "mt-3"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_button, {
          type: "primary",
          onClick: $setup.openCreateRoleModal
        }, {
          default: _withCtx(() => [
            _createElementVNode("h4", null, "+ " + _toDisplayString(_ctx.$t('newRole')), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _: 1
    }, 8, ["row-count", "quantity-message"]),
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, null, {
          default: _withCtx(() => [
            _createVNode(_component_base_table, {
              "show-loading-table": $setup.loadingRoles,
              "show-no-content-table": !$setup.roles.length,
              "error-message": _ctx.$t('noRoles')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_table, { data: $setup.roles }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_table_column, {
                      label: _ctx.$t('name'),
                      prop: "name"
                    }, null, 8, ["label"]),
                    _createVNode(_component_el_table_column, {
                      label: _ctx.$t('scopeMask'),
                      prop: "scopeMask"
                    }, null, 8, ["label"]),
                    _createVNode(_component_el_table_column, {
                      label: _ctx.$t('description'),
                      prop: "description"
                    }, null, 8, ["label"]),
                    _createVNode(_component_el_table_column, { width: "80" }, {
                      default: _withCtx((props) => [
                        _createVNode(_component_el_tooltip, {
                          placement: "top",
                          content: _ctx.$t('editRole')
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("img", {
                              src: require(`@/assets/images/icons/new/edit.svg`),
                              height: "14",
                              width: "14",
                              class: "action-icon",
                              onClick: ($event: any) => ($setup.openUpdateRoleModal(props.row))
                            }, null, 8, _hoisted_2)
                          ]),
                          _: 2
                        }, 1032, ["content"]),
                        _createVNode(_component_el_tooltip, {
                          placement: "top",
                          class: "ml-1",
                          content: _ctx.$t('deleteRole')
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("img", {
                              src: require(`@/assets/images/icons/new/delete.svg`),
                              height: "14",
                              width: "14",
                              class: "action-icon",
                              onClick: ($event: any) => ($setup.openRemoveRoleModal(props.row))
                            }, null, 8, _hoisted_3)
                          ]),
                          _: 2
                        }, 1032, ["content"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["data"])
              ]),
              _: 1
            }, 8, ["show-loading-table", "show-no-content-table", "error-message"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_pagination, {
      count: $setup.allRolesCount,
      page: $setup.pageNumber,
      "page-size": $setup.pageSize,
      onChangePage: $setup.changePage
    }, null, 8, ["count", "page", "page-size", "onChangePage"]),
    _createVNode(_component_create_or_update_role_modal, {
      modelValue: $setup.showCreateOrUpdateRoleModal,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.showCreateOrUpdateRoleModal) = $event)),
      role: $setup.activeRole,
      permissions: $setup.permissions,
      onConfirm: $setup.createOrUpdateRole,
      onClose: _cache[1] || (_cache[1] = ($event: any) => ($setup.showCreateOrUpdateRoleModal = false))
    }, null, 8, ["modelValue", "role", "permissions", "onConfirm"]),
    _createVNode(_component_base_confirmation_modal, {
      modelValue: $setup.showRemoveRoleConfirmationModal,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.showRemoveRoleConfirmationModal) = $event)),
      content: _ctx.$t('confirmRemoveRole'),
      width: "600px",
      "feedback-text": _ctx.$t('roleRemoved'),
      onConfirm: $setup.removeRole,
      onClose: _cache[3] || (_cache[3] = ($event: any) => ($setup.showRemoveRoleConfirmationModal = false))
    }, null, 8, ["modelValue", "content", "feedback-text", "onConfirm"])
  ], 64))
}