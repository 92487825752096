import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_upload = _resolveComponent("el-upload")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_upload, {
      class: "upload-demo",
      action: "",
      "http-request": $setup.emitFiles,
      multiple: "",
      "auto-upload": false,
      "show-file-list": false,
      "on-change": $setup.emitFiles,
      accept: $props.accept
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_button, {
          size: "small",
          type: "primary"
        }, {
          default: _withCtx(() => [
            _createElementVNode("h4", null, _toDisplayString('+ ' + _ctx.$t('newFile')), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["http-request", "on-change", "accept"])
  ]))
}