export class SortParams {
  constructor(sortBy: string, sortDirection: SortDirection) {
    this.sortBy = sortBy;
    this.sortDirection = sortDirection;
  }
  public sortBy: string;
  public sortDirection: SortDirection;
}

export enum SortDirection {
  ASC = 0,
  DESC = 1
}
