<template>
  <base-modal
    :modal-title="$t('properties')"
    :confirm-text="$t('save')"
    :dialog-visible="dialogVisible"
    width="500px"
    @confirm="save"
    @close="close"
  >
    <template #content>
      <h4>{{ $t('addNewProperty') }}</h4>
      <el-form
        ref="newPropertyFormRef"
        :model="newPropertyForm"
        :rules="newPropertyFormRules"
        label-position="top"
      >
        <div class="d-flex align-items-center">
          <el-form-item prop="name" class="mb-0 flex-grow-1">
            <el-input
              v-model="newPropertyForm.name"
              size="small"
              :placeholder="$t('name')"
            ></el-input>
          </el-form-item>
          <el-form-item prop="value" class="mb-0 ml-1 flex-grow-1">
            <el-input
              v-model="newPropertyForm.value"
              size="small"
              :placeholder="$t('value')"
            ></el-input>
          </el-form-item>
          <el-form-item class="mb-0">
            <el-button
              class="icon-button p-1 cursor-pointer"
              @click="addProperty"
            >
              <plus class="icon-size-8" />
            </el-button>
          </el-form-item>
        </div>
      </el-form>
      <h4 class="mt-4">{{ $t('properties') }}</h4>
      <el-form v-if="localProperties.length">
        <el-form-item>
          <ul class="pl-0 d-flex width-100">
            <el-scrollbar max-height="500px" wrap-class="pl-2 pr-2 pb-2">
              <li
                v-for="(property, index) in localProperties"
                :key="property.name"
                class="d-flex"
              >
                <span class="property-text body-1 width-50">
                  {{ property.name }}
                </span>
                <span class="property-text body-1 width-50">
                  {{ property.value }}
                </span>
                <img
                  :src="require(`@/assets/images/icons/new/delete.svg`)"
                  class="ml-auto clickable icon-size-7"
                  @click="removeProperty(index)"
                />
              </li>
            </el-scrollbar>
          </ul>
        </el-form-item>
      </el-form>
    </template>
  </base-modal>
</template>
<script lang="ts">
import { ref, onMounted, onUpdated, reactive } from 'vue';
import BaseModal from '@/components/modals/BaseModal.vue';

import {
  ElForm,
  ElButton,
  ElFormItem,
  ElInput,
  ElScrollbar,
  ElMessage
} from 'element-plus';
import { Plus } from '@element-plus/icons-vue';

import {
  mustBeAlphanumericDashUnderscore,
  mustNotBeEmpty
} from '@/utils/formValidators';
import i18n from '@/i18n';
import arraysEqual from '@/utils/arrayUtils';
import {
  CreatePropertyModel,
  PropertyModel
} from '@etp/etp-nodemodelgit-client/axios';

export default {
  name: 'PropertiesModal',
  components: {
    BaseModal,
    ElForm,
    ElButton,
    ElFormItem,
    ElInput,
    ElScrollbar,
    Plus
  },
  props: {
    dialogVisible: { type: Boolean, default: false, required: true },
    properties: {
      type: Array as () => Array<PropertyModel>,
      default: () => new Array<PropertyModel>()
    }
  },
  emits: ['close', 'confirm'],
  setup(props, { emit }) {
    let localProperties = ref(new Array<PropertyModel>());

    const localMustNotBeInArray = (rule: any, value: string, callback: any) => {
      if (
        localProperties.value.some(
          el => el.name?.toLowerCase() == value.toLowerCase()
        )
      ) {
        callback(new Error(i18n.global.t('noDuplicateProperty')));
      } else callback();
    };

    let newPropertyForm = ref({
      name: '',
      value: ''
    } as PropertyModel);
    const newPropertyFormRef = ref<HTMLFormElement>();
    let newPropertyFormRules = reactive({
      name: [
        { validator: mustBeAlphanumericDashUnderscore, trigger: 'blur' },
        { validator: localMustNotBeInArray, trigger: 'blur' }
      ],
      value: [{ validator: mustNotBeEmpty, trigger: 'blur' }]
    });

    const updateProperties = () => {
      localProperties.value = [...props.properties];
    };

    const save = () => {
      emit('confirm', localProperties.value as CreatePropertyModel[]);
      ElMessage({
        showClose: true,
        message: i18n.global.t('savingChanges')
      });
    };

    const close = () => {
      if (
        arraysEqual<PropertyModel>(props.properties, localProperties.value) ||
        confirm(i18n.global.t('forgotToSave'))
      ) {
        emit('close');
      }
    };

    onMounted(() => updateProperties());
    onUpdated(() => updateProperties());

    const addProperty = () => {
      newPropertyFormRef.value?.validate((valid: boolean) => {
        if (!valid) return;
        localProperties.value.push({
          name: newPropertyForm.value.name,
          value: newPropertyForm.value.value
        } as PropertyModel);
        newPropertyForm.value = {
          name: '',
          value: ''
        } as PropertyModel;
      });
    };
    const removeProperty = (index: number) => {
      localProperties.value.splice(index, 1);
    };

    return {
      newPropertyForm,
      newPropertyFormRef,
      newPropertyFormRules,
      localProperties,
      save,
      close,
      addProperty,
      removeProperty
    };
  }
};
</script>
<style lang="scss" scoped>
.form-item {
  flex-grow: 1;
}
.add-button {
  background: transparent;
  border-radius: $--border-radius-small !important;
  min-width: 24px;
  margin-bottom: 4px;
}
li {
  align-items: center;
  height: 40px;
  box-shadow: 0 8px 20px $etp-modal-shadow;
  border-radius: 20px;
  margin: 8px 0;
  padding: 0 16px;
}

.property-text {
  font-size: 100%;
  display: flex;
  align-items: center;
  position: relative;
  overflow: overlay;
  margin: 0px 8px;
}

.clickable {
  cursor: pointer;
}
</style>
