import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_base_modal = _resolveComponent("base-modal")!

  return (_openBlock(), _createBlock(_component_base_modal, {
    "modal-title": _ctx.$t('configurationHistory'),
    "dialog-visible": $props.modelValue,
    "has-confirmation-button": false,
    width: "100%",
    onClose: $setup.close
  }, {
    content: _withCtx(() => [
      _createVNode(_component_el_table, {
        data: $setup.props.configurationVersions
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            label: _ctx.$t('version'),
            width: "150"
          }, {
            default: _withCtx((scope) => [
              _createElementVNode("h4", null, _toDisplayString(scope.row.version), 1),
              _createElementVNode("h4", null, _toDisplayString($setup.formatDate(scope.row.timestamp)), 1),
              _createElementVNode("h4", null, _toDisplayString($setup.formatTime(scope.row.timestamp)), 1)
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_table_column, {
            label: _ctx.$t('item')
          }, {
            default: _withCtx(({ row, $index }) => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.fileConfigurations, (item, key) => {
                return (_openBlock(), _createBlock(_component_el_row, {
                  key: key,
                  class: _normalizeClass(
                $setup.isFileDifferentToPreviousVersion($index, item.namePattern)
                  ? 'color-red mb-1'
                  : 'mb-1'
              )
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_col, { span: 6 }, {
                      default: _withCtx(() => [
                        _createElementVNode("h4", null, _toDisplayString(_ctx.$t('file')), 1),
                        _createElementVNode("h4", null, _toDisplayString(item.namePattern), 1)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_el_col, { span: 18 }, {
                      default: _withCtx(() => [
                        _createElementVNode("h4", null, _toDisplayString(item), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1032, ["class"]))
              }), 128)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.tableConfigurations, (item, key) => {
                return (_openBlock(), _createBlock(_component_el_row, {
                  key: key,
                  class: _normalizeClass(
                $setup.isTableDifferentToPreviousVersion($index, item.targetTableName)
                  ? 'color-red mb-1'
                  : 'mb-1'
              )
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_col, { span: 6 }, {
                      default: _withCtx(() => [
                        _createElementVNode("h4", null, _toDisplayString(_ctx.$t('table')), 1),
                        _createElementVNode("h4", null, _toDisplayString(item.targetTableName), 1)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_el_col, { span: 18 }, {
                      default: _withCtx(() => [
                        _createElementVNode("h4", null, _toDisplayString(item), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1032, ["class"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_table_column, { width: "150" }, {
            default: _withCtx(({ $index }) => [
              ($index !== 0 && !$props.disableRestore)
                ? (_openBlock(), _createBlock(_component_el_button, {
                    key: 0,
                    type: "primary",
                    onClick: ($event: any) => ($setup.restoreConfiguration($index))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('restore')), 1)
                    ]),
                    _: 2
                  }, 1032, ["onClick"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["data"])
    ]),
    _: 1
  }, 8, ["modal-title", "dialog-visible", "onClose"]))
}