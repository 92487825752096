export const spacingWidthHeaderComponents = 5;

export const feature_dashboards_analysisServices =
  'Dashboards_AnalysisServices';
export const feature_dashboards_powerbi = 'Dashboards_PowerBi';
export const feature_eInvoicing = 'EInvoicing';
export const feature_gain = 'GAIN';
export const feature_nodeModelApi = 'NodeModelApi';
export const feature_nodeModelGit = 'NodeModelGit';
export const feature_pipelines = 'Pipelines';
export const feature_storage = 'Storage';
export const feature_taskScheduler = 'TaskScheduler';
export const feature_reporting = 'Reporting';
export const feature_workflow = 'Workflow';

export const gainImportFlowPipelineName = 'input-flow';

export const permissions_authorization_reader = 'ETP.Authorization.Reader';
export const permissions_authorization_writer = 'ETP.Authorization.Writer';
export const permissions_connectors_configurationWriter =
  'ETP.Connectors.Configuration.Writer';
export const permissions_dashboarding_powerBiReader =
  'ETP.Dashboarding.PowerBi.Reader';
export const permissions_dashboarding_powerBiWriter =
  'ETP.Dashboarding.PowerBi.Writer';
export const permissions_engagements_reader = 'ETP.Engagements.Reader';
export const permissions_gainBff_configurationImportReader =
  'ETP.GAIN.BFF.Configuration.Import.Reader';
export const permissions_gainBff_configurationImportWriter =
  'ETP.GAIN.BFF.Configuration.Import.Writer';
export const permissions_gainBff_requirementsStatusWriter =
  'ETP.GAIN.BFF.Requirements.Status.Writer';
export const permissions_infra_costsReader = 'ETP.Infra.Costs.Reader';
export const permissions_infra_tenantsReader = 'ETP.Infra.Tenant.Reader';
export const permissions_nodeModel_gitRead = 'ETP.NodeModel.Git.Read';
export const permissions_nodeModel_gitWrite = 'ETP.NodeModel.Git.Write';
export const permissions_pipelines_gitWrite = 'ETP.Pipelines.Git.Write';
export const permissions_pipelines_pipelinesRead =
  'ETP.Pipelines.Pipelines.Read';
export const permissions_pipelines_pipelinesStart =
  'ETP.Pipelines.Pipelines.Start';
export const permissions_pipelines_pipelinesWrite =
  'ETP.Pipelines.Pipelines.Write';
export const permissions_rbac_apply = 'ETP.RBAC.Apply';
export const permissions_rbac_tasks_read = 'ETP.RBAC.Tasks.Read';
export const permissions_storage_foldersContributor =
  'ETP.Storage.Folders.Contributor';
export const permissions_storage_foldersReader = 'ETP.Storage.Folders.Reader';
export const permissions_storage_foldersWriter = 'ETP.Storage.Folders.Writer';
export const permissions_taskScheduler_taskCreator =
  'ETP.TaskScheduler.Task.Creator';
export const permissions_taskScheduler_taskReader =
  'ETP.TaskScheduler.Task.Reader';

export const roles_engagement_folderContributor =
  'ETP.Engagement.Folder.Contributor';

export const sqlReservedWords = [
  'ABORT',
  'ACTION',
  'ADD',
  'AFTER',
  'ALL',
  'ALTER',
  'ANALYZE',
  'AND',
  'AS',
  'ASC',
  'ATTACH',
  'AUTOINCREMENT',
  'BEFORE',
  'BEGIN',
  'BETWEEN',
  'BY',
  'CASCADE',
  'CASE',
  'CAST',
  'CHECK',
  'COLLATE',
  'COLUMN',
  'COMMIT',
  'CONFLICT',
  'CONSTRAINT',
  'CREATE',
  'CROSS',
  'CURRENT_DATE',
  'CURRENT_TIME',
  'CURRENT_TIMESTAMP',
  'DATABASE',
  'DEFAULT',
  'DEFERRABLE',
  'DEFERRED',
  'DELETE',
  'DESC',
  'DETACH',
  'DISTINCT',
  'DROP',
  'EACH',
  'ELSE',
  'END',
  'ESCAPE',
  'EXCEPT',
  'EXCLUSIVE',
  'EXISTS',
  'EXPLAIN',
  'FAIL',
  'FOR',
  'FOREIGN',
  'FROM',
  'FULL',
  'GLOB',
  'GROUP',
  'HAVING',
  'IF',
  'IGNORE',
  'IMMEDIATE',
  'IN',
  'INDEX',
  'INDEXED',
  'INITIALLY',
  'INNER',
  'INSERT',
  'INSTEAD',
  'INTERSECT',
  'INTO',
  'IS',
  'ISNULL',
  'JOIN',
  'KEY',
  'LEFT',
  'LIKE',
  'LIMIT',
  'MATCH',
  'NATURAL',
  'NO',
  'NOT',
  'NOTNULL',
  'NULL',
  'OF',
  'OFFSET',
  'ON',
  'OR',
  'ORDER',
  'OUTER',
  'PLAN',
  'PRAGMA',
  'PRIMARY',
  'QUERY',
  'RAISE',
  'RECURSIVE',
  'REFERENCES',
  'REGEXP',
  'REINDEX',
  'RELEASE',
  'RENAME',
  'REPLACE',
  'RESTRICT',
  'RIGHT',
  'ROLLBACK',
  'ROW',
  'SAVEPOINT',
  'SELECT',
  'SET',
  'TABLE',
  'TEMP',
  'TEMPORARY',
  'THEN',
  'TO',
  'TRANSACTION',
  'TRIGGER',
  'UNION',
  'UNIQUE',
  'UPDATE',
  'USING',
  'VACUUM',
  'VALUES',
  'VIEW',
  'VIRTUAL',
  'WHEN',
  'WHERE',
  'WITH',
  'WITHOUT'
];
