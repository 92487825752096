import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-feabe88a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "menu-container" }
const _hoisted_2 = { class: "application-details" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_menu = _resolveComponent("el-menu")!
  const _component_el_footer = _resolveComponent("el-footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_el_menu, {
        class: "el-menu-vertical-demo",
        router: "",
        "default-active": $setup.defaultActive,
        "unique-opened": "",
        onOpen: $setup.enableTransition,
        onClose: $setup.enableTransition
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "menu-contents", {}, undefined, true)
        ]),
        _: 3
      }, 8, ["default-active", "onOpen", "onClose"])
    ]),
    _createVNode(_component_el_footer, null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("p", null, [
            _createElementVNode("small", null, [
              _createElementVNode("span", {
                class: _normalizeClass([
              'environment',
              $setup.environment === 'Production'
                ? 'is-primary'
                : $setup.environment === 'Staging'
                ? 'is-warning'
                : 'is-danger'
            ])
              }, _toDisplayString($setup.environment), 3)
            ])
          ])
        ])
      ]),
      _: 1
    })
  ], 64))
}