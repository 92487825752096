import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "subtitle-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_header = _resolveComponent("page-header")!

  return (_openBlock(), _createBlock(_component_page_header, {
    "main-title": _ctx.$t('manual')
  }, {
    subheader: _withCtx(() => [
      _createElementVNode("h4", _hoisted_1, _toDisplayString(_ctx.$t('manualDesc')), 1)
    ]),
    _: 1
  }, 8, ["main-title"]))
}