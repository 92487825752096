const config = {
  sso: {
    tenantId: '',
    clientId: '',
    redirectUri: '',
    redirectUriBlank: '',
    cacheLocation: '',
    postLogoutRedirectUri: '',
    consentScopes: ['']
  },
  settings: {
    createResourceGroup: false
  },
  cloudProvider: 'Azure',
  nodeModelGitProjectName: '',
  environment: '',
  versionNo: '',
  Pipeline: {
    AnalyticalLayerPipelineName: '',
    ConfigurationPipelineName: '',
    ClassificationPipelineName: '',
    ModelLayerPipelineName: '',
    TestLayerPipelineName: ''
  }
};
export default config;

function loadConfiguration() {
  document.documentElement.setAttribute(
    'data-theme',
    localStorage['data-theme'] ?? 'Default'
  );
  return fetch(process.env.BASE_URL + 'appSettings.json')
    .then(result => result.json())
    .then(newconfig => {
      for (let prop in config) {
        delete config[prop];
      }
      for (let prop in newconfig) {
        config[prop] = newconfig[prop];
      }
      return config;
    });
}
export { loadConfiguration };
