import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "body-1" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_base_card = _resolveComponent("base-card")!

  return (_openBlock(), _createBlock(_component_base_card, {
    "no-header": "",
    class: "width-100"
  }, {
    body: _withCtx(() => [
      _createVNode(_component_el_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_col, { span: 7 }, {
            default: _withCtx(() => [
              _createElementVNode("h3", null, _toDisplayString($props.connectorConfiguration.connector), 1),
              _createElementVNode("span", _hoisted_1, _toDisplayString($props.connectorConfiguration.environment), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_col, { span: 14 }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form, {
                ref: "formRef",
                model: $setup.form,
                rules: $setup.formRules
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_form_item, {
                    label: _ctx.$t('configuration'),
                    prop: "configuration"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        modelValue: $setup.form.configuration,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.form.configuration) = $event)),
                        rows: 6,
                        type: "textarea",
                        onChange: $setup.handleConfigurationChange
                      }, null, 8, ["modelValue", "onChange"])
                    ]),
                    _: 1
                  }, 8, ["label"])
                ]),
                _: 1
              }, 8, ["model", "rules"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_col, {
            span: 3,
            class: "d-flex"
          }, {
            default: _withCtx(() => [
              _createElementVNode("img", {
                src: require(`@/assets/images/icons/new/delete.svg`),
                height: "12",
                width: "12",
                class: "action-icon ml-auto mt-1 mr-2",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($setup.deleteConfiguration && $setup.deleteConfiguration(...args)))
              }, null, 8, _hoisted_2)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}