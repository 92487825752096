import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/icons/new/edit.svg'
import _imports_1 from '@/assets/images/icons/new/delete.svg'


const _hoisted_1 = ["onClick"]
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_base_confirmation_modal = _resolveComponent("base-confirmation-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, null, {
          default: _withCtx(() => [
            _createElementVNode("h4", null, _toDisplayString($props.allApplicationsCount) + " · " + _toDisplayString(_ctx.$t('applications')), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_table, {
              data: $props.applicationsData,
              "empty-text": _ctx.$t('noApplications')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_table_column, {
                  label: _ctx.$t('name'),
                  "show-overflow-tooltip": "",
                  prop: "name"
                }, {
                  default: _withCtx((props) => [
                    _createElementVNode("div", null, _toDisplayString(props.row.name), 1)
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  label: _ctx.$t('objectId'),
                  prop: "objectId"
                }, null, 8, ["label"]),
                _createVNode(_component_el_table_column, { width: "100" }, {
                  default: _withCtx((props) => [
                    _createVNode(_component_el_tooltip, {
                      content: _ctx.$t('edit'),
                      placement: "top"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("img", {
                          src: _imports_0,
                          height: "12",
                          width: "12",
                          class: "action-icon mr-1",
                          onClick: ($event: any) => ($setup.openApplicationDetails(props.row.objectId))
                        }, null, 8, _hoisted_1)
                      ]),
                      _: 2
                    }, 1032, ["content"]),
                    _createVNode(_component_el_tooltip, {
                      content: _ctx.$t('delete'),
                      placement: "top"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("img", {
                          src: _imports_1,
                          height: "12",
                          width: "12",
                          class: "action-icon",
                          onClick: ($event: any) => ($setup.openDeleteApplicationModal(props.row))
                        }, null, 8, _hoisted_2)
                      ]),
                      _: 2
                    }, 1032, ["content"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["data", "empty-text"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_base_confirmation_modal, {
      modelValue: $setup.showDeleteApplicationModal,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.showDeleteApplicationModal) = $event)),
      content: _ctx.$t('confirmDeleteApplication'),
      "feedback-text": _ctx.$t('applicationDeleted'),
      onConfirm: $setup.deleteApplication,
      onClose: _cache[1] || (_cache[1] = ($event: any) => ($setup.showDeleteApplicationModal = false))
    }, null, 8, ["modelValue", "content", "feedback-text", "onConfirm"])
  ], 64))
}