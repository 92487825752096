<template>
  <el-row>
    <div class="d-flex align-items-center ml-auto">
      <div
        class="ml-auto pipeline-status-indicator"
        :class="PipelineStatus.Succeeded"
      />
      <h4 class="ml-1 pipeline-status-label">{{ $t('success') }}</h4>
      <div
        class="ml-3 pipeline-status-indicator"
        :class="PipelineStatus.InProgress"
      ></div>
      <h4 class="ml-1 pipeline-status-label">{{ $t('running') }}</h4>
      <div
        class="ml-3 pipeline-status-indicator"
        :class="PipelineStatus.Failed"
      ></div>
      <h4 class="ml-1 pipeline-status-label">{{ $t('failed') }}</h4>
    </div>
  </el-row>
  <el-row class="mt-2 mb-1" justify="space-between" align="middle">
    <el-col :span="5">
      <h4 v-if="loadingPipelineRuns && !pipelineRuns.length">
        {{ $t('loading') }}
      </h4>
      <h4 v-else>{{ pipelineRuns.length }} &#183; {{ $t('pipelineRuns') }}</h4>
    </el-col>
    <el-col :span="6">
      <el-select
        v-model="statusFilter"
        multiple
        :placeholder="$t('pipelineStatusFilter')"
        size="small"
        @change="updateStatusFilter"
      >
        <el-option
          v-for="(item, key) in PipelineStatus"
          :key="key"
          :label="$t(key)"
          :value="item"
          class="d-flex align-items-center"
        >
          <div class="pipeline-status-indicator" :class="item.toLowerCase()" />
          <h4 class="ml-1 pipeline-status-label">{{ $t(key) }}</h4>
        </el-option>
      </el-select>
    </el-col>
  </el-row>
  <el-row class="d-block">
    <base-table
      :show-loading-table="loadingPipelineRuns && !pipelineRuns.length"
      :show-no-content-table="!pipelineRuns.length"
      :error-message="$t('noPipeline')"
    >
      <monitor-pipelines-table
        :pipeline-runs="pipelineRuns"
        :sort-params="sortParams"
        :show-adf-button="showAdfButton"
        @update-sort-params="updateSortParams"
      />
    </base-table>
  </el-row>
</template>

<script lang="ts">
import { ElRow, ElCol, ElSelect, ElOption } from 'element-plus';

import BaseTable from '@/components/tables/BaseTable.vue';
import MonitorPipelinesTable from '@/components/tables/MonitorPipelinesTable.vue';

import { SortDirection, SortParams } from '@/services/web-api/dto/sortParams';

import { ref } from 'vue';
import { PipelineStatus } from '@/services/web-api/enums/pipelineStatus';
import { PipelineRunDto } from '@etp/etp-pipelines-client/axios';

export default {
  name: 'BaseMonitorTab',
  components: {
    ElRow,
    ElCol,
    ElSelect,
    ElOption,
    MonitorPipelinesTable,
    BaseTable
  },
  props: {
    pipelineRuns: {
      type: Array as () => Array<PipelineRunDto>,
      required: true
    },
    sortParams: {
      type: SortParams,
      default: new SortParams('', SortDirection.DESC)
    },
    showAdfButton: {
      type: Boolean,
      default: false
    },
    loadingPipelineRuns: { type: Boolean, default: false }
  },
  emits: ['updateSortParams', 'updateStatusFilter'],
  setup(props, { emit }) {
    const statusFilter = ref(new Array<PipelineStatus>());

    const updateSortParams = (value: SortParams) => {
      emit('updateSortParams', value);
    };
    const updateStatusFilter = () => {
      emit('updateStatusFilter', statusFilter.value);
    };

    return {
      updateSortParams,
      statusFilter,
      PipelineStatus,
      updateStatusFilter
    };
  }
};
</script>

<style scoped lang="scss">
.pipeline-status-label {
  color: $etp-deloitte-blue-5;
  width: fit-content;
}
</style>
