import {
  AggregatedRoleAssignment,
  RoleAssignmentAggregation
} from '@/models/aggregatedRoleAssignment';
import { RoleAssignmentModel } from '@etp/etp-authorization-client/axios';
import {
  ExtractEngagementFromScope,
  ExtractTenantFromScope,
  IsEngagementScope,
  IsTenantScope
} from './scopeHelper';
import { EngagementModel } from '@etp/etp-engagements-client/axios';
import { TenantModel } from '@etp/etp-infra-client';

export const compareRoleAssignments = (
  a: RoleAssignmentModel,
  b: RoleAssignmentModel
): number => {
  if (a.roleName < b.roleName) return -1;
  if (a.roleName > b.roleName) return 1;
  if (a.scope < b.scope) return -1;
  if (a.scope > b.scope) return 1;
  return 0;
};

export const createAggregatedRoleAssignments = (
  roleAssignments: Array<RoleAssignmentModel>,
  engagements: Array<EngagementModel>,
  tenants: Array<TenantModel>
): Array<AggregatedRoleAssignment> => {
  const dictionary = {} as { [key: string]: AggregatedRoleAssignment };
  for (const userRoleAssignment of roleAssignments) {
    const item = {
      aggregationType: RoleAssignmentAggregation.Other,
      aggregationId: userRoleAssignment.scope,
      roleAssignments: [userRoleAssignment]
    } as AggregatedRoleAssignment;

    if (IsTenantScope(userRoleAssignment.scope)) {
      item.aggregationType = RoleAssignmentAggregation.Tenant;
      item.aggregationId = ExtractTenantFromScope(userRoleAssignment.scope);
      item.aggregationDisplayName =
        tenants.find(el => el.id == item.aggregationId)?.name ?? '';
    }
    if (IsEngagementScope(userRoleAssignment.scope)) {
      item.aggregationType = RoleAssignmentAggregation.Engagement;
      item.aggregationId = ExtractEngagementFromScope(userRoleAssignment.scope);
      item.aggregationDisplayName =
        engagements.find(el => el.id == item.aggregationId)?.name ?? '';
    }

    const dictionaryKey = item.aggregationType + '_' + item.aggregationId;
    if (!dictionary[dictionaryKey]) {
      dictionary[dictionaryKey] = item;
    } else {
      dictionary[dictionaryKey].roleAssignments.push(userRoleAssignment);
    }
  }

  const output = new Array<AggregatedRoleAssignment>();
  for (const key in dictionary) {
    output.push(dictionary[key]);
  }
  return output.sort(compareAggregatedRoleAssignments);
};

export const compareAggregatedRoleAssignments = (
  a: AggregatedRoleAssignment,
  b: AggregatedRoleAssignment
): number => {
  if (a.aggregationType < b.aggregationType) return -1;
  if (a.aggregationType > b.aggregationType) return 1;
  if (a.aggregationDisplayName < b.aggregationDisplayName) return -1;
  if (a.aggregationDisplayName > b.aggregationDisplayName) return 1;
  return 0;
};
