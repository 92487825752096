import Overview from '@/views/home/overview/Overview.vue';
import Manual from '../components/Manual.vue';

export default [
  {
    path: '/overview',
    name: 'Overview',
    component: Overview,
    meta: { menuItemName: 'Overview' }
  },
  {
    path: '/manual',
    name: 'Manual',
    component: Manual
  }
];
