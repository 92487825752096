import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, { model: $setup.form }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form_item, {
        prop: "createGatewayEntry",
        class: "mb-0"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_checkbox, {
            class: "checkbox",
            label: _ctx.$t('createGatewayEntry'),
            "model-value": $setup.form.createGatewayEntry,
            onChange: $setup.handleChangeCreateGatewayEntry
          }, null, 8, ["label", "model-value", "onChange"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, {
        prop: "createContainers",
        class: "mb-0"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_checkbox, {
            class: "checkbox",
            label: _ctx.$t('createContainers'),
            "model-value": $setup.form.createContainers,
            onChange: $setup.handleChangeCreateContainers
          }, null, 8, ["label", "model-value", "onChange"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, {
        prop: "deployBlobPrivateEndpoint",
        class: "mb-0"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_checkbox, {
            class: "checkbox",
            label: _ctx.$t('deployBlobPrivateEndpoint'),
            "model-value": $setup.form.deployBlobPrivateEndpoint,
            onChange: $setup.handleChangeDeployBlobPrivateEndpoint
          }, null, 8, ["label", "model-value", "onChange"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, {
        prop: "deployDfsPrivateEndpoint",
        class: "mb-0"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_checkbox, {
            class: "checkbox",
            label: _ctx.$t('deployDfsPrivateEndpoint'),
            "model-value": $setup.form.deployDfsPrivateEndpoint,
            onChange: $setup.handleChangeDeployDfsPrivateEndpoint
          }, null, 8, ["label", "model-value", "onChange"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, {
        prop: "deployFilePrivateEndpoint",
        class: "mb-0"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_checkbox, {
            class: "checkbox",
            label: _ctx.$t('deployFilePrivateEndpoint'),
            "model-value": $setup.form.deployFilePrivateEndpoint,
            onChange: $setup.handleChangeDeployFilePrivateEndpoint
          }, null, 8, ["label", "model-value", "onChange"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, {
        prop: "deployQueuePrivateEndpoint",
        class: "mb-0"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_checkbox, {
            class: "checkbox",
            label: _ctx.$t('deployQueuePrivateEndpoint'),
            "model-value": $setup.form.deployQueuePrivateEndpoint,
            onChange: $setup.handleChangeDeployQueuePrivateEndpoint
          }, null, 8, ["label", "model-value", "onChange"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model"]))
}