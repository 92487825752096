<template>
  <base-confirmation-modal
    :model-value="modelValue"
    width="500px"
    :feedback-text="$t('resourceDeleted')"
    @update:model-value="val => handleClose(val)"
    @confirm="handleConfirm()"
  >
    <template #content>
      <div class="content">
        <div>
          <h3 class="subtitle-2 modal-content">{{ confirmationText }}</h3>
        </div>
        <div class="content-type">
          <h4>Type</h4>
          <span class="body-1">{{ typeText }}</span>
        </div>
        <div class="content-name">
          <h4>Name</h4>
          <span class="body-1">{{ nameText }}</span>
        </div>
      </div>
    </template>
  </base-confirmation-modal>
</template>
<script lang="ts">
import BaseConfirmationModal from '@/components/modals/BaseConfirmationModal.vue';
import i18n from '@/i18n';

export default {
  name: 'DeleteResourceConfirmationModal',
  components: { BaseConfirmationModal },
  props: {
    modelValue: { type: Boolean, default: false },
    center: { type: Boolean, default: true },
    confirmationText: {
      type: String,
      default: i18n.global.t('confirmDeleteResource')
    },
    typeText: { type: String, default: '' },
    nameText: { type: String, default: '' }
  },
  emits: ['confirm', 'update:model-value'],
  setup(props, { emit }) {
    const handleClose = (val: boolean) => {
      emit('update:model-value', val);
    };

    const handleConfirm = () => {
      emit('confirm');
    };

    return {
      handleClose,
      handleConfirm
    };
  }
};
</script>
<style scoped lang="scss">
.content {
  margin: 0px 10px;
}

.content-type {
  text-align: left;
  margin-top: 20px;
}

.content-name {
  text-align: left;
  margin-top: 20px;
}
</style>
