<template>
  <base-modal
    width="500px"
    :modal-title="$t('metadata')"
    :confirm-text="$t('save')"
    :dialog-visible="dialogVisible"
    @confirm="confirm"
    @close="handleClose"
  >
    <template #content>
      <el-form
        v-for="(form, index) in forms"
        :key="index"
        ref="formRef"
        :model="form.value"
        :rules="formRules"
      >
        <el-row class="mt-1 mb-2 is-justify-space-between">
          <el-col :span="10">
            <el-form-item prop="key">
              <el-input
                v-model="form.value.key"
                placeholder="key"
                size="small"
              />
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item prop="value">
              <el-input
                v-model="form.value.value"
                placeholder="value"
                size="small"
              />
            </el-form-item>
          </el-col>
          <el-col
            :span="1"
            class="d-flex justify-content-center align-items-center"
          >
            <el-tooltip placement="top" :content="$t('deleteEntry')">
              <img
                :src="require(`@/assets/images/icons/new/delete.svg`)"
                height="14"
                width="14"
                class="action-icon ml-auto"
                @click="deleteMetadataEntry(index)"
              />
            </el-tooltip>
          </el-col>
        </el-row>
      </el-form>
      <el-row class="mt-3">
        <el-col :span="12">
          <el-button type="info" size="small" @click="addMetadataEntry">
            <h4>{{ $t('addEntry') }}</h4>
          </el-button>
        </el-col>
      </el-row>
    </template>
  </base-modal>
</template>
<script lang="ts">
import {
  ElForm,
  ElFormItem,
  ElInput,
  ElMessage,
  ElButton,
  ElRow,
  ElCol,
  ElTooltip
} from 'element-plus';

import i18n from '@/i18n';
import BaseModal from '@/components/modals/BaseModal.vue';
import { onMounted, onUpdated, reactive, Ref, ref } from 'vue';
import { mustBeAlphanumericUnderscore } from '@/utils/formValidators';

export default {
  name: 'MetadataModal',
  components: {
    ElTooltip,
    ElCol,
    ElRow,
    ElButton,
    BaseModal,
    ElForm,
    ElFormItem,
    ElInput
  },
  props: {
    dialogVisible: { type: Boolean },
    metadata: {
      type: Object as () => Record<string, string> | null,
      default: null
    }
  },
  emits: ['confirm', 'close'],
  setup(props, { emit }) {
    interface DictionaryEntry {
      key: string;
      value: string;
    }

    const forms = ref(new Array<Ref<DictionaryEntry>>());
    const formRef = ref<HTMLElement | null>(null);
    const formRules = reactive({
      key: [
        {
          required: true,
          message: i18n.global.t('thisFieldIsRequired'),
          trigger: ['change', 'blur']
        },
        {
          validator: mustBeAlphanumericUnderscore,
          trigger: 'blur'
        }
      ],
      value: [
        {
          required: true,
          message: i18n.global.t('thisFieldIsRequired'),
          trigger: ['change', 'blur']
        }
      ]
    });

    const addMetadataEntry = () => {
      let newEntry = ref({
        key: '',
        value: ''
      } as DictionaryEntry);
      if (!forms.value?.length) {
        forms.value = [newEntry];
      } else {
        forms.value.push(newEntry);
      }
    };

    const deleteMetadataEntry = (index: number) => {
      if (!forms.value?.length) return;
      forms.value.splice(index, 1);
    };

    const confirm = () => {
      let allFormsValid = true;
      let numFormsChecked = 0;
      if (Array.isArray(formRef.value) && formRef.value.length === 0) {
        emit('confirm', {});
      } else {
        if (Array.isArray(formRef.value)) {
          formRef.value?.forEach((el: HTMLFormElement) =>
            el.validate((formValid: boolean) => {
              if (!formValid) allFormsValid = false;
              numFormsChecked++;
              if (numFormsChecked == forms.value.length && allFormsValid) {
                emit('confirm', dictToMetadata(forms.value));
                ElMessage({
                  showClose: true,
                  message: i18n.global.t('savingChanges')
                });
              } else {
                return false;
              }
            })
          );
        }
      }
    };

    const handleClose = () => emit('close');

    const dictToMetadata = (
      arr: Ref<DictionaryEntry>[]
    ): Record<string, string> => {
      const dictionary: Record<string, string> = {};
      arr.forEach(refObj => {
        const obj = refObj.value;
        dictionary[obj.key] = obj.value;
      });
      return dictionary;
    };

    const metadataToDict = (
      config: Record<string, string> | null
    ): Array<DictionaryEntry> => {
      if (!config) return [];
      return Object.entries(config).map(([key, value]) => {
        return { key, value };
      });
    };

    onMounted(() => {
      forms.value = metadataToDict(props.metadata).map(el => ref(el));
    });

    onUpdated(() => {
      forms.value = metadataToDict(props.metadata).map(el => ref(el));
    });

    return {
      addMetadataEntry,
      confirm,
      deleteMetadataEntry,
      forms,
      formRef,
      formRules,
      handleClose
    };
  }
};
</script>
