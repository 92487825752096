export enum ModificationAction {
  Add,
  Remove
}

export class Modification<T> {
  item: T;
  action: ModificationAction;

  constructor(item: T, action: ModificationAction) {
    this.item = item;
    this.action = action;
  }
}
