import { ElNotification } from 'element-plus';

const ErrorNotification = (notification: ErrorNotification) => {
  ElNotification({
    title: notification.title,
    message: notification.message,
    position: 'bottom-right',
    offset: 50,
    type: 'error'
  });
};

export default ErrorNotification;
export interface ErrorNotification {
  title: string;
  message: string;
}
