import { createStore, createLogger } from 'vuex';
import VuexPersist from 'vuex-persist';
import base from '@/views/store/base';
import home from '@/views/home/store/home';
import overviewStore from '@/views/home/overview/store/overview';
import importStore from '@/views/home/data-journey/import/store/import';
import modelStore from '@/views/home/data-journey/model/store/model';
import dashboardStore from '@/views/home/reporting/dashboard/store/dashboard';
import labelingStore from '@/views/home/data-journey/labeling/store/labeling';
import filteringStore from '@/views/home/data-journey/filtering/store/filtering';
import analyticalStore from '@/views/home/data-journey/analytical/store/analytical';
import tenantsStore from '@/views/admin/tenants/store/tenants';
import testingStore from '@/views/home/data-journey/testing/store/testing';
import usersStore from '@/views/admin/users/store/users';
import engagementsStore from '@/views/admin/engagements/store/engagements';
import configurationStore from '@/views/home/administration/configuration/store/configuration';
import costsStore from '@/views/home/administration/costs/store/costs';
import applicationStore from '@/views/admin/applications/store/applications';
import rolesStore from '@/views/admin/roles/store/roles';
import permissionsStore from '@/views/admin/permissions/store/permissions';
import userPermissionsStore from '@/store/userPermissions';
import gainStore from '@/views/home/administration/gain/store/gain';
import storageStore from '@/views/home/storage/store/storage';

const vuexSessionStorage = new VuexPersist({
  storage: window.sessionStorage,
  modules: ['home']
});

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    base,
    home,
    overview: overviewStore,
    import: importStore,
    model: modelStore,
    dashboard: dashboardStore,
    labeling: labelingStore,
    filtering: filteringStore,
    analytical: analyticalStore,
    tenants: tenantsStore,
    testing: testingStore,
    users: usersStore,
    engagements: engagementsStore,
    configuration: configurationStore,
    costs: costsStore,
    applications: applicationStore,
    roles: rolesStore,
    permissions: permissionsStore,
    userPermissions: userPermissionsStore,
    gain: gainStore,
    storage: storageStore
  },
  plugins: [vuexSessionStorage.plugin, createLogger()]
});
