import Api from '@/services/api';

//TODO: Old api?
export default {
  async getProfilePicture(dimentions: string) {
    return Api('graphApi').get(`v1.0/me/photos/${dimentions}/$value`, {
      responseType: 'arraybuffer'
    });
  }
};
