<template>
  <div>
    <el-row>
      <page-header :main-title="$t('tenants')">
        <template #subheader>
          <h4 class="subtitle-2 mt-3">
            {{ $t('tenantDesc') }}
          </h4>
        </template>
      </page-header>
    </el-row>
    <base-table-header
      :row-count="tenantsCount"
      :quantity-message="$t('tenants')"
      :input-placeholder="$t('searchTenants')"
      debounce
      class="mt-6"
      @query-change="updateTenantsFilter"
    >
      <el-button
        v-if="!isCloudProviderAws"
        class="ml-2"
        type="primary"
        @click="openNewTenantModal()"
      >
        <h4>{{ '+ ' + $t('newTenant') }}</h4>
      </el-button>
    </base-table-header>
    <el-row>
      <el-col>
        <div class="tenants-table">
          <el-table :data="tenants">
            <el-table-column prop="name" sortable="custom" :label="$t('name')">
              <template #default="scope">
                <div>
                  <h4>{{ scope.row.name }}</h4>
                  <span class="body-1 mt-1">
                    {{ scope.row.id }}
                  </span>
                </div>
              </template>
            </el-table-column>
            <el-table-column width="100">
              <template #default="scope">
                <div>
                  <img
                    :src="require(`@/assets/images/icons/new/settings.svg`)"
                    class="action-icon action-spin"
                    height="14"
                    width="14"
                    @click="openTenantDetails(scope.row.id)"
                  />
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
    <pagination
      :count="tenantsCount"
      :page="tenantsPage"
      :page-size="tenantsPageSize"
      @change-page="changePage"
    />
    <new-tenant-modal
      v-model="showNewTenantModal"
      :feedback-text="$t('newTenantAdded')"
      @confirm="createNewTenant"
    />
  </div>
</template>
<script lang="ts">
import PageHeader from '@/components/misc/PageHeader.vue';
import BaseTableHeader from '@/components/tables/BaseTableHeader.vue';
import { ElTable, ElTableColumn, ElCol, ElRow, ElButton } from 'element-plus';
import { computed, onMounted, ref, ComputedRef } from 'vue';
import { useStore } from 'vuex';
import NewTenantModal from './components/NewTenantModal.vue';
import router from '@/router';
import { BaseTenantModel, CreateTenantModel } from '@etp/etp-infra-client';
import Pagination from '@/components/misc/Pagination.vue';
import config from '@/config/config';

export default {
  name: 'TenantsOverview',
  components: {
    BaseTableHeader,
    ElTable,
    ElTableColumn,
    PageHeader,
    NewTenantModal,
    ElRow,
    ElCol,
    ElButton,
    Pagination
  },

  setup() {
    const store = useStore();

    const tenants: ComputedRef<Array<BaseTenantModel>> = computed(
      () => store.getters['tenants/getPageTenants']
    );

    let tenantsCount: ComputedRef<number> = computed(
      () => store.getters['tenants/getTenantsCount']
    );

    let tenantsPage: ComputedRef<number> = computed(
      () => store.getters['tenants/getTenantsPageNumber']
    );

    let tenantsPageSize: ComputedRef<number> = computed(
      () => store.getters['tenants/tenantsPageSize']
    );

    const fetchTenants = async () => {
      await store.dispatch('tenants/fetchPageTenants', tenantsFilter.value);
    };

    const isCloudProviderAws = config.cloudProvider.toLowerCase() === 'aws';

    const showNewTenantModal = ref(false);

    const tenantsFilter = ref('');

    const openNewTenantModal = () => {
      showNewTenantModal.value = true;
    };

    const openTenantDetails = (tenantId: string) => {
      router.push({
        name: 'TenantDetails',
        params: { tenantId }
      });
    };

    const createNewTenant = async (tenant: CreateTenantModel) => {
      showNewTenantModal.value = false;
      await store.dispatch('tenants/createTenant', tenant);
      await fetchTenants();
    };

    const updateTenantsFilter = async (inputQuery: string) => {
      tenantsFilter.value = inputQuery.length >= 3 ? inputQuery : '';
      await fetchTenants();
    };

    const changePage = async (page: number) => {
      store.commit('tenants/SET_TENANTS_PAGE', page);
      await fetchTenants();
    };

    onMounted(async () => {
      await fetchTenants();
    });

    return {
      tenants,
      openNewTenantModal,
      showNewTenantModal,
      createNewTenant,
      openTenantDetails,
      tenantsCount,
      tenantsPage,
      tenantsPageSize,
      changePage,
      updateTenantsFilter,
      isCloudProviderAws
    };
  }
};
</script>
<style scoped lang="scss">
.tenants-table {
  &.action-spin:hover {
    transform: scale(1.15) rotate(90deg);
    opacity: 1;
  }
}

.align-right {
  float: right;
}
</style>
