import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_base_modal = _resolveComponent("base-modal")!

  return (_openBlock(), _createBlock(_component_base_modal, {
    width: "400px",
    "modal-title": $props.permission.name ? _ctx.$t('editPermission') : _ctx.$t('newPermission'),
    "dialog-visible": $props.modelValue,
    "confirm-text": _ctx.$t('save'),
    onConfirm: $setup.confirm,
    onClose: $setup.handleClose
  }, {
    content: _withCtx(() => [
      _createVNode(_component_el_form, {
        ref: "formRef",
        model: $setup.form,
        rules: $setup.formRules,
        "label-position": "top"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, {
            label: _ctx.$t('name'),
            prop: "name"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: $setup.form.name,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.form.name) = $event)),
                disabled: $props.permission.name,
                size: "small"
              }, null, 8, ["modelValue", "disabled"])
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_form_item, {
            label: _ctx.$t('category'),
            prop: "category"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: $setup.form.category,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.form.category) = $event)),
                size: "small"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_form_item, {
            label: _ctx.$t('description'),
            prop: "description"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: $setup.form.description,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.form.description) = $event)),
                size: "small"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["label"])
        ]),
        _: 1
      }, 8, ["model", "rules"])
    ]),
    _: 1
  }, 8, ["modal-title", "dialog-visible", "confirm-text", "onConfirm", "onClose"]))
}