<template>
  <el-row :gutter="spacingWidthHeaderComponents">
    <el-col :span="5">
      <base-card no-header>
        <template #body>
          <el-form>
            <el-form-item :label="$t('branch')" class="mb-0 form-item">
              <div class="d-flex align-items-center">
                <el-select
                  v-model="selectedBranch"
                  :disabled="loadingBranches"
                  value-key="name"
                  size="small"
                  :placeholder="loadingBranches ? $t('loading') : $t('empty')"
                >
                  <el-option
                    v-for="item in branches"
                    :key="item.name ?? ''"
                    :label="item.name ?? ''"
                    :value="item ?? ''"
                  ></el-option>
                </el-select>
                <el-button
                  :disabled="
                    !userCanWriteEngagementNodeModelGit ||
                    isSignedOffGainEngagement
                  "
                  class="icon-button p-1 cursor-pointer"
                  @click="showBranchModal = true"
                >
                  <plus class="icon-size-8" />
                </el-button>
              </div>
            </el-form-item>
          </el-form>
        </template>
      </base-card>
      <base-card v-if="selectedBranch.name" no-header class="mt-1">
        <template #body>
          <el-form>
            <el-form-item :label="$t('node')" class="mb-0 form-item">
              <div class="d-flex align-items-center">
                <el-select
                  v-model="selectedNode"
                  :disabled="!selectedBranch.name || loadingNodes"
                  value-key="name"
                  size="small"
                  :placeholder="loadingNodes ? $t('loading') : $t('empty')"
                >
                  <el-option
                    v-for="item in nodes"
                    :key="item.name ?? ''"
                    :label="item.name ?? ''"
                    :value="item ?? ''"
                  ></el-option>
                </el-select>
                <el-button
                  :disabled="
                    !selectedBranch.name ||
                    selectedBranch.readOnly ||
                    !userCanWriteEngagementNodeModelGit ||
                    isSignedOffGainEngagement
                  "
                  class="icon-button p-1 cursor-pointer"
                  @click="showNodeModal = true"
                >
                  <plus class="icon-size-8" />
                </el-button>
              </div>
            </el-form-item>
          </el-form>
          <el-collapse v-if="selectedNode.name" model-value="fields">
            <el-collapse-item name="fields" class="mb--2" :title="$t('fields')">
              <el-form class="model-form" label-position="top">
                <el-form-item>
                  <el-scrollbar max-height="200px">
                    <model-list-item
                      v-for="field in fieldsObjects"
                      :key="field.name ?? ''"
                      :item="field"
                    />
                  </el-scrollbar>
                  <div
                    v-if="
                      !selectedBranch.readOnly &&
                      userCanWriteEngagementNodeModelGit &&
                      !isSignedOffGainEngagement
                    "
                    class="edit-fields-container"
                    @click="showFieldsModal = true"
                  >
                    <div class="body-1 edit-fields-icon">
                      <img
                        src="@/assets/images/icons/new/edit.svg"
                        height="16"
                        width="16"
                        class="edit-field action-icon"
                      />
                      <h4>{{ $t('editFields') }}</h4>
                    </div>
                  </div>
                </el-form-item>
              </el-form>
            </el-collapse-item>
          </el-collapse>
        </template>
      </base-card>
      <base-card v-if="selectedNode.name" no-header class="mt-1">
        <template #body>
          <el-form>
            <el-form-item :label="$t('test')" class="mb-0 form-item">
              <div class="d-flex align-items-center">
                <el-select
                  v-model="selectedTest"
                  :disabled="!selectedNode.name || loadingTests"
                  value-key="name"
                  size="small"
                  :placeholder="loadingTests ? $t('loading') : $t('empty')"
                >
                  <el-option
                    v-for="test in tests"
                    :key="test.name ?? ''"
                    :label="test.name ?? ''"
                    :value="test ?? ''"
                  ></el-option>
                </el-select>
                <el-button
                  :disabled="
                    !selectedNode.name ||
                    selectedBranch.readOnly ||
                    !userCanWriteEngagementNodeModelGit ||
                    isSignedOffGainEngagement
                  "
                  class="icon-button p-1 cursor-pointer"
                  @click="showTestModal = true"
                >
                  <plus class="icon-size-8" />
                </el-button>
              </div>
            </el-form-item>
          </el-form>
          <el-collapse
            v-if="selectedTest.name && !isGainEngagement"
            model-value="categories"
          >
            <el-collapse-item
              name="categories"
              class="mb--2"
              :title="$t('categories')"
            >
              <el-form class="model-form" label-position="top">
                <el-form-item>
                  <el-scrollbar max-height="200px">
                    <div
                      v-for="item in testDetail.categoryNames"
                      :key="item ?? ''"
                      class="model-list-item mb-1"
                    >
                      <span class="d-flex body-1 align-items-center ml-2">
                        {{ item }}
                      </span>
                    </div>
                  </el-scrollbar>
                  <div
                    v-if="
                      !selectedBranch.readOnly ||
                      !userCanWriteEngagementNodeModelGit ||
                      isSignedOffGainEngagement
                    "
                    class="edit-fields-container"
                    @click="showCategoriesModal = true"
                  >
                    <div class="body-1 edit-fields-icon">
                      <img
                        src="@/assets/images/icons/new/edit.svg"
                        height="16"
                        width="16"
                        class="edit-fields action-icon ml-1"
                      />
                      <h4 class="ml-1">{{ $t('editCategories') }}</h4>
                    </div>
                  </div>
                </el-form-item>
              </el-form>
            </el-collapse-item>
          </el-collapse>
        </template>
      </base-card>
    </el-col>
    <el-col :span="19">
      <base-card no-header>
        <template #body>
          <div class="d-flex pr-2">
            <div
              v-if="
                testDetail.query &&
                !selectedBranch.readOnly &&
                userCanWriteEngagementNodeModelGit &&
                !isSignedOffGainEngagement
              "
              class="ml-auto"
            >
              <el-button
                type="primary"
                class="mt-2"
                @click.stop="updateTestDetail"
              >
                <h4>{{ $t('save') }}</h4>
              </el-button>
            </div>
          </div>
          <el-form>
            <el-form-item :label="$t('queryEditor')">
              <code-editor
                v-model="testDetail.query"
                :read_only="!selectedTest.name"
                font_size="13px"
                :languages="[['SQL', 'SQL']]"
                theme="light"
                min_height="300px"
                min_width="100%"
              ></code-editor>
            </el-form-item>
          </el-form>
        </template>
      </base-card>
    </el-col>
  </el-row>
  <fields-modal
    :dialog-visible="showFieldsModal"
    :fields="fields"
    :all-fields-in-query="allFieldsInQuery"
    :min-field-length="isGainEngagement ? 2 : 4"
    @confirm="updateFields"
    @close="showFieldsModal = false"
  />
  <categories-modal
    :dialog-visible="showCategoriesModal"
    :categories="testDetail.categoryNames ?? []"
    @confirm="updateCategories"
    @close="showCategoriesModal = false"
  />
  <new-branch-modal
    :show-modal="showBranchModal"
    :branches="branches"
    @confirm="addNewBranch"
    @close="showBranchModal = false"
  />
  <new-node-modal
    :show-modal="showNodeModal"
    :nodes="nodes"
    hide-schema-field
    @confirm="addNewNode"
    @close="showNodeModal = false"
  />
  <new-test-modal
    :show-modal="showTestModal"
    @confirm="addNewTest"
    @close="showTestModal = false"
  />
</template>

<script lang="ts">
import {
  ElButton,
  ElCol,
  ElForm,
  ElFormItem,
  ElOption,
  ElRow,
  ElSelect,
  ElCollapse,
  ElCollapseItem,
  ElScrollbar,
  ElMessage
} from 'element-plus';

import BaseCard from '@/components/panels/BaseCard.vue';
import FieldsModal from '@/components/modals/FieldsModal.vue';
import CategoriesModal from '@/components/modals/CategoriesModal.vue';
import NewBranchModal from '@/components/modals/NewBranchModal.vue';
import NewNodeModal from '@/components/modals/NewNodeModal.vue';
import NewTestModal from '@/components/modals/NewTestModal.vue';
import ModelListItem from '@/components/list/BaseFieldslListItem.vue';
import CodeEditor from 'simple-code-editor/CodeEditor.vue';
import { computed, ComputedRef, onMounted, ref } from 'vue';
import { spacingWidthHeaderComponents } from '@/utils/variables';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { appendUrlParam, processUrlParams } from '@/utils/urlParameter';
import { computeGitFields, getFieldsFromQuery } from '@/utils/fieldsHelper';
import i18n from '@/i18n';
import { Plus } from '@element-plus/icons-vue';
import {
  BranchModel,
  CreateBranchModel,
  CreateFieldModel,
  CreateNodeModel,
  CreateTestModel,
  FieldModel,
  NodeModel,
  TestDetailModel,
  TestModel
} from '@etp/etp-nodemodelgit-client/axios';
import { ConfigureField } from '@/services/web-api/dto/nodeModel';
import { GitBranch } from '@etp/etp-pipelines-client/axios';
import { RequirementsStatus } from '@etp/etp-gain-bff-client/axios';

export default {
  name: 'TestingConfig',
  components: {
    BaseCard,
    CategoriesModal,
    ElButton,
    ElCol,
    ElForm,
    ElFormItem,
    ElOption,
    ElRow,
    ElScrollbar,
    ElSelect,
    NewBranchModal,
    NewNodeModal,
    NewTestModal,
    FieldsModal,
    CodeEditor,
    ModelListItem,
    ElCollapse,
    ElCollapseItem,
    Plus
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    let showBranchModal = ref(false);
    let showFieldsModal = ref(false);
    let showCategoriesModal = ref(false);
    let showNodeModal = ref(false);
    let showTestModal = ref(false);

    let loadingBranches: ComputedRef<boolean> = computed(
      () => store.getters['testing/loadingBranches']
    );

    let loadingNodes: ComputedRef<boolean> = computed(
      () => store.getters['testing/loadingNodes']
    );

    let loadingTests: ComputedRef<boolean> = computed(
      () => store.getters['testing/loadingTests']
    );

    const fetchBranches = async () => {
      await store.dispatch('testing/fetchBranches');
    };
    const fetchNodes = async () => {
      await store.dispatch('testing/fetchConfigNodes');
    };
    const fetchFields = async () => {
      await store.dispatch('testing/fetchConfigFields');
    };
    const fetchTests = async () => {
      await store.dispatch('testing/fetchConfigTests');
    };
    const fetchTestDetail = async () => {
      await store.dispatch('testing/fetchConfigTestDetail');
    };
    const updateFields = async (fields: Array<CreateFieldModel>) => {
      await store.dispatch('testing/updateConfigFields', fields);
      await fetchFields();
      showFieldsModal.value = false;
    };

    const branches = computed<Array<GitBranch>>(
      () => store.getters['testing/branches']
    );
    const nodes = computed<Array<NodeModel>>(
      () => store.getters['testing/configNodes']
    );
    const fields = computed<Array<FieldModel>>(
      () => store.getters['testing/configFields']
    );
    const tests = computed<Array<TestModel>>(
      () => store.getters['testing/configTests']
    );
    const testDetail = computed<TestDetailModel>(
      () => store.getters['testing/configTestDetail']
    );

    const addNewBranch = async (model: CreateBranchModel) => {
      await store.dispatch('testing/addNewBranch', model);
      await fetchBranches();
      showBranchModal.value = false;
    };
    const addNewNode = async (model: CreateNodeModel) => {
      await store.dispatch('testing/addNewNode', model);
      await fetchNodes();
      showNodeModal.value = false;
    };
    const addNewTest = async (model: CreateTestModel) => {
      await store.dispatch('testing/addNewTest', model);
      await fetchTests();
      showTestModal.value = false;
    };
    const updateCategories = async (categoryNames: Array<string>) => {
      testDetail.value.categoryNames = categoryNames;
      await store.dispatch('testing/updateConfigCategories', categoryNames);
      showCategoriesModal.value = false;
    };
    const updateTestDetail = async () => {
      ElMessage({
        showClose: true,
        message: i18n.global.t('savingChanges')
      });
      await store.dispatch('testing/updateConfigTestDetail');
      await fetchTestDetail();
    };

    const selectedBranch = computed({
      get: () => store.getters['testing/selectedConfigBranch'],
      set: async (branch: BranchModel) => {
        appendUrlParam('branch', branch.name ?? '');
        store.commit('testing/SET_SELECTED_CONFIG_BRANCH', branch);
        await fetchNodes();
      }
    });
    const selectedNode = computed({
      get: () => store.getters['testing/selectedConfigNode'],
      set: async (node: NodeModel) => {
        appendUrlParam('branch', selectedBranch.value.name ?? '');
        appendUrlParam('node', node.name ?? '');
        store.commit('testing/SET_SELECTED_CONFIG_NODE', node);
        await fetchTests();
        await fetchFields();
      }
    });
    const selectedTest = computed({
      get: () => store.getters['testing/selectedConfigTest'],
      set: async (test: TestModel) => {
        appendUrlParam('branch', selectedBranch.value.name ?? '');
        appendUrlParam('node', selectedNode.value.name ?? '');
        appendUrlParam('test', test.name ?? '');
        store.commit('testing/SET_SELECTED_CONFIG_TEST', test);
        await fetchTestDetail();
      }
    });
    const fieldsObjects = computed<ConfigureField[]>(() =>
      computeGitFields(fields.value ?? [], testDetail.value.query ?? '')
    );
    const allFieldsInQuery = computed<string[]>(() =>
      getFieldsFromQuery(testDetail.value.query || '')
    );

    let showNodeModelGitPages: ComputedRef<boolean> = computed(
      () => store.getters['home/showNodeModelGitPages']
    );
    const isGainEngagement: ComputedRef<boolean> = computed(
      () => store.getters['home/isGainEngagement']
    );
    const userCanWriteEngagementNodeModelGit: ComputedRef<boolean> = computed(
      () => store.getters['userPermissions/canWriteEngagementNodeModelGit']
    );
    const isSignedOffGainEngagement: ComputedRef<boolean> = computed(
      () =>
        isGainEngagement.value &&
        store.getters['gain/getCurrentRequirementsStatus']
          ?.requirementsStatus === RequirementsStatus.SignedOff
    );

    onMounted(async () => {
      if (!showNodeModelGitPages.value) {
        return router.push({ name: 'Overview' });
      }
      await store.dispatch(
        'userPermissions/checkIfCanWriteEngagementNodeModelGit'
      );
      if (isGainEngagement.value) {
        await store.dispatch('gain/fetchCurrentRequirementsStatus');
      }
      fetchBranches().then(async () => {
        await processUrlParams([
          {
            name: 'branch',
            handler: async (branchName: string) => {
              let branch = branches.value.find(
                (el: BranchModel) => el.name == branchName
              );
              if (!branch) return;
              store.commit('testing/SET_SELECTED_CONFIG_BRANCH', branch);
              await fetchNodes();
            }
          },
          {
            name: 'node',
            handler: async (nodeName: string) => {
              let node = nodes.value.find(
                (el: NodeModel) => el.name == nodeName
              );
              if (!node) return;
              store.commit('testing/SET_SELECTED_CONFIG_NODE', node);
              await fetchTests();
              await fetchFields();
            }
          },
          {
            name: 'test',
            handler: async (testName: string) => {
              let test = tests.value.find(
                (el: TestModel) => el.name == testName
              );
              if (!test) return;
              store.commit('testing/SET_SELECTED_CONFIG_TEST', test);
              await fetchTestDetail();
            }
          }
        ]);
      });
    });

    return {
      addNewBranch,
      addNewNode,
      addNewTest,
      branches,
      nodes,
      selectedBranch,
      selectedNode,
      selectedTest,
      loadingBranches,
      loadingNodes,
      loadingTests,
      showBranchModal,
      showCategoriesModal,
      showNodeModal,
      showTestModal,
      spacingWidthHeaderComponents,
      tests,
      testDetail,
      updateCategories,
      updateTestDetail,
      fieldsObjects,
      allFieldsInQuery,
      updateFields,
      showFieldsModal,
      fields,
      isGainEngagement,
      isSignedOffGainEngagement,
      userCanWriteEngagementNodeModelGit
    };
  }
};
</script>
<style lang="scss" scoped>
.model-list-item {
  background: $etp-deloitte-white;
  height: 24px;
  border-radius: 8px;
  display: flex;
}
.model-list-item-content {
  display: inline-flex;
  align-items: center;
  .model-list-item-status {
    width: 8px;
    height: 8px;
    background: $etp-deloitte-green-1;
    border-radius: 100%;
    margin: 0px 16px 0px 12px;
  }
}
.form-item {
  flex-grow: 1;
}
.el-collapse-item__content {
  padding-bottom: 0px !important;
}
</style>
