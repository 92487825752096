<template>
  <base-run-tab
    :loading-pipelines="loadingPipelines"
    :pipelines="pipelines"
    :can-hide-pipelines="userCanWriteEngagementPipelines"
    :start-pipeline-run="startPipelineRun"
    :toggle-pipeline="togglePipeline"
  />
</template>

<script lang="ts">
import BaseRunTab from '@/components/tabs/BaseRunTab.vue';
import { useStore } from 'vuex';
import { computed, onMounted, ComputedRef } from 'vue';
import { spacingWidthHeaderComponents } from '@/utils/variables';
import { useRouter } from 'vue-router';
import {
  PipelineDto,
  StartPipelineRunDto,
  UpdatePipelineDto
} from '@etp/etp-pipelines-client/axios';
import { detectStoreToUse } from '@/utils/storeHelper';

export default {
  name: 'RunOverview',
  components: { BaseRunTab },
  setup() {
    const store = useStore();
    const router = useRouter();
    const storeToUse = detectStoreToUse();

    const loadingPipelines: ComputedRef<boolean> = computed(
      () => store.getters[`${storeToUse}/loadingListPipelines`]
    );
    const pipelines: ComputedRef<Array<PipelineDto>> = computed(
      () => store.getters[`${storeToUse}/pipelines`]
    );

    const listPipelines = async () => {
      await store.dispatch(`${storeToUse}/listPipelines`);
    };

    const startPipelineRun = async (pipeline: PipelineDto) => {
      await store.dispatch(`${storeToUse}/startPipelineRun`, {
        pipelineName: pipeline.shortName,
        parameters: {
          EngagementId: store.getters['home/getCurrentEngagement'].id
        }
      } as StartPipelineRunDto);
    };

    const togglePipeline = async (pipeline: PipelineDto) => {
      await store.dispatch(`${storeToUse}/updatePipeline`, {
        pipelineShortName: pipeline.shortName,
        hidePipeline: !pipeline.isHidden
      } as UpdatePipelineDto);
      await listPipelines();
    };

    const showPipelinePages: ComputedRef<boolean> = computed(
      () => store.getters['home/showPipelinePages']
    );
    const userCanWriteEngagementPipelines: ComputedRef<boolean> = computed(
      () => store.getters['userPermissions/canWriteEngagementPipelines']
    );

    onMounted(async () => {
      if (!showPipelinePages.value) return router.push({ name: 'Overview' });
      await listPipelines();
      await store.dispatch(
        'userPermissions/checkIfCanWriteEngagementPipelines'
      );
    });

    return {
      pipelines,
      startPipelineRun,
      spacingWidthHeaderComponents,
      togglePipeline,
      loadingPipelines,
      userCanWriteEngagementPipelines
    };
  }
};
</script>
