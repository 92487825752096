<template>
  <el-row class="d-flex">
    <h4 class="mt-auto">
      {{ deploymentTasks.length }} &#183;
      {{ $t('tasks') }}
    </h4>
  </el-row>
  <el-table :data="deploymentTasks" @row-click="openTask">
    <el-table-column prop="name" :width="400" :label="$t('name')">
      <template #default="scope">
        <span class="body-1">
          {{ scope.row.name }}
        </span>
      </template>
    </el-table-column>
    <el-table-column prop="message" :label="$t('message')">
      <template #default="scope">
        <span class="body-1">
          {{ scope.row.message }}
        </span>
      </template>
    </el-table-column>
    <el-table-column prop="status" :width="180" :label="$t('status')">
      <template #default="scope">
        <span class="body-1">
          {{ getTaskStatus(scope.row.status) }}
        </span>
      </template>
    </el-table-column>
    <el-table-column :width="100">
      <template #default="scope">
        <el-tooltip
          v-if="scope.row.type == 'DeployArmTemplateTask'"
          placement="top"
          :content="$t('open')"
        >
          <img
            :src="require(`@/assets/images/icons/new/healthcheck.svg`)"
            height="12"
            width="12"
            class="mr-1 cursor-pointer"
            @click="openTask(scope.row)"
          />
        </el-tooltip>
        <el-tooltip placement="top" :content="scope.row.configuration">
          <img
            :src="require(`@/assets/images/icons/new/settings.svg`)"
            height="12"
            width="12"
          />
        </el-tooltip>
      </template>
    </el-table-column>
  </el-table>
</template>
<script lang="ts">
import { ElTable, ElTableColumn, ElRow, ElTooltip } from 'element-plus';
import { computed, ComputedRef, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { DeploymentTaskViewModel, TenantModel } from '@etp/etp-infra-client';
import i18n from '@/i18n';
export default {
  name: 'TenantDeploymentTasks',
  components: {
    ElTable,
    ElTableColumn,
    ElRow,
    ElTooltip
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    const tenant: ComputedRef<TenantModel> = computed(
      () => store.getters['tenants/getActiveTenant']
    );

    const deploymentTasks: ComputedRef<Array<DeploymentTaskViewModel>> =
      computed(() => store.getters['tenants/getDeploymentTasks']);

    let openTask = (task: DeploymentTaskViewModel) => {
      if (!tenant.value.cloudConfiguration || !task.configuration) return;
      let subscriptionId = JSON.parse(tenant.value.cloudConfiguration)[
        'SubscriptionId'
      ];
      let deploymentName = JSON.parse(task.configuration)['DeploymentName'];
      if (!subscriptionId || !deploymentName) return;
      let azureUrl = `https://portal.azure.com/#blade/HubsExtension/DeploymentDetailsBlade/overview/id/%2Fsubscriptions%2F${subscriptionId}%2Fproviders%2FMicrosoft.Resources%2Fdeployments%2F${deploymentName}`;
      window.location.assign(azureUrl);
    };

    const getTaskStatus = (taskStatus: number) => {
      switch (taskStatus) {
        case 0:
          return i18n.global.t('new');
        case 1:
          return i18n.global.t('enqueued');
        case 2:
          return i18n.global.t('InProgress');
        case 3:
          return i18n.global.t('Success');
        default:
          return i18n.global.t('Failed');
      }
    };

    onMounted(async () => {
      let tenantId = router.currentRoute.value.params.tenantId as string;
      let deploymentId = router.currentRoute.value.params
        .deploymentId as string;
      await store.dispatch('tenants/fetchTenant', { tenantId });
      await store.dispatch('tenants/fetchDeploymentTasks', {
        tenantId,
        deploymentId
      });
    });

    return {
      deploymentTasks,
      getTaskStatus,
      openTask
    };
  }
};
</script>

<style lang="scss" scoped></style>
