import { Module } from 'vuex';
import { NodeModelStoreModule, NodeModelStoreState } from '@/store/nodeModel';
import { LayerName } from '@etp/etp-pipelines-client/axios';
import { PipelinesStoreModule, PipelinesStoreState } from '@/store/pipelines';

const getDefaultState = () => {
  return {
    ...new NodeModelStoreModule('labeling').state,
    ...new PipelinesStoreModule(LayerName.LabelingLayer).state
  };
};

interface LabelingState extends NodeModelStoreState, PipelinesStoreState {}

const nodeModelStore = new NodeModelStoreModule('labeling');
const pipelinesStore = new PipelinesStoreModule(LayerName.LabelingLayer);

class LabelingModule implements Module<LabelingState, any> {
  namespaced = true;
  state = {
    ...nodeModelStore.state,
    ...pipelinesStore.state
  };
  getters = {
    ...nodeModelStore.getters,
    ...pipelinesStore.getters
  };
  actions = {
    ...nodeModelStore.actions,
    ...pipelinesStore.actions
  };
  mutations = {
    ...nodeModelStore.mutations,
    ...pipelinesStore.mutations,
    RESET_STATE(state: LabelingState) {
      Object.assign(state, getDefaultState());
    }
  };
}

export default new LabelingModule();
