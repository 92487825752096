import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_row = _resolveComponent("el-row")!
  const _component_batch_details_card = _resolveComponent("batch-details-card")!
  const _component_base_table = _resolveComponent("base-table")!
  const _component_el_col = _resolveComponent("el-col")!

  return (_openBlock(), _createBlock(_component_el_row, {
    gutter: $setup.spacingWidthHeaderComponents,
    class: "mt-6"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_col, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_row, { class: "mt-2 mb-1" }, {
            default: _withCtx(() => [
              _createElementVNode("h4", null, _toDisplayString($setup.batchDetails.length) + " · " + _toDisplayString(_ctx.$t('results')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_row, { class: "d-block" }, {
            default: _withCtx(() => [
              _createVNode(_component_base_table, {
                "show-loading-table": $setup.loadingTable,
                "show-no-content-table": !$setup.batchDetails.length,
                "error-message": _ctx.$t('noBatchDetails')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_row, { class: "mt-1" }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.batchDetails, (item, index) => {
                        return (_openBlock(), _createBlock(_component_batch_details_card, {
                          key: index,
                          entry: item,
                          class: "mb-1"
                        }, null, 8, ["entry"]))
                      }), 128))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["show-loading-table", "show-no-content-table", "error-message"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["gutter"]))
}