<template>
  <div class="d-flex">
    <div class="d-flex" @click="goToPath(0)">
      <h6 class="hyperlink">{{ rootStorageName }}</h6>
      <h6 class="ml-1">{{ '/' }}</h6>
    </div>
    <div v-for="(item, index) in path" :key="index" class="d-flex ml-1">
      <div class="d-flex hyperlink" @click="goToPath(index + 1)">
        <img
          :src="require(`@/assets/images/icons/folder-16.svg`)"
          height="14"
          width="14"
        />
        <h6 class="ml-1">{{ item }}</h6>
      </div>
      <h6 class="ml-1">{{ '/' }}</h6>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: 'StorageObjectsTable',
  components: {},
  props: {
    path: {
      type: Array as () => Array<string>,
      required: true
    },
    rootStorageName: {
      type: String,
      required: true
    }
  },
  emits: ['go-to-path'],

  setup(props, { emit }) {
    const goToPath = (pathDepth: number) => {
      emit('go-to-path', props.path.slice(0, pathDepth));
    };

    return { goToPath };
  }
};
</script>
