import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-auto" }
const _hoisted_2 = { class: "body-1" }
const _hoisted_3 = { class: "body-1" }
const _hoisted_4 = { class: "body-1" }
const _hoisted_5 = ["src", "onClick"]
const _hoisted_6 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_row, { class: "d-flex" }, {
      default: _withCtx(() => [
        _createElementVNode("h4", _hoisted_1, _toDisplayString($setup.deploymentTasks.length) + " · " + _toDisplayString(_ctx.$t('tasks')), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_el_table, {
      data: $setup.deploymentTasks,
      onRowClick: $setup.openTask
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          prop: "name",
          width: 400,
          label: _ctx.$t('name')
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("span", _hoisted_2, _toDisplayString(scope.row.name), 1)
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_el_table_column, {
          prop: "message",
          label: _ctx.$t('message')
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("span", _hoisted_3, _toDisplayString(scope.row.message), 1)
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_el_table_column, {
          prop: "status",
          width: 180,
          label: _ctx.$t('status')
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("span", _hoisted_4, _toDisplayString($setup.getTaskStatus(scope.row.status)), 1)
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_el_table_column, { width: 100 }, {
          default: _withCtx((scope) => [
            (scope.row.type == 'DeployArmTemplateTask')
              ? (_openBlock(), _createBlock(_component_el_tooltip, {
                  key: 0,
                  placement: "top",
                  content: _ctx.$t('open')
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("img", {
                      src: require(`@/assets/images/icons/new/healthcheck.svg`),
                      height: "12",
                      width: "12",
                      class: "mr-1 cursor-pointer",
                      onClick: ($event: any) => ($setup.openTask(scope.row))
                    }, null, 8, _hoisted_5)
                  ]),
                  _: 2
                }, 1032, ["content"]))
              : _createCommentVNode("", true),
            _createVNode(_component_el_tooltip, {
              placement: "top",
              content: scope.row.configuration
            }, {
              default: _withCtx(() => [
                _createElementVNode("img", {
                  src: require(`@/assets/images/icons/new/settings.svg`),
                  height: "12",
                  width: "12"
                }, null, 8, _hoisted_6)
              ]),
              _: 2
            }, 1032, ["content"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["data", "onRowClick"])
  ], 64))
}