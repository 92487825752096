import { Module } from 'vuex';
import { NodeModelStoreModule, NodeModelStoreState } from '@/store/nodeModel';
import { LayerName } from '@etp/etp-pipelines-client/axios';
import { PipelinesStoreModule, PipelinesStoreState } from '@/store/pipelines';

const getDefaultState = () => {
  return {
    ...new NodeModelStoreModule('test').state,
    ...new PipelinesStoreModule(LayerName.TestLayer).state
  };
};

interface TestingState extends NodeModelStoreState, PipelinesStoreState {}

const nodeModelStore = new NodeModelStoreModule('test');
const pipelinesStore = new PipelinesStoreModule(LayerName.TestLayer);

class TestingModule implements Module<TestingState, any> {
  namespaced = true;
  state = {
    ...nodeModelStore.state,
    ...pipelinesStore.state
  };
  getters = {
    ...nodeModelStore.getters,
    ...pipelinesStore.getters
  };
  actions = {
    ...nodeModelStore.actions,
    ...pipelinesStore.actions
  };
  mutations = {
    ...nodeModelStore.mutations,
    ...pipelinesStore.mutations,
    RESET_STATE(state: TestingState) {
      Object.assign(state, getDefaultState());
    }
  };
}

export default new TestingModule();
