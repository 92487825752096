<template>
  <base-card no-header>
    <template #body>
      <el-row>
        <el-col :span="6">
          <h3>{{ $t('taskScheduler') }}</h3>
        </el-col>
        <el-col :span="12">
          <el-form ref="formRef" :model="form">
            <el-form-item
              class="mb-0"
              :label="$t('storageAccountName')"
              prop="storageAccountName"
            >
              <el-select
                v-model="form.storageAccountName"
                size="small"
                :placeholder="form.storageAccountName"
                @change="handleStorageAccountNameUpdate"
              >
                <el-option
                  v-for="item in localStorageResources"
                  :key="item.name"
                  :label="item.name"
                  :value="item.name"
                />
              </el-select>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="6" class="d-flex">
          <el-button
            class="ml-auto"
            type="primary"
            :disabled="disabled"
            @click="save"
          >
            <h4>{{ $t('save') }}</h4>
          </el-button>
        </el-col>
      </el-row>
    </template>
  </base-card>
</template>
<script lang="ts">
import BaseCard from '@/components/panels/BaseCard.vue';
import { onUpdated, reactive, ref } from 'vue';
import {
  ElButton,
  ElCol,
  ElForm,
  ElFormItem,
  ElOption,
  ElRow,
  ElSelect
} from 'element-plus';
import { ResourceType, ResourceModel } from '@etp/etp-infra-client';
import {
  CreateOrUpdateConfigurationDto,
  EngagementConfiguration
} from '@etp/etp-taskscheduler-client/axios';

export default {
  name: 'TaskSchedulerConfigurationCard',
  components: {
    BaseCard,
    ElButton,
    ElCol,
    ElForm,
    ElFormItem,
    ElOption,
    ElRow,
    ElSelect
  },
  props: {
    configuration: {
      type: Object as () => EngagementConfiguration,
      default: {} as EngagementConfiguration,
      required: true
    },
    disabled: { type: Boolean, default: false },
    resources: {
      type: Array as () => Array<ResourceModel>,
      default: () => new Array<ResourceModel>()
    }
  },
  emits: ['update'],
  setup(props, { emit }) {
    const formRef = ref<HTMLFormElement>();
    const form = reactive({
      storageAccountName: ''
    });

    let localStorageResources = ref(new Array<ResourceModel>());
    let localStorageConfiguration = ref({});

    const save = async () => {
      formRef.value?.validate((valid: boolean) => {
        if (valid) {
          emit('update', {
            configuration: createStorageConfiguration()
          } as CreateOrUpdateConfigurationDto);
        }
      });
    };

    const createStorageConfiguration = () => {
      return JSON.stringify({
        StorageAccountName: form.storageAccountName
      });
    };

    const handleStorageAccountNameUpdate = (storageAccountName: string) => {
      form.storageAccountName = storageAccountName;
    };

    const updateLocalVars = async () => {
      localStorageResources.value = props.resources.filter(
        el => el.resourceType == ResourceType.StorageAccount
      );

      localStorageConfiguration.value = JSON.parse(
        props.configuration.storageConfiguration || '{}'
      );

      form.storageAccountName =
        localStorageConfiguration.value['StorageAccountName'] ?? '';
    };

    onUpdated(() => {
      updateLocalVars();
    });

    return {
      form,
      formRef,
      localStorageResources,
      handleStorageAccountNameUpdate,
      save
    };
  }
};
</script>
<style scoped lang="scss"></style>
