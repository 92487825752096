import UsersOverview from '@/views/admin/users/UsersOverview.vue';
import UserDetails from '@/views/admin/users/UserDetails.vue';
import UserProfile from '@/views/admin/users/UserProfile.vue';
import UserRoles from '@/views/admin/users/UserRoles.vue';

export default [
  {
    path: 'users',
    name: 'UsersOverview',
    component: UsersOverview,
    meta: { menuItemName: 'UsersOverview' }
  },
  {
    path: 'users/:email',
    name: 'UserDetails',
    redirect: { name: 'UserProfile' },
    component: UserDetails,
    meta: { menuItemName: 'UsersOverview' },
    children: [
      {
        path: 'profile',
        name: 'UserProfile',
        component: UserProfile
      },
      {
        path: 'roles',
        name: 'UserRoles',
        component: UserRoles
      }
    ]
  }
];
