<template>
  <el-row class="d-flex">
    <h4 class="mt-auto">
      {{ resources.length }} &#183;
      {{ $t('azureComponents') }}
    </h4>
    <el-button
      type="primary"
      class="ml-auto"
      :disabled="tenant.deploymentStatus === DeploymentStatus.InProgress"
      @click="showNewResourceModal = true"
    >
      <h4>{{ $t('new') }}</h4>
    </el-button>
    <el-button
      type="primary"
      :disabled="tenant.deploymentStatus === DeploymentStatus.InProgress"
      @click="saveResources"
    >
      <h4>{{ $t('save') }}</h4>
    </el-button>
    <el-button
      type="primary"
      :disabled="tenant.deploymentStatus === DeploymentStatus.InProgress"
      @click="showStartDeploymentModal = true"
    >
      <h4>{{ $t('deploy') }}</h4>
    </el-button>
    <el-button
      v-if="resources.length <= 0"
      type="primary"
      :disabled="tenant.deploymentStatus === DeploymentStatus.InProgress"
      @click="showTemplateModal = true"
    >
      <h4>{{ $t('importTemplate') }}</h4>
    </el-button>
  </el-row>
  <table-placeholder-loading v-if="tenantResourcesLoading" :columns="2" />
  <el-row
    v-for="(resource, index) in resources"
    v-else
    :key="index"
    class="mt-1"
  >
    <tenant-compose-card
      :resources="resources"
      :deployment-in-progress="
        tenant.deploymentStatus === DeploymentStatus.InProgress
      "
      :resource="resource"
      @update="(configuration: string) => updateConfiguration(index, configuration)"
      @delete="deleteResource(index)"
    />
  </el-row>
  <new-resource-modal
    :dialog-visible="showNewResourceModal"
    @confirm="addResource"
    @close="showNewResourceModal = false"
  />
  <tenant-resource-template-modal
    v-model="showTemplateModal"
    @close="showTemplateModal = false"
    @confirm="createTemplateResources"
  />
  <base-confirmation-modal
    v-model="showStartDeploymentModal"
    :content="$t('confirmStartDeployment')"
    width="600px"
    :feedback-text="$t('deploymentInProgress')"
    @confirm="startDeployment"
    @close="showStartDeploymentModal = false"
  />
</template>
<script lang="ts">
import { ElButton, ElRow, ElMessage } from 'element-plus';
import TablePlaceholderLoading from '@/components/tables/TablePlaceholderLoading.vue';
import BaseConfirmationModal from '@/components/modals/BaseConfirmationModal.vue';
import TenantComposeCard from './components/TenantComposeCard.vue';
import NewResourceModal from './components/NewResourceModal.vue';
import TenantResourceTemplateModal from './components/TenantResourceTemplateModal.vue';
import { onMounted, ref, computed, ComputedRef } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import i18n from '@/i18n';
import {
  CreateResourceModel,
  ResourceModel,
  ResourceType,
  TenantModel,
  UpdateResourceModel,
  DeploymentStatus
} from '@etp/etp-infra-client';

export default {
  name: 'TenantCompose',
  components: {
    TenantResourceTemplateModal,
    BaseConfirmationModal,
    ElButton,
    ElRow,
    NewResourceModal,
    TablePlaceholderLoading,
    TenantComposeCard
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const showNewResourceModal = ref(false);
    const showStartDeploymentModal = ref(false);
    const showTemplateModal = ref(false);
    const tenantResourcesLoading = ref(false);

    let tenantId: string;

    let oldResources = new Array<ResourceModel>();

    const tenant: ComputedRef<TenantModel> = computed(
      () => store.getters['tenants/getActiveTenant']
    );

    const resources: ComputedRef<Array<ResourceModel>> = computed(
      () => store.getters['tenants/getResources']
    );

    const createTemplateResources = (resourceArray: Array<ResourceModel>) => {
      resourceArray.forEach(async resource => {
        await addResource(resource);
      });

      showTemplateModal.value = false;
    };

    const updateLocalVars = async () => {
      tenantId = router.currentRoute.value.params.tenantId as string;
      await store.dispatch('tenants/fetchTenant', { tenantId });
      await store.dispatch('tenants/fetchResources', { tenantId });
      oldResources = [...resources.value];
    };

    const addResource = async (resource: ResourceModel) => {
      let newResources = [...resources.value];
      newResources.push(resource);
      store.commit('tenants/SET_RESOURCES', newResources);
      showNewResourceModal.value = false;
      ElMessage({
        showClose: true,
        message: i18n.global.t('azureComponentAdded')
      });
    };

    const deleteResource = async (index: number) => {
      let newResources = [...resources.value];
      newResources.splice(index, 1);
      store.commit('tenants/SET_RESOURCES', newResources);
    };

    const saveResources = async () => {
      tenantResourcesLoading.value = true;
      resources.value
        .filter(el => !el.id)
        .forEach(
          async el =>
            await store.dispatch('tenants/createResource', {
              tenantId: tenantId,
              model: el as CreateResourceModel
            })
        );
      resources.value
        .filter(nr => oldResources.some(or => or.id == nr.id))
        .forEach(
          async el =>
            await store.dispatch('tenants/updateResource', {
              tenantId: tenantId,
              resourceId: el.id,
              model: el as UpdateResourceModel
            })
        );
      oldResources
        .filter(or => !resources.value.some(nr => nr.id == or.id))
        .forEach(
          async el =>
            await store.dispatch('tenants/deleteResource', {
              tenantId: tenantId,
              resourceId: el.id
            })
        );
      await updateLocalVars();
      tenantResourcesLoading.value = false;
      ElMessage({
        showClose: true,
        message: i18n.global.t('tenantResourceSettingsSaved')
      });
    };

    const startDeployment = async () => {
      tenantResourcesLoading.value = true;
      showStartDeploymentModal.value = false;
      await store.dispatch('tenants/startDeployment', {
        tenantId
      });
      tenantResourcesLoading.value = false;
    };

    const updateConfiguration = async (
      index: number,
      newConfiguration: string
    ) => {
      let newResources = [...resources.value];
      newResources[index].configuration = newConfiguration;
      store.commit('tenants/SET_RESOURCES', newResources);
    };

    onMounted(async () => {
      tenantResourcesLoading.value = true;
      await updateLocalVars();
      tenantResourcesLoading.value = false;
    });

    return {
      addResource,
      deleteResource,
      DeploymentStatus,
      resources,
      ResourceType,
      saveResources,
      showNewResourceModal,
      showStartDeploymentModal,
      showTemplateModal,
      startDeployment,
      tenant,
      tenantResourcesLoading,
      updateConfiguration,
      createTemplateResources
    };
  }
};
</script>
<style scoped lang="scss"></style>
