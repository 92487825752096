import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4a6d3fc4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "body-1 pl-1" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["src"]
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["src"]
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_plus = _resolveComponent("plus")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!
  const _component_base_modal = _resolveComponent("base-modal")!

  return (_openBlock(), _createBlock(_component_base_modal, {
    "modal-title": _ctx.$t('fields'),
    "confirm-text": _ctx.$t('save'),
    "dialog-visible": $props.dialogVisible,
    width: "640px",
    onConfirm: $setup.save,
    onClose: $setup.close
  }, {
    content: _withCtx(() => [
      _createVNode(_component_el_row, null, {
        default: _withCtx(() => [
          _createElementVNode("h4", null, _toDisplayString(_ctx.$t('addNewField')) + ":", 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_form, {
        ref: "newFieldFormRef",
        model: $setup.newFieldForm,
        rules: $setup.newFieldFormRules,
        inline: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_row, { class: "mb-4" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, {
                span: 10,
                class: "pr-2"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_form_item, {
                    prop: "name",
                    class: "width-100"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        modelValue: $setup.newFieldForm.name,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.newFieldForm.name) = $event)),
                        size: "small",
                        placeholder: _ctx.$t('name')
                      }, null, 8, ["modelValue", "placeholder"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_el_col, {
                span: 10,
                class: "d-flex pr-2"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_form_item, {
                    prop: "dataType",
                    class: "width-100 mr-0"
                  }, {
                    default: _withCtx(() => [
                      (!$setup.props.hideDataTypes)
                        ? (_openBlock(), _createBlock(_component_el_select, {
                            key: 0,
                            modelValue: $setup.newFieldForm.dataType,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.newFieldForm.dataType) = $event)),
                            size: "small",
                            placeholder: _ctx.$t('dataType')
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.allowedDataTypes, (dataType) => {
                                return (_openBlock(), _createBlock(_component_el_option, {
                                  key: dataType,
                                  label: dataType,
                                  value: dataType
                                }, null, 8, ["label", "value"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["modelValue", "placeholder"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_el_col, {
                span: 4,
                class: "d-flex align-items-center justify-content-center"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_button, {
                    class: "icon-button p-0 cursor-pointer",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => ($setup.addField()))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_plus, { class: "icon-size-8" })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model", "rules"]),
      ($setup.localNotAppliedFields.length)
        ? (_openBlock(), _createBlock(_component_el_row, {
            key: 0,
            class: "mb-1"
          }, {
            default: _withCtx(() => [
              _createElementVNode("h4", null, _toDisplayString(_ctx.$t('fieldsFromQry')) + ":", 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      ($setup.localNotAppliedFields.length)
        ? (_openBlock(), _createBlock(_component_el_row, {
            key: 1,
            class: "mb-4"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.localNotAppliedFields, (field, index) => {
                return (_openBlock(), _createBlock(_component_el_tag, {
                  key: index,
                  size: "small",
                  class: "cursor-pointer",
                  onClick: ($event: any) => ($setup.addField(field))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(field.name), 1)
                  ]),
                  _: 2
                }, 1032, ["onClick"]))
              }), 128))
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      ($setup.fieldsListForm.localFields.length)
        ? (_openBlock(), _createBlock(_component_el_row, {
            key: 2,
            class: "mb-1"
          }, {
            default: _withCtx(() => [
              _createElementVNode("h4", null, _toDisplayString(_ctx.$t('fields')) + ":", 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_el_scrollbar, { "max-height": "500px" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form, {
            ref: "fieldsListFormRef",
            model: $setup.fieldsListForm
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.fieldsListForm.localFields, (field, index) => {
                return (_openBlock(), _createBlock(_component_el_row, {
                  key: field.name ?? '',
                  class: "hover-grey"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      class: "width-100",
                      prop: 'localFields.' + index,
                      rules: {
                validator: $setup.localFieldValidator,
                trigger: 'change'
              }
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_col, { span: 10 }, {
                          default: _withCtx(() => [
                            _createElementVNode("span", _hoisted_1, _toDisplayString(field.name), 1)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_el_col, {
                          span: 10,
                          class: "pr-2"
                        }, {
                          default: _withCtx(() => [
                            (!$setup.props.hideDataTypes)
                              ? (_openBlock(), _createBlock(_component_el_select, {
                                  key: 0,
                                  modelValue: field.dataType,
                                  "onUpdate:modelValue": ($event: any) => ((field.dataType) = $event),
                                  size: "small"
                                }, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.allowedDataTypes, (dataType) => {
                                      return (_openBlock(), _createBlock(_component_el_option, {
                                        key: dataType,
                                        label: dataType,
                                        value: dataType
                                      }, null, 8, ["label", "value"]))
                                    }), 128))
                                  ]),
                                  _: 2
                                }, 1032, ["modelValue", "onUpdate:modelValue"]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_el_col, {
                          span: 4,
                          class: "d-flex"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", {
                              class: "clickable ml-auto",
                              onClick: ($event: any) => ($setup.promoteField(index))
                            }, [
                              _createElementVNode("img", {
                                src: require(`@/assets/images/icons/listupicon.svg`),
                                width: "20",
                                height: "20"
                              }, null, 8, _hoisted_3)
                            ], 8, _hoisted_2),
                            _createElementVNode("div", {
                              class: "clickable",
                              onClick: ($event: any) => ($setup.demoteField(index))
                            }, [
                              _createElementVNode("img", {
                                src: require(`@/assets/images/icons/listdownicon.svg`),
                                width: "20",
                                height: "20"
                              }, null, 8, _hoisted_5)
                            ], 8, _hoisted_4),
                            _createElementVNode("div", {
                              class: "clickable",
                              onClick: ($event: any) => ($setup.removeField(index))
                            }, [
                              _createElementVNode("img", {
                                src: require(`@/assets/images/icons/new/delete.svg`),
                                width: "12",
                                height: "12"
                              }, null, 8, _hoisted_7)
                            ], 8, _hoisted_6)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["prop", "rules"])
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          }, 8, ["model"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modal-title", "confirm-text", "dialog-visible", "onConfirm", "onClose"]))
}