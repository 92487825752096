<template>
  <el-form :model="form">
    <el-form-item :label="$t('kind')" prop="kind" class="mb-0">
      <el-select
        v-model="form.kind"
        filterable
        size="small"
        @change="handleChangeKind"
      >
        <el-option
          v-for="(index, item) in AiServicesKind"
          :key="index"
          :value="index"
          :label="item"
        />
      </el-select>
    </el-form-item>
    <el-form-item
      :label="$t('managedIdentityName')"
      prop="managedIdentityName"
      class="mb-0"
    >
      <el-select
        v-model="form.managedIdentityName"
        size="small"
        :placeholder="$t('empty')"
        @change="handleChangeManagedIdentityName"
      >
        <el-option
          v-for="item in managedIdentityNames"
          :key="item"
          :label="item"
          :value="item"
        />
      </el-select>
    </el-form-item>
  </el-form>
</template>
<script lang="ts">
import { onMounted, onUpdated, ref } from 'vue';
import { ElForm, ElFormItem, ElOption, ElSelect } from 'element-plus';
import { AiServicesConfiguration } from '../models/TenantAzureConfigurationObjects';
import { AiServicesKind } from '../models/AiServicesKind';

export default {
  name: 'AiServicesConfigurationForm',
  components: {
    ElForm,
    ElFormItem,
    ElOption,
    ElSelect
  },
  props: {
    configuration: { type: String, default: '' },
    managedIdentityNames: {
      type: Array as () => Array<string>,
      default: () => new Array<string>()
    }
  },
  emits: ['update-configuration'],
  setup(props, { emit }) {
    const form = ref(new AiServicesConfiguration());
    const localConfugrationObject = ref({});

    const emitUpdateEvent = () => {
      emit(
        'update-configuration',
        JSON.stringify({
          ...localConfugrationObject.value,
          ...form.value
        })
      );
    };

    const handleChangeKind = (newValue: number) => {
      form.value.kind = newValue;
      emitUpdateEvent();
    };

    const handleChangeManagedIdentityName = (newValue: string) => {
      form.value.managedIdentityName = newValue;
      emitUpdateEvent();
    };

    const updateLocalVars = () => {
      localConfugrationObject.value = JSON.parse(props.configuration || '{}');
      form.value.kind =
        localConfugrationObject.value['kind'] ?? AiServicesKind.Unknown;
      form.value.managedIdentityName =
        localConfugrationObject.value['managedIdentityName'] ?? '';
    };

    onMounted(() => {
      updateLocalVars();
      emitUpdateEvent();
    });
    onUpdated(() => updateLocalVars());

    return {
      AiServicesKind,
      form,
      handleChangeKind,
      handleChangeManagedIdentityName
    };
  }
};
</script>
<style scoped lang="scss"></style>
