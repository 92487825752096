<template>
  <base-modal
    :modal-title="$t('publishPipelines')"
    :dialog-visible="showModal"
    :has-confirmation-button="false"
    width="400px"
    data-test-id="publish-pipelines-modal"
    @close="$emit('close')"
  >
    <template #content>
      <h4 class="mt-4">{{ $t('repositories') }}</h4>
      <el-scrollbar max-height="500px" wrap-class="pl-2 pr-2 pb-2">
        <base-card
          v-for="(collaborationBranch, index) in collaborationBranches"
          :key="index"
          no-header
          data-test-id="collaboration-branch-list-item"
          class="mb-1 mt-1 smaller-card"
        >
          <template #body>
            <el-row>
              <el-col class="d-flex align-items-center" :span="18">
                <span data-test-id="collaboration-branch-name">
                  {{ collaborationBranch.parentRepositoryName }}
                </span>
              </el-col>
              <el-col :span="6">
                <el-button
                  class="ml-auto"
                  type="primary"
                  data-test-id="open-button"
                  @click="openLink(collaborationBranch.branchUrl)"
                >
                  <h4>{{ $t('open') }}</h4>
                </el-button>
              </el-col>
            </el-row>
          </template>
        </base-card>
      </el-scrollbar>
    </template>
  </base-modal>
</template>
<script lang="ts">
import { ElScrollbar, ElButton, ElCol, ElRow } from 'element-plus';
import BaseModal from '@/components/modals/BaseModal.vue';
import { CollaborationBranch } from '@etp/etp-pipelines-client/axios';
import BaseCard from '@/components/panels/BaseCard.vue';
export default {
  name: 'PublishPipelinesModal',
  components: {
    BaseModal,
    ElScrollbar,
    ElButton,
    ElCol,
    ElRow,
    BaseCard
  },
  props: {
    showModal: { type: Boolean, default: false },
    collaborationBranches: {
      type: Array as () => Array<CollaborationBranch>,
      default: () => new Array<CollaborationBranch>()
    }
  },
  emits: ['close'],
  setup() {
    const openLink = async (url: any) => {
      window.open(url);
    };

    return {
      openLink
    };
  }
};
</script>

<style lang="scss" scoped>
.smaller-card:deep(.el-card__body) {
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
