<template>
  <base-modal
    :modal-title="$t('tenant')"
    :dialog-visible="modelValue"
    :confirm-text="$t('save')"
    width="400px"
    @close="close()"
    @confirm="confirm()"
  >
    <template #content>
      <el-form
        ref="formRef"
        :model="form"
        :rules="formRules"
        label-position="top"
      >
        <el-form-item :label="$t('etpTemplateType')" prop="etpTemplateType">
          <el-select
            v-model="form.templateType"
            size="small"
            :placeholder="$t('empty')"
            @change="handleTemplateTypeChange"
          >
            <el-option
              v-for="template in templateTypes"
              :key="template"
              :label="template"
              :value="template"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item :label="$t('prefix')" prop="resourceGroupName">
          <el-input
            v-model="form.resourceGroupName"
            size="small"
            @input="handleResourceGroupNameChange"
          >
            <template #prepend>
              {{ resourceGroupPrefix }}
            </template>
          </el-input>
        </el-form-item>

        <el-row v-for="(resource, index) in proposedResources" :key="index">
          <el-col :span="23">
            <span class="body-1">
              {{ formatResourceType(resource.resourceType) }} -
              {{ resource.name }}
            </span>
          </el-col>
          <el-col :span="1">
            <img
              :src="require(`@/assets/images/icons/new/delete.svg`)"
              height="12"
              width="12"
              class="action-icon"
              @click="removeListElement(index)"
            />
          </el-col>
        </el-row>
      </el-form>
    </template>
  </base-modal>
</template>
<script lang="ts">
import BaseModal from '@/components/modals/BaseModal.vue';
import {
  ElForm,
  ElFormItem,
  ElInput,
  ElOption,
  ElSelect,
  ElRow,
  ElCol
} from 'element-plus';
import { reactive, ref } from 'vue';
import formatResourceName from '@/views/admin/tenants/helpers/formatResourceName';
import config from '@/config/config';
import i18n from '@/i18n';
import { ResourceModel, ResourceType } from '@etp/etp-infra-client';

export default {
  name: 'TenantResourceTemplateModal',
  components: {
    ElCol,
    ElRow,
    BaseModal,
    ElInput,
    ElForm,
    ElFormItem,
    ElOption,
    ElSelect
  },
  props: {
    modelValue: { type: Boolean, default: false }
  },
  emits: ['close', 'confirm', 'update:model-value'],
  setup(props, { emit }) {
    const form = reactive({
      templateType: '',
      resourceGroupName: ''
    });
    const formRef = ref<HTMLFormElement>();
    const formRules = reactive({
      resourceGroupName: [
        {
          required: true,
          message: i18n.global.t('thisFieldIsRequired'),
          trigger: 'blur'
        }
      ]
    });

    const resourceGroupPrefix = formatResourceName.getResourceGroupPrefix();

    const proposedResources = ref(Array<ResourceModel>());

    const templateTypes = [
      'WKR Analytics Engagement',
      'WKR Analtyics Base Tenant',
      'Payroll Analytics Engagement',
      'Payroll Analytics Base Tenant',
      'Payroll Automation'
    ];

    const confirm = () => {
      formRef.value?.validate(async (valid: boolean) => {
        if (valid) {
          emit('confirm', proposedResources.value);
          clearFields();
        }
      });
    };

    const close = () => {
      clearFields();
      emit('update:model-value', false);
    };

    const clearFields = () => {
      form.templateType = '';
      form.resourceGroupName = '';
      proposedResources.value = [];
    };

    const handleTemplateTypeChange = (templateType: string) => {
      var templateTypeFormatted = templateType.replace(/[\s-]+/g, '');
      var templateResources = config.ETPTemplate[templateTypeFormatted];

      proposedResources.value = templateResources.map(templateResource => {
        return {
          resourceType: templateResource.resourceType,
          name: generateResourceName(templateResource.resourceType)
        } as ResourceModel;
      });
    };

    const formatResourceType = (resourceType: number) => {
      const resourceKey = Object.keys(ResourceType)[
        resourceType
      ] as keyof typeof ResourceType;

      const specialCases: Record<string, string> = {
        PowerBIWorkspace: 'Power BI Workspace',
        PowerBIEmbedded: 'Power BI Embedded'
      };

      if (specialCases[resourceKey]) {
        return specialCases[resourceKey];
      }

      return resourceKey.replace(/([A-Z])/g, ' $1').trim();
    };

    const removeListElement = (index: number) => {
      if (index !== -1) {
        proposedResources.value.splice(index, 1);
      }
    };

    const generateResourceName = (resourceType: number) => {
      let prefix = resourceGroupPrefix.replace(/[-]+/g, '').toLowerCase();

      const resourceKey = Object.keys(ResourceType)[
        resourceType
      ] as keyof typeof ResourceType;

      var resourceAcronym = resourceKey.match(/[A-Z]/g)?.join('').toLowerCase();

      let resourceFullName =
        prefix + form.resourceGroupName.toLowerCase() + resourceAcronym;

      return resourceFullName;
    };

    const handleResourceGroupNameChange = (newValue: string | undefined) => {
      if (!newValue) return;
      proposedResources.value.forEach((r: ResourceModel) => {
        r.name = generateResourceName(r.resourceType);
      });
    };

    return {
      form,
      formRef,
      formRules,
      confirm,
      close,
      formatResourceType,
      handleTemplateTypeChange,
      handleResourceGroupNameChange,
      templateTypes,
      proposedResources,
      resourceGroupPrefix,
      removeListElement
    };
  }
};
</script>
<style scoped lang="scss"></style>
