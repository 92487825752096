import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Login from '@/views/login/LoginOverview.vue';
import Base from '@/views/Base.vue';
import Admin from '@/views/admin/AdminOverview.vue';
import Home from '@/views/home/HomeOverview.vue';
import Overview from '@/views/home/overview/route/overview';
import Import from '@/views/home/data-journey/import/route/import';
import Model from '@/views/home/data-journey/model/route/model';
import Dashboard from '@/views/home/reporting/dashboard/route/dashboard';
import Filtering from '@/views/home/data-journey/filtering/route/filtering';
import Labeling from '@/views/home/data-journey/labeling/route/labeling';
import AnalyticalModel from '@/views/home/data-journey/analytical/route/analytical';
import Tenants from '@/views/admin/tenants/route/tenants';
import Testing from '@/views/home/data-journey/testing/route/testing';
import Users from '@/views/admin/users/route/users';
import Engagements from '@/views/admin/engagements/route/engagements';
import Configuration from '@/views/home/administration/configuration/route/configuration';
import Costs from '@/views/home/administration/costs/route/costs';
import Privacy from '@/views/contracts/privacy/route/privacy';
import TermsOfUse from '@/views/contracts/terms-of-use/route/termsofuse';
import Contracts from '@/views/contracts/ContractsOverview.vue';
import Eula from '@/views/eula/Eula.vue';
import msal from '@/services/msal/msal';
import Applications from '@/views/admin/applications/route/applications';
import Profile from '@/views/profile/route/profile';
import Roles from '@/views/admin/roles/route/roles';
import Permissions from '@/views/admin/permissions/route/permissions';
import Connectors from '@/views/home/administration/connectors/route/connectors';
import Gain from '@/views/home/administration/gain/route/gain';
import EngagementAccess from '@/views/home/administration/engagementAccess/route/engagementAccess';
import Storage from '@/views/home/storage/route/storage';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/base',
    name: 'Base',
    component: Base,
    children: [
      {
        path: '/admin',
        name: 'Admin',
        component: Admin,
        children: [
          ...Tenants,
          ...Users,
          ...Engagements,
          ...Applications,
          Roles,
          Permissions
        ]
      },
      {
        path: '/home',
        name: 'Home',
        component: Home,
        children: [
          Filtering,
          Labeling,
          ...Import,
          Model,
          ...Dashboard,
          AnalyticalModel,
          Testing,
          TermsOfUse,
          Configuration,
          Costs,
          ...Overview,
          Profile,
          Connectors,
          Gain,
          EngagementAccess,
          ...Storage
        ]
      },
      {
        path: '/contracts',
        name: 'Contracts',
        component: Contracts,
        children: [TermsOfUse, Privacy]
      }
    ]
  },
  {
    path: '/eula',
    name: 'Eula',
    component: Eula
  },
  {
    path: '/front-end-sso',
    name: 'auth',
    redirect: '/overview'
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(async (to, from, next) => {
  await msal.handleRedirectPromise();

  if (to.name !== 'Login' && !msal.isAuthenticated()) {
    await msal.signIn('loginRedirect');
  }

  next();
});

export default router;
