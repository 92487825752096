<template>
  <page-header :main-title="$t('manual')">
    <template #subheader>
      <h4 class="subtitle-2">{{ $t('manualDesc') }}</h4>
    </template>
  </page-header>
</template>

<script lang="ts">
import PageHeader from '@/components/misc/PageHeader.vue';

export default {
  name: 'ManualPage',
  components: { PageHeader }
};
</script>
