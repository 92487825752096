<template>
  <el-row :gutter="24">
    <el-col :span="17">
      <base-table-header
        :row-count="tasksCount"
        :quantity-message="$t('orchestrations')"
        :input-placeholder="$t('searchOrchestrations')"
        @query-change="changeFilter"
      >
        <div class="pr-2 ml-2">
          <el-select
            v-model="statusFilter"
            size="small"
            clearable
            :placeholder="loadingTasks ? $t('loading') : $t('filterStatus')"
          >
            <el-option
              v-for="(item, key) in TaskState"
              :key="key"
              :label="$t(key)"
              :value="item"
            />
          </el-select>
        </div>
        <div class="pl-1 ml-2">
          <el-button
            v-if="userCanWriteEngagementTaskSchedulerTasks"
            type="primary"
            @click="openNewTasksModal"
          >
            <h4>{{ '+ ' + $t('newOrchestration') }}</h4>
          </el-button>
        </div>
      </base-table-header>
      <el-row class="d-block">
        <base-table
          :show-loading-table="loadingTasks"
          :show-no-content-table="!tasks.length"
        >
          <import-tasks-table
            :table-data="tasks"
            @approve-task="approveTask"
            @disapprove-task="disapproveTask"
            @edit-task="openNewTasksModal"
          />
        </base-table>
        <pagination
          :count="tasksCount"
          :page="tasksPaginationParams.page"
          :page-size="tasksPaginationParams.pageSize"
          @change-page="changePage"
        />
        <import-task-create
          :dialog-visible="showNewTaskModal"
          :task-value="taskModalData"
          @confirm="createOrUpdateTask"
          @close="showNewTaskModal = false"
        />
      </el-row>
    </el-col>
  </el-row>
</template>

<script lang="ts">
import {
  ElRow,
  ElCol,
  ElButton,
  ElMessage,
  ElOption,
  ElSelect
} from 'element-plus';

import Pagination from '@/components/misc/Pagination.vue';
import ImportTasksTable from './components/ImportTasksTable.vue';
import ImportTaskCreate from './components/ImportTaskCreate.vue';
import BaseTable from '@/components/tables/BaseTable.vue';
import BaseTableHeader from '@/components/tables/BaseTableHeader.vue';

import { useStore } from 'vuex';
import { computed, ComputedRef, onMounted, ref } from 'vue';
import { TaskState } from './store/entities/task';
import i18n from '@/i18n';
import PaginationParams from '@/services/web-api/dto/paginationParams';
import { useRouter } from 'vue-router';
import {
  CreateOrUpdateTaskDto,
  ScheduledTaskDto
} from '@etp/etp-taskscheduler-client/axios';

export default {
  name: 'ImportScheduler',
  components: {
    BaseTableHeader,
    ElRow,
    ElCol,
    ElButton,
    ElOption,
    ElSelect,
    ImportTasksTable,
    ImportTaskCreate,
    BaseTable,
    Pagination
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const taskModalData = ref<ScheduledTaskDto>();
    const showNewTaskModal = ref<boolean>(false);

    const tasks: ComputedRef<ScheduledTaskDto[]> = computed(
      () => store.getters['import/getTasks']
    );
    const tasksCount: ComputedRef<number> = computed(
      () => store.getters['import/getTasksCount']
    );
    const loadingTasks: ComputedRef<boolean> = computed(
      () => store.getters['import/loadingTasks']
    );
    const tasksPaginationParams: ComputedRef<PaginationParams> = computed(
      () => store.getters['import/getTasksPaginationParams']
    );

    const changePage = async (page: number) => {
      let newPagination = {
        ...tasksPaginationParams.value,
        page
      } as PaginationParams;
      store.commit('import/SET_TASKS_PAGINATION_PARAMS', newPagination);
      store.dispatch('import/fetchTasks');
    };

    let currentFilter = ref('');
    const changeFilter = async (inputQuery: string) => {
      if (inputQuery.length >= 3) {
        currentFilter.value = inputQuery;
      } else if (currentFilter.value.length >= 3) {
        currentFilter.value = '';
      } else {
        return;
      }
      store.commit('import/SET_TASKS_TYPE_FILTER', currentFilter.value);
      store.dispatch('import/fetchTasks');
    };

    const statusFilter = computed({
      get: () => store.getters['import/getTasksStatusFilter'],
      set: async (status: string) => {
        store.commit('import/SET_TASKS_STATUS_FILTER', status);
        store.dispatch('import/fetchTasks');
      }
    });

    const openNewTasksModal = (task?: ScheduledTaskDto): void => {
      taskModalData.value = task?.id ? task : ({} as ScheduledTaskDto);
      showNewTaskModal.value = true;
    };

    const createOrUpdateTask = async (
      task: ScheduledTaskDto
    ): Promise<void> => {
      showNewTaskModal.value = false;
      let message = i18n.global.t('addingTask');
      let taskId = task.id;
      let payload = {
        type: task.type,
        params: task.params,
        plannedExecutionDate: new Date(
          task.plannedExecutionDate ?? ''
        ).toISOString()
      } as CreateOrUpdateTaskDto;
      if (taskId) {
        await store.dispatch('import/updateTask', { taskId, payload });
        message = i18n.global.t('editTask');
      } else {
        await store.dispatch('import/createTask', payload);
      }
      await store.dispatch('import/fetchTasks', false);
      ElMessage({
        showClose: true,
        message: message
      });
    };
    const approveTask = async (taskId: string) => {
      await store.dispatch('import/approveTask', taskId);
      await store.dispatch('import/fetchTasks', false);
    };
    const disapproveTask = async (taskId: string) => {
      await store.dispatch('import/disapproveTask', taskId);
      await store.dispatch('import/fetchTasks', false);
    };

    const showTaskSchedulerPages: ComputedRef<boolean> = computed(
      () => store.getters['home/showTaskSchedulerPages']
    );
    const userCanWriteEngagementTaskSchedulerTasks: ComputedRef<boolean> =
      computed(
        () =>
          store.getters['userPermissions/canWriteEngagementTaskSchedulerTasks']
      );
    onMounted(async () => {
      if (!showTaskSchedulerPages.value) router.push({ name: 'ImportMonitor' });
      store.dispatch('import/fetchTasks');
      await store.dispatch(
        'userPermissions/checkIfCanWriteEngagementTaskSchedulerTasks'
      );
    });

    return {
      tasks,
      tasksCount,
      tasksPaginationParams,
      loadingTasks,
      showNewTaskModal,
      createOrUpdateTask,
      userCanWriteEngagementTaskSchedulerTasks,
      openNewTasksModal,
      approveTask,
      disapproveTask,
      taskModalData,
      changePage,
      changeFilter,
      statusFilter,
      TaskState
    };
  }
};
</script>

<style></style>
