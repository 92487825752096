import ImportOverview from '../ImportOverview.vue';
import Scheduler from '../ImportScheduler.vue';
import GainImportFiles from '../GainImportFiles.vue';
import GainImportTables from '../GainImportTables.vue';
import MonitorOverview from '@/views/home/shared/MonitorOverview.vue';
import StorageManager from '@/views/home/storage/components/StorageManager.vue';

export default [
  {
    path: '/import',
    name: 'Import',
    component: ImportOverview,
    meta: { menuItemName: 'Import' },
    children: [
      {
        path: '/:storagePurpose/folders/:path(.*)*',
        name: 'ImportFolders',
        component: StorageManager
      },
      {
        path: 'gain-files',
        name: 'GainImportFiles',
        component: GainImportFiles
      },
      {
        path: 'gain-tables',
        name: 'GainImportTables',
        component: GainImportTables
      },
      {
        path: 'scheduler',
        name: 'ImportScheduler',
        component: Scheduler
      },
      {
        path: 'monitor',
        name: 'ImportMonitor',
        component: MonitorOverview
      }
    ]
  }
];
