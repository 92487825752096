import { ActionContext, Module } from 'vuex';
import {
  CreatePermissionModel,
  PermissionModel,
  PermissionModelPage,
  UpdatePermissionModel
} from '@etp/etp-authorization-client/axios';
import { createAuthorisationClient } from '@/services/api';

interface PermissionState {
  loadingPermissions: boolean;
  permissions: Array<PermissionModel>;
  pagePermissions: Array<PermissionModel>;
  permissionsPageNumber: number;
  permissionsPageSize: number;
  permissionsCount: number;
}

class PermissionModule implements Module<PermissionState, any> {
  namespaced = true;
  state = {
    loadingPermissions: false,
    permissions: new Array<PermissionModel>(),
    pagePermissions: new Array<PermissionModel>(),
    permissionsPageNumber: 1,
    permissionsPageSize: 10,
    permissionsCount: 10
  };
  getters = {
    getLoadingPermissions: (state: PermissionState): boolean =>
      state.loadingPermissions,
    getPermissions: (state: PermissionState): Array<PermissionModel> =>
      state.permissions,
    getPagePermissions: (state: PermissionState): Array<PermissionModel> =>
      state.pagePermissions,
    getPermissionsPageNumber: (state: PermissionState): number => {
      return state.permissionsPageNumber;
    },
    getPermissionsPageSize: (state: PermissionState): number => {
      return state.permissionsPageSize;
    },
    getPermissionsCount: (state: PermissionState): number => {
      return state.permissionsCount;
    }
  };
  actions = {
    async createPermission(
      context: ActionContext<PermissionState, any>,
      payload: PermissionModel
    ) {
      let client = createAuthorisationClient();
      await client.createPermission({ ...payload } as CreatePermissionModel);
    },
    async deletePermission(
      context: ActionContext<PermissionState, any>,
      payload: {
        permissionName: string;
      }
    ) {
      let client = createAuthorisationClient();
      await client.deletePermission(payload.permissionName);
    },
    async fetchPagePermissions(context: ActionContext<PermissionState, any>) {
      context.state.loadingPermissions = true;
      let client = createAuthorisationClient();
      const { data, status } = await client.listPermissions(
        context.state.permissionsPageNumber,
        context.state.permissionsPageSize
      );
      if (status == 200) {
        context.commit('SET_PAGINATED_PERMISSIONS', data);
      } else {
        context.commit('SET_PAGINATED_PERMISSIONS', {} as PermissionModelPage);
      }
      context.state.loadingPermissions = false;
    },
    async fetchAllPermissions(context: ActionContext<PermissionState, any>) {
      context.state.loadingPermissions = true;
      let client = createAuthorisationClient();
      const { data, status } = await client.listPermissions();
      if (status == 200) {
        context.commit('SET_PERMISSIONS', data);
      } else {
        context.commit('SET_PERMISSIONS', {} as PermissionModelPage);
      }
      context.state.loadingPermissions = false;
    },
    async updatePermission(
      context: ActionContext<PermissionState, any>,
      payload: PermissionModel
    ) {
      if (!payload.name) return;
      let client = createAuthorisationClient();
      await client.updatePermission(payload.name, {
        ...payload
      } as UpdatePermissionModel);
    }
  };
  mutations = {
    SET_PERMISSIONS(state: PermissionState, payload: PermissionModelPage) {
      state.permissions = payload.results ?? new Array<PermissionModel>();
      state.permissionsCount = payload.meta?.totalItems ?? 0;
    },
    SET_PAGINATED_PERMISSIONS(
      state: PermissionState,
      payload: PermissionModelPage
    ) {
      state.pagePermissions = payload.results ?? new Array<PermissionModel>();
      state.permissionsCount = payload.meta?.totalItems ?? 0;
    },
    SET_PERMISSIONS_PAGE(state: PermissionState, payload: number) {
      state.permissionsPageNumber = payload;
    }
  };
}

export default new PermissionModule();
