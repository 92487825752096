import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_run_tab = _resolveComponent("base-run-tab")!

  return (_openBlock(), _createBlock(_component_base_run_tab, {
    "loading-pipelines": $setup.loadingPipelines,
    pipelines: $setup.pipelines,
    "can-hide-pipelines": $setup.userCanWriteEngagementPipelines,
    "start-pipeline-run": $setup.startPipelineRun,
    "toggle-pipeline": $setup.togglePipeline
  }, null, 8, ["loading-pipelines", "pipelines", "can-hide-pipelines", "start-pipeline-run", "toggle-pipeline"]))
}