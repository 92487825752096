import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_base_modal = _resolveComponent("base-modal")!

  return (_openBlock(), _createBlock(_component_base_modal, {
    "modal-title": _ctx.$t('addRole'),
    "dialog-visible": $props.modelValue,
    "confirm-text": _ctx.$t('save'),
    width: "600px",
    onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('update:model-value', false))),
    onConfirm: _cache[5] || (_cache[5] = ($event: any) => ($setup.confirm()))
  }, {
    content: _withCtx(() => [
      _createVNode(_component_el_form, {
        ref: "formRef",
        model: $setup.form,
        rules: $setup.formRules,
        "label-position": "top"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, {
            label: _ctx.$t('role'),
            prop: "roleNames"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_select, {
                modelValue: $setup.form.roleNames,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.form.roleNames) = $event)),
                multiple: "",
                size: "small",
                onChange: $setup.handleRolesChange
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.roles, (role) => {
                    return (_openBlock(), _createBlock(_component_el_option, {
                      key: role.name,
                      label: role.name,
                      value: role.name,
                      disabled: 
                !!$setup.selectedRolesScopeMask &&
                role.scopeMask != $setup.selectedRolesScopeMask
              
                    }, null, 8, ["label", "value", "disabled"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modelValue", "onChange"])
            ]),
            _: 1
          }, 8, ["label"]),
          ($setup.showEngagementSelector)
            ? (_openBlock(), _createBlock(_component_el_form_item, {
                key: 0,
                label: _ctx.$t('engagement'),
                prop: "engagementId"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_select, {
                    modelValue: $setup.form.engagementId,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.form.engagementId) = $event)),
                    size: "small",
                    onChange: $setup.handleEngagementChange
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.engagements, (engagement) => {
                        return (_openBlock(), _createBlock(_component_el_option, {
                          key: engagement.id,
                          label: engagement.name,
                          value: engagement.id
                        }, null, 8, ["label", "value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue", "onChange"])
                ]),
                _: 1
              }, 8, ["label"]))
            : _createCommentVNode("", true),
          ($setup.showTenantSelector)
            ? (_openBlock(), _createBlock(_component_el_form_item, {
                key: 1,
                label: _ctx.$t('tenant'),
                prop: "tenantId"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_select, {
                    modelValue: $setup.form.tenantId,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.form.tenantId) = $event)),
                    size: "small",
                    onChange: $setup.handleTenantChange
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.tenants, (tenant) => {
                        return (_openBlock(), _createBlock(_component_el_option, {
                          key: tenant.id,
                          label: tenant.name,
                          value: tenant.id
                        }, null, 8, ["label", "value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue", "onChange"])
                ]),
                _: 1
              }, 8, ["label"]))
            : _createCommentVNode("", true),
          _createVNode(_component_el_form_item, {
            label: _ctx.$t('scope'),
            prop: "scope"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: $setup.form.scope,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.form.scope) = $event)),
                size: "small",
                disabled: $setup.disableScopeField
              }, null, 8, ["modelValue", "disabled"])
            ]),
            _: 1
          }, 8, ["label"])
        ]),
        _: 1
      }, 8, ["model", "rules"])
    ]),
    _: 1
  }, 8, ["modal-title", "dialog-visible", "confirm-text"]))
}