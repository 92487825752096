<template>
  <page-header :main-title="$t('filtering')">
    <template #tabs>
      <div class="d-flex mt-5">
        <router-link
          v-if="configurationStorageExists"
          v-slot="{ isExactActive }"
          :to="{
            name: 'StorageManager',
            params: { storagePurpose: 'configuration', path: [] }
          }"
        >
          <base-tab :active="isExactActive">
            <h4>{{ $t('storage') }}</h4>
          </base-tab>
        </router-link>
        <router-link
          v-if="userCanReadEngagementNodeModelGit && showNodeModelGitPages"
          v-slot="{ isActive }"
          :to="{ name: 'FilteringConfig' }"
        >
          <base-tab :active="isActive">
            <h4>{{ $t('configure') }}</h4>
          </base-tab>
        </router-link>
        <router-link
          v-if="userCanReadEngagementNodeModelGit && showNodeModelGitPages"
          v-slot="{ isActive }"
          :to="{ name: 'FilteringBuild' }"
        >
          <base-tab :active="isActive">
            <h4>{{ $t('build') }}</h4>
          </base-tab>
        </router-link>
        <router-link
          v-if="showPipelinePages"
          v-slot="{ isActive }"
          :to="{ name: 'FilteringRun' }"
        >
          <base-tab :active="isActive">
            <h4>{{ $t('run') }}</h4>
          </base-tab>
        </router-link>
        <router-link
          v-if="showPipelinePages"
          v-slot="{ isActive }"
          :to="{ name: 'FilteringMonitor' }"
        >
          <base-tab :active="isActive">
            <h4>{{ $t('monitor') }}</h4>
          </base-tab>
        </router-link>
        <div class="horizontal-line mt-2">
          <hr />
        </div>
      </div>
    </template>
    <template #subheader>
      <div class="mt-3">
        <h4 v-if="routerName == 'FilteringConfig'" class="subtitle-2">
          {{ $t('filteringConfigDesc') }}
        </h4>
        <h4 v-else-if="routerName == 'FilteringBuild'" class="subtitle-2">
          {{ $t('filteringBuildDesc') }}
        </h4>
        <h4 v-else-if="routerName == 'FilteringRun'" class="subtitle-2">
          {{ $t('runTabDesc') }}
        </h4>
        <h4 v-else-if="routerName == 'FilteringMonitor'" class="subtitle-2">
          {{ $t('monitorTabDesc') }}
        </h4>
      </div>
    </template>
  </page-header>
  <div class="mt-6">
    <router-view />
  </div>
</template>
<script lang="ts">
import PageHeader from '@/components/misc/PageHeader.vue';
import BaseTab from '@/components/tabs/BaseTab.vue';
import { computed, ComputedRef, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default {
  name: 'FilteringOverview',
  components: {
    PageHeader,
    BaseTab
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    let routerName = ref(router.currentRoute.value.name);

    const showNodeModelApiPages: ComputedRef<boolean> = computed(
      () => store.getters['home/showNodeModelApiPages']
    );
    const showNodeModelGitPages: ComputedRef<boolean> = computed(
      () => store.getters['home/showNodeModelGitPages']
    );
    const showPipelinePages: ComputedRef<boolean> = computed(
      () => store.getters['home/showPipelinePages']
    );
    const userCanReadEngagementNodeModelGit: ComputedRef<boolean> = computed(
      () => store.getters['userPermissions/canReadEngagementNodeModelGit']
    );
    const configurationStorageExists: ComputedRef<boolean> = computed(
      () => store.getters['storage/configurationStorageExists']
    );

    onMounted(async () => {
      await store.dispatch(
        'userPermissions/checkIfCanReadEngagementNodeModelGit'
      );
    });

    return {
      configurationStorageExists,
      routerName,
      showNodeModelApiPages,
      showNodeModelGitPages,
      showPipelinePages,
      userCanReadEngagementNodeModelGit
    };
  }
};
</script>
