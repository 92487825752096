﻿<template>
  <base-card no-header>
    <template #body>
      <el-row>
        <el-col :span="6">
          <h3>{{ $t('reporting') }}</h3>
        </el-col>
        <el-col :span="12">
          <el-form ref="formRef" :model="form" :rules="formRules">
            <el-form-item
              class="mb-0"
              :label="$t('storageType')"
              prop="storageType"
            >
              <el-select
                v-model="form.storageType"
                size="small"
                :placeholder="$t('empty')"
                class="mb-0"
                @change="handleStorageTypeUpdate"
              >
                <el-option
                  v-for="(index, item) in ReportStorageType"
                  :key="index"
                  :label="item"
                  :value="index"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              class="mb-0"
              :label="$t('storageAccountName')"
              prop="storageAccountName"
            >
              <el-select
                v-model="form.storageAccountName"
                size="small"
                :placeholder="form.storageAccountName"
              >
                <el-option
                  v-for="item in localStorageResources"
                  :key="item.name"
                  :label="item.name"
                  :value="item.name"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              class="mb-0"
              :label="$t('outputContainer')"
              prop="outputContainer"
            >
              <el-input v-model="form.outputContainer" size="small" />
            </el-form-item>
            <el-form-item
              class="mb-0"
              :label="$t('sqlServerName')"
              prop="sqlServerName"
            >
              <el-select
                v-model="form.sqlServerName"
                clearable
                size="small"
                :placeholder="$t('empty')"
              >
                <el-option
                  v-for="item in localSqlServers"
                  :key="item.name ?? ''"
                  :label="item.name ?? ''"
                  :value="item.name"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              class="mb-0"
              :label="$t('sqlDatabase')"
              prop="sqlDatabaseName"
            >
              <el-select
                v-model="form.sqlDatabaseName"
                clearable
                size="small"
                :placeholder="$t('empty')"
              >
                <el-option
                  v-for="item in localDatabases"
                  :key="item.name ?? ''"
                  :label="item.name ?? ''"
                  :value="item.name"
                />
              </el-select>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="6" class="d-flex">
          <el-button
            class="ml-auto"
            type="primary"
            :disabled="disabled"
            @click="save"
          >
            <h4>{{ $t('save') }}</h4>
          </el-button>
        </el-col>
      </el-row>
    </template>
  </base-card>
</template>
<script lang="ts">
import BaseCard from '@/components/panels/BaseCard.vue';
import { onUpdated, reactive, ref } from 'vue';
import {
  ElButton,
  ElCol,
  ElForm,
  ElFormItem,
  ElInput,
  ElOption,
  ElRow,
  ElSelect
} from 'element-plus';
import { ResourceType, ResourceModel } from '@etp/etp-infra-client';
import {
  ReportStorageType,
  ReportConfigurationDto
} from '@etp/etp-reporting-client/axios';
import {
  mustBeAlphaNumericDashAndLowerCase,
  mustNotBeEmpty
} from '@/utils/formValidators';
export default {
  name: 'ReportingConfigurationCard',
  components: {
    ElInput,
    BaseCard,
    ElButton,
    ElCol,
    ElForm,
    ElFormItem,
    ElOption,
    ElRow,
    ElSelect
  },
  props: {
    configuration: {
      type: Object as () => ReportConfigurationDto,
      default: {} as ReportConfigurationDto,
      required: true
    },
    disabled: { type: Boolean, default: false },
    resources: {
      type: Array as () => Array<ResourceModel>,
      default: () => new Array<ResourceModel>()
    }
  },
  emits: ['update'],
  setup(props, { emit }) {
    const formRef = ref<HTMLFormElement>();

    const formRules = reactive({
      outputContainer: [
        {
          validator: mustNotBeEmpty,
          trigger: 'blur'
        },
        {
          validator: mustBeAlphaNumericDashAndLowerCase,
          trigger: 'blur'
        }
      ],
      sqlServerName: [
        {
          validator: mustNotBeEmpty,
          trigger: 'blur'
        }
      ],
      sqlDatabaseName: [
        {
          validator: mustNotBeEmpty,
          trigger: 'blur'
        }
      ]
    });

    const form = reactive({
      storageAccountName: '',
      outputContainer: '',
      configurationName: '',
      sqlServerName: '',
      sqlDatabaseName: '',
      storageType: -1
    });

    let localStorageConfiguration = ref({});
    let localStorageResources = ref(new Array<ResourceModel>());
    let localSqlServers = ref(new Array<ResourceModel>());
    let localDatabases = ref(new Array<ResourceModel>());
    const save = async () => {
      formRef.value?.validate((valid: boolean) => {
        if (valid) {
          let payload = {
            storageType: form.storageType,
            storageAccountName: form.storageAccountName,
            outputContainer: form.outputContainer,
            configurationName: form.configurationName,
            sqlServerName: form.sqlServerName,
            sqlDatabaseName: form.sqlDatabaseName
          } as ReportConfigurationDto;
          emit('update', payload);
        }
      });
    };

    const updateLocalVars = async () => {
      handleStorageTypeUpdate(form.storageType);

      localSqlServers.value = props.resources.filter(
        el => el.resourceType == ResourceType.SqlServer
      );
      localDatabases.value = props.resources.filter(
        el => el.resourceType == ResourceType.SqlDatabase
      );

      form.storageAccountName = props.configuration.storageAccountName ?? '';
      form.outputContainer = props.configuration.outputContainer ?? '';
      form.configurationName = props.configuration.configurationName ?? '';
      form.sqlServerName = props.configuration.sqlServerName ?? '';
      form.sqlDatabaseName = props.configuration.sqlDatabaseName ?? '';
      form.storageType = props.configuration.storageType ?? 0;
    };

    const handleStorageTypeUpdate = (type: number) => {
      form.storageType = type;
      switch (type) {
        case ReportStorageType.Azure_StorageAccount:
          localStorageResources.value = props.resources.filter(
            el => el.resourceType == ResourceType.StorageAccount
          );
          form.storageAccountName =
            localStorageConfiguration.value['StorageAccountName'] ?? '';
          break;
        case ReportStorageType.Azure_DataLakeStorage:
          localStorageResources.value = props.resources.filter(
            el => el.resourceType == ResourceType.DataLakeStorage
          );
          form.storageAccountName =
            localStorageConfiguration.value['StorageAccountName'] ?? '';
          break;
        default:
          form.storageAccountName = '';
          break;
      }
    };

    onUpdated(() => {
      updateLocalVars();
    });

    return {
      form,
      formRef,
      formRules,
      handleStorageTypeUpdate,
      localStorageResources,
      localSqlServers,
      localDatabases,
      ReportStorageType,
      save
    };
  }
};
</script>
<style scoped lang="scss"></style>
