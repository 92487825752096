<template>
  <div class="panel-header d-flex">
    <div class="title-container d-flex">
      <div class="icon d-flex mr-2 align-items-center avatar">
        <slot name="avatar" />
      </div>
      <div>
        <h3 class="main-title">{{ mainTitle }}</h3>
        <slot name="subheader" />
      </div>
    </div>
    <div class="actions">
      <slot name="actions" />
    </div>
  </div>
</template>
<script lang="ts">
export default {
  name: 'PanelHeader',
  props: {
    mainTitle: { type: String, default: '', required: true }
  }
};
</script>
<style lang="scss" scoped>
.panel-header {
  margin-bottom: 2em;
}
.panel-header .title-container {
  align-items: center;
}
.main-title {
  color: $etp-deloitte-blue-7;
}
</style>
