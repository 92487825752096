import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "d-flex justify-content-center" }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_base_table = _resolveComponent("base-table")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_revert_promotion_modal = _resolveComponent("revert-promotion-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_row, { gutter: $setup.spacingWidthHeaderComponents }, {
      default: _withCtx(() => [
        _createVNode(_component_base_table, {
          "show-loading-table": $setup.showLoadingTable,
          "show-no-content-table": !$setup.promotions.length,
          "error-message": _ctx.$t('noHistoryToDisplay')
        }, {
          default: _withCtx(() => [
            ($setup.promotions.length)
              ? (_openBlock(), _createBlock(_component_el_table, {
                  key: 0,
                  data: $setup.promotions
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_table_column, {
                      label: _ctx.$t('version'),
                      width: "100"
                    }, {
                      default: _withCtx((scope) => [
                        _createElementVNode("h4", null, _toDisplayString(scope.row.sourceVersion), 1)
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_el_table_column, {
                      label: _ctx.$t('status'),
                      width: "120"
                    }, {
                      default: _withCtx((scope) => [
                        _createElementVNode("h4", null, _toDisplayString(_ctx.$t(Object.keys($setup.PromotionStatus)[scope.row.status])), 1)
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_el_table_column, {
                      label: _ctx.$t('time'),
                      width: "120"
                    }, {
                      default: _withCtx((scope) => [
                        _createElementVNode("h4", null, _toDisplayString($setup.formatTimestamp(scope.row.promotedAt)), 1)
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_el_table_column, {
                      label: _ctx.$t('updatedBy'),
                      width: "200"
                    }, {
                      default: _withCtx((scope) => [
                        _createElementVNode("h4", null, _toDisplayString(scope.row.createdBy), 1)
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_el_table_column, {
                      label: _ctx.$t('comment')
                    }, {
                      default: _withCtx((scope) => [
                        _createElementVNode("h4", null, _toDisplayString(scope.row.comment), 1)
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    (
            !$setup.promotionInProgress &&
            $setup.versionToRevert > -Infinity &&
            $setup.versionToRestore > -Infinity
          )
                      ? (_openBlock(), _createBlock(_component_el_table_column, {
                          key: 0,
                          width: "120"
                        }, {
                          default: _withCtx((scope) => [
                            _createElementVNode("div", _hoisted_1, [
                              (scope.row.sourceVersion == $setup.versionToRevert)
                                ? (_openBlock(), _createBlock(_component_el_button, {
                                    key: 0,
                                    type: "primary",
                                    onClick: ($event: any) => ($setup.openRevertModal(scope.row))
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$t('revert')), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["onClick"]))
                                : (scope.row.sourceVersion == $setup.versionToRestore)
                                  ? (_openBlock(), _createElementBlock("h4", _hoisted_2, _toDisplayString(_ctx.$t('target')), 1))
                                  : _createCommentVNode("", true)
                            ])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["data"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["show-loading-table", "show-no-content-table", "error-message"])
      ]),
      _: 1
    }, 8, ["gutter"]),
    _createVNode(_component_revert_promotion_modal, {
      modelValue: $setup.showRevertModal,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.showRevertModal) = $event)),
      "feedback-text": _ctx.$t('revertingPromotion'),
      "version-to-revert": $setup.versionToRevert,
      "version-to-restore": $setup.versionToRestore,
      onConfirm: $setup.revertPromotion
    }, null, 8, ["modelValue", "feedback-text", "version-to-revert", "version-to-restore", "onConfirm"])
  ], 64))
}