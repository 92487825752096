<template>
  <base-modal
    :modal-title="$t('configurationHistory')"
    :dialog-visible="modelValue"
    :has-confirmation-button="false"
    width="100%"
    @close="close"
  >
    <template #content>
      <el-table :data="props.configurationVersions">
        <el-table-column :label="$t('version')" width="150">
          <template #default="scope">
            <h4>{{ scope.row.version }}</h4>
            <h4>{{ formatDate(scope.row.timestamp) }}</h4>
            <h4>{{ formatTime(scope.row.timestamp) }}</h4>
          </template>
        </el-table-column>
        <el-table-column :label="$t('item')">
          <template #default="{ row, $index }">
            <el-row
              v-for="(item, key) in row.fileConfigurations"
              :key="key"
              :class="
                isFileDifferentToPreviousVersion($index, item.namePattern)
                  ? 'color-red mb-1'
                  : 'mb-1'
              "
            >
              <el-col :span="6">
                <h4>{{ $t('file') }}</h4>
                <h4>{{ item.namePattern }}</h4>
              </el-col>
              <el-col :span="18">
                <h4>{{ item }}</h4>
              </el-col>
            </el-row>
            <el-row
              v-for="(item, key) in row.tableConfigurations"
              :key="key"
              :class="
                isTableDifferentToPreviousVersion($index, item.targetTableName)
                  ? 'color-red mb-1'
                  : 'mb-1'
              "
            >
              <el-col :span="6">
                <h4>{{ $t('table') }}</h4>
                <h4>{{ item.targetTableName }}</h4>
              </el-col>
              <el-col :span="18">
                <h4>{{ item }}</h4>
              </el-col>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column width="150">
          <template #default="{ $index }">
            <el-button
              v-if="$index !== 0 && !disableRestore"
              type="primary"
              @click="restoreConfiguration($index)"
            >
              {{ $t('restore') }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </template>
  </base-modal>
</template>
<script lang="ts">
import BaseModal from '@/components/modals/BaseModal.vue';
import { ElButton, ElCol, ElRow, ElTable, ElTableColumn } from 'element-plus';
import { GainImportConfiguration } from '@etp/etp-gain-bff-client/axios';
import moment from 'moment';

export default {
  name: 'ImportConfigurationVersionsModal',
  components: {
    BaseModal,
    ElButton,
    ElCol,
    ElRow,
    ElTable,
    ElTableColumn
  },
  props: {
    modelValue: { type: Boolean, default: false },
    configurationVersions: {
      type: Array as () => Array<GainImportConfiguration>,
      default: () => new Array<GainImportConfiguration>()
    },
    disableRestore: { type: Boolean, default: false }
  },
  emits: ['close', 'update:model-value', 'restore'],
  setup(props, { emit }) {
    const close = () => {
      emit('update:model-value', false);
    };
    const restoreConfiguration = (index: number) => {
      emit('restore', props.configurationVersions[index]);
    };
    const formatDate = (input: Date) => {
      if (!input) return '';
      return moment(input).format('YYYY-MM-DD');
    };
    const formatTime = (input: Date) => {
      if (!input) return '';
      return moment(input).format('HH:mm:ss');
    };

    const isFileDifferentToPreviousVersion = (
      index: number,
      namePattern: string
    ): boolean => {
      if (index === props.configurationVersions.length - 1) return false;
      var currentFileSpec = props.configurationVersions[
        index
      ].fileConfigurations?.filter(el => el.namePattern === namePattern);
      var previousFileSpecs = props.configurationVersions[
        index + 1
      ].fileConfigurations?.filter(el => el.namePattern === namePattern);
      return (
        JSON.stringify(currentFileSpec) !== JSON.stringify(previousFileSpecs)
      );
    };

    const isTableDifferentToPreviousVersion = (
      index: number,
      tableName: string
    ): boolean => {
      if (index === props.configurationVersions.length - 1) return false;
      var currentTableSpec = props.configurationVersions[
        index
      ].tableConfigurations?.filter(el => el.targetTableName === tableName);
      var previousTableSpec = props.configurationVersions[
        index + 1
      ].tableConfigurations?.filter(el => el.targetTableName === tableName);
      return (
        JSON.stringify(currentTableSpec) !== JSON.stringify(previousTableSpec)
      );
    };

    return {
      confirm,
      close,
      formatDate,
      formatTime,
      isFileDifferentToPreviousVersion,
      isTableDifferentToPreviousVersion,
      props,
      restoreConfiguration
    };
  }
};
</script>
<style scoped lang="scss">
.color-red {
  color: $etp-darker-red;
}
</style>
