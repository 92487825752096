import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_base_table = _resolveComponent("base-table")!
  const _component_base_modal = _resolveComponent("base-modal")!

  return (_openBlock(), _createBlock(_component_base_modal, {
    width: "690px",
    "modal-title": $setup.props.modalTitle,
    "confirm-text": _ctx.$t('confirm'),
    "dialog-visible": $props.dialogVisible,
    "has-confirmation-button": !!$setup.fileNamesToProcess.length,
    onConfirm: $setup.confirm,
    onClose: $setup.close
  }, {
    content: _withCtx(() => [
      _createVNode(_component_base_table, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_table, {
            ref: "multipleTableRef",
            data: $setup.props.files,
            onSelectionChange: $setup.handleProcessSelectionChange
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_table_column, { type: "selection" }),
              _createVNode(_component_el_table_column, {
                label: _ctx.$t('name'),
                prop: "actualName"
              }, null, 8, ["label"]),
              ($props.showArchive)
                ? (_openBlock(), _createBlock(_component_el_table_column, {
                    key: 0,
                    width: "100",
                    label: _ctx.$t('archive')
                  }, {
                    default: _withCtx((scope) => [
                      _createVNode(_component_el_checkbox, {
                        onChange: x => $setup.handleArchiveSelectionChange(x, scope.row)
                      }, null, 8, ["onChange"])
                    ]),
                    _: 1
                  }, 8, ["label"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["data", "onSelectionChange"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modal-title", "confirm-text", "dialog-visible", "has-confirmation-button", "onConfirm", "onClose"]))
}