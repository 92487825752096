<template>
  <el-row :gutter="spacingWidthHeaderComponents">
    <el-col :span="5">
      <base-building-panel
        v-model:selectedBranch="selectedBranch"
        :branches="branches"
        :loading-branches="loadingBranches"
      >
        <template #item>
          <el-form-item :label="$t('node')">
            <el-select
              v-model="selectedNode"
              :disabled="!selectedBranch.name || loadingNodes"
              value-key="name"
              size="small"
              :placeholder="loadingNodes ? $t('loading') : $t('empty')"
            >
              <el-option
                v-for="item in nodes"
                :key="item.name ?? ''"
                :label="item.name ?? ''"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
        </template>
      </base-building-panel>
    </el-col>
    <el-col :span="19">
      <base-card no-header>
        <template #body>
          <el-row class="d-flex pr-2">
            <el-button
              v-if="userCanStartEngagementPipelines && tests.length"
              :disabled="!tests.some(el => el.active)"
              type="primary"
              class="mt-2 ml-auto"
              @click.stop="startPipelineRun"
            >
              <h4>{{ $t('run') }}</h4>
            </el-button>
          </el-row>
          <el-row>
            <h4 v-if="loadingTests" class="mt-auto">{{ $t('loading') }}</h4>
          </el-row>
          <el-row>
            <table-placeholder-loading v-if="loadingTests" :columns="1" />
            <el-table
              v-else
              class="build-table"
              :data="tests"
              :empty-text="$t('noTests')"
            >
              <el-table-column prop="active" width="120">
                <template v-if="tests.length" #header>
                  <el-checkbox
                    class="checkbox"
                    :label="$t('active')"
                    :model-value="allActive"
                    @change="updateAllActive"
                  />
                </template>
                <template #default="{ row, $index }">
                  <el-checkbox
                    :model-value="row.active"
                    @change="
                      updateRow({
                        index: $index,
                        row: { ...row, active: $event }
                      })
                    "
                  />
                </template>
              </el-table-column>
              <el-table-column prop="name">
                <template v-if="tests.length" #header>
                  <span>{{ $t('test') }}</span>
                </template>
                <template #default="scope">
                  <div>{{ scope.row.name }}</div>
                </template>
              </el-table-column>
            </el-table>
          </el-row>
        </template>
      </base-card>
    </el-col>
  </el-row>
</template>

<script lang="ts">
import {
  ElButton,
  ElCheckbox,
  ElCol,
  ElFormItem,
  ElNotification,
  ElOption,
  ElRow,
  ElSelect,
  ElTable,
  ElTableColumn
} from 'element-plus';

import TablePlaceholderLoading from '@/components/tables/TablePlaceholderLoading.vue';
import BaseBuildingPanel from '@/components/panels/BaseBuildingPanel.vue';
import BaseCard from '@/components/panels/BaseCard.vue';
import { computed, ComputedRef, onMounted, ref } from 'vue';
import { spacingWidthHeaderComponents } from '@/utils/variables';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { appendUrlParam, processUrlParams } from '@/utils/urlParameter';
import i18n from '@/i18n';
import {
  LayerName,
  StartPipelineRunDto
} from '@etp/etp-pipelines-client/axios';
import config from '@/config/config';
import { BranchModel, NodeModel } from '@etp/etp-nodemodelgit-client/axios';
import {
  BuildNodeModel,
  BuildTestModel
} from '@/services/web-api/dto/nodeModel';

export default {
  name: 'ModelBuilding',
  components: {
    ElButton,
    ElCheckbox,
    BaseBuildingPanel,
    BaseCard,
    ElCol,
    ElRow,
    ElFormItem,
    ElOption,
    ElSelect,
    ElTable,
    ElTableColumn,
    TablePlaceholderLoading
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    let pipelineParameters = {
      EngagementId: store.getters['home/getCurrentEngagement'].id,
      TenantId: store.getters['home/getCurrentEngagement'].tenantId,
      Branch: store.getters['testing/selectedBuildBranch'].name,
      Node: store.getters['testing/selectedBuildNode'].name,
      ActiveTests: store.getters['testing/buildTests']
        .filter(n => n.active)
        .map(n => n.name)
    };

    const allActive = ref(false);

    const branches: ComputedRef<Array<BranchModel>> = computed(
      () => store.getters['testing/branches']
    );

    const nodes: ComputedRef<Array<BuildNodeModel>> = computed(
      () => store.getters['testing/buildNodes']
    );

    const tests: ComputedRef<Array<BuildTestModel>> = computed(
      () => store.getters['testing/buildTests']
    );

    let loadingBranches: ComputedRef<boolean> = computed(
      () => store.getters['testing/loadingBranches']
    );

    let loadingNodes: ComputedRef<boolean> = computed(
      () => store.getters['testing/loadingNodes']
    );

    let loadingTests: ComputedRef<boolean> = computed(
      () => store.getters['testing/loadingTests']
    );

    const updateRow = payload => {
      tests.value[payload.index] = payload.row;
      pipelineParameters.ActiveTests = tests.value
        .filter(n => n.active)
        .map(n => n.name);
    };

    const updateAllActive = (val: boolean) => {
      allActive.value = val;
      tests.value.map((el: BuildTestModel) => {
        el.active = val;
      });
      pipelineParameters.ActiveTests = tests.value
        .filter(n => n.active)
        .map(n => n.name);
    };

    const fetchBranches = async () => {
      await store.dispatch('testing/fetchBranches');
    };
    const fetchNodes = async () => {
      await store.dispatch('testing/fetchBuildNodes');
    };
    const fetchTests = async () => {
      await store.dispatch('testing/fetchBuildTests');
    };
    const startPipelineRun = async () => {
      await store.dispatch('testing/startPipelineRun', {
        currentLayer: LayerName.TestLayer,
        pipelineName: config.Pipeline.TestLayerPipelineName,
        parameters: pipelineParameters
      } as StartPipelineRunDto);
      ElNotification({
        title: 'Building ',
        dangerouslyUseHTMLString: true,
        message: i18n.global.t('testsRunning'),
        type: 'info'
      });
    };

    const selectedBranch = computed({
      get: () => store.getters['testing/selectedBuildBranch'],
      set: async (branch: BranchModel) => {
        appendUrlParam('branch', branch.name ?? '');
        store.commit('testing/SET_SELECTED_BUILD_BRANCH', branch);
        pipelineParameters.Branch = branch.name;
        await fetchNodes();
      }
    });
    const selectedNode = computed({
      get: () => store.getters['testing/selectedBuildNode'],
      set: async (node: NodeModel) => {
        appendUrlParam('branch', selectedBranch.value.name ?? '');
        appendUrlParam('node', node.name ?? '');
        store.commit('testing/SET_SELECTED_BUILD_NODE', node);
        pipelineParameters.Node = node.name;
        await fetchTests();
      }
    });

    const showNodeModelGitPages: ComputedRef<boolean> = computed(
      () => store.getters['home/showNodeModelGitPages']
    );
    const userCanStartEngagementPipelines: ComputedRef<boolean> = computed(
      () => store.getters['userPermissions/canStartEngagementPipelines']
    );
    onMounted(async () => {
      if (!showNodeModelGitPages.value) {
        return router.push({ name: 'Overview' });
      }
      fetchBranches().then(async () => {
        await processUrlParams([
          {
            name: 'branch',
            handler: async (branchName: string) => {
              let branch = branches.value.find(
                (el: BranchModel) => el.name == branchName
              );
              pipelineParameters.Branch = branchName;
              if (!branch) return;
              store.commit('testing/SET_SELECTED_BUILD_BRANCH', branch);
              await fetchNodes();
            }
          },
          {
            name: 'node',
            handler: async (nodeName: string) => {
              let node = nodes.value.find(
                (el: NodeModel) => el.name == nodeName
              );
              pipelineParameters.Node = nodeName;
              if (!node) return;
              store.commit('testing/SET_SELECTED_BUILD_NODE', node);
              await fetchTests();
            }
          }
        ]);
      });
      await store.dispatch(
        'userPermissions/checkIfCanStartEngagementPipelines'
      );
    });

    return {
      allActive,
      updateRow,
      updateAllActive,
      branches,
      startPipelineRun,
      nodes,
      selectedBranch,
      selectedNode,
      tests,
      spacingWidthHeaderComponents,
      loadingBranches,
      loadingNodes,
      loadingTests,
      userCanStartEngagementPipelines
    };
  }
};
</script>
