<template>
  <div class="navbar">
    <router-link :to="{ name: 'Overview' }" class="logo-wrapper">
      <div class="logo-holder">
        <img src="@/assets/images/etplogo.svg" class="logo-svg" />
      </div>
      <div class="logo-text">
        <span class="subtitle-1">
          {{ $t('etp') }}
        </span>
      </div>
    </router-link>
    <slot></slot>
  </div>
</template>
<script lang="ts">
export default {
  name: 'TheNavbar'
};
</script>
<style lang="scss" scoped>
.navbar {
  margin: 0rem;
  padding: 0rem 1.25rem 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: visible;
  background: transparentize($color: $etp-green-white, $amount: 1);
  min-height: 78px;
  max-height: 78px;
  .logo-wrapper {
    text-decoration: none;
    display: flex;
    align-items: flex-start;
    position: relative;
    right: 6px;
    margin-right: 136px;
    .logo-holder {
      margin: 0px 0px 0px 30px;
      border-radius: 100%;
      .logo-svg {
        position: relative;
        width: 20px;
        height: 25px;
      }
    }
    .logo-text {
      display: flex;
      flex-direction: column;
      margin-left: 1rem;
      margin-top: 2px;
      .subtitle-1 {
        margin-block-end: -0.075rem;
        margin-block-start: auto;
        text-rendering: geometricPrecision;
        color: $etp-light-grey;
      }
    }
  }
}
</style>
