import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createBlock(_component_el_table, {
    data: $props.pipelineRuns,
    "empty-text": _ctx.$t('noMonitor'),
    onSortChange: $setup.setSorting
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_table_column, {
        prop: "status",
        align: 'center',
        "header-align": "'left'",
        width: 65,
        sortable: "custom"
      }, {
        default: _withCtx((scope) => [
          _createElementVNode("div", {
            class: _normalizeClass(["pipeline-status-indicator", $setup.getStatusColor(scope.row.status)])
          }, null, 2)
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, {
        prop: "pipelineName",
        label: _ctx.$t('name'),
        sortable: "custom",
        "show-overflow-tooltip": ""
      }, {
        default: _withCtx((scope) => [
          _createElementVNode("h4", null, _toDisplayString(scope.row.pipelineName), 1)
        ]),
        _: 1
      }, 8, ["label"]),
      _createVNode(_component_el_table_column, {
        prop: "message",
        label: _ctx.$t('message'),
        "show-overflow-tooltip": ""
      }, {
        default: _withCtx((scope) => [
          _createElementVNode("h4", null, _toDisplayString(scope.row.message), 1)
        ]),
        _: 1
      }, 8, ["label"]),
      _createVNode(_component_el_table_column, {
        width: "200",
        prop: "runStart",
        label: _ctx.$t('runStart'),
        sortable: "custom"
      }, {
        default: _withCtx((scope) => [
          _createElementVNode("h4", null, _toDisplayString($setup.runDateFormat(scope.row.runStart)), 1)
        ]),
        _: 1
      }, 8, ["label"]),
      ($props.showAdfButton)
        ? (_openBlock(), _createBlock(_component_el_table_column, {
            key: 0,
            width: "150",
            "class-name": "overflow",
            prop: "url",
            align: "right"
          }, {
            default: _withCtx((scope) => [
              _createVNode(_component_el_button, {
                type: "primary",
                onClick: ($event: any) => ($setup.goToPipelineRun(scope.row.url))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("h4", null, _toDisplayString(_ctx.$t('view')), 1)
                ]),
                _: 2
              }, 1032, ["onClick"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["data", "empty-text", "onSortChange"]))
}