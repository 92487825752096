import TenantsOverview from '@/views/admin/tenants/TenantsOverview.vue';
import TenantDetails from '@/views/admin/tenants/TenantDetails.vue';
import TenantGeneral from '@/views/admin/tenants/TenantGeneral.vue';
import TenantCompose from '@/views/admin/tenants/TenantCompose.vue';
import TenantDeployments from '@/views/admin/tenants/TenantDeployments.vue';
import TenantDeploymentTasks from '@/views/admin/tenants/TenantDeploymentTasks.vue';
import TenantEngagements from '@/views/admin/tenants/TenantEngagements.vue';
import TenantUsers from '@/views/admin/tenants/TenantUsers.vue';
import TenantApplications from '@/views/admin/tenants/TenantApplications.vue';

export default [
  {
    path: 'tenants',
    name: 'TenantsOverview',
    component: TenantsOverview,
    meta: { menuItemName: 'TenantsOverview' }
  },
  {
    path: 'tenants/:tenantId',
    name: 'TenantDetails',
    redirect: { name: 'TenantGeneral' },
    component: TenantDetails,
    meta: { menuItemName: 'TenantsOverview' },
    children: [
      {
        path: 'general',
        name: 'TenantGeneral',
        component: TenantGeneral
      },
      {
        path: 'compose',
        name: 'TenantCompose',
        component: TenantCompose
      },
      {
        path: 'engagements',
        name: 'TenantEngagements',
        component: TenantEngagements
      },
      {
        path: 'deployments',
        name: 'TenantDeployments',
        component: TenantDeployments
      },
      {
        path: 'deployments/:deploymentId',
        name: 'TenantDeploymentTasks',
        component: TenantDeploymentTasks
      },
      {
        path: 'users',
        name: 'TenantUsers',
        component: TenantUsers
      },
      {
        path: 'applications',
        name: 'TenantApplications',
        component: TenantApplications
      }
    ]
  }
];
