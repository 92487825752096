import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_row, { class: "d-flex" }, {
      default: _withCtx(() => [
        _createElementVNode("h4", _hoisted_1, _toDisplayString($setup.tasks.length) + " · " + _toDisplayString(_ctx.$t('tasks')), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_el_table, { data: $setup.tasks }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          label: _ctx.$t('principalId'),
          formatter: $setup.findPrincipalId
        }, null, 8, ["label", "formatter"]),
        _createVNode(_component_el_table_column, {
          label: _ctx.$t('status')
        }, {
          default: _withCtx((scope) => [
            _createVNode(_component_el_tooltip, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString($setup.findStatus(scope.row)), 1)
              ]),
              content: _withCtx(() => [
                _createTextVNode(_toDisplayString(scope.row.message), 1)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_el_table_column, {
          label: _ctx.$t('resourceName'),
          formatter: $setup.findResourceName
        }, null, 8, ["label", "formatter"]),
        _createVNode(_component_el_table_column, {
          label: _ctx.$t('resourceType'),
          formatter: $setup.findType
        }, null, 8, ["label", "formatter"]),
        _createVNode(_component_el_table_column, {
          label: _ctx.$t('lastUpdated')
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("h4", null, _toDisplayString($setup.formatDate(scope.row.timestamp)), 1)
          ]),
          _: 1
        }, 8, ["label"])
      ]),
      _: 1
    }, 8, ["data"])
  ], 64))
}