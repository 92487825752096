import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mt-auto" }
const _hoisted_2 = { class: "body-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_col = _resolveComponent("el-col")!

  return (_openBlock(), _createBlock(_component_el_row, { gutter: 24 }, {
    default: _withCtx(() => [
      _createVNode(_component_el_col, { span: 12 }, {
        default: _withCtx(() => [
          _createVNode(_component_el_row, { class: "d-flex" }, {
            default: _withCtx(() => [
              _createElementVNode("h4", _hoisted_1, _toDisplayString($setup.deployments.length) + " · " + _toDisplayString(_ctx.$t('deployments')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table, {
            data: $setup.deployments,
            "row-class-name": "table-row-clickable",
            onRowClick: $setup.openDeployment
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_table_column, {
                prop: "name",
                label: _ctx.$t('name')
              }, {
                default: _withCtx((scope) => [
                  _createElementVNode("span", _hoisted_2, _toDisplayString(scope.row.name), 1)
                ]),
                _: 1
              }, 8, ["label"])
            ]),
            _: 1
          }, 8, ["data", "onRowClick"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}