import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card-header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createBlock(_component_el_card, { class: "box-card" }, _createSlots({
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "body")
    ]),
    _: 2
  }, [
    (!$props.noHeader)
      ? {
          name: "header",
          fn: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _renderSlot(_ctx.$slots, "header")
            ])
          ]),
          key: "0"
        }
      : undefined
  ]), 1024))
}