<template>
  <div class="user-menu-container">
    <div class="user-name">
      <span class="welcome">
        {{ $t('welcome') }},
        <b>{{ currentUser.displayName }}</b>
      </span>
    </div>
    <div class="picture-menu-wrapper">
      <img
        v-if="profilePicture"
        :src="profilePicture"
        height="38"
        width="38"
        class="user"
      />
      <img
        v-if="!profilePicture"
        src="@/assets/images/icons/user-placeholder.png"
        height="38"
        width="38"
        class="user"
      />
    </div>
    <el-dropdown type="primary" trigger="click" placement="bottom-end">
      <span class="el-dropdown-link">
        <img
          src="@/assets/images/icons/new/menu.svg"
          width="10"
          height="10"
          class="action-icon"
        />
      </span>
      <template #dropdown>
        <el-dropdown-menu class="menu-dropdown">
          <h3>
            <el-dropdown-item
              @click="$router.push({ name: 'ProfileOverview' })"
            >
              <span class="menu-item">
                <img
                  class="icon"
                  src="@/assets/images/icons/new/profile.svg"
                  height="14"
                  width="14"
                />
                {{ $t('profile') }}
              </span>
            </el-dropdown-item>
          </h3>
          <hr class="divider" />
          <h3>
            <el-dropdown-item
              v-if="userCanReadGlobalUsers"
              @click="$router.push({ name: 'UsersOverview' })"
            >
              <span class="menu-item">
                <img
                  class="icon"
                  src="@/assets/images/icons/new/users.svg"
                  height="14"
                  width="14"
                />
                {{ $t('users') }}
              </span>
            </el-dropdown-item>
          </h3>
          <h3>
            <el-dropdown-item
              v-if="userCanReadGlobalUsers"
              @click="$router.push({ name: 'ApplicationsOverview' })"
            >
              <span class="menu-item">
                <img
                  class="icon"
                  src="@/assets/images/icons/new/healthcheck.svg"
                  height="14"
                  width="14"
                />
                {{ $t('applications') }}
              </span>
            </el-dropdown-item>
          </h3>
          <h3>
            <el-dropdown-item
              v-if="userCanReadGlobalTenants"
              @click="$router.push({ name: 'TenantsOverview' })"
            >
              <span class="menu-item">
                <img
                  class="icon"
                  src="@/assets/images/icons/new/tenants.svg"
                  height="14"
                  width="14"
                />
                {{ $t('tenants') }}
              </span>
            </el-dropdown-item>
          </h3>
          <h3>
            <el-dropdown-item
              v-if="userCanReadGlobalEngagements"
              @click="$router.push({ name: 'EngagementsOverview' })"
            >
              <span class="menu-item">
                <img
                  class="icon"
                  src="@/assets/images/icons/office.svg"
                  height="14"
                  width="14"
                />
                {{ $t('engagements') }}
              </span>
            </el-dropdown-item>
          </h3>
          <h3>
            <el-dropdown-item
              v-if="userCanReadGlobalAuthorization"
              class="menu-item"
              @click="$router.push({ name: 'RolesOverview' })"
            >
              <span class="menu-item">
                <img
                  class="icon"
                  src="@/assets/images/icons/userroles.svg"
                  height="14"
                  width="14"
                />
                {{ $t('roles') }}
              </span>
            </el-dropdown-item>
          </h3>
          <h3>
            <el-dropdown-item
              v-if="userCanReadGlobalAuthorization"
              @click="$router.push({ name: 'PermissionsOverview' })"
            >
              <span class="menu-item">
                <img
                  class="icon"
                  src="@/assets/images/icons/check-list.svg"
                  height="14"
                  width="14"
                />
                {{ $t('permissions') }}
              </span>
            </el-dropdown-item>
          </h3>
          <hr
            v-if="
              userCanReadGlobalAuthorization ||
              userCanReadGlobalEngagements ||
              userCanReadGlobalTenants ||
              userCanReadGlobalUsers
            "
            class="divider"
          />
          <h3>
            <el-dropdown-item @click="$router.push({ name: 'Overview' })">
              <span class="menu-item">
                <img
                  class="icon"
                  src="@/assets/images/icons/new/home.svg"
                  height="14"
                  width="14"
                />
                {{ $t('overview') }}
              </span>
            </el-dropdown-item>
          </h3>
          <hr class="divider" />
          <h3>
            <el-dropdown-item @click="signOut">
              <span class="menu-item">
                <img
                  class="icon"
                  src="@/assets/images/icons/new/logout.svg"
                  height="14"
                  width="14"
                />
                {{ $t('logout') }}
              </span>
            </el-dropdown-item>
          </h3>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
</template>
<script lang="ts">
import { computed, ComputedRef, onMounted } from 'vue';
import { useStore } from 'vuex';

import { ElDropdown, ElDropdownItem, ElDropdownMenu } from 'element-plus';

export default {
  name: 'TheUserMenu',
  components: {
    ElDropdown,
    ElDropdownMenu,
    ElDropdownItem
  },
  props: {
    currentUser: { type: Object, required: true },
    profilePicture: { type: String, required: false, default: undefined }
  },
  emits: ['signout'],
  setup(props, { emit }) {
    const store = useStore();

    const signOut = () => {
      emit('signout');
    };

    const userCanReadGlobalAuthorization: ComputedRef<boolean> = computed(
      () => store.getters['userPermissions/canReadGlobalAuthorization']
    );
    const userCanReadGlobalEngagements: ComputedRef<boolean> = computed(
      () => store.getters['userPermissions/canReadGlobalEngagements']
    );
    const userCanReadGlobalTenants: ComputedRef<boolean> = computed(
      () => store.getters['userPermissions/canReadGlobalTenants']
    );
    const userCanReadGlobalUsers: ComputedRef<boolean> = computed(
      () => store.getters['userPermissions/canReadGlobalUsers']
    );

    onMounted(async () => {
      await store.dispatch('userPermissions/checkIfCanReadGlobalAuthorization');
      await store.dispatch('userPermissions/checkIfCanReadGlobalEngagements');
      await store.dispatch('userPermissions/checkIfCanReadGlobalTenants');
      await store.dispatch('userPermissions/checkIfCanReadGlobalUsers');
    });

    return {
      signOut,
      userCanReadGlobalAuthorization,
      userCanReadGlobalEngagements,
      userCanReadGlobalTenants,
      userCanReadGlobalUsers
    };
  }
};
</script>
<style scoped lang="scss">
.menu-item {
  margin: 5px 12px;
  color: $etp-deloitte-blue-7;
}
.user-menu-container,
.menu-item,
.picture-menu-wrapper,
.user-name {
  display: flex;
  align-items: center;
}
.user-name {
  margin-right: 15px;
}
h4 {
  margin-right: 3px;
}
.picture-menu-wrapper {
  margin-right: 1rem;
  padding-right: 1.5rem;
  border-right: 1px solid $etp-deloitte-white;
}
.user {
  border-radius: 100%;
  opacity: 0.9;
}
.space-around {
  padding: 7px 0 0;
}
.menu-dropdown {
  width: 200px;
  overflow: hidden;
}
.divider {
  border: 0.25px solid $etp-shade-1;
  background: $etp-shade-1;
  width: 67%;
  margin: 19px 32px;
}
.icon {
  margin-right: 15px;
}
.welcome {
  font-size: 14px;
  font-weight: 400;
}
.is-disabled {
  cursor: pointer;
}
</style>
