<template>
  <base-card no-header>
    <template #body>
      <el-row class="d-flex pr-2">
        <el-button
          v-if="showBuildButton && localNodes.length"
          :disabled="!localNodes.some(el => el.active || el.rebuild)"
          type="primary"
          class="mt-2 ml-auto"
          @click.stop="startPipelineRun"
        >
          <h4>{{ $t('build') }}</h4>
        </el-button>
      </el-row>
      <el-row>
        <h4 v-if="loadingNodes" class="mt-auto">{{ $t('loading') }}</h4>
      </el-row>
      <el-row>
        <table-placeholder-loading v-if="loadingNodes" :columns="1" />
        <table-placeholder v-else-if="!localNodes.length" />
        <el-table
          v-else
          class="build-table"
          :data="localNodes"
          :empty-text="$t('noBuilds')"
        >
          <el-table-column prop="active" width="120">
            <template v-if="localNodes.length" #header>
              <el-checkbox
                class="checkbox"
                :label="$t('active')"
                :model-value="allActive"
                @update:model-value="updateAllActive"
              />
            </template>
            <template #default="{ row, $index }">
              <el-checkbox
                :model-value="row.active"
                @update:model-value="
                  updateRow({
                    index: $index,
                    row: { ...row, active: $event }
                  })
                "
              />
            </template>
          </el-table-column>
          <el-table-column prop="rebuild" width="150">
            <template v-if="localNodes.length" #header>
              <el-checkbox
                class="checkbox"
                :label="$t('rebuild')"
                :model-value="allRebuild"
                @update:model-value="updateAllRebuild"
              />
            </template>
            <template #default="{ row, $index }">
              <el-checkbox
                :model-value="row.rebuild"
                @update:model-value="
                  updateRow({
                    index: $index,
                    row: { ...row, rebuild: $event, active: $event }
                  })
                "
              />
            </template>
          </el-table-column>
          <el-table-column prop="name">
            <template v-if="localNodes.length" #header>
              <span>{{ $t('node') }}</span>
            </template>
            <template #default="scope">
              <span>
                {{ scope.row.name }}
              </span>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
    </template>
  </base-card>
</template>
<script lang="ts">
import {
  ElButton,
  ElCheckbox,
  ElMessage,
  ElRow,
  ElTable,
  ElTableColumn
} from 'element-plus';

import TablePlaceholderLoading from '@/components/tables/TablePlaceholderLoading.vue';
import TablePlaceholder from '@/components/tables/TablePlaceholder.vue';
import BaseCard from '@/components/panels/BaseCard.vue';
import { ref, watch } from 'vue';
import i18n from '@/i18n';
import { BuildNodeModel } from '@/services/web-api/dto/nodeModel';

export default {
  name: 'BaseBuildingTable',
  components: {
    BaseCard,
    ElRow,
    ElCheckbox,
    ElTable,
    ElTableColumn,
    ElButton,
    TablePlaceholderLoading,
    TablePlaceholder
  },
  props: {
    modelValue: { type: Array as () => Array<BuildNodeModel>, required: true },
    loadingNodes: { type: Boolean, default: false },
    showBuildButton: { type: Boolean, default: false }
  },
  emits: ['update:model-value', 'start-pipeline-run'],
  setup(props, { emit }) {
    const startPipelineRun = () => {
      emit('start-pipeline-run');
      ElMessage({
        showClose: true,
        message: i18n.global.t('nodesBuilding')
      });
    };

    const allActive = ref(false);
    const allRebuild = ref(false);

    const localNodes = ref();

    const updateRow = payload => {
      localNodes.value[payload.index] = payload.row;
      emit('update:model-value', localNodes.value);
    };

    const updateAllActive = (value: boolean) => {
      allActive.value = value;
      localNodes.value.map((el: BuildNodeModel) => {
        el.active = value;
      });
      emit('update:model-value', localNodes.value);
    };

    const updateAllRebuild = (value: boolean) => {
      allRebuild.value = value;
      localNodes.value.map((el: BuildNodeModel) => {
        el.rebuild = value;
      });
      emit('update:model-value', localNodes.value);
    };

    watch(
      () => props.modelValue,
      newValue => {
        localNodes.value = newValue;
      },
      { immediate: true }
    );

    return {
      allActive,
      allRebuild,
      localNodes,
      updateRow,
      updateAllActive,
      updateAllRebuild,
      startPipelineRun
    };
  }
};
</script>
