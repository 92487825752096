const GetGlobalScope = () => '/platform';
const GetScopeForTenant = (tenantId: string) => '/tenants/' + tenantId;
const GetScopeForEngagement = (engagementId: string) =>
  '/engagements/' + engagementId;
const GetScopeForStoragePath = (
  engagementId: string,
  storagePurpose: string,
  path: Array<string>
) => `/engagements/${engagementId}/storage/${storagePurpose}/${path.join('/')}`;

const GetScopeMaskForEngagement = () => '/engagements/{engagementId}';
const GetScopeMaskForTenant = () => '/tenants/{tenantId}';

const IsTenantScope = (scope: string): boolean => scope.startsWith('/tenants/');
const IsEngagementScope = (scope: string): boolean =>
  scope.startsWith('/engagements/');

const ExtractTenantFromScope = (scope: string): string => {
  if (!IsTenantScope(scope)) return '';
  return scope.split('/')[2];
};
const ExtractEngagementFromScope = (scope: string): string => {
  if (!IsEngagementScope(scope)) return '';
  return scope.split('/')[2];
};

export {
  ExtractEngagementFromScope,
  ExtractTenantFromScope,
  GetGlobalScope,
  GetScopeForTenant,
  GetScopeForEngagement,
  GetScopeForStoragePath,
  GetScopeMaskForEngagement,
  GetScopeMaskForTenant,
  IsEngagementScope,
  IsTenantScope
};
