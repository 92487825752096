<template>
  <el-form :model="form">
    <el-form-item prop="deploySqlPrivateEndpoint" class="mb-0">
      <el-checkbox
        class="checkbox"
        :label="$t('deploySqlPrivateEndpoint')"
        :model-value="form.deploySqlPrivateEndpoint"
        @change="handleChangeDeploySqlPrivateEndpoint"
      />
    </el-form-item>
    <el-form-item prop="deploySparkPool" class="mb-0">
      <el-checkbox
        class="checkbox"
        :label="$t('deployApacheSparkPool')"
        :model-value="form.deploySparkPool"
        @change="handleChangeDeploySparkPool"
      />
    </el-form-item>
    <el-form-item prop="deploySqlPool" class="mb-0">
      <el-checkbox
        class="checkbox"
        :label="$t('deploySqlPool')"
        :model-value="form.deploySqlPool"
        @change="handleChangeDeploySqlPool"
      />
    </el-form-item>
    <el-form-item prop="preventDataExfiltration" class="mb-0">
      <el-checkbox
        class="checkbox"
        :label="$t('preventDataExfiltration')"
        :model-value="form.preventDataExfiltration"
        @change="handleChangePreventDataExfiltration"
      />
    </el-form-item>
    <el-form-item :label="$t('dataLakeStorageName')" class="mb-0">
      <el-select
        v-model="form.dataLakeStorageName"
        size="small"
        :placeholder="$t('empty')"
        @change="handleChangeDataLakeStorageName"
      >
        <el-option
          v-for="item in dataLakeStorageNames"
          :key="item"
          :label="item"
          :value="item"
        ></el-option>
      </el-select>
    </el-form-item>
    <el-form-item :label="$t('managedIdentityName')">
      <el-select
        v-model="form.managedIdentityName"
        size="small"
        :placeholder="$t('empty')"
        @change="handleChangeManagedIdentityName"
      >
        <el-option
          v-for="item in managedIdentityNames"
          :key="item"
          :label="item"
          :value="item"
        ></el-option>
      </el-select>
    </el-form-item>
  </el-form>
</template>
<script lang="ts">
import {
  ElForm,
  ElFormItem,
  ElCheckbox,
  ElOption,
  ElSelect
} from 'element-plus';
import { SynapseAnalyticsConfiguration } from '@/views/admin/tenants/models/TenantAzureConfigurationObjects';
import { onMounted, onUpdated, ref } from 'vue';

export default {
  name: 'SynapseAnalyticsConfigurationForm',
  components: { ElForm, ElFormItem, ElCheckbox, ElOption, ElSelect },
  props: {
    configuration: { type: String, default: '' },
    dataLakeStorageNames: {
      type: Array as () => Array<string>,
      default: () => new Array<string>()
    },
    managedIdentityNames: {
      type: Array as () => Array<string>,
      default: () => new Array<string>()
    }
  },
  emits: ['update-configuration'],
  setup(props, { emit }) {
    let form = ref(new SynapseAnalyticsConfiguration());
    let localConfugrationObject = ref({});

    const emitUpdateEvent = () => {
      emit(
        'update-configuration',
        JSON.stringify({
          ...localConfugrationObject.value,
          ...form.value
        })
      );
    };

    const handleChangeDataLakeStorageName = (newValue: string) => {
      form.value.dataLakeStorageName = newValue;
      emitUpdateEvent();
    };

    const handleChangeManagedIdentityName = (newValue: string) => {
      form.value.managedIdentityName = newValue;
      emitUpdateEvent();
    };

    const handleChangeDeploySqlPrivateEndpoint = (newValue: boolean) => {
      form.value.deploySqlPrivateEndpoint = newValue;
      emitUpdateEvent();
    };

    const handleChangeDeploySparkPool = (newValue: boolean) => {
      form.value.deploySparkPool = newValue;
      emitUpdateEvent();
    };

    const handleChangeDeploySqlPool = (newValue: boolean) => {
      form.value.deploySqlPool = newValue;
      emitUpdateEvent();
    };

    const handleChangePreventDataExfiltration = (newValue: boolean) => {
      form.value.preventDataExfiltration = newValue;
      emitUpdateEvent();
    };

    const updateLocalVars = () => {
      localConfugrationObject.value = JSON.parse(props.configuration || '{}');
      form.value.dataLakeStorageName =
        localConfugrationObject.value['dataLakeStorageName'] ?? '';
      form.value.managedIdentityName =
        localConfugrationObject.value['managedIdentityName'] ?? '';
      form.value.deploySqlPrivateEndpoint =
        localConfugrationObject.value['deploySqlPrivateEndpoint'] ?? false;
      form.value.preventDataExfiltration =
        localConfugrationObject.value['preventDataExfiltration'] ?? false;
      form.value.deploySparkPool =
        localConfugrationObject.value['deploySparkPool'] ?? false;
      form.value.deploySqlPool =
        localConfugrationObject.value['deploySqlPool'] ?? false;
    };

    onMounted(() => {
      updateLocalVars();
      emitUpdateEvent();
    });
    onUpdated(() => updateLocalVars());

    return {
      form,
      handleChangeDataLakeStorageName,
      handleChangeDeploySparkPool,
      handleChangeDeploySqlPool,
      handleChangeManagedIdentityName,
      handleChangeDeploySqlPrivateEndpoint,
      handleChangePreventDataExfiltration
    };
  }
};
</script>
<style scoped lang="scss"></style>
