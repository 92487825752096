import { AccessToken } from '@azure/core-auth';
import {
  AccountInfo,
  AuthenticationResult,
  Configuration,
  PopupRequest,
  PublicClientApplication,
  RedirectRequest,
  SilentRequest
} from '@azure/msal-browser';
import { MsalInitOptions, MsalGetTokenOptions } from './types';
import store from '@/store';
import { EngagementModel } from '@etp/etp-engagements-client/axios';

let myMSALObj: PublicClientApplication;
let account: AccountInfo | null;
let loginPopupRequest: PopupRequest;
let loginRedirectRequest: RedirectRequest;
let authenticated = false;

async function loginPopup(): Promise<void> {
  return new Promise((resolve, reject) => {
    myMSALObj
      .loginPopup(loginPopupRequest)
      .then((response: AuthenticationResult) => {
        account = response.account;
        localStorage.setItem('etpMsalAccount', JSON.stringify(account));
        resolve();
      })
      .catch(() => {
        reject();
      });
  });
}

async function loginRedirect(): Promise<void> {
  return new Promise(() => {
    myMSALObj.loginRedirect(loginRedirectRequest);
  });
}

export default {
  init(options: MsalInitOptions): void {
    const configuration: Configuration = {
      auth: {
        authority: `https://login.microsoftonline.com/${options.tenantId}`,
        clientId: options.clientId,
        postLogoutRedirectUri: options.postLogoutRedirectUri,
        redirectUri: options.redirectUri
      },
      cache: {
        cacheLocation: options.cacheLocation,
        storeAuthStateInCookie: false // Set this to 'true' if you are having issues on IE11 or Edge
      }
    };
    localStorage.setItem('initialAddress', window.location.pathname);
    myMSALObj = new PublicClientApplication(configuration);
    account = null;
    loginPopupRequest = {
      scopes: options.consentScopes
    };
    loginRedirectRequest = {
      ...loginPopupRequest,
      redirectStartPage:
        localStorage.getItem('initialAddress') !== process.env.BASE_URL
          ? `${localStorage.getItem('initialAddress')}`
          : process.env.BASE_URL + 'overview'
    };
  },
  async signIn(method: string): Promise<void> {
    if (method === 'loginPopup') {
      return loginPopup();
    }
    if (method === 'loginRedirect') {
      return loginRedirect();
    }
    return Promise.reject();
  },
  async signOut(): Promise<void> {
    return new Promise(resolve => {
      localStorage.removeItem('etpMsalAccount');
      store.commit('home/SET_CURRENT_ENGAGEMENT', {} as EngagementModel);
      myMSALObj.logoutRedirect({
        account: account,
        onRedirectNavigate: () => false
      });
      resolve();
    });
  },
  async getToken(options: MsalGetTokenOptions): Promise<AccessToken | null> {
    if (!account) {
      account = JSON.parse(
        localStorage.getItem('etpMsalAccount') || ''
      ) as AccountInfo;
    }
    let silentRequest: SilentRequest = {
      authority: `https://login.microsoftonline.com/${options.tenantId}`,
      scopes: options.scopes,
      account: account
    };
    try {
      const response: AuthenticationResult = await myMSALObj.acquireTokenSilent(
        silentRequest
      );
      return {
        token: response.accessToken,
        expiresOnTimestamp: response.expiresOn?.getTime()
      } as AccessToken;
    } catch (e) {
      let popupRequest: PopupRequest = { scopes: options.scopes };
      return myMSALObj
        .acquireTokenPopup(popupRequest)
        .then((resp: AuthenticationResult) => {
          return {
            token: resp.accessToken,
            expiresOnTimestamp: resp.expiresOn?.getTime()
          } as AccessToken;
        })
        .catch(() => {
          return null;
        });
    }
  },
  async handleRedirectPromise() {
    await myMSALObj.handleRedirectPromise().then(response => {
      const currentAccounts = myMSALObj.getAllAccounts();

      if (currentAccounts && currentAccounts.length) {
        const account = currentAccounts[0];
        localStorage.setItem('etpMsalAccount', JSON.stringify(account));
        authenticated = true;
      } else if (!currentAccounts.length && response != null) {
        localStorage.setItem(
          'etpMsalAccount',
          JSON.stringify(response.account)
        );
        authenticated = true;
      }
    });
  },
  isAuthenticated() {
    return authenticated;
  }
};
