<template>
  <base-card no-header class="width-100">
    <template #body>
      <el-row>
        <el-col :span="10">
          <h3>{{ entry.batchId }}</h3>
          <el-tooltip placement="top" :content="entry.statusMessage || ''">
            <span v-if="entry.status" class="body-1">
              {{ formatBatchStatus(entry.status) }}
            </span>
          </el-tooltip>
        </el-col>
        <el-col :span="12">
          <el-row>
            <el-col :span="9">
              <h4>{{ $t('created') }}</h4>
            </el-col>
            <el-col :span="9">
              <h4>{{ formatDate(entry.createdAt) }}</h4>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="9">
              <h4>{{ $t('receivedFromClient') }}</h4>
            </el-col>
            <el-col :span="9">
              <h4>{{ formatDate(entry.recordsReceivedFromClientAt) }}</h4>
            </el-col>
            <el-col :span="6">
              <h4 class="align-right">
                {{ formatRecords(entry.numRecordsReceivedFromClient) }}
              </h4>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="9">
              <h4>{{ $t('sentToGain') }}</h4>
            </el-col>
            <el-col :span="9">
              <h4>{{ formatDate(entry.recordsSentToGainAt) }}</h4>
            </el-col>
            <el-col :span="6">
              <h4 class="align-right">
                {{ formatRecords(entry.numRecordsSentToGain) }}
              </h4>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </template>
  </base-card>
</template>

<script lang="ts">
import BaseCard from '@/components/panels/BaseCard.vue';
import { ElRow, ElCol, ElTooltip } from 'element-plus';
import { BatchDetails, BatchStatus } from '@etp/etp-gain-bff-client/axios';
import moment from 'moment';
import i18n from '@/i18n';

export default {
  name: 'BatchDetailsCard',
  components: { ElRow, ElCol, ElTooltip, BaseCard },
  props: {
    entry: { type: Object as () => BatchDetails, required: true }
  },
  setup() {
    const formatDate = (input: string | null | undefined) => {
      if (!input) return '-';
      return moment(input).format('YYYY-MM-DD HH:mm:ss');
    };
    const formatRecords = (input: number | null | undefined) => {
      let count = input ?? 0;
      return count + ' ' + i18n.global.t('records');
    };
    const formatBatchStatus = (input: BatchStatus): string => {
      return (
        i18n.global.t('batchStatus') + ': ' + Object.keys(BatchStatus)[input]
      );
    };

    return {
      formatBatchStatus,
      formatDate,
      formatRecords
    };
  }
};
</script>
