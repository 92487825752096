import EngagementAccessOverview from '../EngagementAccessOverview.vue';
import EngagementApplications from '@/views/admin/engagements/EngagementApplications.vue';
import EngagementRbacTasks from '@/views/admin/engagements/EngagementRbacTasks.vue';
import EngagementUsers from '@/views/admin/engagements/EngagementUsers.vue';

export default {
  path: '/engagement-access/:engagementId',
  name: 'EngagementAccessOverview',
  redirect: { name: 'EngagementAccessUsers' },
  component: EngagementAccessOverview,
  meta: { menuItemName: 'EngagementAccess' },
  children: [
    {
      path: 'applications',
      name: 'EngagementAccessApplications',
      component: EngagementApplications
    },
    {
      path: 'users',
      name: 'EngagementAccessUsers',
      component: EngagementUsers
    },
    {
      path: 'tasks',
      name: 'EngagementAccessTasks',
      component: EngagementRbacTasks
    }
  ]
};
