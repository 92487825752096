import { AiServicesKind } from './AiServicesKind';

export class AiServicesConfiguration {
  kind: number;
  managedIdentityName: string;
  constructor() {
    this.kind = AiServicesKind.Unknown;
    this.managedIdentityName = '';
  }
}

export class DatabricksConfiguration {
  pricingTier: string;
  constructor() {
    this.pricingTier = '';
  }
}

export class DataFactoryConfiguration {
  deployDataFactoryPrivateEndpoint: boolean;
  addDataFactoryLinkedServices: boolean;
  managedIdentityName: string;
  linkedServiceResourceIds: Array<string>;
  constructor() {
    this.deployDataFactoryPrivateEndpoint = false;
    this.addDataFactoryLinkedServices = false;
    this.managedIdentityName = '';
    this.linkedServiceResourceIds = [];
  }
}

export class EventGridTopicConfiguration {
  deployTopicPrivateEndpoint: boolean;
  constructor() {
    this.deployTopicPrivateEndpoint = false;
  }
}

export class FunctionAppConfiguration {
  createGatewayEntry: boolean;
  managedIdentityName: string;
  storageAccountName: string;
  constructor() {
    this.createGatewayEntry = false;
    this.managedIdentityName = '';
    this.storageAccountName = '';
  }
}

export class KeyVaultConfiguration {
  deployKeyVaultPrivateEndpoint: boolean;
  managedIdentityNames: Array<string>;
  constructor() {
    this.deployKeyVaultPrivateEndpoint = false;
    this.managedIdentityNames = [];
  }
}

export class ContainerRegistryConfiguration {
  deployContainerRegistryPrivateEndpoint: boolean;
  managedIdentityName: string;
  skuType: string;
  constructor() {
    this.deployContainerRegistryPrivateEndpoint = false;
    this.skuType = '';
    this.managedIdentityName = '';
  }
}

export class MachineLearningWorkspaceConfiguration {
  deployMachineLearningPrivateEndpoint: boolean;
  managedIdentityName: string;
  storageAccountName: string;
  keyVaultName: string;
  containerRegistryName: string;
  constructor() {
    this.deployMachineLearningPrivateEndpoint = false;
    this.managedIdentityName = '';
    this.storageAccountName = '';
    this.keyVaultName = '';
    this.containerRegistryName = '';
  }
}

export class PowerBiWorkspaceConfiguration {
  powerBiCapacityName: string;
  constructor() {
    this.powerBiCapacityName = '';
  }
}

export class SqlDatabaseConfiguration {
  seedEntries: boolean;
  sqlServerName: string;
  constructor() {
    this.seedEntries = false;
    this.sqlServerName = '';
  }
}

export class SqlServerConfiguration {
  deploySqlServerPrivateEndpoint: boolean;
  managedIdentityName: string;
  constructor() {
    this.deploySqlServerPrivateEndpoint = false;
    this.managedIdentityName = '';
  }
}

export class StorageAccountConfiguration {
  createGatewayEntry: boolean;
  createContainers: boolean;
  deployBlobPrivateEndpoint: boolean;
  deployDfsPrivateEndpoint: boolean;
  deployFilePrivateEndpoint: boolean;
  deployQueuePrivateEndpoint: boolean;
  constructor() {
    this.createGatewayEntry = false;
    this.createContainers = false;
    this.deployBlobPrivateEndpoint = false;
    this.deployDfsPrivateEndpoint = false;
    this.deployFilePrivateEndpoint = false;
    this.deployQueuePrivateEndpoint = false;
  }
}

export class SynapseAnalyticsConfiguration {
  dataLakeStorageName: string;
  managedIdentityName: string;
  deploySqlPrivateEndpoint: boolean;
  preventDataExfiltration: boolean;
  deploySparkPool: boolean;
  deploySqlPool: boolean;
  constructor() {
    this.dataLakeStorageName = '';
    this.managedIdentityName = '';
    this.deploySqlPrivateEndpoint = false;
    this.preventDataExfiltration = false;
    this.deploySparkPool = false;
    this.deploySqlPool = false;
  }
}
