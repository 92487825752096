import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ae9f02ce"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mb-1" }
const _hoisted_2 = { class: "d-flex align-items-center" }
const _hoisted_3 = { class: "pl-0 width-100" }
const _hoisted_4 = ["src", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_plus = _resolveComponent("plus")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!
  const _component_base_modal = _resolveComponent("base-modal")!

  return (_openBlock(), _createBlock(_component_base_modal, {
    "modal-title": _ctx.$t('categories'),
    "confirm-text": _ctx.$t('save'),
    "dialog-visible": $props.dialogVisible,
    width: "400px",
    onConfirm: $setup.confirm,
    onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
  }, {
    content: _withCtx(() => [
      _createElementVNode("h4", _hoisted_1, _toDisplayString(_ctx.$t('addNewCategory')), 1),
      _createVNode(_component_el_form, {
        ref: "newCategoryFormRef",
        model: $setup.newCategoryForm,
        rules: $setup.newCategoryFormRules,
        "label-position": "top"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_el_form_item, { class: "flex-grow-1 mb-0" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: $setup.newCategoryForm.name,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.newCategoryForm.name) = $event)),
                  size: "small",
                  placeholder: _ctx.$t('name')
                }, null, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_button, {
                  class: "icon-button p-1 cursor-pointer",
                  onClick: $setup.addCategory
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_plus, { class: "icon-size-8" })
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      }, 8, ["model", "rules"]),
      _createVNode(_component_el_form, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, null, {
            default: _withCtx(() => [
              _createElementVNode("ul", _hoisted_3, [
                _createVNode(_component_el_scrollbar, { "max-height": "500px" }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.localCategories, (category, index) => {
                      return (_openBlock(), _createElementBlock("li", {
                        key: category,
                        class: "d-flex"
                      }, [
                        _createElementVNode("span", null, _toDisplayString(category), 1),
                        _createElementVNode("img", {
                          src: require(`@/assets/images/icons/new/delete.svg`),
                          class: "ml-auto icon-size-7",
                          onClick: ($event: any) => ($setup.removeCategory(index))
                        }, null, 8, _hoisted_4)
                      ]))
                    }), 128))
                  ]),
                  _: 1
                })
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modal-title", "confirm-text", "dialog-visible", "onConfirm"]))
}