import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1a3c2d3a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex align-items-flex-end" }
const _hoisted_2 = { class: "main-title" }
const _hoisted_3 = {
  key: 0,
  class: "subtitle-2 ml-1 extra-title"
}
const _hoisted_4 = { class: "mt-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_el_select, {
        class: "storage-select width-auto",
        "value-key": "storagePurpose",
        onChange: $setup.handleSelectChange
      }, {
        prefix: _withCtx(() => [
          _createElementVNode("h2", _hoisted_2, _toDisplayString($setup.pageHeader), 1)
        ]),
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.storageConfigurations.filter(c => c.displayName?.length! > 2), (item) => {
            return (_openBlock(), _createBlock(_component_el_option, {
              key: item.storagePurpose,
              label: item.displayName ?? item.storagePurpose,
              value: item.storagePurpose
            }, null, 8, ["label", "value"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["onChange"]),
      ($setup.filesCount || $setup.foldersCount)
        ? (_openBlock(), _createElementBlock("h4", _hoisted_3, _toDisplayString(`(${$setup.foldersCount} ${_ctx.$t('folders')}, ${$setup.filesCount} ${_ctx.$t('files')})`), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_router_view)
    ])
  ], 64))
}