import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_row = _resolveComponent("el-row")!
  const _component_gain_import_files_list_card = _resolveComponent("gain-import-files-list-card")!
  const _component_gain_import_files_sql_card = _resolveComponent("gain-import-files-sql-card")!
  const _component_import_configuration_versions_modal = _resolveComponent("import-configuration-versions-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createElementVNode("span", {
          class: "body-1 cursor-pointer",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.openVersionModal && $setup.openVersionModal(...args)))
        }, _toDisplayString(_ctx.$t('currentVersion') + ': ' + $setup.gainImportConfiguration.version), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_el_row, { class: "mt-2 mb-2" }, {
      default: _withCtx(() => [
        ($setup.localFileLogicType == $setup.FileLogicType.BooleanLogic)
          ? (_openBlock(), _createBlock(_component_gain_import_files_list_card, {
              key: 0,
              disabled: !$setup.userCanWriteEngagementGainImportConfiguration || $setup.isSignedOff,
              "file-confgurations": $setup.localFileConfigurations,
              onChangeLogicType: $setup.handleChangeLogicType,
              onSave: $setup.saveFileConfigurations
            }, null, 8, ["disabled", "file-confgurations", "onChangeLogicType", "onSave"]))
          : _createCommentVNode("", true),
        ($setup.localFileLogicType == $setup.FileLogicType.SqlLogic)
          ? (_openBlock(), _createBlock(_component_gain_import_files_sql_card, {
              key: 1,
              "batch-files": $setup.gainBatchFiles,
              disabled: !$setup.userCanWriteEngagementGainImportConfiguration || $setup.isSignedOff,
              "sql-confguration": $setup.localSqlConfiguration,
              "staging-files": $setup.gainStagingFiles,
              onChangeLogicType: $setup.handleChangeLogicType,
              onSave: $setup.saveSqlConfiguration,
              onTest: $setup.testSqlConfiguration
            }, null, 8, ["batch-files", "disabled", "sql-confguration", "staging-files", "onChangeLogicType", "onSave", "onTest"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_import_configuration_versions_modal, {
      modelValue: $setup.showVersionModal,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.showVersionModal) = $event)),
      "configuration-versions": $setup.gainImportConfigurationHistory,
      "disable-restore": 
      !$setup.userCanWriteEngagementGainImportConfiguration || $setup.isSignedOff
    ,
      onRestore: $setup.handleRestore,
      onClose: _cache[2] || (_cache[2] = ($event: any) => ($setup.showVersionModal = false))
    }, null, 8, ["modelValue", "configuration-versions", "disable-restore", "onRestore"])
  ], 64))
}