import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-09522f4a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex align-items-center" }
const _hoisted_2 = { class: "mt-4" }
const _hoisted_3 = { class: "pl-0 d-flex width-100" }
const _hoisted_4 = { class: "property-text body-1 width-50" }
const _hoisted_5 = { class: "property-text body-1 width-50" }
const _hoisted_6 = ["src", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_plus = _resolveComponent("plus")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!
  const _component_base_modal = _resolveComponent("base-modal")!

  return (_openBlock(), _createBlock(_component_base_modal, {
    "modal-title": _ctx.$t('properties'),
    "confirm-text": _ctx.$t('save'),
    "dialog-visible": $props.dialogVisible,
    width: "500px",
    onConfirm: $setup.save,
    onClose: $setup.close
  }, {
    content: _withCtx(() => [
      _createElementVNode("h4", null, _toDisplayString(_ctx.$t('addNewProperty')), 1),
      _createVNode(_component_el_form, {
        ref: "newPropertyFormRef",
        model: $setup.newPropertyForm,
        rules: $setup.newPropertyFormRules,
        "label-position": "top"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_el_form_item, {
              prop: "name",
              class: "mb-0 flex-grow-1"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: $setup.newPropertyForm.name,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.newPropertyForm.name) = $event)),
                  size: "small",
                  placeholder: _ctx.$t('name')
                }, null, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              prop: "value",
              class: "mb-0 ml-1 flex-grow-1"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: $setup.newPropertyForm.value,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.newPropertyForm.value) = $event)),
                  size: "small",
                  placeholder: _ctx.$t('value')
                }, null, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, { class: "mb-0" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_button, {
                  class: "icon-button p-1 cursor-pointer",
                  onClick: $setup.addProperty
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_plus, { class: "icon-size-8" })
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      }, 8, ["model", "rules"]),
      _createElementVNode("h4", _hoisted_2, _toDisplayString(_ctx.$t('properties')), 1),
      ($setup.localProperties.length)
        ? (_openBlock(), _createBlock(_component_el_form, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, null, {
                default: _withCtx(() => [
                  _createElementVNode("ul", _hoisted_3, [
                    _createVNode(_component_el_scrollbar, {
                      "max-height": "500px",
                      "wrap-class": "pl-2 pr-2 pb-2"
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.localProperties, (property, index) => {
                          return (_openBlock(), _createElementBlock("li", {
                            key: property.name,
                            class: "d-flex"
                          }, [
                            _createElementVNode("span", _hoisted_4, _toDisplayString(property.name), 1),
                            _createElementVNode("span", _hoisted_5, _toDisplayString(property.value), 1),
                            _createElementVNode("img", {
                              src: require(`@/assets/images/icons/new/delete.svg`),
                              class: "ml-auto clickable icon-size-7",
                              onClick: ($event: any) => ($setup.removeProperty(index))
                            }, null, 8, _hoisted_6)
                          ]))
                        }), 128))
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["modal-title", "confirm-text", "dialog-visible", "onConfirm", "onClose"]))
}