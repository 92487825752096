import {
  createApiBffClient,
  createGainBffClient,
  createTaskSchedulerApiClient
} from '@/services/api';
import { LayerName } from '@etp/etp-pipelines-client/axios';
import { ActionContext, Module } from 'vuex';
import PaginationParams from '@/services/web-api/dto/paginationParams';
import {
  CreateOrUpdateTaskDto,
  ScheduledTaskDto,
  ScheduledTaskDtoPaginatedModel
} from '@etp/etp-taskscheduler-client/axios';
import { PipelinesStoreModule, PipelinesStoreState } from '@/store/pipelines';
import { GainImportConfiguration } from '@etp/etp-gain-bff-client/axios';
import { ImportFilesDto } from '@etp/etp-bff-client/axios';

const getDefaultState = () => {
  return {
    ...new PipelinesStoreModule(LayerName.ImportLayer).state,
    gainImportConfiguration: {} as GainImportConfiguration,
    gainImportConfigurationHistory: new Array<GainImportConfiguration>()
  };
};

interface ImportState extends PipelinesStoreState {
  gainImportConfiguration: GainImportConfiguration;
  gainImportConfigurationHistory: Array<GainImportConfiguration>;
  tasks: Array<ScheduledTaskDto>;
  tasksCount: number;
  tasksPaginationParams: PaginationParams;
  tasksTypeFilter: string;
  tasksStatusFilter: string;
  loadingTasks: boolean;
}

const pipelinesStore = new PipelinesStoreModule(LayerName.ImportLayer);

class ImportModule implements Module<ImportState, any> {
  namespaced = true;
  state = {
    ...pipelinesStore.state,
    gainImportConfiguration: {} as GainImportConfiguration,
    gainImportConfigurationHistory: new Array<GainImportConfiguration>(),
    tasks: new Array<ScheduledTaskDto>(),
    tasksCount: 0,
    tasksPaginationParams: new PaginationParams(1, 10),
    tasksTypeFilter: '',
    tasksStatusFilter: '',
    loadingTasks: false
  };
  getters = {
    ...pipelinesStore.getters,
    getGainImportConfiguration: (state: ImportState): GainImportConfiguration =>
      state.gainImportConfiguration,
    getGainImportConfigurationHistory: (
      state: ImportState
    ): Array<GainImportConfiguration> => state.gainImportConfigurationHistory,
    getTasks: (state: ImportState): Array<ScheduledTaskDto> => state.tasks,
    getTasksCount: (state: ImportState): number => state.tasksCount,
    getTasksPaginationParams: (state: ImportState): PaginationParams =>
      state.tasksPaginationParams,
    getTasksStatusFilter: (state: ImportState): string =>
      state.tasksStatusFilter,
    loadingTasks: (state: ImportState): boolean => state.loadingTasks
  };
  actions = {
    ...pipelinesStore.actions,
    async importFiles(
      context: ActionContext<ImportState, any>,
      payload: {
        storagePurpose: string;
        path: Array<string>;
        fileNames: Array<string>;
      }
    ) {
      let engagementId = context.rootState.home.currentEngagement.id;
      let client = createApiBffClient();

      await client.importFilesToDb(
        engagementId,
        payload.storagePurpose,
        payload.path.join('/'),
        {
          fileNames: payload.fileNames
        } as ImportFilesDto
      );
    },
    async fetchTasks(
      context: ActionContext<ImportState, any>,
      enableLoadingState = true
    ) {
      if (enableLoadingState) context.commit('SET_LOADING_TASKS', true);
      let engagementId = context.rootState.home.currentEngagement.id;
      const { data: tasksData, status } =
        await createTaskSchedulerApiClient().getTasks(
          engagementId,
          context.state.tasksStatusFilter,
          context.state.tasksTypeFilter,
          context.state.tasksPaginationParams.page.toString()
        );
      if (status == 200) {
        context.commit('SET_TASKS', tasksData);
      } else {
        context.commit('SET_TASKS', {});
      }
      context.commit('SET_LOADING_TASKS', false);
    },
    async createTask(
      context: ActionContext<ImportState, any>,
      payload: CreateOrUpdateTaskDto
    ) {
      let engagementId = context.rootState.home.currentEngagement.id;
      await createTaskSchedulerApiClient().createTask(engagementId, payload);
    },
    async approveTask(
      context: ActionContext<ImportState, any>,
      payload: string
    ) {
      let engagementId = context.rootState.home.currentEngagement.id;
      await createTaskSchedulerApiClient().approveTask(engagementId, payload);
    },
    async disapproveTask(
      context: ActionContext<ImportState, any>,
      payload: string
    ) {
      let engagementId = context.rootState.home.currentEngagement.id;
      await createTaskSchedulerApiClient().disapproveTask(
        engagementId,
        payload
      );
    },
    async updateTask(
      context: ActionContext<ImportState, any>,
      input: {
        taskId: string;
        payload: CreateOrUpdateTaskDto;
      }
    ) {
      await createTaskSchedulerApiClient().updateTask(
        context.rootState.home.currentEngagement.id,
        input.taskId,
        input.payload
      );
    },
    async fetchGainImportConfiguration(
      context: ActionContext<ImportState, any>
    ) {
      let engagementId = context.rootState.home.currentEngagement.id;
      const { data, status } =
        await createGainBffClient().getLatestImportConfiguration(engagementId);
      if (status == 200) {
        context.commit('SET_GAIN_IMPORT_CONFIGURATION', data);
      } else {
        context.commit('SET_GAIN_IMPORT_CONFIGURATION', {});
      }
    },
    async fetchGainImportConfigurationHistory(
      context: ActionContext<ImportState, any>
    ) {
      let engagementId = context.rootState.home.currentEngagement.id;
      const { data, status } =
        await createGainBffClient().listImportConfigurations(engagementId);
      if (status == 200) {
        context.commit('SET_GAIN_IMPORT_CONFIGURATION_HISTORY', data);
      } else {
        context.commit(
          'SET_GAIN_IMPORT_CONFIGURATION_HISTORY',
          new Array<GainImportConfiguration>()
        );
      }
    },
    async updateGainImportConfiguration(
      context: ActionContext<ImportState, any>,
      payload: GainImportConfiguration
    ) {
      let engagementId = context.rootState.home.currentEngagement.id;
      await createGainBffClient().setImportConfiguration(engagementId, payload);
    }
  };
  mutations = {
    ...pipelinesStore.mutations,
    SET_GAIN_IMPORT_CONFIGURATION(
      state: ImportState,
      payload: GainImportConfiguration
    ) {
      state.gainImportConfiguration = payload;
    },
    SET_GAIN_IMPORT_CONFIGURATION_HISTORY(
      state: ImportState,
      payload: Array<GainImportConfiguration>
    ) {
      state.gainImportConfigurationHistory = payload;
    },
    SET_TASKS(state: ImportState, payload: ScheduledTaskDtoPaginatedModel) {
      state.tasks = payload.items ?? [];
      state.tasksCount = payload.itemCount ?? 0;
    },
    SET_TASKS_PAGINATION_PARAMS(state: ImportState, payload: PaginationParams) {
      state.tasksPaginationParams = payload;
    },
    SET_TASKS_TYPE_FILTER(state: ImportState, payload: string) {
      state.tasksTypeFilter = payload;
    },
    SET_TASKS_STATUS_FILTER(state: ImportState, payload: string) {
      state.tasksStatusFilter = payload;
    },
    SET_LOADING_TASKS(state: ImportState, payload: boolean) {
      state.loadingTasks = payload;
    },
    RESET_STATE(state: ImportState) {
      Object.assign(state, getDefaultState());
    }
  };
}

export default new ImportModule();
