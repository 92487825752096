import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_base_modal = _resolveComponent("base-modal")!

  return (_openBlock(), _createBlock(_component_base_modal, {
    "modal-title": _ctx.$t('engagement'),
    "dialog-visible": $props.modelValue,
    "confirm-text": _ctx.$t('save'),
    width: "400px",
    onClose: _cache[2] || (_cache[2] = ($event: any) => ($setup.close())),
    onConfirm: _cache[3] || (_cache[3] = ($event: any) => ($setup.confirm()))
  }, {
    content: _withCtx(() => [
      _createVNode(_component_el_form, {
        ref: "formRef",
        model: $setup.form,
        rules: $setup.formRules,
        "label-position": "top"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, {
            label: _ctx.$t('name'),
            prop: "name"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: $setup.form.name,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.form.name) = $event)),
                size: "small"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_form_item, {
            label: _ctx.$t('tenant'),
            prop: "tenantId"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_select, {
                modelValue: $setup.form.tenantId,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.form.tenantId) = $event)),
                size: "small",
                "value-key": "id"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.tenants, (tenant) => {
                    return (_openBlock(), _createBlock(_component_el_option, {
                      key: tenant.id ?? '',
                      label: tenant.name ?? '',
                      value: tenant.id ?? ''
                    }, null, 8, ["label", "value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["label"])
        ]),
        _: 1
      }, 8, ["model", "rules"])
    ]),
    _: 1
  }, 8, ["modal-title", "dialog-visible", "confirm-text"]))
}