<template>
  <el-form :model="form">
    <el-form-item prop="deployContainerRegistryPrivateEndpoint" class="mb-0">
      <el-checkbox
        class="checkbox"
        :label="$t('deployContainerRegistryPrivateEndpoint')"
        :model-value="form.deployContainerRegistryPrivateEndpoint"
        @change="handleChangedeployContainerRegistryPrivateEndpoint"
      />
    </el-form-item>
    <el-form-item :label="$t('managedIdentityName')" class="mb-0">
      <el-select
        v-model="form.managedIdentityName"
        size="small"
        :placeholder="$t('empty')"
        @change="handleChangeManagedIdentityName"
      >
        <el-option
          v-for="item in managedIdentityNames"
          :key="item"
          :label="item"
          :value="item"
        />
      </el-select>
    </el-form-item>
    <el-form-item :label="$t('AzureContainerRegistryTier')" class="mb-0">
      <el-select
        v-model="form.skuType"
        size="small"
        :placeholder="$t('empty')"
        @change="handleChangeSkuType"
      >
        <el-option
          v-for="item in skuTypeArray"
          :key="item"
          :label="item"
          :value="item"
        ></el-option>
      </el-select>
    </el-form-item>
  </el-form>
</template>
<script lang="ts">
import {
  ElCheckbox,
  ElForm,
  ElFormItem,
  ElSelect,
  ElOption
} from 'element-plus';
import { ContainerRegistryConfiguration } from '@/views/admin/tenants/models/TenantAzureConfigurationObjects';
import { onMounted, onUpdated, ref } from 'vue';

export default {
  name: 'ContainerRegistryConfigurationCard',
  components: { ElCheckbox, ElForm, ElFormItem, ElSelect, ElOption },
  props: {
    configuration: { type: String, default: '' },
    managedIdentityNames: {
      type: Array as () => Array<string>,
      default: () => new Array<string>()
    }
  },
  emits: ['update-configuration'],
  setup(props, { emit }) {
    let form = ref(new ContainerRegistryConfiguration());
    let localConfigurationObject = ref({});
    const skuTypeArray = ['Basic', 'Standard', 'Premium'];

    const emitUpdateEvent = () => {
      emit(
        'update-configuration',
        JSON.stringify({
          ...localConfigurationObject.value,
          ...form.value
        })
      );
    };

    const handleChangedeployContainerRegistryPrivateEndpoint = (
      newValue: boolean
    ) => {
      form.value.deployContainerRegistryPrivateEndpoint = newValue;
      emitUpdateEvent();
    };

    const handleChangeManagedIdentityName = (newValue: string) => {
      form.value.managedIdentityName = newValue;
      emitUpdateEvent();
    };

    const handleChangeSkuType = (newValue: string) => {
      form.value.skuType = newValue;
      emitUpdateEvent();
    };

    const updateLocalVars = () => {
      localConfigurationObject.value = JSON.parse(props.configuration || '{}');
      form.value.deployContainerRegistryPrivateEndpoint =
        localConfigurationObject.value[
          'deployContainerRegistryPrivateEndpoint'
        ] ?? false;
      form.value.managedIdentityName =
        localConfigurationObject.value['managedIdentityName'] ?? '';
      form.value.skuType = localConfigurationObject.value['skuType'] ?? '';
    };

    onMounted(() => {
      updateLocalVars();
      emitUpdateEvent();
    });
    onUpdated(() => updateLocalVars());

    return {
      form,
      handleChangedeployContainerRegistryPrivateEndpoint,
      handleChangeManagedIdentityName,
      handleChangeSkuType,
      skuTypeArray
    };
  }
};
</script>
<style scoped lang="scss"></style>
