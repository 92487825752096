import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-auto" }
const _hoisted_2 = { class: "body-1" }
const _hoisted_3 = { class: "body-1" }
const _hoisted_4 = ["src", "onClick"]
const _hoisted_5 = { class: "d-flex align-items-center" }
const _hoisted_6 = ["src", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_table_placeholder_loading = _resolveComponent("table-placeholder-loading")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_base_toggle_button = _resolveComponent("base-toggle-button")!
  const _component_add_application_role_assignment_modal = _resolveComponent("add-application-role-assignment-modal")!
  const _component_base_confirmation_modal = _resolveComponent("base-confirmation-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_row, { class: "d-flex" }, {
      default: _withCtx(() => [
        _createElementVNode("h4", _hoisted_1, _toDisplayString($setup.engagementApplications.length) + " · " + _toDisplayString(_ctx.$t('applications')), 1),
        ($setup.userCanWriteEngagementAuthorization)
          ? (_openBlock(), _createBlock(_component_el_button, {
              key: 0,
              disabled: $setup.loading,
              class: "ml-auto",
              type: "primary",
              onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.showAddRoleAssignmentModal = true))
            }, {
              default: _withCtx(() => [
                _createElementVNode("h4", null, _toDisplayString(_ctx.$t('addApplication')), 1)
              ]),
              _: 1
            }, 8, ["disabled"]))
          : _createCommentVNode("", true),
        ($setup.userCanWriteEngagementRbac)
          ? (_openBlock(), _createBlock(_component_el_button, {
              key: 1,
              disabled: $setup.loading,
              type: "primary",
              onClick: $setup.applyApplicationsRbac
            }, {
              default: _withCtx(() => [
                _createElementVNode("h4", null, _toDisplayString(_ctx.$t('syncRoles')), 1)
              ]),
              _: 1
            }, 8, ["disabled", "onClick"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    ($setup.loading)
      ? (_openBlock(), _createBlock(_component_table_placeholder_loading, {
          key: 0,
          columns: 4
        }))
      : (_openBlock(), _createBlock(_component_el_table, {
          key: 1,
          data: $setup.engagementApplications
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_table_column, { width: "200" }, {
              header: _withCtx(() => [
                _createTextVNode(_toDisplayString($setup.showObjectId ? _ctx.$t('objectId') : _ctx.$t('name')), 1)
              ]),
              default: _withCtx((scope) => [
                _createTextVNode(_toDisplayString($setup.showObjectId ? scope.row.objectId : scope.row.name), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_table_column, null, {
              header: _withCtx(() => [
                _createVNode(_component_el_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_col, { span: 6 }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('role')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_col, { span: 17 }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('scope')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              default: _withCtx((scope) => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(scope.row.roleAssignments.sort($setup.compareRoleAssignments) as Array<RoleAssignmentModel>, (roleAssignment) => {
                  return (_openBlock(), _createBlock(_component_el_row, {
                    key: roleAssignment.roleName
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_col, { span: 6 }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", _hoisted_2, _toDisplayString(roleAssignment.roleName), 1)
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_el_col, { span: 17 }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", _hoisted_3, _toDisplayString(roleAssignment.scope), 1)
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_el_col, {
                        span: 1,
                        class: "d-flex align-items-center"
                      }, {
                        default: _withCtx(() => [
                          ($setup.userCanWriteEngagementAuthorization)
                            ? (_openBlock(), _createElementBlock("img", {
                                key: 0,
                                src: require(`@/assets/images/icons/new/delete.svg`),
                                height: "12",
                                width: "12",
                                class: "ml-auto action-icon",
                                onClick: ($event: any) => ($setup.openRemoveRoleAssignmentModal(roleAssignment))
                              }, null, 8, _hoisted_4))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            }),
            ($setup.userCanWriteEngagementAuthorization)
              ? (_openBlock(), _createBlock(_component_el_table_column, {
                  key: 0,
                  width: "55"
                }, {
                  default: _withCtx((scope) => [
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("img", {
                        src: require(`@/assets/images/icons/bin.svg`),
                        class: "icon-size-7 cursor-pointer",
                        onClick: ($event: any) => ($setup.removeApplication(scope.row))
                      }, null, 8, _hoisted_6)
                    ])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["data"])),
    _createVNode(_component_el_row, { class: "d-flex" }, {
      default: _withCtx(() => [
        _createVNode(_component_base_toggle_button, {
          modelValue: $setup.showObjectId,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.showObjectId) = $event)),
          "active-text": _ctx.$t('objectId'),
          "inactive-text": _ctx.$t('name'),
          class: "ml-auto"
        }, null, 8, ["modelValue", "active-text", "inactive-text"])
      ]),
      _: 1
    }),
    _createVNode(_component_add_application_role_assignment_modal, {
      "modal-visible": $setup.showAddRoleAssignmentModal,
      applications: $setup.allApplications,
      roles: $setup.allRoles,
      scope: $setup.GetScopeForEngagement($setup.engagementId),
      onConfirm: $setup.addRoleAssignments,
      onClose: _cache[2] || (_cache[2] = ($event: any) => ($setup.showAddRoleAssignmentModal = false))
    }, null, 8, ["modal-visible", "applications", "roles", "scope", "onConfirm"]),
    _createVNode(_component_base_confirmation_modal, {
      modelValue: $setup.showRemoveRoleAssignmentModal,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.showRemoveRoleAssignmentModal) = $event)),
      content: _ctx.$t('confirmRemoveUserFromEngagement'),
      "feedback-text": _ctx.$t('engagementApplicationDeleted'),
      onConfirm: $setup.removeRoleAssignment,
      onClose: _cache[4] || (_cache[4] = ($event: any) => ($setup.showRemoveRoleAssignmentModal = false))
    }, null, 8, ["modelValue", "content", "feedback-text", "onConfirm"])
  ], 64))
}