import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tree = _resolveComponent("el-tree")!

  return (_openBlock(), _createBlock(_component_el_tree, {
    ref: "treeRef",
    data: $props.allNodes,
    "show-checkbox": "",
    "node-key": "id",
    "default-checked-keys": $props.selectedNodes,
    "default-expanded-keys": $props.selectedNodes,
    onCheck: $setup.handleChange
  }, null, 8, ["data", "default-checked-keys", "default-expanded-keys", "onCheck"]))
}