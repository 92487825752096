<template>
  <page-header :main-title="$t('testing')">
    <template #tabs>
      <div class="d-flex mt-5">
        <router-link
          v-if="userCanReadEngagementNodeModelGit && showNodeModelGitPages"
          v-slot="{ isActive }"
          :to="{ name: 'TestingConfig' }"
        >
          <base-tab :active="isActive">
            <h4>{{ $t('configure') }}</h4>
          </base-tab>
        </router-link>
        <router-link
          v-if="
            userCanReadEngagementNodeModelGit &&
            showNodeModelGitPages &&
            !isGainEngagement
          "
          v-slot="{ isActive }"
          :to="{ name: 'TestingBuild' }"
        >
          <base-tab :active="isActive">
            <h4>{{ $t('build') }}</h4>
          </base-tab>
        </router-link>
        <router-link
          v-if="showPipelinePages && !isGainEngagement"
          v-slot="{ isActive }"
          :to="{ name: 'TestingRunOverview' }"
        >
          <base-tab :active="isActive">
            <h4>{{ $t('run') }}</h4>
          </base-tab>
        </router-link>
        <router-link
          v-if="showPipelinePages && !isGainEngagement"
          v-slot="{ isActive }"
          :to="{ name: 'TestingMonitorOverview' }"
        >
          <base-tab :active="isActive">
            <h4>{{ $t('monitor') }}</h4>
          </base-tab>
        </router-link>
        <div class="horizontal-line mt-2">
          <hr />
        </div>
      </div>
    </template>
    <template #subheader>
      <div class="mt-3">
        <h4 v-if="routerName == 'OldTestingConfig'" class="subtitle-2">
          {{ $t('testingConfigDesc') }}
        </h4>
        <h4 v-if="routerName == 'TestingConfig'" class="subtitle-2">
          {{ $t('testingConfigDesc') }}
        </h4>
        <h4
          v-if="
            routerName == 'TestingBuild' ||
            routerName == 'OldTestingBuildingOverview'
          "
          class="subtitle-2"
        >
          {{ $t('testingBuildingDesc') }}
        </h4>
        <h4 v-if="routerName == 'TestingRunOverview'" class="subtitle-2">
          {{ $t('testingRunOverviewDesc') }}
        </h4>
        <h4 v-if="routerName == 'TestingMonitorOverview'" class="subtitle-2">
          {{ $t('monitorTabDesc') }}
        </h4>
      </div>
    </template>
  </page-header>
  <div class="mt-6">
    <router-view />
  </div>
</template>
<script lang="ts">
import PageHeader from '@/components/misc/PageHeader.vue';
import BaseTab from '@/components/tabs/BaseTab.vue';
import { ref, computed, ComputedRef, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
export default {
  name: 'TestingOverview',
  components: { PageHeader, BaseTab },
  setup() {
    const router = useRouter();
    const store = useStore();
    let routerName = ref(router.currentRoute.value.name);

    const showNodeModelApiPages: ComputedRef<boolean> = computed(
      () => store.getters['home/showNodeModelApiPages']
    );
    const showNodeModelGitPages: ComputedRef<boolean> = computed(
      () => store.getters['home/showNodeModelGitPages']
    );
    const showPipelinePages: ComputedRef<boolean> = computed(
      () => store.getters['home/showPipelinePages']
    );
    const userCanReadEngagementNodeModelGit: ComputedRef<boolean> = computed(
      () => store.getters['userPermissions/canReadEngagementNodeModelGit']
    );
    const isGainEngagement: ComputedRef<boolean> = computed(
      () => store.getters['home/isGainEngagement']
    );

    onMounted(async () => {
      await store.dispatch(
        'userPermissions/checkIfCanReadEngagementNodeModelGit'
      );
      if (isGainEngagement.value) {
        router.push({ name: 'TestingConfig' });
      }
    });

    return {
      isGainEngagement,
      routerName,
      showNodeModelApiPages,
      showNodeModelGitPages,
      showPipelinePages,
      userCanReadEngagementNodeModelGit
    };
  }
};
</script>
<style scoped lang="scss"></style>
