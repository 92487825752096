import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fd72fa8c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mt-4" }
const _hoisted_2 = { "data-test-id": "collaboration-branch-name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_base_card = _resolveComponent("base-card")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!
  const _component_base_modal = _resolveComponent("base-modal")!

  return (_openBlock(), _createBlock(_component_base_modal, {
    "modal-title": _ctx.$t('publishPipelines'),
    "dialog-visible": $props.showModal,
    "has-confirmation-button": false,
    width: "400px",
    "data-test-id": "publish-pipelines-modal",
    onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
  }, {
    content: _withCtx(() => [
      _createElementVNode("h4", _hoisted_1, _toDisplayString(_ctx.$t('repositories')), 1),
      _createVNode(_component_el_scrollbar, {
        "max-height": "500px",
        "wrap-class": "pl-2 pr-2 pb-2"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.collaborationBranches, (collaborationBranch, index) => {
            return (_openBlock(), _createBlock(_component_base_card, {
              key: index,
              "no-header": "",
              "data-test-id": "collaboration-branch-list-item",
              class: "mb-1 mt-1 smaller-card"
            }, {
              body: _withCtx(() => [
                _createVNode(_component_el_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_col, {
                      class: "d-flex align-items-center",
                      span: 18
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_2, _toDisplayString(collaborationBranch.parentRepositoryName), 1)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_el_col, { span: 6 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_button, {
                          class: "ml-auto",
                          type: "primary",
                          "data-test-id": "open-button",
                          onClick: ($event: any) => ($setup.openLink(collaborationBranch.branchUrl))
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("h4", null, _toDisplayString(_ctx.$t('open')), 1)
                          ]),
                          _: 2
                        }, 1032, ["onClick"])
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modal-title", "dialog-visible"]))
}