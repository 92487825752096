<template>
  <el-row class="d-flex">
    <el-button
      class="ml-auto"
      type="primary"
      @click="showNewConfigurationModal = true"
    >
      <h4>{{ $t('new') }}</h4>
    </el-button>
    <el-button class="ml-2" type="primary" @click="saveConfigurations">
      <h4>{{ $t('save') }}</h4>
    </el-button>
  </el-row>
  <el-row
    v-for="(item, index) in localConfigurations"
    :key="index"
    class="d-block mt-1"
  >
    <storage-configuration-card
      :configuration="item"
      :resources="tenantResources"
      @update="(payload: CreateOrUpdateConfigurationDto) => handleConfigurationUpdate(index, payload)"
      @delete="handleConfigurationDelete(index)"
    />
  </el-row>
  <new-storage-configuration-modal
    :model-value="showNewConfigurationModal"
    :existing-purposes="localConfigurations.map(el => el.storagePurpose)"
    @close="showNewConfigurationModal = false"
    @confirm="handleConfigurationCreate"
  />
</template>
<script lang="ts">
import { ElButton, ElMessage, ElRow } from 'element-plus';
import NewStorageConfigurationModal from './components/NewStorageConfigurationModal.vue';
import StorageConfigurationCard from '@/views/admin/engagements/components/StorageConfigurationCard.vue';
import {
  CreateOrUpdateConfigurationDto,
  EngagementStorageConfiguration,
  UpdateConfigurationDto
} from '@etp/etp-storage-client/axios';
import { computed, ComputedRef, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { ResourceModel } from '@etp/etp-infra-client';
import i18n from '@/i18n';

export default {
  name: 'EngagementStorage',
  components: {
    ElButton,
    ElRow,
    NewStorageConfigurationModal,
    StorageConfigurationCard
  },
  setup() {
    let engagementId = '';
    const store = useStore();
    const router = useRouter();

    const localConfigurations = ref(
      new Array<EngagementStorageConfiguration>()
    );
    const showNewConfigurationModal = ref(false);

    const tenantResources: ComputedRef<Array<ResourceModel>> = computed(
      () => store.getters['tenants/getResources']
    );

    const handleConfigurationCreate = async (name: string) => {
      localConfigurations.value.push({
        storagePurpose: name
      } as EngagementStorageConfiguration);
      showNewConfigurationModal.value = false;
    };

    const handleConfigurationDelete = async (index: number) => {
      localConfigurations.value.splice(index, 1);
    };

    const handleConfigurationUpdate = async (
      index: number,
      payload: CreateOrUpdateConfigurationDto
    ) => {
      localConfigurations.value[index].configuration = payload.configuration;
      localConfigurations.value[index].displayName = payload.displayName;
      localConfigurations.value[index].implementation = payload.implementation;
    };

    const saveConfigurations = async () => {
      let statusCode = await store.dispatch(
        'engagements/updateStorageConfigurations',
        {
          engagementId,
          configurations: localConfigurations.value.map(el => {
            return {
              configuration: el.configuration,
              displayName: el.displayName,
              implementation: el.implementation,
              storagePurpose: el.storagePurpose
            } as UpdateConfigurationDto;
          })
        }
      );
      ElMessage({
        message: i18n.global.t(
          statusCode > 300
            ? 'storageConfigurationSaveFailure'
            : 'storageConfigurationSaveSuccess'
        )
      });
      await fetchConfigurations();
    };

    const fetchConfigurations = async () => {
      await store.dispatch(
        'engagements/fetchStorageConfigurations',
        engagementId
      );
      localConfigurations.value = [
        ...store.getters['engagements/getStorageConfigurations']
      ];
    };

    onMounted(async () => {
      engagementId = router.currentRoute.value.params.engagementId as string;
      await store.dispatch('engagements/fetchEngagement', engagementId);
      let tenantId = store.getters['engagements/getActiveEngagement'].tenantId;
      await store.dispatch('tenants/fetchResources', { tenantId });
      await fetchConfigurations();
    });

    return {
      handleConfigurationCreate,
      handleConfigurationDelete,
      handleConfigurationUpdate,
      localConfigurations,
      saveConfigurations,
      showNewConfigurationModal,
      tenantResources
    };
  }
};
</script>
