interface EtpIntervalShape {
  key: string;
  interval: number;
}

let etpIntervals: Array<EtpIntervalShape> = new Array<EtpIntervalShape>();

// eslint-disable-next-line
export function setEtpInterval(key: string, callBack: Function, ms: number) {
  const interval: EtpIntervalShape = {
    key: key,
    interval: setInterval(async () => callBack(), ms)
  };

  etpIntervals.push(interval);
}

export function destroyEtpInterval(key: string) {
  const targetIndex = etpIntervals.findIndex(x => x.key == key);

  if (targetIndex == -1) return;

  const targetInterval = etpIntervals[targetIndex];

  clearInterval(targetInterval.interval);
  etpIntervals.splice(targetIndex, 1);
}
