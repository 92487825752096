import { ActionContext, Module } from 'vuex';
import {
  createCostManagementClient,
  createInfraApiClient
} from '@/services/api';
import {
  CloudCostsForTenantDto,
  GroupedResourceCloudCostsDto
} from '@etp/etp-infra-client';
import { AutopauseExclusionInput } from '@etp/etp-costsaving-client/axios';
import { HttpStatusCode } from 'axios';

interface CostsState {
  totalCosts: CloudCostsForTenantDto;
  resourceCosts: Array<GroupedResourceCloudCostsDto>;
  loading: boolean;
}

class CostsModule implements Module<CostsState, any> {
  namespaced = true;
  state = {
    totalCosts: {} as CloudCostsForTenantDto,
    resourceCosts: new Array<GroupedResourceCloudCostsDto>(),
    loading: false
  };
  getters = {
    getTotalCosts: (state: CostsState): CloudCostsForTenantDto => {
      return state.totalCosts;
    },
    getCostsPerResource: (
      state: CostsState
    ): Array<GroupedResourceCloudCostsDto> => {
      return state.resourceCosts;
    },
    getLoadingCosts: (state: CostsState): boolean => {
      return state.loading;
    }
  };
  actions = {
    async fetchTotalCosts(context: ActionContext<CostsState, any>) {
      const tenantId = context.rootState.home.currentEngagement.tenantId;
      let client = createInfraApiClient();
      const { data, status } = await client.getTotalCosts(tenantId);

      if (status >= 200 && status <= 299) {
        context.commit('SET_TOTAL_COSTS', data);
      } else {
        context.commit('SET_TOTAL_COSTS', {} as CloudCostsForTenantDto);
      }
    },
    async fetchCostsPerResource(context: ActionContext<CostsState, any>) {
      const tenantId = context.rootState.home.currentEngagement.tenantId;
      context.commit('SET_LOADING_COSTS', true);

      let client = createInfraApiClient();

      const { data, status } = await client.getDetailedCosts(tenantId);

      if (status >= 200 && status <= 299) {
        context.commit('SET_USAGE_COSTS', data);
      } else {
        context.commit(
          'SET_USAGE_COSTS',
          new Array<GroupedResourceCloudCostsDto>()
        );
      }
      context.commit('SET_LOADING_COSTS', false);
    },
    async stopCostSavingForEngagement(
      context: ActionContext<CostsState, any>,
      payload: AutopauseExclusionInput
    ): Promise<HttpStatusCode> {
      const engagementId = context.rootState.home.currentEngagement.id;
      if (!engagementId) return HttpStatusCode.BadRequest;

      try {
        let client = createCostManagementClient();
        const { status } = await client.setEngagementAutoPauseExclusion(
          engagementId,
          payload
        );
        return status;
      } catch (exception: any) {
        return (
          exception?.response?.status ?? HttpStatusCode.InternalServerError
        );
      }
    },
    async stopCostSavingForTenant(
      context: ActionContext<CostsState, any>,
      payload: AutopauseExclusionInput
    ): Promise<HttpStatusCode> {
      const tenantId = context.rootState.home.currentEngagement.tenantId;
      if (!tenantId) return HttpStatusCode.BadRequest;

      try {
        let client = createCostManagementClient();
        const { status } = await client.setTenantAutoPauseExclusion(
          tenantId,
          payload
        );
        return status;
      } catch (exception: any) {
        return (
          exception?.response?.status ?? HttpStatusCode.InternalServerError
        );
      }
    }
  };
  mutations = {
    SET_TOTAL_COSTS(state: CostsState, data: CloudCostsForTenantDto) {
      state.totalCosts = data;
    },
    SET_USAGE_COSTS(
      state: CostsState,
      data: Array<GroupedResourceCloudCostsDto>
    ) {
      state.resourceCosts = data;
    },
    SET_LOADING_COSTS(state: CostsState, data: boolean) {
      state.loading = data;
    }
  };
}

export default new CostsModule();
