<template>
  <el-form ref="formRef" :model="form" :rules="formRules" label-position="top">
    <el-row>
      <el-col :span="12" class="d-flex pt-1">
        <el-button
          class="ml-auto"
          type="primary"
          @click="updateEngagementGeneralInfo"
        >
          <h4>{{ $t('save') }}</h4>
        </el-button>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12" class="mt-2">
        <el-card class="card-form">
          <el-form-item :label="$t('name')" prop="name">
            <el-input v-model="form.name" size="small" />
          </el-form-item>
          <el-form-item :label="$t('engagementId')">
            <div class="body-1 form-item-readonly">
              {{ engagement.id }}
            </div>
          </el-form-item>
          <el-form-item :label="$t('tenantId')">
            <div class="body-1 form-item-readonly">
              <span
                class="tenant-hyperlink d-flex align-items-center"
                @click="redirectToTenant"
              >
                {{ engagement.tenantId }}
                <img
                  class="ml-1 icon-size-5"
                  src="@/assets/images/icons/share-26.svg"
                />
              </span>
            </div>
          </el-form-item>
          <el-form-item :label="$t('features')">
            <el-select v-model="form.selectedFeatures" multiple size="small">
              <el-option
                v-for="feature in availableFeatures"
                :key="feature"
                :label="feature"
                :value="feature"
              />
            </el-select>
          </el-form-item>
        </el-card>
      </el-col>
    </el-row>
  </el-form>
</template>
<script lang="ts">
import {
  ElButton,
  ElCard,
  ElCol,
  ElForm,
  ElFormItem,
  ElMessage,
  ElInput,
  ElOption,
  ElRow,
  ElSelect
} from 'element-plus';
import { computed, ComputedRef, onMounted, ref, reactive } from 'vue';
import { useRouter } from 'vue-router';
import store from '@/store';
import i18n from '@/i18n';
import {
  EngagementModel,
  UpdateEngagementDto
} from '@etp/etp-engagements-client/axios';
import {
  feature_dashboards_analysisServices,
  feature_dashboards_powerbi,
  feature_eInvoicing,
  feature_gain,
  feature_nodeModelApi,
  feature_nodeModelGit,
  feature_pipelines,
  feature_storage,
  feature_taskScheduler,
  feature_reporting,
  feature_workflow
} from '@/utils/variables';
import config from '@/config/config';

export default {
  name: 'EngagementGeneral',
  components: {
    ElRow,
    ElCol,
    ElButton,
    ElInput,
    ElForm,
    ElFormItem,
    ElCard,
    ElOption,
    ElSelect
  },
  setup() {
    const router = useRouter();
    let engagementId: string;

    let engagement: ComputedRef<EngagementModel> = computed(
      () => store.getters['engagements/getActiveEngagement']
    );

    const form = ref({
      name: '',
      selectedFeatures: []
    });
    const formRef = ref<HTMLFormElement>();
    const formRules = reactive({
      name: [
        {
          required: true,
          message: i18n.global.t('thisFieldIsRequired'),
          trigger: 'blur'
        },
        {
          min: 3,
          message: i18n.global
            .t('pleaseEnterNumCharacters')
            .replace('[num]', '3'),
          trigger: 'blur'
        }
      ]
    });

    let availableFeatures: string[] = [];

    if (config.cloudProvider.toLowerCase() === 'aws') {
      availableFeatures = [
        feature_gain,
        feature_nodeModelGit,
        feature_pipelines,
        feature_storage,
        feature_reporting
      ];
    } else {
      availableFeatures = [
        feature_dashboards_analysisServices,
        feature_dashboards_powerbi,
        feature_eInvoicing,
        feature_nodeModelApi,
        feature_nodeModelGit,
        feature_pipelines,
        feature_storage,
        feature_taskScheduler,
        feature_reporting,
        feature_workflow
      ];
    }

    const updateEngagementGeneralInfo = () => {
      formRef.value?.validate(async (valid: boolean) => {
        if (valid) {
          await store.dispatch('engagements/updateEngagement', {
            engagementId: engagementId,
            model: {
              name: form.value.name,
              features: JSON.stringify(form.value.selectedFeatures),
              resourceIds: engagement.value.resourceIds
            } as UpdateEngagementDto
          });
          await updateLocalVars();
          ElMessage({
            showClose: true,
            message: i18n.global.t('engagementInfoSaved')
          });
        }
      });
    };

    const updateLocalVars = async () => {
      engagementId = router.currentRoute.value.params.engagementId as string;
      await store.dispatch('engagements/fetchEngagement', engagementId);
      form.value.name = engagement.value.name ?? '';
      form.value.selectedFeatures = JSON.parse(
        engagement.value.features ?? '[]'
      );
    };

    const redirectToTenant = () => {
      router.push({
        name: 'TenantDetails',
        params: { tenantId: engagement.value.tenantId }
      });
    };

    onMounted(async () => {
      await updateLocalVars();
    });

    return {
      availableFeatures,
      form,
      formRef,
      formRules,
      engagement,
      updateEngagementGeneralInfo,
      redirectToTenant
    };
  }
};
</script>
<style lang="scss" scoped>
.tenant-hyperlink {
  &:hover {
    text-decoration: underline;
    color: $etp-deloitte-blue-5;
    cursor: pointer;
    .icon {
      transform: translateY(-0.3em);
    }
  }
}
</style>
