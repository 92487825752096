<template>
  <base-card no-header>
    <template #body>
      <el-row>
        <el-col :span="6">
          <h3>{{ $t('eInvoicing') }}</h3>
        </el-col>
        <el-col :span="12">
          <el-form ref="formRef" :model="form" :rules="formRules">
            <el-form-item
              class="mb-0"
              :label="$t('provider')"
              prop="eInvoicingProvider"
            >
              <el-input v-model="form.eInvoicingProvider" size="small" />
            </el-form-item>
            <el-form-item
              class="mb-0"
              :label="$t('storageAccountName')"
              prop="storageAccountName"
            >
              <el-select
                v-model="form.storageAccountName"
                size="small"
                :placeholder="$t('empty')"
              >
                <el-option
                  v-for="item in localStorageResources"
                  :key="item.name ?? ''"
                  :label="item.name ?? ''"
                  :value="item.name"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              class="mb-0"
              :label="$t('containerName')"
              prop="containerName"
            >
              <el-input v-model="form.containerName" size="small" />
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="6" class="d-flex">
          <el-button
            class="ml-auto"
            type="primary"
            :disabled="disabled"
            @click="save"
          >
            <h4>{{ $t('save') }}</h4>
          </el-button>
        </el-col>
      </el-row>
    </template>
  </base-card>
</template>
<script lang="ts">
import BaseCard from '@/components/panels/BaseCard.vue';
import { mustNotBeEmpty } from '@/utils/formValidators';
import { onUpdated, reactive, ref } from 'vue';
import {
  ElButton,
  ElCol,
  ElForm,
  ElFormItem,
  ElInput,
  ElOption,
  ElRow,
  ElSelect
} from 'element-plus';
import { ResourceType, ResourceModel } from '@etp/etp-infra-client';
import { EInvoicingConfigurationDetails } from '@etp/etp-einvoicing-client/axios';

export default {
  name: 'EInvoicingConfigurationCard',
  components: {
    BaseCard,
    ElButton,
    ElCol,
    ElForm,
    ElFormItem,
    ElInput,
    ElOption,
    ElRow,
    ElSelect
  },
  props: {
    configuration: {
      type: Object as () => EInvoicingConfigurationDetails,
      default: {} as EInvoicingConfigurationDetails,
      required: true
    },
    disabled: { type: Boolean, default: false },
    resources: {
      type: Array as () => Array<ResourceModel>,
      default: () => new Array<ResourceModel>()
    }
  },
  emits: ['update'],
  setup(props, { emit }) {
    const form = reactive({
      eInvoicingProvider: '',
      storageAccountName: '',
      containerName: ''
    });
    const formRef = ref<HTMLFormElement>();
    const formRules = reactive({
      eInvoicingProvider: [
        {
          validator: mustNotBeEmpty,
          trigger: 'blur'
        }
      ],
      storageAccountName: [
        {
          validator: mustNotBeEmpty,
          trigger: 'blur'
        }
      ],
      containerName: [
        {
          validator: mustNotBeEmpty,
          trigger: 'blur'
        }
      ]
    });

    const save = () => {
      formRef.value?.validate((valid: boolean) => {
        if (valid) {
          emit('update', {
            providerName: form.eInvoicingProvider,
            storageAccountName: form.storageAccountName,
            containerName: form.containerName
          } as EInvoicingConfigurationDetails);
        }
      });
    };

    let localStorageResources = ref(new Array<ResourceModel>());

    onUpdated(() => {
      localStorageResources.value = props.resources.filter(
        el => el.resourceType == ResourceType.StorageAccount
      );
      form.eInvoicingProvider = props.configuration?.providerName ?? '';
      form.storageAccountName = props.configuration?.storageAccountName ?? '';
      form.containerName = props.configuration?.containerName ?? '';
    });

    return {
      form,
      formRef,
      formRules,
      localStorageResources,
      save
    };
  }
};
</script>
<style scoped lang="scss"></style>
