<template>
  <div>
    <el-dialog
      :title="modalTitle"
      :model-value="dialogVisible"
      :before-close="handleClose"
      :width="width"
    >
      <div class="modal-body">
        <slot name="content"></slot>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <div class="footer-slot">
            <slot name="footer"></slot>
          </div>
          <div class="footer-action">
            <el-button
              v-if="hasConfirmationButton"
              class="modal-confirm"
              type="primary"
              @click="confirm"
            >
              <h4>{{ confirmText }}</h4>
            </el-button>
          </div>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script lang="ts">
import { ElDialog, ElButton } from 'element-plus';

export default {
  name: 'BaseModal',
  components: { ElDialog, ElButton },
  props: {
    modalTitle: { type: String, default: '' },
    confirmText: { type: String, default: 'save' },
    hasConfirmationButton: { type: Boolean, default: true },
    dialogVisible: { type: Boolean, default: false },
    width: { type: String, default: '300px' }
  },
  emits: ['confirm', 'close'],
  setup(props, { emit }) {
    const confirm = () => {
      emit('confirm');
    };
    const handleClose = (): void => {
      emit('close');
    };

    return { handleClose, confirm };
  }
};
</script>

<style scoped lang="scss">
.dialog-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .modal-confirm {
    margin-left: 10px;
  }
}
.footer-slot {
  flex-grow: 1;
  margin-right: 20px;
}
</style>
