import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_table_configuration_card = _resolveComponent("table-configuration-card")!
  const _component_import_configuration_versions_modal = _resolveComponent("import-configuration-versions-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_row, { class: "d-flex" }, {
      default: _withCtx(() => [
        _createElementVNode("span", {
          class: "body-1 cursor-pointer",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.openVersionModal && $setup.openVersionModal(...args)))
        }, _toDisplayString(_ctx.$t('currentVersion') + ': ' + $setup.gainImportConfiguration.version), 1),
        _createVNode(_component_el_button, {
          class: "ml-auto",
          type: "primary",
          disabled: !$setup.userCanWriteEngagementGainImportConfiguration || $setup.isSignedOff,
          onClick: $setup.addEntry
        }, {
          default: _withCtx(() => [
            _createElementVNode("h4", null, _toDisplayString(_ctx.$t('add')), 1)
          ]),
          _: 1
        }, 8, ["disabled", "onClick"]),
        _createVNode(_component_el_button, {
          class: "ml-2",
          type: "primary",
          disabled: !$setup.userCanWriteEngagementGainImportConfiguration || $setup.isSignedOff,
          onClick: $setup.saveTableConfigurations
        }, {
          default: _withCtx(() => [
            _createElementVNode("h4", null, _toDisplayString(_ctx.$t('save')), 1)
          ]),
          _: 1
        }, 8, ["disabled", "onClick"])
      ]),
      _: 1
    }),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.gainTableConfigurations, (item, index) => {
      return (_openBlock(), _createBlock(_component_el_row, {
        key: index,
        class: "mt-1"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_table_configuration_card, {
            configuration: item,
            "disable-delete": 
        !$setup.userCanWriteEngagementGainImportConfiguration || $setup.isSignedOff
      ,
            "file-configurations": $setup.gainFileConfigurations,
            onUpdate: (configuration) => $setup.updateEntry(index, configuration),
            onDelete: ($event: any) => ($setup.deleteEntry(index))
          }, null, 8, ["configuration", "disable-delete", "file-configurations", "onUpdate", "onDelete"])
        ]),
        _: 2
      }, 1024))
    }), 128)),
    _createVNode(_component_import_configuration_versions_modal, {
      modelValue: $setup.showVersionModal,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.showVersionModal) = $event)),
      "configuration-versions": $setup.gainImportConfigurationHistory,
      "disable-restore": 
      !$setup.userCanWriteEngagementGainImportConfiguration || $setup.isSignedOff
    ,
      onRestore: $setup.handleRestore,
      onClose: _cache[2] || (_cache[2] = ($event: any) => ($setup.showVersionModal = false))
    }, null, 8, ["modelValue", "configuration-versions", "disable-restore", "onRestore"])
  ], 64))
}