<template>
  <base-modal
    :modal-title="$t('addNewSource')"
    :confirm-text="$t('save')"
    :dialog-visible="showModal"
    width="400px"
    @confirm="confirm"
    @close="$emit('close')"
  >
    <template #content>
      <el-form ref="formRef" :model="form" :rules="formRules">
        <el-form-item prop="sourceName" :label="$t('name') + ':'">
          <el-input v-model="form.sourceName" size="small" />
        </el-form-item>
      </el-form>
    </template>
  </base-modal>
</template>
<script lang="ts">
import { ElForm, ElFormItem, ElInput, ElMessage } from 'element-plus';

import BaseModal from '@/components/modals/BaseModal.vue';
import i18n from '@/i18n';
import { onUpdated, reactive, ref } from 'vue';
import { mustBeAlphanumericDashUnderscore } from '@/utils/formValidators';
import { CreateSourceModel } from '@etp/etp-nodemodelgit-client/axios';
export default {
  name: 'NewSourceModal',
  components: {
    BaseModal,
    ElInput,
    ElForm,
    ElFormItem
  },
  props: {
    showModal: { type: Boolean, default: false }
  },
  emits: ['close', 'confirm'],
  setup(props, { emit }) {
    let form = ref({
      sourceName: ''
    });
    let formRules = reactive({
      sourceName: [
        {
          validator: mustBeAlphanumericDashUnderscore,
          trigger: 'change'
        }
      ]
    });

    const formRef = ref<HTMLFormElement>();
    const confirm = () => {
      formRef.value?.validate((valid: boolean) => {
        if (!valid) return;
        emit('confirm', {
          name: form.value.sourceName
        } as CreateSourceModel);
        ElMessage({
          showClose: true,
          message: i18n.global.t('newSourceAdded')
        });
      });
    };

    onUpdated(() => {
      formRef.value?.resetFields();
    });

    return { confirm, form, formRef, formRules };
  }
};
</script>
