<template>
  <div>
    <div class="colored-circle-a size-s" />
    <div class="colored-circle-a size-m" />
    <div class="colored-circle-a size-l" />
  </div>
</template>

<script lang="ts">
export default {
  name: 'BaseCircles'
};
</script>

<style scoped lang="scss">
.size-s {
  position: absolute;
  width: 252.09px;
  height: 252.09px;
  left: -50px;
  bottom: 0px;
}
.size-m {
  position: absolute;
  width: 442px;
  height: 442px;
  right: -100.53px;
  bottom: 0px;
  transform: rotate(-180deg);
}
.size-l {
  position: absolute;
  width: 768.49px;
  height: 768.49px;
  left: -150px;
  top: -200px;
  transform: rotate(67.13deg);
}
</style>
