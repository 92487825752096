<template>
  <base-modal
    :modal-title="$t('stopCostSavingDesc')"
    :dialog-visible="modelValue"
    :confirm-text="$t('save')"
    width="400px"
    @close="$emit('update:model-value', false)"
    @confirm="confirm()"
  >
    <template #content>
      <el-form label-position="top">
        <el-row>
          <el-col :span="12">
            <el-form-item :label="$t('hours')">
              <el-input-number
                v-model="localHours"
                :min="0"
                :max="10"
                size="small"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('minutes')">
              <el-input-number
                v-model="localMinutes"
                :min="0"
                :max="60"
                size="small"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row>
        <h4 class="mt-2">
          {{
            $t('estimatedPeriodEnd') +
            ': ' +
            calculatePeriodEnd(localHours, localMinutes)
          }}
        </h4>
      </el-row>
    </template>
  </base-modal>
</template>
<script lang="ts">
import BaseModal from '@/components/modals/BaseModal.vue';
import { ElCol, ElForm, ElFormItem, ElInputNumber, ElRow } from 'element-plus';
import { ref } from 'vue';
import moment from 'moment';
import { AutopauseExclusionInput } from '@etp/etp-costsaving-client/axios';
export default {
  name: 'StopCostSavingModal',
  components: {
    BaseModal,
    ElCol,
    ElForm,
    ElFormItem,
    ElInputNumber,
    ElRow
  },
  props: {
    modelValue: { type: Boolean, default: false }
  },
  emits: ['close', 'confirm', 'update:model-value'],
  setup(props, { emit }) {
    let localHours = ref(1);
    let localMinutes = ref(0);

    let confirm = () => {
      emit('confirm', {
        hours: localHours.value,
        minutes: localMinutes.value
      } as AutopauseExclusionInput);
    };

    const calculatePeriodEnd = (hours: number, minutes: number) => {
      let date = new Date();
      date.setTime(
        date.getTime() + hours * 60 * 60 * 1000 + minutes * 60 * 1000
      );
      return moment(date).format('HH:mm');
    };

    return {
      calculatePeriodEnd,
      confirm,
      localHours,
      localMinutes
    };
  }
};
</script>
