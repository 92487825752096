import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_monitor_tab = _resolveComponent("base-monitor-tab")!

  return (_openBlock(), _createBlock(_component_base_monitor_tab, {
    "pipeline-runs": $setup.pipelineRuns,
    "sort-params": $setup.sortParams,
    "show-adf-button": $setup.userCanWriteEngagementPipelines && !$setup.isGainEngagement,
    "loading-pipeline-runs": $setup.loadingPipelineRuns,
    onUpdateSortParams: $setup.updateSortParams,
    onUpdateStatusFilter: $setup.updateStatusFilter
  }, null, 8, ["pipeline-runs", "sort-params", "show-adf-button", "loading-pipeline-runs", "onUpdateSortParams", "onUpdateStatusFilter"]))
}