<template>
  <div class="model-list-item">
    <span class="model-list-item-content">
      <div :class="['field-dot', item.status]"></div>
      <span class="body-1 model-list-item-text">{{ item.name }}</span>
    </span>
  </div>
</template>
<script lang="ts">
import { ConfigureField } from '@/services/web-api/dto/nodeModel';
export default {
  name: 'ModelListItem',
  props: {
    item: {
      type: Object as () => ConfigureField,
      default: (): ConfigureField => {
        return {
          status: 'active',
          name: 'ACCOUNT_NUMBER_BK',
          dataType: 'nvarchar(255)'
        };
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.model-list-item {
  background: $etp-deloitte-white;
  height: 24px;
  border-radius: 8px;
  display: flex;
  width: 100%;
  margin: 4px 0px 4px 0px;
}
.model-list-item-text {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 85%;
  white-space: nowrap;
}
.model-list-item-content {
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  .field-dot {
    width: 8px;
    height: 8px;
    background: $etp-deloitte-green-1;
    border-radius: 100%;
    margin: 0px 16px 0px 12px;
    &.inactive {
      background: $etp-warning-red;
    }
  }
}
</style>
