import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "body-1 ml-2 form-item-readonly" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, {
    ref: "formRef",
    model: $setup.form,
    rules: $setup.formRules,
    "label-position": "top"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_col, {
            span: 12,
            class: "d-flex pt-1"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_button, {
                class: "ml-auto",
                type: "primary",
                onClick: $setup.updateUserInfo
              }, {
                default: _withCtx(() => [
                  _createElementVNode("h4", null, _toDisplayString(_ctx.$t('save')), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_el_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_col, {
            span: 12,
            class: "mt-2"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_card, { class: "cursor-default" }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_form_item, {
                    label: _ctx.$t('name'),
                    prop: "name"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        modelValue: $setup.form.name,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.form.name) = $event)),
                        size: "small",
                        class: "body-1"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }, 8, ["label"]),
                  _createVNode(_component_el_form_item, {
                    label: _ctx.$t('jobTitle'),
                    prop: "jobTitle"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_1, _toDisplayString($setup.form.jobTitle), 1)
                    ]),
                    _: 1
                  }, 8, ["label"]),
                  _createVNode(_component_el_form_item, {
                    label: _ctx.$t('language'),
                    prop: "language"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_select, {
                        modelValue: $setup.form.language,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.form.language) = $event)),
                        size: "small"
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$i18n.availableLocales, (language, i) => {
                            return (_openBlock(), _createBlock(_component_el_option, {
                              key: `Lang${i}`,
                              label: language,
                              value: language
                            }, null, 8, ["label", "value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["modelValue"])
                    ]),
                    _: 1
                  }, 8, ["label"]),
                  _createVNode(_component_el_form_item, {
                    label: _ctx.$t('template'),
                    prop: "template"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_select, {
                        modelValue: $setup.form.template,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.form.template) = $event)),
                        size: "small"
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.templates, (template, i) => {
                            return (_openBlock(), _createBlock(_component_el_option, {
                              key: i,
                              label: template,
                              value: template
                            }, null, 8, ["label", "value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["modelValue"])
                    ]),
                    _: 1
                  }, 8, ["label"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model", "rules"]))
}