<template>
  <base-modal
    :modal-title="$t('categories')"
    :confirm-text="$t('save')"
    :dialog-visible="dialogVisible"
    width="400px"
    @confirm="confirm"
    @close="$emit('close')"
  >
    <template #content>
      <h4 class="mb-1">{{ $t('addNewCategory') }}</h4>
      <el-form
        ref="newCategoryFormRef"
        :model="newCategoryForm"
        :rules="newCategoryFormRules"
        label-position="top"
      >
        <div class="d-flex align-items-center">
          <el-form-item class="flex-grow-1 mb-0">
            <el-input
              v-model="newCategoryForm.name"
              size="small"
              :placeholder="$t('name')"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              class="icon-button p-1 cursor-pointer"
              @click="addCategory"
            >
              <plus class="icon-size-8" />
            </el-button>
          </el-form-item>
        </div>
      </el-form>
      <el-form>
        <el-form-item>
          <ul class="pl-0 width-100">
            <el-scrollbar max-height="500px">
              <li
                v-for="(category, index) in localCategories"
                :key="category"
                class="d-flex"
              >
                <span>{{ category }}</span>
                <img
                  :src="require(`@/assets/images/icons/new/delete.svg`)"
                  class="ml-auto icon-size-7"
                  @click="removeCategory(index)"
                />
              </li>
            </el-scrollbar>
          </ul>
        </el-form-item>
      </el-form>
    </template>
  </base-modal>
</template>
<script lang="ts">
import { ref, onMounted, onUpdated, reactive } from 'vue';
import BaseModal from '@/components/modals/BaseModal.vue';

import {
  ElButton,
  ElForm,
  ElFormItem,
  ElInput,
  ElScrollbar,
  ElMessage
} from 'element-plus';

import { mustNotContainSpecialChars } from '@/utils/formValidators';
import i18n from '@/i18n';
import { Plus } from '@element-plus/icons-vue';

export default {
  name: 'CategoriesModal',
  components: {
    BaseModal,
    ElButton,
    ElForm,
    ElFormItem,
    ElInput,
    ElScrollbar,
    Plus
  },
  props: {
    dialogVisible: { type: Boolean, default: false, required: true },
    categories: {
      type: Array as () => Array<string>,
      default: () => new Array<string>()
    }
  },
  emits: ['close', 'confirm'],
  setup(props, { emit }) {
    let localCategories = ref(new Array<string>());

    const localMustNotBeInArray = (rule: any, value: string, callback: any) => {
      if (
        localCategories.value.some(
          el => el.toLowerCase() == value.toLowerCase()
        )
      ) {
        callback(new Error(i18n.global.t('noDuplicateCategory')));
      } else callback();
    };

    let newCategoryForm = ref({
      name: ''
    });
    let newCategoryFormRules = reactive({
      name: [
        { validator: mustNotContainSpecialChars, trigger: 'blur' },
        { validator: localMustNotBeInArray, trigger: 'blur' }
      ]
    });
    const newCategoryFormRef = ref<HTMLFormElement>();

    const updateCategories = () => {
      localCategories.value = [...props.categories];
    };

    const confirm = () => {
      emit('confirm', localCategories.value);
      ElMessage({
        showClose: true,
        message: i18n.global.t('savingChanges')
      });
    };

    onMounted(() => updateCategories());
    onUpdated(() => updateCategories());

    const addCategory = () => {
      newCategoryFormRef.value?.validate((valid: boolean) => {
        if (!valid) return;
        if (localCategories.value.includes(newCategoryForm.value.name)) return;
        localCategories.value.push(newCategoryForm.value.name);
        newCategoryForm.value.name = '';
      });
    };
    const removeCategory = (index: number) => {
      localCategories.value.splice(index, 1);
    };

    return {
      addCategory,
      confirm,
      localCategories,
      newCategoryForm,
      newCategoryFormRef,
      newCategoryFormRules,
      removeCategory
    };
  }
};
</script>
<style lang="scss" scoped>
.form-item {
  flex-grow: 1;
}
li {
  align-items: center;
  height: 40px;
  box-shadow: 0 8px 20px $etp-modal-shadow;
  border-radius: 20px;
  margin: 8px 0;
  padding: 0 16px;
}
</style>
