<template>
  <el-form :model="form">
    <el-form-item prop="seedEntries" class="mb-0">
      <el-checkbox
        class="checkbox"
        :disabled="disableSeedCheckbox"
        :label="$t('seedEntries')"
        :model-value="form.seedEntries"
        @change="handleChangeSeedEntries"
      />
    </el-form-item>
    <el-form-item :label="$t('sqlServerName')">
      <el-select
        v-model="form.sqlServerName"
        size="small"
        :placeholder="$t('empty')"
        @change="handleChangeSqlServerName"
      >
        <el-option
          v-for="item in sqlServerNames"
          :key="item"
          :label="item"
          :value="item"
        ></el-option>
      </el-select>
    </el-form-item>
  </el-form>
</template>
<script lang="ts">
import {
  ElForm,
  ElFormItem,
  ElCheckbox,
  ElOption,
  ElSelect
} from 'element-plus';
import { SqlDatabaseConfiguration } from '@/views/admin/tenants/models/TenantAzureConfigurationObjects';
import { onMounted, onUpdated, ref } from 'vue';

export default {
  name: 'SqlDatabaseConfigurationForm',
  components: { ElForm, ElFormItem, ElCheckbox, ElOption, ElSelect },
  props: {
    configuration: { type: String, default: '' },
    sqlServerNames: {
      type: Array as () => Array<string>,
      default: () => new Array<string>()
    }
  },
  emits: ['update-configuration'],
  setup(props, { emit }) {
    let form = ref(new SqlDatabaseConfiguration());
    let localConfugrationObject = ref({});
    const disableSeedCheckbox = ref(false);

    const emitUpdateEvent = () => {
      emit(
        'update-configuration',
        JSON.stringify({
          ...localConfugrationObject.value,
          ...form.value
        })
      );
    };

    const handleChangeSeedEntries = (newValue: boolean) => {
      form.value.seedEntries = newValue;
      emitUpdateEvent();
    };

    const handleChangeSqlServerName = (newValue: string) => {
      form.value.sqlServerName = newValue;
      emitUpdateEvent();
    };

    const updateLocalVars = () => {
      localConfugrationObject.value = JSON.parse(props.configuration || '{}');
      form.value.seedEntries =
        localConfugrationObject.value['seedEntries'] ?? false;
      form.value.sqlServerName =
        localConfugrationObject.value['sqlServerName'] ?? '';
      disableSeedCheckbox.value =
        localConfugrationObject.value['initialSetupPerformed'] ?? false;
    };

    onMounted(() => {
      updateLocalVars();
      emitUpdateEvent();
    });
    onUpdated(() => updateLocalVars());

    return {
      disableSeedCheckbox,
      form,
      handleChangeSeedEntries,
      handleChangeSqlServerName
    };
  }
};
</script>
<style scoped lang="scss"></style>
