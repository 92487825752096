import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bb767eae"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-body" }
const _hoisted_2 = { class: "dialog-footer" }
const _hoisted_3 = { class: "footer-slot" }
const _hoisted_4 = { class: "footer-action" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_dialog, {
      title: $props.modalTitle,
      "model-value": $props.dialogVisible,
      "before-close": $setup.handleClose,
      width: $props.width
    }, {
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
          ]),
          _createElementVNode("div", _hoisted_4, [
            ($props.hasConfirmationButton)
              ? (_openBlock(), _createBlock(_component_el_button, {
                  key: 0,
                  class: "modal-confirm",
                  type: "primary",
                  onClick: $setup.confirm
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("h4", null, _toDisplayString($props.confirmText), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"]))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "content", {}, undefined, true)
        ])
      ]),
      _: 3
    }, 8, ["title", "model-value", "before-close", "width"])
  ]))
}