<template>
  <div>
    <div class="colored-circle-a size-m" />
    <div class="colored-circle-a size-xl" />
    <div class="glass-circle size-s bs-1" />
    <div class="glass-circle size-l bs-1" />
  </div>
</template>

<script lang="ts">
export default {
  name: 'LoginCircles'
};
</script>

<style scoped lang="scss">
.size-s {
  left: 6.25%;
  bottom: 12.5%;
  height: 363px;
  width: 363px;
  animation: grow 4000ms ease-in-out infinite alternate;
}
@keyframes grow {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.1);
  }
}
.size-l {
  top: 22.64%;
  right: 6.12%;
  height: 544px;
  width: 544px;
  animation: shrink-move 15000ms ease-in-out infinite alternate;
}
@keyframes shrink-move {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0.9) translateY(-30%);
  }
}

.size-m {
  position: absolute;
  width: 445.15px;
  height: 445.15px;
  bottom: 25.1px;
  right: -72.67px;
  animation: grow-move 8000ms ease-in-out infinite alternate;
}
@keyframes grow-move {
  from {
    transform: scale(0.75) scaleX(-1);
  }
  to {
    transform: scale(1.1) translateX(-50%) scaleX(-1);
  }
}
.size-xl {
  position: absolute;
  width: 768.49px;
  height: 768.49px;
  left: -151.1px;
  top: -172.67px;
  transform: rotate(107.13deg);
  animation: left-to-right 10000ms ease-in-out forwards infinite alternate;
}
@keyframes left-to-right {
  100% {
    transform: translateX(50%);
  }
}
</style>
