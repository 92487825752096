import { ActionContext, Module } from 'vuex';
import { createGainBffClient } from '@/services/api';
import {
  BatchDetails,
  BatchFileSqlModel,
  EngagementRequirementsStatus,
  ExecuteFileSqlRequest,
  ModelFile,
  Promotion,
  RevertPromotionRequest,
  UpdateRequirementsStatusRequest
} from '@etp/etp-gain-bff-client/axios';

interface GainState {
  batchDetails: Array<BatchDetails>;
  batchFiles: Array<BatchFileSqlModel>;
  currentRequirementsStatus: EngagementRequirementsStatus;
  promotions: Array<Promotion>;
  requirementsStatusHistory: Array<EngagementRequirementsStatus>;
  stagingFiles: Array<ModelFile>;
}

class GainModule implements Module<GainState, any> {
  namespaced = true;
  state = {
    batchDetails: new Array<BatchDetails>(),
    batchFiles: new Array<BatchFileSqlModel>(),
    currentRequirementsStatus: {} as EngagementRequirementsStatus,
    promotions: new Array<Promotion>(),
    requirementsStatusHistory: new Array<EngagementRequirementsStatus>(),
    stagingFiles: new Array<ModelFile>()
  };

  getters = {
    getBatchDetails: (state: GainState): Array<BatchDetails> =>
      state.batchDetails,
    getBatchFiles: (state: GainState): Array<BatchFileSqlModel> =>
      state.batchFiles,
    getCurrentRequirementsStatus: (
      state: GainState
    ): EngagementRequirementsStatus => state.currentRequirementsStatus,
    getPromotions: (state: GainState): Array<Promotion> => state.promotions,
    getRequirementsStatusHistory: (
      state: GainState
    ): Array<EngagementRequirementsStatus> => state.requirementsStatusHistory,
    getStagingFiles: (state: GainState): Array<ModelFile> => state.stagingFiles
  };
  actions = {
    async fetchBatchDetails(context: ActionContext<GainState, any>) {
      const engagementId = context.rootState.home.currentEngagement.id;
      if (!engagementId) return;
      let client = createGainBffClient();
      const { data, status } = await client.listBatchDetails(engagementId);
      if (status == 200) {
        context.commit('SET_BATCH_DETAILS', data);
      } else {
        context.commit('SET_BATCH_DETAILS', new Array<BatchDetails>());
      }
    },
    async fetchCurrentRequirementsStatus(
      context: ActionContext<GainState, any>
    ) {
      const engagementId = context.rootState.home.currentEngagement.id;
      if (!engagementId) return;
      let client = createGainBffClient();
      const { data, status } = await client.getLatestRequirementsStatus(
        engagementId
      );
      if (status == 200) {
        context.commit('SET_CURRENT_REQUIREMENTS_STATUS', data);
      } else {
        context.commit(
          'SET_CURRENT_REQUIREMENTS_STATUS',
          {} as EngagementRequirementsStatus
        );
      }
    },
    async fetchPromotions(context: ActionContext<GainState, any>) {
      const engagementId = context.rootState.home.currentEngagement.id;
      if (!engagementId) return;
      let client = createGainBffClient();
      const { data, status } = await client.listPromotions(engagementId);
      if (status == 200) {
        context.commit('SET_PROMOTIONS', data);
      } else {
        context.commit('SET_PROMOTIONS', new Array<Promotion>());
      }
    },
    async fetchRequirementsStatusHistory(
      context: ActionContext<GainState, any>
    ) {
      const engagementId = context.rootState.home.currentEngagement.id;
      if (!engagementId) return;
      let client = createGainBffClient();
      const { data, status } = await client.listRequirementsStatus(
        engagementId
      );
      if (status == 200) {
        context.commit('SET_REQUIREMENTS_STATUS_HISTORY', data);
      } else {
        context.commit(
          'SET_REQUIREMENTS_STATUS_HISTORY',
          new Array<EngagementRequirementsStatus>()
        );
      }
    },
    async revertPromotion(
      context: ActionContext<GainState, any>,
      payload: RevertPromotionRequest
    ) {
      const engagementId = context.rootState.home.currentEngagement.id;
      if (!engagementId) return;
      payload.revertedBy = context.rootState.base.currentUser.displayName;
      await createGainBffClient().revertPromotion(engagementId, payload);
    },
    async updateRequirementsStatus(
      context: ActionContext<GainState, any>,
      payload: UpdateRequirementsStatusRequest
    ) {
      const engagementId = context.rootState.home.currentEngagement.id;
      if (!engagementId) return;
      payload.updatedBy = context.rootState.base.currentUser.displayName;
      await createGainBffClient().updateRequirementsStatus(
        engagementId,
        payload
      );
    },
    async fetchStagingFiles(context: ActionContext<GainState, any>) {
      const engagementId = context.rootState.home.currentEngagement.id;
      if (!engagementId) return;
      const { data, status } = await createGainBffClient().listStagingFiles(
        engagementId
      );
      if (status == 200) {
        context.commit('SET_STAGING_FILES', data);
      } else {
        context.commit('SET_STAGING_FILES', new Array<ModelFile>());
      }
    },
    async testImportConfiguration(
      context: ActionContext<GainState, any>,
      payload: ExecuteFileSqlRequest
    ): Promise<string | null | undefined> {
      const engagementId = context.rootState.home.currentEngagement.id;
      if (!engagementId) return null;
      const { data, status } =
        await createGainBffClient().testImportConfiguration(
          engagementId,
          payload
        );
      if (status == 200) {
        context.commit('SET_BATCH_FILES', data.batchFiles ?? []);
        return data.sqliteError;
      } else {
        context.commit('SET_BATCH_FILES', new Array<BatchFileSqlModel>());
        return null;
      }
    }
  };

  mutations = {
    SET_BATCH_DETAILS(state: GainState, payload: Array<BatchDetails>) {
      state.batchDetails = payload;
    },
    SET_CURRENT_REQUIREMENTS_STATUS(
      state: GainState,
      payload: EngagementRequirementsStatus
    ) {
      state.currentRequirementsStatus = payload;
    },
    SET_PROMOTIONS(state: GainState, payload: Array<Promotion>) {
      state.promotions = payload;
    },
    SET_REQUIREMENTS_STATUS_HISTORY(
      state: GainState,
      payload: Array<EngagementRequirementsStatus>
    ) {
      state.requirementsStatusHistory = payload;
    },
    SET_STAGING_FILES(state: GainState, payload: Array<ModelFile>) {
      state.stagingFiles = payload;
    },
    SET_BATCH_FILES(state: GainState, payload: Array<BatchFileSqlModel>) {
      state.batchFiles = payload;
    }
  };
}

export default new GainModule();
