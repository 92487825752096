import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import config, { loadConfiguration } from '@/config/config';
import VueClipboard from 'vue3-clipboard';
import '@/styles/main.scss';
import i18n from './i18n';
import { MsalInitOptions } from './services/msal/types';
import msal from '@/services/msal/msal';

require('typeface-open-sans');
require('typeface-work-sans');
require('typeface-inter');

loadConfiguration().then(() => {
  const msalOptions: MsalInitOptions = {
    tenantId: config.sso.tenantId,
    clientId: config.sso.clientId,
    redirectUri: config.sso.redirectUri,
    postLogoutRedirectUri: config.sso.postLogoutRedirectUri,
    cacheLocation: config.sso.cacheLocation,
    consentScopes: config.sso.consentScopes
  };

  msal.init(msalOptions);

  createApp(App)
    .use(store)
    .use(router)
    .use(i18n)
    .use(VueClipboard, {
      autoSetContainer: true,
      appendToBody: true
    })
    .mount('#app');
});
