<template>
  <el-row :gutter="spacingWidthHeaderComponents" class="mt-6">
    <el-col>
      <el-row class="mt-2 mb-1">
        <h4>{{ batchDetails.length }} &#183; {{ $t('results') }}</h4>
      </el-row>
      <el-row class="d-block">
        <base-table
          :show-loading-table="loadingTable"
          :show-no-content-table="!batchDetails.length"
          :error-message="$t('noBatchDetails')"
        >
          <el-row class="mt-1">
            <batch-details-card
              v-for="(item, index) in batchDetails"
              :key="index"
              :entry="item"
              class="mb-1"
            />
          </el-row>
        </base-table>
      </el-row>
    </el-col>
  </el-row>
</template>
<script lang="ts">
import { ElRow, ElCol } from 'element-plus';
import { onMounted, computed, ComputedRef, ref } from 'vue';
import { useStore } from 'vuex';
import { spacingWidthHeaderComponents } from '@/utils/variables';
import BatchDetailsCard from './components/BatchDetailsCard.vue';
import BaseTable from '@/components/tables/BaseTable.vue';
import { BatchDetails } from '@etp/etp-gain-bff-client/axios';

export default {
  name: 'GainBatches',
  components: {
    ElRow,
    ElCol,
    BaseTable,
    BatchDetailsCard
  },
  setup() {
    const store = useStore();
    const loadingTable = ref(true);

    const batchDetails: ComputedRef<Array<BatchDetails>> = computed(
      () => store.getters['gain/getBatchDetails']
    );

    onMounted(async () => {
      await store.dispatch('gain/fetchBatchDetails');
      loadingTable.value = false;
    });

    return {
      batchDetails,
      loadingTable,
      spacingWidthHeaderComponents
    };
  }
};
</script>
