<template>
  <div class="menu-container">
    <el-menu
      class="el-menu-vertical-demo"
      router
      :default-active="defaultActive"
      unique-opened
      @open="enableTransition"
      @close="enableTransition"
    >
      <slot name="menu-contents" />
    </el-menu>
  </div>
  <el-footer>
    <div class="application-details">
      <p>
        <small>
          <span
            :class="[
              'environment',
              environment === 'Production'
                ? 'is-primary'
                : environment === 'Staging'
                ? 'is-warning'
                : 'is-danger'
            ]"
          >
            {{ environment }}
          </span>
        </small>
      </p>
    </div>
  </el-footer>
</template>
<script lang="ts">
import { ElMenu, ElFooter } from 'element-plus';

import router from '@/router';
import config from '@/config/config';
import { computed, onMounted, ref, ComputedRef } from 'vue';

export default {
  name: 'TheMenu',
  components: { ElMenu, ElFooter },

  setup() {
    let environment = ref(config.environment);

    let defaultActive: ComputedRef<string> = computed(() => {
      return router.currentRoute.value.meta?.menuItemName as string;
    });
    onMounted(() => {
      document.querySelectorAll('.el-menu').forEach(el => {
        el.classList.add('no-transition');
      });
    });
    const enableTransition = () => {
      document.querySelectorAll('.no-transition').forEach(el => {
        el.classList.remove('no-transition');
      });
    };
    return { enableTransition, defaultActive, environment };
  }
};
</script>
<style lang="scss" scoped>
.menu-container {
  padding: 0 2em 1em 2em;
  box-sizing: border-box;
  width: $etp-menu-width;
}
.application-details {
  color: $etp-dark-grey;
  position: absolute;
  bottom: 1.5em;
  left: 3em;
  .environment {
    &.is-primary {
      color: $etp-light-green;
    }
    &.is-warning {
      color: $etp-warning-yellow;
    }
    &.is-danger {
      color: $etp-warning-red;
    }
  }
}
</style>
