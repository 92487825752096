<template>
  <el-row class="width-100">
    <base-card no-header class="width-100">
      <template #body>
        <el-row class="d-flex">
          <el-select
            v-model="localFileLogicType"
            size="small"
            class="ml-auto width-20"
            @change="handleLogicTypeChange"
          >
            <el-option
              v-for="(index, item) in FileLogicType"
              :key="index"
              :label="item"
              :value="index"
            />
          </el-select>
          <el-button
            class="ml-2"
            type="primary"
            :disabled="props.disabled"
            @click="save"
          >
            <h4>{{ $t('save') }}</h4>
          </el-button>
        </el-row>
        <el-row class="mt-3">
          <code-editor
            v-model="localSqlQuery"
            font_size="13px"
            :languages="[['SQL', 'SQL']]"
            theme="light"
            min_height="300px"
            min_width="100%"
          />
        </el-row>
      </template>
    </base-card>
  </el-row>
  <el-row class="width-100 mt-2">
    <el-col :span="9">
      <base-card no-header class="mr-1">
        <template #body>
          <el-row>
            <el-col :span="16" class="d-flex">
              <el-tooltip placement="top" :content="'string'">
                <h4>{{ 'Name' }}</h4>
              </el-tooltip>
            </el-col>
            <el-col :span="6" class="d-flex justify-content-center">
              <el-tooltip placement="top" :content="'boolean'">
                <h4>{{ 'IsNewDrop' }}</h4>
              </el-tooltip>
            </el-col>
          </el-row>
          <el-row
            v-for="(item, index) in localStagingFiles"
            :key="index"
            class="mt-1"
          >
            <el-col :span="16" class="d-flex justify-content-center">
              <el-input v-model="item.name" size="small" />
            </el-col>
            <el-col
              :span="6"
              class="d-flex justify-content-center align-items-center"
            >
              <el-checkbox v-model="item.isNewDrop" size="small" />
            </el-col>
            <el-col
              :span="2"
              class="d-flex justify-content-center align-items-center"
            >
              <img
                :src="require(`@/assets/images/icons/new/delete.svg`)"
                height="14"
                width="14"
                class="action-icon"
                @click="deleteStagingFile(index)"
              />
            </el-col>
          </el-row>
          <el-row class="mt-1">
            <el-button type="info" @click="addStagingFile">
              <h4>{{ $t('add') }}</h4>
            </el-button>
            <el-button class="ml-2" type="info" @click="resetStagingFiles">
              <h4>{{ $t('reset') }}</h4>
            </el-button>
            <el-button type="primary" class="ml-auto" @click="testStagingFiles">
              <h4>{{ $t('test') }}</h4>
            </el-button>
          </el-row>
        </template>
      </base-card>
    </el-col>
    <el-col :span="15">
      <base-card no-header class="ml-1">
        <template #body>
          <el-row>
            <el-col :span="9" class="d-flex">
              <el-tooltip placement="top" :content="'string'">
                <h4>{{ 'FileName' }}</h4>
              </el-tooltip>
            </el-col>
            <el-col :span="6" class="d-flex">
              <el-tooltip placement="top" :content="'string'">
                <h4>{{ 'Batch' }}</h4>
              </el-tooltip>
            </el-col>
            <el-col :span="3" class="d-flex justify-content-center">
              <el-tooltip placement="top" :content="'boolean'">
                <h4>{{ 'IsTrigger' }}</h4>
              </el-tooltip>
            </el-col>
            <el-col :span="3" class="d-flex justify-content-center">
              <el-tooltip placement="top" :content="'boolean'">
                <h4>{{ 'IsMissing' }}</h4>
              </el-tooltip>
            </el-col>
            <el-col :span="3" class="d-flex justify-content-center">
              <el-tooltip placement="top" :content="'boolean'">
                <h4>{{ 'Archived' }}</h4>
              </el-tooltip>
            </el-col>
          </el-row>
          <table-placeholder-loading v-if="testInProgress" :columns="2" />
          <el-row
            v-for="(item, index) in localBatchFiles"
            v-else
            :key="index"
            class="mt-1"
          >
            <el-col :span="9" class="d-flex justify-content-center">
              <el-input v-model="item.fileName" disabled size="small" />
            </el-col>
            <el-col :span="6" class="d-flex justify-content-center">
              <el-input v-model="item.batch" disabled size="small" />
            </el-col>
            <el-col
              :span="3"
              class="d-flex justify-content-center align-items-center"
            >
              <el-checkbox v-model="item.isTrigger" disabled size="small" />
            </el-col>
            <el-col
              :span="3"
              class="d-flex justify-content-center align-items-center"
            >
              <el-checkbox v-model="item.isMissing" disabled size="small" />
            </el-col>
            <el-col
              :span="3"
              class="d-flex justify-content-center align-items-center"
            >
              <el-checkbox v-model="item.archived" disabled size="small" />
            </el-col>
          </el-row>
        </template>
      </base-card>
    </el-col>
  </el-row>
</template>
<script lang="ts">
import BaseCard from '@/components/panels/BaseCard.vue';
import {
  ElButton,
  ElCheckbox,
  ElCol,
  ElInput,
  ElOption,
  ElRow,
  ElSelect,
  ElTooltip
} from 'element-plus';
import {
  BatchFileSqlModel,
  ExecuteFileSqlRequest,
  InputSqlConfiguration,
  FileLogicType,
  StagingFileSqlModel,
  ModelFile
} from '@etp/etp-gain-bff-client/axios';
import { onMounted, onUpdated, ref } from 'vue';
import CodeEditor from 'simple-code-editor/CodeEditor.vue';
import TablePlaceholderLoading from '@/components/tables/TablePlaceholderLoading.vue';

export default {
  name: 'GainImportFilesSqlCard',
  components: {
    BaseCard,
    CodeEditor,
    ElButton,
    ElCheckbox,
    ElCol,
    ElInput,
    ElOption,
    ElRow,
    ElSelect,
    ElTooltip,
    TablePlaceholderLoading
  },
  props: {
    batchFiles: {
      type: Array as () => Array<BatchFileSqlModel>,
      required: true
    },
    disabled: { type: Boolean, default: false },
    sqlConfguration: {
      type: Object as () => InputSqlConfiguration,
      default: {} as InputSqlConfiguration
    },
    stagingFiles: { type: Array as () => Array<ModelFile>, required: true }
  },
  emits: ['change-logic-type', 'save', 'test'],
  setup(props, { emit }) {
    const localSqlQuery = ref('');
    const localFileLogicType = ref(FileLogicType.SqlLogic);
    const localStagingFiles = ref(new Array<StagingFileSqlModel>());
    const localBatchFiles = ref(new Array<BatchFileSqlModel>());
    const testInProgress = ref(false);

    const addStagingFile = () => {
      localStagingFiles.value.push({} as StagingFileSqlModel);
    };

    const deleteStagingFile = (index: number) => {
      localStagingFiles.value.splice(index, 1);
    };

    const resetStagingFiles = () => {
      localStagingFiles.value = props.stagingFiles.map(el => {
        return {
          name: el.name,
          isNewDrop: false
        } as StagingFileSqlModel;
      });
      localBatchFiles.value = new Array<BatchFileSqlModel>();
    };

    const handleLogicTypeChange = (newValue: FileLogicType) => {
      emit('change-logic-type', newValue);
    };

    const save = () => {
      emit('save', {
        ...props.sqlConfguration,
        sqlQuery: localSqlQuery.value
      } as InputSqlConfiguration);
    };

    const testStagingFiles = () => {
      testInProgress.value = true;
      emit('test', {
        stagingFiles: localStagingFiles.value,
        sqlQuery: localSqlQuery.value
      } as ExecuteFileSqlRequest);
    };

    onMounted(() => {
      localSqlQuery.value = props.sqlConfguration.sqlQuery ?? '';
      resetStagingFiles();
    });
    onUpdated(() => {
      localBatchFiles.value =
        props.batchFiles ?? new Array<BatchFileSqlModel>();
      testInProgress.value = false;
    });

    return {
      addStagingFile,
      deleteStagingFile,
      FileLogicType,
      handleLogicTypeChange,
      localBatchFiles,
      localFileLogicType,
      localSqlQuery,
      localStagingFiles,
      props,
      resetStagingFiles,
      save,
      testInProgress,
      testStagingFiles
    };
  }
};
</script>
<style></style>
