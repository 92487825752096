<template>
  <el-row>
    <page-header :main-title="$t('users')">
      <template #subheader>
        <h4 class="subtitle-2 mt-3">
          {{ $t('userDesc') }}
        </h4>
      </template>
    </page-header>
  </el-row>
  <base-table-header
    :row-count="allUsersCount"
    :quantity-message="$t('users')"
    :input-placeholder="$t('searchUser')"
    class="mt-6"
    @query-change="changeFilter"
  >
    <el-button type="primary" class="ml-2" @click="openNewUserModal()">
      <h4>{{ '+ ' + $t('newUser') }}</h4>
    </el-button>
  </base-table-header>
  <el-table :data="filteredUsers">
    <el-table-column
      :label="$t('name')"
      show-overflow-tooltip
      prop="name"
      sortable
      width="360"
    />
    <el-table-column :label="$t('email')" show-overflow-tooltip prop="email" />
    <el-table-column width="100">
      <template #default="props">
        <div class="d-flex align-items-center">
          <div class="ml-auto mt--1 mb--1">
            <el-tooltip
              placement="top"
              :content="
                props.row.disabled ? $t('activateUser') : $t('deactivateUser')
              "
            >
              <base-toggle-button
                :model-value="!props.row.disabled"
                :indicator-text="
                  props.row.disabled
                    ? $t('userActivated')
                    : $t('userDeactivated')
                "
                @update:model-value="toggleUser(props.row)"
              />
            </el-tooltip>
          </div>
          <div class="ml-2 mr-2 d-flex align-items-center">
            <el-tooltip placement="top" :content="$t('userDetails')">
              <img
                :src="require(`@/assets/images/icons/new/edit.svg`)"
                height="14"
                width="14"
                class="action-icon"
                @click="openUserDetails(props.row.email)"
              />
            </el-tooltip>
          </div>
        </div>
      </template>
    </el-table-column>
  </el-table>
  <pagination
    :count="allUsersCount"
    :page="pageNumber"
    :page-size="pageSize"
    @change-page="changePage"
  />
  <new-user-modal
    v-model="showNewUserModal"
    :existing-emails="allUsersEmails"
    :feedback-text="$t('userInfoSaved')"
    @confirm="createNewUser"
  />
</template>
<script lang="ts">
import PageHeader from '@/components/misc/PageHeader.vue';
import {
  ElTable,
  ElTableColumn,
  ElRow,
  ElButton,
  ElTooltip
} from 'element-plus';
import { computed, onMounted, ref, ComputedRef } from 'vue';
import { useStore } from 'vuex';
import router from '@/router';
import NewUserModal from './components/NewUserModal.vue';
import { CreateOrUpdateUserDto } from '@etp/etp-profile-client/axios';
import BaseTableHeader from '@/components/tables/BaseTableHeader.vue';
import BaseToggleButton from '@/components/buttons/BaseToggleButton.vue';
import {
  CreateOrUpdateUserModel,
  UserModel
} from '@etp/etp-authorization-client/axios';
import Pagination from '@/components/misc/Pagination.vue';

export default {
  name: 'UsersOverview',
  components: {
    BaseTableHeader,
    BaseToggleButton,
    ElTable,
    ElTableColumn,
    PageHeader,
    ElRow,
    ElButton,
    ElTooltip,
    NewUserModal,
    Pagination
  },

  setup() {
    const store = useStore();

    let allUsers: ComputedRef<Array<UserModel>> = computed(
      () => store.getters['users/getUsers']
    );

    let pageUsers: ComputedRef<Array<UserModel>> = computed(
      () => store.getters['users/getPageUsers']
    );

    let pageNumber: ComputedRef<number> = computed(
      () => store.getters['users/getUsersPageNumber']
    );

    let pageSize: ComputedRef<number> = computed(
      () => store.getters['users/getUsersPageSize']
    );

    let allUsersCount: ComputedRef<number> = computed(
      () => store.getters['users/getUsersCount']
    );

    let allUsersEmails: ComputedRef<Array<string>> = computed(() =>
      allUsers.value.map(x => x.email)
    );

    const fetchUsers = async () => {
      await store.dispatch('users/fetchPageUsers');
      await store.dispatch('users/fetchAllUsers');
    };

    const showNewUserModal = ref(false);

    const openNewUserModal = () => {
      showNewUserModal.value = true;
    };

    const openUserDetails = (email: any) => {
      router.push({
        name: 'UserDetails',
        params: { email }
      });
    };

    const createNewUser = async user => {
      showNewUserModal.value = false;
      await store.dispatch('users/createOrUpdateUser', {
        email: user.email,
        profileModel: {
          displayName: user.name,
          language: user.language,
          templateName: user.template
        } as CreateOrUpdateUserDto,
        authorizationModel: {
          name: user.name,
          disabled: false
        } as CreateOrUpdateUserModel
      });
      await fetchUsers();
      setFilteredUsers();
    };

    const toggleUser = async (user: UserModel) => {
      await store.dispatch('users/createOrUpdateUser', {
        email: user.email,
        authorizationModel: {
          name: user.name,
          disabled: !user.disabled
        } as CreateOrUpdateUserModel
      });
      user.disabled = !user.disabled;
    };

    const userFilter = ref('');
    const filteredUsers = ref(new Array<UserModel>());

    const changeFilter = (inputQuery: string) => {
      userFilter.value = inputQuery.length >= 3 ? inputQuery : '';
      setFilteredUsers();
    };

    const setFilteredUsers = () => {
      filteredUsers.value = userFilter.value
        ? allUsers.value.filter(
            user =>
              user.name
                .toLowerCase()
                .includes(userFilter.value.toLowerCase()) ||
              user.email.toLowerCase().includes(userFilter.value.toLowerCase())
          )
        : pageUsers.value;
    };

    const changePage = async (page: number) => {
      store.commit('users/SET_USERS_PAGE', page);
      await fetchUsers();
      setFilteredUsers();
    };

    onMounted(async () => {
      await fetchUsers();
      setFilteredUsers();
    });

    return {
      allUsersEmails,
      allUsersCount,
      changeFilter,
      changePage,
      createNewUser,
      filteredUsers,
      openNewUserModal,
      openUserDetails,
      pageUsers,
      pageNumber,
      pageSize,
      showNewUserModal,
      toggleUser
    };
  }
};
</script>
<style scoped lang="scss"></style>
