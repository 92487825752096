<template>
  <el-form :model="form">
    <el-form-item prop="createGatewayEntry" class="mb-0">
      <el-checkbox
        class="checkbox"
        :label="$t('createGatewayEntry')"
        :model-value="form.createGatewayEntry"
        @change="handleChangeCreateGatewayEntry"
      />
    </el-form-item>
    <el-form-item prop="createContainers" class="mb-0">
      <el-checkbox
        class="checkbox"
        :label="$t('createContainers')"
        :model-value="form.createContainers"
        @change="handleChangeCreateContainers"
      />
    </el-form-item>
    <el-form-item prop="deployBlobPrivateEndpoint" class="mb-0">
      <el-checkbox
        class="checkbox"
        :label="$t('deployBlobPrivateEndpoint')"
        :model-value="form.deployBlobPrivateEndpoint"
        @change="handleChangeDeployBlobPrivateEndpoint"
      />
    </el-form-item>
    <el-form-item prop="deployDfsPrivateEndpoint" class="mb-0">
      <el-checkbox
        class="checkbox"
        :label="$t('deployDfsPrivateEndpoint')"
        :model-value="form.deployDfsPrivateEndpoint"
        @change="handleChangeDeployDfsPrivateEndpoint"
      />
    </el-form-item>
    <el-form-item prop="deployFilePrivateEndpoint" class="mb-0">
      <el-checkbox
        class="checkbox"
        :label="$t('deployFilePrivateEndpoint')"
        :model-value="form.deployFilePrivateEndpoint"
        @change="handleChangeDeployFilePrivateEndpoint"
      />
    </el-form-item>
    <el-form-item prop="deployQueuePrivateEndpoint" class="mb-0">
      <el-checkbox
        class="checkbox"
        :label="$t('deployQueuePrivateEndpoint')"
        :model-value="form.deployQueuePrivateEndpoint"
        @change="handleChangeDeployQueuePrivateEndpoint"
      />
    </el-form-item>
  </el-form>
</template>
<script lang="ts">
import { ElForm, ElFormItem, ElCheckbox } from 'element-plus';
import { StorageAccountConfiguration } from '@/views/admin/tenants/models/TenantAzureConfigurationObjects';
import { onMounted, onUpdated, ref } from 'vue';

export default {
  name: 'StorageAccountConfigurationForm',
  components: { ElForm, ElFormItem, ElCheckbox },
  props: {
    configuration: { type: String, default: '' }
  },
  emits: ['update-configuration'],
  setup(props, { emit }) {
    let form = ref(new StorageAccountConfiguration());
    let localConfugrationObject = ref({});

    const emitUpdateEvent = () => {
      emit(
        'update-configuration',
        JSON.stringify({
          ...localConfugrationObject.value,
          ...form.value
        })
      );
    };

    const handleChangeCreateGatewayEntry = (newValue: boolean) => {
      form.value.createGatewayEntry = newValue;
      emitUpdateEvent();
    };

    const handleChangeCreateContainers = (newValue: boolean) => {
      form.value.createContainers = newValue;
      emitUpdateEvent();
    };

    const handleChangeDeployBlobPrivateEndpoint = (newValue: boolean) => {
      form.value.deployBlobPrivateEndpoint = newValue;
      emitUpdateEvent();
    };

    const handleChangeDeployDfsPrivateEndpoint = (newValue: boolean) => {
      form.value.deployDfsPrivateEndpoint = newValue;
      emitUpdateEvent();
    };

    const handleChangeDeployFilePrivateEndpoint = (newValue: boolean) => {
      form.value.deployFilePrivateEndpoint = newValue;
      emitUpdateEvent();
    };

    const handleChangeDeployQueuePrivateEndpoint = (newValue: boolean) => {
      form.value.deployQueuePrivateEndpoint = newValue;
      emitUpdateEvent();
    };

    const updateLocalVars = () => {
      localConfugrationObject.value = JSON.parse(props.configuration || '{}');
      form.value.createGatewayEntry =
        localConfugrationObject.value['createGatewayEntry'] ?? false;
      form.value.createContainers =
        localConfugrationObject.value['createContainers'] ?? false;
      form.value.deployBlobPrivateEndpoint =
        localConfugrationObject.value['deployBlobPrivateEndpoint'] ?? false;
      form.value.deployDfsPrivateEndpoint =
        localConfugrationObject.value['deployDfsPrivateEndpoint'] ?? false;
      form.value.deployFilePrivateEndpoint =
        localConfugrationObject.value['deployFilePrivateEndpoint'] ?? false;
      form.value.deployQueuePrivateEndpoint =
        localConfugrationObject.value['deployQueuePrivateEndpoint'] ?? false;
    };

    onMounted(() => {
      updateLocalVars();
      emitUpdateEvent();
    });
    onUpdated(() => updateLocalVars());

    return {
      form,
      handleChangeCreateGatewayEntry,
      handleChangeCreateContainers,
      handleChangeDeployBlobPrivateEndpoint,
      handleChangeDeployDfsPrivateEndpoint,
      handleChangeDeployFilePrivateEndpoint,
      handleChangeDeployQueuePrivateEndpoint
    };
  }
};
</script>
<style scoped lang="scss"></style>
