import { ActionContext, Module } from 'vuex';
import { createPipelinesApiClient } from '@/services/api';
import { PipelineRunDto } from '@etp/etp-pipelines-client/axios';

interface OverviewState {
  lastPipelineRunsOverview: Array<PipelineRunDto>;
}

class OverviewModule implements Module<OverviewState, any> {
  namespaced = true;
  state = {
    lastPipelineRunsOverview: new Array<PipelineRunDto>()
  };
  getters = {
    getLastPipelineRunsOverview: (
      state: OverviewState
    ): Array<PipelineRunDto> => {
      return state.lastPipelineRunsOverview;
    }
  };
  actions = {
    async fetchLastPipelineRunsOverview(
      context: ActionContext<OverviewState, any>
    ) {
      let client = createPipelinesApiClient();
      const engagementId = context.rootState.home.currentEngagement?.id;
      if (!engagementId) return;
      const { data, status } = await client.lastPipelineRunsOverview(
        engagementId
      );
      if (status == 200) {
        context.commit('SET_LAST_PIPELINE_RUNS_OVERVIEW', data);
      } else {
        context.commit(
          'SET_LAST_PIPELINE_RUNS_OVERVIEW',
          new Array<PipelineRunDto>()
        );
      }
    }
  };
  mutations = {
    SET_LAST_PIPELINE_RUNS_OVERVIEW(
      state: OverviewState,
      payload: Array<PipelineRunDto>
    ) {
      state.lastPipelineRunsOverview = payload;
    }
  };
}

export default new OverviewModule();
