import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_connector_configuration_card = _resolveComponent("connector-configuration-card")!
  const _component_new_connector_configuration_modal = _resolveComponent("new-connector-configuration-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_row, { class: "d-flex" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_button, {
          class: "ml-auto",
          type: "primary",
          onClick: $setup.openNewConfigurationModal
        }, {
          default: _withCtx(() => [
            _createElementVNode("h4", null, _toDisplayString(_ctx.$t('new')), 1)
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_el_button, {
          class: "ml-2",
          type: "primary",
          onClick: $setup.saveConfiguration
        }, {
          default: _withCtx(() => [
            _createElementVNode("h4", null, _toDisplayString(_ctx.$t('save')), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _: 1
    }),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.connectorsConfiguration, (item, index) => {
      return (_openBlock(), _createBlock(_component_el_row, {
        key: index,
        class: "mt-1"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_connector_configuration_card, {
            "connector-configuration": item,
            onUpdate: (configuration) => $setup.updateEntry(index, configuration),
            onDelete: ($event: any) => ($setup.deleteEntry(index))
          }, null, 8, ["connector-configuration", "onUpdate", "onDelete"])
        ]),
        _: 2
      }, 1024))
    }), 128)),
    _createVNode(_component_new_connector_configuration_modal, {
      modelValue: $setup.showNewConfigurationModal,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.showNewConfigurationModal) = $event)),
      "available-connectors": $setup.availableConnectors,
      onConfirm: $setup.addEntry,
      onClose: _cache[1] || (_cache[1] = ($event: any) => ($setup.showNewConfigurationModal = false))
    }, null, 8, ["modelValue", "available-connectors", "onConfirm"])
  ], 64))
}