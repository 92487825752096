import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_tree = _resolveComponent("base-tree")!

  return (_openBlock(), _createBlock(_component_base_tree, {
    "all-nodes": $setup.tree,
    "selected-nodes": $setup.getSelectedNodes($props.existingPermissions),
    onChange: $setup.handleChange
  }, null, 8, ["all-nodes", "selected-nodes", "onChange"]))
}