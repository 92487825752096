import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "subtitle-2 mt-3" }
const _hoisted_2 = { class: "ml-auto" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 1,
  class: "mt-auto"
}
const _hoisted_5 = { class: "costs-table" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_header = _resolveComponent("page-header")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_base_table = _resolveComponent("base-table")!
  const _component_stop_cost_saving_modal = _resolveComponent("stop-cost-saving-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_page_header, {
      "main-title": _ctx.$t('costs')
    }, {
      subheader: _withCtx(() => [
        _createElementVNode("h4", _hoisted_1, _toDisplayString(_ctx.$t('costsOverview')), 1)
      ]),
      _: 1
    }, 8, ["main-title"]),
    _createVNode(_component_el_row, { class: "d-flex align-items-center" }, {
      default: _withCtx(() => [
        _createElementVNode("h4", _hoisted_2, _toDisplayString(_ctx.$t('tenantActions') + ' :'), 1),
        _createVNode(_component_el_button, {
          type: "primary",
          class: "ml-2",
          onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.showStopCostSavingModal = true))
        }, {
          default: _withCtx(() => [
            _createElementVNode("h4", null, _toDisplayString(_ctx.$t('stopCostSaving')), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_row, {
      gutter: 24,
      class: "mt-6"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_row, null, {
              default: _withCtx(() => [
                ($setup.loadingCosts)
                  ? (_openBlock(), _createElementBlock("h4", _hoisted_3, _toDisplayString(_ctx.$t('loading')), 1))
                  : (_openBlock(), _createElementBlock("h4", _hoisted_4, _toDisplayString($setup.usageCosts.length) + " · " + _toDisplayString(_ctx.$t('cost')), 1))
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, null, {
          default: _withCtx(() => [
            _createVNode(_component_base_table, {
              "show-loading-table": $setup.loadingCosts,
              "show-no-content-table": !$setup.usageCosts.length,
              "error-message": _ctx.$t('noCosts')
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_el_table, {
                    data: $setup.usageCosts,
                    "summary-method": $setup.totalCostsRow,
                    "show-summary": ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_table_column, { type: "expand" }, {
                        default: _withCtx((scope) => [
                          _createVNode(_component_el_table, {
                            data: scope.row.resourceCloudCostsGroupDetails
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_table_column, {
                                prop: "ResourceName",
                                label: _ctx.$t('name')
                              }, {
                                default: _withCtx((inner) => [
                                  _createElementVNode("div", null, [
                                    _createElementVNode("h4", null, _toDisplayString(inner.row.resourceName), 1)
                                  ])
                                ]),
                                _: 1
                              }, 8, ["label"]),
                              _createVNode(_component_el_table_column, {
                                label: _ctx.$t('costLastMonth'),
                                width: "200"
                              }, {
                                default: _withCtx((inner) => [
                                  _createElementVNode("h4", null, _toDisplayString($setup.formatCloudCosts(
                            inner.row.lastMonth?.currency,
                            inner.row.lastMonth?.amount
                          )), 1)
                                ]),
                                _: 1
                              }, 8, ["label"]),
                              _createVNode(_component_el_table_column, {
                                label: _ctx.$t('costMtd'),
                                width: "200"
                              }, {
                                default: _withCtx((inner) => [
                                  _createElementVNode("h4", null, _toDisplayString($setup.formatCloudCosts(
                            inner.row.monthToDate?.currency,
                            inner.row.monthToDate?.amount
                          )), 1)
                                ]),
                                _: 1
                              }, 8, ["label"])
                            ]),
                            _: 2
                          }, 1032, ["data"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_table_column, {
                        prop: "ResourcePath",
                        label: _ctx.$t('path')
                      }, {
                        default: _withCtx((scope) => [
                          _createElementVNode("h4", null, _toDisplayString(scope.row.resourcePath), 1)
                        ]),
                        _: 1
                      }, 8, ["label"]),
                      _createVNode(_component_el_table_column, {
                        prop: "ResourceName",
                        label: _ctx.$t('name')
                      }, {
                        default: _withCtx((scope) => [
                          _createElementVNode("h4", null, _toDisplayString(scope.row.resourceName), 1)
                        ]),
                        _: 1
                      }, 8, ["label"]),
                      _createVNode(_component_el_table_column, {
                        label: _ctx.$t('costLastMonth'),
                        width: "200"
                      }, {
                        default: _withCtx((scope) => [
                          _createElementVNode("h4", null, _toDisplayString($setup.formatCloudCosts(
                      scope.row.lastMonth?.currency,
                      scope.row.lastMonth?.amount
                    )), 1)
                        ]),
                        _: 1
                      }, 8, ["label"]),
                      _createVNode(_component_el_table_column, {
                        label: _ctx.$t('costMtd'),
                        width: "200"
                      }, {
                        default: _withCtx((scope) => [
                          _createElementVNode("h4", null, _toDisplayString($setup.formatCloudCosts(
                      scope.row.monthToDate?.currency,
                      scope.row.monthToDate?.amount
                    )), 1)
                        ]),
                        _: 1
                      }, 8, ["label"])
                    ]),
                    _: 1
                  }, 8, ["data", "summary-method"])
                ])
              ]),
              _: 1
            }, 8, ["show-loading-table", "show-no-content-table", "error-message"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_stop_cost_saving_modal, {
      modelValue: $setup.showStopCostSavingModal,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.showStopCostSavingModal) = $event)),
      onConfirm: $setup.stopCostSaving
    }, null, 8, ["modelValue", "onConfirm"])
  ], 64))
}