<template>
  <div class="pagination">
    <el-pagination
      layout="prev, pager, next"
      :total="count"
      :page-size="pageSize"
      hide-on-single-page
      :current-page="page"
      @current-change="changePage"
    />
  </div>
</template>

<script lang="ts">
import { ElPagination } from 'element-plus';

export default {
  name: 'PaginationComponent',
  components: { ElPagination },
  props: {
    count: { type: Number, default: 0 },
    pageSize: { type: Number, default: 10 },
    page: { type: Number, default: 1 }
  },
  emits: ['changePage'],
  setup(props, { emit }) {
    const changePage = page => {
      emit('changePage', page);
    };
    return {
      changePage
    };
  }
};
</script>
<style lang="scss"></style>
