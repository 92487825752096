<template>
  <el-table :data="tableData" fit>
    <el-table-column label="Name">
      <template #default="scope">
        {{ scope.row.name }}
      </template>
    </el-table-column>
    <el-table-column label="Id">
      <template #default="scope">
        {{ scope.row.id }}
      </template>
    </el-table-column>
    <el-table-column :width="100">
      <template #default="scope">
        <el-tooltip placement="top" :content="$t('openDashboard')">
          <img
            :src="require(`@/assets/images/icons/eye.svg`)"
            height="14"
            width="16"
            class="action-icon mr-1"
            @click.stop="openDashboard(scope.row)"
          />
        </el-tooltip>
        <el-tooltip placement="top" :content="$t('openDashboardNewTab')">
          <img
            :src="require(`@/assets/images/icons/new-tab.svg`)"
            height="14"
            width="14"
            class="action-icon mr-1"
            @click.stop="openDashboardNewTab(scope.row)"
          />
        </el-tooltip>
        <el-tooltip placement="top" :content="$t('deleteDashboard')">
          <img
            :src="require(`@/assets/images/icons/new/delete.svg`)"
            height="14"
            width="14"
            class="action-icon"
            @click.stop="openDeleteDashboardModal(scope.row)"
          />
        </el-tooltip>
      </template>
    </el-table-column>
  </el-table>
  <base-confirmation-modal
    v-model="showDeleteDashboardModal"
    :content="$t('deleteDashboard')"
    :feedback-text="$t('dashboardDeleted')"
    @confirm="deleteDashboard"
    @close="showDeleteDashboardModal = false"
  />
</template>
<script lang="ts">
import { ElTable, ElTableColumn, ElTooltip } from 'element-plus';
import BaseConfirmationModal from '@/components/modals/BaseConfirmationModal.vue';

import { ref } from 'vue';
import { DashboardInfo } from '@etp/etp-dashboarding-client/axios';

export default {
  name: 'DashboardTable',
  components: {
    ElTable,
    ElTableColumn,
    ElTooltip,
    BaseConfirmationModal
  },
  props: { tableData: { type: Array, required: true } },
  emits: ['open-dashboard', 'open-dashboard-newtab', 'confirm'],
  setup(props, { emit }) {
    const showDeleteDashboardModal = ref(false);
    const dashboardToDelete = ref();
    const openDeleteDashboardModal = (row: DashboardInfo) => {
      dashboardToDelete.value = row;
      showDeleteDashboardModal.value = true;
    };

    let openDashboard = (row: DashboardInfo) => {
      emit('open-dashboard', row);
    };
    let openDashboardNewTab = (row: DashboardInfo) => {
      emit('open-dashboard-newtab', row);
    };

    let deleteDashboard = () => {
      emit('confirm', dashboardToDelete.value.id);
      showDeleteDashboardModal.value = false;
    };
    return {
      openDashboard,
      openDashboardNewTab,
      deleteDashboard,
      showDeleteDashboardModal,
      openDeleteDashboardModal
    };
  }
};
</script>
