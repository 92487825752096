<template>
  <el-row class="d-flex">
    <h4 class="mt-auto">
      {{ tenantApplications.length }} &#183;
      {{ $t('applications') }}
    </h4>
    <el-button
      class="ml-auto"
      type="primary"
      @click="showAddRoleAssignmentModal = true"
    >
      <h4>{{ $t('addApplication') }}</h4>
    </el-button>
  </el-row>
  <el-table :data="tenantApplications">
    <el-table-column prop="name" :label="$t('name')" width="200" />
    <el-table-column>
      <template #header>
        <el-row>
          <el-col :span="6">
            {{ $t('role') }}
          </el-col>
          <el-col :span="17">
            {{ $t('scope') }}
          </el-col>
        </el-row>
      </template>
      <template #default="scope">
        <el-row
          v-for="roleAssignment in scope.row.roleAssignments.sort(compareRoleAssignments) as Array<RoleAssignmentModel>"
          :key="roleAssignment.roleName"
        >
          <el-col :span="6">
            <span class="body-1">
              {{ roleAssignment.roleName }}
            </span>
          </el-col>
          <el-col :span="17">
            <span class="body-1">
              {{ roleAssignment.scope }}
            </span>
          </el-col>
          <el-col :span="1">
            <img
              :src="require(`@/assets/images/icons/new/delete.svg`)"
              height="12"
              width="12"
              class="action-icon"
              @click="openRemoveRoleAssignmentModal(roleAssignment)"
            />
          </el-col>
        </el-row>
      </template>
    </el-table-column>
  </el-table>
  <add-application-role-assignment-modal
    :modal-visible="showAddRoleAssignmentModal"
    :applications="allApplications"
    :roles="allRoles"
    :scope="GetScopeForTenant(tenantId)"
    @confirm="addRoleAssignments"
    @close="showAddRoleAssignmentModal = false"
  />
  <base-confirmation-modal
    v-model="showRemoveRoleAssignmentModal"
    :content="$t('confirmRemoveApplicationFromTenant')"
    :feedback-text="$t('tenantApplicationDeleted')"
    @confirm="removeRoleAssignment"
    @close="showRemoveRoleAssignmentModal = false"
  />
</template>
<script lang="ts">
import {
  ElRow,
  ElCol,
  ElTable,
  ElTableColumn,
  ElButton,
  ElMessage
} from 'element-plus';
import AddApplicationRoleAssignmentModal from '@/components/modals/AddApplicationRoleAssignmentModal.vue';
import BaseConfirmationModal from '@/components/modals/BaseConfirmationModal.vue';
import { computed, ComputedRef, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { GetScopeForTenant, GetScopeMaskForTenant } from '@/utils/scopeHelper';
import {
  ApplicationDetailsModel,
  RoleAssignmentModel,
  RoleModel,
  ApplicationRoleAssignmentModel,
  ApplicationModel
} from '@etp/etp-authorization-client/axios';
import i18n from '@/i18n';
import { compareRoleAssignments } from '@/utils/roleAssignmentFunctions';

export default {
  name: 'TenantApplications',
  components: {
    AddApplicationRoleAssignmentModal,
    BaseConfirmationModal,
    ElButton,
    ElRow,
    ElCol,
    ElTable,
    ElTableColumn
  },
  setup() {
    let tenantId = ref('');
    let showAddRoleAssignmentModal = ref(false);
    const roleAssignmentToRemove = ref({} as RoleAssignmentModel);
    const showRemoveRoleAssignmentModal = ref(false);

    const router = useRouter();
    const store = useStore();

    const tenantApplications: ComputedRef<Array<ApplicationDetailsModel>> =
      computed(() => store.getters['tenants/getApplications']);
    const allApplications: ComputedRef<Array<ApplicationModel>> = computed(
      () => store.getters['applications/getApplications']
    );
    const allRoles: ComputedRef<Array<RoleModel>> = computed(
      () => store.getters['roles/getRoles']
    );

    const fetchTenantApplications = async () =>
      await store.dispatch('tenants/fetchApplications', tenantId.value);

    const openRemoveRoleAssignmentModal = (
      roleAssignment: RoleAssignmentModel
    ) => {
      roleAssignmentToRemove.value = roleAssignment;
      showRemoveRoleAssignmentModal.value = true;
    };

    const removeRoleAssignment = async () => {
      await store.dispatch('roles/removeApplicationRoleAssignment', {
        objectId: roleAssignmentToRemove.value.principalId,
        scope: roleAssignmentToRemove.value.scope,
        roleName: roleAssignmentToRemove.value.roleName
      } as ApplicationRoleAssignmentModel);
      showRemoveRoleAssignmentModal.value = false;
      await fetchTenantApplications();
    };

    const addRoleAssignments = async (
      applicationRoleAssignments: Array<ApplicationRoleAssignmentModel>
    ) => {
      for (let applicationRoleAssignment of applicationRoleAssignments) {
        await store.dispatch(
          'roles/addApplicationRoleAssignment',
          applicationRoleAssignment
        );
      }
      ElMessage({
        showClose: true,
        message: i18n.global.t('rolesAddedSuccessfully')
      });
      showAddRoleAssignmentModal.value = false;
      await fetchTenantApplications();
    };

    const updateLocalVars = async () => {
      tenantId.value = router.currentRoute.value.params.tenantId as string;
      await fetchTenantApplications();
      await store.dispatch('applications/fetchAllApplications');
      await store.dispatch('roles/fetchAllRoles', GetScopeMaskForTenant());
    };

    onMounted(() => updateLocalVars());

    return {
      addRoleAssignments,
      allApplications,
      allRoles,
      compareRoleAssignments,
      GetScopeForTenant,
      openRemoveRoleAssignmentModal,
      removeRoleAssignment,
      showAddRoleAssignmentModal,
      showRemoveRoleAssignmentModal,
      tenantApplications,
      tenantId
    };
  }
};
</script>
<style lang="scss" scoped></style>
