import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_code_editor = _resolveComponent("code-editor")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_base_modal = _resolveComponent("base-modal")!

  return (_openBlock(), _createBlock(_component_base_modal, {
    width: "500px",
    "modal-title": 
      $props.taskValue.id ? _ctx.$t('updateOrchestration') : _ctx.$t('addNewOrchestration')
    ,
    "confirm-text": _ctx.$t('save'),
    "dialog-visible": $props.dialogVisible,
    onConfirm: _cache[3] || (_cache[3] = ($event: any) => ($setup.confirm())),
    onClose: $setup.handleClose
  }, {
    content: _withCtx(() => [
      _createVNode(_component_el_form, {
        ref: "formRef",
        model: $setup.form,
        rules: $setup.formRules
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, {
            prop: "type",
            label: _ctx.$t('orchestrationType')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: $setup.form.type,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.form.type) = $event)),
                size: "small"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_form_item, {
            size: "small",
            label: _ctx.$t('plannedExecutionDate') + ':'
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_date_picker, {
                modelValue: $setup.form.plannedExecutionDate,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.form.plannedExecutionDate) = $event)),
                class: "date-picker",
                type: "datetime",
                placeholder: _ctx.$t('pickADate')
              }, null, 8, ["modelValue", "placeholder"])
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_form_item, {
            label: _ctx.$t('parameters') + ':',
            prop: "params"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_code_editor, {
                modelValue: $setup.form.params,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.form.params) = $event)),
                font_size: "13px",
                languages: [['JSON', 'JSON']],
                theme: "light",
                min_height: "300px",
                min_width: "100%"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["label"])
        ]),
        _: 1
      }, 8, ["model", "rules"])
    ]),
    _: 1
  }, 8, ["modal-title", "confirm-text", "dialog-visible", "onClose"]))
}