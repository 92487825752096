<template>
  <base-modal
    :modal-title="$t('user') + ' +'"
    :dialog-visible="modelValue"
    :confirm-text="$t('save')"
    @close="$emit('update:model-value', false)"
    @confirm="confirm()"
  >
    <template #content>
      <el-form
        ref="formRef"
        :model="form"
        :rules="formRules"
        label-position="top"
      >
        <el-form-item :label="$t('name')" prop="name">
          <el-input v-model="form.name" size="small" :minlength="10" />
        </el-form-item>
        <el-form-item :label="$t('email')" prop="email">
          <el-input v-model="form.email" size="small" :minlength="30" />
        </el-form-item>
        <el-form-item :label="$t('language')" prop="language">
          <el-select v-model="form.language" size="small">
            <el-option
              v-for="(language, i) in $i18n.availableLocales"
              :key="`Lang${i}`"
              :label="language"
              :value="language"
            />
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('template')" prop="template">
          <el-select v-model="form.template" size="small">
            <el-option
              v-for="(template, i) in templates"
              :key="i"
              :label="template"
              :value="template"
            />
          </el-select>
        </el-form-item>
      </el-form>
    </template>
  </base-modal>
</template>
<script lang="ts">
import BaseModal from '@/components/modals/BaseModal.vue';
import {
  ElForm,
  ElFormItem,
  ElInput,
  ElMessage,
  ElOption,
  ElSelect
} from 'element-plus';
import { reactive, ref } from 'vue';
import i18n from '@/i18n';
import { mustBeValidEmail } from '@/utils/formValidators';

export default {
  name: 'NewUserModal',
  components: {
    BaseModal,
    ElInput,
    ElForm,
    ElFormItem,
    ElSelect,
    ElOption
  },
  props: {
    modelValue: { type: Boolean, default: false },
    existingEmails: { type: Array, default: () => new Array<string>() },
    feedbackText: { type: String, default: i18n.global.t('saved') }
  },
  emits: ['close', 'confirm', 'update:model-value'],
  setup(props, { emit }) {
    const form = reactive({
      name: '',
      email: '',
      language: '',
      template: ''
    });

    const localMustNotBeInArray = (rule: any, value: string, callback: any) => {
      if (props.existingEmails.some(el => el == value)) {
        callback(new Error(i18n.global.t('emailAddressNotAvailable')));
      } else callback();
    };

    const formRules = reactive({
      name: {
        required: true,
        message: i18n.global.t('pleaseProvideName'),
        trigger: ['change', 'blur']
      },
      email: [
        {
          validator: mustBeValidEmail,
          trigger: 'blur'
        },
        {
          validator: localMustNotBeInArray,
          triegger: 'blur'
        }
      ],
      language: {
        required: true,
        message: i18n.global.t('thisFieldIsRequired'),
        trigger: ['change', 'blur']
      },
      template: {
        required: true,
        message: i18n.global.t('thisFieldIsRequired'),
        trigger: 'blur'
      }
    });
    const formRef = ref<HTMLFormElement>();

    const templates: string[] = ['Default', 'Deloitte'];

    let confirm = () => {
      formRef.value?.validate((valid: boolean) => {
        if (valid) {
          emit('confirm', {
            name: form.name,
            email: form.email,
            language: form.language,
            template: form.template
          });
          ElMessage({
            showClose: true,
            message: props.feedbackText
          });
        }
      });
    };
    return {
      confirm,
      form,
      formRef,
      formRules,
      templates
    };
  }
};
</script>
<style scoped lang="scss"></style>
