<template>
  <el-row>
    <page-header :main-title="$t('engagementConfiguration')">
      <template #subheader>
        <h4 class="subtitle-2 mt-3">
          {{ $t('engagementConfigurationDesc') + ' ' + currentEngagement.id }}
        </h4>
      </template>
    </page-header>
  </el-row>
  <el-row v-if="!isGainEngagement" class="d-flex align-items-center">
    <h4 class="ml-auto">
      {{ $t('engagementActions') + ' :' }}
    </h4>
    <el-button
      type="primary"
      class="ml-2"
      @click="showStopCostSavingModal = true"
    >
      <h4>{{ $t('stopCostSaving') }}</h4>
    </el-button>
    <el-button type="primary" class="ml-2" @click="openPublishPipelinesModal">
      <h4>{{ $t('publishPipelines') }}</h4>
    </el-button>
  </el-row>
  <el-row :gutter="spacingWidthHeaderComponents" class="mt-6">
    <el-col :span="17">
      <el-row class="mt-2 mb-1">
        <h4>{{ engagementResources.length }} &#183; {{ $t('results') }}</h4>
      </el-row>
      <el-row class="d-block">
        <base-table
          :show-no-content-table="!engagementResources.length"
          :error-message="$t('noImport')"
        >
          <el-row class="mt-1">
            <configuration-card
              v-for="(resource, index) in engagementResources"
              :key="index"
              :resource="resource"
              :tenant="tenant"
              class="mb-1"
            />
          </el-row>
        </base-table>
      </el-row>
    </el-col>
  </el-row>
  <publish-pipelines-modal
    :show-modal="showPublishPipelineModal"
    :collaboration-branches="collaborationBranches"
    @close="showPublishPipelineModal = false"
  />
  <stop-cost-saving-modal
    v-model="showStopCostSavingModal"
    @confirm="stopCostSaving"
  />
</template>
<script lang="ts">
import { ElRow, ElCol, ElButton, ElMessage } from 'element-plus';
import PageHeader from '@/components/misc/PageHeader.vue';
import ConfigurationCard from './components/ConfigurationCard.vue';
import BaseTable from '@/components/tables/BaseTable.vue';
import { TenantModel } from '@etp/etp-infra-client';
import { ResourceModel } from '@etp/etp-bff-client/axios';
import { onMounted, computed, ComputedRef, Ref, ref } from 'vue';
import { useStore } from 'vuex';
import { spacingWidthHeaderComponents } from '@/utils/variables';
import { EngagementModel } from '@etp/etp-engagements-client/axios';
import PublishPipelinesModal from '@/components/modals/PublishPipelinesModal.vue';
import StopCostSavingModal from '@/components/modals/StopCostSavingModal.vue';
import { CollaborationBranch } from '@etp/etp-pipelines-client/axios';
import { AutopauseExclusionInput } from '@etp/etp-costsaving-client/axios';
import i18n from '@/i18n';

export default {
  name: 'ConfigurationOverview',
  components: {
    PageHeader,
    ConfigurationCard,
    ElRow,
    ElCol,
    BaseTable,
    PublishPipelinesModal,
    ElButton,
    StopCostSavingModal
  },
  setup() {
    const store = useStore();

    const showPublishPipelineModal = ref(false);
    const showStopCostSavingModal = ref(false);

    const engagementResources: ComputedRef<Array<ResourceModel>> = computed(
      () => store.getters['configuration/getEngagementResources']
    );

    const currentEngagement: Ref<EngagementModel> = computed(
      () => store.getters['home/getCurrentEngagement']
    );

    const tenant: ComputedRef<TenantModel> = computed(
      () => store.getters['configuration/getTenant'] as TenantModel
    );

    const collaborationBranches: ComputedRef<Array<CollaborationBranch>> =
      computed(() => store.getters['configuration/getCollaborationBranches']);

    const openPublishPipelinesModal = async () => {
      await store.dispatch('configuration/fetchCollaborationBranches');
      showPublishPipelineModal.value = true;
    };

    const stopCostSaving = async (payload: AutopauseExclusionInput) => {
      showStopCostSavingModal.value = false;
      const httpStatusCode = await store.dispatch(
        'costs/stopCostSavingForEngagement',
        payload
      );
      if (httpStatusCode < 200 || httpStatusCode >= 300) {
        ElMessage({
          showClose: true,
          type: 'error',
          message: i18n.global.t('stopCostSavingFailed')
        });
      }
    };

    const isGainEngagement: ComputedRef<boolean> = computed(
      () => store.getters['home/isGainEngagement']
    );

    onMounted(async () => {
      let tenantId = currentEngagement.value.tenantId;

      await store.dispatch('configuration/fetchTenant', { tenantId });
      await store.dispatch('configuration/fetchEngagementResources');
    });

    return {
      currentEngagement,
      engagementResources,
      isGainEngagement,
      spacingWidthHeaderComponents,
      tenant,
      collaborationBranches,
      openPublishPipelinesModal,
      showPublishPipelineModal,
      showStopCostSavingModal,
      stopCostSaving
    };
  }
};
</script>
