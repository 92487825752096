<template>
  <base-modal
    width="690px"
    :modal-title="$t('userSettings')"
    :confirm-text="$t('save')"
    :dialog-visible="dialogVisible"
    @confirm="confirm"
    @close="handleClose"
  >
    <template #content>
      <div>
        <el-divider>{{ $t('inheritedAssignments') }}</el-divider>
        <el-table :data="localInheritedUsers" :empty-text="$t('noUsers')">
          <el-table-column>
            <template #default="scope">
              <div class="body-1">
                {{ scope.row.name }}
              </div>
              <div class="body-2">
                {{ scope.row.email }}
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-divider>{{ $t('directAssignments') }}</el-divider>
        <el-table :data="localDirectUsers" :empty-text="$t('noUsers')">
          <el-table-column>
            <template #default="scope">
              <div class="body-1">
                {{ scope.row.name }}
              </div>
              <div class="body-2">
                {{ scope.row.email }}
              </div>
            </template>
          </el-table-column>
          <el-table-column :width="100" align="center">
            <template #default="scope">
              <div class="actions">
                <el-tooltip :content="$t('remove')">
                  <img
                    src="@/assets/images/icons/new/delete.svg"
                    class="icon-size-7"
                    @click="removeUser(scope.$index, scope.row)"
                  />
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </template>
    <template #footer>
      <el-dropdown max-height="400px" type="primary" trigger="click">
        <div class="user-dropdown">
          <div class="body-1">
            {{ $t('selectUser') }}
          </div>
          <div class="el-dropdown-link">
            <i class="el-icon-d-caret el-icon--right"></i>
          </div>
        </div>
        <template #dropdown>
          <el-dropdown-menu class="dropdown-menu">
            <el-dropdown-item
              v-for="(user, index) in localAvailableUsers"
              :key="user.email"
              @click="addUser(index, user)"
            >
              {{ user.name }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </template>
  </base-modal>
</template>
<script lang="ts">
import {
  ElDivider,
  ElDropdown,
  ElDropdownMenu,
  ElDropdownItem,
  ElTable,
  ElTableColumn,
  ElTooltip
} from 'element-plus';

import BaseModal from '@/components/modals/BaseModal.vue';
import { ref, onMounted, onUpdated } from 'vue';
import { Modification, ModificationAction } from '@/models/modification';
import { UserDetailsModel } from '@etp/etp-authorization-client/axios';
import { roles_engagement_folderContributor } from '@/utils/variables';

export default {
  name: 'FolderUsersModal',
  components: {
    BaseModal,
    ElDivider,
    ElDropdown,
    ElDropdownMenu,
    ElDropdownItem,
    ElTable,
    ElTableColumn,
    ElTooltip
  },
  props: {
    dialogVisible: { type: Boolean, default: false },
    engagementUsers: {
      type: Array as () => Array<UserDetailsModel>,
      required: true
    },
    targetScope: { type: String, required: true }
  },
  emits: ['close', 'update-users'],
  setup(props, { emit }) {
    let localInheritedUsers = ref(new Array<UserDetailsModel>());
    let localDirectUsers = ref(new Array<UserDetailsModel>());
    let localAvailableUsers = ref(new Array<UserDetailsModel>());
    let usersToBeAdded = ref(new Array<UserDetailsModel>());
    let usersToBeDeleted = ref(new Array<UserDetailsModel>());

    const sortList = (list: UserDetailsModel[]) =>
      list.sort((a, b) => a.name.localeCompare(b.name));

    const updateLocalVars = (engagementUsers: Array<UserDetailsModel>) => {
      localInheritedUsers.value = new Array<UserDetailsModel>();
      localDirectUsers.value = new Array<UserDetailsModel>();
      for (var user of engagementUsers) {
        var inheritedRoleAssignments = user.roleAssignments.filter(
          el =>
            el.roleName == roles_engagement_folderContributor &&
            props.targetScope.startsWith(el.scope) &&
            props.targetScope != el.scope
        );
        if (inheritedRoleAssignments.length) {
          localInheritedUsers.value.push({
            ...user,
            roleAssignments: inheritedRoleAssignments
          });
        }
        var directRoleAssignments = user.roleAssignments.filter(
          el =>
            el.roleName == roles_engagement_folderContributor &&
            el.scope == props.targetScope
        );
        if (directRoleAssignments.length) {
          localDirectUsers.value.push({
            ...user,
            roleAssignments: directRoleAssignments
          });
        }
      }
      localAvailableUsers.value = engagementUsers.filter(
        eu => !localDirectUsers.value.find(du => eu.email === du.email)
      );
      localInheritedUsers.value = sortList(localInheritedUsers.value);
      localDirectUsers.value = sortList(localDirectUsers.value);
      localAvailableUsers.value = sortList(localAvailableUsers.value);
    };
    onMounted(() => updateLocalVars([...props.engagementUsers]));
    onUpdated(() => updateLocalVars([...props.engagementUsers]));

    const removeUser = (index: number, user: UserDetailsModel) => {
      localDirectUsers.value.splice(index, 1);
      localAvailableUsers.value.push(user);
      localAvailableUsers.value = sortList(localAvailableUsers.value);
      usersToBeAdded.value = usersToBeAdded.value.filter(
        utba => utba.email !== user.email
      );
      usersToBeDeleted.value.push(user);
    };
    const addUser = (index: number, user: UserDetailsModel) => {
      localDirectUsers.value.push(user);
      localDirectUsers.value = sortList(localDirectUsers.value);
      localAvailableUsers.value.splice(index, 1);
      usersToBeAdded.value.push(user);
      usersToBeDeleted.value = usersToBeDeleted.value.filter(
        utbr => utbr.email !== user.email
      );
    };

    const handleClose = () => {
      emit('close');
    };
    const confirm = () => {
      const removeModifications = [...new Set(usersToBeDeleted.value)].map(
        u => new Modification<UserDetailsModel>(u, ModificationAction.Remove)
      );
      const addModifications = [...new Set(usersToBeAdded.value)].map(
        u => new Modification<UserDetailsModel>(u, ModificationAction.Add)
      );
      emit('update-users', removeModifications.concat(addModifications));
      usersToBeAdded.value = [];
      usersToBeDeleted.value = [];
    };

    return {
      handleClose,
      localAvailableUsers,
      localDirectUsers,
      localInheritedUsers,
      removeUser,
      addUser,
      confirm
    };
  }
};
</script>
<style lang="scss" scoped>
.user-dropdown {
  color: $etp-light-grey;
  background: $etp-grey-white-1;
  border-radius: 12px;
  width: 100%;
  border: 1px solid $etp-blue-white;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;
  cursor: pointer;
}
.actions {
  cursor: pointer;
  transition: 250ms all cubic-bezier(0.19, 1, 0.22, 1);
  opacity: 0.75;
}
</style>
