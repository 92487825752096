import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_base_table = _resolveComponent("base-table")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_update_requirements_status_modal = _resolveComponent("update-requirements-status-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_row, { gutter: $setup.spacingWidthHeaderComponents }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, null, {
          default: _withCtx(() => [
            (
          $setup.currentRequirementsStatus.requirementsStatus !=
          $setup.RequirementsStatus.SignedOff
        )
              ? (_openBlock(), _createBlock(_component_el_row, {
                  key: 0,
                  class: "d-flex"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_button, {
                      type: "primary",
                      class: "ml-auto",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.showRequirementsStatusModal = true))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('update')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_el_row, { class: "mt-2 d-block" }, {
              default: _withCtx(() => [
                _createVNode(_component_base_table, {
                  "show-loading-table": $setup.loadingTable,
                  "show-no-content-table": !$setup.requirementsStatusHistory.length,
                  "error-message": _ctx.$t('noHistoryToDisplay')
                }, {
                  default: _withCtx(() => [
                    ($setup.requirementsStatusHistory.length)
                      ? (_openBlock(), _createBlock(_component_el_table, {
                          key: 0,
                          data: $setup.requirementsStatusHistory
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_table_column, {
                              label: _ctx.$t('timestamp'),
                              width: "200"
                            }, {
                              default: _withCtx((scope) => [
                                _createElementVNode("h4", null, _toDisplayString($setup.formatTimestamp(scope.row.updatedAt)), 1)
                              ]),
                              _: 1
                            }, 8, ["label"]),
                            _createVNode(_component_el_table_column, {
                              label: _ctx.$t('requirementsStatus'),
                              width: "200"
                            }, {
                              default: _withCtx((scope) => [
                                _createElementVNode("h4", null, _toDisplayString(_ctx.$t(
                      Object.keys($setup.RequirementsStatus)[
                        scope.row.requirementsStatus
                      ]
                    )), 1)
                              ]),
                              _: 1
                            }, 8, ["label"]),
                            _createVNode(_component_el_table_column, {
                              label: _ctx.$t('updatedBy'),
                              width: "200"
                            }, {
                              default: _withCtx((scope) => [
                                _createElementVNode("h4", null, _toDisplayString(scope.row.updatedBy), 1)
                              ]),
                              _: 1
                            }, 8, ["label"]),
                            _createVNode(_component_el_table_column, {
                              label: _ctx.$t('message')
                            }, {
                              default: _withCtx((scope) => [
                                _createElementVNode("h4", null, _toDisplayString(scope.row.message), 1)
                              ]),
                              _: 1
                            }, 8, ["label"])
                          ]),
                          _: 1
                        }, 8, ["data"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["show-loading-table", "show-no-content-table", "error-message"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["gutter"]),
    _createVNode(_component_update_requirements_status_modal, {
      modelValue: $setup.showRequirementsStatusModal,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.showRequirementsStatusModal) = $event)),
      "allowed-values": $setup.allowedRequirementsStatus,
      "feedback-text": _ctx.$t('requirementsStatusSubmitted'),
      "current-status": $setup.currentRequirementsStatus,
      onConfirm: $setup.updateRequirementsStatus
    }, null, 8, ["modelValue", "allowed-values", "feedback-text", "current-status", "onConfirm"])
  ], 64))
}