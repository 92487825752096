import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src", "onClick"]
const _hoisted_2 = ["src", "onClick"]
const _hoisted_3 = ["src", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_base_confirmation_modal = _resolveComponent("base-confirmation-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_table, {
      data: $props.tableData,
      fit: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, { label: "Name" }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(scope.row.name), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, { label: "Id" }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(scope.row.id), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, { width: 100 }, {
          default: _withCtx((scope) => [
            _createVNode(_component_el_tooltip, {
              placement: "top",
              content: _ctx.$t('openDashboard')
            }, {
              default: _withCtx(() => [
                _createElementVNode("img", {
                  src: require(`@/assets/images/icons/eye.svg`),
                  height: "14",
                  width: "16",
                  class: "action-icon mr-1",
                  onClick: _withModifiers(($event: any) => ($setup.openDashboard(scope.row)), ["stop"])
                }, null, 8, _hoisted_1)
              ]),
              _: 2
            }, 1032, ["content"]),
            _createVNode(_component_el_tooltip, {
              placement: "top",
              content: _ctx.$t('openDashboardNewTab')
            }, {
              default: _withCtx(() => [
                _createElementVNode("img", {
                  src: require(`@/assets/images/icons/new-tab.svg`),
                  height: "14",
                  width: "14",
                  class: "action-icon mr-1",
                  onClick: _withModifiers(($event: any) => ($setup.openDashboardNewTab(scope.row)), ["stop"])
                }, null, 8, _hoisted_2)
              ]),
              _: 2
            }, 1032, ["content"]),
            _createVNode(_component_el_tooltip, {
              placement: "top",
              content: _ctx.$t('deleteDashboard')
            }, {
              default: _withCtx(() => [
                _createElementVNode("img", {
                  src: require(`@/assets/images/icons/new/delete.svg`),
                  height: "14",
                  width: "14",
                  class: "action-icon",
                  onClick: _withModifiers(($event: any) => ($setup.openDeleteDashboardModal(scope.row)), ["stop"])
                }, null, 8, _hoisted_3)
              ]),
              _: 2
            }, 1032, ["content"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["data"]),
    _createVNode(_component_base_confirmation_modal, {
      modelValue: $setup.showDeleteDashboardModal,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.showDeleteDashboardModal) = $event)),
      content: _ctx.$t('deleteDashboard'),
      "feedback-text": _ctx.$t('dashboardDeleted'),
      onConfirm: $setup.deleteDashboard,
      onClose: _cache[1] || (_cache[1] = ($event: any) => ($setup.showDeleteDashboardModal = false))
    }, null, 8, ["modelValue", "content", "feedback-text", "onConfirm"])
  ], 64))
}