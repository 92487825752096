import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_switch = _resolveComponent("el-switch")!

  return (_openBlock(), _createBlock(_component_el_switch, {
    "inactive-text": $props.inactiveText,
    "active-text": $props.activeText,
    "model-value": $props.modelValue,
    loading: $props.loading,
    onChange: $setup.handleSwitch
  }, null, 8, ["inactive-text", "active-text", "model-value", "loading", "onChange"]))
}