import { ActionContext, Module } from 'vuex';
import { createAuthorisationClient } from '@/services/api';
import {
  ApplicationRoleAssignmentModel,
  CreateRoleModel,
  RoleDetailsModel,
  RoleModel,
  RoleModelPage,
  UpdateRoleModel,
  UserRoleAssignmentModel
} from '@etp/etp-authorization-client/axios';

interface RoleState {
  activeRole: RoleDetailsModel;
  loadingRoles: boolean;
  roles: Array<RoleModel>;
  pageRoles: Array<RoleModel>;
  rolesPageNumber: number;
  rolesPageSize: number;
  rolesCount: number;
}

class RoleModule implements Module<RoleState, any> {
  namespaced = true;
  state = {
    activeRole: {} as RoleDetailsModel,
    loadingRoles: false,
    roles: new Array<RoleModel>(),
    pageRoles: new Array<RoleModel>(),
    rolesPageNumber: 1,
    rolesPageSize: 10,
    rolesCount: 0
  };
  getters = {
    getActiveRole: (state: RoleState): RoleDetailsModel => state.activeRole,
    getLoadingRoles: (state: RoleState): boolean => state.loadingRoles,
    getRoles: (state: RoleState): Array<RoleModel> => state.roles,
    getPageRoles: (state: RoleState): Array<RoleModel> => state.pageRoles,
    getRolesPageNumber: (state: RoleState): number => state.rolesPageNumber,
    getRolesPageSize: (state: RoleState): number => state.rolesPageSize,
    getRolesCount: (state: RoleState): number => state.rolesCount
  };
  actions = {
    async createRole(
      context: ActionContext<RoleState, any>,
      payload: RoleModel
    ) {
      let client = createAuthorisationClient();
      const { data, status } = await client.createRole({
        name: payload.name,
        scopeMask: payload.scopeMask,
        description: payload.description
      } as CreateRoleModel);
      if (status == 200) {
        context.commit('SET_ACTIVE_ROLE', {
          ...context.state.activeRole,
          ...data
        });
      }
    },
    async deleteRole(
      context: ActionContext<RoleState, any>,
      payload: {
        roleName: string;
      }
    ) {
      let client = createAuthorisationClient();
      await client.deleteRole(payload.roleName);
    },
    async fetchActiveRole(
      context: ActionContext<RoleState, any>,
      payload: string
    ) {
      let client = createAuthorisationClient();
      const { data, status } = await client.getRoleDetails(payload);
      if (status == 200) {
        context.commit('SET_ACTIVE_ROLE', data);
      } else {
        context.commit('SET_ACTIVE_ROLE', {} as RoleDetailsModel);
      }
    },
    async fetchPageRoles(
      context: ActionContext<RoleState, any>,
      payload: string
    ) {
      context.state.loadingRoles = true;
      let client = createAuthorisationClient();
      const { data, status } = await client.listRoles(
        payload,
        context.state.rolesPageNumber,
        context.state.rolesPageSize
      );
      if (status == 200) {
        context.commit('SET_PAGINATED_ROLES', data);
      } else {
        context.commit('SET_PAGINATED_ROLES', {} as RoleModelPage);
      }
      context.state.loadingRoles = false;
    },
    async fetchAllRoles(
      context: ActionContext<RoleState, any>,
      payload: string
    ) {
      context.state.loadingRoles = true;
      let client = createAuthorisationClient();
      const { data, status } = await client.listRoles(payload);
      if (status == 200) {
        context.commit('SET_ROLES', data);
      } else {
        context.commit('SET_ROLES', {} as RoleModelPage);
      }
      context.state.loadingRoles = false;
    },
    async updateRole(
      context: ActionContext<RoleState, any>,
      payload: RoleModel
    ) {
      if (!payload.name) return;
      let client = createAuthorisationClient();
      await client.updateRole(payload.name, {
        scopeMask: payload.scopeMask,
        description: payload.description
      } as UpdateRoleModel);
    },
    async addPermissionToRole(
      context: ActionContext<RoleState, any>,
      payload: {
        roleName: string;
        permissionName: string;
      }
    ) {
      let client = createAuthorisationClient();
      await client.addPermissionToRole(
        payload.roleName,
        payload.permissionName
      );
    },
    async removePermissionFromRole(
      context: ActionContext<RoleState, any>,
      payload: {
        roleName: string;
        permissionName: string;
      }
    ) {
      let client = createAuthorisationClient();
      await client.removePermissionFromRole(
        payload.roleName,
        payload.permissionName
      );
    },
    async addUserRoleAssignment(
      context: ActionContext<RoleState, any>,
      payload: UserRoleAssignmentModel
    ) {
      let client = createAuthorisationClient();
      await client.addUserRoleAssignment(payload);
    },
    async removeUserRoleAssignment(
      context: ActionContext<RoleState, any>,
      payload: UserRoleAssignmentModel
    ) {
      let client = createAuthorisationClient();
      await client.removeUserRoleAssignment(payload);
    },
    async addApplicationRoleAssignment(
      context: ActionContext<RoleState, any>,
      payload: ApplicationRoleAssignmentModel
    ) {
      let client = createAuthorisationClient();
      await client.addApplicationRoleAssignment(payload);
    },
    async removeApplicationRoleAssignment(
      context: ActionContext<RoleState, any>,
      payload: ApplicationRoleAssignmentModel
    ) {
      let client = createAuthorisationClient();
      await client.removeApplicationRoleAssignment(payload);
    }
  };
  mutations = {
    SET_ACTIVE_ROLE(state: RoleState, payload: RoleDetailsModel) {
      state.activeRole = payload;
    },
    SET_ROLES(state: RoleState, payload: RoleModelPage) {
      state.roles = payload.results ?? new Array<RoleModel>();
      state.rolesCount = payload.meta?.totalItems ?? 0;
    },
    SET_PAGINATED_ROLES(state: RoleState, payload: RoleModelPage) {
      state.pageRoles = payload.results ?? new Array<RoleModel>();
      state.rolesCount = payload.meta?.totalItems ?? 0;
    },
    SET_ROLES_PAGE(state: RoleState, payload: number) {
      state.rolesPageNumber = payload;
    }
  };
}

export default new RoleModule();
